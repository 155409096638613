<span
  *ngIf="itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE"
  [ngClass]="{
    'school-data-icon-toggle':
      itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE
  }"
  (click)="toggle()"
  class="{{ iconCustomClass }} school-data-icon"
>
  <i class="fa fa-search"></i>
</span>
<div
  [ngClass]="{
    'school-data-search-toggle':
      itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE,
    outside: itemType === ItemTypeEnum.TOGGLE_OUTSIDE,
    inside: itemType === ItemTypeEnum.TOGGLE && !moveTo,
    'inside-container': itemType === ItemTypeEnum.TOGGLE && moveTo,
    active: animationReady
  }"
  [ngStyle]="{
    display:
      (valueMoved && itemType === ItemTypeEnum.TOGGLE_OUTSIDE) ||
      (itemType === ItemTypeEnum.TOGGLE && !moveToBody && !moveTo) ||
      (itemType === ItemTypeEnum.TOGGLE && (moveToBody || moveTo) && valueMoved) ||
      (itemType !== ItemTypeEnum.TOGGLE_OUTSIDE && itemType !== ItemTypeEnum.TOGGLE && !moveToBody)
        ? 'flex'
        : 'none'
  }"
  (@toggle.done)="animationDone()"
  id="school-data-search-{{ itemId }}"
  class="school-data-search--value search-box-container align-items-center"
  [@toggle]="(itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE) && !isVisible ? 'closed' : 'open'"
  [@slide]="itemType === ItemTypeEnum.SLIDE && !isVisible ? 'closed' : 'open'"
>
  <div class="search-wrapper">
    <div class="search-data">
      <div class="search-box-container--fields">
        <input
          [(ngModel)]="searchPhrase"
          (search)="onSearch()"
          type="search"
          class="form-control"
          placeholder="{{ 'Search' | translate }}"
        />
        <button
          (click)="onSearch()"
          class="btn btn-secondary"
          type="button"
        >
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
    <span
      *ngIf="itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE"
      class="school-data-icon-toggle-close"
    >
      <i
        (click)="toggle()"
        class="school-data-icon--i icon-remove-circle"
      ></i>
    </span>
  </div>
</div>
