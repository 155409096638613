import {
  Component,
  Inject,
} from '@angular/core';
import {
  enums,
  NGB_MODAL_DATA,
  SitesSettingsDomainModel,
} from '@jotter3/api-connector';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isNil } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'j3-sites-popup-box',
  templateUrl: './sites-popup-box.component.html',
  styleUrls: ['./sites-popup-box.component.scss'],
})
export class SitesPopupBoxComponent {
  constructor(
    private cookieService: CookieService,
    public activeModal: NgbActiveModal,
    @Inject(NGB_MODAL_DATA) public dialogData: { popupSettings: SitesSettingsDomainModel }
  ) {}

  get modalSettings(): SitesSettingsDomainModel {
    return this.dialogData.popupSettings;
  }

  onClose(): void {
    if (!isNil(this.modalSettings?.popupSettings)) {
      if (
        this.modalSettings?.popupSettings.publicationMode === 1 &&
        this.cookieService.get('J3CookiesAccepted') !== enums.CookieState.REJECTED
      ) {
        this.cookieService.set('popup_box_closed', 'true');
      }
      if (this.modalSettings?.popupSettings.publicationMode === 2) {
        if (this.cookieService.check('popup_box_closed')) {
          this.cookieService.delete('popup_box_closed');
        }
      }
    }

    this.activeModal.close(this.modalSettings);
  }
}
