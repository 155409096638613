
import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createAction,
  props,
} from '@ngrx/store';

import { TemplateDefinitionDomainModel } from '../../domain-models';



const ACTIONS_PREFIX = '[J3|API|TEMPLATES]';

export const TemplatesStoreActions = {
  loadTrigger: createAction(`${ACTIONS_PREFIX} Load trigger`),
  loadSuccess: createAction(`${ACTIONS_PREFIX} Load success`, props<{ entity: Array<TemplateDefinitionDomainModel> }>()),
  loadFailed: createAction(`${ACTIONS_PREFIX} Load failed`, props<{ errors: Array<ApiErrorResponse | unknown> }>()),
};
