import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { GdprSettingsDomainModel } from '../../../domain-models';
import { CookieState } from '../../../enums';
import { gdprSettingsActions } from '../actions';

export interface GdprSettingsState extends Partial<GdprSettingsDomainModel> {
  isLoaded: boolean;
  isLoading: boolean;
  cookieAccepted: CookieState;
  cookieHasBeenShow: boolean;
}

export const initalGdprSettingsState: GdprSettingsState = {
  isLoaded: false,
  isLoading: false,
  cookieAccepted: CookieState.NOT_INITIALIZED,
  cookieHasBeenShow: false,
};

const gdprReturn = createReducer<GdprSettingsState>(
  initalGdprSettingsState,
  on(gdprSettingsActions.LoadGdprSettings, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(gdprSettingsActions.LoadGdprSettingsComplete, (state, { gdprSettings }) => ({
    ...state,
    ...gdprSettings,
    isLoaded: true,
    isLoading: false,
  })),
  on(gdprSettingsActions.LoadGdprSettingsFailed, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
  })),
  on(gdprSettingsActions.UpdateGdprSettings, (state) => ({ ...state })),
  on(gdprSettingsActions.UpdateGdprSettingsFailed, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
  })),
  on(gdprSettingsActions.UpdateGdprSettingsComplete, (state, { gdprSettings }) => ({
    ...state,
    ...gdprSettings,
    isLoading: false,
    isLoaded: true,
  })),
  on(gdprSettingsActions.UpdateCookieAccepted, (state, { accepted }) => ({
    ...state,
    cookieAccepted: accepted,
  }))
);

export const gdprReducer = (state: GdprSettingsState = initalGdprSettingsState, action: Action): GdprSettingsState =>
  gdprReturn(state, action);
