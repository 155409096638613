<div
  class="toggle-content-enabled"
  *ngIf="designMode && formExpired | ngrxPush"
>
  This form is no longer available
</div>
<div
  class="toggle-content-enabled"
  *ngIf="designMode && (formNotStarted | ngrxPush)"
>
  The form will become available {{ publishDate | date : deteTimeFormatNg }}
</div>
<ng-container *ngIf="cssClass === 'toggle-element'; else standard">
  <j3-toggle-info-component *ngIf="designMode"> </j3-toggle-info-component>
  <ng-container
    *ngIf="
      !designMode &&
        (formExpired | ngrxPush) === false &&
        (formNotStarted | ngrxPush) === false;
      else standard
    "
  >
    <div
      (mouseenter)="toggleItem(true, elementId)"
      (mouseleave)="toggleItem(false, elementId)"
    >
      <div
        class="sectionHeader text-center h3 mb-0"
        [id]="'element-header-' + elementId"
      >
        {{ header | translate }}
      </div>
      <div
        class="forms-wrapper section-toggle-wrapper absolute-section"
        [@openClose]="this.isActive ? 'open' : 'closed'"
        [id]="'element-wrapper-' + elementId"
        [ngClass]="{ 'section-toggle-wrapper-active': isActive === true }"
        [ngStyle]="{ bottom: isActive ? itemPosition : '0' }"
        (@openClose.done)="animationOverflow(false, elementId)"
        (@openClose.start)="animationOverflow(true, elementId)"
      >
        <div>
          <ng-container [ngTemplateOutlet]="contentData"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #standard>
  <ng-container *ngIf="designMode || formIsVisible | ngrxPush">
    <div class="sectionHeader h3 mb-0" *ngIf="formDefinition.length">
      {{ header | translate }}
    </div>
    <ng-container [ngTemplateOutlet]="contentData"></ng-container>
  </ng-container>
  <div
    class="form-not-available"
    *ngIf="this.selectedForm && !formDefinition.length"
  >
    <p>This form is not available for not logged in users.</p>
    <p><a class="btn" (click)="login()">Login</a></p>
  </div>
</ng-template>

<ng-template #contentData>
  <p
    class="p-3 w-100 h-100 text-center bg-secondary"
    *ngIf="!selectedForm && designMode"
  >
    {{ 'Select Form to Show' | translate }}
  </p>
  <div class="forms-container">
    <ng-container *ngIf="selectedForm">
      <div class="row">
        <div class="col">
          <form *ngIf="formDefinition" [formGroup]="formGroup">
            <formly-form
              [options]="options"
              [fields]="formDefinition"
              [form]="formGroup"
            ></formly-form>
          </form>
        </div>
      </div>
      <div class="row" *ngIf="formDefinition.length">
        <div class="col-12 text-center">
          <button
            class="btn btn-primary"
            [disabled]="designMode || !formGroup.valid"
            (click)="onSubmit()"
          >
            {{ 'Submit' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
