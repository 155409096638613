import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createAction,
  props,
} from '@ngrx/store';

import { SlideshowDomainModel } from '../../domain-models';

const ACTIONS_PREFIX = '[J3|API|SITE SLIDESHOWS]';

export const SiteSlideshowsStoreActions = {
  loadTrigger: createAction(`${ACTIONS_PREFIX} Load trigger`, props<{ siteId: string }>()),
  loadSuccess: createAction(`${ACTIONS_PREFIX} Load success`, props<{ entity: SlideshowDomainModel }>()),
  loadFailed: createAction(`${ACTIONS_PREFIX} Load failed`, props<{ errors: Array<ApiErrorResponse | unknown> }>()),
  updateTrigger: createAction(`${ACTIONS_PREFIX} Update trigger`, props<{ entity: Partial<SlideshowDomainModel> }>()),
  updateSuccess: createAction(`${ACTIONS_PREFIX} Update success`, props<{ entity: Partial<SlideshowDomainModel> }>()),
  updateFailed: createAction(`${ACTIONS_PREFIX} Update failed`, props<{ errors: Array<ApiErrorResponse | unknown> }>()),
};
