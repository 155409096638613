import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  filter,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import {
  settingsActions,
  settingsSelectors,
} from '../state/settings';

@Injectable({ providedIn: 'root' })
export class GeneralSettingsLoaderGuard {
  constructor(private store: Store) {}

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.store.select(settingsSelectors.generalSettingsSelector.areGeneralSettingsLoading).pipe(
      filter((loading) => !loading),
      take(1),
      switchMap(() =>
        this.store.select(settingsSelectors.generalSettingsSelector.areGeneralSettingsLoaded).pipe(
          tap((loaded) => {
            if (loaded) {
              return;
            }
            this.store.dispatch(settingsActions.generalSettingsActions.LoadGeneralSettings());
          }),
          filter((loaded) => loaded),
          take(1)
        ))
    );
  }
}
