import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { PolicyGroupDomainModel } from '../../../domain-models';
import { policiesGroupsActions } from '../actions';

interface PoliciesGroupsState extends EntityState<PolicyGroupDomainModel> {
  isLoading: boolean;
  isLoaded: boolean;
}

const policiesGroupsAdapter: EntityAdapter<PolicyGroupDomainModel> = createEntityAdapter<PolicyGroupDomainModel>();

const initalPoliciesGroupsState: PoliciesGroupsState = policiesGroupsAdapter.getInitialState({
  isLoaded: false,
  isLoading: false,
});

const policiesGroupsReturn = createReducer<PoliciesGroupsState>(
  initalPoliciesGroupsState,
  on(policiesGroupsActions.LoadPoliciesGroups, (state): PoliciesGroupsState => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(policiesGroupsActions.LoadPoliciesGroupsComplete, (state, { policiesGroups }) =>
    policiesGroupsAdapter.setAll(policiesGroups, {
      ...state,
      isLoading: false,
      isLoaded: true,
    })),
  on(policiesGroupsActions.LoadPoliciesGroupsFailed, (state): PoliciesGroupsState => ({
    ...state,
    isLoading: false,
    isLoaded: false,
  }))
);

const policiesGroupsReducer = (state: PoliciesGroupsState | undefined, action: Action): PoliciesGroupsState => {
  return policiesGroupsReturn(state, action);
};

export { initalPoliciesGroupsState,
  policiesGroupsAdapter,
  policiesGroupsReducer,
  PoliciesGroupsState };
