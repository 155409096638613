import { Component } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
} from '@angular/platform-browser';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import * as moment from 'moment-timezone';

import { urlValidators } from '../../validators';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'video-embed-content-component',
  displayName: 'Video Embed',
  icon: 'video_call',
  category: SiteComponentCategory.EMBED,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './embed-video.component.html',
  styleUrls: ['./embed-video.component.scss'],
})
export class EmbedVideoSiteElementComponent extends JotterSitesBaseComponent {
  public videoSrc = '';
  public videoSafeUrl: SafeResourceUrl = '';
  public useMoment: any = moment;

  constructor(private sanitizer: DomSanitizer) {
    super(EmbedVideoSiteElementComponent);
  }

  @Property({
    displayName: 'Youtube or Vimeo Link',
    modifierType: ModifierType.TEXT,
    required: true,
    validates: {
      videoLinkMatch: {
        expression: urlValidators.vimeoYoutubeUrlValidator,
        message: 'youtube/vimeo link only',
      },
    },
  })
  get videoLinkUrl(): string {
    return this.videoSrc;
  }

  set videoLinkUrl(value: string) {
    this.videoSrc = value;
    this.videoSafeUrl = this.getSafeUrl(value);
  }

  @Property({
    displayName: 'Video Size',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: 'Original',
        value: false,
      },
      {
        label: 'Full Width',
        value: true,
      },
    ],
    defaultValue: false,
  })
    fullWidth = false;

  private getSafeUrl(link: string): SafeResourceUrl | undefined {
    if (!link) {
      return undefined;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.parseVideoURL(link));
  }

  private getParam(url: string, base: string): string {
    const re = new RegExp(`(\\?|&)${base}\\=([^&]*)(&|$)`);
    const matches = url.match(re);
    if (matches) {
      return matches[2];
    } else {
      return '';
    }
  }

  private parseVideoURL(url: string): string {
    let retVal;

    switch (true) {
      case url.indexOf('youtube.com/watch') !== -1:
        retVal = this.getParam(url, 'v');
        return `https://www.youtube.com/embed/${retVal}`;
      case url.indexOf('youtu.be/') !== -1:
        retVal = url.replace('https://youtu.be/', '');
        return `https://www.youtube.com/embed/${retVal}`;
      default:
        retVal = url.match(/vimeo.com\/(\d+)/)[1];
        return `https://player.vimeo.com/video/${retVal}`;
    }
  }
}
