import {
  enums,
  SiteDomainModel,
} from '@jotter3/api-connector';
import { BehaviorSubject } from 'rxjs';

export abstract class IGdprDataProvider {
  abstract onNavigateToPolicy(): void;
  abstract checkIfCookiesAccepted(cookieKey: string, cookiesType: number): boolean;
  abstract onCookeResponse(accepted: enums.CookieState, cookieKey: string): void;
  abstract initGoogleAnalytics(site: SiteDomainModel): void;
  abstract cookiesAccept$: BehaviorSubject<enums.CookieState>;
}
