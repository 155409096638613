<ng-container *ngIf="cssClass === 'toggle-element'; else standard">
  <j3-toggle-info-component *ngIf="designMode"> </j3-toggle-info-component>
  <ng-container *ngIf="!designMode; else standard">
    <div
      (mouseenter)="toggleItem(true, elementId)"
      (mouseleave)="toggleItem(false, elementId)"
    >
      <div
        class="sectionHeader text-center h3 mb-0"
        [id]="'element-header-' + elementId"
      >
        {{ header | translate }}
      </div>
      <div
        class="galleries-wrapper section-toggle-wrapper absolute-section"
        [@openClose]="this.isActive ? 'open' : 'closed'"
        [id]="'element-wrapper-' + elementId"
        [ngClass]="{
          'section-toggle-wrapper-active': isActive
        }"
        [ngStyle]="{ bottom: isActive ? itemPosition : '0' }"
        (@openClose.done)="animationOverflow(false, elementId)"
        (@openClose.start)="animationOverflow(true, elementId)"
      >
        <ng-container [ngTemplateOutlet]="editor"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #standard>
  <div class="content-element content-element-text-block" [ngClass]="cssClass">
    <div
      class="sectionHeader text-center h3 mb-0"
      *ngIf="header"
      [id]="'element-header-' + elementId"
    >
      {{ header | translate }}
    </div>
    <ng-container [ngTemplateOutlet]="editor"></ng-container>
  </div>
</ng-template>

<ng-template #editor>
  <mat-progress-spinner
    class="editor-loader"
    *ngIf="tinyMCELoading$ | ngrxPush"
    mode="indeterminate"
  />
  <p
    class="text-center text-gray-700"
    *ngIf="designMode && (editorDisabled$ | ngrxPush) && !html?.trim()?.length"
  >
    Please enter some text
  </p>
  <j3-text-editor
    [(content)]="html"
    [disabled]="editorDisabled$ | ngrxPush"
    [inlineMode]="true"
    [editorFonts]="fontsList$ | ngrxPush"
    (editorInitialized)="handleEditorInitialized()"
    previewContainerClass="content-element-text-block-wrapper"
  ></j3-text-editor>
</ng-template>
