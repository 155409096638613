import {
  createAction,
  props,
} from '@ngrx/store';

const LoadRouter = createAction('[Router] Load data', props<{ routerData: any }>());
const LoadRouterComplete = createAction('[Router] Load Router Complete', props<{ routerData: any }>());
const LoadRouterFailed = createAction('[Router] Load data failed', props<{ error: any }>());

export { LoadRouter,
  LoadRouterComplete,
  LoadRouterFailed };
