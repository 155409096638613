import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { RouterDomainService } from '../../../domain-services';
import { routerActions } from '../actions';

@Injectable()
export class RouterEffects {
  loadRouter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.LoadRouter),
      switchMap((data) => of(routerActions.LoadRouterComplete({ routerData: data.routerData })))
    ));

  constructor(private actions$: Actions, private routerDomainService: RouterDomainService) {}
}
