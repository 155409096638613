import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CookiesNotificationService {
  constructor(private cookieService: CookieService) {}

  public checkIfCookiesAccepted(): boolean {
    return this.cookieService.check('cookiesAccepted')
      ? this.cookieService.get('cookiesAccepted') === 'true'
        ? true
        : false
      : false;
  }
}
