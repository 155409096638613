import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApiService,
} from '@jotter3/wa-core';
import { Store } from '@ngrx/store';
import {
  Observable,
  throwError,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { PolicyGroupDomainModel, PolicyGroupListDomainModel } from '../domain-models';
import { BaserDomainService } from './base.domain-service';

@Injectable({ providedIn: 'root' })
export class PoliciesListDomainService extends BaserDomainService<PolicyGroupDomainModel> {
  constructor(apiService: ApiService, private store: Store) {
    super('policies', apiService);
  }

  public getPolicyGroup(
    group?: string
  ): Observable<ApiResponse<PolicyGroupDomainModel>> {
    return this.apiService.loadSingle<PolicyGroupDomainModel>(`policy_groups/${group}`).pipe(
      map((apiResponse) => {
        const { success, error } = apiResponse;
        if (!success) {
          throwError(error);
        }
        return apiResponse;
      })
    );
  }
}
