import {
  inject,
  Injectable,
} from '@angular/core';
import { LetterDomainModel } from '@jotter3/api-connector';
import {
  EntitiesComponentStoreAbstract,
  EntitiesComponentStoreBaseModel,
  LoadingStateEnum,
} from '@jotter3/store-helpers';
import {
  ApiResponse,
  ApiService,
  dataQuery,
  LHSBracketsDataQueryAdapter,
} from '@jotter3/wa-core';
import { Observable } from 'rxjs';
import { urlJoin } from 'url-join-ts';

@Injectable()
export class LettersComponentStore extends EntitiesComponentStoreAbstract<LetterDomainModel, EntitiesComponentStoreBaseModel<LetterDomainModel>> {
  readonly #apiService: ApiService = inject(ApiService);
  readonly #lhsBracketsDataQueryAdapter: LHSBracketsDataQueryAdapter = inject(LHSBracketsDataQueryAdapter);

  constructor() {
    super({
      entities: [],
      loadingState: LoadingStateEnum.PENDING,
    });
  }

  protected override loadData(queryParams: dataQuery.DataQuery): Observable<ApiResponse<LetterDomainModel[]>> {
    return this.#apiService.load<LetterDomainModel>(urlJoin('letters', 'with_attachments'), this.#lhsBracketsDataQueryAdapter.toQueryParam(queryParams));
  }
}
