import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { GdprEffects } from './gdpr.effects';
import { siteStoreFeature } from './gdpr.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(siteStoreFeature),
    EffectsModule.forFeature([GdprEffects]),
  ],
})
export class GdprStoreModule {

}
