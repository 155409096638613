import { RouterReducerState } from '@ngrx/router-store';
import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { CustomRouterStateUrl } from '../../../serializers/custom-serializer';

export const selectRouterState = createFeatureSelector<RouterReducerState<CustomRouterStateUrl>>('routerData');

export const selectRouterData = createSelector(selectRouterState, (state) => state.state?.routerData);

export const routerStateSelector = createSelector(selectRouterState, (state) => state);

export const routerSelector = createSelector(routerStateSelector, (state) => {
  return Object.values(state);
});

export const areRouterLoading = createSelector(routerStateSelector, (state) => state);

export const areRouterLoaded = createSelector(routerStateSelector, (state) => state.state.routerData);
