<div class="accessibility-settings-wrapper">
  <span
    (click)="toggleSettings()"
    class="{{ iconCustomClass }} school-data-icon"
  >
    <i class="school-data-icon--i {{ iconValue }}"></i>
  </span>
  <div
    class="accessibility-settings"
    [@toggle]="!showSettings ? 'closed' : 'open'"
  >
    <p>Colours</p>
    <span
      (click)="changeColours(ColourVariant.DEFAULT)"
      class="default"
      >C</span
    >
    <span
      (click)="changeColours(ColourVariant.BLACK_AND_WHITE)"
      class="black-white"
      >C</span
    >
    <span
      (click)="changeColours(ColourVariant.YELLOW_AND_BLACK)"
      class="yellow-black"
      >C</span
    >
    <span
      (click)="changeColours(ColourVariant.BLUE_AND_YELLOW)"
      class="blue-yellow"
      >C</span
    >
    <p class="mt-2 mb-0">Font Size</p>
    <div class="font-size-container">
      <p
        (click)="onChangeSize(Size.SMALL)"
        class="font-size small"
      >
        A
      </p>
      <p
        (click)="onChangeSize(Size.NORMAL)"
        class="font-size normal"
      >
        A
      </p>
      <p
        (click)="onChangeSize(Size.LARGE)"
        class="font-size large"
      >
        A
      </p>
    </div>
  </div>
</div>
