import {
  createAction,
  props,
} from '@ngrx/store';

import { TenantInfoDomainModel } from '../../../domain-models';

export const LoadTenantData = createAction('[TENANT] Load Tenant Data', props<{ tenantId: string }>());
export const LoadTenantDataComplete = createAction(
  '[TENANT] Load Tenant Data Success',
  props<{ tenant: TenantInfoDomainModel }>()
);
export const LoadTenantDataFailed = createAction('[TENANT] Load Tenant Data Failed', props<{ error: any }>());
