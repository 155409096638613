import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { J3TranslateService } from '@jotter3/common-helpers';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { filter } from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import { ItemType } from '../../enums';
import { ToggleItemsService } from '../../services/toggle-items.service';
import { languages } from './supported-languages';

@TemplateComponent({
  selector: 'jotter-tplcmp-translate',
  icon: 'icon-globe',
  displayName: 'Translate',
  defaultClass: 'school-data-translate',
})
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'jotter-tplcmp-translate',
  templateUrl: './translate-component.html',
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
  animations: [
    trigger('toggle', [
      state(
        'open',
        style({
          height: '*',
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          overflow: 'hidden',
          paddingTop: 0,
          paddingBottom: 0,
        })
      ),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
  ],
})
export class TranslateComponent extends TemplateBaseComponent implements OnInit {
  #store: Store = inject(Store);

  public isVisible = false;
  public ItemTypeEnum = ItemType;
  public itemId = UUID();
  public valueMoved = false;
  public animationReady = false;
  public readonly languages = languages;

  @Input() iconValue = 'icon-globe';
  @Input() iconCustomClass = '';
  @Input() itemType: string = ItemType.STANDARD;
  @Input() moveToBody = false;
  @Input() moveTo = '';

  constructor(
    private eRef: ElementRef,
    private toggleService: ToggleItemsService,
    private translationService: J3TranslateService
  ) {
    super(TranslateComponent);
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  offClickHandler(event: any): void {
    if (!event.target.classList?.contains('school-data-icon-toggle')) {
      return;
    }

    if (
      (event.target.parentNode.classList?.contains('school-data-icon-toggle') ||
        event.target.classList?.contains('school-data-icon-toggle')) &&
      !this.eRef.nativeElement.contains(event.target) &&
      this.isVisible &&
      (this.itemType === ItemType.TOGGLE || this.itemType === ItemType.TOGGLE_OUTSIDE)
    ) {
      this.isVisible = false;
    }
  }

  toggle(): void {
    if (this.itemType === ItemType.TOGGLE_OUTSIDE || (this.itemType === ItemType.TOGGLE && this.moveToBody && !this.valueMoved)) {
      const bodyElement = document.getElementsByTagName('body')[0];
      const valueBarElement = document.getElementById(`school-data-translate-${this.itemId}`);

      if (bodyElement && valueBarElement) {
        bodyElement?.prepend(valueBarElement);
        this.valueMoved = true;
      }
    }
    if (this.itemType === ItemType.TOGGLE && this.moveTo) {
      const valueElement = document.getElementById(`school-data-translate-${this.itemId}`);
      if (valueElement) {
        document.getElementsByClassName(this.moveTo)[0]?.prepend(valueElement);
        this.valueMoved = true;
      }
    }
    this.toggleService.onToggle(TranslateComponent);
    if (this.itemType === ItemType.TOGGLE || this.itemType === ItemType.TOGGLE_OUTSIDE) {
      this.isVisible = !this.isVisible;
      this.animationReady = !this.animationReady ? true : this.animationReady;
    }
  }

  ngOnInit(): void {
    this.toggleService.toggleEvent
      .pipe(
        untilComponentDestroyed(this),
        filter((res) => res !== TranslateComponent)
      )
      .subscribe((res) => {
        if (res === TranslateComponent) {
          return;
        }
        this.isVisible = false;
      });
    if (this.itemType === ItemType.TOGGLE || this.itemType === ItemType.TOGGLE_OUTSIDE) {
      this.isVisible = false;
    }
  }

  public onSelect(e: Record<string, string>): void {
    this.translationService.use(e['id']);
  }

  public animationDone(): void {
    if (!this.isVisible) {
      this.animationReady = false;
    }
  }
}
