import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  enums,
  FileSourceModel,
} from '@jotter3/api-connector';
import { concatLatestFrom } from '@ngrx/effects';
import {
  isArray,
  isNil,
} from 'lodash-es';
import {
  BehaviorSubject,
  fromEvent,
  map,
  take,
} from 'rxjs';

export interface ImageElement {
  srcset: string;
  mediaSize?: string | null;
  type: string;
}


@Component({
  selector: 'j3-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class J3ImageComponent implements AfterViewInit, OnChanges {
  @Input() public alt: string | null;
  @Input() public title: string | null;
  @Input() public imgSrc: FileSourceModel;
  @Input() public sourceSize: enums.PicturesSizes | enums.PicturesSizes[];

  @Input() public cssWidth: string;
  @Input() public cssHeight: string;

  @ViewChild('nativeSource') imgComponentRef: ElementRef<HTMLImageElement>;

  public mediaSize = enums.ImageSize;
  public pictureSizes = enums.PicturesSizes;
  public imagesList: BehaviorSubject<ImageElement[]> = new BehaviorSubject<ImageElement[]>([]);
  public mainImageSize = '';

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: object
  ) {}


  public ngAfterViewInit(): void {
    if (!this.imgComponentRef) {
      return;
    }

    fromEvent(this.imgComponentRef.nativeElement, 'error').pipe(
      take(1),
      concatLatestFrom(() => this.imagesList),
      map(([
        event,
        imagesList,
      ]) => {
        const htmlImage = event.target as HTMLImageElement;
        const itemToDeleted = imagesList.find((item) => item.srcset === htmlImage.currentSrc);
        return itemToDeleted ? this.#removeMissingImage(itemToDeleted.srcset, imagesList) : imagesList;
      })
    ).subscribe((images) => {
      this.imagesList.next(images);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.imgSrc || changes.sourceSize) {
      if (isArray(this.sourceSize)) {
        this.#generateImages(this.sourceSize, true);
        this.mainImageSize = this.sourceSize[this.sourceSize.length - 1];
      } else {
        this.#generateImages([this.sourceSize], false);
        const sourceSizesArray = this.sourceSize.split(',');
        this.mainImageSize = sourceSizesArray[sourceSizesArray.length - 1];
      }
    }
  }

  public arrayOrSingle(value: enums.PicturesSizes | enums.PicturesSizes[]): boolean {
    return isArray(value);
  }

  public urlExist(urlExist: string): boolean {
    return !isNil(urlExist);
  }

  public generateImageLink(imgSrcLink: string, targetSize: enums.PicturesSizes, ext?: string): string {
    const size = targetSize !== enums.PicturesSizes.L ? this.mediaSize[targetSize] : '1920';
    return `${(imgSrcLink ?? '').replace('400x400', `${size}x${size}`)}${ext ? `.${ ext}` : ''}`;
  }

  #removeMissingImage(image: string, imagesListValues: ImageElement[]): ImageElement[] {
    const filterImage = imagesListValues.findIndex((obj: ImageElement): boolean => obj.srcset === image);
    imagesListValues.splice(filterImage, 1);
    return imagesListValues;
  }

  #generateImages(sourceSize: enums.PicturesSizes[], addMediaSize: boolean): void {
    const listOfImages: ImageElement[] = [];
    sourceSize.forEach((imageSize, i) => {
      let minWidth = '';
      let maxWidth = '';
      switch (true) {
        case sourceSize.length === 1:
          minWidth = '';
          maxWidth = '';
          break;
        case sourceSize.length - 1 === i:
          minWidth = this.mediaSize[sourceSize[i - 1]] ? `(min-width: ${this.mediaSize[sourceSize[i - 1]] + 1}px)` : '';
          break;
        default:
          minWidth = this.mediaSize[sourceSize[i - 1]] ? `(min-width: ${this.mediaSize[sourceSize[i - 1]] + 1}px)` : '';
          maxWidth = `${this.mediaSize[sourceSize[i - 1]] ? ' and ' : ''}(max-width: ${this.mediaSize[sourceSize[i]]}px)`;
          break;
      }

      const mediaSizeValue = minWidth !== '' || maxWidth !== '' ? `${minWidth}${maxWidth}` : null;
      if (!isNil(this.imgSrc?.s)) {
        listOfImages.push(
          {
            srcset: this.generateImageLink(this.imgSrc.s, imageSize, 'webp'),
            mediaSize: mediaSizeValue,
            type: 'image/webp',
          }
        );
      }

      if ((!isNil(this.imgSrc?.[imageSize]) || (isNil(this.imgSrc?.[imageSize]) && !isNil(this.imgSrc?.s))) && sourceSize.length > 1) {
        listOfImages.push(
          {
            srcset: this.urlExist(this.imgSrc[imageSize]) ? this.imgSrc[imageSize] : this.generateImageLink(this.imgSrc.s, imageSize),
            mediaSize: mediaSizeValue,
            type: 'image/jpeg',
          }
        );
      }
    });

    this.imagesList.next(listOfImages);
  }
}
