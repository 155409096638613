import { HttpClient } from '@angular/common/http';
import {
  inject,
  Injectable,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  enums,
  pageContentResourcesState,
  pageContentState,
  PageDomainModel,
  PagesContentDomainModel,
  pagesListState,
  siteStoreSelectors,
  TemplateDefinitionDomainModel,
  templatesSelectors,
} from '@jotter3/api-connector';
import { APP_ENV } from '@jotter3/common-helpers';
import { templateBuilderModel } from '@jotter3/sites-core';
import {
  ComponentStoreAbstract,
  LoadingStateEnum,
  StoreBaseModel,
} from '@jotter3/store-helpers';
import { tapResponse } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isNil } from 'lodash-es';
import {
  combineLatest,
  map,
  Observable,
  switchMap,
} from 'rxjs';
import {
  debounceTime,
  filter,
  tap,
} from 'rxjs/operators';
import { urlJoin } from 'url-join-ts';
import * as WebFont from 'webfontloader';

import {
  resourceHelpers,
  siteTemplateHelpers,
} from '../helpers';
import {
  J3_CORE_MODULE_PROVIDER_TOKEN,
  ModuleConfig,
} from '../providers';
import { AuthService } from '../services';

interface SiteContentComponentStateModel extends StoreBaseModel {
  template?: templateBuilderModel.SiteTemplate;
  constPageMode?: enums.PageDefinitions | null;
  page?: PageDomainModel;
  content?: PagesContentDomainModel;
  url?: string;
  notPermitted?: boolean;
  notFound?: boolean;
}

const DEBOUNCE_DUE_TIME = 50;

interface J3RouteParams {
  url?: string;
  revisionId?: string;
}

@Injectable()
export class SiteContentComponentStore extends ComponentStoreAbstract<SiteContentComponentStateModel> implements OnDestroy {
  readonly #moduleConfig: ModuleConfig = inject(J3_CORE_MODULE_PROVIDER_TOKEN);
  readonly #store: Store = inject(Store);
  readonly #environments = inject(APP_ENV);
  readonly #request = inject(REQUEST, { optional: true });
  readonly #httpClient: HttpClient = inject(HttpClient);
  readonly #activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  readonly #authService: AuthService = inject(AuthService);

  readonly selectCurrentTemplate$ = this.select((state: SiteContentComponentStateModel) => state.template).pipe(filter(template => !isNil(template)));
  readonly selectPage$ = this.select((state: SiteContentComponentStateModel) => state.page).pipe(filter(page => !isNil(page)));
  readonly selectContent$ = this.select((state: SiteContentComponentStateModel) => state.content).pipe(filter(content => !isNil(content)));
  readonly selectConstPageMode$ = this.select((state: SiteContentComponentStateModel) => state.constPageMode);
  readonly selectNotPermitted = this.select((state: SiteContentComponentStateModel) => isNil(state.notPermitted) ? false : state.notPermitted);
  readonly selectNotFound = this.select((state: SiteContentComponentStateModel) => !!state.notFound);

  readonly #setCurrentTemplate = this.updater((state: SiteContentComponentStateModel, template: templateBuilderModel.SiteTemplate) => ({
    ...state,
    template,
  }));

  readonly #setCurrentContent = this.updater((state: SiteContentComponentStateModel, action: { content: PagesContentDomainModel, page: PageDomainModel, url: string }) => ({
    ...state,
    constPageMode: null,
    loadingState: LoadingStateEnum.SUCCESS,
    ...action,
  }));

  readonly #setSearchMode = this.updater((state: SiteContentComponentStateModel, action: { url: string, constPageMode: enums.PageDefinitions }): SiteContentComponentStateModel => ({
    ...state,
    loadingState: LoadingStateEnum.SUCCESS,
    ...action,
  }));

  readonly #setLoadingState = this.updater((state: SiteContentComponentStateModel, loadingState: LoadingStateEnum): SiteContentComponentStateModel => ({
    ...state,
    content: undefined,
    loadingState,
    notPermitted: undefined,
    notFound: undefined,
  }));
  private googleFonts = new Array<string>();

  readonly #setPageNotPermitted = this.updater((state: SiteContentComponentStateModel) => ({
    ...state,
    notPermitted: true,
  }));

  readonly #setNotFound = this.updater((state: SiteContentComponentStateModel) => ({
    ...state,
    notFound: true,
  }));

  constructor() {
    super({
      loadingState: LoadingStateEnum.PENDING,
      constPageMode: null,
    });
  }

  public updatePageContent = this.updater((state: SiteContentComponentStateModel, content: PagesContentDomainModel) => ({
    ...state,
    content,
  }));

  public loadSiteTemplate = this.effect<void>(trigger$ => trigger$.pipe(
    switchMap(() => this.#store.select(templatesSelectors.selectEntities)),
    map(templates => siteTemplateHelpers.convertVariantsToTemplateDefinitions(templates)),
    concatLatestFrom(() => this.#store.select(siteStoreSelectors.selectEntity)),
    map(([
      templates,
      site,
    ]) => siteTemplateHelpers.getSiteOrDefaultTemplate(templates, site.themeName)),
    map(template => {
      if (template?.editorFonts) {
        template.editorFonts.forEach(font => {
          const googleFont = `${font.name}${font.weight ? `:${font.weight}` : ''}`;
          this.googleFonts = [
            ...this.googleFonts,
            googleFont,
          ];
        });
        WebFont.load({
          google: {
            families: this.googleFonts,
          },
        });
      }
      return this.#downloadTemplateFromS3(template);
    }),
    tapResponse(
      (template) => this.#setCurrentTemplate(template),
      (error) => console.error(error)
    )
  ));

  public loadSiteContent = this.effect<void>(trigger$ => trigger$.pipe(
    switchMap(() => combineLatest([
      this.#store.select(pagesListState.pagesSelectors.arePagesListLoaded),
      this.#store.select(pageContentState.pageContentSelectors.selectIsPageContentLoaded),
      this.#store.select(pageContentState.pageContentSelectors.selectIsPageContentLoading),
      this.#store.select(pageContentState.pageContentSelectors.selectCurrentPageUrl),
      this.#observeRouteParams(),
    ])),
    debounceTime(DEBOUNCE_DUE_TIME),
    filter(([
      pagesLoaded,
      pageContentLoaded,
      pageContentLoading,
      currentPageUrl,
      routeParams,
    ]) => {
      if (!pagesLoaded || pageContentLoading) {
        return false;
      }

      const { url, revisionId } = routeParams;
      switch (true) {
        case url.toLowerCase() === enums.PageDefinitions.SEARCH:
          this.#setSearchMode({
            url: url,
            constPageMode: enums.PageDefinitions.SEARCH,
          });
          return false;
          break;
        case url.toLowerCase() === enums.PageDefinitions.CALENDAR:
          this.#setSearchMode({
            url: url,
            constPageMode: enums.PageDefinitions.CALENDAR,
          });
          return false;
          break;
      }

      if ((!pageContentLoaded && !pageContentLoading) || currentPageUrl !== url) {
        this.#setLoadingState(LoadingStateEnum.LOADING);
        this.#store.dispatch(pageContentState.pageContentActions.pageContentActions.LoadPageContent({
          url,
          revisionId,
        }));

        return false;
      }
      return pageContentLoaded;
    }),
    filter(([
      pagesLoaded,
      contentLoaded,
      contentLoading,
    ]) => pagesLoaded && contentLoaded && !contentLoading),
    concatLatestFrom(() => [
      this.#store.select(pageContentState.pageContentSelectors.selectCurrentPage),
      this.#store.select(pageContentState.pageContentSelectors.selectCurrentPageContent),
      this.#store.select(pageContentState.pageContentSelectors.selectCurrentPageUrl),
      this.#store.select(pageContentState.pageContentSelectors.selectPageContentLoadingState),
      this.#store.select(pageContentState.pageContentSelectors.selectPageContentErrors),
    ]),
    filter(([
      ,
      page,
      content,
      pageUrl,
      loadingState,
      errors,
    ]) => {
      if (loadingState === LoadingStateEnum.FAILED) {
        if (errors?.status === 403) {
          if (!this.#authService.isAuthorized) {
            this.#authService.redirectToLogin();
          } else {
            this.#setPageNotPermitted();
          }
        } else if (errors?.status === 404) {
          this.#setNotFound();
        }

        return false;
      }

      return true;
    }),
    map(([
      , page,
      content,
      url,
    ]) => ({
      page,
      content,
      url,
    })),
    switchMap(({ page, content, url }) => {
      return combineLatest([
        this.#store.select(pageContentResourcesState.pageContentResourcesSelectors.selectPageContentResourcesIsLoading),
        this.#store.select(pageContentResourcesState.pageContentResourcesSelectors.selectPageContentResourcesIsLoaded),
        this.#store.select(pageContentResourcesState.pageContentResourcesSelectors.selectResourcePageUrl),
      ]).pipe(
        debounceTime(DEBOUNCE_DUE_TIME),
        filter(([
          resourcesLoading,
          resourcesLoaded,
          currentResourcesUrl,
        ]) => {
          if (resourcesLoading || !content) {
            return false;
          }

          if ((!resourcesLoaded && !resourcesLoading) || currentResourcesUrl !== url) {
            const resourceIds = resourceHelpers.getPageContentResourceIds(content);
            const resourceListIds = resourceHelpers.getPageContentResourceListIds(content);

            this.#store.dispatch(pageContentResourcesState.pageContentResourcesActions.LoadPageContentResources({
              pageUrl: url,
              resourceIds,
              resourceListIds,
            }));
            return false;
          }

          return resourcesLoaded;
        }),
        map(() => ({
          page,
          content,
          url,
        }))
      );
    }),
    tap(({
      page,
      content,
      url,
    }) => {
      if (!isNil((this.#request as any)?.cacheEntry)) {
        (this.#request as any).cacheEntry.page = page;
      }

      this.#setCurrentContent({
        page,
        content,
        url,
      });
    })
  ));

  #downloadTemplateFromS3(templateDefinition: TemplateDefinitionDomainModel): Observable<templateBuilderModel.SiteTemplate> {
    if (!templateDefinition) {
      console.error('templateDefinition is undefined');
      return null;
    }
    const { template_file, folder, styles } = templateDefinition;
    const requestUrl = urlJoin(this.#environments.SITES_TEMPLATES_URL, folder, template_file);

    return this.#httpClient.get(requestUrl, {
      responseType: 'text',
      headers: {
        Anonymous: 'true',
        XTenantIgnore: 'true',
      },
    }).pipe(
      map<string, templateBuilderModel.SiteTemplate>(response => ({
        ...templateDefinition,
        templateHtml: response,
        styles: (styles || []).map((style) => urlJoin(this.#environments.SITES_TEMPLATES_URL, folder, style)),
      }))
    );
  }

  #observeRouteParams(): Observable<J3RouteParams> {
    return this.#moduleConfig.APP_TYPE === 'client'
      ? this.#activatedRoute.url.pipe(map(urlSegments => ({ url: decodeURIComponent(urlSegments.join('/')).toLowerCase() })))
      : this.#activatedRoute.paramMap.pipe(map(paramMap => ({
        url: decodeURIComponent(paramMap.get('pageUrl') ?? paramMap.get('route')).toLowerCase(),
        revisionId: paramMap.get('revisionId'),
      })));
  }
}
