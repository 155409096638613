import { InjectionToken } from '@angular/core';

import { BaseApiEntity } from './api-entity.model';
import { ApiResponse } from './api-response.model';
import { ApiErrorResponse } from './api-response-error.model';
import * as arrayHelpers from './array.helpers';
import { ModuleConfig } from './module-config';
import { Pagination } from './pagination.model';

const ModuleOptions = new InjectionToken<ModuleConfig>('moduleConfig');

export { ApiErrorResponse,
  ApiResponse,
  arrayHelpers,
  BaseApiEntity,
  ModuleConfig,
  ModuleOptions,
  Pagination };
