import { Injectable } from '@angular/core';
import { ApiService } from '@jotter3/wa-core';

import { GdprSettingsDomainModel } from '../domain-models';
import { BaserDomainService } from './base.domain-service';

@Injectable({ providedIn: 'root' })
export class GdprSettingsDomainService extends BaserDomainService<GdprSettingsDomainModel> {
  constructor(apiService: ApiService) {
    super('gdpr/settings', apiService);
  }
}
