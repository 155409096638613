import {
  Component,
  Input,
} from '@angular/core';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';

enum ScrollTo {
  CONTENT = 'down',
  TOP = 'top'
}

@TemplateComponent({
  selector: 'jotter-tplcmp-scroll-to',
  displayName: 'scroll-to',
  icon: 'settings',
  defaultClass: 'scroll-to-button-container',
})
@Component({
  selector: 'jotter-tplcmp-scroll-to',
  template: `<button (click)="scroll()" class="scroll-to-button scroll-to-{{scrollTo}} {{ linkCustomClass ? linkCustomClass : ''}}">
    <i *ngIf="scrollTo === siteScrollTo.TOP; else scrollToContent" class="fa-solid {{ iconCustomClass ? iconCustomClass : 'fa-angles-up'}}"></i>
    <ng-template #scrollToContent>
      <i class="fa-solid {{ iconCustomClass ? iconCustomClass : 'fa-angles-down'}}"></i>
    </ng-template>
    <span *ngIf="buttonText" class="scroll-to-button-text">{{buttonText}}</span>
  </button> <span *ngIf="infoText" class="scroll-to-button-info-text">{{infoText}}</span>`,
})
export class ScrollToComponent extends TemplateBaseComponent {
  @Input() iconCustomClass: string | null = null;
  @Input() linkCustomClass: string | null = null;
  @Input() scrollTo: ScrollTo = ScrollTo.TOP;
  @Input() customTarget: string | null = null;
  @Input() buttonText: string | null = null;
  @Input() infoText: string | null = null;

  constructor() {
    super(ScrollToComponent);
  }

  public siteScrollTo = ScrollTo;

  public scroll(): void {
    if (this.scrollTo === ScrollTo.TOP) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      const target = document.getElementById(this.customTarget ? this.customTarget : 'site-content');
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

