import {
  LoadingStateEnum,
  StoreBaseModel,
} from '@jotter3/store-helpers';
import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createReducer,
  on,
} from '@ngrx/store';

import {
  PageDomainModel,
  PagesContentDomainModel,
} from '../../../domain-models';
import { pageContentActions } from '../actions';

interface PageState extends StoreBaseModel {
  currentPage?: PageDomainModel;
  currentPageContent?: PagesContentDomainModel;
  pageUrl?: string;
  error?: ApiErrorResponse;
}

const initialPageContentState: PageState = {
  loadingState: LoadingStateEnum.PENDING,
};

const pageContentReducer = createReducer<PageState>(
  initialPageContentState,
  on(pageContentActions.LoadPageContent, (state: PageState, { url }): PageState => ({
    ...state,
    loadingState: LoadingStateEnum.LOADING,
    pageUrl: url,
  })),
  on(pageContentActions.LoadPageContentComplete, (state: PageState, { pageUrl, pageContent, page }): PageState => ({
    ...state,
    loadingState: LoadingStateEnum.SUCCESS,
    currentPage: page,
    currentPageContent: pageContent,
    pageUrl,
  })),
  on(pageContentActions.LoadPageContentFailed, (state: PageState, { error }): PageState => ({
    ...state,
    loadingState: LoadingStateEnum.FAILED,
    error,
  })),
  on(pageContentActions.UpdatePageContent, (state: PageState, { pageContent }): PageState => ({
    ...state,
    currentPageContent: pageContent,
  }))
);

export { initialPageContentState,
  pageContentReducer,
  PageState };
