import {
  BaseBuilderComponent,
  DropdownItemModel,
  OptionsListModel,
  OptionsTooltipListModel,
  PropertyMetadataModel,
  SettingsItem,
} from '@jotter3/sites-abstract';
import {
  FormlyFieldConfig,
  FormlyTemplateOptions,
} from '@ngx-formly/core';
import {
  Observable,
  of,
} from 'rxjs';

const getItemDataSource = (
  metadata: PropertyMetadataModel,
  componentInstance?: BaseBuilderComponent
): Observable<DropdownItemModel[]> => {
  const { dropdownSource } = metadata;

  let source = dropdownSource;

  if (typeof source === 'string') {
    source = (componentInstance as any)[source];
  }

  return source instanceof Observable ? (source as Observable<DropdownItemModel[]>) : of(source as DropdownItemModel[]);
};

const getItemOptionsList = (
  metadata: PropertyMetadataModel,
  componentInstance?: BaseBuilderComponent
): Observable<OptionsListModel[]> => {
  const { optionsList } = metadata;

  let source = optionsList;

  if (typeof source === 'string') {
    source = (componentInstance as any)[source];
  }

  return source instanceof Observable ? (source as Observable<OptionsListModel[]>) : of(source as OptionsListModel[]);
};

const getItemOptionsTooltipList = (
  metadata: PropertyMetadataModel,
  componentInstance?: BaseBuilderComponent
): Observable<OptionsTooltipListModel[]> => {
  const { optionsList } = metadata;

  let source = optionsList;

  if (typeof source === 'string') {
    source = (componentInstance as any)[source];
  }

  return source instanceof Observable ? (source as Observable<OptionsTooltipListModel[]>) : of(source as OptionsTooltipListModel[]);
};


const getRadioButtonDataSource = (
  metadata: PropertyMetadataModel,
  componentInstance: BaseBuilderComponent
): Observable<any[]> => {
  const { optionsList } = metadata;

  let source = optionsList;

  if (typeof source === 'string') {
    source = (componentInstance as any)[source];
  }

  return source instanceof Observable ? (source as Observable<any[]>) : of(source as any[]);
};

const baseTransformation = (settingsItem: SettingsItem, templateOptions?: FormlyTemplateOptions): FormlyFieldConfig => {
  const {
    name,
    metadata: {
      placeholder,
      className,
      description,
      validates,
      displayName,
      units,
      required,
      hideExpression,
      template,
      expressions,
      ...restOfMetadata
    },
  } = settingsItem;
  return {
    key: name,
    className,
    templateOptions: {
      ...templateOptions,
      ...restOfMetadata?.['templateOptions'],
      clearable: settingsItem.metadata?.['clearable'] !== false,
      required,
      placeholder,
      template,
      units,
      label: displayName,
      description,
    },
    hideExpression,
    expressions,
    validators: validates,
  };
};

export const textInputTransform = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem),
    type: 'input',
  };

  return fieldConfig;
};

export const unitInputTransform = (
  settingsItem: SettingsItem,
  componentInstance?: BaseBuilderComponent,
  templateOptions: any = {}
): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem),
    type: 'unit-input',
  };

  return fieldConfig;
};

export const numericInputTransform = (
  settingsItem: SettingsItem,
  componentInstance?: BaseBuilderComponent
): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem, { type: 'number' }),
    type: 'input',
  };

  return fieldConfig;
};

export const dropdownInputTransform = (
  settingsItem: SettingsItem,
  componentInstance?: BaseBuilderComponent
): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem, {}),
    type: 'ng-select',
  };

  fieldConfig.templateOptions = {
    ...fieldConfig.templateOptions,
    options: getItemDataSource(settingsItem.metadata, componentInstance),
    valueProp: 'value',
    labelProp: 'label',
  };

  return fieldConfig;
};

export const multiDropdownInputTransform = (
  settingsItem: SettingsItem,
  componentInstance?: BaseBuilderComponent
): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...dropdownInputTransform(settingsItem, componentInstance),
    type: 'ng-select',
  };

  if (fieldConfig.templateOptions) {
    fieldConfig.templateOptions['multiple'] = true;
  }

  return fieldConfig;
};

export const textareaTransform = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig => {
  return {
    ...baseTransformation(settingsItem, { rows: 5 }),
    type: 'textarea',
  };
};

export const chekboxTransform = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem),
    type: 'checkbox',
  };

  return fieldConfig;
};

export const datePickerTransform = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem, { rows: 5 }),
    type: 'datepicker',
  };

  return fieldConfig;
};

export const timePickerTransform = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem, { rows: 5 }),
    type: 'timepicker',
  };

  return fieldConfig;
};

export const arrayTransform = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig => {
  const {
    metadata: { placeholder },
  } = settingsItem;
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem, { items: { type: 'string' } }),
    fieldArray: {
      type: 'input',
      templateOptions: {
        placeholder,
      },
    },
    type: 'array',
  };
  return fieldConfig;
};

export const fileUploadTransform = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem, { type: 'file-upload' }),
    type: 'file-upload',
  };

  return fieldConfig;
};

export const customSelector = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig => ({
  ...settingsItem.metadata?.['customFieldDef'],
});

export const j3ApiDropdown = (
  settingsItem: SettingsItem,
  componentInstance?: BaseBuilderComponent,
  templateOptions: any = {}
): FormlyFieldConfig => {
  const { metadata } = settingsItem;

  return {
    ...baseTransformation(settingsItem, {
      ...templateOptions,
      resourceName: metadata?.['resourceName'],
      valueProp: metadata?.['valueProp'],
      labelProp: metadata?.['labelProp'],
      customItemFilter: metadata?.['customItemFilter'],
      constDataQueryFilters: metadata?.['constDataQueryFilters'],
      orderApiResponse: metadata?.['orderApiResponse'],
      buildOrderQuery: metadata?.['buildOrderQuery'],
      buildSearchQuery: metadata?.['buildSearchQuery'],
    }),
    type: 'j3-api-select',
  };
};

export const j3ApiMultiDropdown = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig =>
  j3ApiDropdown(settingsItem, componentInstance, { multiple: true });

export const radioButtonTransform = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem, {}),
    type: 'radio',
  };

  fieldConfig.templateOptions = {
    ...fieldConfig.templateOptions,
    options: getItemOptionsList(settingsItem.metadata, componentInstance),
    valueProp: 'value',
    labelProp: 'label',
  };

  return fieldConfig;
};

export const radioButtonGroupTransform = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem, {}),
    type: 'radio-btn-group',
  };

  fieldConfig.templateOptions = {
    ...fieldConfig.templateOptions,
    options: getItemOptionsList(settingsItem.metadata, componentInstance),
    valueProp: 'value',
    labelProp: 'label',
  };

  return fieldConfig;
};

export const imageResolutionTransform = (settingsItem: SettingsItem, componentInstance?: BaseBuilderComponent): FormlyFieldConfig => {
  const fieldConfig: FormlyFieldConfig = {
    ...baseTransformation(settingsItem, {}),
    type: 'image-resolution',
  };

  fieldConfig.templateOptions = {
    ...fieldConfig.templateOptions,
    options: getItemOptionsTooltipList(settingsItem.metadata, componentInstance),
    valueProp: 'value',
    labelProp: 'label',
    tooltipProp: 'tooltip',
  };

  return fieldConfig;
};
