import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApiService,
} from '@jotter3/wa-core';
import { Observable } from 'rxjs';
import { urlJoin } from 'url-join-ts';

import {
  TenantInfoDomainModel,
  TenantSignInDomainModel,
} from '../domain-models';
import { BaserDomainService } from './base.domain-service';

@Injectable({ providedIn: 'root' })
export class TenantDomainService extends BaserDomainService<TenantInfoDomainModel> {
  constructor(apiService: ApiService) {
    super('tenants', apiService);
  }

  /**
   * Obtain an access token for the sub-organization and relevant data about that organization
   *
   * @param subOrganisationId : Sub-organisation id
   */
  public signInToSubOrganisation(childId: string): Observable<ApiResponse<TenantSignInDomainModel>> {
    return this.apiService.loadSingle<TenantSignInDomainModel>(urlJoin('auth', 'tenant', childId, 'sign-in'));
  }
}
