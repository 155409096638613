import { Injectable } from '@angular/core';
import {
  IDataModel,
  SiteSettingsDataService,
} from '@jotter3/common-helpers';
import { ContentDataProvider } from '@jotter3/content-components';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteContentsDataService extends ContentDataProvider {
  appEditorUrlLinks = '/';

  constructor(private sitesService: SiteSettingsDataService) {
    super();
  }

  public getData(): Observable<IDataModel> {
    return this.sitesService.getData();
  }
}
