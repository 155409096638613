import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';
import {
  GeneralSettingsLoaderGuard,
  PagesGuard,
  SiteGuard,
  TemplateCollectionLoadedGuard,
} from '@jotter3/api-connector';

import { AppLoadedGuard } from './common';
import {
  ErrorBaseComponent,
  SiteRendererComponent,
} from './components';

const routes: Routes = [
  {
    path: 'error/404',
    component: ErrorBaseComponent,
    data: {
      title: '404',
      statusCode: 404,
      description: 'The page you are looking for was not found.',
    },
  },
  {
    path: 'error/500',
    component: ErrorBaseComponent,
    data: {
      title: '500',
      statusCode: 500,
      description: 'Oops... Something went wrong',
    },
  },
  {
    canActivate: [
      AppLoadedGuard,
      GeneralSettingsLoaderGuard,
      TemplateCollectionLoadedGuard,
      SiteGuard,
      PagesGuard,
    ],
    component: SiteRendererComponent,
    path: '**',
    runGuardsAndResolvers: 'pathParamsChange',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
