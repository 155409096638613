import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import {
  AppStoreActions,
  common,
  domainModels,
  domainServices,
  enums,
  myGroupsListState,
  permissionsState,
  userProfileState,
} from '@jotter3/api-connector';
import { AppType } from '@jotter3/common-helpers';
import { ApiService } from '@jotter3/wa-core';
import { Store } from '@ngrx/store';
import {
  REQUEST,
  RESPONSE,
} from '@nguniversal/express-engine/tokens';
import {
  Request,
  Response,
} from 'express';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import isNil from 'lodash-es/isNil';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { urlJoinP } from 'url-join-ts';

import {
  J3_CORE_MODULE_PROVIDER_TOKEN,
  ModuleConfig,
} from '../providers';
import { AppTenantInfoService } from './app-tenant-info.service';

export interface TokenInfo {
  expiresAt?: number;
  refreshToken?: string;
  token: string;
  type?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private authorized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private authorizationInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isRegistrationProcess = false;
  private ghostStoreKey: string;
  private parentTenantStoreKey: string;

  constructor(
    public readonly firebaseAuth: AngularFireAuth,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly appTenantInfoService: AppTenantInfoService,
    private readonly cookieService: CookieService,
    private readonly profileDomainService: domainServices.ProfileDomainService,
    private readonly store: Store,
    private readonly apiService: ApiService,
    @Inject(J3_CORE_MODULE_PROVIDER_TOKEN) private readonly moduleConfig: ModuleConfig,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    @Optional() @Inject(REQUEST) private readonly request: Request,
    @Optional() @Inject(RESPONSE) private readonly response: Response
  ) {}

  public get authToken(): string {
    const cookieKey = `token_${this.appTenantInfoService.tenantId}`;

    return isPlatformServer(this.platformId) ? this.request?.cookies[cookieKey] : this.cookieService?.get(cookieKey);
  }

  public get idToken(): string {
    const cookieKey = `idToken_${this.appTenantInfoService.tenantId}`;

    return isPlatformServer(this.platformId) ? this.request?.cookies?.[cookieKey] : this.cookieService?.get(cookieKey);
  }

  public get parentTenant(): string {
    return window.sessionStorage.getItem(this.parentTenantStoreKey);
  }

  public set parentTenant(parent_tenant: string) {
    window.sessionStorage.setItem(this.parentTenantStoreKey, parent_tenant);
  }

  public get ghostMode(): boolean {
    const storeValue = window.sessionStorage.getItem(this.ghostStoreKey);

    return storeValue ? !!JSON.parse(storeValue) : false;
  }

  public set ghostMode(value: boolean) {
    if (value) {
      window.sessionStorage.setItem(this.ghostStoreKey, `${value}`);
      return;
    }

    window.sessionStorage.removeItem(this.ghostStoreKey);
  }

  public set registrationProcess(value: boolean) {
    this.isRegistrationProcess = value;
  }

  public initialize(applicationType: AppType): Observable<boolean> {
    if (applicationType === AppType.CLIENT) {
      return this.initClientAuth();
    }

    return this.initAdminAuth();
  }

  public initClientAuth(): Observable<boolean> {
    return this.appTenantInfoService.loaded.pipe(
      filter((loaded) => loaded),
      switchMap(() =>
        isPlatformServer(this.platformId) ? of(this.idToken) : this.firebaseAuth.idTokenResult.pipe(map((token) => token?.token))),
      switchMap((token) => combineLatest([
        of(token),
        this.getAuthToken(token),
      ])),
      tap(([
        token,
        apiToken,
      ]) => {
        if (!token || !apiToken?.result) {
          return;
        }

        const { result, fromCache } = apiToken;

        this.observeTokenExpiredDate(result.token);
        this.setIdToken(token);

        if (fromCache) {
          return;
        }

        this.setToken(result);
      }),
      switchMap(([
        fireToken,
        apiToken,
      ]) => of(!!fireToken && !!apiToken)),
      tap((success) => {
        console.log('[Login Success]: ', success);
        this.authorized$.next(success);
        this.authorizationInitialized.next(true);
      }),
      switchMap(() => this.authorized$)
    );
  }

  public initAdminAuth(): Observable<boolean> {
    return this.appTenantInfoService.loaded.pipe(
      filter((loaded) => loaded),
      tap(() => {
        this.ghostStoreKey = `ghost-${this.appTenantInfoService.tenantId}`;
        this.parentTenantStoreKey = `parent_tenant-${this.appTenantInfoService.tenantId}`;
      }),
      switchMap(() =>
        isPlatformServer(this.platformId)
          ? of(this.request.cookies[`idToken_${this.appTenantInfoService.tenantId}`])
          : this.firebaseAuth.idTokenResult.pipe(map((token) => token?.token))),
      switchMap((tokenResult) => {
        if (!tokenResult || this.isRegistrationProcess) {
          this.isRegistrationProcess = false;
          return of(false);
        }

        return this.getAuthToken(tokenResult).pipe(
          tap(({ result, fromCache }) => {
            this.observeTokenExpiredDate(result.token);
            this.setIdToken(tokenResult);

            if (fromCache) {
              return;
            }

            this.setToken(result);
          }),
          switchMap(() => of(true).pipe(tap(() => this.store.dispatch(permissionsState.permissionActions.LoadPermissions())))),
          switchMap(() => this.store.select(permissionsState.permissionSelectors.arePermissionsLoaded)),
          filter((permissionsLoaded) => permissionsLoaded),
          switchMap(() => this.store.select(permissionsState.permissionSelectors.permissionsSelector)),
          switchMap((permissions) =>
            permissions.map((x) => x.value).includes(enums.PermissionRole.ROLE_DASH_LOGIN)
              ? combineLatest([
                of(true).pipe(tap(() => this.store.dispatch(myGroupsListState.myGroupsActions.LoadMyGroupsList()))),
                this.ghostMode
                  ? this.getProfileFromParentTenant(this.parentTenant).pipe(
                    take(1),
                    tap((userData) =>
                      this.store.dispatch(userProfileState.userActions.LoadUserMyProfileDataComplete({ userData })))
                  )
                  : of(true).pipe(tap(() => this.store.dispatch(userProfileState.userActions.LoadUserMyProfileData()))),
              ])
              : of(true)),
          catchError((error) => {
            console.error(error);
            this.logout();
            return of(false);
          })
        );
      }),
      map((success) => !!success),
      tap((authorized) => this.authorized$.next(authorized)),
      filter((authorized) => authorized)
    );
  }

  public resetPassword(email: string): Promise<void> {
    return this.firebaseAuth.sendPasswordResetEmail(email);
  }

  public loginUser(email: string, password: string): Promise<any> {
    this.authorized$.next(false);
    this.clearCookies();
    return this.firebaseAuth.signInWithEmailAndPassword(email, password);
  }

  public get initialized(): Observable<boolean> {
    return this.authorizationInitialized.asObservable();
  }

  public get authorized(): Observable<boolean> {
    return this.authorized$.asObservable();
  }

  public get isAuthorized(): boolean {
    return this.authorized$.getValue();
  }

  public logout(client?: boolean): void {
    this.apiService.save('auth/logout', {}).subscribe(res => {
      if (res.error) {
        console.error(res.error);
      }
      if (isPlatformServer(this.platformId)) {
        this.clearCookies(true);
      }
      this.firebaseAuth
        .signOut()
        .then(() => {
          this.authorized$.next(false);

          this.clearCookies(true);
          this.store.dispatch(common.resetStateAction());
          this.store.dispatch(AppStoreActions.entered({
            tenant: this.appTenantInfoService.tenant,
            domain: this.appTenantInfoService.domain,
          }));
          if (isNil(client)) {
            this.router.navigate(['/auth/login'], { queryParams: { redirectUrl: this.router.url } });
          } else {
            window.location.replace('/');
          }
        })
        .catch((error) => console.error(error));
    });

  }

  public clearCookies(clearSession = false): void {
    this.removeCookie(`idToken_${this.appTenantInfoService.tenantId}`);
    this.removeCookie(`token_${this.appTenantInfoService.tenantId}`);
    this.removeCookie('authorization');

    if (clearSession) {
      sessionStorage.removeItem(`ghost-${this.appTenantInfoService.tenantId}`);
      sessionStorage.removeItem(`parent_tenant-${this.appTenantInfoService.tenantId}`);
    }

    this.cookieService.deleteAll();
    this.cookieService.deleteAll('/');
  }

  public redirectToLogin(): void {
    const redirectUrl = urlJoinP(this.appTenantInfoService.tenant.domain, [
      'admin',
      'auth',
    ], {
      redirectUrl: this.router.routerState.snapshot.url,
      client: true,
    });

    if (isPlatformServer(this.platformId)) {
      this.response.redirect(302, redirectUrl);
      this.response.end();
      return;
    }

    window.location.replace(redirectUrl);
  }

  private getAuthToken(idToken: string): Observable<{ result: TokenInfo, fromCache: boolean }> {
    if (!idToken) {
      return of(undefined);
    }

    const token = this.getToken();
    const storedIdToken = this.idToken;

    if (token && storedIdToken === idToken) {
      return of({
        result: token,
        fromCache: true,
      });
    }

    const { DB_URL, DB_URL_VPC } = this.moduleConfig;

    const url = urlJoinP(isPlatformServer(this.platformId) && DB_URL_VPC ? DB_URL_VPC : DB_URL, [
      'auth',
      'firebase-token',
    ], {
      fbAuthToken: idToken,
    });

    return this.http.post<{ result: TokenInfo }>(url, {}, { headers: { Anonymous: 'true' } }).pipe(
      take(1),
      map((response) => (response?.result ? response : { result: response }) as any),
      catchError(err => of({
        result: undefined,
        fromCache: false,
        err,
      }))
    );
  }

  private getToken(): TokenInfo | undefined {
    const cookieKey = `token_${this.appTenantInfoService.tenantId}`;

    const token = isPlatformServer(this.platformId) ? this.request.cookies[cookieKey] : this.cookieService.get(cookieKey);

    return token?.trim().length ? { token } : undefined;
  }

  private setToken(tokenInfo: TokenInfo): TokenInfo {
    const cookieKey = `token_${this.appTenantInfoService.tenantId}`;

    this.addOrUpdateCookie(cookieKey, tokenInfo.token, tokenInfo.expiresAt);

    return tokenInfo;
  }

  private setIdToken(idToken: string): void {
    const cookieKey = `idToken_${this.appTenantInfoService.tenantId}`;

    this.addOrUpdateCookie(cookieKey, idToken);
  }

  private addOrUpdateCookie(key: string, value: string, expiresAt?: number | Date): void {
    if (isPlatformServer(this.platformId)) {
      this.response.cookie(key, value, {
        path: '/',
        maxAge: typeof expiresAt === 'number' ? expiresAt : (expiresAt ?? moment().add(1, 'days').toDate()).getTime(),
        sameSite: 'lax',
      });

      return;
    }

    if (this.cookieService.get(key)) {
      this.cookieService.delete(key);
    }

    this.cookieService.set(key, value, {
      path: '/',
      sameSite: 'Lax',
      expires: expiresAt,
    });
  }

  private removeCookie(key: string, path: string = '/'): void {
    if (isPlatformServer(this.platformId)) {
      this.response.clearCookie(key, { path });
      this.request.cookies[key] = undefined;
      return;
    }

    this.cookieService.delete(key, path);
  }

  private getProfileFromParentTenant(parent_tenant: string): Observable<domainModels.UserMyProfileDomainModel> {
    return this.profileDomainService
      .getAllProfiles()
      .pipe(map((result) => result.find((item) => item.tenantId === parent_tenant)));
  }

  private observeTokenExpiredDate(token: string): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const { exp } = jwtDecode<JwtPayload>(token);
    const expDate = new Date(exp * 1000);
    const timeout = expDate.getTime() - Date.now() - 60 * 1000;

    setTimeout(async() => {
      const authUser = await this.firebaseAuth.currentUser;
      await authUser.getIdToken(true);
    }, timeout);
  }

  getErrorMsgByFbCode(errorCode: string): string {
    if (!errorCode) {
      return null;
    }
    console.log(errorCode);
    let errorMsg: string;

    switch (errorCode) {
      case 'auth/email-already-exists':
      case 'auth/email-already-in-use':
        errorMsg = 'The provided email is already in use by an existing user.';
        break;
      case 'auth/invalid-credential':
        errorMsg = 'Invalid credential.';
        break;
      case 'auth/user-not-found':
        errorMsg = 'User not found. Please check the email and try again.';
        break;
      case 'auth/operation-not-allowed':
        errorMsg = 'The sign-in method is disabled for this project.';
        break;
      case 'auth/too-many-requests':
        errorMsg = 'Too many requests. Please try again later.';
        break;
      case 'auth/id-token-expired':
        errorMsg = 'Your login session has expired. Please log in again.';
        break;
      case 'auth/id-token-revoked':
        errorMsg = 'Your login session has been revoked. Please log in again.';
        break;
      case 'auth/invalid-display-name':
        errorMsg = 'Invalid display name. Please provide a valid display name.';
        break;
      case 'auth/invalid-id-token':
        errorMsg = 'Invalid ID token. Please log in again.';
        break;
      case 'auth/invalid-email':
        errorMsg = 'Invalid email. Please provide a valid email.';
        break;
      case 'auth/invalid-password':
      case 'auth/wrong-password':
        errorMsg = 'Invalid password. Please provide a valid password.';
        break;
      default:
        errorMsg = 'Unknown error. Try again later.';
        break;
    }

    errorMsg += ` ${errorCode}`;

    return errorMsg;
  }
}
