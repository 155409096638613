import {
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
} from '@angular/router';
import { PageDomainModel } from '@jotter3/api-connector';
import {
  AppType,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { ContentBuilderManagerService } from '@jotter3/sites-core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { isNil } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';

import { IMenuItem } from '../../models';
import { TemplateDataProvider } from '../../providers';

enum UrlLinks {
  ADMIN = '/admin/sites/pages/',
  CLIENT = ''
}

@TemplateComponent({
  selector: 'jotter-tplcmp-breadcrumbs',
})
@Component({
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent extends TemplateBaseComponent implements OnInit {
  public urlLinksPre: string = UrlLinks.CLIENT;
  public homePage: IMenuItem | undefined;
  public isHomePage: boolean | undefined;
  private menuItemsValue$: BehaviorSubject<IMenuItem[] | undefined> = new BehaviorSubject<IMenuItem[] | undefined>(undefined);

  constructor(
    private router: Router,
    public dataService: TemplateDataProvider,
    public editorAPI: ContentBuilderManagerService,
    @Inject(MODULE_PROVIDER_TOKEN) public moduleProvider: ModuleProvider
  ) {
    super(BreadcrumbsComponent);
  }

  @Input() showOnHomePage = false;
  @Input() homePageIconOnly = false;
  @Input() separatorValue = 'icon-chevron-right';
  @Input() separatorCustomClass = '';
  @Input() separatorAsText = false;
  @Input() homeIconValue = '';
  @Input() homeIconCustomClass = '';

  get menuItems$(): Observable<IMenuItem[] | undefined> {
    return this.menuItemsValue$;
  }

  public ngOnInit(): void {
    if (this.moduleProvider.applicationType !== AppType.CLIENT) {
      this.urlLinksPre = UrlLinks.ADMIN;
    }

    this.breadcrumbs(this.router.url)
      .pipe(take(1), untilComponentDestroyed(this))
      .subscribe((res) => {
        this.menuItemsValue$.next(res);
      });

    this.router.events
      .pipe(
        filter((ev) => ev instanceof NavigationEnd),
        map((ev) => ev as NavigationEnd),
        switchMap((ev) => this.breadcrumbs(ev.url)),
        untilComponentDestroyed(this)
      )
      .subscribe((result) => {
        this.menuItemsValue$.next(result);
      });
  }

  private breadcrumbs(url: string): Observable<IMenuItem[] | undefined> {
    return this.dataService.getPages().pipe(
      filter((pages) => pages?.length > 0),
      map((pages) => {
        this.homePage = pages.find((item) => item.homePage);
        const urlValue = url.split('/').slice(-1)[0];

        if (urlValue.indexOf('search?phrase') !== -1) {
          const searchItem: any = {
            name: 'search',
            title: 'Search',
            homePage: false,
            parentId: undefined,
          };

          return [searchItem];
        }

        const currentItem = pages.find((item) => item?.name === urlValue);

        this.isHomePage = this.homePage?.url === currentItem?.url && this.homePage?.parentId === null;
        return this.getBreadcrumbsList(pages, currentItem);
      })
    );
  }

  private getBreadcrumbsList(pages: IMenuItem[], currentItem?: PageDomainModel): IMenuItem[] {
    if (!currentItem?.urlSegments) {
      return [];
    }

    return currentItem.urlSegments
      .map((segment) => pages.find((page) => page.name === segment))
      .filter((item) => !isNil(item))
      .map((item) => item as IMenuItem);
  }
}
