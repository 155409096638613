import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { GroupTypesState } from '../reducers';

export const selectGroupTypesListState = createFeatureSelector<GroupTypesState>('groupTypesList');

export const groupTypesListStateSelector = createSelector(selectGroupTypesListState, (state) => state);

export const groupTypesListSelector = createSelector(groupTypesListStateSelector, (state) => {
  const entities = Object.values(state.entities);
  return entities;
});

export const areGroupTypesListLoading = createSelector(groupTypesListStateSelector, (state) => state.isLoading);

export const areGroupTypesListLoaded = createSelector(groupTypesListStateSelector, (state) => state.isLoaded);
