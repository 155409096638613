import { Injectable } from '@angular/core';
import { ApiService } from '@jotter3/wa-core';
import { Store } from '@ngrx/store';

import { PolicyGroupDomainModel } from '../domain-models';
import { BaserDomainService } from './base.domain-service';

@Injectable({ providedIn: 'root' })
export class PoliciesGroupsDomainService extends BaserDomainService<PolicyGroupDomainModel> {
  constructor(apiService: ApiService, private store: Store) {
    super('policy_groups', apiService);
  }
}
