<div
  *ngIf="
    designMode &&
    builderElement &&
    builderElement.instance &&
    (builderElement.instance.validFrom || builderElement.instance.validTo)
  "
  class="toggle-content-enabled"
>
  Date limitation enabled
</div>
<div
  [cdkDragDisabled]="!designMode"
  class="site-content-section-column-items-element-content-presenter"
  cdkDrag
  cdkDragRootElement="element-content-presenter"
>
  <div
    *ngIf="designMode && selected"
    class="settings-overlay"
  >
    <div class="site-content-section-column-items-element-settings-buttons-list">
      <span
        cdkDragHandle
        class="site-content-section-column-items-element-settings-buttons-list-item site-content-section-column-items-element-settings-buttons-list-item--move"
        ngbTooltip="Move Element"
        container="body"
      >
        <mat-icon class="site-content-section-column-items-element-settings-buttons-list-item-icon">open_with</mat-icon>
      </span>
      <button
        (click)="onSettings()"
        class="site-content-section-column-items-element-settings-buttons-list-item"
        ngbTooltip="Element Settings"
        container="body"
      >
        <mat-icon class="site-content-section-column-items-element-settings-buttons-list-item-icon">settings</mat-icon>
      </button>
      <button
        (click)="onRemove()"
        class="site-content-section-column-items-element-settings-buttons-list-item site-content-section-column-items-element-settings-buttons-list-item--delete"
        ngbTooltip="Delete Element"
        container="body"
      >
        <mat-icon class="site-content-section-column-items-element-settings-buttons-list-item-icon">delete</mat-icon>
      </button>
    </div>
  </div>
</div>
