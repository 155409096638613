import {
  inject,
  Injectable,
} from '@angular/core';
import { StoryDomainModel } from '@jotter3/api-connector';
import {
  EntitiesComponentStoreAbstract,
  EntitiesComponentStoreBaseModel,
  LoadingStateEnum,
} from '@jotter3/store-helpers';
import {
  ApiResponse,
  ApiService,
  dataQuery,
  LHSBracketsDataQueryAdapter,
} from '@jotter3/wa-core';
import { Observable } from 'rxjs';
import { urlJoin } from 'url-join-ts';

@Injectable()
export class NewsComponentStore extends EntitiesComponentStoreAbstract<StoryDomainModel, EntitiesComponentStoreBaseModel<StoryDomainModel>> {
  readonly #apiService: ApiService = inject(ApiService);
  readonly #lhsBracketsDataQueryAdapter: LHSBracketsDataQueryAdapter = inject(LHSBracketsDataQueryAdapter);

  constructor() {
    super({
      entities: [],
      loadingState: LoadingStateEnum.PENDING,
    });
  }

  protected override loadData(queryParams: dataQuery.DataQuery): Observable<ApiResponse<StoryDomainModel[]>> {
    return this.#apiService.load<StoryDomainModel>(urlJoin('news', 'with_attachments'), this.#lhsBracketsDataQueryAdapter.toQueryParam(queryParams));
  }
}
