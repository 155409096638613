import { LoadingStateEnum } from '@jotter3/store-helpers';
import { createSelector } from '@ngrx/store';

import { appStoreFeature } from './app.reducer';

const {
  selectLoadingStatus,
  selectDomain,
  selectTenant,
  selectSelectedLanguage,
} = appStoreFeature;

const selectIsLoading = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.LOADING
);

const selectIsLoaded = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.SUCCESS || loadingStatus === LoadingStateEnum.FAILED
);

export const appStoreSelectors = {
  selectDomain,
  selectIsLoaded,
  selectIsLoading,
  selectLoadingStatus,
  selectTenant,
  selectSelectedLanguage,
};
