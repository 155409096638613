import {
  EntityCollectionReducerMethods,
  EntityDefinition,
} from '@ngrx/data';

import {
  EntityActionWithPagination as Action,
  EntityCollectionWithPagination as Collection,
} from './pagination.model';

export class AdditionalEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
  constructor(public override entityName: string, public override definition: EntityDefinition<T>) {
    super(entityName, definition);
  }

  protected override queryManySuccess(collection: Collection<T>, action: Action<T[]>): Collection<T> {
    return this.withPagination(super.queryLoadSuccess(collection, action), action);
  }

  protected override queryAllSuccess(collection: Collection<T>, action: Action<T[]>): Collection<T> {
    return this.withPagination(super.queryLoadSuccess(collection, action), action);
  }

  protected override queryLoadSuccess(collection: Collection<T>, action: Action<T[]>): Collection<T> {
    return this.withPagination(super.queryLoadSuccess(collection, action), action);
  }

  private withPagination(collection: Collection<T>, action: Action<T[]>): Collection<T> {
    if (action.payload.pagination) {
      collection.pagination = action.payload.pagination;
    }

    return collection;
  }
}
