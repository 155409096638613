import { Operator } from './operator.model';

export enum FilterType {
  SEARCH = 'search',
  DATE = 'date',
  BOOLEAN = 'boolean',
  NUMERIC = 'numeric',
  SELECT = 'select',
  IS_NULL = 'isNull',
  EMPTY = 'empty'
}

export interface PropertyFilterType {
  property: string;
  filterType?: FilterType;
}

export interface Filter<Value = unknown> {
  type: FilterType;
  property: string;
  value: Value;
  operator: Operator;
}

export type Filters = Filter[];
