import {
  Injector,
  NgModule,
} from '@angular/core';
import {
  ApiConnectorModule,
  common,
  Effects,
  Reducers,
  State,
} from '@jotter3/api-connector';
import {
  EntityDataModule,
  EntityDataService,
} from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import {
  MinimalRouterStateSerializer,
  RouterState,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';

@NgModule({
  imports: [
    EffectsModule.forRoot(Effects),
    StoreModule.forRoot(Reducers, { ...common.genenrateStoreModuleConfig<State>() }),
    EntityDataModule.forRoot({ ...common.EntityDataConfig }),
    StoreDevtoolsModule.instrument({
      maxAge: 10,
      name: 'SJ3 - SSR',
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: MinimalRouterStateSerializer,
      routerState: RouterState.Minimal,
    }),
    ApiConnectorModule.forRoot({ ...environment.ApiConnectorConfig }),
  ],
})
export class ApiConnectorInitializeModule {
  constructor(entityDataService: EntityDataService, injector: Injector) {
    ApiConnectorModule.registerExtendedCollectionServices(entityDataService, injector);
  }
}
