<h2 class="text-center" *ngIf="header?.length">
  {{ header | translate }}
</h2>
<div class="d-flex">
  <button
    class="btn btn-primary m-2"
    *ngIf="designMode"
    [disabled]="subdirTrackArray.length"
    (click)="onOpenFileManager()"
  >
    Add files
  </button>
  <button
    class="btn btn-primary m-2"
    *ngIf="designMode"
    [ngClass]="orderByName ? 'btn-secondary' : 'btn-outline-secondary'"
    [disabled]="subdirTrackArray.length"
    (click)="toggleSorting()"
  >
    {{ orderByName ? 'Disable sorting by name' : 'Sort files by name' }}
  </button>
</div>
<table
  class="table files-list"
  #filesListContainer
  [ngClass]="[tableLayoutMobile ? 'files-list-mobile' : '']"
>
  <thead>
    <tr [ngClass]="[tableLayoutMobile ? 'd-none' : '']">
      <th></th>
      <th>{{ 'Name' | translate }}</th>
      <th>{{ 'Format' | translate }}</th>
      <th></th>
    </tr>
    <tr [ngClass]="[tableLayoutMobile ? '' : 'd-none']">
      <th>{{ 'Files' | translate }}</th>
    </tr>
  </thead>
  <tbody (cdkDropListDropped)="onFileReorder($event)" cdkDropList>
    <tr class="progress-indicator" [class.hidden]="!loading">
      <td class="p-0" colspan="4">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </td>
    </tr>
    <tr
      class="directory"
      *ngIf="subdirTrackArray.length"
      (click)="onGoUpFromSubdirectory()"
    >
      <td class="icon"><i class="fas fa-arrow-up"></i></td>
      <td colspan="3">...</td>
    </tr>
    <tr *ngIf="!loading && (!items || !(items | async)?.length)">
      <td class="justify-content-center text-center" colspan="4">
        {{ 'No data' | translate }}
      </td>
    </tr>
    <tr
      *ngFor="let resource of items | async; let i = index"
      [ngClass]="{ directory: resource.file.directory }"
      [cdkDragDisabled]="
        !designMode ||
        (orderByName && designMode) ||
        subdirTrackArray.length > 0
      "
      (click)="onGoInsideDirectory(resource)"
      cdkDrag
    >
      <td class="icon">
        <i class="fas fa-{{ getResourceIcon(resource.file) }}"></i>
      </td>
      <td>
        {{ resource.file.name | fileInfo : fileInfoType.NAME
        }}{{
          resource?.file?.directory && !tableLayoutMobile
            ? ''
            : (resource.file.name | fileInfo : fileInfoType.EXTENSION)
        }}
      </td>
      <td [ngClass]="[tableLayoutMobile ? 'd-none' : '']">
        {{
          resource?.file?.directory
            ? ''
            : (resource.file.name | fileInfo : fileInfoType.EXTENSION)
        }}
      </td>
      <td class="actions pr-2">
        <i
          class="action-ico-button fas fa-arrow-up mx-2"
          *ngIf="
            designMode &&
            !firstItemOfArray(resource) &&
            !this.orderByName &&
            !subdirTrackArray.length
          "
          (click)="moveItemsInArray(i, i - 1, true)"
          title="Move up"
        ></i>
        <i
          class="action-ico-button fas fa-arrow-down mx-2"
          *ngIf="
            designMode &&
            !lastItemOfArray(resource) &&
            !this.orderByName &&
            !subdirTrackArray.length
          "
          (click)="moveItemsInArray(i, i + 1, true)"
          title="Move down"
        ></i>
        <i
          class="action-ico-button fas fa-download mx-2"
          *ngIf="!resource?.file?.directory"
          (click)="onDownloadFile(resource.file)"
          title="Download"
        ></i>
        <i
          class="action-ico-button fas fa-eye mx-2"
          *ngIf="!resource?.file?.directory"
          (click)="onPreviewFile(resource.file)"
          title="Preview"
        ></i>
        <i
          class="action-ico-button remove fas fa-trash-alt mx-2"
          *ngIf="designMode && !subdirTrackArray.length"
          (click)="onRemoveFile(resource)"
          title="Remove"
        ></i>
      </td>
    </tr>
  </tbody>
  <div *cdkDragPlaceholder></div>
</table>
<div class="d-flex justify-content-center">
  <ngb-pagination
    *ngIf="resources?.length > itemsPerPage && (items | async)"
    [(page)]="currentPage"
    [pageSize]="itemsPerPage"
    [collectionSize]="resources?.length"
    (pageChange)="onPageChange($event)"
  ></ngb-pagination>
</div>
