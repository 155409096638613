import {
  AfterViewInit,
  Component,
  inject,
} from '@angular/core';
import { domainModels } from '@jotter3/api-connector';
import { ResourcesManagerService } from '@jotter3/common-components';
import {
  FileDownloadService,
  J3TranslateService,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { ApiService } from '@jotter3/wa-core';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { isEqual } from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import {
  filter,
  map,
} from 'rxjs/operators';

import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'embed-document-content-component',
  icon: 'document',
  category: SiteComponentCategory.MEDIA,
  displayName: 'Document',
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './embed-document.component.html',
  styleUrls: ['./embed-document.component.scss'],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class EmbedDocumentSiteElementComponent extends JotterSitesBaseComponent implements AfterViewInit {
  public currentPage = 1;
  public documentZoom = 0.9;
  public maxPages: number = null;
  private headerValue: string;
  private documentSrcValue: domainModels.IFileDomainModel = null;
  private documentResourceId: string;

  private pdfComponent: any;

  readonly #fileDownloadService: FileDownloadService = inject(FileDownloadService);
  readonly #moduleProvider: ModuleProvider = inject(MODULE_PROVIDER_TOKEN);

  constructor(
    private apiService: ApiService,
    private deviceService: DeviceDetectorService,
    private readonly resourcesManager: ResourcesManagerService
  ) {
    super(EmbedDocumentSiteElementComponent);
    if (this.platformBrowser) {
      import('ng2-pdf-viewer').then(module => {
        this.pdfComponent = module.PdfViewerComponent;
      }).catch(err => {
        console.error('Error loading PdfViewerComponent', err);
      });
    }
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
    templateOptions: {
      required: false,
      placeholder: 'Enter header',
    },
  })
  public get header(): string {
    return this.headerValue;
  }
  public set header(value: string) {
    this.headerValue = value;
  }

  @Property({
    displayName: 'Document',
    modifierType: ModifierType.FILEUPLOAD,
    templateOptions: {
      label: 'Document',
      required: true,
      placeholder: 'Select document',
      allowTypes: [2],
      multipleItems: false,
      getUrl: true,
    },
    required: true,
  })
  public get documentSRC(): domainModels.IFileDomainModel {
    return this.documentSrcValue;
  }
  public set documentSRC(value: domainModels.IFileDomainModel) {
    if (isEqual(this.documentSrcValue, value)) {
      return;
    }
    if (Array.isArray(value)) {
      value = value.length ? value[0] : undefined;
    }

    if (this.documentSrcValue?.id === value?.id) {
      return;
    }

    this.documentSrcValue = value;
    if (!value || !this.designMode || !this.viewInitialized) {
      return;
    }

    this.addToResources(value.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((resourceId) => {
        this.documentResourceId = resourceId;
      });
  }

  @Property({
    displayName: 'Height',
    modifierType: ModifierType.UNIT_INPUT,
    required: true,
    templateOptions: {
      min: 100,
    },
  })
  public documentHeight = '400px';

  public search(stringToSearch: string): void {
    if (!this.platformBrowser) {
      this.pdfComponent.eventBus.dispatch('find', {
        query: stringToSearch,
        type: 'again',
        caseSensitive: false,
        findPrevious: undefined,
        highlightAll: true,
        phraseSearch: true,
      });
    }
  }

  public override setDataset(dataset: { [key: string]: any } = {}): void {
    super.setDataset(dataset);
    if (dataset.resourceId) {
      this.loadFromResource(dataset.resourceId)
        .pipe(untilComponentDestroyed(this))
        .subscribe(({ file }) => {
          this.documentResourceId = dataset.resourceId;
          return (this.documentSrcValue = file as domainModels.IFileDomainModel);
        });
    }
    if (this.designMode && !dataset.resourceId && dataset.documentSRC) {
      if (Array.isArray(dataset.documentSRC)) {
        dataset.documentSRC = dataset.documentSRC?.length ? dataset.documentSRC[0] : undefined;
      }

      this.addToResources(dataset.documentSRC.id)
        .pipe(untilComponentDestroyed(this))
        .subscribe((resourceId) => {
          this.documentResourceId = resourceId;
        });
    }
  }

  public override getDataset(): { [key: string]: any } {
    const dataset = super.getDataset();
    const { documentSRC, ...rest } = dataset;
    rest.resourceId = this.documentResourceId;

    return rest;
  }

  public afterLoadComplete(pdf: any): void {
    this.maxPages = pdf.numPages;
  }

  public zoomDocument(zoomIn: boolean): void {
    const maxZoom = 2.4;
    const minZoom = 0.1;
    if (zoomIn) {
      this.documentZoom = this.documentZoom + 0.1 > maxZoom ? maxZoom : this.documentZoom + 0.1;
    } else {
      this.documentZoom = this.documentZoom - 0.1 < minZoom ? minZoom : this.documentZoom - 0.1;
    }
  }

  public zoomReset(): void {
    this.documentZoom = 0.9;
  }

  public checkPageValue(pageValue: string): void {
    this.currentPage = pageValue === '' ? 1 : this.currentPage;
  }

  public changePage(next: boolean): void {
    if (next) {
      this.currentPage++;
    } else {
      this.currentPage--;
    }
  }

  public heightTypeInPx(documentHeight: string | number): string {

    if (typeof documentHeight === 'number') {
      return `${documentHeight}px`;
    }
    return documentHeight;
  }

  public onDownloadFile(): void {
    this.#fileDownloadService.onDownloadFile(this.documentSRC.name, this.documentSRC?.url?.pdf || this.documentSRC?.document?.pdf);
  }

  private addToResources(file: string): Observable<string> {
    const reqModel: Partial<domainModels.ResourceDomainModel> = { file };

    return this.resourcesManager.setResource(reqModel, 'SINGLE');
  }

  private loadFromResource(resourceId: string): Observable<domainModels.ResourceDomainModel> {
    return this.resourcesManager.getResource(resourceId, 'SINGLE').pipe(
      map(res => res as domainModels.ResourceDomainModel),
      filter((result) => !!result)
    );
  }
}
