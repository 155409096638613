import {
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { isNil } from 'lodash-es';
import * as moment from 'moment-timezone';

export const isDateValid = (control: AbstractControl): boolean | null => {
  const { value } = control;

  if (isNil(value)) {
    return true;
  }
  const momentDate = moment(value).startOf('day').valueOf();

  return !!momentDate;
};

export const dateValidator = (control: AbstractControl): ValidationErrors | null => {
  const { validFrom, validTo } = control.value;

  const dateFrom = validFrom ? moment(validFrom).startOf('day').valueOf() : null;

  const dateTo = validTo ? moment(validTo).endOf('day').valueOf() : null;

  const dateFromValid = dateFrom && dateTo ? dateTo > dateFrom : true;
  const dateToValid = dateFrom && dateTo ? dateTo > dateFrom : true;

  return dateFromValid && dateToValid
    ? null
    : {
      J3BaseContentComponentDatesValidator: {
        message: 'Wrong dates',
      },
    };
};

export const isDateInBorders = (dateToCheck: string, dateFrom?: string, dateTo?: string): boolean => {
  const toCheck = moment(dateToCheck);

  const isValidFrom = isNil(dateFrom) ? true : toCheck.isSameOrAfter(moment(dateFrom), 'day');
  const isValidTo = isNil(dateTo) ? true : toCheck.isSameOrBefore(dateTo, 'day');

  return isValidFrom && isValidTo;
};
