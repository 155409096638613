import {
  inject,
  Injectable,
} from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  switchMap,
} from 'rxjs/operators';

import { PolicyGroupDomainModel } from '../../../domain-models';
import { PoliciesListDomainService } from '../../../domain-services';
import { policiesListActions } from '../actions';

@Injectable()
export class PoliciesListEffects {
  readonly #policiesListDomainService: PoliciesListDomainService = inject(PoliciesListDomainService);

  LoadPoliciesList = createEffect(() =>
    this.actions$.pipe(
      ofType(policiesListActions.LoadPoliciesList),
      switchMap((action) => {
        const { group } = action;
        return this.#policiesListDomainService.getPolicyGroup(group).pipe(
          switchMap((response) => {
            const observableApiCalls: Observable<PolicyGroupDomainModel> = of(response.result);
            return observableApiCalls.pipe(
              switchMap((responseArray) =>
                of(
                  policiesListActions.LoadPoliciesListComplete({
                    policies: responseArray.policies.map((x) => x).flat(),
                  })
                )),
              catchError((error) => of(policiesListActions.LoadPoliciesListFailed({ error })))
            );
          }),
          catchError((error) => of(policiesListActions.LoadPoliciesListFailed({ error })))
        );
      })
    ));

  constructor(
    private actions$: Actions
  ) {}
}
