import {
  inject,
  Injectable,
} from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  catchError,
  map,
  of,
  switchMap,
} from 'rxjs';

import { GdprSettingsDomainService } from '../../domain-services';
import { GdprStoreActions } from './gdpr.actions';

@Injectable()
export class GdprEffects {
  readonly #actions$ = inject(Actions);
  readonly #domainService: GdprSettingsDomainService = inject(GdprSettingsDomainService);

  loadRequested$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(GdprStoreActions.loadTrigger),
      switchMap(() => this.#domainService.get('')),
      map((response) => response.success
        ? GdprStoreActions.loadSuccess({ entity: response.result })
        : GdprStoreActions.loadFailed({ errors: [response.error] }),
      catchError(err => of(GdprStoreActions.loadFailed({ errors: [err] }))))
    ));

  upsertRequested$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(GdprStoreActions.upsertTrigger),
      switchMap(({ entity }) => this.#domainService.put(entity)),
      map((result) => GdprStoreActions.upsertSuccess({ entity: result })),
      catchError((error) => of(GdprStoreActions.upsertFailed({ errors: [error] })))
    ));
}
