import { CalendarView } from '@jotter3/calendar';
import { DropdownItemModel } from '@jotter3/sites-abstract';

import { LayoutType } from '../../enums';

export enum CALENDAR_EVENT_STYLE {
  STYLE_NORMAL = 1,
  STYLED_ASIDE = 2
}

export const DEFAULTS = {
  HEADER: 'Calendar',
  DEFAULT_VIEW: CalendarView.DAY_GRID_MONTH,
  EVENTS_COUNT: 5,
  ALLOW_EVENTS_FILTER: false,
  CALENDAR_EVENT_STYLE: CALENDAR_EVENT_STYLE.STYLE_NORMAL,
  LAYOUT: LayoutType.NORMAL,
  DISPLAYED_SLIDES: 2,
  SLIDE_TRANSITION: 5000,
  SHOW_INDICATORS: false,
  SHOW_ARROWS: false,
  SHOW_CATEGORY_FILTER: false,
  MAX_EVENTS_COUNT: 5,
};

export const VIEW_DROPDOWN_OPTIONS: Array<DropdownItemModel<CalendarView, string>> = [
  {
    value: CalendarView.DAY_GRID_MONTH,
    label: 'Month',
  },
  {
    value: CalendarView.TIME_GRID_WEEK,
    label: 'Week',
  },
  {
    value: CalendarView.TIME_GRID_DAY,
    label: 'Day',
  },
  {
    value: CalendarView.LIST_WEEK,
    label: 'List',
  },
  {
    value: CalendarView.EVENTS_LIST,
    label: 'Events List',
  },
];

export const SHOW_EVENTS_DROPDOWN_OPTIONS: Array<DropdownItemModel<number, string>> = [
  {
    label: '5 days',
    value: 5,
  },
  {
    label: '10 days',
    value: 10,
  },
  {
    label: '15 days',
    value: 15,
  },
  {
    label: '30 days',
    value: 30,
  },
  {
    label: '60 days',
    value: 60,
  },
  {
    label: '90 days',
    value: 90,
  },
];

export const EVENT_STYLE_DROPDOWN_OPTIONS: Array<DropdownItemModel<CALENDAR_EVENT_STYLE, string>> = [
  {
    label: 'Standard',
    value: CALENDAR_EVENT_STYLE.STYLE_NORMAL,
    icon: 'style_normal',
  },
  {
    label: 'With stylised date',
    value: CALENDAR_EVENT_STYLE.STYLED_ASIDE,
    icon: 'style_aside',
  },
];

export const LAYOUT_DROPDOWN_OPTIONS: Array<DropdownItemModel<LayoutType, string>> = [
  {
    label: 'Normal',
    value: LayoutType.NORMAL,
  },
  {
    label: 'Carousel',
    value: LayoutType.CAROUSEL,
  },
];

export const DISPLAYED_SLIDES_DROPDOWN_OPTIONS: Array<DropdownItemModel<number, string>> = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '16',
    value: 16,
  },
];

export const SLIDE_TRANSITION_TIME_DROPDOWN_OPTIONS: Array<DropdownItemModel<number, string>> = [
  {
    label: '5s',
    value: 5000,
  },
  {
    label: '10s',
    value: 10000,
  },
  {
    label: '15s',
    value: 15000,
  },
  {
    label: '20s',
    value: 20000,
  },
];
