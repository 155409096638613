import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createAction,
  props,
} from '@ngrx/store';

import {
  PageDomainModel,
  PagesContentDomainModel,
} from '../../../domain-models';

const LoadPageContent = createAction('[Page content] Load data', props<{ url?: string, revisionId?: string }>());
const LoadPageContentComplete = createAction('[Page content] Load data Complete', props<{ page: PageDomainModel, pageContent: PagesContentDomainModel, pageUrl: string }>());
const LoadPageContentFailed = createAction('[Page content] Load data failed', props<{ error: ApiErrorResponse }>());

const UpdatePageContent = createAction('[Page content] Update', props<{ pageContent: PagesContentDomainModel }>());

export { LoadPageContent,
  LoadPageContentComplete,
  LoadPageContentFailed,
  UpdatePageContent };
