import { isPlatformServer } from '@angular/common';
import {
  Component,
  inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { EventInput } from '@fullcalendar/core';
import SwiperCore, {
  A11y,
  Autoplay,
  Controller,
  Navigation,
  Pagination,
  Scrollbar,
  SwiperOptions,
  Thumbs,
  Virtual,
  Zoom,
} from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'j3-events-carousel',
  templateUrl: './events-carousel.component.html',
})
export class EventsCarouselComponent implements OnInit {
  @Input() elTemplate: number;
  @Input() events: EventInput;
  @Input() eventsConfig: SwiperOptions = {};
  @Input() showIndicators: boolean;
  @Input() showArrows: boolean;
  @Input() slidesVisible: number;

  readonly #platformId: object = inject(PLATFORM_ID);

  @ViewChild('eventsListSwiper') eventsListSwiper?: SwiperComponent;

  public ngOnInit(): void {

    if (isPlatformServer(this.#platformId)) {
      return;
    }

    this.initSwiperCore();

  }

  public nextSlide(): void {
    this.eventsListSwiper?.swiperRef.slideNext();
  }

  public prevSlide(): void {
    this.eventsListSwiper?.swiperRef.slidePrev();
  }

  private initSwiperCore(): void {
    SwiperCore.use([
      Navigation,
      Pagination,
      Scrollbar,
      A11y,
      Virtual,
      Zoom,
      Autoplay,
      Thumbs,
      Controller,
    ]);
  }
}
