import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import {
  AfterViewInit,
  Component,
} from '@angular/core';
import { LeafletService } from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  isNil,
  isUndefined,
} from 'lodash-es';
import { v4 as UUID } from 'uuid';

import { GeoJson } from '../../models/geojson.model';
import { postCodeValidators } from '../../validators';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';
import { mapConfig } from './map-configuration';

@SiteComponent({
  selector: 'map-component',
  displayName: 'Map',
  icon: 'location',
  category: SiteComponentCategory.OTHER,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapSiteElementComponent extends JotterSitesBaseComponent implements AfterViewInit {
  private map: any;
  public ukPostcode: string;
  private coordinates: number[][];
  public readonly id: string = UUID();
  private marker: any = null;
  private prevPostcode: string = null;

  public heightValue = '';
  public widthValue = '';
  private information = '';
  private mapInitialized = false;
  private leafletData: any;

  constructor(
    private http: HttpClient,
    private leafletServie: LeafletService
  ) {
    super(MapSiteElementComponent);
  }

  @Property({
    displayName: 'Post Code',
    modifierType: ModifierType.TEXT,
    required: true,
    validates: {
      postcodeCheck: {
        expression: postCodeValidators.UKPostcodeValidator,
        message: 'Wrong postcode format',
      },
    },
  })
  get postcode(): string {
    return this.ukPostcode;
  }

  set postcode(value: string) {
    if (this.ukPostcode) {
      this.prevPostcode = this.ukPostcode;
    }
    const code = value.replace(/\s/g, '');
    this.ukPostcode = code.replace(/^(.*)(\d)/, '$1 $2');
    if (this.platformServer) {
      return;
    }
    if (isNil(this.leafletData)) {
      this.importLeaflet();
    } else {
      this.getDataFromApi();
    }
  }

  @Property({
    displayName: 'Information',
    modifierType: ModifierType.TEXT,
    required: false,
  })
  get markerInformation(): string {
    return this.information;
  }

  set markerInformation(value: string) {
    this.information = value;
    if (this.platformServer) {
      return;
    }
  }

  @Property({
    modifierType: ModifierType.UNIT_INPUT,
    displayName: 'Width',
    units: [
      '%',
      'px',
    ],
  })
  get cssWidth(): string {
    return this.widthValue;
  }

  set cssWidth(value: string) {
    this.widthValue = value;
    if (!this.mapInitialized) {
      return;
    }
  }

  @Property({
    modifierType: ModifierType.UNIT_INPUT,
    displayName: 'Height',
    units: [
      '%',
      'px',
    ],
  })
  public get cssHeight(): string {
    return this.heightValue;
  }

  set cssHeight(value: string) {
    this.heightValue = value;
    if (!this.mapInitialized) {
      return;
    }
  }

  @Property({
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: 'Left',
        value: 'mr-auto',
        icon: 'align_left',
      },
      {
        label: 'Center',
        value: 'mx-auto',
        icon: 'align_center',
      },
      {
        label: 'Right',
        value: 'ml-auto',
        icon: 'align_right',
      },
    ],
    clearable: false,
    displayName: 'Align',
    defaultValue: 'mx-auto',
  })
    align = 'mx-auto';

  public override afterSettingsSaved(): void {
    setTimeout(() => {
      this.refreshMap();
    });
  }

  public override ngAfterViewInit(): void {
    if (this.platformServer) {
      return;
    }
    this.importLeaflet();
  }

  private importLeaflet(): void {
    this.leafletServie
      .loadMap()
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: (leaflet) => {
          this.leafletData = leaflet;
          this.getDataFromApi();
        },
        error: (error) => {
          console.log('Error: ', error);
        },
      });
  }

  private initMap(lan: number, lon: number): void {
    const container = this.leafletData.DomUtil.get(`map-${this.id}`);
    if (container != null) {
      container._leaflet_id = null;
    }
    const tiles = this.leafletData.tileLayer(mapConfig.tileUrl, {
      maxZoom: 19,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    const icon = this.leafletData.icon({
      iconUrl: './assets/marker-icon.png',
      iconSize: [
        25,
        41,
      ],
      iconAnchor: [
        12.5,
        41,
      ],
    });
    this.marker = this.leafletData.marker([
      lon,
      lan,
    ], { icon });
    this.updatePopup();

    this.map = this.leafletData.map(`map-${this.id}`, {
      center: [
        lon,
        lan,
      ],
      zoom: 16,
    });
    tiles?.addTo(this.map);
    this.map?.attributionControl.setPrefix(false);
    this.map?.on('moveend', () => {
      this.marker = this.leafletData.marker([
        lon,
        lan,
      ], { icon });
    });
    this.marker?.addTo(this.map);
  }

  private updateMap(lan: number, lon: number): void {
    this.map.setView([
      lon,
      lan,
    ], 16);
    if (this.marker) {
      this.marker.setLatLng([
        lon,
        lan,
      ]);
    } else {
      this.marker = this.leafletData.marker([
        lon,
        lan,
      ]);
    }
    this.updatePopup();
    this.marker?.addTo(this.map);
  }

  private updatePopup(): void {
    if (this.markerInformation !== '') {
      this.marker?.bindPopup(this.markerInformation).openPopup();
    } else {
      this.marker?.unbindPopup();
    }
  }

  private refreshMap(): void {
    this.map.invalidateSize();
  }

  private getDataFromApi(): void {
    let params = new HttpParams();
    params = params.append('q', this.ukPostcode);
    params = params.append('format', 'geojson');

    this.subscriptions.push(
      this.http.get(mapConfig.apiUrl, { params }).subscribe((res) => {
        const response = res as GeoJson;
        if (!response?.features?.length) {
          this.ukPostcode = this.prevPostcode;
          return;
        }

        this.coordinates = response.features.map((item) => item.geometry.coordinates);
        if (isUndefined(this.map)) {
          this.initMap(this.coordinates[0][0], this.coordinates[0][1]);
        } else {
          this.updateMap(this.coordinates[0][0], this.coordinates[0][1]);
        }
        this.mapInitialized = true;
      })
    );
  }
}
