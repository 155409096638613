import {
  Component,
  OnDestroy,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import {
  domainModels,
  pagesListState,
  SearchResultDomainModel,
  siteSearchState,
} from '@jotter3/api-connector';
import { J3TranslateService } from '@jotter3/common-helpers';
import { SiteComponent } from '@jotter3/sites-abstract';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  Observable,
  of,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { ContentDataProvider } from '../../providers';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'jotter-cmp-search-result',
  displayName: 'Search result',
  selectedByUser: false,
})
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'jotter-cmp-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class SearchResultComponent extends JotterSitesBaseComponent implements OnDestroy {
  private siteSearchIsLoadingObserver: Observable<boolean>;
  private searchPhraseObserver: Observable<string>;
  private searchResultObserver: Observable<(SearchResultDomainModel & { page: domainModels.PageDomainModel })[]>;
  public phrase: string;

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private contentProvider: ContentDataProvider
  ) {
    super(SearchResultComponent);
  }

  public get isLoading(): Observable<boolean> {
    if (this.siteSearchIsLoadingObserver) {
      this.siteSearchIsLoadingObserver = this.store.select(siteSearchState.siteSearchSelectors.siteSearchIsLoadingSelector);
    }

    return this.siteSearchIsLoadingObserver;
  }

  public get searchPhrase(): Observable<string> {
    if (!this.searchPhraseObserver) {
      this.searchPhraseObserver = this.activatedRoute.queryParams.pipe(
        untilComponentDestroyed(this),
        distinctUntilChanged(),
        filter((params) => !!params?.['phrase']),
        map((params) => params['phrase']),
        tap((phrase) => {
          this.phrase = phrase;
          this.store.dispatch(siteSearchState.siteSearchActions.SiteSearchPhraseChange({ phrase }));
        })
      );
    }

    return this.searchPhraseObserver;
  }

  public get searchResult(): Observable<SearchResultDomainModel[]> {
    if (!this.searchResultObserver) {
      this.searchResultObserver = this.store.select(siteSearchState.siteSearchSelectors.siteSearchResultSelector).pipe(
        untilComponentDestroyed(this),
        map((result) => result || []),
        switchMap((result) =>
          this.store.select(pagesListState.pagesSelectors.pagesListSelector).pipe(
            switchMap((pages) =>
              of(
                result
                  .map((item) => ({
                    ...item,
                    page: pages.find((x) => x.id === item.pageId),
                  }))
                  .filter((x) => !!x.page)
              ))
          ))
      );
    }

    return this.searchResultObserver;
  }

  public getPageUrlString(page: domainModels.PageDomainModel): string {
    return this.platformBrowser ? [
      window.location.origin,
      ...(page.urlSegments || []),
    ].join('/') : '';
  }

  public onSearch(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { phrase: this.phrase },
      replaceUrl: true,
    });
  }

  public generateUrl(page: domainModels.PageDomainModel): string {
    return this.router.createUrlTree([
      this.contentProvider.appEditorUrlLinks || '',
      page.urlSegments || [],
    ].flat()).toString();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.store.dispatch(siteSearchState.siteSearchActions.SiteSearchClearState());
  }
}
