import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { appStoreFeature } from './app.reducer';

@NgModule({
  imports: [StoreModule.forFeature(appStoreFeature)],
})
export class AppStoreModule {

}
