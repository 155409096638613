import 'reflect-metadata';

import { PropertyMetadataModel } from '../models';
import {
  PROPERTIES_META_KEY,
  PROPERTY_META_KEY,
} from './constant';

export const Property = (metadata: PropertyMetadataModel): PropertyDecorator => {
  const propertyDecorator = (target: object, propertyKey: string): void => {
    const properties: string[] = Reflect.getOwnMetadata(PROPERTIES_META_KEY, target.constructor) || [];
    if (properties.indexOf(propertyKey) === -1) {
      properties.push(propertyKey);
    }
    Reflect.defineMetadata(PROPERTIES_META_KEY, properties, target.constructor);

    const propertyTypeMetadata = Reflect.getMetadata('design:type', target, propertyKey);
    if (propertyTypeMetadata) {
      Object.assign(metadata, { _propertyType: propertyTypeMetadata.name });
    }

    return Reflect.defineMetadata(PROPERTY_META_KEY, metadata, target.constructor, propertyKey);
  };

  return propertyDecorator as any;
};
