<ng-container *ngIf="showErr; else mapContainer">
  <div
    [ngClass]="{ showErr: showErr }"
    class="map-container"
  >
    <h5>{{ 'Passed wrong postcode' | translate }}</h5>
  </div>
</ng-container>
<ng-template #mapContainer>
  <div
    [ngClass]="{ showErr: showErr }"
    class="map-container"
  >
    <div class="map-frame">
      <div
        id="footer-map-component"
        class="map"
      ></div>
    </div>
  </div>
</ng-template>
