import {
  inject,
  Injectable,
} from '@angular/core';
import {
  arrayHelpers,
  dataQuery,
  LHSBracketsDataQueryAdapter,
} from '@jotter3/wa-core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  catchError,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';

import {
  ResourceDomainModel,
  ResourceListDomainModel,
  ResourceResponse,
} from '../../../domain-models';
import {
  ResourcesDomainService,
  ResourcesListDomainService,
} from '../../../domain-services';
import { pageContentResourcesActions } from '../actions';



@Injectable()
export class PageContentResourcesEffects {
  readonly #actions$ = inject(Actions);
  readonly #resourcesService = inject(ResourcesDomainService);
  readonly #resourcesListDomainService = inject(ResourcesListDomainService);
  readonly #resourcesDomainService = inject(ResourcesDomainService);
  readonly #queryAdapter = inject(LHSBracketsDataQueryAdapter);

  LoadPageContentResources = createEffect(() => this.#actions$.pipe(
    ofType(pageContentResourcesActions.LoadPageContentResources),
    switchMap(({ resourceIds, resourceListIds }) => {
      if (!resourceIds?.length && ! resourceListIds?.length) {
        return of(pageContentResourcesActions.NoResourcesFound());
      }

      const apiRequests: Array<Observable<ResourceResponse>> = [
        ...this.#createResourcesRequests(resourceIds),
        ...this.#createResourceListRequests(resourceListIds),
      ];


      return forkJoin(apiRequests).pipe(
        map(results => {
          return [
            results?.filter(x => x.type === 'SINGLE').map(res => res.apiResponse?.result).flat(),
            results?.filter(x => x.type === 'LIST').map(res => res.apiResponse?.result).flat(),
          ];
        }),
        map(([
          resources,
          resourceLists,
        ]) => {
          for (const resource of resources) {
            this.#resourcesDomainService.set(resource.id, resource as ResourceDomainModel);
          }
          for (const resource of resourceLists) {
            this.#resourcesListDomainService.set(resource.id, resource as ResourceListDomainModel);
          }

          return pageContentResourcesActions.LoadPageContentResourcesComplete({
            resources: [
              ...resources,
              ...resourceLists,
            ],
          });
        }),
        catchError(error => of(pageContentResourcesActions.LoadPageContentResourcesFailed({ error })))
      );
    })
  ));

  #createResourcesRequests(ids: string[]): Array<Observable<ResourceResponse>> {
    const apiRequests: Array<Observable<ResourceResponse>> = new Array<Observable<ResourceResponse>>();
    for (const chunk of arrayHelpers.chunk(50, ids)) {

      const apiParams: dataQuery.DataQuery = {
        filters: [
          {
            operator: dataQuery.Operator.IN,
            property: 'id',
            type: dataQuery.FilterType.SELECT,
            value: chunk,
          },
          {
            operator: dataQuery.Operator.IS_NULL,
            property: 'resourceList',
            type: dataQuery.FilterType.IS_NULL,
            value: 1,
          },
        ],
        pagination: {
          size: 50,
          page: 1,
        },
      };


      apiRequests.push(this.#resourcesService.getAll(this.#queryAdapter.toQueryParam(apiParams)).pipe(
        map(res => ({
          type: 'SINGLE',
          apiResponse: res,
        }))
      ));
    }

    return apiRequests;
  }

  #createResourceListRequests(ids: string[]): Array<Observable<ResourceResponse>> {
    const apiRequests: Array<Observable<ResourceResponse>> = new Array<Observable<ResourceResponse>>();
    for (const chunk of arrayHelpers.chunk(50, ids)) {

      const apiParams: dataQuery.DataQuery = {
        filters: [
          {
            operator: dataQuery.Operator.IN,
            property: 'id',
            type: dataQuery.FilterType.SELECT,
            value: chunk,
          },
        ],
        pagination: {
          size: 50,
          page: 1,
        },
      };


      apiRequests.push(this.#resourcesListDomainService.getAll(this.#queryAdapter.toQueryParam(apiParams)).pipe(
        map(res => ({
          type: 'LIST',
          apiResponse: res,
        }))
      ));
    }

    return apiRequests;
  }
}
