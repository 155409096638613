<ng-container *ngIf="$headTeacher | async; let headTeacherValue">
  <span
    *ngIf="iconValue"
    [ngClass]="{
      'school-data-icon-toggle':
        itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE
    }"
    (click)="toggle()"
    class="{{ iconCustomClass }} school-data-icon"
  >
    <i class="school-data-icon--i {{ iconValue }}"></i>
  </span>

  <div
    [ngClass]="{
      'school-data-headteacher-toggle': itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE,
      outside: itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE,
      inside: itemType === ItemTypeEnum.TOGGLE && !moveTo,
      'inside-container': itemType === ItemTypeEnum.TOGGLE && moveTo,
      'school-data-headteacher-width': itemType === ItemTypeEnum.WIDTH,
      active: animationReady
    }"
    [ngStyle]="{
      display:
        (valueMoved && itemType === ItemTypeEnum.TOGGLE_OUTSIDE) ||
        (itemType === ItemTypeEnum.TOGGLE && !moveToBody && !moveTo) ||
        (itemType === ItemTypeEnum.TOGGLE && (moveToBody || moveTo) && valueMoved) ||
        (itemType !== ItemTypeEnum.TOGGLE_OUTSIDE && itemType !== ItemTypeEnum.TOGGLE && !moveToBody)
          ? 'flex'
          : 'none'
    }"
    (@toggle.done)="animationDone()"
    id="school-data-headteacher-{{ itemId }}"
    class="school-data-headteacher--value"
    [@toggle]="(itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE) && !isVisible ? 'closed' : 'open'"
    [@slide]="itemType === ItemTypeEnum.SLIDE && !isVisible ? 'closed' : 'open'"
    [@width]="itemType === 'width' && !isVisible ? 'closed' : 'open'"
  >
    <div class="headteacher-wrapper">
      <div class="headteacher-data">
        {{ headTeacherValue.name }}
      </div>
      <span
        *ngIf="itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE"
        class="school-data-icon-toggle-close"
      >
        <i
          (click)="toggle()"
          class="school-data-icon--i icon-remove-circle"
        ></i>
      </span>
    </div>
  </div>
</ng-container>
