import {
  CalendarOptions,
  EventInput,
} from '@fullcalendar/core';
import { CalendarEventDomainModel } from '@jotter3/api-connector';
import { textColorHelpers } from '@jotter3/common-helpers';
import {
  EntitiesComponentStoreAbstract,
  LoadingStateEnum,
} from '@jotter3/store-helpers';
import { dataQuery } from '@jotter3/wa-core';
import { concatLatestFrom } from '@ngrx/effects';
import * as moment from 'moment';
import {
  distinctUntilChanged,
  filter,
  map,
  Observable,
  tap,
} from 'rxjs';
import { v4 as uuid } from 'uuid';

import { DEFAULT_CALENDAR_OPTIONS } from '../calendar.config';
import { CalendarView } from '../enums';
import { CalendarStateModel } from './calendar-state.model';

interface DateRageType {
 from: string; to: string;
}

export abstract class BaseCalendarComponentStore extends EntitiesComponentStoreAbstract<CalendarEventDomainModel, CalendarStateModel<CalendarEventDomainModel>> {
  readonly #onUpdateCalendarOptions = this.updater((state: CalendarStateModel<CalendarEventDomainModel>, options: Partial<CalendarOptions>): CalendarStateModel<CalendarEventDomainModel> => ({
    ...state,
    options: {
      ...state.options,
      ...options,
    },
  }));

  readonly #onUpdateDates = this.updater((state: CalendarStateModel<CalendarEventDomainModel>, dates: DateRageType) => {
    const { queryParams } = state;

    return {
      ...state,
      dates,
      queryParams: {
        ...queryParams,
        filters: this.#prepareQueryFilters(queryParams, dates),
      },
    };
  });

  public constructor() {
    super({
      entities: [],
      loadingState: LoadingStateEnum.PENDING,
      options: DEFAULT_CALENDAR_OPTIONS,
    });
  }

  public readonly selectOptions$: Observable<CalendarOptions> = this.select((state: CalendarStateModel<CalendarEventDomainModel>) => state.options);
  public readonly selectDates$: Observable<DateRageType | undefined> = this.select((state: CalendarStateModel<CalendarEventDomainModel>) => state.dates);
  public readonly selectCalendarView$: Observable<string> = this.select((state: CalendarStateModel<CalendarEventDomainModel>) => state.options?.initialView);
  public selectEvents$: Observable<EventInput[]> = this.selectEntities$.pipe(
    filter(entities => !!entities.length),
    distinctUntilChanged(),
    map((entities: CalendarEventDomainModel[]) => this.mapToCalendarEvent(entities))
  );

  public readonly changeCalendarView = this.effect((view$: Observable<CalendarView>) =>
    view$.pipe(
      tap((view: CalendarView) => {
        this.#onUpdateCalendarOptions({ initialView: view });
      })
    ));

  public readonly changeSelectedDates = this.effect((dates$: Observable<DateRageType>) =>
    dates$.pipe(
      tap((dates) => {
        this.#onUpdateDates(dates);
      })
    ));

  public override readonly setQueryParams = this.effect((params$: Observable<dataQuery.DataQuery>) =>
    params$.pipe(
      concatLatestFrom(() => this.selectDates$),
      tap(([
        params,
        dates,
      ]) =>
        this.onQueryParamsChanged({
          ...params,
          filters: this.#prepareQueryFilters(params, dates),
        }))
    ));

  protected mapToCalendarEvent(sourceEvents: CalendarEventDomainModel[]): EventInput[] {
    const result: EventInput[] = new Array<EventInput>();

    for (const event of sourceEvents) {
      const {
        occurences,
        id,
        categories,
        allDay,
        color: eventColor,
        name,
      } = event;
      for (const { start, end } of occurences ?? []) {
        let curEnd: moment.Moment;
        const orgEnd: moment.Moment = moment.tz(end, moment.tz.guess());

        do {
          curEnd = moment.tz(end, moment.tz.guess());
          const newStart = allDay ? moment.parseZone(start).utc(true).local(true) : moment(start).local(true);
          const newEnd = allDay ? moment.parseZone(end).utc(true).local(true) : moment(end).local(true);

          const newEvent: EventInput = {
            id: uuid(),
            // ...event,
            allDay,
            start: newStart.toDate(),
            end: (allDay ? newEnd.add(1, 'd').startOf('day').toDate() : newEnd.toDate()), // Note: This value is exclusive
            categories: categories?.map(category => {
              const { name: categoryName, color } = category;
              return {
                name: categoryName,
                color,
                id: category.id,
              };
            }),
            permissions: {
              edit: false,
              delete: false,
            },
            textColor: textColorHelpers.colorCalculator(eventColor),
            backgroundColor: eventColor,
            title: name,
            extendedProps: {
              ...event,
              sourceStartDate: newStart,
              sourceEndDate: newEnd,
            },
          };

          result.push(newEvent);
        } while (!orgEnd.isSame(curEnd));
      }
    }

    return result;
  }

  #prepareQueryFilters(queryParams: dataQuery.DataQuery, dates: DateRageType): dataQuery.Filters {
    const newFilters: dataQuery.Filters = [];

    const omittedFilters = [
      'start_date',
      'end_date',
    ];

    for (const queryFilter of queryParams?.filters ?? []) {
      if (omittedFilters.includes(queryFilter.property)) {
        continue;
      }

      newFilters.push(queryFilter);
    }

    const [
      startKey,
      endKey,
    ] = omittedFilters;

    if (!dates) {
      return newFilters;
    }

    newFilters.push({
      property: startKey,
      type: dataQuery.FilterType.DATE,
      operator: dataQuery.Operator.EMPTY,
      value: moment(dates.from).format('YYYY-MM-DD'),
    });

    newFilters.push({
      property: endKey,
      type: dataQuery.FilterType.DATE,
      operator: dataQuery.Operator.EMPTY,
      value: moment(dates.to).format('YYYY-MM-DD'),
    });

    return newFilters;
  }
}
