import { LoadingStateEnum } from '@jotter3/store-helpers';
import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createFeature,
  createReducer,
  on,
} from '@ngrx/store';

import { SiteDomainModel } from '../../domain-models';
import { BaseStateModel } from '../common';
import { SiteStoreActions } from './site.actions';

export interface SiteState extends BaseStateModel {
  entity: SiteDomainModel | undefined;
  domain: string | undefined;
  errors: Array<ApiErrorResponse | unknown> | undefined;
  cookiesAccepted: boolean;
  cookiesHasBeenShown: boolean;
}

const initialState: SiteState = {
  loadingStatus: LoadingStateEnum.PENDING,
  domain: undefined,
  entity: undefined,
  errors: undefined,
  cookiesAccepted: false,
  cookiesHasBeenShown: false,
};

export const siteStoreFeature = createFeature({
  name: 'siteStore',
  reducer: createReducer(
    initialState,
    on(SiteStoreActions.loadTrigger, (state: SiteState, { domain }): SiteState => ({
      ...state,
      domain,
      loadingStatus: LoadingStateEnum.LOADING,
    })),
    on(SiteStoreActions.loadSuccess, (state: SiteState, { entity }): SiteState => ({
      ...state,
      loadingStatus: LoadingStateEnum.SUCCESS,
      entity: entity,
    })),
    on(SiteStoreActions.cookiesAccepted, (state: SiteState): SiteState => ({
      ...state,
      cookiesAccepted: true,
    })),
    on(SiteStoreActions.cookiesHasBeenShown, (state: SiteState): SiteState => ({
      ...state,
      cookiesHasBeenShown: true,
    })),
    on(SiteStoreActions.updateTrigger, (state: SiteState): SiteState => ({
      ...state,
      loadingStatus: LoadingStateEnum.LOADING,
    })),
    on(SiteStoreActions.updateSuccess, (state: SiteState, { entity }): SiteState => ({
      ...state,
      entity: {
        ...state.entity,
        ...entity,
      },
      loadingStatus: LoadingStateEnum.SUCCESS,
    })),
    on(
      SiteStoreActions.loadFailed,
      SiteStoreActions.updateFailed,
      (state: SiteState, { errors }): SiteState => ({
        ...state,
        errors,
        loadingStatus: LoadingStateEnum.FAILED,
      })
    )
  ),
});
