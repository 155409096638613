<ng-container *ngIf="$fax | async; let faxValue">
  <span
    *ngIf="iconValue"
    [ngClass]="{'school-data-icon-toggle': itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE}"
    (click)="toggle()"
    class="{{ iconCustomClass }} school-data-icon"
  >
    <i class="school-data-icon--i {{ iconValue }}"></i>
  </span>

  <div
    [ngClass]="{
        'school-data-fax-toggle': itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE,
        'outside': itemType === ItemTypeEnum.TOGGLE_OUTSIDE,
        'inside': itemType === ItemTypeEnum.TOGGLE && !moveTo,
        'inside-container': itemType === ItemTypeEnum.TOGGLE && moveTo,
        'active': animationReady
    }"
    [ngStyle]="{'display':
        (valueMoved && itemType === ItemTypeEnum.TOGGLE_OUTSIDE) ||
        (itemType === ItemTypeEnum.TOGGLE && !moveToBody && !moveTo) ||
        (itemType === ItemTypeEnum.TOGGLE && (moveToBody || moveTo) && valueMoved) ||
        (itemType !== ItemTypeEnum.TOGGLE_OUTSIDE && (itemType !== ItemTypeEnum.TOGGLE && !moveToBody))
        ? 'flex' : 'none'}"
    (@toggle.done)="animationDone()"
    id="school-data-fax-{{itemId}}"
    class="school-data-fax--value"
    [@toggle]="((itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE) && !isVisible) ? 'closed' : 'open'"
  >
    <div class="fax-wrapper">
      <div class="fax-data">{{faxValue}}</div>
      <span
        *ngIf="itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE"
        (click)="toggle()"
        class="school-data-icon-toggle-close"
      >
        <i class="school-data-icon--i icon-remove-circle"></i>
      </span>
    </div>
  </div>
</ng-container>
