import { LoadingStateEnum } from '@jotter3/store-helpers';
import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createEntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  createReducer,
  on,
} from '@ngrx/store';

import {
  ResourceDomainModel,
  ResourceListDomainModel,
} from '../../../domain-models';
import { pageContentResourcesActions } from '../actions';

export interface PageContentResourcesState extends EntityState<ResourceDomainModel | ResourceListDomainModel> {
  loadingState: LoadingStateEnum;
  pageUrl?: string;
  error?: ApiErrorResponse;
}
const entityAdapter = createEntityAdapter<ResourceDomainModel | ResourceListDomainModel>();

export const initialPageContentResourcesState: PageContentResourcesState = entityAdapter.getInitialState({
  loadingState: LoadingStateEnum.PENDING,
});

export const pageContentResourcesReducer = createReducer<PageContentResourcesState>(
  initialPageContentResourcesState,
  on(pageContentResourcesActions.LoadPageContentResources, (state: PageContentResourcesState, { pageUrl }): PageContentResourcesState => ({
    ...initialPageContentResourcesState,
    pageUrl,
    loadingState: LoadingStateEnum.LOADING,
  })),
  on(pageContentResourcesActions.LoadPageContentResourcesComplete, (state: PageContentResourcesState, { resources }): PageContentResourcesState =>
    entityAdapter.setAll(resources, {
      ...state,
      error: undefined,
      loadingState: LoadingStateEnum.SUCCESS,
    })),
  on(pageContentResourcesActions.LoadPageContentResourcesFailed, (state: PageContentResourcesState, { error }): PageContentResourcesState => ({
    ...state,
    loadingState: LoadingStateEnum.FAILED,
    error,
  })),
  on(pageContentResourcesActions.NoResourcesFound, (state: PageContentResourcesState): PageContentResourcesState => ({
    ...state,
    loadingState: LoadingStateEnum.SUCCESS,
  }))
);
