import {
  Component,
  Injectable,
  ViewEncapsulation,
} from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { FieldType } from '@ngx-formly/core';
import * as moment from 'moment-timezone';

@Injectable()
export class MomentDateAdapter extends NgbDateAdapter<moment.Moment> {
  readonly DELIMITER = '-';

  fromModel(value: moment.Moment | null): NgbDateStruct | null {
    const date = moment.isMoment(value) ? value : moment(value || null);

    return {
      day: date.date(),
      month: date.month() + 1,
      year: date.year(),
    };
  }

  toModel(date: NgbDateStruct | null): moment.Moment | null {
    return date
      ? moment().set({
        date: date.day,
        year: date.year,
        month: date.month - 1,
      })
      : null;
  }
}

@Component({
  templateUrl: './datepicker.field.template.html',
  styleUrls: ['./datepicker.field.scss'],
  providers: [
    {
      provide: NgbDateAdapter,
      useClass: MomentDateAdapter,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DatepickerFormFieldComponent extends FieldType {
  public reset(): void {
    this.formControl.setValue(null);
  }
}
