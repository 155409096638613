import { InjectionToken } from '@angular/core';
import { domainModels } from '@jotter3/api-connector';
import { take } from 'rxjs/operators';

import { LinkType } from '../enums';
import { LinkPluginModel } from '../models';
import { ResourcesManagerService } from '../services';
import { DATA_DEF_ATTRIBUTE } from './consts';

export abstract class LinkProvider {
  protected constructor(private resourcesManager: ResourcesManagerService) {}

  public navigate(ev: PointerEvent, linkModel: LinkPluginModel): boolean {
    const { linkType } = linkModel;
    let preventDefault = false;

    switch (linkType) {
      case LinkType.EXTERNAL_URL:
      case LinkType.MAIL:
        break;
      case LinkType.LINK_CMS_TO_PAGE:
        this.navigateToCMSPage(ev, linkModel);
        preventDefault = true;
        break;
      case LinkType.LINK_TO_FILE:
        this.onResouceAnchorClicked(ev);
        preventDefault = true;
        break;
    }

    return preventDefault;
  }

  private isAnchorElement = (elm: HTMLElement): boolean =>
    elm && elm.nodeName.toLowerCase() === 'a' && elm.hasAttribute(DATA_DEF_ATTRIBUTE);
  private getParentAnchor = (elm: HTMLElement): HTMLElement | null =>
    !elm.parentElement
      ? null
      : this.isAnchorElement(elm.parentElement)
        ? elm.parentElement
        : this.getParentAnchor(elm.parentElement);

  private onResouceAnchorClicked(ev: PointerEvent): void {
    try {
      ev.preventDefault();
      ev.stopPropagation();
      const { target } = ev;
      const anchorElement = this.isAnchorElement(target as HTMLElement)
        ? (target as HTMLAnchorElement)
        : this.getParentAnchor(target as HTMLElement);

      if (!anchorElement) {
        return;
      }

      const formModel: LinkPluginModel = JSON.parse(decodeURIComponent(anchorElement.getAttribute(DATA_DEF_ATTRIBUTE) || '{}'));

      if (formModel?.linkType !== LinkType.LINK_TO_FILE || (!formModel?.resource && !formModel?.resourceId)) {
        return;
      }

      this.resourcesManager
        .getResource(formModel?.resource ? formModel?.resource?.id : formModel?.resourceId, 'SINGLE')
        .pipe(take(1))
        .subscribe((res) => {
          const resource = res as domainModels.ResourceDomainModel;
          const { openInNewTab } = formModel;
          const {
            url: { origin },
          } = resource.file as domainModels.IFileDomainModel;
          window.open(origin, openInNewTab || ev.ctrlKey || ev.metaKey ? '_blank' : '_self');
        });
    } catch (err) {
      console.warn(`ERR: cannot parse '${DATA_DEF_ATTRIBUTE}' for given selection`);
    }
  }

  protected abstract navigateToCMSPage(ev: PointerEvent, model: LinkPluginModel): void;
}

export const J3_LINK_PROVIDER: InjectionToken<LinkProvider> = new InjectionToken<LinkProvider>('J3_LINK_PROVIDER');
