import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  NavigationStart,
  Router,
} from '@angular/router';
import { domainModels } from '@jotter3/api-connector';
import { ResourcesManagerService } from '@jotter3/common-components';
import { J3TranslateService } from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { ContentBuilderManagerService } from '@jotter3/sites-core';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { isNil } from 'lodash-es';
import {
  Observable,
  of,
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'twitter-embeded-component',
  displayName: 'Twitter',
  icon: 'twitter',
  category: SiteComponentCategory.EMBED,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './twitter-embeded.component.html',
  styleUrls: ['./twitter-embeded.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'editMode',
        style({
          transform: 'none',
        })
      ),
      state(
        'open',
        style({
          transform: 'translate(0, -50%)',
        })
      ),
      state(
        'closed',
        style({
          transform: 'translate(-{{twitterWidth}}, -50%)',
        }),
        { params: { twitterWidth: '30px' } }
      ),
      transition('closed <=> open', [animate('0.2s')]),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class TwitterEmbededElementComponent extends JotterSitesBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  public headerValue = '';

  public imgOpenSrcValue: domainModels.IFileDomainModel;
  public imgCloseSrcValue: domainModels.IFileDomainModel;
  private imageOpenResourceId: string;
  private imageCloseResourceId: string;
  private fixedElementValue = true;
  private alignValue = 'justify-content-center';
  public floatingElementValue = false;
  private heightValue = '';
  private widthValue = '';
  private fullHeightValue = false;
  public openClose = 'closed';
  public elementId: string = UUID();
  public elementExists = false;
  public noImageSelected = false;
  private parentNode: ParentNode;
  public isBlocked: boolean;

  @ViewChild('trigger') trigger: ElementRef;

  constructor(
    private container: ViewContainerRef,
    private router: Router,
    private resourceManager: ResourcesManagerService,
    public editorAPI: ContentBuilderManagerService
  ) {
    super(TwitterEmbededElementComponent);

    router.events.pipe(untilComponentDestroyed(this), take(1)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        document
          .querySelector(
            '.content-element.content-element-twitter-embeded' + `.${this.container.element.nativeElement.className}`
          )
          ?.remove();
      }
    });
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
    hideExpression: 'model.floatingElement',
    templateOptions: {
      required: false,
      placeholder: 'Enter header...',
    },
  })
  public header = '';

  @Property({
    displayName: 'Twitter account name',
    modifierType: ModifierType.TEXT,
    required: true,
  })
  public twitterAccount = '';

  @Property({
    displayName: 'Width',
    modifierType: ModifierType.UNIT_INPUT,
  })
  public get width(): string {
    return this.widthValue;
  }

  public set width(value: string) {
    this.widthValue = value;
  }

  @Property({
    displayName: 'Height',
    description: 'px',
    modifierType: ModifierType.TEXT,
    required: true,
  })
  public get height(): string {
    return this.heightValue;
  }

  public set height(value: string) {
    this.heightValue = this.checkIfNumber(value) ? `${value}px` : value;
  }

  @Property({
    displayName: 'Floating element',
    modifierType: ModifierType.CHECK_BOX,
    defaultValue: false,
  })
  public get floatingElement(): boolean {
    return this.floatingElementValue;
  }

  public set floatingElement(value: boolean) {
    const prevFloatingVal = this.floatingElementValue;
    this.floatingElementValue = value;
    if (prevFloatingVal === value) {
      return;
    }
    this.elementExists = false;
  }

  @Property({
    displayName: 'Element fixed',
    modifierType: ModifierType.CHECK_BOX,
    hideExpression: '!model.floatingElement',
    defaultValue: true,
  })
  public get fixedElement(): boolean {
    return this.fixedElementValue ? this.fixedElementValue : false;
  }

  public set fixedElement(value: boolean) {
    this.fixedElementValue = value;
  }

  @Property({
    modifierType: ModifierType.FILEUPLOAD,
    templateOptions: {
      multipleItems: false,
      allowTypes: [1],
      getUrl: true,
    },
    displayName: 'Button open image',
    hideExpression: '!model.floatingElement',
  })
  public get imageOpenSRC(): domainModels.IFileDomainModel {
    return this.imgOpenSrcValue;
  }

  public set imageOpenSRC(value: domainModels.IFileDomainModel) {
    if (Array.isArray(value)) {
      value = value.length ? value[0] : undefined;
    }
    this.noImageSelected = value ? false : true;
    if (this.imgOpenSrcValue?.id === value?.id) {
      return;
    }

    this.imgOpenSrcValue = value;

    if (!value || !this.designMode || !this.viewInitialized) {
      return;
    }

    this.addToResources(value.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe(({ id }) => {
        this.imageOpenResourceId = id;
      });
  }

  @Property({
    modifierType: ModifierType.FILEUPLOAD,
    templateOptions: {
      multipleItems: false,
      allowTypes: [1],
      getUrl: true,
    },
    displayName: 'Button close image',
    hideExpression: '!model.floatingElement',
  })
  public get imageCloseSRC(): domainModels.IFileDomainModel {
    return this.imgCloseSrcValue;
  }

  public set imageCloseSRC(value: domainModels.IFileDomainModel) {
    if (Array.isArray(value)) {
      value = value.length ? value[0] : undefined;
    }

    if (this.imgCloseSrcValue?.id === value?.id) {
      return;
    }

    this.imgCloseSrcValue = value;

    if (!value || !this.designMode || !this.viewInitialized) {
      return;
    }

    this.addToResources(value.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe(({ id }) => {
        this.imageCloseResourceId = id;
      });
  }

  @Property({
    displayName: 'Full height button image',
    modifierType: ModifierType.CHECK_BOX,
    hideExpression: '!model.floatingElement',
    defaultValue: false,
  })
  public get fullHeight(): boolean {
    return this.fullHeightValue;
  }

  public set fullHeight(value: boolean) {
    this.fullHeightValue = this.fullHeightValue !== undefined ? value : false;
  }

  @Property({
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: 'Top',
        value: 'justify-content-start',
      },
      {
        label: 'Middle',
        value: 'justify-content-center',
      },
      {
        label: 'Bottom',
        value: 'justify-content-end',
      },
    ],
    clearable: false,
    displayName: 'Button position',
    hideExpression: '!model.floatingElement || model.fullHeight',
    defaultValue: 'justify-content-center',
  })
  public get align(): string {
    return this.alignValue;
  }

  public set align(value: string) {
    this.alignValue = this.alignValue ? value : 'justify-content-center';
  }

  @HostListener('window:resize') onResize(): void {
    this.appendToContainer(
      '.content-element.content-element-twitter-embeded',
      this.fixedElement ? '' : this.cssClass
    );
  }

  public ngOnInit(): void {
    if (this.platformBrowser) {
      setTimeout(() => {
        this.appendToContainer(
          '.content-element.content-element-twitter-embeded',
          this.fixedElement ? '' : this.cssClass
        );
      }, 100);
    }

    if (this.platformServer) {
      return;
    }

    this.onDesingModeChanged.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.appendToContainer(
        '.content-element.content-element-twitter-embeded',
        this.fixedElement ? '' : this.cssClass
      );
      if (this.twitterAccount) {
        this.loadTwitter();
      }
    });
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.editorAPI
      .contentBlocked()
      .pipe(
        untilComponentDestroyed(this),
        filter(() => this.platformBrowser)
      )
      .subscribe((blocked) => {
        setTimeout(() => {
          this.isBlocked = blocked;

          if (blocked) {
            if (document.getElementById('twitter_widget')) {
              document.getElementById('twitter_widget').remove();
              this.clearPlatformNodes('script');
              this.clearPlatformNodes('iframe');
            }
          } else {
            this.loadTwitter();
          }
        }, 1000);
      });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.platformBrowser && !this.designMode && this.fixedElement && this.cssClass) {
      document.getElementById(`twitter-wrapper-${this.elementId}`).remove();
    }
  }

  public floatingTwitterImage(): string {
    if (this.openClose === 'closed') {
      return !isNil(this.imgOpenSrcValue) ? this.imgOpenSrcValue?.icon?.s || this. imgOpenSrcValue?.url?.s : './assets/images/twitter-close.png';
    } else {
      return !isNil(this.imgCloseSrcValue) ? this.imgCloseSrcValue?.icon?.s || this.imgCloseSrcValue?.url?.s : './assets/images/twitter-open.png';
    }
  }

  public floatingTwitterImageWebp(): string {
    if (this.openClose === 'closed') {
      return !isNil(this.imgOpenSrcValue) ? this.imgOpenSrcValue?.icon?.webp?.s || this. imgOpenSrcValue?.url?.webp?.s : './assets/images/twitter-close.png';
    } else {
      return !isNil(this.imgCloseSrcValue) ? this.imgCloseSrcValue?.icon?.webp?.s || this.imgCloseSrcValue?.url?.webp?.s : './assets/images/twitter-open.png';
    }
  }

  private checkIfNumber(val: any): boolean {
    return !isNaN(val);
  }

  private loadTwitter(): void {
    if (this.platformServer) {
      return;
    }
    if (!document.getElementById('twitter_widget')) {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.id = 'twitter_widget';
      script.type = 'text/javascript';
      script.src = 'https://platform.twitter.com/widgets.js';
      script.async = true;
      head.appendChild(script);
    }
    setTimeout(() => {
      switch (this.floatingElement) {
        case true: {
          const iframe = document.querySelector('iframe');

          const twitterWrapper = document.getElementById(`twitter-wrapper-${this.elementId}`);
          let floatingTwitter;

          if (!isNil(twitterWrapper)) {
            floatingTwitter = twitterWrapper.getElementsByClassName('floating-twitter');
            if (twitterWrapper.getElementsByClassName('twitter-timeline').length) {
              return;
            }
          }

          if (!isNil(floatingTwitter) && !!floatingTwitter.length && floatingTwitter[0].getElementsByTagName('iframe')?.length) {
            if (this.#isChild(iframe, floatingTwitter[0])) {
              floatingTwitter[0].removeChild(iframe);
            }

            (window as any).twttr.widgets.createTimeline(
              {
                sourceType: 'profile',
                screenName: this.twitterAccount.replace(/^@/g, ''),
              },
              document.getElementById(`twitter-wrapper-${this.elementId}`).getElementsByClassName('floating-twitter')[0],
              {
                width: this.width ? this.width : '100%',
                height: this.height,
              }
            );
            this.elementExists = true;
          } else {
            if ((window as any)?.twttr) {
              (window as any).twttr.widgets.createTimeline(
                {
                  sourceType: 'profile',
                  screenName: this.twitterAccount.replace(/^@/g, ''),
                },
                document.getElementById(`twitter-wrapper-${this.elementId}`).getElementsByClassName('floating-twitter')[0],
                {
                  width: this.width ? this.width : '100%',
                  height: this.height,
                }
              );
            }
          }

          this.elementExists = true;
          break;
        }
        case false: {
          (window as any)?.twttr?.widgets?.load();
          this.elementExists = true;
          break;
        }
      }
    }, 1000);
  }

  private addToResources(file: string): Observable<domainModels.ResourceDomainModel> {
    const reqModel: Partial<domainModels.ResourceDomainModel> = { file };

    return this.resourceManager
      .getResource(file, 'SINGLE')
      .pipe(
        switchMap((result) =>
          result
            ? of(result as domainModels.ResourceDomainModel)
            : this.resourceManager
              .putResource(reqModel, 'SINGLE')
              .pipe(
                switchMap((x) =>
                  this.resourceManager.getResource(x, 'SINGLE').pipe(map((y) => y as domainModels.ResourceDomainModel)))
              ))
      );
  }

  public override setDataset(dataset: { [key: string]: any } = {}): void {
    super.setDataset(dataset);
    if (dataset?.['imageOpenResourceId'] === null) {
      this.noImageSelected = true;
    }
    if (dataset?.['imageOpenResourceId']) {
      this.resourceManager
        .getResource(dataset?.['imageOpenResourceId'])
        .pipe(take(1))
        .subscribe((result) => {
          if ('file' in result) {
            this.imgOpenSrcValue = result.file as domainModels.IFileDomainModel;
          }
        });
    }

    if (dataset?.['imageCloseResourceId']) {
      this.resourceManager
        .getResource(dataset?.['imageCloseResourceId'])
        .pipe(take(1))
        .subscribe((result) => {
          if ('file' in result) {
            this.imgCloseSrcValue = result.file as domainModels.IFileDomainModel;
          }
        });
    }

    if (this.designMode && !dataset?.['imageOpenResourceId'] && dataset?.['imageOpenSRC']) {
      if (Array.isArray(dataset?.['imageOpenSRC'])) {
        dataset['imageOpenSRC'] = dataset?.['imageOpenSRC']?.length ? dataset?.['imageOpenSRC'][0] : undefined;
      }

      this.addToResources(dataset?.['imageOpenSRC'].id)
        .pipe(untilComponentDestroyed(this))
        .subscribe(({ id }) => {
          this.imageOpenResourceId = id;
        });
    }

    if (this.designMode && !dataset?.['imageCloseResourceId'] && dataset?.['imageCloseSRC']) {
      if (Array.isArray(dataset?.['imageCloseSRC'])) {
        dataset['imageCloseSRC'] = dataset?.['imageCloseSRC']?.length ? dataset?.['imageCloseSRC'][0] : undefined;
      }

      this.addToResources(dataset?.['imageCloseSRC'].id)
        .pipe(untilComponentDestroyed(this))
        .subscribe(({ id }) => {
          this.resourceManager.putResource.bind(this.resourceManager);
          this.imageCloseResourceId = id;
        });
    }
  }

  public override getDataset(): { [key: string]: any } {
    const dataset = super.getDataset() ?? {};
    const { imageOpenSRC, imageCloseSRC, ...rest } = dataset;
    rest['imageOpenResourceId'] = this.imageOpenSRC ? this.imageOpenResourceId : null;
    rest['imageCloseResourceId'] = this.imageCloseSRC ? this.imageCloseResourceId : null;
    return rest;
  }

  public getPositionStyle(): string {
    switch (true) {
      case this.fixedElement && this.floatingElement && !this.designMode: {
        return 'fixed';
      }
      case !this.fixedElement && this.floatingElement && !this.designMode: {
        return 'absolute';
      }
      default: {
        return 'static';
      }
    }
  }

  public toggleAnimation(): void {
    if (this.designMode) {
      return;
    }
    this.openClose = this.openClose === 'closed' ? 'open' : 'closed';
  }

  public override afterSettingsSaved(): void {
    setTimeout(() => {
      this.loadTwitter();
    });
  }

  public clearPx(num: string): string {
    return num.replace(/px$/, '');
  }

  public clearPlatformNodes(tag: string): void {
    const list = Array.prototype.slice.call(document.getElementsByTagName(tag));
    list.forEach((item) => {
      if (item.src.includes('platform.twitter')) {
        item.parentNode.removeChild(item);
      }
    });
  }

  public getTwitterOpenSrc(): string {
    const open = './assets/images/twitter-open.webp';
    const closed = './assets/images/twitter-close.webp';
    switch (true) {
      case !isNil(this.imgCloseSrcValue) && !isNil(this.imgOpenSrcValue):
        return this.openClose === 'closed' ? this.imgCloseSrcValue?.icon?.s || this.imgCloseSrcValue?.url?.s : this.imgOpenSrcValue?.icon?.s || this.imgOpenSrcValue?.url?.s;
        break;
      case !isNil(this.imgCloseSrcValue) && isNil(this.imgOpenSrcValue):
        return this.openClose === 'closed' ? open : this.imgCloseSrcValue?.icon?.s || this.imgCloseSrcValue?.url?.s;
        break;
      case isNil(this.imgCloseSrcValue) && !isNil(this.imgOpenSrcValue):
        return this.openClose === 'closed' ? this.imgOpenSrcValue?.icon?.s || this.imgOpenSrcValue?.url?.s : closed;
        break;
      default:
        return this.openClose === 'closed' ? open : closed;
        break;
    }
  }

  #isChild(target: Element, parentObj: Element): boolean {
    let targetObj = target;

    while (!isNil(targetObj) && targetObj.tagName.toUpperCase() !== 'BODY') {
      if (targetObj === parentObj) {
        return true;
      }

      targetObj = targetObj.parentNode as Element;
    }

    return false;
  }
}
