<ng-container *ngIf="cssClass === 'toggle-element'; else standard">
  <j3-toggle-info-component *ngIf="designMode"> </j3-toggle-info-component>
  <ng-container *ngIf="!designMode; else standard">
    <div
      (mouseenter)="toggleItem(true, elementId)"
      (mouseleave)="toggleItem(false, elementId)"
    >
      <div
        class="sectionHeader text-center h3 mb-0"
        *ngIf="source"
        [id]="'element-header-' + elementId"
      >
        {{ header }}
      </div>
      <div
        class="galleries-wrapper section-toggle-wrapper absolute-section"
        [@openClose]="this.isActive ? 'open' : 'closed'"
        [id]="'element-wrapper-' + elementId"
        [ngClass]="{
          'section-toggle-wrapper-active': isActive === true
        }"
        [ngStyle]="{ bottom: isActive ? itemPosition : '0' }"
        (@openClose.done)="animationOverflow(false, elementId)"
        (@openClose.start)="animationOverflow(true, elementId)"
      >
        <div>
          <ng-container [ngTemplateOutlet]="contentData"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #standard>
  <ng-container>
    <div
      class="sectionHeader text-center h3 mb-0"
      *ngIf="header && (designMode || (!designMode && source))"
    >
      {{ header | translate }}
    </div>
    <ng-container [ngTemplateOutlet]="contentData"></ng-container>
  </ng-container>
</ng-template>

<ng-template #contentData>
  <p
    class="p-3 w-100 h-100 text-center bg-secondary"
    *ngIf="(!selectedGallery || !source) && designMode"
  >
    Select Gallery to Show
  </p>
  <div class="galleries-container">
    <ng-container *ngIf="source && selectedView !== 'MASONRY'; else masonry">
      <div class="normal-view" [ngClass]="selectedView">
        <div
          class="single-image"
          *ngFor="let attachment of source.attachments; let index = index"
          (click)="openPreview(attachment)"
        >
          <img
            [src]="getImageFromAttachment(attachment.url)"
            [alt]="source.name + ' - image ' + index"
            [title]="source.name + ' - image ' + index + 1"
            loading="lazy"
          />
        </div>
      </div>
    </ng-container>
    <ng-template #masonry>
      <ngx-masonry
        class="masonry-view"
        *ngIf="platformBrowser && source && selectedView === 'masonry'"
        [options]="options"
        [ordered]="true"
      >
        <div
          class="masonry-item single-image"
          *ngFor="let attachment of source.attachments; let index = index"
          (click)="openPreview(attachment)"
          ngxMasonryItem
        >
          <img
            [src]="getImageFromAttachment(attachment.url)"
            [alt]="source.name + ' - image ' + index"
            [title]="source.name + ' - image ' + index + 1"
            loading="lazy"
          />
        </div>
      </ngx-masonry>
    </ng-template>
  </div>
</ng-template>
