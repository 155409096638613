import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { commonFields } from '@jotter3/common-components';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';

import {
  fields,
  formlyComponents,
} from './formly';

@NgModule({
  declarations: [...formlyComponents],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormlyModule.forChild({
      validationMessages: [
        {
          name: 'required',
          message: 'This field is required',
        },
      ],
      types: [
        ...fields,
        ...commonFields,
      ],
    }),
  ],
  exports: [...formlyComponents],
})
export class SharedModule {}
