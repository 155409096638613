import {
  createAction,
  props,
} from '@ngrx/store';

import { PageDomainModel } from '../../../domain-models';

const LoadPagesList = createAction('[Pages list] Load data', props<{ limit?: number }>());
const LoadPagesListComplete = createAction('[Pages list] Load Pages List Complete', props<{ pagesList: PageDomainModel[] }>());
const LoadPagesListFailed = createAction('[Pages list] Load data failed', props<{ error: any }>());

export { LoadPagesList,
  LoadPagesListComplete,
  LoadPagesListFailed };
