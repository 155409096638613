import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  Observable,
  throwError,
} from 'rxjs';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  // #store: Store = inject(Store);
  // #toastFactory: AppToastFactory = inject(AppToastFactory);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // const toast = this.#toastFactory.createToastError(error);

          // this.#store.dispatch(AppStoreActions.showToastError(toast));
          return throwError(() => error);
        })
      );
  }
}
