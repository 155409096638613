import {
  DOCUMENT,
  isPlatformBrowser,
} from '@angular/common';
import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  myGroupsListState,
  permissionsState,
  userProfileState,
} from '@jotter3/api-connector';
import {
  AppType,
  J3TranslateService,
} from '@jotter3/common-helpers';
import { AuthService } from '@jotter3/core';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  Subscription,
} from 'rxjs';
import {
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-toasts aria-live="polite" aria-atomic="true"/>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  readonly #subscription: Subscription = new Subscription();
  readonly #platformId = inject(PLATFORM_ID);
  readonly #document = inject(DOCUMENT);
  readonly #authService: AuthService = inject(AuthService);
  readonly #store: Store = inject(Store);
  readonly #translateService: J3TranslateService = inject(J3TranslateService);

  public ngOnInit(): void {
    this.#subscription.add(this.#authService
      .initialize(AppType.CLIENT)
      .pipe(
        tap((isAuthorized) => {
          this.#store.dispatch(userProfileState.userActions.SetUserAuthorized({ isAuthorized }));
        }),
        filter((authorized) => authorized),
        switchMap(() =>
          combineLatest([
            this.#store.select(permissionsState.permissionSelectors.permissionsStateSelector),
            this.#store.select(myGroupsListState.myGroupsSelectors.selectMyGroupsListState),
          ])),
        tap(([
          permissions,
          groups,
        ]) => {
          if (!permissions.isLoaded && !permissions.isLoading) {
            this.#store.dispatch(permissionsState.permissionActions.LoadPermissions());
          }

          if (!groups.isLoaded && !groups.isLoading) {
            this.#store.dispatch(myGroupsListState.myGroupsActions.LoadMyGroupsList());
          }
        })
      )
      .subscribe());

    if (!isPlatformBrowser(this.#platformId)) {
      return;
    }

    this.#document.body.classList.remove('server-body');
  }

  public ngOnDestroy(): void {
    this.#translateService.ngOnDestroy();
    this.#subscription.unsubscribe();
  }
}
