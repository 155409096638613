import { enums } from '@jotter3/api-connector';
import {
  DropdownItemModel,
  OptionsTooltipListModel,
} from '@jotter3/sites-abstract';

import { LinkType } from '../../enums';

export const IMAGE_ALIGN_DROPDOWN_SOURCE: Array<DropdownItemModel<string, string>> = [
  {
    label: 'Left',
    value: 'align-left',
    icon: 'align_left',
  },
  {
    label: 'Center',
    value: 'align-auto',
    icon: 'align_center',
  },
  {
    label: 'Right',
    value: 'align-right',
    icon: 'align_right',
  },
];

export const LINK_TYPE_DROPDOWN_SOURCE: Array<DropdownItemModel<LinkType, string>> = [
  {
    label: 'External url',
    value: LinkType.EXTERNAL_URL,
  },
  {
    label: 'Link to cms page',
    value: LinkType.LINK_CMS_TO_PAGE,
  },
  {
    label: 'E-Mail address',
    value: LinkType.MAIL,
  },
  {
    label: 'Link to file',
    value: LinkType.LINK_TO_FILE,
  },
];

export const IMAGE_SIZES_DROPDOWN_SOURCE: Array<DropdownItemModel<enums.PicturesSizes, string>> = [
  {
    label: 'Extra Small (200px)',
    value: enums.PicturesSizes.XS,
  },
  {
    label: 'Small (400px)',
    value: enums.PicturesSizes.S,
  },
  {
    label: 'Medium (800px)',
    value: enums.PicturesSizes.M,
  },
  {
    label: 'Large (1300px)',
    value: enums.PicturesSizes.ML,
  },
  {
    label: 'Extra Large (1920px)',
    value: enums.PicturesSizes.L,
  },
];

export const IMAGE_SIZES_OPTIONLIST_SOURCE: Array<OptionsTooltipListModel<enums.PicturesSizes, string, string>> = [
  {
    label: 'Extra Small',
    value: enums.PicturesSizes.XS,
    tooltip: '200px',
  },
  {
    label: 'Small',
    value: enums.PicturesSizes.S,
    tooltip: '400px',
  },
  {
    label: 'Medium',
    value: enums.PicturesSizes.M,
    tooltip: '800px',
  },
  {
    label: 'Large',
    value: enums.PicturesSizes.ML,
    tooltip: '1300px',
  },
  {
    label: 'Extra Large',
    value: enums.PicturesSizes.L,
    tooltip: '1920px',
  },
];
