import {
  createAction,
  props,
} from '@ngrx/store';

import { GroupDomainModel } from '../../../domain-models';

const LoadMyGroupsList = createAction('[My Groups list] Load data');
const LoadMyGroupsListComplete = createAction(
  '[My Groups list] Load My Groups List Complete',
  props<{ myGroupsList: GroupDomainModel[] }>()
);
const LoadMyGroupsListFailed = createAction('[My Groups list] Load data failed', props<{ error: any }>());

export { LoadMyGroupsList,
  LoadMyGroupsListComplete,
  LoadMyGroupsListFailed };
