import {
  createAction,
  props,
} from '@ngrx/store';

import { GdprSettingsDomainModel } from '../../../domain-models';
import { CookieState } from '../../../enums';

const LoadGdprSettings = createAction('[Settings - Gdpr] Load data');
const LoadGdprSettingsComplete = createAction(
  '[Settings - GDPR] Load data success',
  props<{ gdprSettings: GdprSettingsDomainModel }>()
);
const LoadGdprSettingsFailed = createAction('[Settings - GDPR] Load data failed', props<{ error: any }>());
const UpdateGdprSettings = createAction('[Settings - GDPR] Update', props<{ gdprSettings: Partial<GdprSettingsDomainModel> }>());
const UpdateGdprSettingsComplete = createAction(
  '[Settings - GDPR] Update data success',
  props<{ gdprSettings: Partial<GdprSettingsDomainModel> }>()
);
const UpdateGdprSettingsFailed = createAction('[Settings - GDPR] Update data failed', props<{ error: any }>());
const UpdateCookieAccepted = createAction('[GDPR] - Cookie accepted', props<{ accepted: CookieState }>());

export { LoadGdprSettings,
  LoadGdprSettingsComplete,
  LoadGdprSettingsFailed,
  UpdateCookieAccepted,
  UpdateGdprSettings,
  UpdateGdprSettingsComplete,
  UpdateGdprSettingsFailed };
