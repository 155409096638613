<ng-container *ngIf="pages$ | ngrxPush as menu">
  <ng-container [ngSwitch]="true">
    <ng-container
      *ngSwitchCase="
        menuType === siteMenuType.MOBILE || menuType === siteMenuType.HAMBURGER
      "
    >
      <div class="site-menu-button site-menu--{{ menuType }}-button">
        <ng-container *ngTemplateOutlet="hamburgerButton"></ng-container>
      </div>
      <ul
        class="site-menu site-menu--{{ menuType }}"
        [@openClose]="this.isActive ? 'open' : 'closed'"
        [class.site-menu-isopen]="this.isActive"
      >
        <ng-content
          *ngTemplateOutlet="recursiveList; context: { $implicit: menu }"
        ></ng-content>
      </ul>
    </ng-container>

    <ng-container
      *ngSwitchCase="
        menuType === siteMenuType.HAMBURGERFIXED ||
        menuType === siteMenuType.HAMBURGERFIXEDVERTICAL
      "
    >
      <div class="site-menu-button site-menu--{{ menuType }}-button">
        <ng-container *ngTemplateOutlet="hamburgerButton"></ng-container>
      </div>
      <div
        class="site-menu--{{ menuType }}-wrapper"
        id="site-menu--{{ menuType }}-wrapper-{{ menuID }}"
        [@slideToggle]="{
          value: this.isActive,
          params: {
            menuWidthValue: '-' + toggleMenuWidth
          }
        }"
      >
        <ng-container *ngTemplateOutlet="hamburgerButton"></ng-container>
        <ul class="site-menu site-menu--{{ menuType }}" *ngIf="menu.length">
          <ng-content
            *ngTemplateOutlet="recursiveList; context: { $implicit: menu }"
          ></ng-content>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="menuType === siteMenuType.HORIZONTAL">
      <ng-container *ngTemplateOutlet="fixedDummyContainer"></ng-container>
      <ng-container *ngIf="menuFixed; else simpleHorizontal">
        <div
          class="site-menu--{{ menuType }}-fixed-wrapper"
          id="site-menu-{{ menuType }}-{{ menuID }}-fixed-wrapper"
        >
          <ng-content
            *ngTemplateOutlet="horizontalMenu; context: { $implicit: menu }"
          >
          </ng-content>
        </div>
      </ng-container>
      <ng-template #simpleHorizontal>
        <ng-content
          *ngTemplateOutlet="horizontalMenu; context: { $implicit: menu }"
        ></ng-content>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-content
        *ngTemplateOutlet="
          menuList;
          context: { $implicit: childLevelPages$ | ngrxPush }
        "
      >
      </ng-content>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template
  #recursiveList
  let-list
  let-levelType="type"
  let-levelNum="level"
  let-maxMenuItemsInRow="maxMenuItemsInRow"
>
  <ng-container *ngFor="let item of list; let first = first; let last = last">
    <li
      class="site-menu-item site-menu-item-{{ item.id }} levelnum-{{
        levelNum
      }} levelType-{{ levelType }}"
      id="site-menu-item-{{ menuType }}-{{ item.id }}"
      [class.site-menu-item--first]="first"
      [class.site-menu-item--home]="item.homePage"
      [class.site-menu-item--last]="last"
      [class.site-menu-item--active]="item.name === page?.name"
      [class.site-menu-item--contains-active]="
        containsActivePage(item.children)
      "
      [ngClass]="{
        'site-menu-item-top': (!item?.parentId || (menuFilter === menuFilterType.ALLCHILDREN && levelNum === 1)) && !levelType,
        'site-menu-item--has-children': !!item?.children?.length,
      }"
      (mouseenter)="checkChildPosition(item.id)"
      (mouseleave)="hideMenuItem(item.id)"
    >
      <ng-container [ngSwitch]="item.type">
        <ng-container *ngSwitchCase="2">
          <a
            class="site-menu-item--link site-menu-item--external-link site-menu-item--link-{{
              item.id
            }}"
            [class.has-children]="item.children.length"
            (touchstart)="touchStart($event, item.id, !item?.parentId)"
            (click)="redirectToExternal(item.attributes)"
            container="body"
          >
            {{ item.title | translate }}
            <i
              class="site-menu-item--link-icon"
              *ngIf="
                showArrows &&
                item?.children?.length &&
                menuType !== siteMenuType.MOBILE &&
                menuType !== siteMenuType.HAMBURGERFIXEDVERTICAL
              "
              [ngClass]="
                item?.parentId || (item?.children?.length && levelType)
                  ? 'icon-chevron-right'
                  : 'icon-chevron-down'
              "
            ></i>
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <span
            class="site-menu-item--link site-menu-item--external-link site-menu-item--link-{{
              item.id
            }}"
            [class.has-children]="item?.children?.length"
            (touchstart)="touchStart($event, item.id, !item?.parentId)"
            container="body"
          >
            {{ item?.title | translate }}
            <i
              class="site-menu-item--link-icon"
              *ngIf="
                showArrows &&
                item?.children?.length &&
                menuType !== siteMenuType.MOBILE &&
                menuType !== siteMenuType.HAMBURGERFIXEDVERTICAL
              "
              [ngClass]="
                item?.parentId || (item?.children?.length && levelType)
                  ? 'icon-chevron-right'
                  : 'icon-chevron-down'
              "
            ></i>
          </span>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <a
            class="site-menu-item--link site-menu-item--link-{{ item.id }}"
            [class.has-children]="item?.children?.length"
            [routerLink]="generateUrl(item.urlSegments || item.name)"
            (touchstart)="touchStart($event, item.id, !item?.parentId)"
            container="body"
          >
            {{ item.title }}
            <i
              class="site-menu-item--link-icon"
              *ngIf="
                showArrows &&
                item?.children?.length &&
                menuType !== siteMenuType.MOBILE &&
                menuType !== siteMenuType.HAMBURGERFIXEDVERTICAL
              "
              [ngClass]="
                item.parentId || (item?.children?.length && levelType)
                  ? 'icon-chevron-right'
                  : 'icon-chevron-down'
              "
            ></i>
          </a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="item?.children?.length">
        <button
          class="site-menu-item--toggle"
          *ngIf="
            menuType === siteMenuType.MOBILE ||
            menuType === siteMenuType.HAMBURGERFIXEDVERTICAL ||
            menuType === siteMenuType.VERTICALWITHBUTTONS
          "
          (click)="showSubmenu(item, levelNum)"
        >
          <i
            class="site-menu-item--toggle-icon"
            [ngClass]="[
              menuType === siteMenuType.VERTICALWITHBUTTONS
                ? openedSections.indexOf(item.id) !== -1
                  ? closeIcon
                  : openIcon
                : openedSections.indexOf(item.id) !== -1
                ? 'icon-calcminus'
                : 'icon-calcplus',
              openedSections.indexOf(item.id) !== -1 ? 'submenu-active' : ''
            ]"
          ></i>
        </button>

        <ng-container [ngSwitch]="true">
          <ng-container
            *ngSwitchCase="
              menuType === siteMenuType.MOBILE ||
              menuType === siteMenuType.HAMBURGERFIXEDVERTICAL ||
              menuType === siteMenuType.VERTICALWITHBUTTONS
            "
          >
            <ul
              class="site-menu-item--list site-menu-item--submenu site-menu-item--submenu-{{
                item.id
              }}"
              [@openClose]="
                openedSections.indexOf(item.id) !== -1 ? 'open' : 'closed'
              "
              [class.site-menu-isopen]="openedSections.indexOf(item.id) !== -1"
              (@openClose.start)="animationStatus($event, item.id, 'start')"
              (@openClose.done)="animationStatus($event, item.id, 'done')"
            >
              <ng-container
                *ngTemplateOutlet="
                  recursiveList;
                  context: { $implicit: item.children }
                "
              ></ng-container>
            </ul>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container
              *ngIf="
                (!item.parentId ||
                  (menuFilter === menuFilterType.ALLCHILDREN &&
                    levelNum === 1)) &&
                  !levelType &&
                  addAnimation;
                else noAnimation
              "
            >
              <ul
                class="site-menu-item--list site-menu-item--submenu site-menu-item--submenu-{{
                  item.id
                }}"
                [@menuAnimation]="{
                  value: menuHovered === item.id ? 'open' : 'closed',
                  params: {
                    paddingTopOpen: paddingTopOpen + 'px',
                    paddingTopClosed: paddingTopClosed + 'px',
                    animationSpeed: animationSpeed + 's'
                  }
                }"
                (@menuAnimation.start)="
                  toggleStartAnimationData($event, item.id)
                "
                (@menuAnimation.done)="toggleDoneAnimationData($event, item.id)"
              >
                <ng-container
                  *ngTemplateOutlet="
                    recursiveList;
                    context: { $implicit: item.children, type: 'submenu' }
                  "
                ></ng-container>
              </ul>
            </ng-container>

            <ng-template #noAnimation>
              <ul
                class="site-menu-item--list site-menu-item--submenu site-menu-item--submenu-{{
                  item.id
                }}"
              >
                <ng-container
                  *ngTemplateOutlet="
                    recursiveList;
                    context: { $implicit: item.children, type: 'submenu' }
                  "
                ></ng-container>
              </ul>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>
    </li>
  </ng-container>
</ng-template>

<ng-template #hamburgerButton>
  <button
    class="hamburger hamburger--elastic site-menu-button--hamburger"
    [class.is-active]="isActive"
    (click)="toggleMenu()"
    type="button"
    aria-label="Menu"
    aria-controls="navigation"
    aria-expanded="true/false"
  >
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
    <ng-container *ngIf="hamburgerTitle !== ''">
      {{ hamburgerTitle | translate }}
    </ng-container>
  </button>
</ng-template>

<ng-template #fixedDummyContainer>
  <div
    class="site-menu-dummy-container"
    id="site-menu-{{ menuType }}-{{ menuID }}-dummy-container"
    *ngIf="menuFixed"
    [ngStyle]="{ height: containerHeight + 'px' }"
  ></div>
</ng-template>

<ng-template #horizontalMenu let-menu>
  <ul
    class="site-menu site-menu--{{ menuType }}"
    id="site-menu-{{ menuType }}-{{ menuID }}"
    #standardMenu
    [ngClass]="[menuVisible ? 'menu-is-visible' : '']"
    [ngStyle]="{
      'max-height': menuHeight ? menuHeight + 'px' : ''
    }"
  >
    <ng-container
      *ngTemplateOutlet="
        recursiveList;
        context: { $implicit: menu, level: 1, maxMenuItemsInRow: maxMenuItems }
      "
    ></ng-container>
    <ng-container
      *ngIf="
        menuType === siteMenuType.HORIZONTAL && menuItemsMoreList as menuMore
      "
    >
      <li
        class="site-menu-item site-menu-item--more site-menu-item-top"
        id="more-items-{{ menuType }}-{{ menuID }}"
        #moreitems
        [class.site-menu-item--contains-active]="
          containsActivePage(menuItemsMoreList)
        "
        [ngClass]="
          menuItemsMoreList.length > 0
            ? 'has-children'
            : 'site-menu-item--more-hidden'
        "
        (mouseenter)="checkChildPosition('-more')"
        (mouseleave)="hideMenuItem('-more')"
      >
        <ng-container *ngIf="menuItemsMoreList.length > 0">
          <span class="site-menu-item--link site-menu-item--link-more">{{
            'More' | translate
          }}</span>
        </ng-container>
        <ng-container *ngIf="addAnimation === true; else noMenuAnimations">
          <ul
            class="site-menu-item--list site-menu-item--submenu site-menu-item--submenu--more"
            *ngIf="menuItemsMoreList"
            [@menuAnimation]="{
              value: menuHovered === menuID ? 'open' : 'closed',
              params: {
                paddingTopOpen: paddingTopOpen + 'px',
                paddingTopClosed: paddingTopClosed + 'px',
                animationSpeed: animationSpeed + 's'
              }
            }"
            (@menuAnimation.start)="toggleStartAnimationData($event, '-more')"
            (@menuAnimation.done)="toggleDoneAnimationData($event, menuID)"
          >
            <ng-container
              *ngTemplateOutlet="
                recursiveList;
                context: {
                  $implicit: menuMore,
                  type: 'more'
                }
              "
            ></ng-container>
          </ul>
        </ng-container>
        <ng-template #noMenuAnimations>
          <ul
            class="site-menu-item--list site-menu-item--submenu site-menu-item--submenu--more"
          >
            <ng-container
              *ngTemplateOutlet="
                recursiveList;
                context: {
                  $implicit: menuMore,
                  type: 'more'
                }
              "
            ></ng-container>
          </ul>
        </ng-template>
      </li>
    </ng-container>
  </ul>
</ng-template>

<ng-template #menuList let-menu>
  <ul
    class="site-menu site-menu--{{ menuType }}"
    id="site-menu-{{ menuType }}-{{ menuID }}"
    #standardMenu
    *ngIf="menu.length"
    [ngClass]="[
      menuVisible ? 'menu-is-visible' : '',
      menuType === siteMenuType.VERTICALWITHBUTTONS ? extendSide : ''
    ]"
    [ngStyle]="{
      'max-height': menuHeight ? menuHeight + 'px' : ''
    }"
  >
    <ng-container
      *ngTemplateOutlet="recursiveList; context: { $implicit: menu, level: 1 }"
    ></ng-container>
  </ul>
</ng-template>
