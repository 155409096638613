<div class="policies-element">
  <h3 class="sectionHeader h3" *ngIf="header">
    {{ header | translate }}
  </h3>
  <div class="policy-content">
    <ng-container
      *ngFor="let policyGroup of filteredPoliciesGroups$ | ngrxPush"
    >
      <div class="policy-content-item">
        <h4 class="policy-header" [ngClass]="headerAlign">
          {{ policyGroup.name }}
        </h4>
        <ul class="policy-list" [ngClass]="align">
          <ng-container *ngIf="!policyGroup.policies.length">
            <p class="policy-list-empty">No policies in group</p>
          </ng-container>
          <ng-container *ngFor="let policyItem of policyGroup.policies">
            <li
              class="policy-list-item"
              (click)="handleOnDownload(policyItem.file)"
            >
              <i class="material-icons type-icon icon">description</i>
              {{ policyItem.name }}
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-container>
  </div>
</div>
