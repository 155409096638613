/** MATERIAL UI */
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  CommonModule,
  isPlatformBrowser,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Inject,
  NgModule,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import {
  initializeApp,
  provideFirebaseApp,
} from '@angular/fire/app';
import {
  getAuth,
  provideAuth,
} from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatCommonModule,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  Router,
  UrlSerializer,
} from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
/** */
/** WA-CORE && Jotter3 packages */
import {
  AppToastComponent,
  CommonComponentsModule,
  J3_LINK_PROVIDER,
} from '@jotter3/common-components';
import {
  APP_ENV,
  AppType,
  IGdprDataProvider,
  J3TranslateService,
  StudybugsProvider,
  TRANSLATE_PROVIDER,
  USE_GOOGLE_TRANSLATE,
  XTimezoneInterceptor,
} from '@jotter3/common-helpers';
import {
  ContentComponentsModule,
  contentElements,
} from '@jotter3/content-components';
import {
  ErrorHandlerInterceptor,
  J3CoreModule,
} from '@jotter3/core';
import {
  SitesCoreModule,
  SNACKBAR_COMPONENT,
} from '@jotter3/sites-core';
import {
  templateComponents,
  TemplateComponentsModule,
} from '@jotter3/template-components';
import {
  JsonResponseHttpInterceptor,
  WaCoreModule,
} from '@jotter3/wa-core';
import {
  NgbCarouselModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { PushModule } from '@ngrx/component';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { Request } from 'express';
import { BehaviorSubject } from 'rxjs';
import { SwiperModule } from 'swiper/angular';
import { urlJoin } from 'url-join-ts';

import { environment } from '../environments/environment';
import { ApiConnectorInitializeModule } from './api-connector.initialize.module';
import { AppComponent } from './app.component';
/** */
import { AppRoutingModule } from './app-routing.module';
import {
  AppInitializerService,
  AuthInterceptor,
  BrowserStateInterceptor,
  GdprDataService,
  J3LinkProvider,
  LowerCaseUrlSerializer,
  StudybugsService,
  XTenantInterceptor,
} from './common';
import { J3TranslateClientProvider } from './common/services/j3-translate-client.provider';
import { J3TranslateHttpLoader } from './common/services/j3-translate-http-loader';
import { components } from './components';
import {
  SiteContentsDataService,
  SiteTemplatesDataService,
} from './services';
import { SharedModule } from './shared';

export const translateLoaderFactory = (http: HttpClient): J3TranslateHttpLoader =>
  new J3TranslateHttpLoader(http, environment.translationAsset, '.json');

@NgModule({
  declarations: [
    AppComponent,
    ...components,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule.withServerTransition({ appId: 'webanywhereClient' }),
    BrowserAnimationsModule,
    SharedModule,
    WaCoreModule.forRoot({ ...environment.ApiConnectorConfig }),
    AngularFireModule.initializeApp({ ...environment.Firebase }),
    provideFirebaseApp(() => initializeApp({ ...environment.Firebase })),
    provideAuth(() => getAuth()),
    CommonComponentsModule,
    FormlyModule.forRoot(),
    FormlyBootstrapModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    J3CoreModule.forRoot({
      DB_URL: environment.ApiConnectorConfig.DB_URL,
      DB_URL_VPC: environment.ApiConnectorConfig.DB_URL_VPC,
      LOCAL_DOMAIN: environment.tenantDomain,
      APP_TYPE: 'client',
    }),
    ContentComponentsModule.forRoot(AppType.CLIENT, SiteContentsDataService, environment),
    TemplateComponentsModule.forRoot(SiteTemplatesDataService),
    SitesCoreModule.WithComponents([...contentElements], [...templateComponents], environment.isServer),
    ReactiveFormsModule,
    DragDropModule,
    MatCardModule,
    MatCommonModule,
    MatNativeDateModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatTabsModule,
    NgbModule,
    NgbCarouselModule,
    AppRoutingModule,
    FontAwesomeModule,
    SwiperModule,
    ApiConnectorInitializeModule,
    PushModule,
    AppToastComponent,
  ],
  providers: [
    AppInitializerService,
    J3TranslateService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => (): void => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XTenantInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonResponseHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XTimezoneInterceptor,
      multi: true,
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    {
      provide: IGdprDataProvider,
      useClass: GdprDataService,
    },
    {
      provide: StudybugsProvider,
      useClass: StudybugsService,
    },
    {
      provide: TranslateService,
      useClass: J3TranslateService,
    },
    {
      provide: TRANSLATE_PROVIDER,
      useClass: J3TranslateClientProvider,
    },
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: environment.STATIC_CDN
        ? urlJoin(environment.STATIC_CDN, 'external_libraries/tinymce/tinymce.min.js')
        : 'external_libraries/tinymce/tinymce.min.js',
    },
    {
      provide: USE_GOOGLE_TRANSLATE,
      useValue: new BehaviorSubject<boolean>(true),
    },
    {
      provide: APP_ENV,
      useValue: environment,
    },
    {
      provide: J3_LINK_PROVIDER,
      useClass: J3LinkProvider,
    },
    {
      provide: SNACKBAR_COMPONENT,
      useValue: {},
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) platformId: object,
    @Optional() @Inject(REQUEST) req: Request,
    readonly translateService: TranslateService,
    readonly j3TranslateService: J3TranslateService
  ) {
    const lang = isPlatformBrowser(platformId) ? 'en' : req?.cookies?.lang;

    translateService.use(lang || 'en');
    j3TranslateService.use(lang || 'en');
  }
}
