import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Type,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatCommonModule,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { dateValidators } from '@jotter3/common-helpers';
import {
  IconRegistryService,
  IconsModule,
  vaIconsAlignLeft,
  vaIconsCalendar,
  vaIconsClose,
  vaIconsContacts,
  vaIconsDocument,
  vaIconsDoubleLeftArrows,
  vaIconsFacebook,
  vaIconsFaq,
  vaIconsGallery,
  vaIconsHome,
  vaIconsImage,
  vaIconsLink,
  vaIconsList,
  vaIconsLocation,
  vaIconsMail,
  vaIconsNews,
  vaIconsSingleLineTextInput,
  vaIconsSourceCode,
  vaIconsTwitter,
  vaIconsVideo,
  vaIconsVideoCall,
} from '@jotter3/icons';
import {
  SiteBaseComponent,
  TemplateBaseComponent,
} from '@jotter3/sites-abstract';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';

import {
  CMS_CONTENT_COMPONENTS,
  CMS_TEMPLATE_COMPONENTS,
} from './common';
import { ContentBuilderComponent } from './components/content-builder/content-builder.component';
import { ElementContentPresenterComponent } from './components/element-content-presenter/element-content-presenter.component';
import { ElementSelectorComponent } from './components/element-selector/element-selector.component';
import { ElementSettingsDesignerComponent } from './components/element-settings-designer/element-settings-designer.component';
import { RowSettingsComponent } from './components/row-settings/row-settings.component';
import { TemplateResolverComponent } from './components/template-resolver.component/template-resolver.component';
import {
  AudienceTargetingDirective,
  TemplateAdditionalActionsDirective,
} from './directives';
import { pipes } from './pipes';
import {
  ContentBuilderManagerService,
  DynamicFormBuilderService,
  TemplateParserHtml,
} from './services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    DragDropModule,
    IconsModule,
    MatCardModule,
    MatCommonModule,
    MatNativeDateModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatTabsModule,
    FormlyModule.forChild({
      validators: [
        {
          name: 'J3BaseContentComponentDatesValidator',
          validation: dateValidators.dateValidator,
        },
      ],
    }),
    NgbModule,
    IconsModule,
  ],
  declarations: [
    ElementContentPresenterComponent,
    ElementSettingsDesignerComponent,
    ElementSelectorComponent,
    RowSettingsComponent,
    ContentBuilderComponent,
    TemplateAdditionalActionsDirective,
    TemplateResolverComponent,
    AudienceTargetingDirective,
    ...pipes,
  ],
  exports: [
    ElementContentPresenterComponent,
    ElementSelectorComponent,
    RowSettingsComponent,
    ContentBuilderComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    TemplateResolverComponent,
    TemplateAdditionalActionsDirective,
    AudienceTargetingDirective,
    ...pipes,
  ],
  providers: [
    TemplateAdditionalActionsDirective,
    TemplateParserHtml,
    ContentBuilderManagerService,
    DynamicFormBuilderService,
    AudienceTargetingDirective,
    ...pipes,
  ],
})
export class SitesCoreModule {
  constructor(iconRegistry: IconRegistryService) {
    iconRegistry.registerIcons([
      vaIconsHome,
      vaIconsAlignLeft,
      vaIconsDoubleLeftArrows,
      vaIconsFaq,
      vaIconsImage,
      vaIconsGallery,
      vaIconsVideoCall,
      vaIconsDocument,
      vaIconsList,
      vaIconsNews,
      vaIconsCalendar,
      vaIconsMail,
      vaIconsSingleLineTextInput,
      vaIconsTwitter,
      vaIconsFacebook,
      vaIconsVideo,
      vaIconsLocation,
      vaIconsLink,
      vaIconsContacts,
      vaIconsSourceCode,
      vaIconsClose,
    ]);
  }

  static WithComponents(
    siteComponents: Array<Type<SiteBaseComponent>>,
    templateComponents?: Array<Type<TemplateBaseComponent>>,
    isServer?: boolean
  ): ModuleWithProviders<SitesCoreModule> {
    return {
      ngModule: SitesCoreModule,
      providers: [
        {
          provide: CMS_CONTENT_COMPONENTS,
          useValue: siteComponents,
          multi: true,
        },
        {
          provide: CMS_TEMPLATE_COMPONENTS,
          useValue: [
            ...(templateComponents || []),
            ContentBuilderComponent,
          ],
        },
      ],
    };
  }

  static forChild(
    siteComponents: Array<Type<SiteBaseComponent>> = [],
    templateComponents: Array<Type<TemplateBaseComponent>> = []
  ): ModuleWithProviders<SitesCoreModule> {
    return {
      ngModule: SitesCoreModule,
      providers: [
        {
          provide: CMS_CONTENT_COMPONENTS,
          useValue: siteComponents,
          multi: true,
        },
        {
          provide: CMS_TEMPLATE_COMPONENTS,
          useValue: [
            ...(templateComponents || []),
            ContentBuilderComponent,
          ],
        },
      ],
    };
  }
}
