import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  Inject,
} from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
} from '@angular/platform-browser';
import {
  J3TranslateService,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { v4 as UUID } from 'uuid';

import { ToggleContentService } from '../../services';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'embed-code-content-component',
  displayName: 'Embeded Code',
  icon: 'source_code',
  category: SiteComponentCategory.EMBED,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './embed-code.component.html',
  styleUrls: ['./embed-code.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class EmbedCodeSiteElementComponent extends JotterSitesBaseComponent {
  embedCode = '';
  embedSafeUrl: SafeHtml;
  isActive = false;
  elementId: string = UUID();
  itemPosition = '0px';

  public showElement = false;
  public clientPage = false;

  constructor(
    private sanitizer: DomSanitizer,
    private toggleContent: ToggleContentService,
    private store: Store,
    @Inject(MODULE_PROVIDER_TOKEN) private moduleProvider: ModuleProvider,
    private _sanitizer: DomSanitizer
  ) {
    super(EmbedCodeSiteElementComponent);
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
  })
    header = '';

  @Property({
    displayName: 'Embedded Code',
    modifierType: ModifierType.TEXT_AREA,
    required: true,
  })
  get embedCodeHtml(): string {
    return this.cleanScripts(this.embedCode);
  }
  set embedCodeHtml(value: string) {
    this.embedCode = value;
    this.embedSafeUrl = this.getSafeUrl(value);
  }

  private getSafeUrl(link: string | SafeResourceUrl): SafeResourceUrl | undefined {
    if (!link || this.platformServer) {
      return undefined;
    }
    const cleanHtml = this.cleanScripts(this.embedCode);
    return this.sanitizer.bypassSecurityTrustHtml(cleanHtml);
  }

  public toggleItem(status: boolean, id: string): void {
    this.isActive = status;
    this.itemPosition = this.toggleContent.toggleContent(status, id);
  }

  public animationOverflow(status: boolean, id: string): void {
    this.toggleContent.toggleOverflow(status, id);
  }

  public override getDataset(): { [key: string]: any } {
    const dataset = super.getDataset();
    const { showElement, ...rest } = dataset;
    return rest;
  }

  public override setDataset(data: { [key: string]: any }): void {
    const { showElement, ...rest } = data || {};
    super.setDataset(rest);

    if (!rest) {
      return;
    }
  }

  public cleanScripts(data: string): string {
    return cloneDeep(data)
      .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gim, '')
      .replace(
        // eslint-disable-next-line no-useless-backreference
        / \bon[a-z]+\s*=\s*(?:(['"]).+?\1|(?:\S+?\(.*?\)(?=[\s>])))|["']javascript:.+["']\1/gim,
        ''
      );
  }
}
