import {
  Component,
  Inject,
  OnInit,
  Type,
} from '@angular/core';
import { reflectMetadata } from '@jotter3/reflection-core';
import {
  constant,
  SiteBaseComponent,
  SiteComponentCategory,
  SiteComponentItem,
  SiteComponentMetadata,
} from '@jotter3/sites-abstract';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isNil } from 'lodash-es';

import { CMS_CONTENT_COMPONENTS } from '../../common';
import {
  contentBuilderModel,
  templateBuilderModel,
} from '../../models';

@Component({
  templateUrl: './element-selector.component.html',
  styleUrls: ['./element-selector.component.scss'],
})
export class ElementSelectorComponent implements OnInit {
  public items: SiteComponentItem[] = [];

  public siteCategories = [
    SiteComponentCategory.TEXT,
    SiteComponentCategory.MEDIA,
    SiteComponentCategory.MODULES,
    SiteComponentCategory.EMBED,
    SiteComponentCategory.OTHER,
  ];

  public siteCategoryColours = [
    '#C2F1EB',
    '#FFDDCE',
    '#C4E5F7',
    '#DCD2EE',
    '#E7E7E7',
  ];

  private containerAPI: templateBuilderModel.GridBuilderApi | contentBuilderModel.ContentBuilderApi;

  constructor(
    @Inject(CMS_CONTENT_COMPONENTS) private componentsDef: Type<SiteBaseComponent>[][],
    public activeModal: NgbActiveModal
  ) {}

  public ngOnInit(): void {
    this.items = this.componentsDef
      .flat()
      .map((item) =>
        (reflectMetadata.getComponentMetadata<SiteComponentMetadata>(constant.COMPONENT_DESCRIPTION_META_KEY, item) || [])
          .flat()
          .map((element) => ({
            ...element,
            component: item,
          })))
      .flat()
      .filter((x) => isNil(x.selectedByUser) || x.selectedByUser);
  }

  public onCloseDialog(): void {
    this.activeModal.close(false);
  }

  public onAddItem(item: SiteComponentItem): void {
    this.activeModal.close();
    this.containerAPI.addElement(item as any, this.containerAPI.index);
  }
}
