import {
  inject,
  Injectable,
} from '@angular/core';
import { AppStoreActions } from '@jotter3/api-connector';
import {
  Actions,
  ofType,
} from '@ngrx/effects';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

export interface Toast {
      id: string;
      title: string;
      description?: string;
      classname?: string;
      delay?: number;
      type: 'success' | 'error' | 'warning' | 'info' | 'question';
  }

  @Injectable({ providedIn: 'root' })
export class AppToastService {
  readonly #storeActions$ = inject(Actions);
  public toasts: Toast[] = [];

  #show(toast: Toast): void {
    this.toasts.push(toast);
  }

  public successToast(title: string = 'Success!', description?: string): void {
    this.#show({
      id: UUID(),
      title,
      description,
      classname: 'bg-success text-light',
      type: 'success',
    });
  }

  public errorToast(title: string = 'Error!', description?: string): void {
    this.#show({
      id: UUID(),
      title,
      description,
      classname: 'bg-danger text-light',
      type: 'error',
    });
  }

  public neutralToast(title: string = 'Warning!', description?: string): void {
    this.#show({
      id: UUID(),
      title,
      description,
      type: 'info',
    });
  }

  public remove(id: string): void {
    this.toasts = this.toasts.filter((t) => t.id !== id);
  }

  public observeAppErrors(): Observable<unknown> {
    return this.#storeActions$.pipe(
      ofType(AppStoreActions.showToastError),
      tap(({ title, description }) => {
        console.log('aaaa', title, description);
        this.errorToast(title, description);
      })
    );
  }
}
