import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import {
  Observable,
  of,
} from 'rxjs';
import { v4 as UUID } from 'uuid';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-formly-select',
  templateUrl: './select.field.template.html',
})
export class SelectFieldComponent extends FieldType {
  uniqueID: string = UUID();

  get selectOptions(): Observable<any[]> {
    return this.to?.options instanceof Observable
      ? (this.to?.options as Observable<any[]>)
      : of((this.to?.options as any[]) || []);
  }
}
