import { isPlatformBrowser } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import {
  makeStateKey,
  TransferState,
} from '@angular/platform-browser';
import { AppTenantInfoService } from '@jotter3/core';
import {
  Observable,
  of,
} from 'rxjs';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root',
})
export class BrowserStateInterceptor implements HttpInterceptor {
  constructor(
    private readonly transferState: TransferState,
    private readonly appTenantInfoService: AppTenantInfoService,
    @Inject(PLATFORM_ID) private readonly platformId: object
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { method, urlWithParams } = req;

    if (method === 'GET' && isPlatformBrowser(this.platformId)) {
      const key = makeStateKey<any>(`${this.appTenantInfoService.tenantId || ''}|${Md5.hashStr(urlWithParams)}`);
      const storedResponse: string = this.transferState.get(key, null);
      if (storedResponse) {
        const response = new HttpResponse({
          body: storedResponse,
          status: 200,
        });

        return of(response);
      }
    }

    return next.handle(req);
  }
}
