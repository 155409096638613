const pluralize = (singularForm: string): string => {
  if (!singularForm) {
    return null;
  }

  if (
    singularForm.endsWith('ies') ||
    singularForm.endsWith('es') ||
    (!singularForm.endsWith('us') && singularForm.endsWith('s'))
  ) {
    return singularForm;
  }

  if (singularForm.endsWith('y') && !singularForm.endsWith('ay')) {
    return `${singularForm.substring(0, singularForm.length - 1)}ies`;
  }

  if (singularForm.endsWith('us')) {
    return `${singularForm}es`;
  }

  return `${singularForm}s`;
};

const toKebabCase = (value: string): string => value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export { pluralize,
  toKebabCase };
