
import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createAction,
  props,
} from '@ngrx/store';

import { DirectoryDomainModel } from '../../domain-models';



const ACTIONS_PREFIX = '[J3|API|DIRECTORIES]';

export const DirectoriesStoreActions = {
  loadTrigger: createAction(`${ACTIONS_PREFIX} Load trigger`),
  loadSuccess: createAction(`${ACTIONS_PREFIX} Load success`, props<{ entity: Array<DirectoryDomainModel> }>()),
  loadFailed: createAction(`${ACTIONS_PREFIX} Load failed`, props<{ errors: Array<ApiErrorResponse | unknown> }>()),
  setSelected: createAction(`${ACTIONS_PREFIX} Set selected`, props<{ entity: DirectoryDomainModel }>()),
  upsertTrigger: createAction(`${ACTIONS_PREFIX} Upsert trigger`, props<{ entity: DirectoryDomainModel }>()),
  upsertSuccess: createAction(`${ACTIONS_PREFIX} Upsert success`, props<{ entity: DirectoryDomainModel }>()),
  upsertFailed: createAction(`${ACTIONS_PREFIX} Upsert failed`, props<{ errors: Array<ApiErrorResponse | unknown> }>()),
  deleteTrigger: createAction(`${ACTIONS_PREFIX} Delete trigger`, props<{ entityId: string }>()),
  deleteSuccess: createAction(`${ACTIONS_PREFIX} Delete success`, props<{ entityId: string }>()),
  deleteFailed: createAction(`${ACTIONS_PREFIX} Delete failed`, props<{ errors: Array<ApiErrorResponse | unknown> }>()),
};
