import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { routerActions } from '../actions';

export interface RouterState {
  isLoaded: boolean;
  isLoading: boolean;
}

export interface RouterDataState {
  routerData: RouterState;
}

const initialRouterState: RouterDataState = {
  routerData: {
    isLoaded: false,
    isLoading: false,
  },
};

const routerReturn = createReducer<RouterDataState>(
  initialRouterState,
  on(routerActions.LoadRouter, (state) => ({
    ...state,
    routerData: {
      ...state.routerData,
      isLoading: true,
    },
  })),
  on(routerActions.LoadRouterComplete, (state, { routerData }) => ({
    ...state,
    routerData: {
      ...state.routerData,
      ...routerData,
      isLoaded: true,
      isLoading: false,
    },
  })),
  on(routerActions.LoadRouterFailed, (state) => ({
    ...state,
    routerData: {
      ...state.routerData,
      isLoading: false,
      isLoaded: false,
    },
  }))
);

export const routerReducer = (state: RouterDataState = initialRouterState, action: Action): RouterDataState =>
  routerReturn(state, action);
