
export enum PicturesSizes {
  XS = 'xs',
  S = 's',
  M = 'm',
  ML = 'ml',
  L = 'l'
}

export const ImageSize: Record<PicturesSizes, number | null> = {
  [PicturesSizes.XS]: 200,
  [PicturesSizes.S]: 400,
  [PicturesSizes.M]: 800,
  [PicturesSizes.ML]: 1300,
  [PicturesSizes.L]: null,
};
