import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createAction,
  props,
} from '@ngrx/store';

import { SiteDomainModel } from '../../domain-models';

const ACTIONS_PREFIX = '[J3|API|SITES]';

export const SiteStoreActions = {
  loadTrigger: createAction(`${ACTIONS_PREFIX} Load trigger`, props<{ domain: string }>()),
  loadSuccess: createAction(`${ACTIONS_PREFIX} Load success`, props<{ entity: SiteDomainModel }>()),
  loadFailed: createAction(`${ACTIONS_PREFIX} Load failed`, props<{ errors: Array<ApiErrorResponse | unknown> }>()),
  updateTrigger: createAction(`${ACTIONS_PREFIX} Update trigger`, props<{ entity: Partial<SiteDomainModel> }>()),
  updateSuccess: createAction(`${ACTIONS_PREFIX} Update success`, props<{ entity: Partial<SiteDomainModel> }>()),
  updateFailed: createAction(`${ACTIONS_PREFIX} Update failed`, props<{ errors: Array<ApiErrorResponse | unknown> }>()),
  cookiesAccepted: createAction(`${ACTIONS_PREFIX} Cookies accepted`),
  cookiesHasBeenShown: createAction(`${ACTIONS_PREFIX} Cookies has been shown`),
};
