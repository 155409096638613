import { Injectable } from '@angular/core';
import {
  ApiErrorResponse,
  ApiService,
} from '@jotter3/wa-core';
import {
  ComponentStore,
  tapResponse,
} from '@ngrx/component-store';
import { isNil } from 'lodash-es';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { NewsletterSubscriptionModel } from './newsletter-subscription.model';

interface NewsletterComponentState {
  loadingState: 'LOADING' | 'PENDING' | 'SUCCESS' | 'FAILED';
  error?: ApiErrorResponse;
}

const initialState: NewsletterComponentState = {
  loadingState: 'PENDING',
};

@Injectable()
export class NewsletterSubscriptionComponentStore extends ComponentStore<NewsletterComponentState> {
  readonly #recaptchaService: ReCaptchaV3Service;
  readonly #apiService: ApiService;

  constructor(private recaptchaService: ReCaptchaV3Service, private apiService: ApiService) {
    super({ ...initialState });

    this.#recaptchaService = recaptchaService;
    this.#apiService = apiService;
  }

  readonly #onResetState = this.updater(() => ({ ...initialState }));

  readonly #onStartLoading = this.updater(
    (state: NewsletterComponentState): NewsletterComponentState => ({
      ...state,
      error: undefined,
      loadingState: 'LOADING',
    })
  );

  readonly #onLoadedSuccess = this.updater(
    (state: NewsletterComponentState): NewsletterComponentState => ({
      ...state,
      error: undefined,
      loadingState: 'SUCCESS',
    })
  );

  readonly #onLoadedFailed = this.updater(
    (state: NewsletterComponentState, error: ApiErrorResponse): NewsletterComponentState => ({
      ...state,
      error,
      loadingState: 'FAILED',
    })
  );

  readonly selectIsLoadedSuccess$: Observable<boolean> = this.select((state) => state.loadingState === 'SUCCESS');

  readonly selectIsLoadedFailed$: Observable<boolean> = this.select((state) => state.loadingState === 'FAILED');

  readonly selectIsLoading$: Observable<boolean> = this.select((state) => state.loadingState === 'LOADING');

  readonly selectErrors$: Observable<ApiErrorResponse> = this.select((state: NewsletterComponentState) => state.error);

  readonly selectHasErrors$ = this.selectErrors$.pipe(map((error) => !!error));

  readonly subscribeToNewsletter = this.effect((formData$: Observable<NewsletterSubscriptionModel>) =>
    formData$.pipe(
      filter((formData) => !isNil(formData)),
      tap(() => this.#onStartLoading()),
      withLatestFrom(this.recaptchaService.execute('NewsletterSubscription')),
      map(([
        formData,
        recaptchaToken,
      ]) => ({
        ...formData,
        recaptchaToken,
        modules: [
          'gallery',
          'letter',
          'news',
        ],
      })),
      switchMap((formData) =>
        this.#apiService.save<NewsletterSubscriptionModel>('email_subscriptions', formData).pipe(
          tapResponse(
            ({ success, error }) => {
              if (!success) {
                return this.#onLoadedFailed(error);
              }

              return this.#onLoadedSuccess();
            },
            (error) => this.#onLoadedFailed(error as any)
          )
        ))
    ));

  readonly resetState = this.effect<void>((triggers$) => triggers$.pipe(tap(() => this.#onResetState())));
}
