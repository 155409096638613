<ng-container *ngIf="cssClass === 'toggle-element'; else standard">
  <j3-toggle-info-component *ngIf="designMode"> </j3-toggle-info-component>
  <ng-container *ngIf="!designMode; else standard">
    <div
      (mouseenter)="toggleItem(true, elementId)"
      (mouseleave)="toggleItem(false, elementId)"
    >
      <div
        class="sectionHeader text-center h3 mb-0"
        [id]="'element-header-' + elementId"
      >
        {{ header | ngrxPush }}
      </div>
      <div
        class="letters-wrapper section-toggle-wrapper absolute-section"
        [@openClose]="this.isActive ? 'open' : 'closed'"
        [id]="'element-wrapper-' + elementId"
        [ngClass]="{
          'section-toggle-wrapper-active': isActive
        }"
        [ngStyle]="{ bottom: isActive ? itemPosition : '0' }"
        (@openClose.done)="animationOverflow(false, elementId)"
        (@openClose.start)="animationOverflow(true, elementId)"
      >
        <div>
          <ng-container [ngTemplateOutlet]="contentData"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #standard>
  <div [ngClass]="cssClass">
    <div class="sectionHeader text-center h3 mb-0" *ngIf="!!header">
      {{ header | ngrxPush }}
    </div>
    <ng-container [ngTemplateOutlet]="contentData"></ng-container>
  </div>
</ng-template>

<ng-template #contentData>
  <div
    class="p-3 w-100 h-100 text-center bg-secondary ng-star-inserted"
    *ngIf="designMode && !imageSRC"
  >
    ENTER IMAGE SOURCE
  </div>
  <ng-container *ngIf="imageSRC">
    <ng-container *ngIf="imageSRC?.icon || imageSRC?.url; else text">
      <div
        class="image-wrapper element-image-wrapper"
        [ngClass]="cssHeight ? 'container-size' : 'container-inline'"
      >
        <ng-container *ngIf="linkType; else nolink">
          <ng-container *ngIf="linkType === enumsValues.MAIL">
            <span class="image-link-active" *ngIf="designMode && mailto">
              Image link active
            </span>
            <ng-container *ngIf="!designMode && mailto; else nolink">
              <figure>
                <a
                  class="image-container"
                  [target]="target ? '_blank' : '_self'"
                  href="mailto:{{ mailto }}"
                >
                  <div
                    class="sj-image-wrapper"
                    [style.max-width]="linkType ? cssWidth : 'inherit'"
                    [style.max-height]="cssHeight"
                    ngClass="{{ align ? align : 'align-left' }} {{
                      cssHeight ? 'aspect-ratio' : ''
                    }}"
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        image;
                        context: { $implicit: imageSRC }
                      "
                    ></ng-container>
                  </div>

                  <j3-image-caption
                    class="image-caption"
                    *ngIf="(captionTitle || captionText) && captionsInside"
                    [captionTitle]="captionTitle"
                    [captionText]="captionText"
                    [align]="align"
                  ></j3-image-caption>
                </a>
                <j3-image-caption
                  class="image-caption"
                  *ngIf="(captionTitle || captionText) && !captionsInside"
                  [captionTitle]="captionTitle"
                  [captionText]="captionText"
                  [align]="align"
                ></j3-image-caption>
              </figure>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="linkType === enumsValues.LINK_CMS_TO_PAGE">
            <span
              class="image-link-active"
              *ngIf="designMode && selectedCmsUrl"
            >
              Image link active
            </span>
            <ng-container *ngIf="!designMode && selectedCmsUrl; else nolink">
              <figure>
                <a
                  class="image-container"
                  [routerLink]="generateUrl(selectedCmsUrl)"
                  [target]="target ? '_blank' : '_self'"
                >
                  <div
                    class="sj-image-wrapper"
                    [style.max-width]="linkType ? cssWidth : 'inherit'"
                    [style.max-height]="cssHeight"
                    ngClass="{{ align ? align : 'align-left' }} {{
                      cssHeight ? 'aspect-ratio' : ''
                    }}"
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        image;
                        context: { $implicit: imageSRC }
                      "
                    ></ng-container>
                  </div>

                  <j3-image-caption
                    class="image-caption"
                    *ngIf="(captionTitle || captionText) && captionsInside"
                    [captionTitle]="captionTitle"
                    [captionText]="captionText"
                    [align]="align"
                  ></j3-image-caption>
                </a>
                <j3-image-caption
                  class="image-caption"
                  *ngIf="(captionTitle || captionText) && !captionsInside"
                  [captionTitle]="captionTitle"
                  [captionText]="captionText"
                  [align]="align"
                ></j3-image-caption>
              </figure>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="linkType === enumsValues.EXTERNAL_URL">
            <span class="image-link-active" *ngIf="designMode && url">
              Image link active
            </span>
            <ng-container *ngIf="!designMode && url; else nolink">
              <figure>
                <a
                  class="image-container"
                  [href]="url"
                  [target]="target ? '_blank' : '_self'"
                  (click)="onExternalLinkClicked($event)"
                >
                  <div
                    class="sj-image-wrapper"
                    [style.max-width]="linkType ? cssWidth : 'inherit'"
                    [style.max-height]="cssHeight"
                    ngClass="{{ align ? align : 'align-left' }} {{
                      cssHeight ? 'aspect-ratio' : ''
                    }}"
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        image;
                        context: { $implicit: imageSRC }
                      "
                    ></ng-container>
                  </div>

                  <j3-image-caption
                    class="image-caption"
                    *ngIf="(captionTitle || captionText) && captionsInside"
                    [captionTitle]="captionTitle"
                    [captionText]="captionText"
                    [align]="align"
                  ></j3-image-caption>
                </a>
                <j3-image-caption
                  class="image-caption"
                  *ngIf="(captionTitle || captionText) && !captionsInside"
                  [captionTitle]="captionTitle"
                  [captionText]="captionText"
                  [align]="align"
                ></j3-image-caption>
              </figure>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="linkType === enumsValues.LINK_TO_FILE">
            <span class="image-link-active" *ngIf="designMode && resourceUrl">
              Image link active
            </span>
            <ng-container *ngIf="!designMode && resourceUrl; else nolink">
              <figure>
                <a
                  class="a-pointer image-container"
                  (click)="openResourceElement()"
                >
                  <div
                    class="sj-image-wrapper"
                    [style.max-width]="linkType ? cssWidth : 'inherit'"
                    [style.max-height]="cssHeight"
                    ngClass="{{ align ? align : 'align-left' }} {{
                      cssHeight ? 'aspect-ratio' : ''
                    }}"
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        image;
                        context: { $implicit: imageSRC }
                      "
                    ></ng-container>
                  </div>

                  <j3-image-caption
                    class="image-caption"
                    *ngIf="(captionTitle || captionText) && captionsInside"
                    [captionTitle]="captionTitle"
                    [captionText]="captionText"
                    [align]="align"
                  ></j3-image-caption>
                </a>
                <j3-image-caption
                  class="image-caption"
                  *ngIf="(captionTitle || captionText) && !captionsInside"
                  [captionTitle]="captionTitle"
                  [captionText]="captionText"
                  [align]="align"
                ></j3-image-caption>
              </figure>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #nolink>
          <figure>
            <div class="image-container">
              <div
                class="sj-image-wrapper"
                [style.max-height]="cssHeight"
                [style.max-width]="
                  !linkType ||
                  (linkType && designMode) ||
                  (linkType && captionsInside)
                    ? cssWidth
                    : 'inherit'
                "
                ngClass="{{ align ? align : 'align-left' }} {{
                  cssHeight ? 'aspect-ratio' : ''
                }}"
              >
                <ng-container
                  *ngTemplateOutlet="image; context: { $implicit: imageSRC }"
                ></ng-container>
              </div>
              <j3-image-caption
                class="image-caption"
                *ngIf="(captionTitle || captionText) && captionsInside"
                [captionTitle]="captionTitle"
                [captionText]="captionText"
                [align]="align"
              ></j3-image-caption>
            </div>
            <j3-image-caption
              class="image-caption"
              *ngIf="(captionTitle || captionText) && !captionsInside"
              [captionTitle]="captionTitle"
              [captionText]="captionText"
              [align]="align"
            ></j3-image-caption>
          </figure>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #text>
      <div>
        {{ imageSRC.name }}
      </div>
    </ng-template>
  </ng-container>
</ng-template>
<ng-template #image let-imageSRC>
  <j3-image
    [imgSrc]="imageSRC?.icon || imageSRC?.url | ngrxPush"
    [sourceSize]="picturesSizesList(imageSize)"
    ngClass="image-resizer image-default"
    title="{{ captionTitle ? captionTitle : '' }}{{
      captionText && captionTitle ? ' - ' : ''
    }}{{ captionText ? captionText : '' }}"
    alt="{{ captionTitle ? captionTitle : '' }}{{
      captionText && captionTitle ? ' - ' : ''
    }}{{ captionText ? captionText : '' }}"
  ></j3-image>
</ng-template>
