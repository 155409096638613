import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createAction,
  props,
} from '@ngrx/store';

import {
  ResourceDomainModel,
  ResourceListDomainModel,
} from '../../../domain-models';

export const LoadPageContentResources = createAction('[Page Content Resources] Load data', props<{
  resourceIds: string[],
  resourceListIds: string[],
  pageUrl: string,
}>());
export const LoadPageContentResourcesComplete = createAction('[Page Content Resources] Load data completed', props<{ resources: Array<ResourceDomainModel | ResourceListDomainModel> }>());
export const LoadPageContentResourcesFailed = createAction('[Page Content Resources] Load data failed', props<{ error: ApiErrorResponse }>());
export const NoResourcesFound = createAction('[Page Content Resources] No resources found');
