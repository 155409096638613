import { isPlatformServer } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { isNil } from 'lodash-es';
import {
  Observable,
  throwError,
} from 'rxjs';
import { urlJoin } from 'url-join-ts';

import {
  ModuleConfig,
  ModuleOptionsInjectionToken,
} from '../common';
import { pluralNames } from '../common/entities.config';

@Injectable({
  providedIn: 'root',
})
export class UrlParamReplaceInterceptor implements HttpInterceptor {
  private readonly regex = /:\w+/gi;
  constructor(
    @Inject(ModuleOptionsInjectionToken) private readonly moduleConfig: ModuleConfig,
    private readonly activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private readonly platformId: object
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { DB_URL, DB_URL_VPC } = this.moduleConfig;
    const { url } = req;

    const API_URL = isPlatformServer(this.platformId) && DB_URL_VPC ? DB_URL_VPC : DB_URL;

    if (
      this.regex.test(url) &&
      Object.values(pluralNames)
        .map((x) => urlJoin(API_URL, x.toLocaleLowerCase()))
        .find((x) => url.toLocaleLowerCase().startsWith(x))
    ) {
      const urlSegments: string[] = [];

      const params = this.getTreeParams(this.activatedRoute.snapshot);

      for (const urlSegment of url.split('/')) {
        if (this.regex.test(urlSegment)) {
          const paramKey = urlSegment.replace(':', '').trim();
          const paramValue = params[paramKey];

          if (isNil(paramValue)) {
            return throwError(new Error(`missing param ${paramKey} in route`));
          }

          urlSegments.push(paramValue);
          continue;
        }

        urlSegments.push(urlSegment);
      }

      req = req.clone({ url: urlSegments.join('/') });
    }

    return next.handle(req);
  }

  private getTreeParams(route: ActivatedRouteSnapshot): { [key: string]: string } {
    let params: { [key: string]: string } = {};
    params = {
      ...params,
      ...route.params,
    };
    if (route.children?.length) {
      for (const child of route.children) {
        params = {
          ...params,
          ...child.params,
          ...this.getTreeParams(child),
        };
      }
    }

    for (const key of Object.keys(params)) {
      params[key.toLocaleLowerCase()] = params[key];
    }

    return params;
  }
}
