import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import { ItemType } from '../../enums';
import { TemplateDataProvider } from '../../providers';
import { ToggleItemsService } from '../../services/toggle-items.service';

@TemplateComponent({
  selector: 'jotter-tplcmp-socialmedia',
  displayName: 'Social media',
  icon: 'settings',
  defaultClass: 'social-media',
})
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'jotter-tplcmp-socialmedia',
  templateUrl: './social-media.component.html',
  animations: [
    trigger('toggle', [
      state(
        'open',
        style({
          height: '*',
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          overflow: 'hidden',
          paddingTop: 0,
          paddingBottom: 0,
        })
      ),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
  ],
})
export class SocialMediaComponent extends TemplateBaseComponent implements OnInit {
  public $socialMedia: Observable<Record<string, { [key: string]: string }> | undefined> | undefined;
  public ItemTypeEnum = ItemType;
  public isVisible = false;
  public itemId = UUID();

  @Input() iconValue = 'icon-share-alt';
  @Input() iconCustomClass = '';
  @Input() itemType: string = ItemType.STANDARD;

  constructor(private dataProvider: TemplateDataProvider, private toggleService: ToggleItemsService) {
    super(SocialMediaComponent);
  }

  ngOnInit(): void {
    this.$socialMedia = this.dataProvider.getData().pipe(map((res) => res.socialMedia));
  }

  toggle(): void {
    this.toggleService.onToggle(SocialMediaComponent);
    if (this.itemType === ItemType.TOGGLE) {
      this.isVisible = !this.isVisible;
    }
  }
}
