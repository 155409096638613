import { isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  BaseBuilderComponent,
  SettingsDesignerModel,
  SettingsItem,
} from '@jotter3/sites-abstract';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  cloneDeep,
  isUndefined,
} from 'lodash-es';

import { DynamicFormBuilderService } from '../../services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'element-settings-designer',
  templateUrl: './element-settings-designer.component.html',
  styleUrls: ['./element-settings-designer.component.scss'],
})
export class ElementSettingsDesignerComponent implements OnInit, AfterViewInit {
  readonly GROUP_NOT_SPECIFED = 'GROUP_NOT_SPECIFED';

  public $viewInitialized = false;
  public formGroup: FormGroup = new FormGroup({});
  public advancedFormGroup: FormGroup = new FormGroup({});
  public formModel: any = {}; // Dynamic form model;
  public formFieldsNormal: FormlyFieldConfig[] = [];
  public formFieldsAdvanced: FormlyFieldConfig[] = [];
  public settings: SettingsDesignerModel | undefined;
  public showAdvanced = false;

  constructor(
    private activeModal: NgbActiveModal,
    private dynamicFormService: DynamicFormBuilderService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  public ngOnInit(): void {
    if (!this.settings) {
      return;
    }

    const { componentInstance, items, componentMetadata } = this.settings;

    this.formFieldsNormal = this.dynamicFormService.transformToFormly(
      items.filter((item) => !item?.metadata?.['advanced']),
      componentInstance
    );

    this.formFieldsAdvanced = this.dynamicFormService.transformToFormly(
      items.filter((item) => item?.metadata?.['advanced']),
      componentInstance
    );

    if (componentMetadata.settingsDialogSettings) {
      const { advancedSettingsValidators, standardSettingsValidators } = componentMetadata.settingsDialogSettings;

      this.formFieldsNormal = [
        {
          validators: { validation: standardSettingsValidators || [] },
          fieldGroup: [...this.formFieldsNormal].flat(),
        },
      ];

      this.formFieldsNormal = [...((componentInstance as BaseBuilderComponent).beforeFormCreated(this.formFieldsNormal) || this.formFieldsNormal)];

      this.formFieldsAdvanced = [
        {
          validators: { validation: advancedSettingsValidators || [] },
          fieldGroup: [...this.formFieldsAdvanced].flat(),
        },
      ];
    }

    this.formModel = this.getFormModel(items, componentInstance);
  }

  public ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.$viewInitialized = true;
      return;
    }

    setTimeout(() => {
      this.$viewInitialized = true;
    });
  }

  public onToggleAdvanced(): void {
    this.showAdvanced = !this.showAdvanced;
  }

  public onSaveChanges(): void {
    if (!this.formGroup.valid || !this.advancedFormGroup.valid || !this.settings) {
      return;
    }

    const { componentInstance, items } = this.settings;
    const advancedFields: Record<string, any> = {};
    const formValue = {
      ...this.formGroup.getRawValue(),
      ...this.advancedFormGroup.getRawValue(),
    };

    items
      .filter((item) => item?.metadata?.['advanced'])
      .forEach((element) => {
        advancedFields[element.name] = element.value;
      });

    for (const opt of items) {
      componentInstance[opt.name] = isUndefined(formValue[opt.name]) ? advancedFields[opt.name] : formValue[opt.name];
    }

    this.settings.onDatasetChanged?.(formValue);
    this.activeModal.close();
    (componentInstance as BaseBuilderComponent).settingsModalActive$.next(false);
    (componentInstance as BaseBuilderComponent).afterSettingsSaved();
  }

  private getFormModel(settings: SettingsItem[], componentInstance: any): { [key: string]: any } {
    const model: { [key: string]: any } = {};

    for (const { name } of settings) {
      model[name] = cloneDeep(componentInstance[name]);
    }

    return model;
  }

  public dismiss(): void {
    const { componentInstance } = this.settings;
    (componentInstance as BaseBuilderComponent).settingsModalActive$.next(false);

    this.activeModal.close();
  }
}
