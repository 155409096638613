import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApiService,
} from '@jotter3/wa-core';
import {
  Observable,
  of,
  tap,
} from 'rxjs';

import { ResourceDomainModel } from '../domain-models';
import { BaserDomainService } from './base.domain-service';

@Injectable({ providedIn: 'root' })
export class ResourcesDomainService extends BaserDomainService<ResourceDomainModel> {
  readonly resourceMap: Map<string, ResourceDomainModel> = new Map<string, ResourceDomainModel>();

  constructor(apiService: ApiService) {
    super('resources', apiService);
  }

  public override get(id: string, headers?: HttpHeaders): Observable<ApiResponse<ResourceDomainModel>> {
    if (this.resourceMap.has(id)) {
      return of({
        result: this.resourceMap.get(id),
        statusCode: 200,
        success: true,
        error: undefined,
        pagination: {},
      });
    } else {
      return super.get(id).pipe(tap(result => {
        console.log('[RESOURCE NOT FOUND IN SCOPE]: ', id);
        if (!result.success) {
          return;
        }
        this.set(id, result.result);
      }));
    }
  }

  public set(id: string, resource: ResourceDomainModel): void {
    this.resourceMap.set(id, resource);
  }
}
