<div class="card">
  <div class="card-header d-flex align-items-center justify-content-between">
    <h5 class="m-0">Image settings</h5>
    <i class="close-icon material-icons-outlined" (click)="onClose()">close</i>
  </div>
  <div class="card-body">
    <form class="form-container" [formGroup]="formGroup">
      <formly-form
        *ngIf="viewInitialized$ | ngrxPush"
        [form]="formGroup"
        [fields]="formFields"
        [model]="model"
      ></formly-form>
    </form>
  </div>
  <div class="card-footer d-flex align-items-center justify-content-end">
    <button
      class="btn btn-primary"
      id="submitbutton"
      [disabled]="!viewInitialized || !formGroup.valid"
      (click)="onSubmit(formActionType.SUBMIT)"
    >
      Submit
    </button>
  </div>
</div>
