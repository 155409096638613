<figcaption>
  <div class="captions-wrapper">
    <h5
      *ngIf="captionTitle"
      [ngClass]="align ? align : 'align-left'"
      class="caption-title"
    >
      {{ captionTitle | translate }}
    </h5>
    <p
      *ngIf="captionText"
      [ngClass]="align ? align : 'align-left'"
      class="caption-text"
    >
      {{ captionText | translate }}
    </p>
  </div>
</figcaption>
