import { Observable } from 'rxjs';

import { DraftEntityAbstractDomainModel } from '../domain-models';
import {
  DraftState,
  DraftStatus,
} from '../enums';
import { PaginatedEntityCollectionServiceBase } from './paginated-entity-collection-base';

export abstract class DraftsAbstractEntitiesCollectionBase<
  TEntity extends DraftEntityAbstractDomainModel
> extends PaginatedEntityCollectionServiceBase<TEntity> {
  /**
   * Change Draft state
   *
   * @param id (`string`) - Target entity Id
   * @param draftState (`DraftState`) - new draft state (avaible states: [DRAFT, PUBLISHED])
   */
  public changeDraftState(id: string, draftState: DraftState): Observable<TEntity> {
    const entity: Partial<TEntity> = {
      id,
      draftState,
    } as Partial<TEntity>;

    return this.update(entity);
  }

  /**
   * Change dragt status
   *
   * @param id (`string`) - Target entity Id
   * @param draftStatus (`DraftStatus`) - new draft state (avaible statuses: [IN_PROGRESS, PENDING_REVIEW])
   */
  public changeDraftStatus(id: string, draftStatus: DraftStatus): Observable<TEntity> {
    const entity: Partial<TEntity> = {
      id,
      draftStatus,
    } as Partial<TEntity>;

    return this.update(entity);
  }
}
