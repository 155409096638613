import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { PolicyGroupListDomainModel } from '../../../domain-models';
import { policiesListActions } from '../actions';

interface PoliciesState extends EntityState<PolicyGroupListDomainModel> {
  isLoading: boolean;
  isLoaded: boolean;
}

const policiesListAdapter: EntityAdapter<PolicyGroupListDomainModel> = createEntityAdapter<PolicyGroupListDomainModel>();

const initalPoliciesState: PoliciesState = policiesListAdapter.getInitialState({
  isLoaded: false,
  isLoading: false,
});

const policiesListReturn = createReducer<PoliciesState>(
  initalPoliciesState,
  on(policiesListActions.LoadPoliciesList, (state): PoliciesState => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(policiesListActions.LoadPoliciesListComplete, (state, { policies }) =>
    policiesListAdapter.setAll(policies, {
      ...state,
      isLoading: false,
      isLoaded: true,
    })),
  on(policiesListActions.LoadPoliciesListFailed, (state): PoliciesState => ({
    ...state,
    isLoading: false,
    isLoaded: false,
  }))
);

const policiesListReducer = (state: PoliciesState | undefined, action: Action): PoliciesState => {
  return policiesListReturn(state, action);
};

export { initalPoliciesState,
  policiesListAdapter,
  policiesListReducer,
  PoliciesState };
