export enum Direction {
  ASC = 'asc',
  DESC = 'desc'
}

export interface SingleOrder {
  key: string;
  direction: Direction;
}

export type Order = SingleOrder[];
