import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  domainModels,
  enums,
  pagesListState,
  ResourcesDomainService,
} from '@jotter3/api-connector';
import {
  imageHelpers,
  J3TranslateService,
} from '@jotter3/common-helpers';
import {
  DropdownItemModel,
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { ApiService } from '@jotter3/wa-core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  isEqual,
  isNil,
} from 'lodash-es';
import {
  Observable,
  of,
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import * as contentEnums from '../../enums';
import { ContentDataProvider } from '../../providers';
import { ToggleContentService } from '../../services';
import { urlValidators } from '../../validators';
import { emailValidator } from '../../validators/email.validators';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';
import {
  IMAGE_ALIGN_DROPDOWN_SOURCE,
  IMAGE_SIZES_OPTIONLIST_SOURCE,
  LINK_TYPE_DROPDOWN_SOURCE,
} from './image-element.consts';

@SiteComponent({
  selector: 'image-content-component',
  displayName: 'Image',
  icon: 'image',
  category: SiteComponentCategory.MEDIA,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './image-element.component.html',
  styleUrls: ['./image-element.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageElementComponent extends JotterSitesBaseComponent {
  readonly #cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  private imgSrcValue: domainModels.IFileDomainModel = null;
  private fileUrl: domainModels.IFileDomainModel;
  private fileResource: domainModels.ResourceDomainModel;
  private captionsInsideValue = false;

  public isActive = false;
  public elementId: string = UUID();
  public itemPosition = '0px';
  public selectedLinkTypeValue: string = null;
  public selectedLinkValue: string = null;
  public enumsValues = contentEnums.LinkType;
  public targetValue = false;
  public aspectRatioValue = 1;

  private widthValue = '';
  private heightValue = '';
  private urlValue = '';

  public pictureSizeList = enums.PicturesSizes;
  public mediaSize = enums.ImageSize;

  constructor(
    private readonly toggleContent: ToggleContentService,
    private readonly store: Store,
    private readonly dataService: ContentDataProvider,
    private readonly resourcesDomainService: ResourcesDomainService,
    private readonly apiService: ApiService,
    private readonly router: Router
  ) {
    super(ImageElementComponent);
  }

  @ViewChild('pic', { static: false }) pic: ElementRef;

  @Property({
    modifierType: ModifierType.TEXT,
    displayName: 'Heading',
  })
    header = '';

  @Property({
    modifierType: ModifierType.FILEUPLOAD,
    templateOptions: {
      multipleItems: false,
      allowTypes: [1],
      getUrl: true,
    },
    required: true,
    displayName: 'Image',
  })
  public get imageSRC(): domainModels.IFileDomainModel {
    return this.imgSrcValue;
  }

  public set imageSRC(value: domainModels.IFileDomainModel) {
    if (Array.isArray(value)) {
      value = value.length ? value[0] : undefined;
    }

    if (this.imgSrcValue?.id === value?.id) {
      return;
    }

    this.imgSrcValue = value;

    if (!value || !this.designMode || !this.viewInitialized) {
      return;
    }

  }

  @Property({
    modifierType: ModifierType.TEXT,
    displayName: 'Caption Title',
    advanced: true,
  })
    captionTitle = '';

  @Property({
    modifierType: ModifierType.TEXT,
    displayName: 'Caption Text',
    advanced: true,
  })
    captionText = '';

  @Property({
    displayName: 'Captions inside link',
    modifierType: ModifierType.CHECK_BOX,
    hideExpression: ImageElementComponent.linkTargetExpr,
    className: 'form-switch',
  })
  public get captionsInside(): boolean {
    return this.captionsInsideValue;
  }

  public set captionsInside(value: boolean) {
    if (isEqual(this.captionsInsideValue, value) || isNil(value)) {
      return;
    }
    this.captionsInsideValue = value;
  }

  @Property({
    modifierType: ModifierType.UNIT_INPUT,
    displayName: 'Image max width',
    description: 'This option will prevent the image from exceeding a certain width. The image proportions will be preserved.',
    units: [
      '%',
      'px',
    ],
  })
  public get cssWidth(): string {
    return this.widthValue;
  }

  private set cssWidth(value: string) {
    this.widthValue = isNil(value) ? '' : value;
  }

  @Property({
    modifierType: ModifierType.UNIT_INPUT,
    displayName: 'Image max height',
    description: 'This option will prevent the image from exceeding a certain height. The image proportions will be preserved.',
  })
  public get cssHeight(): string {
    return this.heightValue;
  }

  private set cssHeight(value: string) {
    if (!isNil(value) && value.includes('%')) {
      this.heightValue = '';
    } else {
      this.heightValue = isNil(value) ? '' : value;
    }
  }

  @Property({
    displayName: 'Image Resolution',
    modifierType: ModifierType.IMAGE_RESOLUTION,
    optionsList: IMAGE_SIZES_OPTIONLIST_SOURCE,
    required: true,
  })
  public imageSize = enums.PicturesSizes.M;


  @Property({
    modifierType: ModifierType.DROPDOWN,
    template: 'vaIcons',
    dropdownSource: IMAGE_ALIGN_DROPDOWN_SOURCE,
    clearable: false,
    displayName: 'Align',
  })
    align = 'align-left';

  @Property({
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: LINK_TYPE_DROPDOWN_SOURCE,
    displayName: 'Link',
  })
  public get linkType(): string {
    return this.selectedLinkTypeValue;
  }

  public set linkType(value: string) {
    if (isEqual(this.selectedLinkTypeValue, value)) {
      return;
    }
    this.selectedLinkTypeValue = value;
  }

  @Property({
    modifierType: ModifierType.DROPDOWN,
    hideExpression: ImageElementComponent.imageCMSUlrExpr,
    displayName: 'Select url',
    dropdownSource: 'cmsListDropdown',
  })
  public get selectedCmsUrl(): string {
    return this.selectedLinkValue;
  }

  public set selectedCmsUrl(value: string) {
    if (isEqual(this.selectedLinkValue, value)) {
      return;
    }
    this.selectedLinkValue = value;
  }

  public get cmsListDropdown(): Observable<DropdownItemModel<string, string>[]> {
    return this.store.select(pagesListState.pagesSelectors.pagesListSelector).pipe(
      take(1),
      map((items) =>
        items.filter((menuItem) => menuItem.draftState === 1)
          .map((item) => ({
            value: item.url,
            label: item.title,
          })))
    );
  }

  @Property({
    modifierType: ModifierType.TEXT,
    hideExpression: ImageElementComponent.imageUlrExpr,
    validates: {
      datesMatch: {
        expression: urlValidators.urlValidator,
        message: 'Invalid url value',
      },
    },
    displayName: 'Url',
  })
  public get url(): string {
    return this.urlValue;
  }

  public set url(value: string) {
    this.urlValue = value;

    if (!this.urlValue?.trim()?.length) {
      return;
    }

    const regex = /https?:\/\/.+/gm;

    if (!regex.test(this.urlValue)) {
      this.urlValue = `http://${this.urlValue}`;
    }
  }

  @Property({
    modifierType: ModifierType.TEXT,
    hideExpression: ImageElementComponent.imageMailtoExpr,
    validates: {
      datesMatch: {
        expression: emailValidator,
        message: 'Invalid email value',
      },
    },
    displayName: 'Email',
  })
  public mailto = '';

  @Property({
    modifierType: ModifierType.FILEUPLOAD,
    hideExpression: ImageElementComponent.imageFileExpr,
    displayName: 'File',
    templateOptions: {
      multipleItems: false,
      allowTypes: ['all'],
      getUrl: true,
    },
  })
  public get file(): domainModels.IFileDomainModel {
    return this.fileUrl;
  }

  public set file(value: domainModels.IFileDomainModel) {
    if (!value || (Array.isArray(value) && !value.length)) {
      this.fileUrl = undefined;
      return;
    }

    this.fileUrl = Array.isArray(value) ? value[0] : value;

    if (!this.designMode || !this.viewInitialized) {
      return;
    }

    this.addToResources(this.fileUrl.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((res) => {
        this.fileResource = res;
      });
  }

  @Property({
    displayName: 'Open link in new window',
    modifierType: ModifierType.CHECK_BOX,
    hideExpression: ImageElementComponent.linkTargetExpr,
  })
  public get target(): boolean {
    return this.targetValue;
  }

  public set target(value: boolean) {
    if (isEqual(this.targetValue, value) || isNil(value)) {
      return;
    }
    this.targetValue = value;
  }

  public get resourceUrl(): string {
    if (!this.fileResource) {
      return undefined;
    }
    const resourceFile = this.fileResource?.file as domainModels.IFileDomainModel;
    return resourceFile?.url?.origin || resourceFile.icon?.origin;
  }

  public static imageCMSUlrExpr(model: any): boolean {
    return model.linkType !== contentEnums.LinkType.LINK_CMS_TO_PAGE;
  }

  public static imageUlrExpr(model: any): boolean {
    return model.linkType !== contentEnums.LinkType.EXTERNAL_URL;
  }

  public static imageMailtoExpr(model: any): boolean {
    return model.linkType !== contentEnums.LinkType.MAIL;
  }

  public static imageFileExpr(model: any): boolean {
    return model.linkType !== contentEnums.LinkType.LINK_TO_FILE;
  }

  public static linkTargetExpr(model: any): boolean {
    return !model.linkType;
  }


  public override afterSettingsSaved(): void {
    super.afterSettingsSaved();
    this.#cdRef.detectChanges();
  }

  private addToResources(file: string): Observable<domainModels.ResourceDomainModel> {
    const reqModel: Partial<domainModels.ResourceDomainModel> = { file };
    const queryParams = new HttpParams().set('file', file).set('resourceList[isNull]', 1);

    return this.apiService.load<domainModels.ResourceDomainModel>('resources', queryParams).pipe(
      switchMap(({ result }) => {
        if (result.length > 0) {
          return of({ result: result[0] });
        }
        return this.apiService.save<domainModels.ResourceDomainModel>('resources', reqModel);
      }),
      map((res) => res.result)
    );
  }

  private loadFromResource(resourceId: string): Observable<domainModels.ResourceDomainModel> {
    return this.resourcesDomainService.get(resourceId).pipe(
      map(res => res.result),
      filter(result => !!result)
    );
  }

  public toggleItem(status: boolean, id: string): void {
    this.isActive = status;
    this.itemPosition = this.toggleContent.toggleContent(status, id);
  }

  public animationOverflow(status: boolean, id: string): void {
    this.toggleContent.toggleOverflow(status, id);
  }

  public generateUrl(pageName: string): string {
    return this.router
      .createUrlTree([
        this.dataService.appEditorUrlLinks ? this.dataService.appEditorUrlLinks : '',
        pageName.split('/'),
      ].flat())
      .toString();
  }

  public openResourceElement(): void {
    this.apiService
      .loadSingle<domainModels.ResourceDomainModel>('resources', this.fileResource.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe(({ result }) => {
        const {
          url: { origin },
        } = result.file as domainModels.IFileDomainModel;
        window.open(origin, this.target ? '_blank' : '_self');
      });
  }

  public override setDataset(dataset: { [key: string]: any } = {}): void {
    super.setDataset(dataset);
    if (dataset['resourceFileId']) {
      this.loadFromResource(dataset['resourceFileId'])
        .pipe(untilComponentDestroyed(this))
        .subscribe((res) => {
          this.fileResource = res;
          this.file = res.file as domainModels.IFileDomainModel;
        });
    }

    if (dataset['imageResourceId']) {
      this.loadFromResource(dataset['imageResourceId'])
        .pipe(untilComponentDestroyed(this))
        .subscribe((res) => {
          this.imgSrcValue = res.file as domainModels.IFileDomainModel;
          this.#cdRef.detectChanges();
        });
    }
  }

  public override getDataset(): { [key: string]: any } {
    const dataset = super.getDataset();
    const { fileResource, imageSRC, file, ...rest } = dataset;
    rest['resourceFileId'] = this.fileResource?.id;
    rest['imageSRC'] = this.imageSRC;

    return rest;
  }

  public onExternalLinkClicked(ev: MouseEvent): void {
    ev.preventDefault();
    const regex = /https?:\/\/.+/gm;

    let externalUrl = this.url;

    if (!regex.test(externalUrl)) {
      externalUrl = `http://${externalUrl}`;
    }

    window.open(externalUrl, this.target ? '_blank' : '_self');
  }

  public picturesSizesList(imageSize: enums.PicturesSizes): Array<string> {
    return imageHelpers.picturesSizesList(imageSize);
  }
}
