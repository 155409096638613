import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { userProfileState } from '@jotter3/api-connector';
import { J3TranslateService } from '@jotter3/common-helpers';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TemplateDataProvider } from '../../providers';

@TemplateComponent({
  selector: 'jotter-tplcmp-footer',
  displayName: 'Footer',
  icon: 'settings',
  defaultClass: 'site-footer-data',
})
@Component({
  selector: 'jotter-tplcmp-footer',
  templateUrl: './footer.component.html',
  styles: ['.link-to-schooljotter:hover { text-decoration: underline; }'],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class FooterComponent extends TemplateBaseComponent implements OnInit {
  $schoolName: Observable<string | undefined> | undefined;
  @Input() copyrights = '';
  currentDate = moment().format('YYYY');
  isLogged: any = null;

  constructor(private dataProvider: TemplateDataProvider, private store: Store) {
    super(FooterComponent);
  }

  ngOnInit(): void {
    this.$schoolName = this.dataProvider.getData().pipe(map((res) => res.schoolName));
    this.store
      .select(userProfileState.userSelectors.isUserAuthroized)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => (this.isLogged = data));
  }
}
