import {
  createAction,
  props,
} from '@ngrx/store';

import { UserMyProfileDomainModel } from '../../../domain-models';

export const LoadUserMyProfileData = createAction('[USER] Load User Data');
export const LoadUserMyProfileDataComplete = createAction(
  '[USER] Load User Data Success',
  props<{ userData: UserMyProfileDomainModel }>()
);
export const LoadUserMyProfileDataFailed = createAction('[USER] Load User Data Failed', props<{ error: any }>());

export const SetUserAuthorized = createAction('[USER] Set user Authorized', props<{ isAuthorized: boolean }>());
