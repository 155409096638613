import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  Injector,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { WaCoreModule } from '@jotter3/wa-core';
import {
  DefaultDataServiceConfig,
  EntityCollectionReducerMethodsFactory,
  EntityDataService,
  HttpUrlGenerator,
  PersistenceResultHandler,
} from '@ngrx/data';
import { ActionReducerMap } from '@ngrx/store';

import {
  AppStoreModule,
  DirectoriesStoreModule,
  GdprStoreModule,
  SiteSettingsStoreModule,
  SiteSlideshowsStoreModule,
  SiteStoreModule,
  TemplatesStoreModule,
} from './+state';
import {
  PagesContentCollectionService,
  PagesContentUpdateCollectionService,
} from './collection-services';
import {
  AdditionalEntityCollectionReducerMethodsFactory,
  AdditionalPersistenceResultHandler,
  CustomHttpUrlGenerator,
  ModuleConfig,
  ModuleOptionsInjectionToken,
  StoreDataServiceConfig,
} from './common';
import { UrlParamReplaceInterceptor } from './interceptors';
import { State } from './state';
import * as gdpr from './state/gdpr';
import * as groupTypesList from './state/group-types';
import * as loaderStatus from './state/loader';
import * as mobile from './state/mobile-settings';
import * as myGroups from './state/my-groups';
import * as pageContent from './state/pagecontent';
import * as pageContentResources from './state/pagecontent-resources';
import * as pagesList from './state/pageslist';
import * as peopleList from './state/peoplelist';
import * as permissions from './state/permissions';
import * as policies from './state/policies';
import * as policiesGroups from './state/policies-groups';
import * as routerData from './state/router';
import * as settings from './state/settings';
import * as siteSearch from './state/site-search';
import * as tenant from './state/tenant';
import * as user from './state/user';

export const Reducers: ActionReducerMap<State> = {
  permissions: permissions.permissionReducers.permissionsReducer,
  settings: settings.settingsReducers.generalSettingsReducers.settingsReducer,
  userprofile: user.userReducers.userMyProfileReducer,
  pagesList: pagesList.pagesReducers.pagesListReducer,
  pageContent: pageContent.pageContentReducers.pageContentReducer,
  pageContentResources: pageContentResources.pageContentResourcesReducers.pageContentResourcesReducer,
  gdpr: gdpr.gdprSettingsReducers.gdprSettingsReducers.gdprReducer,
  tenant: tenant.tenantReducers.tenantReducer,
  siteSearch: siteSearch.siteSearchReducers.siteSearchReducer,
  peopleList: peopleList.peopleReducers.peopleListReducer,
  groupTypesList: groupTypesList.groupTypesReducers.groupTypesListReducer,
  routerData: routerData.routerReducers.routerReducer.routerReducer,
  mobile: mobile.mobileReducers.mobileSettingsReducers.mobileReducer,
  myGroups: myGroups.myGroupsReducers.myGroupsListReducer,
  loaderStatus: loaderStatus.loaderReducers.loaderReducer.loaderReducer,
  policies: policies.policiesReducers.policiesListReducer,
  policiesGroups: policiesGroups.policiesGroupsReducers.policiesGroupsReducer,
};

export const Effects = [
  permissions.permissionEffects.PermissionsEffects,
  settings.settingsEffects.GeneralSettingsEffects,
  user.userEffects.UserProfileEffects,
  pagesList.pagesEffects.PagesListEffects,
  pageContent.pageContentEffects.PageContentEffects,
  pageContentResources.pageContentResourcesEffects.PageContentResourcesEffects,
  gdpr.gdprSettingsEffects.GdprSettingsEffects,
  tenant.tenantEffects.TenantEffects,
  siteSearch.siteSearchEffects.SiteSearchEffects,
  peopleList.peopleEffects.PeopleListEffects,
  groupTypesList.groupTypesEffects.GroupTypesListEffects,
  routerData.routerEffects.RouterEffects,
  mobile.mobileEffects.MobileSettingsEffects,
  myGroups.myGroupsEffects.MyGroupsListEffects,
  loaderStatus.loaderEffects.LoaderStatusEffects,
  policies.policiesEffects.PoliciesListEffects,
  policiesGroups.policiesGroupsEffects.PoliciesGroupsEffects,
];

@NgModule({
  declarations: [],
  imports: [
    WaCoreModule,
    AppStoreModule,
    GdprStoreModule,
    SiteStoreModule,
    SiteSettingsStoreModule,
    SiteSlideshowsStoreModule,
    TemplatesStoreModule,
    DirectoriesStoreModule,
  ],
})
export class ApiConnectorModule {
  public static forRoot(opt: ModuleConfig): ModuleWithProviders<ApiConnectorModule> {
    return {
      ngModule: ApiConnectorModule,
      providers: [
        {
          provide: ModuleOptionsInjectionToken,
          useValue: opt,
        },
        {
          provide: DefaultDataServiceConfig,
          useClass: StoreDataServiceConfig,
        },
        {
          provide: HttpUrlGenerator,
          useClass: CustomHttpUrlGenerator,
        },
        {
          provide: EntityCollectionReducerMethodsFactory,
          useClass: AdditionalEntityCollectionReducerMethodsFactory,
        },
        {
          provide: PersistenceResultHandler,
          useClass: AdditionalPersistenceResultHandler,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: UrlParamReplaceInterceptor,
          multi: true,
        },
        PagesContentCollectionService,
        PagesContentUpdateCollectionService,
      ],
    };
  }
  public static registerExtendedCollectionServices(entityDataService: EntityDataService, injector: Injector): void {
    entityDataService.registerServices({
      PagesContent: injector.get(PagesContentCollectionService),
      PagesContentUpdate: injector.get(PagesContentUpdateCollectionService),
    });
  }
}
