<ng-container [ngSwitch]="template">
  <div *ngSwitchCase="attachmentLayout.NEWS">
    <div class="single-news__attachments" *ngIf="attachments.length">
      <div
        class="single-news__attachment-wrapper"
        *ngFor="let attachment of attachments"
      >
        <div
          class="single-news__attachment"
          (click)="handleOnDownload(attachment)"
        >
          <i
            class="action-ico-button fas fa-download mx-2"
            title="Download"
          ></i>
          <p>{{ attachment.name }}</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchDefault>
    <ul class="attachments" *ngIf="attachments.length">
      <li *ngFor="let attachment of attachments">
        <a [href]="attachment.url" target="_blank">
          {{ attachment.name }}
        </a>
        <i
          class="action-ico-button fas fa-download mx-2"
          (click)="handleOnDownload(attachment)"
          title="Download"
        ></i>
      </li>
    </ul>
  </div>
</ng-container>
