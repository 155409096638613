import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
} from '@angular/core';

import { IconRegistryService } from './icon-registry.service';

@Component({
  selector: 'j3-icon',
  template: '<ng-content></ng-content>',
  styles: [':host {display:inline-block} :host::ng-deep svg{width: 100%; height: 100%}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  private svgIcon: SVGElement | undefined;

  @Input()
  set name(iconName: string) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this.iconRegistry.getIcon(iconName);
    if (!svgData) {
      return;
    }
    this.svgIcon = this.svgElementFromString(svgData);
    this.element.nativeElement.appendChild(this.svgIcon);
  }

  constructor(
    private element: ElementRef,
    private iconRegistry: IconRegistryService,
    @Optional() @Inject(DOCUMENT) private document: Document
  ) {}

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
  }
}
