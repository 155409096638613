import {
  Component,
  EventEmitter,
  Input,
  Output,
  Type,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { constant } from '../decorators';
import { SiteComponentMetadata } from '../models';
import { BaseBuilderComponent } from './base-builder.compoment';

@Component({
  template: '',
})
export abstract class SiteBaseComponent extends BaseBuilderComponent {
  public readonly isFocused$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isDesignMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onDesingModeChanged: EventEmitter<boolean> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onSelectedChanged: EventEmitter<boolean> = new EventEmitter();
  public isEdited = new BehaviorSubject(false);

  private inDesignMode: boolean;
  private isSelected: boolean;
  private readonly metadata: SiteComponentMetadata;

  protected constructor(protected override type: Type<SiteBaseComponent>) {
    super(type);

    this.metadata = this.getComponentMetadata(constant.COMPONENT_DESCRIPTION_META_KEY);
  }

  @Input() public set focused(value: boolean) {
    this.isFocused$.next(value);
  }

  @Input()
  public get designMode(): boolean {
    return this.inDesignMode;
  }

  public set designMode(value: boolean) {
    if (this.inDesignMode === value) {
      return;
    }

    this.inDesignMode = value;
    this.onDesingModeChanged.emit(value);
    this.isDesignMode$.next(value);
  }

  @Input()
  public get selected(): boolean {
    return this.isSelected;
  }

  public set selected(value: boolean) {
    if (value === this.isSelected) {
      return;
    }

    this.isSelected = value;
    this.onSelectedChanged.emit(value);
  }

  public get componentMetadata(): SiteComponentMetadata {
    return this.metadata;
  }
}
