import { LoadingStateEnum } from '@jotter3/store-helpers';
import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { PageContentResourcesState } from '../reducers';

export const selectPageContentResourcesStateFeature = createFeatureSelector<PageContentResourcesState>('pageContentResources');

export const selectPageContentResourcesState = createSelector(
  selectPageContentResourcesStateFeature,
  (state: PageContentResourcesState) => state
);

export const selectPageContentResourcesLoadingState = createSelector(
  selectPageContentResourcesStateFeature,
  (state: PageContentResourcesState) => state.loadingState
);

export const selectPageContentResourcesIsLoading = createSelector(
  selectPageContentResourcesLoadingState,
  (state: LoadingStateEnum): boolean => state === LoadingStateEnum.LOADING
);

export const selectPageContentResourcesIsLoaded = createSelector(
  selectPageContentResourcesLoadingState,
  (state: LoadingStateEnum): boolean => state === LoadingStateEnum.SUCCESS || state === LoadingStateEnum.FAILED
);

export const selectResourcePageUrl = createSelector(
  selectPageContentResourcesStateFeature,
  (state: PageContentResourcesState) => state.pageUrl
);

export const selectPageContentResourcesIds = createSelector(
  selectPageContentResourcesStateFeature,
  (state: PageContentResourcesState) => state.ids
);

export const selectPageContentResources = createSelector(
  selectPageContentResourcesStateFeature,
  (state: PageContentResourcesState) => Object.values(state.entities)
);
