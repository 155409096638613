import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  domainModels,
  enums,
  siteStoreSelectors,
} from '@jotter3/api-connector';
import {
  imageHelpers,
  J3TranslateService,
} from '@jotter3/common-helpers';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  cloneDeep,
  isNil,
} from 'lodash-es';
import {
  fromEvent,
  merge,
  Observable,
  Subscription,
} from 'rxjs';
import {
  filter,
  map,
} from 'rxjs/operators';
import SwiperCore, {
  A11y,
  Autoplay,
  Controller,
  Navigation,
  Pagination,
  Scrollbar,
  SwiperOptions,
  Thumbs,
  Virtual,
  Zoom,
} from 'swiper';
import { SwiperComponent } from 'swiper/angular';

import {
  defaultLayout,
  DEFAULTS,
  defaultSpaceBetween,
  Grid,
  ItemType,
} from './consts';


@TemplateComponent({
  selector: 'jotter-tplcmp-awards',
  displayName: 'Awards',
  icon: 'settings',
  defaultClass: 'school-awards',
})
@Component({
  selector: 'jotter-tplcmp-awards',
  templateUrl: './awards-component.component.html',
  styleUrls: ['./awards-component.component.scss'],
  animations: [
    trigger('toggle', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AwardsComponent extends TemplateBaseComponent implements OnInit, OnDestroy {
  readonly #subscription: Subscription = new Subscription();
  readonly #store: Store = inject(Store);
  public readonly awardsImageSize$: Observable<enums.PicturesSizes> = this.#store.select(
    siteStoreSelectors.selectEntity
  ).pipe(
    filter(response => !isNil(response?.footerAwards)),
    map(response => response?.customContent?.length ? response.customContent[0] : {}),
    map(customContent => customContent.awardsImageSize ?? enums.PicturesSizes.XS)
  );

  public readonly awards$: Observable<domainModels.AwardsList[]> = this.#store.select(
    siteStoreSelectors.selectEntity
  ).pipe(
    filter(response => !isNil(response?.footerAwards)),
    map((response) => cloneDeep(response.footerAwards ?? []).sort((a, b) => a.order > b.order ? 1 : -1))
  );

  public readonly swiperConfig$: Observable<SwiperOptions> = this.awards$.pipe(map(awards => this.#swiperConfig(awards)));

  public isVisible = false;

  @Input() customClass = '';
  @Input() grid: Grid = defaultLayout;
  @Input() spaceBetween: Grid = defaultSpaceBetween;
  @Input() slide = DEFAULTS.VISIBLE_SLIDES;
  @Input() speed = DEFAULTS.SPEED;
  @Input() timing = DEFAULTS.ANIMATION_DELAY;
  @Input() point = DEFAULTS.SHOW_INDICATORS;
  @Input() arrows = DEFAULTS.SHOW_ARROWS;
  @Input() loop = DEFAULTS.LOOP;
  @Input() height = 110;
  @Input() itemType = ItemType.STANDARD;

  @ViewChild('awardsListSwiper') awardsListSwiper?: SwiperComponent;

  constructor(
    private eRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    super(AwardsComponent);
  }

  public ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (this.itemType === ItemType.TOGGLE) {
      this.#subscription.add(
        merge(
          fromEvent(document, 'click'),
          fromEvent(document, 'touchStart')
        )
          .pipe(
            filter((event: any) => event.target.classList?.contains('awards-button-toggle'))
          )
          .subscribe((event: any) => {
            if (
              (event.target.parentNode.classList?.contains('awards-button-toggle') ||
                event.target.classList?.contains('awards-button-toggle')) &&
              !this.eRef.nativeElement.contains(event.target)
            ) {
              this.toggle();
            }
          })
      );
    }

    this.#initSwiperCore();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.#subscription.unsubscribe();
  }

  public toggle(): void {
    if (this.itemType === 'toggle') {
      this.isVisible = !this.isVisible;
    }
  }

  public nextSlide(): void {
    this.awardsListSwiper?.swiperRef.slideNext();
  }

  public prevSlide(): void {
    this.awardsListSwiper?.swiperRef.slidePrev();
  }

  #swiperConfig(awards: domainModels.AwardsList[]): SwiperOptions {
    console.log('config recreate');
    return {
      autoplay:
        this.timing === 0
          ? false
          : {
            delay: this.timing,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          },
      speed: this.speed,
      pagination:
        this.point
          ? {
            el: '.awards-pagination',
            type: 'bullets',
          }
          : false,
      loop: true,
      slidesPerView: this.grid.xs,
      preventInteractionOnTransition: true,
      allowTouchMove: true,
      spaceBetween: this.spaceBetween.xs,
      breakpoints: {
        576: {
          slidesPerView: awards.length >= this.grid.sm ? this.grid.sm : awards.length,
          spaceBetween: this.spaceBetween.sm,
        },

        768: {
          slidesPerView: awards.length >= this.grid.md ? this.grid.md : awards.length,
          spaceBetween: this.spaceBetween.md,
        },

        991: {
          slidesPerView: awards.length >= this.grid.lg ? this.grid.lg : awards.length,
          spaceBetween: this.spaceBetween.lg,
        },

        1200: {
          slidesPerView: awards.length >= this.grid.xl ? this.grid.xl : awards.length,
          spaceBetween: this.spaceBetween.xl,
        },
      },
    };
  }

  #initSwiperCore(): void {
    SwiperCore.use([
      Navigation,
      Pagination,
      Scrollbar,
      A11y,
      Virtual,
      Zoom,
      Autoplay,
      Thumbs,
      Controller,
    ]);
  }

  public picturesSizesList(imageSize: enums.PicturesSizes): Array<string> {
    return imageHelpers.picturesSizesList(imageSize);
  }
}
