import { PagesContentDomainModel } from '@jotter3/api-connector';
import { contentBuilderModel } from '@jotter3/sites-core';
import {
  isArray,
  isNil,
  keys,
} from 'lodash-es';

type ResourcesMap = Record<string, (contentItem: contentBuilderModel.ContentItemModel, key: string) => string | string[] | undefined>;

const resourceContentPropertyGetter = (content: contentBuilderModel.ContentItemModel, key: string): string | string[] | undefined =>
  content.dataset?.[key];

const getAccordionResourceListIds = (content: contentBuilderModel.ContentItemModel, key: string): string | string[] | undefined => {
  const resourceIds: Array<string> = new Array<string>();
  const items = content.dataset?.[key];

  if (items?.length) {

    for (const item of items) {
      if (isNil(item?.resourceListId)) {
        continue;
      }

      resourceIds.push(item.resourceListId);
    }

  }

  return resourceIds;
};

const RESOURCE_TYPES: Record<string, ResourcesMap> = {
  'image-content-component': {
    imageResourceId: resourceContentPropertyGetter,
    resourceFileId: resourceContentPropertyGetter,
  },
  'j3-content-video': {
    videoResourceId: resourceContentPropertyGetter,
  },
  'embed-document-content-component': {
    resourceId: resourceContentPropertyGetter,
  },
};

const RESOURCE_LIST_TYPES: Record<string, ResourcesMap> = {
  'accordion-content-component': {
    accordionItems: getAccordionResourceListIds,
  },
  'files-list-content-component': {
    resourceListId: resourceContentPropertyGetter,
  },
  'quick-links-content-component': {
    resourceListId: resourceContentPropertyGetter,
  },
  'slideshow-content-component': {
    resourceListId: resourceContentPropertyGetter,
  },
};



const getPageContentItems = (pageContent: PagesContentDomainModel): contentBuilderModel.ContentItemModel[] => {
  const rows: contentBuilderModel.ContentRowModel[] = JSON.parse(JSON.stringify(pageContent?.contents ?? [], (key: string, value: any) => {
    if (key === 'instance' && typeof value !== 'string') {
      return undefined;
    }

    return value;
  }));

  return rows
    .map(row => row.sections)
    .map(sections => sections.map(section => section.items).flat())
    .flat();
};

const getResourceIds = (pageContent: PagesContentDomainModel, TYPES: Record<string, ResourcesMap>): string[] => {
  const resourceIds: string[] = [];
  const contentItems: contentBuilderModel.ContentItemModel[] = getPageContentItems(pageContent)
    .filter(item => keys(TYPES).includes(item.selector.toString()));

  for (const item of contentItems) {
    const selector = isArray(item.selector) ? item.selector[0] : item.selector;

    const attrKeys = TYPES?.[selector];
    for (const key of Object.keys(attrKeys)) {
      const ids = attrKeys[key](item, key);

      if (isNil(ids)) {
        continue;
      }

      const foundedResources = isArray(ids) ? ids : [ids];

      resourceIds.push(...foundedResources);
      console.log(`Load resource for component "${selector}": ${key} | value: ${foundedResources}`);
    }
  }

  return resourceIds.filter(Boolean);
};

export const getPageContentResourceIds = (pageContent: PagesContentDomainModel): string[] =>
  getResourceIds(
    pageContent,
    RESOURCE_TYPES
  );

export const getPageContentResourceListIds = (pageContent: PagesContentDomainModel): string[] =>
  getResourceIds(
    pageContent,
    RESOURCE_LIST_TYPES
  );
