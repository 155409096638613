import {
  createAction,
  props,
} from '@ngrx/store';

import { PermissionDomainModel } from '../../../domain-models';

const LoadPermissions = createAction('[Permissions] Load Permissions');
const LoadPermissionsComplete = createAction(
  '[Permissions] Load Permissions Success',
  props<{ permissions: PermissionDomainModel[] }>()
);
const LoadPermissionsFailed = createAction('[Permissions] Load Permissions Failed', props<{ error: any }>());

export { LoadPermissions,
  LoadPermissionsComplete,
  LoadPermissionsFailed };
