import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  AttachmentModel,
  domainModels,
} from '@jotter3/api-connector';
import {
  FileDownloadService,
  J3TranslateService,
} from '@jotter3/common-helpers';
import {
  DropdownItemModel,
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import {
  ApiService,
  dataQuery,
  LHSBracketsDataQueryAdapter,
} from '@jotter3/wa-core';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import {
  Observable,
  of,
} from 'rxjs';
import {
  map,
  switchMap,
  take,
} from 'rxjs/operators';
// import Swiper core and required components
import { v4 as UUID } from 'uuid';

import { LayoutType } from '../../enums';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'policies-content-component',
  displayName: 'Policies',
  icon: 'document',
  category: SiteComponentCategory.MODULES,
  showSettingsAfterAdd: true,
  roles: ['ROLE_NEWS_VIEWALL'],
})
@Component({
  templateUrl: './policies-element.component.html',
  styleUrls: ['./policies-element.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class PoliciesSiteElementComponent extends JotterSitesBaseComponent implements AfterViewInit {
  readonly #fileDownloadService: FileDownloadService = inject(FileDownloadService);
  readonly #apiService: ApiService = inject(ApiService);

  private loadingValue: Observable<boolean> = of(true);
  private selectedPoliciesGroupsValue: string[] = [];
  elementId: string = UUID();
  public showIndicators = false;
  public showArrows = false;
  public layoutTypeEnum = LayoutType;
  public newsList: domainModels.StoryDomainModel[] = [];
  private selectedGroupsValue: string[] = [];

  private policiesGroups$: Observable<domainModels.PolicyGroupDomainModel[]>;
  public filteredPoliciesGroups$: Observable<domainModels.PolicyGroupDomainModel[]>;


  constructor(
    private queryAdapter: LHSBracketsDataQueryAdapter
  ) {
    super(PoliciesSiteElementComponent);
    this.getFromApi();
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
    defaultValue: 'Policies',
    required: false,
    placeholder: 'Enter header',
    clearable: true,
  })
  public header = 'Policies';

  @Property({
    displayName: 'Policies Groups',
    modifierType: ModifierType.MULTIDROPDOWN,
    dropdownSource: 'policiesGroupsSelector',
  })
  public get selectedPoliciesGroups(): string[] {
    return this.selectedPoliciesGroupsValue;
  }

  public set selectedPoliciesGroups(value: string[]) {
    if (isEqual(this.selectedPoliciesGroupsValue, value)) {
      return;
    }
    this.selectedPoliciesGroupsValue = value;
  }

  @Property({
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: 'Left',
        value: 'text-start',
      },
      {
        label: 'Center',
        value: 'text-center',
      },
      {
        label: 'Right',
        value: 'text-end',
      },
    ],
    clearable: false,
    displayName: 'Polcy group title align',
    defaultValue: 'text-start',
  })
    headerAlign = 'text-start';

  @Property({
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: 'Left',
        value: 'justify-content-start',
      },
      {
        label: 'Center',
        value: 'justify-content-center',
      },
      {
        label: 'Right',
        value: 'justify-content-end',
      },
      {
        label: 'Space around',
        value: 'justify-content-around',
      },
      {
        label: 'Space between',
        value: 'justify-content-between',
      },
    ],
    clearable: false,
    displayName: 'Align',
    defaultValue: 'justify-content-start',
  })
    align = 'justify-content-start';


  public get policiesGroupsSelector(): Observable<DropdownItemModel<string, string>[]> {
    return this.policiesGroups$.pipe(
      take(1),
      map((items) =>
        items
          .map((item) => ({
            value: item.id,
            label: item.name,
          })))
    );
  }

  public override afterSettingsSaved(): void {
    super.afterSettingsSaved();
    this.filteredPolicies();
  }

  public get loading$(): Observable<boolean> {
    return this.loadingValue;
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.filteredPolicies();
  }

  private getFromApi(): void {
    const query: dataQuery.DataQuery = {
      filters: [
        {
          type: dataQuery.FilterType.BOOLEAN,
          operator: dataQuery.Operator.EMPTY,
          property: 'active',
          value: true,
        },
      ],
    };

    this.policiesGroups$ = this.#apiService.load<domainModels.PolicyGroupDomainModel>('policy_groups/all', this.queryAdapter.toQueryParam(query)).pipe(
      untilComponentDestroyed(this),
      switchMap((policies) => {
        return of([...policies.result]);
      })
    );
    this.filteredPolicies();
  }

  public validReview(revievDate: Date): boolean {
    return moment(new Date).isBefore(revievDate);
  }

  public handleOnDownload(attachment: AttachmentModel): void {
    if (this.designMode) {
      return;
    }
    const { name, url } = attachment;
    this.#fileDownloadService.onDownloadFile(name, url);
  }

  private filteredPolicies(): void {
    if (!this.selectedPoliciesGroupsValue.length) {
      this.filteredPoliciesGroups$ = this.policiesGroups$;
    } else {
      this.filteredPoliciesGroups$ = this.policiesGroups$.pipe(map(policies => {
        return policies.filter(policy => this.selectedPoliciesGroupsValue.includes(policy.id));
      }));
    }
  }

}
