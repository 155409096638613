import { MatSnackBar } from '@angular/material/snack-bar';

import { AccordionElementComponent } from './accordion/accordion-element.conponent';
import { CalendarContentComponent } from './calendar/calendar.component';
import { EventsCarouselComponent } from './calendar/events-carousel/events-carousel.component';
import { EventsListItemComponent } from './calendar/events-list-item/events-list-item.component';
import { CalendarFormComponent } from './calendar-form/calendar-form.component';
import { ContactDataSiteElementComponent } from './contact-data/contact-data.component';
import { ContentVideoComponent } from './content-video/content-video.component';
import { EmbedCodeSiteElementComponent } from './embed-code/embed-code.component';
import { EmbedDocumentSiteElementComponent } from './embed-document/embed-document.component';
import { EmbedVideoSiteElementComponent } from './embed-video/embed-video.component';
import { FacebookEmbededElementComponent } from './facebook-embeded/facebook-embeded.component';
import { FilesListContentComponent } from './files-list/files-list.component';
import { FormsSiteElementComponent } from './forms/forms-element.component';
import { GalleriesSiteElementComponent } from './galleries/galleries.component';
import { GalleriesPreviewComponent } from './galleries/preview/galleries-preview.component';
import { ImageCaptionComponent } from './image-element/image-caption/image-caption.component';
import { ImageElementComponent } from './image-element/image-element.component';
import { LettersComponent } from './letters/letters.component';
import { MapSiteElementComponent } from './map/map.component';
import { MyNewTermSiteElementComponent } from './my-new-term/my-new-term.component';
import { NewsComponent } from './news/news.component';
import { NewsPreviewComponent } from './news/news-preview/news-preview.component';
import { NewsletterSubscriptionComponent } from './newsletter-subscription/newsletter-subscription.component';
import { PermissionDenyComponent } from './permission-deny/permission-deny.component';
import { PoliciesSiteElementComponent } from './policies/policies-element.component';
import { PoliciesPreviewComponent } from './policies/policies-preview/policies-preview.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { ScrollingTextSiteComponent } from './scrolling-text/scrolling-text.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { SlideshowSiteComponent } from './slideshow/slideshow-element.component';
import { TextBlockElementComponent } from './text-block/text-block.component';
import { TwitterEmbededElementComponent } from './twitter-embeded/twitter-embeded.component';

const contentElements = [
  TextBlockElementComponent,
  ScrollingTextSiteComponent,
  EmbedVideoSiteElementComponent,
  CalendarContentComponent,
  GalleriesSiteElementComponent,
  LettersComponent,
  EmbedCodeSiteElementComponent,
  ImageElementComponent,
  MapSiteElementComponent,
  EmbedDocumentSiteElementComponent,
  FilesListContentComponent,
  SlideshowSiteComponent,
  FormsSiteElementComponent,
  AccordionElementComponent,
  SearchResultComponent,
  ContentVideoComponent,
  TwitterEmbededElementComponent,
  FacebookEmbededElementComponent,
  ContactDataSiteElementComponent,
  QuickLinksComponent,
  PermissionDenyComponent,
  NewsletterSubscriptionComponent,
  NewsComponent,
  PoliciesSiteElementComponent,
  MyNewTermSiteElementComponent,
];

const providers = [MatSnackBar];

const declarations = [
  ...contentElements,
  TextBlockElementComponent,
  ScrollingTextSiteComponent,
  EmbedVideoSiteElementComponent,
  CalendarContentComponent,
  GalleriesSiteElementComponent,
  LettersComponent,
  EmbedCodeSiteElementComponent,
  CalendarFormComponent,
  GalleriesPreviewComponent,
  SlideshowSiteComponent,
  FormsSiteElementComponent,
  SearchResultComponent,
  TwitterEmbededElementComponent,
  FacebookEmbededElementComponent,
  ContactDataSiteElementComponent,
  QuickLinksComponent,
  NewsPreviewComponent,
  PermissionDenyComponent,
  EventsListItemComponent,
  EventsCarouselComponent,
  ImageCaptionComponent,
  PoliciesSiteElementComponent,
  PoliciesPreviewComponent,
  MyNewTermSiteElementComponent,
];

export { AccordionElementComponent,
  CalendarContentComponent,
  CalendarFormComponent,
  ContactDataSiteElementComponent,
  contentElements,
  ContentVideoComponent,
  declarations,
  EmbedCodeSiteElementComponent,
  EmbedDocumentSiteElementComponent,
  EmbedVideoSiteElementComponent,
  FacebookEmbededElementComponent,
  FilesListContentComponent,
  FormsSiteElementComponent,
  GalleriesPreviewComponent,
  GalleriesSiteElementComponent,
  ImageElementComponent,
  LettersComponent,
  MapSiteElementComponent,
  MyNewTermSiteElementComponent,
  NewsletterSubscriptionComponent,
  NewsPreviewComponent,
  PermissionDenyComponent,
  PoliciesPreviewComponent,
  PoliciesSiteElementComponent,
  providers,
  QuickLinksComponent,
  ScrollingTextSiteComponent,
  SearchResultComponent,
  SlideshowSiteComponent,
  TextBlockElementComponent,
  TwitterEmbededElementComponent };
