import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';

import { MobileSettingsDomainService } from '../../../domain-services';
import { mobileSettingsActions } from '../actions';

@Injectable()
export class MobileSettingsEffects {
  loadMobileSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(mobileSettingsActions.LoadMobileSettings),
      mergeMap(() =>
        this.mobileSettingsDomainService.get('').pipe(
          map(
            ({ result: rawSettings }) => mobileSettingsActions.LoadMobileSettingsComplete({ mobileSettings: rawSettings }),
            catchError((error) => of(mobileSettingsActions.LoadMobileSettingsFailed({ error })))
          )
        ))
    ));

  updateMobileSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(mobileSettingsActions.UpdateMobileSettings),
      mergeMap(({ mobileSettings }) =>
        this.mobileSettingsDomainService.put(mobileSettings).pipe(
          switchMap(() =>
            of(mobileSettingsActions.LoadMobileSettings(), mobileSettingsActions.UpdateMobileSettingsComplete({ mobileSettings }))),
          catchError((error) => of(mobileSettingsActions.UpdateMobileSettingsFailed({ error })))
        ))
    ));

  constructor(private actions$: Actions, private mobileSettingsDomainService: MobileSettingsDomainService) {}
}
