import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  domainModels,
  NGB_MODAL_DATA,
} from '@jotter3/api-connector';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import {
  BehaviorSubject,
  of,
} from 'rxjs';
import {
  map,
  switchMap,
  take,
} from 'rxjs/operators';

import * as enums from '../../../../enums';
import { ImagePluginModel } from '../../../../models';
import { ResourcesManagerService } from '../../../../services';

export interface LinkFormDialogData {
  formModel: ImagePluginModel;
  showRemoveButton: boolean;
}

@Component({
  templateUrl: './image.dialog.component.html',
  styles: ['.close-icon{ cursor: pointer; }'],
})
export class ImageDialogComponent extends OnDestroyMixin implements OnInit, AfterViewInit {
  public viewInitialized = false;
  public viewInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private formGroupValue: FormGroup;
  private formFieldsValue: FormlyFieldConfig[];
  private modelValue: ImagePluginModel = {
    imageSize: 'm',
  };
  constructor(
    private activeModal: NgbActiveModal,
    private resourceManager: ResourcesManagerService,
    @Inject(NGB_MODAL_DATA) @Optional() private dialogData: LinkFormDialogData
  ) {
    super();
  }

  public get formGroup(): FormGroup {
    if (!this.formGroupValue) {
      this.formGroupValue = new FormGroup({});
    }

    return this.formGroupValue;
  }

  public get model(): ImagePluginModel {
    return this.modelValue;
  }

  public set model(value: ImagePluginModel) {
    this.modelValue = value;
  }

  public get formFields(): FormlyFieldConfig[] {
    if (!this.formFieldsValue) {
      this.formFieldsValue = this.defineFormFields();
    }

    return this.formFieldsValue;
  }

  public get formActionType(): typeof enums.FormAction {
    return enums.FormAction;
  }

  public ngOnInit(): void {
    this.initializeModel(this.dialogData?.formModel);
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.model = {
        ...this.model,
        ...this.dialogData?.formModel,
      };

      this.viewInitialized = true;
    });
  }

  public onClose(): void {
    this.activeModal.close({
      formAction: enums.FormAction.CANCEL,
    });
  }

  public onSubmit(formAction: enums.FormAction): void {
    let invokeSubmit = of(this.model);
    if (formAction === enums.FormAction.SUBMIT) {
      const { resourceId, file } = this.model;
      const invokeResource = resourceId
        ? this.resourceManager.putResource.bind(this.resourceManager)
        : this.resourceManager.setResource.bind(this.resourceManager);
      const resourceModel: domainModels.ResourceDomainModel = {
        id: resourceId,
        file: file as string,
      };

      invokeSubmit = invokeResource(resourceModel, 'SINGLE').pipe(
        untilComponentDestroyed(this),
        switchMap((res: string) => this.resourceManager.getResource(res, 'SINGLE')),
        map((res) => {
          const { id, file: resource } = res as domainModels.ResourceDomainModel;
          return {
            ...this.model,
            resourceId: id,
            file: resource,
          };
        })
      );
    }

    invokeSubmit.pipe(take(1)).subscribe((model) => {
      this.activeModal.close({
        model,
        formAction,
      });
    });
  }

  private defineFormFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            type: 'file-upload',
            key: 'file',
            templateOptions: {
              multipleItems: false,
              allowTypes: [1],
              label: 'Image',
              required: true,
            },
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-6',
                key: 'width',
                type: 'input',
                templateOptions: {
                  label: 'Width',
                },
              },
              {
                className: 'col-6',
                key: 'height',
                type: 'input',
                templateOptions: {
                  label: 'Height',
                },
              },
            ],
          },
          {
            type: 'image-resolution',
            key: 'imageSize',
            defaultValue: 'm',
            props: {
              label: 'Image Resolution',
              required: true,
              valueProp: 'value',
              labelProp: 'label',
              clearable: false,
              options: [
                {
                  label: 'Extra Small',
                  value: 'xs',
                  tooltip: '200px',
                },
                {
                  label: 'Small',
                  value: 's',
                  tooltip: '400px',
                },
                {
                  label: 'Medium',
                  value: 'm',
                  tooltip: '800px',
                },
                {
                  label: 'Large',
                  value: 'ml',
                  tooltip: '1300px',
                },
                {
                  label: 'Extra Large',
                  value: 'l',
                  tooltip: '1920px',
                },
              ],
            },
          },
          {
            type: 'input',
            key: 'description',
            templateOptions: {
              label: 'Alternative description',
            },
          },
        ],
      },
    ];
  }

  private initializeModel(model: ImagePluginModel): void {
    const { resourceId } = model;
    if (resourceId) {
      this.resourceManager
        .getResource(resourceId)
        .pipe(take(1))
        .subscribe((res) => {
          const result = res as domainModels.ResourceDomainModel;
          this.model = {
            ...model,
            file: result.file,
          };
          this.viewInitialized$.next(true);
          return;
        });
      return;
    }

    this.model = {
      ...this.model,
      ...model,
    };
    this.viewInitialized$.next(true);
  }
}
