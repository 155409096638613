import { LoadingStateEnum } from '@jotter3/store-helpers';
import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createFeature,
  createReducer,
  on,
} from '@ngrx/store';

import { SlideshowDomainModel } from '../../domain-models';
import { BaseStateModel } from '../common';
import { SiteSlideshowsStoreActions } from './site-slideshows.actions';

export interface SiteSlideshowsState extends BaseStateModel {
  entity: SlideshowDomainModel | undefined;
  siteId: string | undefined;
  errors: Array<ApiErrorResponse | unknown> | undefined;
}

const initialState: SiteSlideshowsState = {
  loadingStatus: LoadingStateEnum.PENDING,
  siteId: undefined,
  entity: undefined,
  errors: undefined,
};

export const siteSlideshowsStoreFeature = createFeature({
  name: 'siteSlideshowsStore',
  reducer: createReducer(
    initialState,
    on(SiteSlideshowsStoreActions.loadTrigger, (state: SiteSlideshowsState, { siteId }): SiteSlideshowsState => ({
      ...state,
      siteId,
      loadingStatus: LoadingStateEnum.LOADING,
    })),
    on(SiteSlideshowsStoreActions.loadSuccess, (state: SiteSlideshowsState, { entity }): SiteSlideshowsState => ({
      ...state,
      loadingStatus: LoadingStateEnum.SUCCESS,
      entity: entity,
    })),
    on(SiteSlideshowsStoreActions.updateTrigger, (state: SiteSlideshowsState): SiteSlideshowsState => ({
      ...state,
      loadingStatus: LoadingStateEnum.LOADING,
    })),
    on(SiteSlideshowsStoreActions.updateSuccess, (state: SiteSlideshowsState, { entity }): SiteSlideshowsState => ({
      ...state,
      entity: {
        ...state.entity,
        ...entity,
      },
      loadingStatus: LoadingStateEnum.SUCCESS,
    })),
    on(
      SiteSlideshowsStoreActions.loadFailed,
      SiteSlideshowsStoreActions.updateFailed,
      (state: SiteSlideshowsState, { errors }): SiteSlideshowsState => ({
        ...state,
        errors,
        loadingStatus: LoadingStateEnum.FAILED,
      })
    )
  ),
});
