import { domainModels } from '@jotter3/api-connector';
import { Observable } from 'rxjs';

import {
  IDataModel,
  ISubpage,
} from '../models';

export abstract class TemplateDataProvider {
  abstract appEditorUrlLinks?: string;

  abstract getData(): Observable<IDataModel>;
  abstract getPages(): Observable<ISubpage[]>;
  abstract getSitesData(): Observable<domainModels.SiteDomainModel>;

  abstract get slideshowData(): Observable<domainModels.SlideshowDomainModel>;
  abstract invokeSearch(phrase: string | undefined): void;
}
