import {
  createAction,
  props,
} from '@ngrx/store';

import { GeneralSettingsDomainModel } from '../../../domain-models';

const LoadGeneralSettings = createAction('[Settings - General] Load data');
const LoadGeneralSettingsComplete = createAction(
  '[Settings - General] Load data success',
  props<{ generalSettings: Partial<GeneralSettingsDomainModel> }>()
);
const LoadGeneralSettingsFailed = createAction('[Settings - General] Load data failed', props<{ error: any }>());
const UpdateGeneralSettings = createAction(
  '[Settings - General] Update',
  props<{ generalSettings: Partial<GeneralSettingsDomainModel> }>()
);
const UpdateGeneralSettingsComplete = createAction(
  '[Settings - General] Update data success',
  props<{ generalSettings: Partial<GeneralSettingsDomainModel> }>()
);
const UpdateGeneralSettingsFailed = createAction('[Settings - General] Update data failed', props<{ error: any }>());

export { LoadGeneralSettings,
  LoadGeneralSettingsComplete,
  LoadGeneralSettingsFailed,
  UpdateGeneralSettings,
  UpdateGeneralSettingsComplete,
  UpdateGeneralSettingsFailed };
