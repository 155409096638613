import { Injectable } from '@angular/core';
import { DefaultPersistenceResultHandler } from '@ngrx/data';
import { Action } from '@ngrx/store';

import { EntityActionWithPagination } from './pagination.model';

@Injectable()
export class AdditionalPersistenceResultHandler extends DefaultPersistenceResultHandler {
  override handleSuccess(originalAction: EntityActionWithPagination): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);

    return (data: any) => {
      const action: any = actionHandler.call(this, data);

      if (action && data?.pagination) {
        action.payload.pagination = data.pagination;
      }

      if (action && data?.result) {
        action.payload.data = data.result;
      }

      return action;
    };
  }
}
