import {
  EntityCollectionService,
  EntityCollectionServiceBase,
} from '@ngrx/data';

import { SelectorsWithPagination } from '../common';

export abstract class PaginatedEntityCollectionServiceBase<T> extends EntityCollectionServiceBase<
  T,
  SelectorsWithPagination<T>
> {}

export interface PaginatedEntityCollectionService<T> extends EntityCollectionService<T> {
  selectors$: SelectorsWithPagination<T>;
}
