import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { domainModels } from '@jotter3/api-connector';
import { ResourcesManagerService } from '@jotter3/common-components';
import { J3TranslateService } from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { ApiService } from '@jotter3/wa-core';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'j3-content-video',
  displayName: 'Video',
  icon: 'video_call',
  category: SiteComponentCategory.MEDIA,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './content-video.component.html',
  styleUrls: ['./content-video.component.scss'],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class ContentVideoComponent extends JotterSitesBaseComponent implements AfterViewInit {
  private readonly mediaRefreshTick: number = 5000;
  private readonly processingTick: number = 2000;

  private nativeVideo: HTMLVideoElement;

  private isProcessing = false;
  private initialClick = true;
  private videoSrcValue: domainModels.IFileDomainModel = null;
  private videoResourceId: string;
  private fileResource: domainModels.ResourceDomainModel = null;
  private processingTimeoutHandler: NodeJS.Timeout;
  private refreshingTimeoutHandler: NodeJS.Timeout;

  private widthValue = '';
  private heightValue = '';

  constructor(
    private readonly apiService: ApiService,
    private readonly resourcesManager: ResourcesManagerService
  ) {
    super(ContentVideoComponent);
  }

  @Property({
    modifierType: ModifierType.TEXT,
    displayName: 'Heading',
  })
  public header = '';

  @Property({
    modifierType: ModifierType.FILEUPLOAD,
    templateOptions: {
      multipleItems: false,
      allowTypes: [4],
      getUrl: true,
    },
    required: true,
    displayName: 'Video',
    ignoreInDataset: true,
  })
  public get videoSRC(): domainModels.IFileDomainModel {
    return this.videoSrcValue;
  }

  public set videoSRC(value: domainModels.IFileDomainModel) {
    if (Array.isArray(value)) {
      value = value.length ? value[0] : undefined;
    }

    if (this.videoSrcValue?.id === value?.id) {
      return;
    }

    this.videoSrcValue = value;

    if (!value || !this.designMode || !this.viewInitialized) {
      return;
    }

    this.addToResources(value.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((resourceId) => {
        this.videoResourceId = resourceId;
      });
  }

  @Property({
    modifierType: ModifierType.DROPDOWN,
    template: 'vaIcons',
    dropdownSource: [
      {
        label: 'Left',
        value: 'align-left',
        icon: 'align_left',
      },
      {
        label: 'Center',
        value: 'align-auto',
        icon: 'align_center',
      },
      {
        label: 'Right',
        value: 'align-right',
        icon: 'align_right',
      },
    ],
    clearable: false,
    displayName: 'Align',
    defaultValue: 'align-left',
  })
    align = 'align-left';

  public get videoProcessing(): boolean {
    return this.isProcessing && !this.platformServer;
  }

  @Property({
    modifierType: ModifierType.UNIT_INPUT,
    displayName: 'Width',
    units: [
      '%',
      'px',
    ],
  })
  public get cssWidth(): string {
    return this.widthValue;
  }

  private set cssWidth(value: string) {
    this.widthValue = value;
  }

  @Property({
    modifierType: ModifierType.UNIT_INPUT,
    displayName: 'Height',
    description: 'Constrain height',
  })
  public get cssHeight(): string {
    return this.heightValue;
  }

  private set cssHeight(value: string) {
    this.heightValue = value;
  }

  private addToResources(file: string): Observable<string> {
    const reqModel: Partial<domainModels.ResourceDomainModel> = { file };

    return this.resourcesManager.setResource(reqModel, 'SINGLE');
  }

  private loadFromResource(resourceId: string): Observable<domainModels.ResourceDomainModel> {
    return this.resourcesManager.getResource(resourceId, 'SINGLE').pipe(map(res => res as domainModels.ResourceDomainModel));
  }

  public override setDataset(dataset: { [key: string]: any } = {}): void {
    super.setDataset(dataset);
    if (dataset.videoResourceId) {
      this.loadFromResource(dataset.videoResourceId)
        .pipe(untilComponentDestroyed(this))
        .subscribe((res) => {
          this.videoSrcValue = res?.file as domainModels.IFileDomainModel;
        });
    }

    if (this.designMode && !dataset.videoResourceId && dataset.videoSRC) {
      if (Array.isArray(dataset.videoSRC)) {
        dataset.videoSRC = dataset.videoSRC?.length ? dataset.videoSRC[0] : undefined;
      }

      this.addToResources(dataset.imageSRC.id)
        .pipe(untilComponentDestroyed(this))
        .subscribe((resourceId) => {
          this.videoResourceId = resourceId;
        });
    }
  }

  @ViewChild('videoElement', { static: false })
  public set videoElement(element: ElementRef<HTMLVideoElement>) {
    if (!element || this.platformServer) {
      return;
    }
    const { nativeElement } = element;
    this.nativeVideo = nativeElement;

    nativeElement.addEventListener('click', () => {
      if (nativeElement.paused) {
        nativeElement.currentTime = this.initialClick ? 0 : nativeElement.currentTime;
        nativeElement.play();
        this.initialClick = false;
        return;
      }
      nativeElement.pause();
    });
  }

  @ViewChild('videoSource', { static: false })
  public set videoSource(source: ElementRef<HTMLSourceElement>) {
    if (!source || this.platformServer) {
      return;
    }

    const { nativeElement: nativeSourceElement } = source;

    nativeSourceElement.addEventListener('error', () => {
      this.isProcessing = true;

      clearTimeout(this.processingTimeoutHandler);
      clearTimeout(this.refreshingTimeoutHandler);

      this.refreshingTimeoutHandler = setTimeout(() => {
        if (this.nativeVideo) {
          this.nativeVideo.load();
        }

        this.processingTimeoutHandler = setTimeout(() => {
          this.isProcessing = false;
        }, this.processingTick);
      }, this.mediaRefreshTick);
    });
  }

  public override getDataset(): { [key: string]: any } {
    const dataset = super.getDataset();
    const { fileResource, imageSRC, file, ...rest } = dataset;
    rest.resourceFileId = this.fileResource?.id;
    rest.videoResourceId = this.videoResourceId;

    return rest;
  }
}
