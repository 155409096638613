import {
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import {
  ApiResponse,
  ApiService,
  BaseApiEntity,
} from '@jotter3/wa-core';
import {
  Observable,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';

export abstract class BaserDomainService<TModel extends BaseApiEntity> {
  constructor(protected resourceName: string, protected apiService: ApiService) {}

  public get(id: string, headers?: HttpHeaders): Observable<ApiResponse<TModel>> {
    return this.apiService.loadSingle<TModel>(this.resourceName, id, { headers });
  }

  public getAll(queryParams?: HttpParams): Observable<ApiResponse<TModel[]>> {
    return this.apiService.load<TModel>(this.resourceName, queryParams).pipe(
      map((apiResponse) => {
        const { success, error } = apiResponse;

        if (!success) {
          throwError(error);
        }
        return apiResponse;
      })
    );
  }

  public post(model: Partial<TModel>, headers?: HttpHeaders): Observable<TModel> {
    return this.apiService.save<TModel>(this.resourceName, model, { headers }).pipe(map((res) => res.result || (res as any)));
  }

  public put(model: Partial<TModel>, headers?: HttpHeaders): Observable<TModel> {
    return this.apiService.save<TModel>(this.resourceName, model, { headers }).pipe(map((res) => res.result || (res as any)));
  }

  public delete(id: string, headers?: HttpHeaders): Observable<boolean> {
    return this.apiService.delete(this.resourceName, id).pipe(
      map((res) => !!res),
      catchError(() => of(false))
    );
  }
}
