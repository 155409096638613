import { LoadingStateEnum } from '@jotter3/store-helpers';
import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createEntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  createFeature,
  createReducer,
  on,
} from '@ngrx/store';

import { DirectoryDomainModel } from '../../domain-models';
import { DirectoriesStoreActions } from './directories.actions';

export interface DirectoriesState extends EntityState<DirectoryDomainModel> {
  loadingStatus: LoadingStateEnum;
  selectedEntity: DirectoryDomainModel | undefined;
  errors: Array<ApiErrorResponse | unknown> | undefined;
}

const directoriesStateAdapter = createEntityAdapter<DirectoryDomainModel>();

const initialState: DirectoriesState = directoriesStateAdapter.getInitialState({
  loadingStatus: LoadingStateEnum.PENDING,
  errors: undefined,
  selectedEntity: undefined,
});

export const templatesStoreFeature = createFeature({
  name: 'directoriesStore',
  reducer: createReducer(
    initialState,
    on(DirectoriesStoreActions.loadTrigger, (state: DirectoriesState): DirectoriesState => ({
      ...state,
      loadingStatus: LoadingStateEnum.LOADING,
    })),
    on(DirectoriesStoreActions.loadSuccess, (state: DirectoriesState, { entity }): DirectoriesState => directoriesStateAdapter.addMany(entity, {
      ...state,
      loadingStatus: LoadingStateEnum.SUCCESS,
    })),
    on(DirectoriesStoreActions.loadFailed, DirectoriesStoreActions.upsertFailed, DirectoriesStoreActions.deleteFailed, (state: DirectoriesState, { errors }): DirectoriesState => ({
      ...state,
      errors,
      loadingStatus: LoadingStateEnum.FAILED,
    })),
    on(DirectoriesStoreActions.setSelected, (state: DirectoriesState, { entity }): DirectoriesState => ({
      ...state,
      selectedEntity: entity,
    })),
    on(DirectoriesStoreActions.upsertSuccess, (state: DirectoriesState, { entity }): DirectoriesState => directoriesStateAdapter.upsertOne(entity, state)),
    on(DirectoriesStoreActions.deleteSuccess, (state: DirectoriesState, { entityId }): DirectoriesState => directoriesStateAdapter.removeOne(entityId, state))
  ),
});
