import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { TenantInfoDomainModel } from '../../../domain-models';
import * as tenantActions from '../actions';

interface TenantState extends Partial<TenantInfoDomainModel> {
  isLoading: boolean;
  isLoaded: boolean;
}

const initialTenantState: TenantState = {
  isLoaded: false,
  isLoading: false,
};

const tenantReturn = createReducer<TenantState>(
  initialTenantState,
  on(tenantActions.LoadTenantData, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(tenantActions.LoadTenantDataComplete, (state, { tenant }) => ({
    ...state,
    ...tenant,
    isLoading: false,
    isLoaded: true,
  })),
  on(tenantActions.LoadTenantDataFailed, (state) => ({
    ...state,
    isLoaded: false,
    isLoading: false,
  }))
);

const tenantReducer = (state: TenantState = initialTenantState, action: Action): TenantState => tenantReturn(state, action);

export { initialTenantState,
  tenantReducer,
  TenantState };
