import {
  DOCUMENT,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  Renderer2,
  Type,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
  NavigationEnd,
  Router,
} from '@angular/router';
import {
  domainModels,
  enums,
  gdprSettingsState,
  PageDomainModel,
  PagesContentDomainModel,
  settingsState,
  siteStoreSelectors,
} from '@jotter3/api-connector';
import { SitesPopupBoxComponent } from '@jotter3/common-components';
import {
  IGdprDataProvider,
  InjectorHelperService,
  stringHelpers,
  StudybugsProvider,
} from '@jotter3/common-helpers';
import {
  CalendarContentComponent,
  PermissionDenyComponent,
  SearchResultComponent,
} from '@jotter3/content-components';
import {
  AuthService,
  SiteContentComponentStore,
} from '@jotter3/core';
import { reflectMetadata } from '@jotter3/reflection-core';
import {
  constant,
  SiteBaseComponent,
  SiteComponentMetadata,
} from '@jotter3/sites-abstract';
import {
  ContentBuilderManagerService,
  contentBuilderModel,
} from '@jotter3/sites-core';
import { TemplateDataProvider } from '@jotter3/template-components';
import { ApiService } from '@jotter3/wa-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import {
  cloneDeep,
  isNil,
} from 'lodash-es';
import * as moment from 'moment-timezone';
import { CookieService } from 'ngx-cookie-service';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  of,
  Subscription,
  zip,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import {
  AppConfigurationService,
  SiteTemplatesDataService,
} from '../../services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'jotter-site-renderer',
  templateUrl: './site-renderer.component.html',
  styleUrls: ['./site-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SiteContentComponentStore],
})
export class SiteRendererComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly #subscription: Subscription = new Subscription();
  readonly #cookieKey: string = 'J3CookiesAccepted';
  readonly #componentStore: SiteContentComponentStore = inject(SiteContentComponentStore);
  readonly #searchPageId: string = UUID();
  readonly #calendarPageId: string = UUID();
  readonly #metaService: Meta = inject(Meta);
  readonly #authService: AuthService = inject(AuthService);
  readonly #ngbModal: NgbModal = inject(NgbModal);
  readonly #injectorHelper: InjectorHelperService = inject(InjectorHelperService);
  readonly #router: Router = inject(Router);
  readonly #contentProvider: ContentBuilderManagerService = inject(ContentBuilderManagerService);
  readonly #appConfigurationService: AppConfigurationService = inject(AppConfigurationService);
  readonly #store: Store = inject(Store);
  readonly #templateProvider: TemplateDataProvider = inject(TemplateDataProvider);
  readonly #renderer: Renderer2 = inject(Renderer2);
  readonly #cookieService: CookieService = inject(CookieService);
  readonly #apiService: ApiService = inject(ApiService);
  readonly #windowScroll$: Observable<Event> = fromEvent(window, 'scroll');
  readonly #windowResize$: Observable<Event> = fromEvent(window, 'resize');
  readonly #showCookies: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly siteTemplate$ = this.#componentStore.selectCurrentTemplate$.pipe(distinctUntilChanged());

  settings: Partial<domainModels.GeneralSettingsDomainModel>;

  private currentContentValue: contentBuilderModel.ContentRowModel[];

  public cookiesType$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private popupClosed = false;
  public pageExist: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public cookieBoxVisible$ = combineLatest([
    this.pageExist,
    this.#showCookies,
  ]).pipe(
    map(([
      pageExist,
      showCookies,
    ]) => pageExist && showCookies),
    distinctUntilChanged(),
    tap(res => console.log('Cookie visible: ', res))
  );
  constructor(
    @Optional() @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject(REQUEST) private request: Request,
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject(IGdprDataProvider) private iGdprDataProvider: IGdprDataProvider,
    @Optional() @Inject(StudybugsProvider) private studybugsProvider: StudybugsProvider
  ) {
    this.#subscription.add(
      this.#router.events
        .pipe(
          filter((ev) => ev instanceof NavigationEnd && isPlatformBrowser(this.platformId))
        )
        .subscribe(() => {
          this.checkPositions();
        })
    );

    if (this.#cookieService.get('ga_enabled')) {
      this.#subscription.add(
        this.#store.select(siteStoreSelectors.selectEntity)
          .pipe(
            filter((currentSite) => !!currentSite && !!currentSite?.googleAnalytics),
            take(1),
            tap((site) => this.iGdprDataProvider.initGoogleAnalytics(site))
          )
          .subscribe()
      );
    }
  }

  public ngOnInit(): void {
    this.#subscription.add(
      this.#authService.initialized.pipe(
        filter(initialized => initialized),
        distinctUntilChanged(),
        switchMap(() =>
          zip([
            this.#componentStore.selectPage$,
            this.#componentStore.selectContent$,
          ]))
      ).subscribe(
        ([
          currentPage,
          currentPageContent,
        ]) => {
          this.applyCurrentPageToContext(currentPage, currentPageContent);
        }
      )
    );

    this.#subscription.add(this.#componentStore.selectNotPermitted.subscribe((notPermitted: boolean) => {
      if (!notPermitted) {
        return;
      }

      this.#contentProvider.rows = this.createCustomContent(PermissionDenyComponent);
      this.pageExist.next(true);
    }));

    this.#subscription.add(this.#componentStore.selectNotFound.subscribe(notFound => {
      if (!notFound) {
        return;
      }

      this.tryNavigateToHome();
    }));

    this.#subscription.add(this.#componentStore.selectConstPageMode$.subscribe((data) => {
      switch (true) {
        case data === enums.PageDefinitions.SEARCH:
          this.#contentProvider.rows = this.createCustomContent(SearchResultComponent);
          this.addClassList(this.document.body, ['jotter-site--search']);
          break;
        case data === enums.PageDefinitions.CALENDAR:
          this.#contentProvider.rows = this.createCustomContent(CalendarContentComponent);
          this.addClassList(this.document.body, ['jotter-site--calendar']);
          break;
        default:
          this.#contentProvider.rows = this.currentContentValue as contentBuilderModel.ContentRowModel[];
          break;
      }
      this.pageExist.next(true);
    }));

    this.#subscription.add(
      this.#store.select(siteStoreSelectors.selectEntity).pipe(
        filter(entity => !!entity?.additionalHeaders)
      ).subscribe(({ additionalHeaders }) => {
        for (const key of Object.keys(additionalHeaders)) {
          this.#metaService.addTag({
            name: stringHelpers.toKebabCase(key),
            content: additionalHeaders[key],
          });
        }
      })
    );

    this.#componentStore.loadSiteTemplate();
    this.#componentStore.loadSiteContent();

    if (!this.#cookieService.check(this.#cookieKey)) {
      this.#cookieService.set(this.#cookieKey, 'false', moment.tz().add(6, 'month').toDate(), '/');
    }

    this.#subscription.add(
      this.siteTemplate$
        .pipe(filter(() => isPlatformBrowser(this.platformId)))
        .subscribe(template => {
          this.addClassList(this.document.body, [`jotter-site-theme--${template.folder.toLowerCase()}`]);
        })
    );

    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.#subscription.add(
      this.#windowResize$.pipe(
        filter(() => isPlatformBrowser(this.platformId)),
        debounceTime(200)
      ).subscribe(() => this.checkPositions())
    );

    this.#subscription.add(
      this.#windowScroll$.pipe(
        filter(() => isPlatformBrowser(this.platformId)),
        debounceTime(200)
      ).subscribe(() => this.checkBodyPosition())
    );
  }

  public ngAfterViewInit(): void {
    this.#subscription.add(
      this.#store
        .select(settingsState.settingsSelectors.generalSettingsSelector.generalSettingsSelector)
        .pipe(
          tap((generalSettings) => {
            if (generalSettings?.isLoaded || generalSettings?.isLoading) {
              return;
            }

            this.#store.dispatch(settingsState.settingsActions.generalSettingsActions.LoadGeneralSettings());
          }),
          filter((generalSettings) => generalSettings?.isLoaded),
          take(1)
        )
        .subscribe(
          (generalSettings) => {
            this.settings = generalSettings;
          },
          (error) => {
            console.error(error);
          }
        )
    );

    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.#subscription.add(
      this.iGdprDataProvider.cookiesAccept$
        .asObservable()
        .subscribe((cookieAccetped) => {
          if (cookieAccetped === enums.CookieState.ACCEPTED) {
            this.onCookeResponse(cookieAccetped);
          }
        })
    );

    this.initGdprPolicy();
    this.showSitePopupIfExist();

    setTimeout(() => {
      const logoutLink = document.getElementById('logout-link');
      if (!isNil(logoutLink)) {
        this.#subscription.add(
          fromEvent(logoutLink, 'click')
            .subscribe(() => {
              this.#authService.logout(true);
            })
        );
      }
      this.checkPositions();
    }, 5000);

    this.#subscription.add(this.#store.select(siteStoreSelectors.selectEntity).subscribe(site => {
      this.studybugsProvider.initStudyBugs(site);
    }));
  }

  public ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }

  private addClassList(target: any, value: string[]): void {
    value
      .filter((cssClass) => cssClass.trim().length)
      .forEach((cssClass) => this.#renderer.addClass(target, cssClass.trim().replace(' ', '_')));
  }

  private applyCurrentPageToContext(page: PageDomainModel, content: PagesContentDomainModel): void {
    try {
      const { title, layout, id, homePage, name } = page;

      if (isPlatformBrowser(this.platformId)) {
        const classes = this.document.body.getAttribute('class').split(' ');
        classes.forEach((cl) => {
          if (cl.includes('jotter-site--')) {
            this.#renderer.removeClass(this.document.body, cl);
          }
        });
      }

      this.addClassList(this.document.body, [
        homePage ? 'jotter-site--main-page' : '',
        `jotter-site--${name}`,
        'jotter-site--client',
      ]);

      this.document.body.classList.remove('overflow-hidden');
      if (page.id !== this.#searchPageId && page.id !== this.#calendarPageId) {
        (this.#templateProvider as SiteTemplatesDataService).loadSlideshowForPage(id);
      }

      if (!content.contents?.length) {
        try {
          const layoutObj = JSON.parse(layout);
          this.#contentProvider.rows = layoutObj || [];
        } catch (err) {
          console.warn(err);
          this.#contentProvider.rows = [];
        }
      } else {
        this.currentContentValue = cloneDeep(content.contents as contentBuilderModel.ContentRowModel[]);
        this.#contentProvider.rows = cloneDeep(content.contents as contentBuilderModel.ContentRowModel[]);
      }

      this.#appConfigurationService.title = `${title} | ${this.settings.name}`;
      this.pageExist.next(true);
    } catch (err) {
      console.error('ERROR ON PARSING CONTENT JSON');
      console.error(err);
      this.#router.navigate(['/error/500'], { skipLocationChange: true });
    }
  }

  private tryNavigateToHome(homePage?: domainModels.PageDomainModel): void {
    this.#router.navigate(homePage ? [
      '/',
      ...homePage.urlSegments,
    ] : [
      'error',
      '404',
    ], {
      skipLocationChange: !homePage,
    });
  }

  private checkBodyPosition(): void {
    if (document.body.getBoundingClientRect().top < 0) {
      document.body.classList.add('scrolling');
      return;
    }

    document.body.classList.remove('scrolling');
  }

  private checkPositions(): void {
    const header = this.document.querySelector('.site-header')?.getBoundingClientRect().height
      ? this.document.querySelector('.site-header')?.getBoundingClientRect().height
      : 0;
    document.documentElement.style.setProperty('--header-height', `${header + 30}px`);

    const slideshow = this.document.querySelector('.top-swiper-slideshow')?.getBoundingClientRect().height
      ? this.document.querySelector('.top-swiper-slideshow')?.getBoundingClientRect().height
      : 0;
    document.documentElement.style.setProperty('--slideshow-wrapper-height', `${slideshow}px`);

    const toolbar = this.document.querySelector('.toolbar-wrapper')?.getBoundingClientRect().height
      ? this.document.querySelector('.toolbar-wrapper')?.getBoundingClientRect().height
      : 0;

    document.documentElement.style.setProperty('--header-position', `${toolbar}px`);
  }

  private initGdprPolicy(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.#subscription.add(
      this.#store
        .select(gdprSettingsState.gdprSettingsSelectors.gdprSettingsSelector.gdprSettingsSelector)
        .pipe(
          tap((gdpr) => {
            if (!gdpr.isLoaded && !gdpr.isLoading) {
              this.#store.dispatch(gdprSettingsState.gdprSettingsActions.gdprSettingsActions.LoadGdprSettings());
            }
          }),
          filter((gdpr) => gdpr.isLoaded),
          take(1)
        )
        .subscribe((res) => {
          setTimeout(() => {
            this.cookiesType$.next(res.gdpr_popup_show ? res.gdpr_popup_layout || 0 : null);
            this.#showCookies.next(!!res.gdpr_popup_show);
          });
        })
    );
  }

  public onCookeResponse(accepted: enums.CookieState): void {
    this.iGdprDataProvider.onCookeResponse(accepted, this.#cookieKey);
  }

  public onAnalyticsResponse(accepted: boolean): void {
    this.#subscription.add(this.#store.select(siteStoreSelectors.selectEntity).subscribe(site => {
      const findGaTagScript = this.document.getElementById('g-tag-script');
      const gaScript = this.document.getElementById('ga-script');
      if (accepted && !findGaTagScript) {
        this.iGdprDataProvider.initGoogleAnalytics(site);
      } else if (!accepted && findGaTagScript) {
        findGaTagScript.remove();
        gaScript.remove();
      }
    }));
  }

  public onNavigateToPolicy(): void {
    this.iGdprDataProvider.onNavigateToPolicy();
  }

  private createCustomContent<T extends SiteBaseComponent>(componentType: Type<T>): contentBuilderModel.ContentRowModel[] {
    const metadata = reflectMetadata
      .getComponentMetadata<SiteComponentMetadata>(constant.COMPONENT_DESCRIPTION_META_KEY, componentType)
      .flat()[0];

    return [
      {
        uuid: UUID(),
        sections: [
          {
            size: 12,
            uuid: UUID(),
            items: [
              {
                ...metadata,
                component: [metadata.selector].flat()[0],
                uuid: UUID(),
              },
            ],
          },
        ],
      },
    ];
  }

  private redirectIfNotAuthorized(): boolean {
    if (!this.#authService.isAuthorized) {
      this.#authService.redirectToLogin();
      return false;
    }

    return true;
  }

  private showSitePopupIfExist(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.#subscription.add(
      this.#store.select(siteStoreSelectors.selectSiteId).pipe(switchMap(siteId =>
        this.#apiService
          .loadSingle<domainModels.SitesSettingsDomainModel>(`sites/${siteId}/settings`)
          .pipe(
            switchMap(({ result }) => {
              const popupClosed =
            this.popupClosed ||
            (isPlatformServer(this.platformId) && this.request
              ? this.request.cookies.popup_box_closed === 'true'
              : this.#cookieService.get('popup_box_closed') === 'true');

              if (
                (result.popupSettings.publicationMode === 2 && (!result.addPopup || this.popupClosed)) ||
            (result.popupSettings.publicationMode === 1 && (popupClosed || !result.addPopup))
              ) {
                return of({});
              }

              return this.#ngbModal.open(SitesPopupBoxComponent, {
                injector: this.#injectorHelper.provideModalData({
                  popupSettings: result,
                }),
              }).closed;
            }),
            take(1)
          )))
        .subscribe((result: any) => {
          if (!result?.popupSettings) {
            return;
          }

          this.popupClosed = true;
        })
    );
  }
}
