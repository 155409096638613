import { TemplateDefinitionDomainModel } from '@jotter3/api-connector';

export const convertVariantsToTemplateDefinitions = (domainModels: TemplateDefinitionDomainModel[]): TemplateDefinitionDomainModel[] => {
  return domainModels.map(template => {
    const res: TemplateDefinitionDomainModel[] = [];
    const { variants, ...rest } = template;
    for (const variant of variants) {
      res.push({
        ...rest,
        name: variant.name ? `${rest.name} (${variant.name})` : rest.name,
        styles: [variant.path],
      });
    }

    return res;
  }).flat();
};

export const getSiteOrDefaultTemplate = (templates: TemplateDefinitionDomainModel[], templateName: string): TemplateDefinitionDomainModel =>
  templates.find(template => template.name === templateName)
  ?? templates.find(template => template.default);
