import { AbstractControl } from '@angular/forms';

export const vimeoYoutubeUrlValidator = (control: AbstractControl): boolean =>
  /(https?:\/\/)www.(youtube.com\/watch[?]v=([a-zA-Z0-9_-]{11}))|(https?:\/\/)(youtu.be\/([a-zA-Z0-9_-]{11}))|https?:\/\/(www.)?vimeo.com\/([0-9]*)/.test(
    control.value
  );

export const urlValidator = (control: AbstractControl): boolean => {
  if (!control.value) {
    return true;
  }
  // eslint-disable-next-line
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'%\(\)\*\+,;=.]+$/.test(
    control.value
  );
};
