import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { loaderActions } from '..';

export interface LoaderState {
  isLoaded: boolean;
  isLoading: boolean;
  isVisible?: boolean;
}

export interface LoaderDataState {
  loaderStatus: LoaderState;
}

const initialLoaderState: LoaderDataState = {
  loaderStatus: {
    isLoaded: false,
    isLoading: false,
    isVisible: true,
  },
};

const loaderReturn = createReducer<LoaderDataState>(
  initialLoaderState,
  on(loaderActions.LoadLoaderStatus, (state) => ({
    ...state,
    loaderStatus: {
      ...state.loaderStatus,
      isLoading: true,
    },
  })),
  on(loaderActions.LoadLoaderStatusComplete, (state, { loaderStatus }) => ({
    ...state,
    loaderStatus: {
      ...state.loaderStatus,
      ...loaderStatus,
      isLoaded: true,
      isLoading: false,
    },
  })),
  on(loaderActions.LoadLoaderStatusFailed, (state) => ({
    ...state,
    loaderStatus: {
      ...state.loaderStatus,
      isLoading: false,
      isLoaded: false,
    },
  })),
  on(loaderActions.SetLoaderVisibility, (state, { isVisible }) => ({
    ...state,
    loaderStatus: {
      ...state.loaderStatus,
      isVisible,
      isLoading: false,
      isLoaded: false,
    },
  }))
);

export const loaderReducer = (state: LoaderDataState = initialLoaderState, action: Action): LoaderDataState =>
  loaderReturn(state, action);
