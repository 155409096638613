import { Injectable } from '@angular/core';
import { ApiService } from '@jotter3/wa-core';
import { Store } from '@ngrx/store';

import { GroupTypeDomainModel } from '../domain-models';
import { BaserDomainService } from './base.domain-service';

@Injectable({ providedIn: 'root' })
export class GroupTypesDomainService extends BaserDomainService<GroupTypeDomainModel> {
  constructor(apiService: ApiService, private store: Store) {
    super('group_types', apiService);
  }
}
