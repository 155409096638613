<div class="row">
  <div class="col-lg-12">
    <div class="search-result-box card-box">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="pt-3 pb-4">
            <div class="input-group">
              <input
                [(ngModel)]="phrase"
                (search)="onSearch()"
                type="search"
                class="form-control"
                placeholder="Search..."
              />
              <button
                (click)="onSearch()"
                type="button"
                class="btn waves-effect waves-light btn-custom m-0"
              >
                <i class="fa fa-search me-1"></i> {{ 'Search' | translate }}
              </button>
            </div>
            <div
              *ngIf="(isLoading | async) !== true"
              class="mt-4 text-center"
            >
              <h4 *ngIf="searchPhrase | async as searchKey">
                {{ 'Your search for' | translate }} "{{ searchKey }}" {{ 'returned' | translate }}
                {{ (searchResult | async)?.length || 0 }}
                {{ (searchResult | async)?.length > 1 ? 'results' : ('result' | translate) }}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          *ngIf="platformBrowser && (isLoading | async)"
          class="component-loading-indicator"
        >
          <mat-spinner></mat-spinner>
        </div>

        <div
          *ngFor="let item of searchResult | async"
          class="col-md-12"
        >
          <div class="search-item">
            <h4 class="mb-1 text-start">
              <a
                [routerLink]="generateUrl(item.page)"
                [innerHTML]="item.title | highlightSearch | translate"
              ></a>
            </h4>
            <div class="font-13 text-success mb-3">{{ getPageUrlString(item.page) }}</div>
            <p
              [innerHTML]="item.content | highlightSearch | translate"
              class="mb-0 text-muted"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
