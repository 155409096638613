import { LoadingStateEnum } from '@jotter3/store-helpers';
import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createFeature,
  createReducer,
  on,
} from '@ngrx/store';

import { SitesSettingsDomainModel } from '../../domain-models';
import { BaseStateModel } from '../common';
import { SiteSettingsStoreActions } from './site-settings.actions';

export interface SiteSettingsState extends BaseStateModel {
  entity: SitesSettingsDomainModel | undefined;
  siteId: string | undefined;
  errors: Array<ApiErrorResponse | unknown> | undefined;
}

const initialState: SiteSettingsState = {
  loadingStatus: LoadingStateEnum.PENDING,
  siteId: undefined,
  entity: undefined,
  errors: undefined,
};

export const siteSettingsStoreFeature = createFeature({
  name: 'siteSettingsStore',
  reducer: createReducer(
    initialState,
    on(SiteSettingsStoreActions.loadTrigger, (state: SiteSettingsState, { siteId }): SiteSettingsState => ({
      ...state,
      siteId,
      loadingStatus: LoadingStateEnum.LOADING,
    })),
    on(SiteSettingsStoreActions.loadSuccess, (state: SiteSettingsState, { entity }): SiteSettingsState => ({
      ...state,
      loadingStatus: LoadingStateEnum.SUCCESS,
      entity: entity,
    })),
    on(SiteSettingsStoreActions.updateTrigger, (state: SiteSettingsState): SiteSettingsState => ({
      ...state,
      loadingStatus: LoadingStateEnum.LOADING,
    })),
    on(SiteSettingsStoreActions.updateSuccess, (state: SiteSettingsState, { entity }): SiteSettingsState => ({
      ...state,
      entity: {
        ...state.entity,
        ...entity,
      },
      loadingStatus: LoadingStateEnum.SUCCESS,
    })),
    on(
      SiteSettingsStoreActions.loadFailed,
      SiteSettingsStoreActions.updateFailed,
      (state: SiteSettingsState, { errors }): SiteSettingsState => ({
        ...state,
        errors,
        loadingStatus: LoadingStateEnum.FAILED,
      })
    )
  ),
});
