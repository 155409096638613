import { LoadingStateEnum } from '@jotter3/store-helpers';
import { createSelector } from '@ngrx/store';

import { siteStoreFeature } from './gdpr.reducer';

const {
  selectLoadingStatus,
  selectDomain,
  selectEntity,
  selectErrors,
} = siteStoreFeature;

const selectIsLoading = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.LOADING
);

const selectIsLoaded = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.SUCCESS || loadingStatus === LoadingStateEnum.FAILED
);

export const gdprSelectors = {
  selectEntity,
  selectDomain,
  selectErrors,
  selectIsLoaded,
  selectIsLoading,
  selectLoadingStatus,
};
