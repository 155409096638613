import { isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import { DefaultDataServiceConfig } from '@ngrx/data';

import {
  ModuleConfig,
  ModuleOptionsInjectionToken,
} from './module-config';

@Injectable({ providedIn: 'root' })
export class StoreDataServiceConfig extends DefaultDataServiceConfig {
  constructor(
    @Inject(ModuleOptionsInjectionToken) moduleConfig: ModuleConfig,
    @Inject(PLATFORM_ID) platformId: object
  ) {
    super();

    const { DB_URL, DB_URL_VPC } = moduleConfig;

    this.root = isPlatformServer(platformId) && DB_URL_VPC ? DB_URL_VPC : DB_URL;
  }
}
