<button
  class="awards-button-toggle"
  *ngIf="itemType === 'toggle' && (awards$ | ngrxPush)?.length"
  (click)="toggle()"
>
  Awards
</button>
<div
  *ngrxLet="awards$ | ngrxPush; let awards"
  [@toggle]="this.itemType === 'toggle' && !this.isVisible ? 'closed' : 'open'"
  [ngClass]="[
    'awards-wrapper',
    className ?? '',
    this.itemType === 'toggle' ? 'awards-toggle' : ''
  ]"
  [style.min-height.px]="height"
>
  <span
    class="school-data-icon-toggle-close"
    *ngIf="itemType === 'toggle'"
    (click)="toggle()"
  >
    <i class="school-data-icon--i icon-remove-circle"></i>
  </span>
  <swiper
    class="awards__images"
    #awardsListSwiper
    *ngIf="awards?.length > 0"
    [config]="swiperConfig$ | ngrxPush"
  >
    <ng-container *ngFor="let award of awards; let j = index">
      <ng-template swiperSlide>
        <div class="awards-item--image-wrapper" [style.height.px]="height">
          <ng-container *ngIf="award.url; else singleImg">
            <a
              [href]="award.url"
              [target]="award.newTab ? award.newTab : '_self'"
            >
              <ng-template
                *ngTemplateOutlet="awardImage; context: { award, j }"
              ></ng-template>
            </a>
          </ng-container>
          <ng-template #singleImg>
            <ng-template
              *ngTemplateOutlet="awardImage; context: { award, j }"
            ></ng-template>
          </ng-template>
        </div>
        <div class="awards-item--caption" *ngIf="award.caption">
          {{ award.caption | translate }}
        </div>
      </ng-template>
    </ng-container>
  </swiper>

  <div class="awards-swiper-controls">
    <button
      class="awards-swiper-navigation awards-swiper-navigation--prev icon-chevron-left"
      *ngIf="awards.length && arrows"
      (click)="prevSlide()"
      type="button"
    ></button>
    <ng-container *ngIf="point">
      <div class="awards-pagination"></div>
    </ng-container>
    <button
      class="awards-swiper-navigation awards-swiper-navigation--next icon-chevron-right"
      *ngIf="awards.length && arrows"
      (click)="nextSlide()"
      type="button"
    ></button>
  </div>
</div>

<ng-template #awardImage let-award="award" let-index="j">
  <j3-image
    class="awards-item--image"
    *ngrxLet="awardsImageSize$ as imageSize"
    [style.height.px]="height"
    [ngClass]="'awards-item--image-' + index"
    [imgSrc]="award?.file?.url"
    [sourceSize]="picturesSizesList(imageSize)"
    [title]="award.caption"
    [alt]="award.caption"
    loading="lazy"
  ></j3-image>
</ng-template>
