import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { GroupTypeDomainModel } from '../../../domain-models';
import { groupTypesActions } from '../actions';

interface GroupTypesState extends EntityState<GroupTypeDomainModel> {
  isLoading: boolean;
  isLoaded: boolean;
}

const groupTypesListAdapter: EntityAdapter<GroupTypeDomainModel> = createEntityAdapter<GroupTypeDomainModel>();

const initalGroupTypesState: GroupTypesState = groupTypesListAdapter.getInitialState({
  isLoaded: false,
  isLoading: false,
});

const groupTypesListReturn = createReducer<GroupTypesState>(
  initalGroupTypesState,
  on(groupTypesActions.GroupTypesList, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(groupTypesActions.GroupTypesListComplete, (state, { groupTypesList }) =>
    groupTypesListAdapter.setAll(groupTypesList, {
      ...state,
      isLoading: false,
      isLoaded: true,
    })),
  on(groupTypesActions.GroupTypesListFailed, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
  }))
);

const groupTypesListReducer = (state: GroupTypesState = initalGroupTypesState, action: Action): GroupTypesState =>
  groupTypesListReturn(state, action);

export { groupTypesListAdapter,
  groupTypesListReducer,
  GroupTypesState,
  initalGroupTypesState };
