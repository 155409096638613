<ng-container *ngIf="languages as languagesCollection">
  <span
    class="{{ iconCustomClass }} school-data-icon"
    *ngIf="iconValue"
    [ngClass]="{'school-data-icon-toggle': itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE}"
    (click)="toggle()"
  >
    <i class="school-data-icon--i {{ iconValue }}"></i>
  </span>

  <div
    class="school-data-translate--value"
    id="school-data-translate-{{itemId}}"
    [ngClass]="{
        'school-data-translate-toggle': itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE,
        'outside': itemType === ItemTypeEnum.TOGGLE_OUTSIDE,
        'inside': itemType === ItemTypeEnum.TOGGLE && !moveTo,
        'inside-container': itemType === ItemTypeEnum.TOGGLE && moveTo,
        'active': animationReady
    }"
    [ngStyle]="{'display':
    (valueMoved && itemType === ItemTypeEnum.TOGGLE_OUTSIDE) ||
    (itemType === ItemTypeEnum.TOGGLE && !moveToBody && !moveTo) ||
    (itemType === ItemTypeEnum.TOGGLE && (moveToBody || moveTo) && valueMoved) ||
    (itemType !== ItemTypeEnum.TOGGLE_OUTSIDE && (itemType !== ItemTypeEnum.TOGGLE && !moveToBody))
    ? 'flex' : 'none'}"
    (@toggle.done)="animationDone()"
    [@toggle]="((itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE) && !isVisible) ? 'closed' : 'open'"
  >
    <div class="translate-wrapper">
      <div class="translate-data">
        <ng-select
          id="change-language"
          [items]="languagesCollection"
          [clearable]="false"
          (change)="onSelect($event)"
          automationId="change-language"
          bindValue="id"
          bindLabel="displayName"
          placeholder="{{'Select language' | translate}}"
        >
        </ng-select>
      </div>
      <span
        class="school-data-icon-toggle-close"
        *ngIf="itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE"
        (click)="toggle()"
      >
        <i class="school-data-icon--i icon-remove-circle"></i>
      </span>
    </div>
  </div>
</ng-container>
