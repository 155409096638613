import {
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  PLATFORM_ID,
  Renderer2,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { dateValidators } from '@jotter3/common-helpers';
import {
  SiteBaseComponent,
  SiteComponentMetadata,
  TemplateBaseComponent,
} from '@jotter3/sites-abstract';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment-timezone';

import { CMS_CONTENT_COMPONENTS } from '../../common';
import { componentDefinitionsHelper } from '../../helpers';
import {
  contentBuilderModel,
  templateBuilderModel,
} from '../../models';
import { ElementSettingsDesignerComponent } from '../element-settings-designer/element-settings-designer.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'element-content-presenter',
  templateUrl: './element-content-presenter.component.html',
})
export class ElementContentPresenterComponent extends OnDestroyMixin implements OnDestroy, AfterViewInit {
  private componentInfo:
    | {
        component: Type<SiteBaseComponent | TemplateBaseComponent>,
        metadata?: SiteComponentMetadata,
      }
    | undefined;
  private designModeValue = false;
  private validFromValue = '';
  public builderElement: templateBuilderModel.TemplateComponentDefModel | contentBuilderModel.ContentItemModel;
  private readonly componentsDef$: {
    metadata?: SiteComponentMetadata,
    component: Type<SiteBaseComponent>,
  }[];
  contentPresenter: ComponentRef<SiteBaseComponent | TemplateBaseComponent> | undefined;
  private contentContainer: any;

  public get isValidDate(): boolean {
    try {
      const { validFrom, validTo } = this.builderElement?.dataset ?? {};
      const now = moment();

      return dateValidators.isDateInBorders(now.toISOString(), validFrom, validTo);
    } catch (err) {
      /*
         Previously we serialized whole moment object and sand to backend as date.
         Currently this throw error when we tray convert fake serialized moment object to real one and brake page.
         Save solution is to return true (object can be shown) in catch statement if problem appears.
      */
      return true;
    }
  }

  @Input()
  public get designMode(): boolean {
    return this.designModeValue;
  }

  public set designMode(value: boolean) {
    this.designModeValue = value;
    if (this.contentPresenter?.instance) {
      this.updateDesignMode(this.contentPresenter.instance, value);
    }
  }

  @Input()
  public get validFrom(): string {
    return this.validFromValue;
  }

  public set validFrom(value: string) {
    this.validFromValue = value;
  }

  @Input() public containerAPI:
    | Omit<templateBuilderModel.GridBuilderApi, 'addElement' | 'getElements' | 'getDataset' | 'setDataset'>
    | any
    | undefined;
  @Input() public selected = false;
  @Input() private addedItem = false;

  @Input()
  public set item(value: templateBuilderModel.TemplateComponentDefModel | contentBuilderModel.ContentItemModel) {
    this.builderElement = value;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(CMS_CONTENT_COMPONENTS)
    componentsDef: Type<SiteBaseComponent>[][],
    private componentFactory: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private modal: NgbModal,
    private renderer: Renderer2,
    private applicationRef: ApplicationRef,
    private readonly appInjector: Injector
  ) {
    super();
    this.componentsDef$ = componentDefinitionsHelper.getComponentDefinitions(componentsDef.flat());
  }

  public ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.initializeComponent();
      return;
    }

    setTimeout(this.initializeComponent.bind(this), 5);
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.contentPresenter?.destroy();
  }

  public onSettings(): void {
    if (!this.componentInfo) {
      return;
    }

    this.contentPresenter?.instance.settingsModalActive$.next(true);

    const dialogRef = this.modal.open(ElementSettingsDesignerComponent, {
      size: 'lg',
    });

    dialogRef.componentInstance.settings = {
      onDatasetChanged: this.onDatasetChanged.bind(this),
      componentMetadata: this.contentPresenter?.instance.componentMetadata,
      ...this.contentPresenter?.instance.mapToSettingsDesigner(),
    };
  }

  public onRemove(): void {
    this.containerAPI?.removeElement(this.builderElement as any);
  }

  private onDatasetChanged(dataset: { [key: string]: any }): void {
    if (!this.builderElement?.dataset) {
      return;
    }

    this.builderElement.dataset = dataset;
  }

  private updateDesignMode(
    instance: SiteBaseComponent | TemplateBaseComponent,
    designMode: boolean,
    locked: boolean = false
  ): void {
    if (instance instanceof SiteBaseComponent) {
      instance.designMode = designMode;
    }

    if (instance instanceof TemplateBaseComponent) {
      instance.templateDesignMode = designMode;
    }

    if (locked || (instance && instance.validTime())) {
      return;
    }

    switch (designMode) {
      case false:
        this.builderElement.dataset = instance.getDataset();
        this.renderer.removeChild(this.container.element.nativeElement, this.contentContainer);
        break;
      case true:
        this.initializeComponent();
        break;
    }
  }

  private initializeComponent(): void {
    try {
      const { component, instance: createdComponent } = this.builderElement;

      this.componentInfo = this.componentsDef$.find((cmpInfo) =>
        typeof component === 'string' || component instanceof String
          ? [cmpInfo.metadata.selector].flat().includes(component as string)
          : cmpInfo.component === component);

      if (!this.componentInfo) {
        console.warn(`Component ${component} not found`);
        return;
      }

      if (isPlatformServer(this.platformId) && this.componentInfo.metadata?.isBrowserComponentOnly) {
        console.warn(`Component ${component} only render on browser side`);
        return;
      }

      if (!isPlatformBrowser(this.platformId)) {
        this.builderElement = {
          ...this.builderElement,
          component: this.componentInfo.component,
        } as any;
      }

      const factory = this.componentFactory.resolveComponentFactory<TemplateBaseComponent | SiteBaseComponent>(
        this.componentInfo.component
      );

      if (!this.designMode && !this.isValidDate) {
        return;
      }

      this.contentContainer = this.renderer.createElement('div');

      this.renderer.appendChild(this.container.element.nativeElement, this.contentContainer);

      this.contentPresenter = factory.create(this.appInjector, [], this.contentContainer);

      const { instance } = this.contentPresenter as { instance: any };

      this.updateDesignMode(instance, this.designMode, true);

      if (instance instanceof SiteBaseComponent) {
        if (createdComponent) {
          this.builderElement.dataset = createdComponent.getDataset();
        }
        instance.setDataset(this.builderElement.dataset);
      }

      this.applicationRef.attachView(this.contentPresenter.hostView);

      try {
        this.builderElement.instance = instance;
      } catch {
        this.builderElement = {
          ...this.builderElement,
          instance,
        };
      }

      if (this.designMode && this.addedItem && this.componentInfo.metadata.showSettingsAfterAdd) {
        this.onSettings();
      }

    } catch (err) {}
  }
}
