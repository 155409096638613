import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createAction,
  props,
} from '@ngrx/store';

import { GdprSettingsDomainModel } from '../../domain-models';

const ACTIONS_PREFIX = '[J3|API|GDPR]';

export const GdprStoreActions = {
  loadTrigger: createAction(`${ACTIONS_PREFIX} Load trigger`),
  loadSuccess: createAction(`${ACTIONS_PREFIX} Load success`, props<{ entity: GdprSettingsDomainModel }>()),
  loadFailed: createAction(`${ACTIONS_PREFIX} Load failed`, props<{ errors: Array<ApiErrorResponse | unknown> }>()),
  upsertTrigger: createAction(`${ACTIONS_PREFIX} Upsert trigger`, props<{ entity: Partial<GdprSettingsDomainModel> }>()),
  upsertSuccess: createAction(`${ACTIONS_PREFIX} Upsert success`, props<{ entity: Partial<GdprSettingsDomainModel> }>()),
  upsertFailed: createAction(`${ACTIONS_PREFIX} Upsert failed`, props<{ errors: Array<ApiErrorResponse | unknown> }>()),
};
