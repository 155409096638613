import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  DOCUMENT,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  PageDomainModel,
  pagesListState,
  siteStoreSelectors,
} from '@jotter3/api-connector';
import {
  AppType,
  J3TranslateService,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import { SiteContentComponentStore } from '@jotter3/core';
import {
  ModifierType,
  Property,
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  cloneDeep,
  isNil,
} from 'lodash-es';
import {
  BehaviorSubject,
  debounceTime,
  fromEvent,
  of,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import {
  IMenuItem,
  ISubpage,
} from '../../models';
import { TemplateDataProvider } from '../../providers';

enum SiteMenuType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  VERTICALWITHBUTTONS = 'vertical-with-buttons',
  HAMBURGER = 'hamburger',
  HAMBURGERFIXED = 'hamburger-fixed',
  HAMBURGERFIXEDVERTICAL = 'hamburger-fixed-vertical',
  MOBILE = 'mobile'
}

enum MenuFilterType {
  ALLCHILDREN = 'all-children'
}

enum ExtendSide {
  DOWN = 'extend-down',
  RIGHT = 'extend-right'
}

@TemplateComponent({
  selector: 'jotter-tplcmp-menu',
  displayName: 'Menu',
  icon: 'settings',
  defaultClass: 'site-nav',
  defaultTag: 'nav',
})
@Component({
  selector: 'jotter-tplcmp-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./scss/menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
    trigger('slideToggle', [
      state(
        'false',
        style({
          left: '{{menuWidthValue}}',
        }),
        { params: { menuWidthValue: '-360px' } }
      ),
      state(
        'true',
        style({
          left: '0',
        })
      ),
      transition('true <=> false', [animate('0.3s')]),
    ]),
    trigger('fadeToggle', [
      state(
        'true',
        style({
          display: 'block',
        })
      ),
      state(
        'false',
        style({
          display: 'none',
        })
      ),
      transition('true <=> false', [animate('0.1s')]),
    ]),
    trigger('menuAnimation', [
      state(
        'open',
        style({
          opacity: '{{opacityOpen}}',
          'padding-top': '{{paddingTopOpen}}',
        }),
        {
          params: {
            paddingTopOpen: '30px',
            opacityOpen: 1,
          },
        }
      ),
      state(
        'closed',
        style({
          opacity: 0,
          'padding-top': '{{paddingTopClosed}}',
        }),
        { params: { paddingTopClosed: '50px' } }
      ),
      transition('closed <=> open', [animate('{{animationSpeed}}')], {
        params: { animationSpeed: '0.2s' },
      }),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent extends TemplateBaseComponent implements OnInit, AfterViewInit {
  readonly #changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  readonly #store: Store = inject(Store);
  readonly #componentStore: SiteContentComponentStore = inject(SiteContentComponentStore);

  public menuItemsValue$: BehaviorSubject<IMenuItem[]> = new BehaviorSubject<IMenuItem[]>([]);
  public menuItemsListFull: IMenuItem[] = [];
  public menuItemsMoreList: IMenuItem[] = [];
  public maxMenuItemsValue: number | null = null;
  public isActive = false;
  public openedSections: string[] = [];
  public allPagesList: IMenuItem[] = [];
  public menuID: string = UUID();
  public siteMenuType = SiteMenuType;
  public menuFilterType = MenuFilterType;
  public menuVisible = false;
  public menuHeight = -1;
  private resizeInProgress = false;
  public containerHeight = -1;
  private templateContainerPosition = 0;
  public showMenuItem = false;
  public menuHovered: string | undefined;
  public toggleMenuWidth = '360px';
  public page: PageDomainModel;

  readonly #sourcePages$ = this.#store.select(pagesListState.pagesSelectors.pagesListSelector).pipe(
    filter(pages => !!pages.length),
    map(pages => pages.sort((a, b) => (!isNil(a.lp) ? a.lp : Infinity) - (!isNil(b.lp) ? b.lp : Infinity)))
  );

  public pages$ = this.#sourcePages$.pipe(
    concatLatestFrom(() => this.#store.select(siteStoreSelectors.selectEntity)
      .pipe(
        filter(settings => !isNil(settings)),
        map(settings => settings.customContent[0]?.maxMenuItems)
      )),
    map(([
      pages,
      maxMenuItems,
    ]) => {
      const menuItemListTree = this.filterItems(pages, (item) => !item.parentId || item.parentId?.trim() === '');
      return [
        pages,
        menuItemListTree,
        maxMenuItems,
        this.menuType,
        this.showMore,
      ] as const;
    }),
    map(([
      pages,
      menuItemListTree,
      maxMenuItems,
    ]) => {
      this.maxMenuItemsValue = maxMenuItems;
      if (this.menuType !== SiteMenuType.HORIZONTAL || this.menuType === SiteMenuType.HORIZONTAL && !this.showMore) {
        if (this.menuFilter === MenuFilterType.ALLCHILDREN) {
          this.document.body.classList.add(`empty-${this.menuType}`);
        }
        return this.filterMenuItems(menuItemListTree, pages, this.router.url);
      } else if (isNil(this.maxMenuItemsValue)) {
        this.menuItemsListFull = cloneDeep(menuItemListTree);
        setTimeout(() => this.checkMoreItemPosition(menuItemListTree, this.menuItemsMoreList), 500);
        return menuItemListTree;
      } else {
        const menuData = menuItemListTree.length > this.maxMenuItemsValue ? this.checkMenuLength(menuItemListTree, this.maxMenuItemsValue) : menuItemListTree;
        return menuData;
      }

    })
  );

  public readonly childLevelPages$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(this.router),
    map(event => event as NavigationEnd),
    map(({ url }: NavigationEnd) => {
      if (this.moduleProvider.applicationType === AppType.CLIENT) {
        return url;
      }

      return url.replace('/sites/page/', '');
    }),
    distinctUntilChanged(),
    concatLatestFrom(() => this.#sourcePages$),
    map(([
      url,
      pages,
    ]) => {
      const menuItemListTree = this.filterItems(pages, (item) => !item.parentId || item.parentId?.trim() === '');
      return this.filterMenuItems(menuItemListTree, pages, url);
    })
  );


  constructor(
    public dataService: TemplateDataProvider,
    private elem: ElementRef,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(MODULE_PROVIDER_TOKEN) private moduleProvider: ModuleProvider
  ) {
    super(MenuComponent);
  }

  @Property({
    displayName: 'Orientation',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: 'Vertical',
        value: true,
      },
      {
        label: 'Horizontal',
        value: false,
      },
      {
        label: 'Hamburger',
        value: false,
      },
      {
        label: 'Hamburger-fixed',
        value: false,
      },
      {
        label: 'Mobile',
        value: false,
      },
    ],
  })
  @Input()
    menuType: string = SiteMenuType.HORIZONTAL;
  @Input() showArrows = false;
  @Input() menuWidth: number | undefined;
  @Input() addAnimation = false;
  @Input() paddingTopOpen: number | undefined;
  @Input() paddingTopClosed: number | undefined;
  @Input() animationSpeed: number | undefined;
  @Input() menuFilter = '';
  @Input() showMore = true;
  @Input() hamburgerTitle = '';
  @Input() menuFixed = false;
  @Input() extendSide: string = ExtendSide.DOWN;
  @Input() openIcon = 'icon-chevron-right';
  @Input() closeIcon = 'icon-chevron-right';

  @ViewChild('standardMenu') standardMenu: ElementRef | undefined;

  @HostListener('window:touchstart', ['$event']) onTouchStart(event: Event): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const { offsetParent } = event.target as any;

    if (!offsetParent?.classList.contains('site-menu-item')) {
      this.hideSubmenuItems();
    }
  }

  onResize(): void {
    if (this.menuType === SiteMenuType.HORIZONTAL && this.showMore) {

    }

    this.resizedEnded();

    if (this.menuType === SiteMenuType.HAMBURGERFIXEDVERTICAL) {
      this.checkMenuWidth(this.menuWidth);
      const menuEl = this.document.getElementById(`site-menu--${this.menuType}-wrapper-${this.menuID}`).parentNode;
      const menuDisplayValue = window.getComputedStyle(menuEl).getPropertyValue('display');

      if (menuDisplayValue === 'none') {
        this.isActive = false;
        this.document.body.classList.remove('overflow-hidden');
      }
    }
  }

  private resizedEnded(): void {
    if (this.resizeInProgress) {
      return;
    }
    this.resizeInProgress = true;

    if (this.menuType === SiteMenuType.HORIZONTAL && this.showMore) {
      this.hideSubmenuItems();
      this.clearMenuPosition();
      if (isNil(this.maxMenuItemsValue)) {
        this.checkMoreItemPosition(cloneDeep(this.menuItemsListFull), []);
      }
    }
  }

  private onWindowScroll(): void {

    const dummyElement = this.document.getElementById(`site-menu-${this.menuType}-${this.menuID}-dummy-container`);
    const fixedWrapper = this.document.getElementById(`site-menu-${this.menuType}-${this.menuID}-fixed-wrapper`);

    if (isNil(dummyElement) || isNil(fixedWrapper)) {
      return;
    }

    if (0 >= dummyElement.getBoundingClientRect().top - this.templateContainerPosition) {
      fixedWrapper.classList.add('sticky-menu');

      const mainHeaderHTMLObject = document.getElementById('main-header');

      if (mainHeaderHTMLObject && window.getComputedStyle(mainHeaderHTMLObject).getPropertyValue('position') === 'static') {
        fixedWrapper.classList.add('sticky-menu-smaller');
      }

      const horizontalMenu = this.document.getElementById(`site-menu-horizontal-${this.menuID}`);

      if (!isNil(horizontalMenu)) {
        dummyElement.style.height = `${
          horizontalMenu.getBoundingClientRect().height
        }px`;
      }
    } else {
      fixedWrapper.classList.remove('sticky-menu');
      fixedWrapper.classList.remove('sticky-menu-smaller');
      dummyElement.style.height = 0;
    }
  }

  public generateUrl(pageName: string | string[]): string[] {
    const urlSegments = typeof pageName === 'string' ? [pageName] : pageName;
    return [
      this.dataService.appEditorUrlLinks ? this.dataService.appEditorUrlLinks : '',
      urlSegments,
    ].flat();
  }

  public redirectToExternal(att: { name: string, value: string }[]): void {
    const target = att.find((item) => item?.name === 'openInNewTab')?.value ? '_blank' : '_self';
    const url: string | undefined = att.find((item) => item?.name === 'externalUrl')?.value;
    window.open(this.getHttpUrl(url) ? url : `//${url}`, target);
  }

  private getHttpUrl(value: string | undefined): boolean {
    if (!value) {
      return false;
    }
    let url: URL;
    try {
      url = new URL(value);
    } catch (err) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  private filterItems(array: IMenuItem[], comparer: (parent: ISubpage) => boolean): IMenuItem[] {
    const result: IMenuItem[] = [];

    for (const item of array.filter((x) => comparer(x))) {
      if (!item.draftState || item.hideInMenu) {
        continue;
      }

      result.push({
        ...item,
        children: this.filterItems(array, (subElement) => subElement.parentId === item.id),
      });
    }

    return result;
  }

  public toggleMenu(): void {
    this.isActive = !this.isActive;
    if (this.isActive && this.menuType === SiteMenuType.HAMBURGERFIXEDVERTICAL) {
      this.document.body.classList.add('overflow-hidden');
    } else {
      this.document.body.classList.remove('overflow-hidden');
    }
  }

  public showSubmenu(menuItem: IMenuItem, levelNum: number): void {
    const currentMenuItem = this.openedSections.indexOf(menuItem.id);
    if (currentMenuItem !== -1) {
      this.openedSections.splice(currentMenuItem, 1);
    } else {
      if (levelNum === 1) {
        this.openedSections = [];
      }
      this.openedSections.push(menuItem.id);
    }
  }

  public checkChildPosition(itemClass: string): void {
    const currentMenuItem = this.elem.nativeElement.querySelector(`.site-menu-item--submenu-${itemClass}`);
    const windowWidth = window.innerWidth;
    currentMenuItem?.parentElement.classList.add('sub-menu-visible');

    if (
      !currentMenuItem?.parentElement.classList.contains('site-menu-item--submenu-left') &&
      currentMenuItem?.getBoundingClientRect().right > windowWidth
    ) {
      currentMenuItem?.parentElement.classList.add('site-menu-item--submenu-left');
    }
    const menuItem =
      itemClass === '-more' ? `more-items-${this.menuType}-${this.menuID}` : `site-menu-item-${this.menuType}-${itemClass}`;

    const menuItemElement = document.getElementById(menuItem);

    if (menuItemElement && menuItemElement.classList.contains('site-menu-item-top')) {
      this.showMenuItem = true;
      this.menuHovered = itemClass === '-more' ? this.menuID : itemClass;
    }
  }

  public hideMenuItem(itemClass: string): void {
    const currentMenuItem = this.elem.nativeElement.querySelector(`.site-menu-item--submenu-${itemClass}`);
    currentMenuItem?.parentElement.classList.remove('sub-menu-visible');
    const menuItem =
      itemClass === '-more' ? `more-items-${this.menuType}-${this.menuID}` : `site-menu-item-${this.menuType}-${itemClass}`;

    const menuItemElement = document.getElementById(menuItem);

    if (menuItemElement && menuItemElement.classList.contains('site-menu-item-top')) {
      this.menuHovered = undefined;
      this.showMenuItem = false;
    }
  }

  public touchStart(e: Event, itemClass: string, parent: boolean): void {
    const currentLinkItem = this.elem.nativeElement.querySelector(`.site-menu-item--link-${itemClass}`);
    const hasChildren = currentLinkItem.classList.contains('has-children');
    const subMenuVisible = currentLinkItem?.parentElement.classList.contains('sub-menu-visible');
    if (hasChildren && !subMenuVisible) {
      e.preventDefault();
      if (parent) {
        this.hideSubmenuItems();
      }

      this.checkChildPosition(itemClass);
    }
  }

  private hideSubmenuItems(): void {
    Array.from(document.querySelectorAll('.site-menu-item')).forEach((el) => {
      el.classList.remove('sub-menu-visible');
    });
  }

  private clearMenuPosition(): void {
    Array.from(document.querySelectorAll('.site-menu-item')).forEach((el) => {
      el.classList.remove('site-menu-item--submenu-left');
    });
  }

  public checkMenuWidth(menuWidth: string | number | undefined): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const currentMenuWidth = menuWidth ? menuWidth : 360;
    const widthType = typeof currentMenuWidth === 'number' ? 'px' : '';
    document.documentElement.style.setProperty('--hamburger-fixed-vertical-width', currentMenuWidth + widthType);
    this.toggleMenuWidth = currentMenuWidth + widthType;
  }

  public ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.router.events
      .pipe(
        filter((ev) => ev instanceof NavigationStart),
        untilComponentDestroyed(this)
      )
      .subscribe(() => {
        this.isActive = false;
      });

    if (this.menuType === SiteMenuType.HAMBURGERFIXEDVERTICAL) {
      this.checkMenuWidth(this.menuWidth);
    }

    this.#componentStore.selectPage$.subscribe((page) => {
      this.page = page;
    });
  }

  public ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.#changeDetector.detectChanges();

    if (this.moduleProvider.applicationType !== AppType.CLIENT) {
      const mainHeader = document.getElementById('main-header');
      const toolbar = this.document.getElementsByClassName('toolbar-wrapper')[0].getBoundingClientRect();
      if (!!mainHeader && !!toolbar) {
        const header =
          window.getComputedStyle(mainHeader).getPropertyValue('position') === 'static'
            ? this.document.getElementsByClassName('main-header')[0].getBoundingClientRect().height
            : 0;
        this.templateContainerPosition = toolbar.height + header;
      }
    }

    fromEvent(window, 'scroll').pipe(
      untilComponentDestroyed(this),
      debounceTime(50),
      filter(() => isPlatformBrowser(this.platformId) && this.menuFixed)
    ).subscribe(() => {
      this.onWindowScroll();
    });

    fromEvent(window, 'resize').pipe(
      untilComponentDestroyed(this),
      debounceTime(200),
      map(() => {
        if (isNil(this.maxMenuItemsValue) && this.menuType === SiteMenuType.HORIZONTAL && this.showMore) {
          this.menuItemsMoreList = [];
          this.pages$ = of(cloneDeep(this.menuItemsListFull));
          this.#changeDetector.detectChanges();
        }
      })
    ).subscribe(() => {
      this.menuHeight = -1;
      this.onResize();
      this.menuVisible = false;
      this.onWindowScroll();
    });
  }


  private filterMenuItems(menuItemListTree: IMenuItem[], pages: ISubpage[], url: string): IMenuItem[] {
    this.document.body.classList.remove(`empty-${this.menuType}`);

    if ((this.router.url.includes('/calendar?') || this.router.url.includes('/search?')) && this.menuFilter === MenuFilterType.ALLCHILDREN) {
      this.document.body.classList.add(`empty-${this.menuType}`);
      return [];
    }
    const decodedUrl = decodeURIComponent(url);

    if (this.menuFilter === MenuFilterType.ALLCHILDREN) {
      const currentItems = pages.filter((item) => item.name === decodedUrl.split('/').slice(-1)[0]) ?? [];

      if (!currentItems?.length) {
        return [];
      }

      const topLevel: IMenuItem[] = this.getParent(pages, currentItems[0].id as string);
      const list = pages.sort((a, b) => (!isNil(a.lp) ? a.lp : Infinity) - (!isNil(b.lp) ? b.lp : Infinity));
      const childrenList = this.getChildrenList(topLevel[0], list);
      if (!childrenList.length && this.menuFilter === MenuFilterType.ALLCHILDREN) {
        this.document.body.classList.add(`empty-${this.menuType}`);
      }

      return childrenList.flat();
    } else {
      if (!menuItemListTree.length && this.menuFilter === MenuFilterType.ALLCHILDREN) {
        this.document.body.classList.add(`empty-${this.menuType}`);
      }
      return menuItemListTree;
    }
  }

  private getChildrenList(topLevel: IMenuItem, pages: ISubpage[]): ISubpage[] {
    return pages
      .filter((page) => !isNil(page) && page.parentId === topLevel?.id && page.draftState && !page.hideInMenu)
      .map((page) => ({
        ...page,
        children: this.getChildrenList(page, pages),
      }));
  }

  public getParent(list: IMenuItem[], id: string): IMenuItem[] {
    let node = list.filter((item) => item.id === id);
    if (node[0]?.parentId) {
      return (node = this.getParent(list, node[0]?.parentId));
    }
    return node;
  }

  private checkMenuLength(menuItemListTree: IMenuItem[], maxMenuItems: number): IMenuItem[] {
    const menuList = cloneDeep(menuItemListTree).splice(0, maxMenuItems - 1);
    this.menuItemsMoreList = cloneDeep(menuItemListTree).splice(maxMenuItems - 1, menuItemListTree.length - 1);
    return menuList;
  }

  public toggleStartAnimationData(event: any, id: any): void {
    event.element.style.display = 'block';
    if (this.showMenuItem) {
      event.element.style.zIndex = null;
      event.element.style.pointerEvents = null;
      this.checkChildPosition(id);
    } else {
      event.element.style.zIndex = -2;
      event.element.style.pointerEvents = 'none';
    }
  }

  public toggleDoneAnimationData(event: any, id: unknown): void {
    if (this.showMenuItem && this.menuHovered === id) {
      event.element.style.display = 'block';
      event.element.classList.add('site-menu-item--submenu-open');
    } else {
      event.element.style.display = 'none';
      event.element.classList.remove('site-menu-item--submenu-open');
    }
  }

  public animationStatus(e: any, id: string, status: 'start' | 'done'): void {
    switch (status) {
      case 'start': {
        e.element.classList.remove('site-menu-item--submenu-open');
        break;
      }
      case 'done': {
        if (this.openedSections.find((item) => item === id)) {
          e.element.classList.add('site-menu-item--submenu-open');
        }
        break;
      }
    }
  }

  public menuItemsWidth(): string {
    return `repeat('${this.maxMenuItemsValue}', min(100px))`;
  }

  public checkMoreItemPosition(menuList: IMenuItem[], menuMore: IMenuItem[]): void {
    const moreItem = this.document.getElementById(`more-items-${this.menuType}-${this.menuID}`);
    const menuElement = this.document.getElementById(`site-menu-${this.menuType}-${this.menuID}`);

    if (!menuElement || !menuElement?.getBoundingClientRect) {
      return;
    }
    const moveToMore = [];
    const firstItem = this.document.getElementById(`site-menu-item-${this.menuType}-${this.menuItemsListFull[0].id}`);

    for (const [i] of menuList.entries()) {
      if (
        this.document.getElementById(`site-menu-item-${this.menuType}-${this.menuItemsListFull[i].id}`)?.offsetTop -
          this.document.getElementById(`site-menu-item-${this.menuType}-${this.menuItemsListFull[0].id}`)?.offsetTop >
        0
      ) {
        moveToMore.push(this.menuItemsListFull.findIndex((x) => x.id === this.menuItemsListFull[i].id));
      }
    }

    for (let i = moveToMore.length; i--;) {
      menuMore.unshift(this.menuItemsListFull[menuList.length - 1]);
      menuList.pop();
    }

    if (firstItem.offsetTop - moreItem.offsetTop < 0) {
      menuMore.unshift(this.menuItemsListFull[menuList.length - 1]);
      menuList.pop();
    }

    this.menuItemsMoreList = cloneDeep(menuMore);

    this.menuVisible = true;
    this.resizeInProgress = false;
    this.menuHeight = 0;
    this.pages$ = of(menuList);

    this.#changeDetector.detectChanges();

  }

  public containsActivePage(items: IMenuItem[]): boolean {
    if (items.length === 0) {
      return false;
    }

    return items.reduce((prev, curr) => prev || curr.name === this.page?.name, false)
      || items.reduce((prev, curr) => prev || this.containsActivePage(curr.children), false);
  }

}
