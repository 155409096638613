import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';
import { isNil } from 'lodash-es';

import { PermissionDomainModel } from '../../../domain-models';
import * as permissionsActions from '../actions';

interface PermissionsState extends EntityState<PermissionDomainModel> {
  isLoading: boolean;
  isLoaded: boolean;
}

const permissionsAdapter: EntityAdapter<PermissionDomainModel> = createEntityAdapter<PermissionDomainModel>({
  sortComparer: false,
});

const initialPermissionsState: PermissionsState = permissionsAdapter.getInitialState({
  isLoading: true,
  isLoaded: false,
});

const permissionsReturn = createReducer<PermissionsState>(
  initialPermissionsState,
  on(permissionsActions.LoadPermissions, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(permissionsActions.LoadPermissionsComplete, (state, { permissions }) =>
    permissionsAdapter.setAll(isNil(permissions) ? [] : permissions, {
      ...state,
      isLoading: false,
      isLoaded: true,
    })),
  on(permissionsActions.LoadPermissionsFailed, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
  }))
);

const permissionsReducer = (state: PermissionsState = initialPermissionsState, action: Action): PermissionsState =>
  permissionsReturn(state, action);

export { initialPermissionsState,
  permissionsAdapter,
  permissionsReducer,
  PermissionsState };
