import {
  inject,
  Injectable,
  Injector,
} from '@angular/core';
import { NGB_MODAL_DATA } from '@jotter3/api-connector';

@Injectable({ providedIn: 'root' })
export class InjectorHelperService {
  #injector = inject(Injector);
  public provideModalData(data: any): Injector {
    return Injector.create({
      providers: [
        {
          provide: NGB_MODAL_DATA,
          useValue: data,
        },
      ],
    });
  }
}
