import { Component } from '@angular/core';
import { SiteComponent } from '@jotter3/sites-abstract';

import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'jotter-cmp-permission-deny',
  displayName: 'Permission Deny',
  selectedByUser: false,
})
@Component({
  template: `
    <div class="page-wrap d-flex flex-row align-items-center">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">
            <span class="display-1 d-block">Oops!</span>
            <div class="mb-4 lead">You cannot access this page.</div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class PermissionDenyComponent extends JotterSitesBaseComponent {
  constructor() {
    super(PermissionDenyComponent);
  }
}
