import { enums } from '@jotter3/api-connector';
import { OrderBy } from '@jotter3/common-helpers';
import { DropdownItemModel } from '@jotter3/sites-abstract';
import { dataQuery } from '@jotter3/wa-core';


export enum VIEW_STYLE {
  STANDARD = 1,
  STYLED_DATA = 2
}

export const DEFAULT_PAGE_SIZE = 5;
export const DEFAULT_HEADER = 'Letters';

export const DEFAULT_VIEW_STYLE: VIEW_STYLE = VIEW_STYLE.STANDARD;

export const VIEW_STYLE_DROPDOWN_SOURCE: DropdownItemModel[] = [
  {
    label: 'Standard',
    value: VIEW_STYLE.STANDARD,
    icon: 'style_normal',
  },
  {
    label: 'With stylised date',
    value: VIEW_STYLE.STYLED_DATA,
    icon: 'style_aside',
  },
];

export const CONST_DATA_QUERY_FILTERS: dataQuery.Filter[] = [
  {
    value: enums.DraftState.PUBLISHED,
    property: 'draftState',
    type: dataQuery.FilterType.EMPTY,
    operator: dataQuery.Operator.EQ,
  },
  {
    value: 0,
    property: 'availability',
    type: dataQuery.FilterType.EMPTY,
    operator: dataQuery.Operator.EQ,
  },
];

export const LETTERS_ORDER_DROPDOWN_SOURCE: Record<OrderBy, dataQuery.SingleOrder> = {
  [OrderBy.NAME_ASC]: {
    key: 'name',
    direction: dataQuery.Direction.ASC,
  },
  [OrderBy.NAME_DESC]: {
    key: 'name',
    direction: dataQuery.Direction.DESC,
  },
  [OrderBy.DATE_ASC]: {
    key: 'publishDate',
    direction: dataQuery.Direction.ASC,
  },
  [OrderBy.DATE_DESC]: {
    key: 'publishDate',
    direction: dataQuery.Direction.DESC,
  },
};
