import { enums } from '@jotter3/api-connector';
import { isNil } from 'lodash-es';

const picturesSizesList = (imageSize: enums.PicturesSizes): Array<string> => {
  const selectedImageSize = !isNil(enums.ImageSize[imageSize]) ? parseInt(`${enums.ImageSize[imageSize]}`, 10) : 1920;
  const picsSizes: string[] = [];
  const pictureSizes = Object.values(enums.PicturesSizes);

  pictureSizes.forEach((size, index) => {
    const sizeValue = !isNil(enums.ImageSize[pictureSizes[index]]) ? enums.ImageSize[pictureSizes[index]] : 1920;
    switch (true) {
      case index === pictureSizes.length - 1 && selectedImageSize > enums.ImageSize.ml:
        picsSizes.push(enums.PicturesSizes.L);
        break;
      case selectedImageSize >= sizeValue:
        picsSizes.push(pictureSizes[index]);
        break;
      case selectedImageSize > enums.ImageSize[pictureSizes[index - 1]] && selectedImageSize < sizeValue:
        picsSizes.push(pictureSizes[index]);
        break;
      case selectedImageSize <= enums.ImageSize.xs && size === enums.PicturesSizes.XS:
        picsSizes.push(enums.PicturesSizes.XS);
        break;
    }
  });

  return picsSizes;
};

export { picturesSizesList };
