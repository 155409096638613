import { LoadingStateEnum } from '@jotter3/store-helpers';
import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { PageState } from '../reducers';

const selectPageContentState = createFeatureSelector<PageState>('pageContent');

export const selectPageContentStateSelector = createSelector(
  selectPageContentState,
  (state: PageState) => state
);

export const selectCurrentPage = createSelector(
  selectPageContentState,
  (state: PageState) => state.currentPage
);

export const selectCurrentPageContent = createSelector(
  selectPageContentState,
  (state: PageState) => state.currentPageContent
);

export const selectPageContentSelector = createSelector(
  selectPageContentStateSelector,
  (state: PageState) => state
);

export const selectPageContentLoadingState = createSelector(
  selectPageContentStateSelector,
  (state: PageState) => state.loadingState
);

export const selectIsPageContentLoading = createSelector(
  selectPageContentLoadingState,
  (loadingState: LoadingStateEnum) => loadingState === LoadingStateEnum.LOADING
);

export const selectIsPageContentLoaded = createSelector(
  selectPageContentLoadingState,
  (loadingState: LoadingStateEnum) => loadingState === LoadingStateEnum.SUCCESS || loadingState === LoadingStateEnum.FAILED
);

export const selectLoadingState = createSelector(
  selectPageContentStateSelector,
  (state: PageState) => state.loadingState
);

export const selectCurrentPageUrl = createSelector(
  selectPageContentStateSelector,
  (state: PageState) => state.pageUrl
);

export const selectPageContentErrors = createSelector(
  selectPageContentSelector,
  (state: PageState) => state.error
);
