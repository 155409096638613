import { LoadingStateEnum } from '@jotter3/store-helpers';
import { createSelector } from '@ngrx/store';

import { siteSettingsStoreFeature } from './site-settings.reducer';

const {
  selectLoadingStatus,
  selectSiteId,
  selectEntity,
  selectErrors,
} = siteSettingsStoreFeature;

const selectIsLoading = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.LOADING
);

const selectIsLoaded = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.SUCCESS || loadingStatus === LoadingStateEnum.FAILED
);

export const siteSettingsStoreSelectors = {
  selectEntity,
  selectSiteId,
  selectErrors,
  selectIsLoaded,
  selectIsLoading,
  selectLoadingStatus,
};
