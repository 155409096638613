import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Injectable,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  domainModels,
  pagesListState,
  settingsState,
} from '@jotter3/api-connector';
import {
  ApiService,
  dataQuery,
  LHSBracketsDataQueryAdapter,
} from '@jotter3/wa-core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { urlJoin } from 'url-join-ts';

import {
  IDataModel,
  ISubpage,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsDataService {
  public slideshowDataObserver: BehaviorSubject<domainModels.SlideshowDomainModel> =
    new BehaviorSubject<domainModels.SlideshowDomainModel>(undefined);
  appEditorUrlLinks = '/sites/page';

  constructor(
    private apiService: ApiService,
    private queryAdapter: LHSBracketsDataQueryAdapter,
    private store: Store,
    private router: Router,
    @Inject(DOCUMENT) private document: any
  ) {}

  /** USED FOR TEMPALTE COMPONENTS */
  public getData(): Observable<IDataModel> {
    return this.store.select(settingsState.settingsSelectors.generalSettingsSelector.generalSettingsSelector).pipe(
      tap((generalSettings) => {
        if (generalSettings?.isLoaded || generalSettings?.isLoading) {
          return;
        }
        this.store.dispatch(settingsState.settingsActions.generalSettingsActions.LoadGeneralSettings());
      }),
      filter((generalSettings) => generalSettings?.isLoaded),
      take(1),
      map((settings) => {
        return {
          ...settings,
          headTeacher: {
            name: settings?.headteacher,
          },
          schoolName: settings?.name,
          motto: settings?.motto,
          phoneNr: settings?.telephone,
          logoImg: settings?.logo,
          address: {
            street: settings?.street,
            postcode: settings?.postcode,
            city: settings?.city,
          },
          pageUrl: '/sites/page/',
          socialMedia: {
            facebook: settings?.facebook,
            twitter: settings?.twitter,
            instagram: settings?.instagram,
            vimeo: settings?.vimeo,
            youtube: settings?.youtube,
          },
        } as any;
      })
    );
  }

  public getPages(): Observable<ISubpage[]> {
    return this.store.select(pagesListState.pagesSelectors.pagesListSelector).pipe(take(1));
  }

  public get slideshowData(): Observable<domainModels.SlideshowDomainModel> {
    return this.slideshowDataObserver.asObservable();
  }

  public loadSlideshowForPage(pageId: string): void {
    const queryParam: dataQuery.DataQuery = {
      pagination: {
        page: 1,
        size: 1,
      },
    };

    const topSlideshowContainer = this.document.getElementsByClassName('top-swiper-slideshow');
    if (topSlideshowContainer.length) {
      topSlideshowContainer[0].style.height = null;
    }

    this.apiService
      .load<domainModels.SlideshowDomainModel>(urlJoin('pages', pageId, 'slideshows'), this.queryAdapter.toQueryParam(queryParam))
      .pipe(
        filter((res) => {
          const result = !!res.result.length;
          if (!result) {
            this.slideshowDataObserver.next(undefined);
          }
          return result;
        }),
        switchMap((res) => this.apiService.loadSingle<domainModels.SlideshowDomainModel>('slideshows', res.result[0].id))
      )
      .subscribe((res) => {
        this.slideshowDataObserver.next(res.result);
      });
  }

  public invokeSearch(phrase: string): void {
    this.router.navigate(['search'], { queryParams: { phrase } });
  }
}
