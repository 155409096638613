
import * as copyPastePlugin from './copy-paste/copy-paste.plugin';
import {
  J3Plugin,
  J3PluginNodeTransform,
} from './core-types';
import * as imagePlugin from './image/image.plugin';
import * as linkPlugin from './link/link.plugin';

const plugins: J3Plugin[] = [
  linkPlugin.initialize,
  imagePlugin.initialize,
  copyPastePlugin.initialize,
];

const nodeTransformations: J3PluginNodeTransform[] = [
  linkPlugin.inspectCurrentContent,
  imagePlugin.inspectCurrentContent,
];

export { nodeTransformations,
  plugins };
