<ng-container *ngIf="attachments">
  <ng-container *ngIf="noPaddingsValue; else noWrapper">
    <div class="no-padding-row">
      <ng-container *ngTemplateOutlet="recursiveList"></ng-container>
    </div>
  </ng-container>

  <ng-template #noWrapper>
    <ng-container *ngTemplateOutlet="recursiveList"></ng-container>
  </ng-template>
</ng-container>
<ng-template #recursiveList>
  <div
    class="carousel-container content-element content-element-slideshow"
    id="carousel-container-{{ elementId }}"
    [ngClass]="cssClass + ''"
  >
    <div *ngIf="customHeader">
      <div class="sectionHeader h3" [id]="'element-header-' + elementId">
        {{ customHeader }}
      </div>
    </div>
    <swiper
      class="content-swiper-slideshow__images content-swiper-slideshow__images-{{
        elementId
      }}"
      [style.max-width]="cssWidth"
      [ngClass]="align"
    >
      <ng-container *ngFor="let slide of attachments; let j = index">
        <ng-template swiperSlide>
          <div
            class="image-wrapper"
            *ngIf="slide?.url || slide?.icon"
            [ngClass]="align"
          >
            <j3-image
              [imgSrc]="slide.url ?? slide.icon | ngrxPush"
              [sourceSize]="picturesSizesList(imageSize)"
              [title]="slide?.altText || slide?.name"
              [alt]="slide?.altText || slide?.name"
              [style.max-height]="cssHeight"
              ngClass="swiper-lazy swiper-image align"
            ></j3-image>
            <div class="swiper-lazy-preloader"></div>
          </div>
        </ng-template>
      </ng-container>
    </swiper>

    <swiper
      class="content-swiper-slideshow__captions content-swiper-slideshow__captions-{{
        elementId
      }}"
      #sliderCaptionsEl
      *ngIf="slideshowCaptionsList.length > 0"
      [style.max-width]="cssWidth"
      [ngClass]="align"
    >
      <ng-container
        *ngFor="let caption of slideshowCaptionsList; let i = index"
      >
        <ng-template swiperSlide>
          <h3
            class="content-swiper-slideshow__caption--title"
            *ngIf="caption.title"
            [ngClass]="align"
          >
            {{ caption.title | translate }}
          </h3>
          <p
            class="content-swiper-slideshow__caption--text"
            *ngIf="caption.text"
            [ngClass]="align"
          >
            {{ caption.text | translate }}
          </p>
        </ng-template>
      </ng-container>
    </swiper>
    <div class="content-swiper-slideshow__nav">
      <ng-container
        *ngIf="
          this.showArrows &&
          (slideshowCaptionsList?.length > 1 || attachments?.length > 1)
        "
      >
        <button
          class="content-swiper-slideshow__navigation content-swiper-slideshow__navigation--prev icon-chevron-left"
          (click)="prevSlide()"
          type="button"
        ></button>
        <button
          class="content-swiper-slideshow__navigation content-swiper-slideshow__navigation--next icon-chevron-right"
          (click)="nextSlide()"
          type="button"
        ></button>
      </ng-container>
      <div
        class="swiper-pagination content-swiper-slideshow__pagination"
        [id]="'content-swiper-slideshow__pagination-' + elementId"
        [ngClass]="showPoints ? '' : 'd-none'"
      ></div>
    </div>
  </div>
  <div
    class="p-3 w-100 h-100 text-center bg-secondary text-white ng-star-inserted"
    *ngIf="designMode && attachments?.length === 0"
  >
    No images selected.
  </div>
</ng-template>
