import { Injectable } from '@angular/core';
import { ApiService } from '@jotter3/wa-core';

import { GeneralSettingsDomainModel } from '../domain-models';
import { BaserDomainService } from './base.domain-service';

@Injectable({ providedIn: 'root' })
export class GeneralSettingsDomainService extends BaserDomainService<GeneralSettingsDomainModel> {
  constructor(apiService: ApiService) {
    super('tenant/settings', apiService);
  }
}
