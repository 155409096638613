import {
  DOCUMENT,
  isPlatformServer,
} from '@angular/common';
import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';

@Injectable()
export class ToggleContentService {
  private renderer: Renderer2;
  constructor(
    @Optional() @Inject(DOCUMENT) readonly document: any,
    @Inject(PLATFORM_ID) private platformId: any,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public toggleContent(status: boolean, id: string): string {
    if (isPlatformServer(this.platformId)) {
      return '';
    }

    const elHeader = this.document.getElementById(`element-header-${id}`);

    if (!elHeader) {
      return null;
    }

    const siteContent = elHeader.closest('.site-content');
    const sectionRow = elHeader.closest('.site-content-section-row');
    const elContentPresenter = elHeader.closest('.site-content-section-column-items-content-presenter');
    const column = elHeader.closest('.site-content-section-column');
    const elHeaderDomRect = elHeader.getBoundingClientRect();
    const siteDomRect = sectionRow.getBoundingClientRect();

    if (status) {
      this.renderer.addClass(siteContent, 'index-auto');
      this.renderer.addClass(elContentPresenter, 'position-static');
      this.renderer.addClass(column, 'position-static');
    } else {
      this.renderer.removeClass(siteContent, 'index-auto');
      this.renderer.removeClass(elContentPresenter, 'position-static');
      this.renderer.removeClass(column, 'position-static');
    }

    return `${sectionRow.offsetHeight - (elHeaderDomRect.top - siteDomRect.top) - 3}px`;
  }

  public toggleOverflow(status: boolean, id: string): void {
    const elHeader = this.document.getElementById(`element-wrapper-${id}`);

    if (!elHeader) {
      return null;
    }

    if (status) {
      this.renderer.removeClass(elHeader, 'overflow-auto');
      this.renderer.addClass(elHeader, 'overflow-hidden');
    } else {
      this.renderer.removeClass(elHeader, 'overflow-hidden');
      this.renderer.addClass(elHeader, 'overflow-auto');
    }
  }
}
