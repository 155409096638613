import {
  CommonModule,
  NgTemplateOutlet,
} from '@angular/common';
import {
  Component,
  inject,
} from '@angular/core';
import {
  NgbModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AppToastService } from '../../services';


@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-toasts',
  imports: [
    NgbModule,
    NgbToastModule,
    NgTemplateOutlet,
    CommonModule,
    TranslateModule,
  ],
  template: `
    <ng-container *ngFor="let toast of toastService.toasts">
      <ngb-toast
        [autohide]="toast.delay<=0"
        [delay]="toast.delay"
        [class]="toast.classname"
        (hidden)="toastService.remove(toast.id)"
        (click)="toastService.remove(toast.id)"
      >
        <h6 class='font-weight-bold'>{{ toast.title | translate }}</h6>
        <p *ngIf="toast.description">{{ toast.description | translate }}</p>
      </ngb-toast>
    </ng-container>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'toast-container position-fixed top-0 end-0 p-3',
    style: 'z-index: 1200; cursor: pointer',
  },
})
export class AppToastComponent {
  toastService = inject(AppToastService);
}
