import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SiteSlideshowsEffects } from './site-slideshows.effects';
import { siteSlideshowsStoreFeature } from './site-slideshows.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(siteSlideshowsStoreFeature),
    EffectsModule.forFeature([SiteSlideshowsEffects]),
  ],
})
export class SiteSlideshowsStoreModule {

}
