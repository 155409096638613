import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DirectoriesEffects } from './directories.effects';
import { templatesStoreFeature } from './directories.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(templatesStoreFeature),
    EffectsModule.forFeature([DirectoriesEffects]),
  ],
})
export class DirectoriesStoreModule {

}
