import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconComponent } from './icon.component';
import { IconRegistryService } from './icon-registry.service';

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule],
  providers: [IconRegistryService],
  exports: [IconComponent],
})
export class IconsModule {}
