import { OrderBy } from '@jotter3/common-helpers';
import { DropdownItemModel } from '@jotter3/sites-abstract';
import { dataQuery } from '@jotter3/wa-core';

import { LayoutType } from '../../enums';

export const DEFAULTS = {
  PAGE_SIZE: 5,
  HEADER: 'News',
  VISIBLE_SLIDES: 2,
  LAYOUT_TYPE: LayoutType.NORMAL,
  SHOW_IMAGES: true,
  SHOW_INDICATORS: false,
  SHOW_ARROWS: false,
  ANIMATION_DELAY: 5000,
};

export enum STORY_STYLE {
  STYLE_NORMAL = 1,
  STYLED_ASIDE = 2
}

export const STORY_STYLE_OPTIONS: Array<DropdownItemModel<STORY_STYLE, string>> = [
  {
    label: 'Standard',
    value: STORY_STYLE.STYLE_NORMAL,
    icon: 'style_normal',
  },
  {
    label: 'With stylised date',
    value: STORY_STYLE.STYLED_ASIDE,
    icon: 'style_aside',
  },
];

export const LAYOUT_TYPE_OPTIONS: Array<DropdownItemModel<LayoutType, string>> = [
  {
    label: 'Normal',
    value: LayoutType.NORMAL,
  },
  {
    label: 'Carousel',
    value: LayoutType.CAROUSEL,
  },
];

export const TRANSITION_TIME_OPTIONS: Array<DropdownItemModel<number, string>> = [
  {
    label: '1s',
    value: 1000,
  },
  {
    label: '2s',
    value: 2000,
  },
  {
    label: '3s',
    value: 3000,
  },
  {
    label: '4s',
    value: 4000,
  },
  {
    label: '5s',
    value: 5000,
  },
  {
    label: '6s',
    value: 6000,
  },
  {
    label: '7s',
    value: 7000,
  },
  {
    label: '8s',
    value: 8000,
  },
  {
    label: '9s',
    value: 9000,
  },
  {
    label: '10s',
    value: 10000,
  },
];

export const createSlidesDisplayDropdownSource = (numberOfElements: number): Array<DropdownItemModel<number, number>> => {
  const result: Array<DropdownItemModel<number, number>> = new Array<DropdownItemModel<number, number>>();
  for (let i = 1; i <= numberOfElements;i++) {
    result.push({
      value: i,
      label: i,
    });
  }

  return result;
};

export const createConstFilters = (): dataQuery.Filter[] =>
  [
    {
      type: dataQuery.FilterType.DATE,
      operator: dataQuery.Operator.LTE as any,
      property: 'publishDate',
      value: new Date().toISOString(),
    },
    {
      type: dataQuery.FilterType.SEARCH,
      operator: dataQuery.Operator.EQ as any,
      property: 'draftState',
      value: 1,
    },
    {
      type: dataQuery.FilterType.SEARCH,
      operator: dataQuery.Operator.EQ as any,
      property: 'availability',
      value: 0,
    },
  ];

export const NEWS_ORDER_DROPDOWN_SOURCE: Record<OrderBy, dataQuery.SingleOrder> = {
  [OrderBy.NAME_ASC]: {
    key: 'title',
    direction: dataQuery.Direction.ASC,
  },
  [OrderBy.NAME_DESC]: {
    key: 'title',
    direction: dataQuery.Direction.DESC,
  },
  [OrderBy.DATE_ASC]: {
    key: 'publishDate',
    direction: dataQuery.Direction.ASC,
  },
  [OrderBy.DATE_DESC]: {
    key: 'publishDate',
    direction: dataQuery.Direction.DESC,
  },
};
