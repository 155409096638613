import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
  Type,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonComponentsModule } from '@jotter3/common-components';
import { J3TranslateService } from '@jotter3/common-helpers';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  LetModule,
  PushModule,
} from '@ngrx/component';
import { NguCarouselModule } from '@ngu/carousel';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

import { templateComponents } from './components';
import { TemplateDataProvider } from './providers';
import { ToggleItemsService } from './services/toggle-items.service';

@NgModule({
  declarations: [...templateComponents],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild(),
    NguCarouselModule,
    SwiperModule,
    NgSelectModule,
    CommonComponentsModule,
    PushModule,
    LetModule,
    CommonComponentsModule,
  ],
  exports: [...templateComponents],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TemplateComponentsModule {
  static forRoot(dataProviderType: Type<TemplateDataProvider>): ModuleWithProviders<TemplateComponentsModule> {
    return {
      ngModule: TemplateComponentsModule,
      providers: [
        { provide: ToggleItemsService },
        {
          provide: TemplateDataProvider,
          useClass: dataProviderType,
        },
        {
          provide: TranslateService,
          useExisting: J3TranslateService,
        },
      ],
    };
  }
}
