import { HttpHeaders } from '@angular/common/http';
import {
  inject,
  Injectable,
} from '@angular/core';
import { ApiService } from '@jotter3/wa-core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  of,
  switchMap,
} from 'rxjs';
import { urlJoin } from 'url-join-ts';

import { ModuleOptionsInjectionToken } from '../../common';
import {
  SiteDomainModel,
  SitesSettingsDomainModel,
} from '../../domain-models';
import { SiteStoreActions } from './site.actions';
import { siteStoreSelectors } from './site.selectors';

@Injectable()
export class SiteEffects {
  readonly #store: Store = inject(Store);
  readonly #actions$ = inject(Actions);
  readonly #apiService: ApiService = inject(ApiService);
  readonly #environments = inject(ModuleOptionsInjectionToken);

  loadSiteRequested$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(SiteStoreActions.loadTrigger),
      switchMap(({ domain }) =>
        this.#apiService.loadSingle<SiteDomainModel>(urlJoin('sites', 'domain', domain), undefined, { headers: new HttpHeaders({ XTenantIgnore: 'true' }) }).pipe(
          map((response) => response.success
            ? SiteStoreActions.loadSuccess({ entity: response.result })
            : SiteStoreActions.loadFailed({ errors: [response.error] }))
        )),
      catchError(err => of(SiteStoreActions.loadFailed({ errors: [err] })))
    ));

  updateSiteRequested$ = createEffect(() => this.#actions$.pipe(
    ofType(SiteStoreActions.updateTrigger),
    concatLatestFrom(() => this.#store.select(siteStoreSelectors.selectSiteId)),
    switchMap(([
      { entity },
      siteId,
    ]) => this.#apiService.save<SitesSettingsDomainModel>(urlJoin('sites'), {
      id: siteId,
      ...entity,
    })),
    map(response => SiteStoreActions.updateSuccess({ entity: response.result })),
    catchError(err => of(SiteStoreActions.updateFailed({ errors: [err] })))
  ));
}
