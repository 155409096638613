import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SiteSettingsEffects } from './site-settings.effects';
import { siteSettingsStoreFeature } from './site-settings.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(siteSettingsStoreFeature),
    EffectsModule.forFeature([SiteSettingsEffects]),
  ],
})
export class SiteSettingsStoreModule {

}
