import {
  Directive,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import {
  domainModels,
  myGroupsListState,
  permissionsState,
} from '@jotter3/api-connector';
import {
  AppType,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import { reflectMetadata } from '@jotter3/reflection-core';
import {
  constant,
  SiteBaseComponent,
  SiteComponentMetadata,
} from '@jotter3/sites-abstract';
import { Store } from '@ngrx/store';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { isNil } from 'lodash-es';
import { combineLatest } from 'rxjs';
import {
  filter,
  switchMap,
  take,
} from 'rxjs/operators';

import { CMS_CONTENT_COMPONENTS } from '../common';
import { ContentItemModel } from '../models/content-builder.model';

/**
 * Example usage:
 *
 * <div *AudienceTargeting="
 *  {
 *      groups: [array with selected groups],
 *      component: component to get roles from component settings,
 *      messageRef: ng-template with message when content not available
 *  }"></div>
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[audienceTargeting]' })
export class AudienceTargetingDirective extends OnDestroyMixin implements OnInit {
  private groups: string[];
  private availablePermissions: string[];
  private userPermissions: domainModels.PermissionDomainModel[];
  private userGroups: domainModels.GroupDomainModel[];
  private messageRef: TemplateRef<any>;
  private elementBlocked = false;
  private readonly componentsDef$: {
    metadata: SiteComponentMetadata,
    component: Type<SiteBaseComponent>,
  }[];
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store,
    @Inject(MODULE_PROVIDER_TOKEN) private moduleProvider: ModuleProvider,
    @Inject(CMS_CONTENT_COMPONENTS) private contentComponents: Type<SiteBaseComponent>[]
  ) {
    super();
    this.componentsDef$ = contentComponents.flat().map((x) => {
      const meta = reflectMetadata.getComponentMetadata<SiteComponentMetadata>(constant.COMPONENT_DESCRIPTION_META_KEY, x);
      return {
        component: x,
        metadata: meta && meta.length > 0 ? meta[0] : undefined,
      };
    });
  }

  @Input()
  public set audienceTargeting(value: { groups: string[], component: ContentItemModel, messageRef?: TemplateRef<any> }) {
    const roles = this.componentsDef$.find((x) => x.metadata.selector === value.component.selector)?.metadata?.roles;
    this.groups = value.groups || [];
    this.availablePermissions = roles ? roles.map((item) => item.toUpperCase()) : [];
    this.messageRef = value.messageRef;
  }

  public ngOnInit(): void {
    if (this.moduleProvider.applicationType === AppType.CLIENT) {
      this.updateView();
    }

    combineLatest([
      this.store.select(permissionsState.permissionSelectors.arePermissionsLoaded).pipe(
        filter((loaded) => loaded),
        switchMap(() => this.store.select(permissionsState.permissionSelectors.permissionsSelector)),
        untilComponentDestroyed(this),
        take(1)
      ),
      this.store.select(myGroupsListState.myGroupsSelectors.areMyGroupsListLoaded).pipe(
        filter((loaded) => loaded),
        switchMap(() => this.store.select(myGroupsListState.myGroupsSelectors.myGroupsListSelector)),
        untilComponentDestroyed(this),
        take(1)
      ),
    ])
      .pipe(take(1))
      .subscribe((res) => {
        this.userPermissions = res[0];
        this.userGroups = res[1];
        this.updateView();
      });
  }

  private updateView(): void {
    if (this.checkPermissions()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    if (this.messageRef && this.elementBlocked) {
      this.viewContainer.createEmbeddedView(this.messageRef);
    }
  }

  private checkPermissions(): boolean {
    if (this.moduleProvider.applicationType === AppType.CLIENT) {
      this.elementBlocked = !!this.groups.length;
      return !this.groups.length;
    }

    if (isNil(this.groups) || !this.groups.length || this.userGroups.some((item) => this.groups.includes(item.id))) {
      this.elementBlocked = false;
      return true;
    }
    this.elementBlocked = !this.userPermissions.some((userPermission) =>
      this.availablePermissions.includes(userPermission.value));
    return this.userPermissions.some((userPermission) => this.availablePermissions.includes(userPermission.value));
  }
}
