import { AccessibilityComponent } from './accessibility/accessibility.component';
import { AwardsComponent } from './awards/awards-component.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FaxComponent } from './fax/fax-component';
import { FooterComponent } from './footer/footer-component';
import { HeadTeacherComponent } from './headteacher/headteacher.component';
import { ImageElementComponent } from './image-element/image-element.component';
import { MailComponent } from './mail/mail.component';
import { MapTemplateComponent } from './map/map.component';
import { MenuComponent } from './menu/menu.component';
import { MottoComponent } from './motto/motto.component';
import { PhoneComponent } from './phone/phone-component';
import { SchoolAddressComponent } from './school-address/school-address.component';
import { SchoolLogoComponent } from './school-logo/school-logo.component';
import { SchoolNameComponent } from './school-name/school-name.component';
import { ScrollToComponent } from './scroll-to/scroll-to.components';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { TextBlockElementComponent } from './text-block/text-block.component';
import { TranslateComponent } from './translate/translate-component';

const templateComponents = [
  AwardsComponent,
  FaxComponent,
  FooterComponent,
  HeadTeacherComponent,
  MailComponent,
  MenuComponent,
  MottoComponent,
  PhoneComponent,
  SchoolAddressComponent,
  SchoolLogoComponent,
  SchoolNameComponent,
  ScrollToComponent,
  SlideshowComponent,
  TextBlockElementComponent,
  ImageElementComponent,
  MapTemplateComponent,
  SearchBoxComponent,
  SocialMediaComponent,
  TranslateComponent,
  BreadcrumbsComponent,
  AccessibilityComponent,
];

export { AccessibilityComponent,
  AwardsComponent,
  BreadcrumbsComponent,
  FaxComponent,
  FooterComponent,
  HeadTeacherComponent,
  ImageElementComponent,
  MailComponent,
  MapTemplateComponent,
  MenuComponent,
  MottoComponent,
  PhoneComponent,
  SchoolAddressComponent,
  SchoolLogoComponent,
  SchoolNameComponent,
  ScrollToComponent,
  SearchBoxComponent,
  SlideshowComponent,
  SocialMediaComponent,
  templateComponents,
  TextBlockElementComponent,
  TranslateComponent };
