import {
  Injectable,
  Type,
} from '@angular/core';
import { TemplateBaseComponent } from '@jotter3/sites-abstract';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToggleItemsService {
  private toggleE = new Subject<Type<TemplateBaseComponent>>();
  public toggleEvent = this.toggleE.asObservable();

  public onToggle(type: Type<TemplateBaseComponent>): void {
    this.toggleE.next(type);
  }
}
