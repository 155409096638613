import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import * as siteSearchReducer from '../reducers/site-search.reducers';

const siteSearchSelector = createFeatureSelector<siteSearchReducer.SiteSearchState>('siteSearch');

export const siteSearchPhraseSelector = createSelector(siteSearchSelector, (state) => state?.searchPhrase);

export const siteSearchIsLoadingSelector = createSelector(siteSearchSelector, (state) => state?.isLoading);

export const siteSearchResultSelector = createSelector(siteSearchSelector, (state) => state?.result);
