<div class="footer-wrapper d-flex justify-content-center flex-wrap">
  <div class="site-footer-data--copyrights">
    <span>{{ 'Copyright' | translate }} &copy;</span>
    <span>
      <ng-container *ngIf="$schoolName">
        {{ $schoolName | async }}
      </ng-container>
      <ng-container *ngIf="currentDate"> {{ currentDate }}. </ng-container>
    </span>
    <span>
      {{ 'Our website is built using' | translate }}
      <a
        class="link-to-schooljotter"
        href="https://www.schooljotter.com/"
        target="_blank"
        >School Jotter 3</a
      >, {{ 'from' | translate }} Webanywhere.&nbsp;</span
    >
  </div>
  <div class="site-footer-data--admin-panel">
    <a href="/admin/auth/login">{{ '[Go to admin panel]' | translate }}</a>
  </div>
  <a class="logout-link" id="logout-link" *ngIf="isLogged">Logout</a>
</div>
