import {
  inject,
  Injectable,
} from '@angular/core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import {
  distinctUntilChanged,
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  switchMap,
} from 'rxjs/operators';

import {
  PagesContentCollectionService,
  PagesContentUpdateCollectionService,
} from '../../../collection-services';
import { PagesContentListDomainModel } from '../../../domain-models';
import { pagesSelectors } from '../../pageslist';
import { pageContentActions } from '../actions';

@Injectable()
export class PageContentEffects {
  readonly #store = inject(Store);
  readonly #pagesContentCollectionService: PagesContentCollectionService = inject(PagesContentCollectionService);
  readonly #pagesContentUpdateCollectionService: PagesContentUpdateCollectionService = inject(PagesContentUpdateCollectionService);

  LoadPageContent = createEffect(() =>
    this.actions$.pipe(
      ofType(pageContentActions.LoadPageContent),
      distinctUntilChanged(),
      concatLatestFrom(() => this.#store.select(pagesSelectors.pagesListSelector)),
      switchMap(([
        action,
        pages,
      ]) => {
        const { url, revisionId } = action;

        const currentPage = pages.find((page) => isNil(url) || !url.trim().length ? page.homePage : page.url.toLowerCase() === url.toLowerCase());

        if (!currentPage) {
          return of(pageContentActions.LoadPageContentFailed({
            error: {
              status: 404,
              message: 'Page not found',
              ok: false,
            } as any,
          }));
        }

        return this.createApiRequest(currentPage?.id, revisionId).pipe(
          switchMap((response) => of(pageContentActions.LoadPageContentComplete({
            page: currentPage,
            pageContent: response.result,
            pageUrl: url,
          }))),
          catchError((error) => of(pageContentActions.LoadPageContentFailed({ error })))
        );
      })
    ));

  constructor(private actions$: Actions) {}

  private createApiRequest(id: string, revisionId?: string): Observable<PagesContentListDomainModel> {
    return revisionId
      ? this.#pagesContentUpdateCollectionService.getContentByContentId(revisionId)
      : this.#pagesContentCollectionService.getContentByPageId(id);
  }
}
