import {
  inject,
  Injectable,
} from '@angular/core';
import {
  ApiResponse,
  dataQuery,
  LHSBracketsDataQueryAdapter,
} from '@jotter3/wa-core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  distinctUntilChanged,
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';

import { siteStoreSelectors } from '../../../+state';
import { PageDomainModel } from '../../../domain-models';
import { PagesListDomainService } from '../../../domain-services';
import { pagesListActions } from '../actions';

@Injectable()
export class PagesListEffects {
  readonly #actions$: Actions = inject(Actions);
  readonly #store: Store = inject(Store);
  readonly #pagesListDomainService: PagesListDomainService = inject(PagesListDomainService);
  readonly #lhsBracketsDataQueryAdapter: LHSBracketsDataQueryAdapter = inject(LHSBracketsDataQueryAdapter);

  LoadPagesList = createEffect(() =>
    this.#actions$.pipe(
      ofType(pagesListActions.LoadPagesList),
      switchMap((action) => this.#store.select(siteStoreSelectors.selectSiteId).pipe(
        filter(siteId => !!siteId),
        distinctUntilChanged(),
        map(siteId => ([
          action,
          siteId,
        ] as const
        ))
      )),
      switchMap(([
        { limit },
        siteId,
      ]) => {
        return this.createApiRequest(siteId, limit).pipe(
          switchMap((response) => {
            const { pagination } = response;
            const observableApiCalls: Observable<ApiResponse<PageDomainModel[]>>[] = [of(response)];

            if (pagination.totalPages > 1) {
              for (let page = 2; page <= pagination.totalPages; page++) {
                observableApiCalls.push(this.createApiRequest(siteId, limit, page));
              }
            }

            return combineLatest(observableApiCalls).pipe(
              switchMap((responseArray) => {
                let pages = responseArray
                  .map((x) => x.result)
                  .flat()
                  .filter((x) => x.parentId !== x.id);
                pages = pages.map((page) => {
                  const urlSegments = this.getUrlForPage(page, pages).reverse();
                  return {
                    ...page,
                    url: urlSegments.join('/'),
                    urlSegments,
                  };
                });

                return of(pagesListActions.LoadPagesListComplete({ pagesList: pages }));
              }),
              catchError((error) => of(pagesListActions.LoadPagesListFailed({ error })))
            );
          }),
          catchError((error) => of(pagesListActions.LoadPagesListFailed({ error })))
        );
      })
    ));

  private createApiRequest(siteId: string, size: number = 1000, page: number = 1): Observable<ApiResponse<PageDomainModel[]>> {
    const queryParams = this.#lhsBracketsDataQueryAdapter.toQueryParam({
      filters: [
        {
          property: 'site',
          operator: dataQuery.Operator.EQ,
          type: dataQuery.FilterType.SEARCH,
          value: siteId,
        },
      ],
      pagination: {
        size,
        page,
      },
      order: [
        {
          direction: dataQuery.Direction.ASC,
          key: 'lp',
        },
      ],
    });

    return this.#pagesListDomainService.getAll(queryParams);
  }

  private getUrlForPage(page: PageDomainModel, source: PageDomainModel[]): string[] {
    if (!page) {
      return [''];
    }

    const { parentId, name } = page;
    let urlSegments: string[] = [name];

    if (parentId) {
      urlSegments = [
        ...urlSegments,
        this.getUrlForPage(
          source.find((x) => x.id === parentId),
          source
        ),
      ].flat();
    }

    return urlSegments;
  }
}
