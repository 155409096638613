import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  switchMap,
} from 'rxjs/operators';

import { SitesCollectionService } from '../../../collection-services';
import {
  SiteSearchClearState,
  SiteSearchPhraseChange,
  SiteSearchResultChangeError,
  SiteSearchResultChangeSuccess,
} from '../actions';

@Injectable()
export class SiteSearchEffects {
  searchPhraseChanged$ = createEffect(() =>
    this.actions.pipe(
      ofType(SiteSearchPhraseChange),
      switchMap(({ phrase }) =>
        phrase?.trim().length
          ? this.sitesCollectionService.invokeSearch(phrase).pipe(
            switchMap(({ result }) => of(SiteSearchResultChangeSuccess({ result }))),
            catchError((error) => of(SiteSearchResultChangeError({ error })))
          )
          : of(SiteSearchClearState()))
    ));

  constructor(private actions: Actions, private sitesCollectionService: SitesCollectionService) {}
}
