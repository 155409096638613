import { DatepickerFormFieldComponent } from './datepicker/datepicker.field';
import { TimePickerFieldComponent } from './time-picker/time-picker.field';

const commonFields = [
  {
    name: 'datepicker',
    component: DatepickerFormFieldComponent,
  },
  {
    name: 'timepicker',
    component: TimePickerFieldComponent,
  },
];

const commonFormFields = [
  DatepickerFormFieldComponent,
  TimePickerFieldComponent,
];

export { commonFields,
  commonFormFields,
  DatepickerFormFieldComponent,
  TimePickerFieldComponent };
