import {
  createAction,
  props,
} from '@ngrx/store';

import { MobileSettingsDomainModel } from '../../../domain-models';

const LoadMobileSettings = createAction('[Settings - Mobile] Load data');
const LoadMobileSettingsComplete = createAction(
  '[Settings - Mobile] Load data success',
  props<{ mobileSettings: Partial<MobileSettingsDomainModel> }>()
);
const LoadMobileSettingsFailed = createAction('[Settings - Mobile] Load data failed', props<{ error: any }>());
const UpdateMobileSettings = createAction(
  '[Settings - Mobile] Update',
  props<{ mobileSettings: Partial<MobileSettingsDomainModel> }>()
);
const UpdateMobileSettingsComplete = createAction(
  '[Settings - Mobile] Update data success',
  props<{ mobileSettings: Partial<MobileSettingsDomainModel> }>()
);
const UpdateMobileSettingsFailed = createAction('[Settings - Mobile] Update data failed', props<{ error: any }>());

export { LoadMobileSettings,
  LoadMobileSettingsComplete,
  LoadMobileSettingsFailed,
  UpdateMobileSettings,
  UpdateMobileSettingsComplete,
  UpdateMobileSettingsFailed };
