import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import { cloneDeep } from 'lodash-es';

@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return;
    }
    let transformedValue = cloneDeep(value);
    const regex = new RegExp(/(\*{3})(.*?)(\*{3})/);
    let match = transformedValue.match(regex);

    while (match) {
      transformedValue = transformedValue.replace(regex, `<span class='highlight'>${match[0].replace(/(\*\*\*)/g, '')}</span>`);
      match = transformedValue.match(regex);
    }

    return transformedValue;
  }
}
