import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { mobileSettingsReducers } from '../reducers';

export const selectSettingsState = createFeatureSelector<mobileSettingsReducers.MobileState>('mobile');

export const mobileSettingsSelector = createSelector(selectSettingsState, (state) => state.mobile);

export const areMobileSettingsLoading = createSelector(mobileSettingsSelector, (state) => state.isLoading);

export const areMobileSettingsLoaded = createSelector(mobileSettingsSelector, (state) => state.isLoaded);
