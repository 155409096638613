<ng-container [ngSwitch]="elTemplate">
  <ng-container *ngSwitchCase="1">
    <div class="single-event__content single-event__content--template-1">
      <div class="single-event__top">
        <div class="single-event__date">
          <span class="calendar-date-day">{{ event.start | date : 'd' }}</span
          >&nbsp;<span class="calendar-date-month">{{
            event.start | date : 'MMM'
          }}</span>
          <span *ngIf="event.showEndDate"> &nbsp;-&nbsp; </span>
          <span class="calendar-date-day" *ngIf="event.showEndDate">{{
            event.end | date : 'd'
          }}</span
          ><ng-container *ngIf="event.showEndDate">&nbsp;</ng-container
          ><span class="calendar-date-month" *ngIf="event.showEndDate">{{
            event.end | date : 'MMM'
          }}</span>
        </div>
        <h5 class="single-event__header">
          {{ event.title }}
        </h5>
        <div class="single-event__date" *ngIf="event.allDay">
          <span class="calendar-hour"
            ><small>{{ 'All Day' | translate }}</small></span
          >
        </div>
        <div class="single-event__date" *ngIf="!event.allDay">
          <span class="calendar-hour"
            ><small>{{ event.start | date : 'h:mm a' }}</small></span
          >
          <span> &nbsp;-&nbsp; </span>
          <span class="calendar-hour"
            ><small>{{ event.end | date : 'h:mm a' }}</small></span
          >
        </div>
      </div>
      <div
        class="single-event__description"
        *ngIf="event.extendedProps.description"
        [innerHTML]="event.extendedProps.description"
      ></div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="2">
    <div class="single-event__content single-event__content--template-2">
      <div class="single-event__left">
        <div class="single-event__date--day">
          {{ event.start | date : 'dd' }}
        </div>
        <div class="single-event__date--month">
          {{ event.start | date : 'MMM' }}
        </div>
        <div class="single-event__date--year">
          {{ event.start | date : 'yyyy' }}
        </div>
      </div>
      <div class="single-event__right">
        <div class="single-event__top">
          <h5 class="single-event__header">
            {{ event.title }}
          </h5>
          <div class="single-event__date--time" *ngIf="event.allDay">
            {{ 'All Day' | translate }}
          </div>
          <div class="single-event__date--time" *ngIf="!event.allDay">
            Start time: {{ event.start | date : 'hh:mm a' }}
          </div>
        </div>
        <div
          class="single-event__description"
          *ngIf="event.extendedProps.description"
          [innerHTML]="event.extendedProps.description"
        ></div>
      </div>
    </div>
  </ng-container>
</ng-container>
