import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { MyGroupsState } from '../reducers';

export const selectMyGroupsListState = createFeatureSelector<MyGroupsState>('myGroups');

export const myGroupsListStateSelector = createSelector(selectMyGroupsListState, (state) => state);

export const myGroupsListSelector = createSelector(myGroupsListStateSelector, (state) => {
  const entities = Object.values(state.entities);
  return entities;
});

export const areMyGroupsListLoading = createSelector(myGroupsListStateSelector, (state) => state.isLoading);

export const areMyGroupsListLoaded = createSelector(myGroupsListStateSelector, (state) => state.isLoaded);
