<div class="single-news__content">
  <div class="single-news__top d-flex justify-content-between">
    <div>
      <h5 class="single-news__header">
        {{ news?.title | translate }}
      </h5>
      <div class="single-news__date">
        {{ news?.publishDate | date : 'd/MM/yyyy h:mm aaa' }}
      </div>
    </div>
    <i class="material-icons" (click)="onCloseDialog()">close</i>
  </div>
  <ng-container *ngIf="news?.headlineImage">
    <div class="single-news__image-container">
      <j3-image
        class="single-news__image"
        [imgSrc]="news?.headlineImage"
        sourceSize="s"
        title="{{ news.title }}"
        alt="{{ news.title }}"
        loading="lazy"
      >
      </j3-image>
    </div>
  </ng-container>
  <div class="single-news__description">
    <j3-text-editor [(content)]="news.story" [disabled]="true"></j3-text-editor>
  </div>
  <div class="single-news__attachments" *ngIf="news?.attachments.length">
    <div
      class="single-news__attachment-wrapper"
      *ngFor="let attachment of news?.attachments || []"
    >
      <div
        class="single-news__attachment"
        (click)="handleOnDownload(attachment)"
      >
        <i class="action-ico-button fas fa-download mx-2" title="Download"></i>
        <p>{{ attachment.name }}</p>
      </div>
    </div>
  </div>
  <div class="p-3 pt-0">
    <button class="btn btn-primary" (click)="onCloseDialog()">OK</button>
  </div>
</div>
