<div
  class="content-element content-element-scrolling-text scrolling-text-container"
  [ngClass]="cssClass"
  [ngStyle]="{
    height: designMode ? 'auto' : scrollingTextContainerHeight + 'px'
  }"
>
  <ng-container *ngIf="!designMode; else standard">
    <j3-text-editor
      [@.disabled]="platformServer"
      [@scrollRightLeft]="{
        value: animState,
        params: { speed: scrollingSpeedVal }
      }"
      [content]="html"
      [disabled]="!designMode"
      [ngClass]="{ 'scrolling-text-content': !designMode }"
      [id]="'scrolling-text-content-' + uuidValue"
      [inlineMode]="true"
      [editorFonts]="getThemeFontsData() | ngrxPush"
      (@scrollRightLeft.done)="triggerAnim($event)"
    ></j3-text-editor>
  </ng-container>

  <ng-template #standard>
    <mat-progress-spinner
      class="editor-loader"
      *ngIf="tinyMCELoading$ | ngrxPush"
      mode="indeterminate"
    />
    <p
      class="text-center text-gray-700"
      *ngIf="
        designMode && (editorDisabled$ | ngrxPush) && !html?.trim()?.length
      "
    >
      Please enter some text
    </p>
    <j3-text-editor
      [@.disabled]="platformServer"
      [(content)]="html"
      [disabled]="editorDisabled$ | ngrxPush"
      [id]="'scrolling-text-content-' + uuidValue"
      [inlineMode]="true"
      [editorFonts]="getThemeFontsData() | ngrxPush"
      (editorInitialized)="handleEditorInitialized()"
    ></j3-text-editor>
  </ng-template>
</div>
