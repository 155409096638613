import { ComponentStore } from '@ngrx/component-store';
import { map } from 'rxjs';

import { LoadingStateEnum } from '../enums';
import { StoreBaseModel } from '../models';

export abstract class ComponentStoreAbstract<TState extends StoreBaseModel> extends ComponentStore<TState> {
  public readonly selectLoadingState = this.select(state => state.loadingState);
  public readonly selectIsLoading = this.selectLoadingState.pipe(map(loadingState => loadingState === LoadingStateEnum.LOADING));
}
