import { Injectable } from '@angular/core';
import {
  ApiResponse,
  LHSBracketsDataQueryAdapter,
} from '@jotter3/wa-core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  combineLatest,
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  switchMap,
} from 'rxjs/operators';

import { GroupTypeDomainModel } from '../../../domain-models';
import { GroupTypesDomainService } from '../../../domain-services';
import { groupTypesActions } from '../actions';

@Injectable()
export class GroupTypesListEffects {
  LoadGroupTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(groupTypesActions.GroupTypesList),
      switchMap((action) => {
        const { limit } = action;
        return this.createApiRequest(limit, 1).pipe(
          switchMap((response) => {
            const { pagination } = response;
            const observableApiCalls: Observable<ApiResponse<GroupTypeDomainModel[]>>[] = [of(response)];

            if (pagination.totalPages > 1) {
              for (let page = 2; page <= pagination.totalPages; page++) {
                observableApiCalls.push(this.createApiRequest(limit, page));
              }
            }

            return combineLatest(observableApiCalls).pipe(
              switchMap((responseArray) =>
                of(
                  groupTypesActions.GroupTypesListComplete({
                    groupTypesList: responseArray.map((x) => x.result).flat(),
                  })
                )),
              catchError((error) => of(groupTypesActions.GroupTypesListFailed({ error })))
            );
          }),
          catchError((error) => of(groupTypesActions.GroupTypesListFailed({ error })))
        );
      })
    ));

  constructor(
    private actions$: Actions,
    private lhsBracketsDataQueryAdapter: LHSBracketsDataQueryAdapter,
    private groupTypesDomainService: GroupTypesDomainService
  ) {}

  private createApiRequest(size: number = 100, page: number = 1): Observable<ApiResponse<GroupTypeDomainModel[]>> {
    const queryParams = this.lhsBracketsDataQueryAdapter.toQueryParam({
      pagination: {
        size,
        page,
      },
    });
    return this.groupTypesDomainService.getAll(queryParams);
  }
}
