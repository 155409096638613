import { isPlatformServer } from '@angular/common';
import {
  Inject,
  inject,
  Injectable,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import {
  makeStateKey,
  TransferState,
} from '@angular/platform-browser';
import {
  domainModels,
  TenantInfoDomainModel,
} from '@jotter3/api-connector';
import { isEqual } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import {
  BehaviorSubject,
  Observable,
} from 'rxjs';

import {
  J3_CORE_MODULE_PROVIDER_TOKEN,
  ModuleConfig,
  SSR_DOMAIN,
} from '../providers';

@Injectable({ providedIn: 'root' })
export class AppTenantInfoService {
  readonly #stateKey = makeStateKey<domainModels.TenantInfoDomainModel>('TENANT_DOMAIN');
  readonly #loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly #tenantDomain: string = inject(SSR_DOMAIN, { optional: true });
  #tenant: domainModels.TenantInfoDomainModel;

  constructor(
    @Inject(J3_CORE_MODULE_PROVIDER_TOKEN) private readonly moduleConfig: ModuleConfig,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    @Optional() private readonly transferState: TransferState,
    @Optional() private cookieService: CookieService
  ) {}

  public get tenant(): domainModels.TenantInfoDomainModel {
    return this.#tenant;
  }

  public set tenant(value: domainModels.TenantInfoDomainModel) {
    if (isEqual(this.#tenant, value)) {
      return;
    }

    if (isPlatformServer(this.platformId)) {
      this.transferState.set(this.#stateKey, value);
    }

    {
      this.#tenant = { ...value };
    }
    this.#loaded.next(true);
  }

  public get tenantId(): string {
    return this.#tenant?.id;
  }

  public get loaded(): Observable<boolean> {
    return this.#loaded;
  }

  public get domain(): string {
    const cookeKey = 'x-tenant-domain';

    const cookeValue = this.#tenantDomain ?? this.cookieService.get(cookeKey);

    let hostName = cookeValue?.length ? cookeValue : window.location.hostname;
    hostName = hostName === 'localhost' ? this.moduleConfig?.LOCAL_DOMAIN?.trim() : hostName?.trim();

    return hostName.replace(/(https?:\/\/)?(www.)?/i, '');
  }

  public loadFromState(): TenantInfoDomainModel {
    return this.transferState.get(this.#stateKey, undefined);
  }
}
