export const colorCalculator = (color: string): string | undefined => {
  if (!color) {
    return undefined;
  }
  const hex = color.match(/[0-9a-f]{2}/gi);

  const rgb = hex.map((item) => {
    const colorValue = parseInt(item, 16) / 255.0;

    if (colorValue <= 0.03928) {
      return colorValue / 12.92;
    } else {
      return Math.pow((colorValue + 0.055) / 1.055, 2.4);
    }
  });

  const luminance = 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];

  return (luminance + 0.05) / (0.0 + 0.05) > (1.0 + 0.05) / (luminance + 0.05) ? '#000' : '#fff';
};
