import '@angular/localize/init';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_VERSION } from './version';

Sentry.init({
  dsn: environment.sentryDSN,
  release: `j3-client@${APP_VERSION}`,
  environment: environment.sentryEnvironment,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ['*'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: environment.sentryTracesSampleRate ?? 0,
});

function bootstrap(): void {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

if (document.readyState !== 'loading') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
