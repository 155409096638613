import {
  Directive,
  TemplateRef,
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[cmsTemplateAdditionalActions]',
})
export class TemplateAdditionalActionsDirective {
  constructor(public tpl: TemplateRef<any>) {}
}
