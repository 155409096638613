import {
  Component,
  OnInit,
} from '@angular/core';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TemplateDataProvider } from '../../providers';

@TemplateComponent({
  selector: 'jotter-tplcmp-schoolname',
  displayName: 'School name',
  icon: 'settings',
  defaultClass: 'school-data-name',
})
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'jotter-tplcmp-schoolname',
  template: '{{$schoolName | async}}',
})
export class SchoolNameComponent extends TemplateBaseComponent implements OnInit {
  $schoolName: Observable<string | undefined> | undefined;

  constructor(private dataProvider: TemplateDataProvider) {
    super(SchoolNameComponent);
  }

  ngOnInit(): void {
    this.$schoolName = this.dataProvider.getData().pipe(map((res) => res.schoolName));
  }
}
