import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Injectable,
  Optional,
} from '@angular/core';
import { domainModels } from '@jotter3/api-connector';
import { StudybugsProvider } from '@jotter3/common-helpers';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { isNil } from 'lodash-es';

@Injectable()
export class StudybugsService extends OnDestroyMixin implements StudybugsProvider {
  constructor(
    @Optional() @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  public initStudyBugs(site: domainModels.SiteDomainModel): void {
    const studyBugsID = site.customContent[0]?.studybugsID;
    if (!isNil(studyBugsID)) {
      const studyBugs = this.document.createElement('script');
      studyBugs.setAttribute(
        'src',
        `//studybugs.com/school/${studyBugsID}/banner.js`
      );
      this.document.head.appendChild(studyBugs);
    }
  }


}
