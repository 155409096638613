import {
  createAction,
  props,
} from '@ngrx/store';

import { SearchResultDomainModel } from '../../../domain-models';

export const SiteSearchPhraseChange = createAction('[Site search] Phrase change', props<{ phrase: string }>());
export const SiteSearchResultChangeSuccess = createAction(
  '[Site search] Result change success',
  props<{ result: SearchResultDomainModel[] }>()
);
export const SiteSearchResultChangeError = createAction('[Site search] Result change error', props<{ error: any }>());
export const SiteSearchClearState = createAction('[Site search] Clear state');
