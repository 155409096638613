import {
  Injector,
  NgZone,
} from '@angular/core';
import {
  domainModels,
  NGB_MODAL_DATA,
} from '@jotter3/api-connector';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isNil } from 'lodash-es';
import { take } from 'rxjs/operators';
import * as tiny from 'tinymce';

import { FormAction } from '../../../../enums';
import { ImagePluginModel } from '../../../../models';
import { injectFn } from '../core-types';
import { ImageDialogComponent } from './image.dialog.component';

const dataDefAttribute = 'data-element-def';
const isImageFigure = (imgElm?: Element): boolean =>
  imgElm?.nodeName?.toLowerCase() === 'img' && imgElm.hasAttribute(dataDefAttribute);

const getImageElement = (editor: tiny.Editor, selectedElm?: Element): Element => {
  const element = selectedElm || editor.selection.getNode();
  if (isImageFigure(element)) {
    return selectedElm;
  }

  return editor.dom.getParent(selectedElm, 'img[src]');
};

const getImageAttributes = (data: ImagePluginModel): Record<string, string> => {
  const attr: Record<string, string> = {};

  const { file, description, ...dataToSerialize } = data;
  const fileSRC: domainModels.IFileDomainModel = (file as domainModels.IFileDomainModel);
  const imageSource: domainModels.FileSourceModel = fileSRC.icon || fileSRC.url;

  const selectedImageSize = dataToSerialize.imageSize ?? 'm';

  attr.src = imageSource?.webp ? (imageSource.webp as any)[selectedImageSize] : (imageSource as any)[selectedImageSize];
  attr.alt = description || '';
  attr.height = `${data.height || ''}`;
  attr.width = `${data.width || ''}`;
  attr[dataDefAttribute] = encodeURIComponent(JSON.stringify(dataToSerialize));

  return attr;
};

const updateAnchor = (editor: tiny.Editor, imageElement: Element, data: ImagePluginModel): void => {
  editor.dom.setAttribs(imageElement, getImageAttributes(data));
  editor.selection.select(imageElement);
};

const createImage = (editor: tiny.Editor, data: ImagePluginModel): void => {
  editor.insertContent(editor.dom.createHTML('img', getImageAttributes(data)));
};

const link = (editor: tiny.Editor, data: ImagePluginModel): void => {
  const selection = editor.selection.getNode();
  const imageElement = getImageElement(editor, selection);
  editor.undoManager.transact(() => {
    if (imageElement) {
      updateAnchor(editor, imageElement, data);
      return;
    }

    createImage(editor, data);
  });
};

const openDialog = (editor: tiny.Editor, inject: injectFn): void => {
  const imgElement: Element = getImageElement(editor, editor.selection.getNode());
  const ngZone: NgZone = inject(NgZone);

  let formModel: ImagePluginModel = {};

  if (imgElement?.hasAttribute(dataDefAttribute)) {
    try {
      formModel = JSON.parse(decodeURIComponent(imgElement.getAttribute(dataDefAttribute) || '{}'));
      formModel = {
        ...formModel,
        description: imgElement.getAttribute('alt'),
        height: imgElement.getAttribute('height'),
        width: imgElement.getAttribute('width'),
      };
    } catch (err) {
      console.warn(`ERR: cannot parse "${dataDefAttribute}" for given selection`);
    }
  }

  ngZone.run(() => {
    const dialogService = inject(NgbModal);
    const dialogRef = dialogService.open(ImageDialogComponent, {
      injector: Injector.create({
        providers: [
          {
            provide: NGB_MODAL_DATA,
            useValue: {
              formModel,
              showRemoveButton: false,
            },
          },
        ],
      }),
    });

    dialogRef.closed.pipe(take(1)).subscribe((result) => {
      if (!result || result.formAction === FormAction.CANCEL) {
        return;
      }

      const { model, formAction } = result;

      if (formAction === FormAction.SUBMIT) {
        link(editor, model);
        return;
      }
    });
  });
};


export const inspectCurrentContent = (element: HTMLElement, inject: injectFn): void => {
  const images: NodeListOf<HTMLImageElement> = element.querySelectorAll('img[data-element-def]');

  if (!images.length) {
    return;
  }

  images.forEach(image => {
    const formModel: ImagePluginModel = JSON.parse(decodeURIComponent(image.getAttribute(dataDefAttribute) || '{}'));
    if (isNil(formModel.imageSize)) {
      formModel.imageSize = 'm';
      image.setAttribute(dataDefAttribute, JSON.stringify(formModel));
      image.setAttribute('src', image.src.replace(/\d+x\d+/g, '800x800'));
    }
  });
};

export const initialize = (editor: tiny.Editor, inject: injectFn): void => {
  editor.ui.registry.addButton('j3Image', {
    icon: 'image',
    tooltip: 'Image...',
    onAction: () => openDialog(editor, inject),
  });
  editor.ui.registry.addMenuItem('j3Image', {
    icon: 'image',
    text: 'Image...',
    onAction: () => openDialog(editor, inject),
  });
  editor.ui.registry.addContextMenu('j3Image', {
    update: (element) => (isImageFigure(element) ? ['j3Image'] : []),
  });

  editor.on('SetContent', (ev) => {
    const { content, target } = ev;

    if (!content?.trim()) {
      return;
    }

    inspectCurrentContent((target as tiny.Editor).getBody(), inject);
  });

};
