import {
  inject,
  Injectable,
} from '@angular/core';
import {
  ApiResponse,
  ApiService,
  dataQuery,
  LHSBracketsDataQueryAdapter,
} from '@jotter3/wa-core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { urlJoin } from 'url-join-ts';

import { SlideshowDomainModel } from '../../domain-models';
import { siteStoreSelectors } from '../site';
import { SiteSlideshowsStoreActions } from './site-slideshows.actions';

@Injectable()
export class SiteSlideshowsEffects {
  readonly #actions$ = inject(Actions);
  readonly #store: Store = inject(Store);
  readonly #apiService: ApiService = inject(ApiService);
  readonly #dataQueryAdapter: LHSBracketsDataQueryAdapter = inject(LHSBracketsDataQueryAdapter);

  loadSiteSlideshowsRequested$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(SiteSlideshowsStoreActions.loadTrigger),
      switchMap(({ siteId }) => this.#loadSlideshowData(siteId)),
      switchMap(slideshows => {
        if (!slideshows.length) {
          return of({
            success: true,
            result: undefined,
            error: undefined,
          });
        }

        return this.#createLoadSingleApiRequest(slideshows[0].id);
      }),
      map((response) => response.success
        ? SiteSlideshowsStoreActions.loadSuccess({ entity: response.result })
        : SiteSlideshowsStoreActions.loadFailed({ errors: [response.error] }))
    ));

  updateSiteSlideshowsRequested = createEffect(() => this.#actions$.pipe(
    ofType(SiteSlideshowsStoreActions.updateTrigger),
    concatLatestFrom(() => this.#store.select(siteStoreSelectors.selectSiteId)),
    switchMap(([
      { entity },
      siteId,
    ]) => this.#apiService.save<SlideshowDomainModel>(urlJoin('slideshows'), {
      ...entity,
      site: siteId,
    })),
    map(response => SiteSlideshowsStoreActions.updateSuccess({ entity: response.result }))
  ));

  #loadSlideshowData(siteId: string): Observable<SlideshowDomainModel[]> {
    const queryParams: dataQuery.DataQuery = {
      filters: [
        {
          operator: dataQuery.Operator.EQ,
          property: 'site',
          type: dataQuery.FilterType.SEARCH,
          value: siteId,
        },
      ],
      pagination: {
        page: 1,
        size: 1,
      },
    };

    return this.#createLoadCollectionApiRequest(queryParams).pipe(
      switchMap((result) => {
        if (result?.length > 0) {
          return of(result);
        }

        return this.#createLoadCollectionApiRequest({
          ...queryParams,
          filters: [
            ...queryParams.filters,
            {
              operator: dataQuery.Operator.EMPTY,
              property: 'deletedStatus',
              type: dataQuery.FilterType.NUMERIC,
              value: 1,
            },
          ],
        });
      })
    );
  }

  #createLoadCollectionApiRequest(queryParams: dataQuery.DataQuery): Observable<SlideshowDomainModel[]> {
    return this.#apiService.load<SlideshowDomainModel>('slideshows', this.#dataQueryAdapter.toQueryParam(queryParams)).pipe(
      map(response => response?.result ?? [])
    );
  }

  #createLoadSingleApiRequest(id: string): Observable<ApiResponse<SlideshowDomainModel>> {
    return this.#apiService.loadSingle<SlideshowDomainModel>('slideshows', id);
  }
}
