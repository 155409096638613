import {
  inject,
  Injectable,
} from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  switchMap,
} from 'rxjs/operators';

import { PolicyGroupDomainModel } from '../../../domain-models';
import { PoliciesGroupsDomainService } from '../../../domain-services';
import { policiesGroupsActions } from '../actions';

@Injectable()
export class PoliciesGroupsEffects {
  readonly #policiesGroupsDomainService: PoliciesGroupsDomainService = inject(PoliciesGroupsDomainService);

  LoadPoliciesGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(policiesGroupsActions.LoadPoliciesGroups),
      switchMap(() => {
        return this.#policiesGroupsDomainService.getAll().pipe(
          switchMap((response) => {
            const observableApiCalls: Observable<PolicyGroupDomainModel[]> = of(response.result);
            return observableApiCalls.pipe(
              switchMap((responseArray) =>
                of(
                  policiesGroupsActions.LoadPoliciesGroupsComplete({
                    policiesGroups: responseArray.map((x) => x).flat(),
                  })
                )),
              catchError((error) => of(policiesGroupsActions.LoadPoliciesGroupsFailed({ error })))
            );
          }),
          catchError((error) => of(policiesGroupsActions.LoadPoliciesGroupsFailed({ error })))
        );
      })
    ));

  constructor(
    private actions$: Actions
  ) {}
}
