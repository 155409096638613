import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { MobileSettingsDomainModel } from '../../../domain-models';
import { mobileSettingsActions } from '../actions';

export interface MobileSettingsState extends Partial<MobileSettingsDomainModel> {
  isLoaded: boolean;
  isLoading: boolean;
}

export interface MobileState {
  mobile: MobileSettingsState;
}

export const initalSettingsState: MobileState = {
  mobile: {
    isLoaded: false,
    isLoading: false,
  },
};

const mobileReturn = createReducer<MobileState>(
  initalSettingsState,
  on(mobileSettingsActions.LoadMobileSettings, (state) => ({
    ...state,
    mobile: {
      ...state.mobile,
      isLoading: true,
    },
  })),
  on(mobileSettingsActions.LoadMobileSettingsComplete, (state, { mobileSettings }) => ({
    ...state,
    mobile: {
      ...state.mobile,
      ...mobileSettings,
      isLoaded: true,
      isLoading: false,
    },
  })),
  on(mobileSettingsActions.LoadMobileSettingsFailed, (state) => ({
    ...state,
    mobile: {
      ...state.mobile,
      isLoading: false,
      isLoaded: false,
    },
  })),
  on(mobileSettingsActions.UpdateMobileSettings, (state) => ({
    ...state,
    mobile: {
      ...state.mobile,
      isLoading: true,
      isLoaded: false,
    },
  })),
  on(mobileSettingsActions.UpdateMobileSettingsFailed, (state) => ({
    ...state,
    mobile: {
      ...state.mobile,
      isLoading: false,
      isLoaded: false,
    },
  })),
  on(mobileSettingsActions.UpdateMobileSettingsComplete, (state, { mobileSettings }) => ({
    ...state,
    mobile: {
      ...state.mobile,
      ...mobileSettings,
      isLoading: false,
      isLoaded: true,
    },
  }))
);

export const mobileReducer = (state: MobileState = initalSettingsState, action: Action): MobileState =>
  mobileReturn(state, action);
