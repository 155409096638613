<div class="news-element">
  <ng-container *ngIf="cssClass === 'toggle-element'; else standard">
    <j3-toggle-info-component *ngIf="designMode"> </j3-toggle-info-component>
    <ng-container *ngIf="!designMode; else standard">
      <div
        (mouseenter)="handleToggleItem(true, instanceId)"
        (mouseleave)="handleToggleItem(false, instanceId)"
      >
        <div class="sectionHeader h3" [id]="'element-header-' + instanceId">
          {{ header }}
        </div>
        <div
          class="news-wrapper section-toggle-wrapper absolute-section"
          *ngrxLet="isActive$; let isActive"
          [@openClose]="this.isActive ? 'open' : 'closed'"
          [id]="'element-wrapper-' + instanceId"
          [ngClass]="{
            'section-toggle-wrapper-active': isActive === true
          }"
          [ngStyle]="{ bottom: isActive ? itemPosition : '0' }"
          (@openClose.done)="handleAnimationOverflow(false, instanceId)"
          (@openClose.start)="handleAnimationOverflow(true, instanceId)"
        >
          <div>
            <ng-container [ngTemplateOutlet]="contentData"></ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #standard>
    <ng-container [ngTemplateOutlet]="contentData"></ng-container>
  </ng-template>

  <ng-template #contentData>
    <h3 class="sectionHeader h3" *ngIf="header">
      {{ header | translate }}
    </h3>
    <ng-container *ngIf="layoutType === layoutTypeEnum.NORMAL; else carousel">
      <div class="news-wrapper" *ngrxLet="entities$; let entities">
        <p
          class="no-result col-12"
          *ngIf="!entities.length && (isLoading$ | ngrxPush) === false"
        >
          {{ 'No News to show' | translate }}
        </p>
        <div class="component-loading-indicator" *ngIf="isLoading$ | ngrxPush">
          <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
        </div>
        <div class="news-container">
          <div
            *ngFor="let news of entities"
            [ngClass]="'single-news single-news--template-' + elTemplateValue"
          >
            <ng-container
              *ngTemplateOutlet="newsItemSingle; context: { $implicit: news }"
            ></ng-container>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-center letters-pagination"
        *ngrxLet="pagination$; let pagination"
      >
        <ngb-pagination
          *ngIf="
            (loadingState$ | async) !== LoadingStateEnum.PENDING &&
            ((entities$ | ngrxPush)?.length < pagination?.totalItems ?? 0)
          "
          [page]="pagination.currentPage"
          [pageSize]="pagination.itemsPerPage"
          [maxSize]="10"
          [rotate]="true"
          [ellipses]="true"
          [boundaryLinks]="true"
          [collectionSize]="pagination.totalItems"
          (pageChange)="handlePageChanged($event)"
        ></ngb-pagination>
      </div>
    </ng-container>
    <ng-template #carousel>
      <div class="news-swiper-wrapper" *ngrxLet="entities$; let entities">
        <swiper
          #newsListSwiper
          *ngIf="entities?.length > 0"
          [config]="swiperConfig$ | ngrxPush"
        >
          <ng-container *ngFor="let news of entities">
            <ng-template swiperSlide>
              <ng-container
                *ngTemplateOutlet="newsItemSingle; context: { $implicit: news }"
              ></ng-container>
            </ng-template>
          </ng-container>
        </swiper>
        <div class="news-swiper-controls">
          <button
            class="news-swiper-navigation news-swiper-navigation--prev icon-chevron-left"
            *ngIf="showArrows && entities?.length > slidesVisibleValue"
            (click)="handlePrevSlide()"
            type="button"
          ></button>
          <div
            class="news-pagination"
            *ngIf="entities?.length > slidesVisibleValue"
            [ngClass]="{ 'd-none': !showIndicatorsValue }"
          ></div>
          <button
            class="news-swiper-navigation news-swiper-navigation--next icon-chevron-right"
            *ngIf="showArrows && entities.length > slidesVisibleValue"
            (click)="handleNextSlide()"
            type="button"
          ></button>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
<ng-template #newsItemSingle let-news>
  <ng-container [ngSwitch]="elTemplateValue">
    <ng-container *ngSwitchCase="1">
      <div class="single-news__content single-news__content--template-1">
        <div
          class="single-news__informations"
          (click)="handleNewsPreview(news)"
        >
          <div class="single-news__top">
            <h5 class="single-news__header">
              {{ news.title | translate }}
            </h5>
            <div class="single-news__date">
              {{ news.publishDate | date : 'd/MM/yyyy h:mm aaa' }}
            </div>
          </div>
          <ng-container *ngIf="news.headlineImage && showImg">
            <div class="single-news__image-container">
              <ng-container
                *ngTemplateOutlet="image; context: { news: news }"
              ></ng-container>
            </div>
          </ng-container>
          <div
            class="single-news__description"
            [innerHTML]="news.story | translate"
          ></div>
        </div>
        <div class="single-news__attachments" *ngIf="news?.attachments.length">
          <j3-attachment-item
            [attachments]="news.attachments"
            [orderAttBy]="orderAttBy"
            template="news"
          ></j3-attachment-item>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <div class="single-news__content single-news__content--template-2">
        <div class="single-news__left">
          <div class="single-news__date--day">
            {{ news.publishDate | date : 'dd' }}
          </div>
          <div class="single-news__date--month">
            {{ news.publishDate | date : 'MMM' }}
          </div>
          <div class="single-news__date--year">
            {{ news.publishDate | date : 'yyyy' }}
          </div>
        </div>
        <div class="single-news__right">
          <div
            class="single-news__informations"
            (click)="handleNewsPreview(news)"
          >
            <div class="single-news__top">
              <h5 class="single-news__header">
                {{ news.title | translate }}
              </h5>
              <div class="single-news__date">
                {{ news.publishDate | date : 'd/MM/yyyy h:mm aaa' }}
              </div>
            </div>
            <ng-container *ngIf="news.headlineImage && showImg">
              <div class="single-news__image-container">
                <ng-container
                  *ngTemplateOutlet="image; context: { news: news }"
                ></ng-container>
              </div>
            </ng-container>
            <div
              class="single-news__description"
              [innerHTML]="news.story | translate"
            ></div>
          </div>
          <j3-attachment-item
            [attachments]="news.attachments"
            [orderAttBy]="orderAttBy"
            template="news"
          ></j3-attachment-item>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #image let-news="news">
  <j3-image
    class="single-news__image single-news__image-default"
    [imgSrc]="news.headlineImage"
    [sourceSize]="picturesSizesList()"
    title="{{ news.title }}"
    alt="{{ news.title }}"
  >
  </j3-image>
</ng-template>
