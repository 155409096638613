import { LoadingStateEnum } from '@jotter3/store-helpers';
import { createSelector } from '@ngrx/store';

import { templatesStoreFeature } from './directories.reducer';

const {
  selectLoadingStatus,
  selectEntities: selectStateEntities,
  selectErrors,
  selectSelectedEntity,
} = templatesStoreFeature;

const selectIsLoading = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.LOADING
);

const selectIsLoaded = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.SUCCESS || loadingStatus === LoadingStateEnum.FAILED
);

const selectEntities = createSelector(selectStateEntities, entities => Object.values(entities));

const selectSelectedId = createSelector(selectSelectedEntity, entity => entity?.id);

export const directoriesSelectors = {
  selectEntities,
  selectErrors,
  selectIsLoaded,
  selectIsLoading,
  selectLoadingStatus,
  selectSelectedEntity,
  selectSelectedId,
};
