import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  CommonModule,
  NgOptimizedImage,
} from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
  Type,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { CalendarComponent } from '@jotter3/calendar';
import { CommonComponentsModule } from '@jotter3/common-components';
import {
  AppType,
  Environment,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import { WaCoreModule } from '@jotter3/wa-core';
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import {
  LetModule,
  PushModule,
} from '@ngrx/component';
import { NguCarouselModule } from '@ngu/carousel';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
} from 'ng-recaptcha';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMasonryModule } from 'ngx-masonry';
import { SwiperModule } from 'swiper/angular';

import {
  contentElements,
  declarations,
  providers,
} from './components';
import { pipes } from './pipes';
import { ContentDataProvider } from './providers';
import {
  CookiesNotificationService,
  ThemeDataService,
  ToggleContentService,
} from './services';

@NgModule({
  declarations: [
    ...declarations,
    ...pipes,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    MatCardModule,
    MatIconModule,
    DragDropModule,
    WaCoreModule,
    MatProgressSpinnerModule,
    CommonComponentsModule,
    NgbModule,
    NguCarouselModule,
    NgbAccordionModule,
    NgbAlertModule,
    FormlyModule.forChild(),
    FormlyBootstrapModule,
    NgxMasonryModule,
    MatProgressBarModule,
    RouterModule,
    SwiperModule,
    RecaptchaV3Module,
    CalendarComponent,
    PushModule,
    LetModule,
    NgOptimizedImage,
  ],
  exports: [...contentElements],
  providers: [
    ...providers,
    ToggleContentService,
    CookiesNotificationService,
    ThemeDataService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContentComponentsModule {
  static forRoot(
    appType: AppType,
    dataProviderType: Type<ContentDataProvider>,
    environment: Environment
  ): ModuleWithProviders<ContentComponentsModule> {
    return {
      ngModule: ContentComponentsModule,
      providers: [
        {
          provide: ContentDataProvider,
          useClass: dataProviderType,
        },
        {
          provide: MODULE_PROVIDER_TOKEN,
          useValue: new ModuleProvider(appType),
        },
        {
          provide: RECAPTCHA_V3_SITE_KEY,
          useValue: environment.recaptcha.siteKey,
        },
      ],
    };
  }
}
