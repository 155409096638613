<ng-container *ngIf="$mail | async; let mailValue">
  <span
    *ngIf="iconValue"
    [ngClass]="{
      'school-data-icon-toggle':
        itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE
    }"
    (click)="toggle()"
    class="{{ iconCustomClass }} school-data-icon"
  >
    <i class="school-data-icon--i {{ iconValue }}"></i>
  </span>

  <div
    [ngClass]="{
      'school-data-email-toggle':
        itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE,
      outside: itemType === ItemTypeEnum.TOGGLE_OUTSIDE,
      inside: itemType === ItemTypeEnum.TOGGLE && !moveTo,
      'inside-container': itemType === ItemTypeEnum.TOGGLE && moveTo,
      'school-data-email-width': itemType === ItemTypeEnum.WIDTH,
      active: animationReady
    }"
    [ngStyle]="{
      display:
        (valueMoved && itemType === ItemTypeEnum.TOGGLE_OUTSIDE) ||
        (itemType === ItemTypeEnum.TOGGLE && !moveToBody && !moveTo) ||
        (itemType === ItemTypeEnum.TOGGLE && (moveToBody || moveTo) && valueMoved) ||
        (itemType !== ItemTypeEnum.TOGGLE_OUTSIDE && itemType !== ItemTypeEnum.TOGGLE && !moveToBody)
          ? 'flex'
          : 'none'
    }"
    (@toggle.done)="animationDone()"
    id="school-data-email-{{ itemId }}"
    class="school-data-email--value"
    [@toggle]="(itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE) && !isVisible ? 'closed' : 'open'"
    [@slide]="itemType === ItemTypeEnum.SLIDE && !isVisible ? 'closed' : 'open'"
    [@width]="itemType === 'width' && !isVisible ? 'closed' : 'open'"
  >
    <div class="email-wrapper">
      <div class="email-data">
        <ng-container *ngIf="mailTo; else regularText">
          <a
            [attr.target]="targeBlank ? '_blank' : ''"
            href="mailto:{{ mailValue }}"
            class="school-data-email--link"
          >
            {{ mailValue }}
          </a>
        </ng-container>
        <ng-template #regularText>
          {{ mailValue }}
        </ng-template>
      </div>
      <span
        *ngIf="itemType === ItemTypeEnum.TOGGLE || itemType === ItemTypeEnum.TOGGLE_OUTSIDE || itemType === ItemTypeEnum.SLIDE"
        class="school-data-icon-toggle-close"
      >
        <i
          (click)="toggle()"
          class="school-data-icon--i icon-remove-circle"
        ></i>
      </span>
    </div>
  </div>
</ng-container>
