import {
  createAction,
  props,
} from '@ngrx/store';

import { UserProfileDomainModel } from '../../../domain-models';

const LoadPeopleList = createAction('[People list] Load data', props<{ limit?: number, group?: string }>());
const LoadPeopleListComplete = createAction(
  '[People list] Load People List Complete',
  props<{ peopleList: UserProfileDomainModel[] }>()
);
const LoadPeopleListFailed = createAction('[People list] Load data failed', props<{ error: any }>());

export { LoadPeopleList,
  LoadPeopleListComplete,
  LoadPeopleListFailed };
