import {
  ApiErrorResponse,
  ApiResponse,
  BaseApiEntity,
  dataQuery,
  Pagination,
} from '@jotter3/wa-core';
import { tapResponse } from '@ngrx/component-store';
import {
  debounceTime,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { LoadingStateEnum } from '../enums';
import { EntitiesComponentStoreBaseModel } from '../models';
import { ComponentStoreAbstract } from './component.store.abstract';

export abstract class EntitiesComponentStoreAbstract<TEntity extends BaseApiEntity, TState extends EntitiesComponentStoreBaseModel<TEntity>> extends ComponentStoreAbstract<TState> {
  readonly #onLoadSuccess = this.updater((state: TState, { entities, pagination }: { entities: TEntity[], pagination?: Pagination }) => ({
    ...state,
    entities,
    pagination,
    loadingState: LoadingStateEnum.SUCCESS,
  }));

  readonly #onLoading = this.updater((state: TState, queryParams: dataQuery.DataQuery) => ({
    ...state,
    loadingState: LoadingStateEnum.LOADING,
    queryParams,
    errors: undefined,
  }));

  readonly #onLoadFailure = this.updater((state: TState, errors: ApiErrorResponse[]) => ({
    ...state,
    loadingState: LoadingStateEnum.FAILED,
    errors,
  }));

  readonly #onSelectedEntityChanged = this.updater((state: TState, entity: TEntity | undefined) => ({
    ...state,
    selectedEntity: entity,
  }));

  protected readonly onQueryParamsChanged = this.updater((state: TState, queryParams: dataQuery.DataQuery) => ({
    ...state,
    queryParams,
  }));

  public readonly selectErrors$ = this.select((state: TState) => state.errors);
  public readonly selectEntities$ = this.select((state: TState) => state.entities);
  public readonly selectQueryParams$: Observable<dataQuery.DataQuery> = this.select((state: TState) => state.queryParams);
  public readonly selectPagination$: Observable<Pagination> = this.select((state: TState) => state.pagination);
  public readonly selectSelectedEntity$: Observable<TEntity> = this.select((state: TState) => state.selectedEntity);

  public readonly setQueryParams = this.effect((params$: Observable<dataQuery.DataQuery>) =>
    params$.pipe(
      tap(params => this.onQueryParamsChanged(params))
    ));

  public readonly loadEntities = this.effect((params$: Observable<dataQuery.DataQuery>) =>
    params$.pipe(
      debounceTime(500),
      tap((params: dataQuery.DataQuery) => this.#onLoading(params)),
      switchMap((params: dataQuery.DataQuery) =>
        this.loadData(params).pipe(
          tapResponse(
            ({ success, error, result, pagination }) => {
              if (!success) {
                return this.#onLoadFailure([error]);
              }

              return this.#onLoadSuccess({
                entities: result,
                pagination: pagination,
              });
            }, error => this.#onLoadFailure([error as any])
          )
        ))
    ));

  public readonly reloadEntities = this.effect<void>(
    trigger$ => trigger$.pipe(
      switchMap(() => this.selectQueryParams$),
      tap(params => this.loadEntities(params))
    )
  );

  public readonly selectEntity = this.effect((entity$: Observable<TEntity | undefined>) =>
    entity$.pipe(
      tap(() => this.#onSelectedEntityChanged(undefined)),
      switchMap(entity => this.onSelectEntity(entity)),
      tap(entity => this.#onSelectedEntityChanged(entity))
    ));

  protected abstract loadData(queryParams: dataQuery.DataQuery): Observable<ApiResponse<TEntity[]>>;
  protected onSelectEntity(entity: TEntity | undefined): Observable<TEntity | undefined> {
    return of(entity);
  }
}
