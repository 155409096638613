import { Type } from '@angular/core';
import { reflectMetadata } from '@jotter3/reflection-core';
import { isNil } from 'lodash-es';
import * as moment from 'moment-timezone';

import { constant } from '../decorators';
import { PropertyMetadataModel } from '../models';

export const getComponentProperties = (componentType: Type<any>): { name: string, metadata: any }[] =>
  reflectMetadata.getPropertyMetadata(constant.PROPERTIES_META_KEY, constant.PROPERTY_META_KEY, componentType);

export const shouldIgnoreProperty = (metadata: PropertyMetadataModel, value: any): boolean => {
  const { ignoreInDataset, defaultValue } = metadata;

  return ignoreInDataset || (!isNil(defaultValue) && defaultValue === value);
};

export const getComponentDataset = (dataset: Record<string, any>, componentType: Type<any>): Record<string, any> => {
  const props = getComponentProperties(componentType);

  const result: { [key: string]: any } = {};
  for (const prop of props) {
    const { name, metadata } = prop;
    try {
      if (shouldIgnoreProperty(metadata, dataset[name])) {
        continue;
      }

      result[name] = moment.isMoment(dataset[name]) ? dataset[name].toISOString() : dataset[name];
    } catch (err) {
      console.log(`${name}: `, dataset[name]);
    }
  }

  return result;
};
