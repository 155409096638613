import {
  Component,
  HostListener,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
} from '@angular/platform-browser';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { ContentBuilderManagerService } from '@jotter3/sites-core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'facebook-embeded-component',
  displayName: 'Facebook',
  icon: 'facebook',
  category: SiteComponentCategory.EMBED,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './facebook-embeded.component.html',
  styleUrls: ['./facebook-embeded.component.scss'],
})
export class FacebookEmbededElementComponent extends JotterSitesBaseComponent implements OnInit {
  public fbEmbededLink = '';
  public fbEmbededSafeLink: SafeResourceUrl = '';
  public tabs = '';
  public fbWidth: string;
  private readonly cookieKey = 'J3CookiesAccepted';
  public isBlocked: boolean;
  constructor(
    public sanitizer: DomSanitizer,
    private container: ViewContainerRef,
    public editorAPI: ContentBuilderManagerService
  ) {
    super(FacebookEmbededElementComponent);
  }

  @Property({
    displayName: 'Facebook Page URL',
    modifierType: ModifierType.TEXT,
    required: true,
  })
  get fbEmbeddedUrl(): string {
    return this.fbEmbededLink;
  }
  set fbEmbeddedUrl(value: string) {
    if (value) {
      this.fbEmbededLink = value;
    }
  }

  @Property({
    displayName: 'Tabs',
    modifierType: ModifierType.MULTIDROPDOWN,
    required: true,
    dropdownSource: [
      {
        label: 'Timeline',
        value: 'timeline',
      },
      {
        label: 'Events',
        value: 'events',
      },
      {
        label: 'Messages',
        value: 'messages',
      },
    ],
  })
  get tabsValues(): string {
    return this.tabs;
  }
  set tabsValues(value: string) {
    if (value) {
      this.tabs = value;
      this.fbEmbededSafeLink = this.getSafeUrl(this.fbEmbededLink);
    }
  }

  @Property({
    displayName: 'Width',
    modifierType: ModifierType.NUMBER,
    required: true,
    templateOptions: {
      min: 180,
      max: 500,
    },
  })
  public width = '';

  @Property({
    displayName: 'Height',
    modifierType: ModifierType.NUMBER,
    required: true,
    templateOptions: {
      min: 70,
    },
  })
  public height = '';

  private getSafeUrl(link: string | SafeResourceUrl): SafeResourceUrl | undefined {
    if (!link) {
      return undefined;
    }
    this.calculateWidth();
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.parsefbEmbededURL(link));
  }

  private calculateWidth(): void {
    const settingsWidth = this.width !== '' ? this.width : 300;
    const maxWidth = this.container.element.nativeElement.offsetWidth;
    this.fbWidth = maxWidth && settingsWidth > maxWidth ? maxWidth : settingsWidth;
  }

  private parsefbEmbededURL(url: string | SafeResourceUrl): string {
    const link = encodeURIComponent(url as string);
    const height = this.height !== '' ? this.height : 300;
    const tabs = this.tabs.length ? this.tabs : 'timeline';
    if ((url as string).indexOf('facebook.com') !== -1) {
      return `https://www.facebook.com/plugins/page.php?href=${link}&tabs=${tabs}&width=${this.fbWidth}&height=${height}&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId`;
    } else {
      return null;
    }
  }

  @HostListener('window:resize') onResize(): void {
    this.fbEmbededSafeLink = this.getSafeUrl(this.fbEmbededLink);
  }

  ngOnInit(): void {
    this.fbEmbededSafeLink = this.getSafeUrl(this.fbEmbededLink);
    this.editorAPI
      .contentBlocked()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.isBlocked = data;
      });
  }

  public override afterSettingsSaved(): void {
    setTimeout(() => {
      this.fbEmbededSafeLink = this.getSafeUrl(this.fbEmbededLink);
    });
  }
}
