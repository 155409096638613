<h2 class="text-center" *ngIf="!!header">
  {{ header | translate }}
</h2>

<div
  class="p-3 w-100 h-100 text-center bg-secondary"
  *ngIf="designMode && !documentSRC"
>
  SELECT DOCUMENT
</div>
<div class="document-wrapper" *ngIf="documentSRC">
  <div class="edit-mode-overlay" *ngIf="designMode"></div>
  <div
    class="col-12 pdf-config"
    *ngIf="documentSRC?.url?.pdf || documentSRC?.document?.pdf"
  >
    <div class="row justify-content-center">
      <div class="col-auto pdf-config-data">
        <button
          class="btn btn-outline-secondary pdf-pages-prev"
          [disabled]="this.currentPage === 1"
          (click)="changePage(false)"
        >
          <span class="material-icons"> chevron_left </span>
        </button>
        <input
          class="input pdf-pages"
          [(ngModel)]="currentPage"
          [min]="1"
          [max]="maxPages"
          (blur)="checkPageValue($event.target.value)"
          minMaxValue
          type="number"
        />
        of {{ maxPages }}
        <button
          class="btn btn-outline-secondary"
          [disabled]="this.currentPage === maxPages"
          (click)="changePage(true)"
        >
          <span class="material-icons"> chevron_right </span>
        </button>
      </div>
      <div class="col-auto pdf-config-data">
        <span>Zoom:</span>
        <button
          class="btn btn-outline-secondary"
          [disabled]="this.documentZoom === 2.4"
          (click)="zoomDocument(true)"
        >
          <span class="material-icons"> zoom_in </span>
        </button>
        <button
          class="btn btn-outline-secondary"
          [disabled]="this.documentZoom === 0.1"
          (click)="zoomDocument(false)"
        >
          <span class="material-icons"> zoom_out </span>
        </button>
        <button class="btn btn-outline-secondary" (click)="zoomReset()">
          Reset
        </button>
        <button class="btn btn-outline-secondary" (click)="onDownloadFile()">
          <span class="material-icons"> download </span>
        </button>
      </div>
      <div class="col pdf-config-data pdf-config-data-search">
        <span class="pdf-config-data-search-text">Search:</span>
        <div class="pdf-config-data-search-container">
          <input
            class="input pdf-search"
            id="searchinpdf"
            (keyup)="search($event.target.value)"
            type="text"
          />
        </div>
      </div>
    </div>
  </div>
  <pdf-viewer
    class="element-pdf-viewer"
    [(page)]="currentPage === null ? null : currentPage"
    [src]="documentSRC?.url?.pdf || documentSRC?.document?.pdf"
    [rotation]="0"
    [original-size]="false"
    [show-all]="true"
    [fit-to-page]="true"
    [zoom]="documentZoom"
    [stick-to-page]="false"
    [render-text]="true"
    [external-link-target]="'blank'"
    [autoresize]="true"
    [show-borders]="false"
    [style.height]="heightTypeInPx(documentHeight)"
    (after-load-complete)="afterLoadComplete($event)"
    zoom-scale="page-width"
  ></pdf-viewer>
</div>
