import {
  inject,
  Injectable,
} from '@angular/core';
import { UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  filter,
  map,
  Observable,
} from 'rxjs';

import {
  templatesSelectors,
  TemplatesStoreActions,
} from '../+state';



@Injectable({ providedIn: 'root' })
export class TemplateCollectionLoadedGuard {
  readonly #store = inject(Store);

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return combineLatest([
      this.#store.select(templatesSelectors.selectIsLoading),
      this.#store.select(templatesSelectors.selectIsLoaded),
    ]).pipe(
      filter(([
        isLoading,
        isLoaded,
      ]) => {
        if (isLoading) {
          return false;
        }
        if (!isLoaded) {
          this.#store.dispatch(TemplatesStoreActions.loadTrigger());
        }
        return isLoaded;
      }),
      map(([, isLoaded]) => isLoaded)
    );

  }
}
