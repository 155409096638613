<div class="p-3">
  <div class="row">
    <div class="col">
      <h4 class="modal-header-title mt-0 mb-0 ms-2">{{ settings.componentMetadata.displayName }} Options</h4>
    </div>
    <div class="col">
      <button
        (click)="dismiss()"
        class="btn small-btn float-end"
        style="width: 40px; height: 40px; padding: 8px"
      >
        <j3-icon name="close"></j3-icon>
      </button>
    </div>
  </div>
</div>
<div class="element-designer-content">
  <form
    *ngIf="$viewInitialized"
    [formGroup]="formGroup"
    (ngSubmit)="onSaveChanges()"
  >
    <formly-form
      [model]="formModel"
      [fields]="formFieldsNormal"
      [form]="formGroup"
    ></formly-form>
  </form>
  <div
    (click)="onToggleAdvanced()"
    class="advanced-button"
  >
    <h4>Advanced Options</h4>
    <span class="material-icons">{{ !showAdvanced ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</span>
  </div>
  <form
    *ngIf="$viewInitialized && showAdvanced"
    [formGroup]="advancedFormGroup"
  >
    <formly-form
      [model]="formModel"
      [fields]="formFieldsAdvanced"
      [form]="advancedFormGroup"
    ></formly-form>
  </form>
</div>
<div class="element-designer-actions">
  <button
    [disabled]="!formGroup.valid"
    (click)="onSaveChanges()"
    class="btn btn-primary"
    type="submit"
  >
    Save
  </button>
  <button
    (click)="dismiss()"
    class="btn btn-secondary"
  >
    Cancel
  </button>
</div>
