import { InjectionToken } from '@angular/core';

export interface ModuleConfig {
  DB_URL: string;
  DB_URL_VPC?: string;
  LOCAL_DOMAIN?: string;
  APP_TYPE: 'admin' | 'client';
}

export const J3_CORE_MODULE_PROVIDER_TOKEN: InjectionToken<ModuleConfig> = new InjectionToken<ModuleConfig>(
  'J3_CORE_MODULE_PROVIDER_TOKEN'
);
