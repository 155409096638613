import { InjectionToken } from '@angular/core';

import { BaseCalendarComponentStore } from './base-calendar.component.store';
import { CalendarStateModel } from './calendar-state.model';

const CALENDAR_STORE: InjectionToken<BaseCalendarComponentStore> = new InjectionToken<BaseCalendarComponentStore>('CALENDAR_STORE');

export { BaseCalendarComponentStore,
  CALENDAR_STORE,
  CalendarStateModel };
