import {
  createAction,
  props,
} from '@ngrx/store';

import { GroupTypeDomainModel } from '../../../domain-models';

const GroupTypesList = createAction('[Group Types list] Load data', props<{ limit?: number }>());
const GroupTypesListComplete = createAction(
  '[Group Types list] Load Group Types List Complete',
  props<{ groupTypesList: GroupTypeDomainModel[] }>()
);
const GroupTypesListFailed = createAction('[Group Types list] Load data failed', props<{ error: any }>());

export { GroupTypesList,
  GroupTypesListComplete,
  GroupTypesListFailed };
