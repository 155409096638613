import { Injectable } from '@angular/core';
import {
  ModifierType,
  SettingsItem,
} from '@jotter3/sites-abstract';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { formlyTransformationHelper } from '../helpers';

@Injectable()
export class DynamicFormBuilderService {
  private readonly fieldTransformMap: Map<
    ModifierType | string,
    (property: SettingsItem, componentInstance?: any) => FormlyFieldConfig
  >;

  constructor() {
    this.fieldTransformMap = this.createTransformMap();
  }

  /**
   * Transform ContentElement properties to Formly fields
   *
   * @param properties List of properties to transforme to Formly field
   */
  public transformToFormly(properties: SettingsItem[], componentInstance: any): FormlyFieldConfig[] {
    return (properties || []).map((property) => {
      const { metadata } = property;

      const transformFuncion = this.fieldTransformMap.get(metadata.modifierType);

      return transformFuncion ? transformFuncion(property, componentInstance) : {};
    });
  }

  private createTransformMap(): Map<ModifierType | string, (property: SettingsItem) => FormlyFieldConfig> {
    const transformationMap = new Map<ModifierType, (property: SettingsItem) => FormlyFieldConfig>();

    transformationMap.set(ModifierType.TEXT, formlyTransformationHelper.textInputTransform);
    transformationMap.set(ModifierType.NUMBER, formlyTransformationHelper.numericInputTransform);
    transformationMap.set(ModifierType.DROPDOWN, formlyTransformationHelper.dropdownInputTransform);
    transformationMap.set(ModifierType.MULTIDROPDOWN, formlyTransformationHelper.multiDropdownInputTransform);
    transformationMap.set(ModifierType.TEXT_AREA, formlyTransformationHelper.textareaTransform);
    transformationMap.set(ModifierType.CHECK_BOX, formlyTransformationHelper.chekboxTransform);
    transformationMap.set(ModifierType.DATE_PICKER, formlyTransformationHelper.datePickerTransform);
    transformationMap.set(ModifierType.TIME_PICKER, formlyTransformationHelper.timePickerTransform);
    transformationMap.set(ModifierType.ARRAY, formlyTransformationHelper.arrayTransform);
    transformationMap.set(ModifierType.FILEUPLOAD, formlyTransformationHelper.fileUploadTransform);

    transformationMap.set(ModifierType.CUSTOM, formlyTransformationHelper.customSelector);

    transformationMap.set(ModifierType.J3_API_DROPDOWN, formlyTransformationHelper.j3ApiDropdown);

    transformationMap.set(ModifierType.UNIT_INPUT, formlyTransformationHelper.unitInputTransform);

    transformationMap.set(ModifierType.J3_API_MULTIDROPDOWN, formlyTransformationHelper.j3ApiMultiDropdown);

    transformationMap.set(ModifierType.RADIO_BUTTON, formlyTransformationHelper.radioButtonTransform);

    transformationMap.set(ModifierType.RADIO_BUTTON_GROUP, formlyTransformationHelper.radioButtonGroupTransform);

    transformationMap.set(ModifierType.IMAGE_RESOLUTION, formlyTransformationHelper.imageResolutionTransform);

    return transformationMap;
  }
}
