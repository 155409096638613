<div
  class="content-element content-element-twitter-embeded{{
    cssClass ? ' ' + cssClass : ''
  }}"
  *ngIf="!this.isBlocked"
  [@openClose]="
    designMode || !floatingElement
      ? 'editMode'
      : { value: openClose, params: { twitterWidth: width ? width : '180px' } }
  "
  [id]="'twitter-wrapper-' + elementId"
  [ngClass]="[
    floatingElement && !designMode ? 'floating' : '',
    fixedElement ? 'fixed' : '',
    floatingElement ? 'floating-item' : ''
  ]"
  [style.width]="floatingElement ? 'auto' : width ? width : '100%'"
  [style.height]="floatingElement ? height : 'auto'"
>
  <div class="static-twitter" *ngIf="!floatingElement; else floatingContainer">
    <div
      class="sectionHeader text-center h3 mb-0"
      [innerHtml]="header | translate"
    ></div>
    <div class="timeline-wrapper" [style.height]="height">
      <a
        class="twitter-timeline"
        *ngIf="twitterAccount; else noProfile"
        [attr.data-height]="clearPx(height)"
        [href]="
          'https://twitter.com/' + twitterAccount + '?ref_src=twsrc%5Etfw'
        "
        data-width="100%"
      >
        {{ 'Tweets by' | translate }} {{ twitterAccount }}
      </a>
    </div>
  </div>

  <ng-template #floatingContainer>
    <div class="timeline-wrapper">
      <div
        class="floating-twitter"
        [style.width]="width ? width : '180px'"
        [style.height]="height"
      ></div>
      <a
        *ngIf="!elementExists"
        [href]="
          'https://twitter.com/' + twitterAccount + '?ref_src=twsrc%5Etfw'
        "
        >{{ 'Tweets by' | translate }} {{ twitterAccount }}</a
      >
    </div>
    <div class="trigger-wrapper d-flex flex-column" [ngClass]="align">
      <img
        #trigger
        [src]="getTwitterOpenSrc()"
        [ngClass]="'image-resizer'"
        [style.height]="fullHeight ? '100%' : 'auto'"
        (click)="toggleAnimation()"
        alt=""
        loading="lazy"
      />
    </div>
  </ng-template>
  <ng-template #noProfile>{{
    'Please update Twitter account name' | translate
  }}</ng-template>
</div>

<div class="content-blocked-notice" *ngIf="this.isBlocked">
  <h3>Twitter Blocked</h3>
  <p>
    This content element cannot load as you have declined to enable cookies. To
    re-enable cookies please click
    <a class="content-blocked-notice--link" (click)="editorAPI.acceptCookies()"
      >here</a
    >
  </p>
  <p>
    <small
      >Our privacy policy can be found here:
      <a
        class="content-blocked-notice--link"
        (click)="editorAPI.onNavigateToPolicy()"
        >Privacy Policy</a
      ></small
    >
  </p>
</div>
