import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  Renderer2,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { reflectMetadata } from '@jotter3/reflection-core';
import {
  constant,
  TemplateBaseComponent,
  TemplateComponentMetadata,
} from '@jotter3/sites-abstract';
import * as html from 'angular-html-parser/lib/compiler/src/ml_parser/ast';
import { isNil } from 'lodash-es';

import {
  CMS_TEMPLATE_COMPONENTS,
  CMS_TEMPLATE_DATA_ATTRIBUTE,
} from '../../common';
import { SecurityTrustType } from '../../enums';
import { templateBuilderModel } from '../../models';
import { TemplateParserHtml } from '../../services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cms-template-resolver',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <link
    *ngFor="let style of !templateDef ? [] : templateDef.styles || []"
    type="text/css"
    rel="stylesheet"
    [href]="style | safe : securityTrustType.RESOURCE"
  />`,
})
export class TemplateResolverComponent implements AfterViewInit, OnDestroy {
  private $templateComponentsDef: {
    component: Type<TemplateBaseComponent>,
    metadata: TemplateComponentMetadata | undefined,
  }[];

  private $viewInitialized = false;
  private $templateDef: templateBuilderModel.SiteTemplate | undefined;
  private $dataset: { [key: string]: any } = {};
  private ngComponentsValue: ComponentRef<any>[] = [];
  readonly securityTrustType = SecurityTrustType;

  constructor(
    @Inject(CMS_TEMPLATE_COMPONENTS)
      templateComponentsDef: Type<TemplateBaseComponent>[][],
    private $templateParser: TemplateParserHtml,
    private $renderer: Renderer2,
    private $contentContainer: ViewContainerRef,
    private $componentFactory: ComponentFactoryResolver,
    private $applicationRef: ApplicationRef,
    private readonly injector: Injector
  ) {
    this.$templateComponentsDef = templateComponentsDef.flat().map((x) => {
      const meta = reflectMetadata.getComponentMetadata<TemplateComponentMetadata>(
        constant.TEMPLATE_COMPONENT_DESCRIPTION_META_KEY,
        x
      );
      return {
        component: x,
        metadata: meta && meta.length > 0 ? meta[0] : undefined,
      };
    });
  }

  @Input()
  get templateDef(): templateBuilderModel.SiteTemplate | undefined {
    return this.$templateDef;
  }

  set templateDef(value: templateBuilderModel.SiteTemplate | undefined) {
    this.$templateDef = value;
    this.initializeTemplateRenderer();
  }

  @Input()
  get dataset(): { [key: string]: any } {
    return this.$dataset;
  }

  set dataset(value: { [key: string]: any }) {
    this.$dataset = value;
  }

  public ngAfterViewInit(): void {
    this.$viewInitialized = true;
    this.initializeTemplateRenderer();
  }

  private initializeTemplateRenderer(): void {
    if (!this.$viewInitialized || !this.templateDef || !this.templateDef.templateHtml) {
      return;
    }

    const { templateHtml, dataset } = this.templateDef;
    this.$contentContainer.clear();
    const parentComponent = this.$contentContainer.element.nativeElement;

    const templateTree = this.$templateParser.parseHtml(templateHtml);
    this.renderTemplate(templateTree.rootNodes as any, parentComponent, dataset);
  }

  private renderTemplate(
    templateTree: html.Node[],
    parentComponent: any,
    templateDataset?: { [key: string]: any }
  ): void {
    for (const node of (templateTree || []).filter(
      (x) => x instanceof html.Element || x instanceof html.Text
    ) as html.Element[]) {
      if (node.constructor.name === 'Text' || node instanceof html.Text) {
        const contentText = this.$renderer.createText((node as any).value);
        this.$renderer.appendChild(parentComponent, contentText);
        continue;
      }

      const { name, attrs, children } = node as html.Element;

      const templateComponent = this.$templateComponentsDef.find(
        (x) => x.metadata && x.metadata.selector === name
      );

      if (!isNil(templateComponent)) {
        try {
          const defaultTag = templateComponent?.metadata?.defaultTag || 'div';
          const datasetProp = attrs.find((x) => x.name === CMS_TEMPLATE_DATA_ATTRIBUTE);
          let templateAttr: any = {};
          try {
            templateAttr = datasetProp
              ? JSON.parse(
                datasetProp.value.replace(/'/g, '"').replace(/,(?!\s*?[{["'\w])/g, '')
              )
              : '';
          } catch (err: unknown) {
            if (datasetProp) {
              console.log(datasetProp.value.replace(/'/g, '"'));
            }
          }

          const elementPresenter = this.$renderer.createElement(
            templateAttr.dataset?.customTag || defaultTag
          );

          const classList = templateAttr.dataset?.customClass
            ? templateAttr.dataset.customClass.split(' ')
            : [];
          if (templateComponent?.metadata?.defaultClass) {
            this.$renderer.addClass(elementPresenter, templateComponent.metadata.defaultClass);
          }

          for (const classes of classList) {
            this.$renderer.addClass(elementPresenter, classes);
          }

          this.$renderer.appendChild(parentComponent, elementPresenter);
          const ctrl = this.createAngularComponent(
            templateComponent.component,
            elementPresenter,
            this.getDatasetForComponent(attrs)
          );

          this.$applicationRef.attachView(ctrl.hostView);
        } catch (err) {
          console.warn(err);
        }
        continue;
      }
      const element = this.$renderer.createElement(name);
      for (const attr of attrs || []) {
        this.$renderer.setAttribute(element, attr.name, attr.value);
      }

      this.renderTemplate(children, element, templateDataset);
      this.$renderer.appendChild(parentComponent, element);
    }
  }

  private getDatasetForComponent(attrs: html.Attribute[]): { [key: string]: any } | undefined {
    const datasetProp = attrs.find((x) => x.name === CMS_TEMPLATE_DATA_ATTRIBUTE);

    if (!datasetProp) {
      return undefined;
    }
    const convertToJson = datasetProp.value
      .replace(/'/g, '"')
      .replace(/,(?!\s*?[{["'\w])/g, '');
    const templateElementDatasetModel = JSON.parse(
      convertToJson
    ) as templateBuilderModel.TemplateElementDatasetModel;
    const { dataset } = templateElementDatasetModel;

    return dataset;
  }

  private createAngularComponent(
    component: Type<TemplateBaseComponent>,
    projectionNode: any,
    dataset?: { [key: string]: any }
  ): ComponentRef<TemplateBaseComponent> {
    const factory = this.$componentFactory.resolveComponentFactory(component);
    const ctrl = factory.create(this.injector, [], projectionNode);

    if (dataset) {
      ctrl.instance.setDataset(dataset);
    }
    this.ngComponentsValue.push(ctrl);

    return ctrl;
  }

  public ngOnDestroy(): void {
    this.ngComponentsValue.forEach((component) => {
      component.destroy();
      component.changeDetectorRef.detach();
    });
  }
}
