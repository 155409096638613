import { Injectable } from '@angular/core';
import {
  Meta,
  Title,
} from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class AppConfigurationService {
  constructor(private titleService: Title, private metaService: Meta) {}

  get title(): string {
    return this.titleService.getTitle();
  }

  set title(value: string) {
    this.titleService.setTitle(value);
  }
}
