import { isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import {
  Observable,
  Subscriber,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LeafletService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  public loadMap(): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      if (isPlatformBrowser(this.platformId)) {
        import('leaflet').then((leaflet) => {
          observer.next({
            ...leaflet,
          });
        });
      } else {
        observer.error('The library could not be loaded');
      }
    });
  }
}
