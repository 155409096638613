<ng-container *ngIf="cssClass === 'toggle-element'; else standard">
  <j3-toggle-info-component *ngIf="designMode"> </j3-toggle-info-component>
  <ng-container *ngIf="!designMode; else standard">
    <div
      (mouseenter)="toggleItem(true, elementId)"
      (mouseleave)="toggleItem(false, elementId)"
    >
      <div
        class="sectionHeader text-center h3 mb-0"
        [id]="'element-header-' + elementId"
      >
        {{ header | translate }}
      </div>
      <div
        class="letters-wrapper section-toggle-wrapper absolute-section"
        [@openClose]="this.isActive ? 'open' : 'closed'"
        [id]="'element-wrapper-' + elementId"
        [ngClass]="{ 'section-toggle-wrapper-active': isActive === true }"
        [ngStyle]="{ bottom: isActive ? itemPosition : '0' }"
        (@openClose.done)="animationOverflow(false, elementId)"
        (@openClose.start)="animationOverflow(true, elementId)"
      >
        <div>
          <ng-container [ngTemplateOutlet]="contentData"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #standard>
  <ng-container>
    <div class="sectionHeader text-center h3 mb-0">
      {{ header | translate }}
    </div>
    <ng-container [ngTemplateOutlet]="contentData"></ng-container>
  </ng-container>
</ng-template>

<ng-template #contentData>
  <div
    class="p-3 w-100 h-100 text-center bg-secondary"
    *ngIf="designMode && !embedCode"
  >
    ENTER EMBED CODE
  </div>

  <div
    class="embed-wrapper"
    *ngIf="(embedCode && designMode) || (!designMode && validTime())"
    [ngClass]="{ 'design-mode': designMode }"
    [innerHTML]="embedSafeUrl"
  ></div>
</ng-template>
