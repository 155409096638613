import { isPlatformServer } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import {
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';
import { urlJoin } from 'url-join-ts';

import {
  ApiResponse,
  BaseApiEntity,
  ModuleConfig,
  ModuleOptions,
} from '../common';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    @Inject(ModuleOptions) private readonly moduleConfig: ModuleConfig,
    @Inject(PLATFORM_ID) private readonly platformId: object
  ) {}

  public generateParams(options: Record<string, string | number | boolean>): HttpParams {
    let queryParams = new HttpParams();
    Object.keys(options).forEach((key: string) => {
      queryParams = queryParams.set(key, options[key]);
    });
    return queryParams;
  }

  private get baseUrl(): string {
    const { DB_URL, DB_URL_VPC } = this.moduleConfig;

    return isPlatformServer(this.platformId) && DB_URL_VPC ? DB_URL_VPC : DB_URL;
  }

  public load<TModel extends BaseApiEntity>(resource: string, queryParams?: HttpParams): Observable<ApiResponse<TModel[]>> {
    return this.http
      .get<ApiResponse<TModel[]>>(urlJoin(this.baseUrl, resource), {
        params: queryParams,
      })
      .pipe(
        map((res) => {
          const { result } = res;
          return result
            ? res
            : {
              result: res as any,
              pagination: {},
              statusCode: 200,
              success: true,
              error: undefined,
            };
        }),
        map((res) => ({
          ...res,
          success: true,
          statusCode: 200,
        })),
        catchError((err: HttpErrorResponse): Observable<ApiResponse<TModel[]>> => {
          const { error, status } = err;
          return of({
            result: [],
            pagination: {},
            statusCode: status,
            error,
            success: false,
          });
        })
      );
  }

  public save<TModel extends BaseApiEntity>(
    resourceName: string,
    entity: Partial<TModel>,
    params?: {
      useJsonServer?: boolean,
      useLocalhost?: boolean,
      updateJson?: boolean,
      headers?: HttpHeaders,
    }
  ): Observable<ApiResponse<TModel>> {
    if (entity.id) {
      return this.http.put<ApiResponse<TModel>>(urlJoin(this.baseUrl, resourceName, entity.id), entity).pipe(
        map((res) => ({
          ...res,
          success: true,
          statusCode: 200,
        })),
        catchError((err: HttpErrorResponse): Observable<ApiResponse<TModel>> => {
          const { error, status } = err;
          return of({
            result: {} as TModel,
            pagination: {},
            statusCode: status,
            error,
            success: false,
          });
        })
      );
    }

    return this.http
      .post<ApiResponse<TModel>>(urlJoin(this.baseUrl, resourceName), entity, { headers: params?.headers })
      .pipe(
        map((res) => ({
          ...res,
          success: true,
          statusCode: 200,
        })),
        catchError((err: HttpErrorResponse): Observable<ApiResponse<TModel>> => {
          const { error, status } = err;
          return of({
            result: {} as TModel,
            pagination: {},
            statusCode: status,
            error,
            success: false,
          });
        })
      );
  }

  public putArray<TModel>(
    resourceName: string,
    entity: Partial<TModel>
  ): Observable<ApiResponse<TModel>> {
    return this.http.put<ApiResponse<TModel>>(urlJoin(this.baseUrl, resourceName, undefined), entity).pipe(
      map((res) => ({
        ...res,
        success: true,
        statusCode: 200,
      })),
      catchError((err: HttpErrorResponse): Observable<ApiResponse<TModel>> => {
        const { error, status } = err;
        return of({
          result: {} as TModel,
          pagination: {},
          statusCode: status,
          error,
          success: false,
        });
      })
    );
  }

  public delete<TModel extends BaseApiEntity>(resourceName: string, id: string): Observable<ApiResponse<TModel>> {
    return this.http.delete<ApiResponse<TModel>>(urlJoin(this.baseUrl, resourceName, id));
  }

  loadSingle<TModel extends BaseApiEntity>(
    resourceName: string,
    id?: string | number,
    params?: {
      useJsonServer?: boolean,
      useLocalhost?: boolean,
      headers?: HttpHeaders,
    }
  ): Observable<ApiResponse<TModel>> {
    const idValue = id ? id : '';

    return this.http
      .get<ApiResponse<TModel>>(urlJoin(this.baseUrl, resourceName, idValue), {
        headers: params?.headers,
      })
      .pipe(
        map((res) => {
          const { result } = res;
          return result
            ? res
            : {
              result: res as any,
              pagination: {},
              statusCode: 200,
              success: true,
              error: undefined,
            };
        }),
        map((res) => ({
          ...res,
          success: true,
          statusCode: 200,
        })),
        catchError((err: HttpErrorResponse): Observable<ApiResponse<TModel>> => {
          const { error, status } = err;
          return of({
            result: {} as TModel,
            pagination: {},
            statusCode: status,
            error,
            success: false,
          });
        })
      );
  }
}
