import { SelectFieldComponent } from './custom-fields';

const fields = [
  {
    name: 'ng-select',
    component: SelectFieldComponent,
  },
];

const formlyComponents = [SelectFieldComponent];

export { fields,
  formlyComponents };
