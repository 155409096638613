<ng-container *ngIf="cssClass === 'toggle-element'; else standardView">
  <j3-toggle-info-component *ngIf="designMode"> </j3-toggle-info-component>
  <ng-container *ngIf="!designMode; else standardView">
    <div
      (mouseenter)="handleToggleItem(true, instanceId)"
      (mouseleave)="handleToggleItem(false, instanceId)"
    >
      <div
        class="sectionHeader text-center h3 mb-0"
        [id]="'element-header-' + instanceId"
      >
        {{ header }}
      </div>
      <div
        class="letters-wrapper section-toggle-wrapper absolute-section"
        *ngrxLet="isActive$; let isActive"
        [@openClose]="this.isActive ? 'open' : 'closed'"
        [id]="'element-wrapper-' + instanceId"
        [ngClass]="{
          'section-toggle-wrapper-active': isActive === true
        }"
        [ngStyle]="{ bottom: isActive ? itemPosition : '0' }"
        (@openClose.done)="animationOverflow(false, instanceId)"
        (@openClose.start)="animationOverflow(true, instanceId)"
      >
        <div>
          <ng-container [ngTemplateOutlet]="contentData"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #standardView>
  <div class="sectionHeader text-center h3 mb-0">{{ header | translate }}</div>
  <ng-container [ngTemplateOutlet]="contentData"></ng-container>
</ng-template>

<ng-template #contentData>
  <div class="component-loading-indicator" *ngIf="isLoading$ | ngrxPush">
    <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
  </div>
  <div
    class="no-result"
    *ngIf="
      (isLoading$ | ngrxPush) === false && (entities$ | ngrxPush)?.length === 0
    "
  >
    {{ 'No Letters to show' | translate }}
  </div>
  <div class="letters-container vstack gap-3">
    <ng-container *ngFor="let letter of entities$ | ngrxPush">
      <ng-container
        *ngTemplateOutlet="letterItemSingle; context: { $implicit: letter }"
      ></ng-container>
    </ng-container>
  </div>
  <div
    class="d-flex justify-content-center letters-pagination"
    *ngrxLet="pagination$; let pagination"
  >
    <ngb-pagination
      *ngIf="
        (loadingState$ | async) !== LoadingStateEnum.PENDING &&
        ((entities$ | ngrxPush)?.length < pagination?.totalItems ?? 0)
      "
      [page]="pagination.currentPage"
      [pageSize]="pagination.itemsPerPage"
      [maxSize]="10"
      [rotate]="true"
      [ellipses]="true"
      [boundaryLinks]="true"
      [collectionSize]="pagination.totalItems"
      (pageChange)="handlePageChanged($event)"
    ></ngb-pagination>
  </div>
</ng-template>

<ng-template #letterItemSingle let-letter>
  <ng-container [ngSwitch]="elTemplateValue">
    <ng-container *ngSwitchCase="VIEW_STYLE.STANDARD">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ letter.name }}</mat-card-title>
          <mat-card-subtitle>{{
            letter.publishDate | date : 'dd/MM/yyyy, h:mm a'
          }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <j3-text-editor
            [content]="letter.description"
            [disabled]="true"
          ></j3-text-editor>
          <j3-attachment-item
            [attachments]="letter.attachments"
            [orderAttBy]="orderAttBy"
          ></j3-attachment-item>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <ng-container *ngSwitchCase="2">
      <div class="single-letter__content single-letter__content--template-2">
        <div class="single-letter__left">
          <div class="cal">
            <div class="single-letter__date--month">
              {{ letter.publishDate | date : 'MMM' }}
            </div>
            <div class="single-letter__date--day">
              {{ letter.publishDate | date : 'dd' }}
            </div>
            <div class="single-letter__date--year">
              {{ letter.publishDate | date : 'yyyy' }}
            </div>
          </div>
        </div>
        <div class="single-letter__right">
          <div class="single-letter__top">
            <h5 class="single-letter__header">
              {{ letter.name }}
            </h5>
            <div class="single-letter__date--time">
              {{ letter.publishDate | date : 'hh:mm a' }}
            </div>
          </div>
          <j3-text-editor
            [content]="letter.description"
            [disabled]="true"
          ></j3-text-editor>
          <ng-container *ngIf="letter.attachments">
            <j3-attachment-item
              [attachments]="letter.attachments"
              [orderAttBy]="orderAttBy"
            ></j3-attachment-item>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
