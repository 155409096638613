import { LoadingStateEnum } from '@jotter3/store-helpers';
import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createFeature,
  createReducer,
  on,
} from '@ngrx/store';

import { GdprSettingsDomainModel } from '../../domain-models';
import { BaseStateModel } from '../common';
import { GdprStoreActions } from './gdpr.actions';

export interface GdprState extends BaseStateModel {
  entity: GdprSettingsDomainModel | undefined;
  domain: string | undefined;
  errors: Array<ApiErrorResponse | unknown> | undefined;
}

const initialState: GdprState = {
  loadingStatus: LoadingStateEnum.PENDING,
  domain: undefined,
  entity: undefined,
  errors: undefined,
};

export const siteStoreFeature = createFeature({
  name: 'gdprStore',
  reducer: createReducer(
    initialState,
    on(GdprStoreActions.loadTrigger, (state: GdprState): GdprState => ({
      ...state,
      loadingStatus: LoadingStateEnum.LOADING,
    })),
    on(GdprStoreActions.loadSuccess, (state: GdprState, { entity }): GdprState => ({
      ...state,
      loadingStatus: LoadingStateEnum.SUCCESS,
      entity: entity,
    })),
    on(GdprStoreActions.loadFailed, (state: GdprState, { errors }): GdprState => ({
      ...state,
      errors,
      loadingStatus: LoadingStateEnum.FAILED,
    }))
  ),
});
