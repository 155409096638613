import { isPlatformBrowser } from '@angular/common';
import {
  inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AppStoreActions,
  appStoreSelectors,
} from '@jotter3/api-connector';
import {
  AppTenantInfoService,
  SSR_DOMAIN,
  SSR_TENANT_INFO,
} from '@jotter3/core';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  filter,
  map,
  tap,
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppLoadedGuard {
  readonly #store = inject(Store);
  readonly #appTenantInfoService = inject(AppTenantInfoService);
  readonly #platformId = inject(PLATFORM_ID);
  readonly #tenantInfo = inject(SSR_TENANT_INFO, { optional: true });
  readonly #domainDomain = inject(SSR_DOMAIN, { optional: true });
  readonly #router = inject(Router);

  canActivate(): Observable<boolean> {
    return this.#store.select(appStoreSelectors.selectIsLoaded).pipe(
      filter(loaded => {
        if (!loaded) {
          if (isPlatformBrowser(this.#platformId)) {
            this.#appTenantInfoService.tenant = this.#appTenantInfoService.loadFromState();
          } else {
            this.#appTenantInfoService.tenant = { ...this.#tenantInfo };
          }

          this.#store.dispatch(AppStoreActions.entered({
            tenant: this.#appTenantInfoService.tenant,
            domain: this.#domainDomain ?? this.#appTenantInfoService.domain,
          }));
        }
        return loaded;
      }),
      concatLatestFrom(() => this.#store.select(appStoreSelectors.selectTenant)),
      tap(([, tenantInfo]) => {
        if (!tenantInfo?.id) {
          this.#router.navigate([
            'error',
            404,
          ], { skipLocationChange: true });
        }
      }),
      map(([loaded]) => loaded)
    );
  }
}
