import { Environment } from '@jotter3/common-helpers';

export const environment: Environment = {
  production: true,
  isServer: false,
  tenantDomain: 'wa.stagingjotter3.co.uk',
  ApiConnectorConfig: {
    DB_URL: 'https://api.stagingjotter3.co.uk/api',
    THEMES_API: 'https://wa.stagingjotter3.co.uk/api',
    TRANSLATE_API: 'https://wa.stagingjotter3.co.uk/api',
  },
  SITES_TEMPLATES_URL: 'https://theme.stagingjotter3.co.uk/templates/',
  Firebase: {
    apiKey: 'AIzaSyDQH62SfsS0k7u5jnFTSmhegsILi7lAQLo',
    authDomain: 'jotter3-staging.firebaseapp.com',
    databaseURL: 'https://jotter3-staging.firebaseio.com',
    projectId: 'jotter3-staging',
    storageBucket: 'jotter3-staging.appspot.com',
    messagingSenderId: '1018785254070',
    appId: '1:1018785254070:web:adbbb3c02c5ee1d7c6f417',
    measurementId: 'G-5XS1VBYHCN',
  },
  recaptcha: {
    siteKey: '6Lfo-rslAAAAAKJ7CRVRnmNdSDKW8ttTi3GYqrnI',
  },
  // sentryDSN: 'https://6636d26dcc76417581382a5be2f3cd4b@o23717.ingest.sentry.io/4503896427200512',
  // sentryTracesSampleRate: 0.1,
  // sentryEnvironment: 'staging',
  // sentryReplaysSessionSampleRate: null,
  // sentryReplaysOnErrorSampleRate: null,
  sentryDSN: null,
  sentryTracesSampleRate: null,
  sentryEnvironment: null,
  STATIC_CDN: 'https://static-01.stagingjotter3.co.uk/client/browser/',
  translationAsset: 'assets/i18n/',
};
