<div
  [innerHtml]="header"
  class="sectionHeader text-center h3 mb-0"
></div>
<div
  *ngIf="designMode && !videoSRC"
  class="p-3 w-100 h-100 text-center bg-secondary"
>
  ENTER VIDEO SOURCE
</div>
<div
  *ngIf="videoSRC && !platformServer"
  class="video-wrapper"
>
  <video
    [style.maxWidth]="cssWidth"
    [ngClass]="align ? align : 'align-left'"
    [style.maxHeight]="cssHeight"
    controls
    preload="metadata"
    #videoElement
  >
    <source
      [src]="(videoSRC?.url || videoSRC?.video)?.mp4?.fhd"
      #videoSource
      type="video/mp4"
    />
    {{ "Sorry, your browser doesn't support embedded videos." | translate }}
  </video>
  <div
    *ngIf="videoProcessing"
    class="progress-wrapper"
  >
    <div class="progress-content">
      <mat-spinner></mat-spinner>
      <span>{{ 'Preparing video' | translate }}</span>
    </div>
  </div>
</div>
