
import { SettingsDialogOptions } from './settings-dialog-options.model';

export interface SiteComponentMetadata {
  selector?: string | string[];
  displayName?: string;
  description?: string;
  icon: string;
  category?: SiteComponentCategory;
  minItemCols?: number;
  minItemRows?: number;
  showSettingsAfterAdd?: boolean;
  selectedByUser?: boolean;
  settingsDialogSettings?: SettingsDialogOptions;
  roles?: string[];
  isBrowserComponentOnly?: boolean;
}

export enum SiteComponentCategory {
  TEXT,
  MEDIA,
  MODULES,
  EMBED,
  OTHER
}
