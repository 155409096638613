import {
  inject,
  Injectable,
} from '@angular/core';
import { EventInput } from '@fullcalendar/core';
import {
  CategoryDomainModel,
  domainModels,
  stateCollectionServices,
} from '@jotter3/api-connector';
import {
  BaseCalendarComponentStore,
  CalendarEvent,
} from '@jotter3/calendar';
import {
  ApiResponse,
  ApiService,
  dataQuery,
  LHSBracketsDataQueryAdapter,
} from '@jotter3/wa-core';
import {
  isNil,
  sortBy,
} from 'lodash-es';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';

@Injectable()
export class CalendarComponentStore extends BaseCalendarComponentStore {
  readonly #apiService: ApiService = inject(ApiService);
  readonly #lhsBracketsAdapter: LHSBracketsDataQueryAdapter = inject(LHSBracketsDataQueryAdapter);
  readonly #categoriesCollectionService: stateCollectionServices.CategoriesCollectionService = inject(stateCollectionServices.CategoriesCollectionService);

  public readonly selectSortedEvents$: Observable<EventInput> = this.select(this.selectEvents$, (events: EventInput) => this.#sortEvents(events));
  public readonly selectEventsCategories$: Observable<CategoryDomainModel[]> = this.select(this.selectEvents$, (events: EventInput) => this.#getEventCategories(events));

  public filterEventsByCategories(categoriesList: domainModels.CategoryDomainModel[]): Observable<EventInput> {
    return this.selectEvents$.pipe(distinctUntilChanged(), map(data => {
      if (!categoriesList.length) {
        return this.#sortEvents(data);
      }
      return this.#sortEvents(data.filter((item: EventInput) => categoriesList.some((f) => item.categories.find((category: domainModels.CategoryDomainModel) => category.id === f.id))));
    }));
  }

  public getSelectedCategoriesData(categories: string[]): Observable<domainModels.CategoryDomainModel[]> {
    const params = this.#lhsBracketsAdapter.toQueryString({
      filters: [
        {
          type: dataQuery.FilterType.SELECT,
          property: 'id',
          operator: dataQuery.Operator.IN,
          value: categories,
        },
      ],
    });
    return this.#categoriesCollectionService.getWithQuery(params);
  }

  protected loadData(queryParams: dataQuery.DataQuery): Observable<ApiResponse<domainModels.CalendarEventDomainModel[]>> {
    return this.#apiService.load<domainModels.CalendarEventDomainModel>('events_occurrences', this.#lhsBracketsAdapter.toQueryParam(queryParams))
      .pipe(
        switchMap((res) => {
          const pages: number = res.pagination.totalPages;

          if (pages > 1) {
            const observables: Observable<ApiResponse<domainModels.CalendarEventDomainModel[]>>[] = [of(res)];
            for (let i = 2; i <= pages; i++) {
              const observablesParams = this.#lhsBracketsAdapter.toQueryParam({
                ...queryParams,
                pagination: {
                  size: 30,
                  page: i,
                },
              });
              observables.push(
                this.#apiService
                  .load<domainModels.CalendarEventDomainModel>('events_occurrences', observablesParams)
                  .pipe(switchMap((item) => {
                    return of(item);
                  }))
              );
            }

            return combineLatest(observables).pipe(switchMap(response => {
              const reponseValue: ApiResponse<domainModels.CalendarEventDomainModel[]> = {
                result: [],
                pagination: {},
                success: true,
                statusCode: 200,
                error: null,
              };
              response.map(x => reponseValue.result.push(...x.result));
              return of(reponseValue);
            }));
          }

          return of(res);
        })
      );
  }

  #sortEvents(eventsList: EventInput): EventInput {
    return eventsList.sort(
      (a: CalendarEvent, b: CalendarEvent) => (a.start !== null ? a.start.getTime() : Infinity) - (b.start !== null ? b.start.getTime() : Infinity)
    );
  }

  #getEventCategories(events: EventInput): CategoryDomainModel[] {
    const categoriesList: domainModels.CategoryDomainModel[] = [];

    events.forEach((eventItem: domainModels.CalendarEventDomainModel) => {
      eventItem.categories.forEach((categoryItem: domainModels.CategoryDomainModel) => {
        const category = categoriesList.find((item) => item.id === categoryItem.id);
        if (isNil(category)) {
          categoriesList.push(categoryItem as domainModels.CategoryDomainModel);
        }
      });
    });

    return sortBy(categoriesList, category => category.name);
  }


}
