import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { PageDomainModel } from '../../../domain-models';
import { pagesListActions } from '../actions';

interface PagesState extends EntityState<PageDomainModel> {
  isLoading: boolean;
  isLoaded: boolean;
  currentPage?: PageDomainModel;
}

const pageListAdapter: EntityAdapter<PageDomainModel> = createEntityAdapter<PageDomainModel>();

const initalPagesState: PagesState = pageListAdapter.getInitialState({
  isLoaded: false,
  isLoading: false,
});

const pagesListReturn = createReducer<PagesState>(
  initalPagesState,
  on(pagesListActions.LoadPagesList, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(pagesListActions.LoadPagesListComplete, (state, { pagesList }) =>
    pageListAdapter.setAll(pagesList, {
      ...state,
      isLoading: false,
      isLoaded: true,
    })),
  on(pagesListActions.LoadPagesListFailed, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
  }))
);

const pagesListReducer = (state: PagesState = initalPagesState, action: Action): PagesState => pagesListReturn(state, action);

export { initalPagesState,
  pageListAdapter,
  pagesListReducer,
  PagesState };
