import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { UserProfileState } from '../reducers';

export const selectUserMyProfileState = createFeatureSelector<UserProfileState>('userprofile');

export const userMyProfileStateSelector = createSelector(selectUserMyProfileState, (state) => state);

export const userMyProfileDataSelector = createSelector(userMyProfileStateSelector, (state) => {
  const entites = Object.values(state.entities);
  return entites.length ? entites[0] : undefined;
});

export const areUserMyProfileDataLoading = createSelector(userMyProfileStateSelector, (state) => state.isLoading);

export const areUserMyProfileDataLoaded = createSelector(userMyProfileStateSelector, (state) => state.isLoaded);

export const isUserAuthroized = createSelector(userMyProfileStateSelector, (state) => state.isAuthorized);
