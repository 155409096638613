<div class="calendar-element">
  <ng-container *ngIf="defaultView === 'eventsList'; else calendarView">
    <ng-container *ngIf="cssClass === 'toggle-element'; else eventListView">
      <j3-toggle-info-component *ngIf="designMode"> </j3-toggle-info-component>
      <ng-container
        *ngIf="!designMode; else eventListView"
        [ngTemplateOutlet]="toggleContainer"
        [ngTemplateOutletContext]="{
          content: eventsList,
          item: 'events-content'
        }"
      ></ng-container>
    </ng-container>

    <ng-template #eventListView>
      <div [ngClass]="cssClass">
        <ng-container [ngTemplateOutlet]="eventsList"></ng-container>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #calendarView>
    <ng-container *ngIf="cssClass === 'toggle-element'; else calendarTemplate">
      <j3-toggle-info-component *ngIf="designMode"> </j3-toggle-info-component>
      <ng-container
        *ngIf="!designMode; else calendarTemplate"
        [ngTemplateOutlet]="toggleContainer"
        [ngTemplateOutletContext]="{ content: waCalCalendar, item: 'calendar' }"
      ></ng-container>
    </ng-container>
    <ng-template #calendarTemplate>
      <div [ngClass]="cssClass">
        <ng-container [ngTemplateOutlet]="headerElement"></ng-container>
        <div
          class="events-content-filters col-12"
          *ngIf="
            showCategoryFilter &&
            (selectedCategoriesList | ngrxPush)?.length > 0
          "
        >
          <ng-container
            [ngTemplateOutlet]="filterData"
            [ngTemplateOutletContext]="{
              categoriesList: selectedCategoriesList | ngrxPush,
              filteredItems: filterByIds
            }"
          ></ng-container>
        </div>
        <ng-container [ngTemplateOutlet]="waCalCalendar"></ng-container>
      </div>
    </ng-template>
  </ng-template>
</div>

<ng-template #waCalCalendar>
  <wa-cal-calendar *ngIf="viewInitialized"></wa-cal-calendar>
</ng-template>

<ng-template #eventsList>
  <ng-container *ngIf="layoutType === layoutTypeEnum.NORMAL; else carousel">
    <ng-container [ngTemplateOutlet]="headerElement"></ng-container>
    <div class="events-content-wrapper">
      <div class="events-content-container" *ngrxLet="events$ as eventsList">
        <div
          class="events-content-filters col-12"
          *ngIf="
            showCategoryFilter &&
            ((visibleCategories$ | ngrxPush)?.length ||
              (selectedCategoriesList | ngrxPush)?.length)
          "
        >
          <ng-container
            [ngTemplateOutlet]="filterData"
            [ngTemplateOutletContext]="{
              categoriesList: !selectedCategoriesList.length
                ? (visibleCategories$ | ngrxPush)
                : (selectedCategoriesList | ngrxPush),
              filteredItems: filterByIds
            }"
          ></ng-container>
        </div>
        <div
          class="events-content-item"
          *ngFor="
            let event of eventsList
              | slice : (page - 1) * maxCount : page * maxCount
          "
        >
          <j3-events-list-item
            [event]="event"
            [elTemplate]="elTemplate"
          ></j3-events-list-item>
        </div>
        <ng-container *ngIf="eventsList?.length > maxCount">
          <ngb-pagination
            class="col-12"
            [(page)]="page"
            [pageSize]="maxCount"
            [collectionSize]="eventsList?.length"
          ></ngb-pagination>
        </ng-container>
      </div>
      <ng-template #loading>
        <p>No Events to show</p>
      </ng-template>
      <div class="events-footer">
        <div class="events-calendar-link" (click)="goToCalendarPage()">
          Show Calendar
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #carousel>
    <ng-container [ngTemplateOutlet]="headerElement"></ng-container>
    <j3-events-carousel
      [events]="events$ | ngrxPush"
      [eventsConfig]="eventsConfig"
      [elTemplate]="elTemplate"
      [showArrows]="showArrows"
      [showIndicators]="showIndicators"
      [slidesVisible]="slidesVisible"
    ></j3-events-carousel>
  </ng-template>
</ng-template>

<ng-template #toggleContainer let-content="content" let-item="item">
  <div
    (mouseenter)="toggleItem(true, elementId)"
    (mouseleave)="toggleItem(false, elementId)"
  >
    <ng-container [ngTemplateOutlet]="headerElement"></ng-container>
    <div
      [@openClose]="this.isActive ? 'open' : 'closed'"
      [id]="'element-wrapper-' + elementId"
      [ngClass]="{
        'section-toggle-wrapper-active': isActive === true
      }"
      [ngStyle]="{ bottom: isActive ? itemPosition : '0' }"
      [class]="item + '-wrapper section-toggle-wrapper absolute-section'"
      (@openClose.done)="animationOverflow(false, elementId)"
      (@openClose.start)="animationOverflow(true, elementId)"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #headerElement>
  <div class="sectionHeader h3" [id]="'element-header-' + elementId">
    {{ header }}
  </div>
</ng-template>

<ng-template
  #filterData
  let-categoriesList="categoriesList"
  let-filteredItems="filteredItems"
>
  <ul class="events-content-filters-list" *ngIf="categoriesList?.length">
    <li class="events-content-filters-list-item">
      <span class="small">Filter events by category:</span>
    </li>
    <ng-container *ngFor="let category of categoriesList">
      <li
        class="events-content-filters-list-item"
        [ngClass]="filteredItems.indexOf(category.id) !== -1 ? 'active' : ''"
        [id]="'events-content-filters-list-item-' + category.id"
        (click)="toggleCategory(category)"
      >
        <span
          class="events-content-filters-list-item-icon"
          [style]="{
            backgroundColor: category.color
          }"
        ></span>
        {{ category.name }}
      </li>
    </ng-container>
  </ul>
</ng-template>
