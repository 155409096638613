import { Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { IFileDomainModel } from '../domain-models';
import { DraftsAbstractEntitiesCollectionBase } from './drafts-abstract.collection.service';

@Injectable({ providedIn: 'root' })
export class FilesCollectionService extends DraftsAbstractEntitiesCollectionBase<IFileDomainModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('File', serviceElementsFactory);
  }
}
