import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MODULE_PROVIDER_TOKEN } from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
} from '@ngx-formly/core';
import classNames from 'classnames';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subscription,
} from 'rxjs';

import { ModuleProvider } from '../../common/module.provider';
import { AppType } from '../../enums';
import { emailValidator } from '../../validators/email.validators';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';
import { NewsletterSubscriptionConst } from './consts';
import { NewsletterSubscriptionComponentStore } from './newsletter-subscription.component.store';

@SiteComponent({
  selector: NewsletterSubscriptionConst.elementSelector,
  displayName: 'Letter subscription',
  icon: 'mail',
  category: SiteComponentCategory.OTHER,
  showSettingsAfterAdd: false,
})
@Component({
  selector: NewsletterSubscriptionConst.elementSelector,
  templateUrl: 'newsletter-subscription.component.html',
  styleUrls: ['newsletter-subscription.component.scss'],
  providers: [NewsletterSubscriptionComponentStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterSubscriptionComponent extends JotterSitesBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly #subscription: Subscription = new Subscription();
  readonly #subscriptionComponentStore: NewsletterSubscriptionComponentStore;
  readonly #moduleProvider: ModuleProvider;

  readonly isLoading$: Observable<boolean>;
  readonly hasError$: Observable<boolean>;
  readonly error$: Observable<ApiErrorResponse>;
  readonly loadedSuccess$: Observable<boolean>;

  readonly formlyFieldConfig$: BehaviorSubject<FormlyFieldConfig[]> = new BehaviorSubject<FormlyFieldConfig[]>([]);
  readonly formGroup: FormGroup = new FormGroup({});
  readonly submitButtonText = NewsletterSubscriptionConst.buttons.submit;
  readonly alertText = NewsletterSubscriptionConst.alerts;
  public formOptions: FormlyFormOptions = {};

  constructor(
    subscriptionComponentStore: NewsletterSubscriptionComponentStore,
    @Inject(MODULE_PROVIDER_TOKEN) moduleProvider: ModuleProvider
  ) {
    super(NewsletterSubscriptionComponent);

    this.#subscriptionComponentStore = subscriptionComponentStore;
    this.isLoading$ = subscriptionComponentStore.selectIsLoading$;
    this.loadedSuccess$ = subscriptionComponentStore.selectIsLoadedSuccess$;
    this.hasError$ = subscriptionComponentStore.selectHasErrors$;
    this.error$ = subscriptionComponentStore.selectErrors$;
    this.#moduleProvider = moduleProvider;
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
    required: true,
    defaultValue: NewsletterSubscriptionConst.header,
  })
  public header: string = NewsletterSubscriptionConst.header;

  @Property({
    displayName: 'Description',
    modifierType: ModifierType.TEXT,
    required: true,
    defaultValue: NewsletterSubscriptionConst.description,
  })
  public description: string = NewsletterSubscriptionConst.description;

  @HostBinding('class')
  public get classes(): string {
    return classNames(NewsletterSubscriptionConst.hostCssClass);
  }

  public ngOnInit(): void {
    this.formlyFieldConfig$.next(this.#getFormlyConfig());

    this.#subscription.add(
      this.#subscriptionComponentStore.selectIsLoadedSuccess$.subscribe(() =>
        setTimeout(() => {
          this.#subscriptionComponentStore.resetState();
          this.formOptions.resetModel();
        }, 3000))
    );
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.#subscription.add(
      combineLatest([
        this.isLoading$,
        this.onDesingModeChanged,
      ]).subscribe(([
        loading,
        designMode,
      ]) => {
        if (loading || designMode) {
          this.formGroup.disable();
          return;
        }
        this.formGroup.enable();
      })
    );

    this.onDesingModeChanged.emit(this.designMode);
  }

  public handleOnSubmit(): void {
    if (!this.formGroup.valid || this.#moduleProvider.applicationType === AppType.ADMIN) {
      return;
    }

    this.#subscriptionComponentStore.subscribeToNewsletter(this.formGroup.getRawValue());
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.#subscription.unsubscribe();
  }

  #getFormlyConfig(): FormlyFieldConfig[] {
    return [
      {
        type: 'input',
        key: 'name',
        props: {
          label: NewsletterSubscriptionConst.formInputs.name.label,
          required: true,
          placeholder: NewsletterSubscriptionConst.formInputs.name.placeholder,
        },
      },
      {
        type: 'input',
        key: 'emailAddress',
        props: {
          label: NewsletterSubscriptionConst.formInputs.email.label,
          required: true,
          placeholder: NewsletterSubscriptionConst.formInputs.email.placeholder,
        },
        validators: {
          email: {
            expression: emailValidator,
            message: (error: any, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Email address`,
          },
        },
      },
    ];
  }
}
