import { isPlatformServer } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { Observable } from 'rxjs';

@Injectable()
export class XTimezoneInterceptor implements HttpInterceptor {
  excludes = ['https://nominatim.openstreetmap.org'];

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: object,
    @Optional() @Inject(REQUEST) private readonly request: Request
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const timeZone = this.getCurrentTimezone();

    if (!timeZone?.length || this.excludes.some(exclude => req.url.includes(exclude))) {
      return next.handle(req);
    }

    const httpRequest = req.clone({
      headers: req.headers.set('X-Timezone', timeZone),
    });

    return next.handle(httpRequest);
  }

  private getCurrentTimezone(): string {
    if (isPlatformServer(this.platformId)) {
      // eslint-disable-next-line @typescript-eslint/dot-notation,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.request['ipInfo']?.timezone;
    }

    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
