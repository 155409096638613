import {
  createAction,
  props,
} from '@ngrx/store';

import { PolicyGroupDomainModel } from '../../../domain-models';

const LoadPoliciesGroups = createAction('[Policies groups] Load data', props<{ group?: string }>());
const LoadPoliciesGroupsComplete = createAction(
  '[Policies groups] Load Policies Groups Complete',
  props<{ policiesGroups: PolicyGroupDomainModel[] }>()
);
const LoadPoliciesGroupsFailed = createAction('[Policies groups] Load data failed', props<{ error: any }>());

export { LoadPoliciesGroups,
  LoadPoliciesGroupsComplete,
  LoadPoliciesGroupsFailed };
