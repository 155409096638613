import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { UserProfileDomainModel } from '../../../domain-models';
import { peopleListActions } from '../actions';

interface PeopleState extends EntityState<UserProfileDomainModel> {
  isLoading: boolean;
  isLoaded: boolean;
}

const peopleListAdapter: EntityAdapter<UserProfileDomainModel> = createEntityAdapter<UserProfileDomainModel>();

const initalPeopleState: PeopleState = {
  ...peopleListAdapter.getInitialState(),
  isLoaded: false,
  isLoading: false,
};

const peopleListReturn = createReducer<PeopleState>(
  initalPeopleState,
  on(peopleListActions.LoadPeopleList, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(peopleListActions.LoadPeopleListComplete, (state, { peopleList }) =>
    peopleListAdapter.setAll(peopleList, {
      ...state,
      isLoading: false,
      isLoaded: true,
    })),
  on(peopleListActions.LoadPeopleListFailed, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
  }))
);

const peopleListReducer = (state: PeopleState | undefined, action: Action): PeopleState => peopleListReturn(state, action);

export { initalPeopleState,
  peopleListAdapter,
  peopleListReducer,
  PeopleState };
