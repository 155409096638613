import {
  CdkDragDrop,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  inject,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  domainModels,
  ResourcesListDomainService,
} from '@jotter3/api-connector';
import {
  FileDownloadService,
  J3TranslateService,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import {
  SNACKBAR_COMPONENT,
  SnackBarCustom,
} from '@jotter3/sites-core';
import { ApiService } from '@jotter3/wa-core';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { orderBy } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { urlJoin } from 'url-join-ts';

import {
  FILE_MANAGER_TOKEN,
  FileManagerProvider,
} from '../../common';
import { FileInfoType } from '../../enums';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'files-list-content-component',
  displayName: 'Files',
  icon: 'list',
  category: SiteComponentCategory.MEDIA,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './files-list.component.html',
  styleUrls: ['files-list.component.scss'],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class FilesListContentComponent extends JotterSitesBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly #fileDownloadService: FileDownloadService = inject(FileDownloadService);

  public orderByName = false;
  private headerValue: string;
  private itemsPerPageValue = 10;
  private resourceListId: string;
  private resourcesValue: Partial<domainModels.ResourceDomainModel>[] = [];
  public subdirTrackArray: domainModels.ResourceDomainModel[] = [];
  private itemsValue: BehaviorSubject<Partial<domainModels.ResourceDomainModel>[]> = new BehaviorSubject<
    Partial<domainModels.ResourceDomainModel>[]
  >([]);
  private previewInNewWindowValue = false;
  private currentPageValue = 1;
  private loadingValue = false;
  private resizeObserver: ResizeObserver;
  public tableLayoutMobile: boolean;

  constructor(
    private readonly resourcesListService: ResourcesListDomainService,
    private readonly apiService: ApiService,
    @Inject(MODULE_PROVIDER_TOKEN) private readonly moduleProvider: ModuleProvider,
    @Optional() @Inject(FILE_MANAGER_TOKEN) private readonly fileManager: FileManagerProvider,
    @Optional() @Inject(DOCUMENT) private readonly document: any,
    @Inject(SNACKBAR_COMPONENT) private snackBarData: SnackBarCustom,
    private zone: NgZone
  ) {
    super(FilesListContentComponent);
    if (this.platformBrowser) {
      this.resizeObserver = new ResizeObserver((entries) => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        this.zone.run(() => {
          this.getContainerWidth(entries[0].contentRect.width);
        });
      });
    }
  }

  @ViewChild('filesListContainer') filesListContainer: ElementRef<HTMLInputElement>;

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
    templateOptions: {
      required: true,
      placeholder: 'Enter header...',
    },
  })
  public get header(): string {
    return this.headerValue;
  }

  public set header(value: string) {
    this.headerValue = value;
  }

  public get resources(): Partial<domainModels.ResourceDomainModel>[] {
    return this.resourcesValue;
  }

  @Property({
    displayName: 'Files to display',
    modifierType: ModifierType.NUMBER,
    required: true,
    templateOptions: {
      required: true,
      placeholder: 'Enter value...',
      min: 1,
    },
    defaultValue: 10,
  })
  public get itemsPerPage(): number {
    return this.itemsPerPageValue;
  }

  public set itemsPerPage(value: number) {
    if (this.itemsPerPageValue === value) {
      return;
    }
    this.itemsPerPageValue = value;
    this.onPageChange(this.currentPage);
  }

  @Property({
    displayName: 'Open files in new window',
    modifierType: ModifierType.CHECK_BOX,
    className: 'form-switch',
    defaultValue: false,
  })
  public get previewInNewWindow(): boolean {
    return this.previewInNewWindowValue;
  }

  public set previewInNewWindow(value: boolean) {
    this.previewInNewWindowValue = value;
  }


  public get loading(): boolean {
    return this.loadingValue;
  }

  public get items(): Observable<Partial<domainModels.ResourceDomainModel>[]> {
    return this.itemsValue;
  }

  public get currentPage(): number {
    return this.currentPageValue;
  }

  public set currentPage(value: number) {
    if (this.currentPageValue === value) {
      return;
    }
    this.currentPageValue = value;
  }

  public get fileInfoType(): typeof FileInfoType {
    return FileInfoType;
  }

  public ngOnInit(): void {
    this.onResourcesChanged(this.resourceListId);
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.platformBrowser) {
      setTimeout(() => {
        if (this.filesListContainer) {
          this.resizeObserver.observe(this.filesListContainer.nativeElement);
        }
      }, 1000);
      return;
    }
  }

  public override getDataset(): { [key: string]: any } {
    const dataset = super.getDataset();
    dataset.selectedFiles = undefined;
    dataset.resourceListId = this.resourceListId;
    dataset.orderByName = this.orderByName;
    return dataset;
  }

  public override setDataset(data: { [key: string]: any }): void {
    super.setDataset(data);

    if (!data) {
      return;
    }

    this.resourceListId = data?.resourceListId;
    this.orderByName = data?.orderByName;
  }

  public onOpenFileManager(): void {
    if (!this.fileManager) {
      return;
    }

    this.fileManager
      .openManager({
        multiple: true,
        allowedTypes: ['all'],
        selectedItems: this.resourcesValue.map((file) => file.file as domainModels.IFileDomainModel) || [],
      })
      .pipe(untilComponentDestroyed(this))
      .subscribe((res: domainModels.IFileDomainModel[]) => {
        const newItems: domainModels.ResourceDomainModel[] = [];
        res.forEach((file) => {
          if (
            this.resourcesValue.findIndex((item) => {
              const fileData = item.file as domainModels.IFileDomainModel;
              return fileData.id === file.id;
            }) === -1
          ) {
            newItems.push({
              id: file.id,
              file,
            });
          }
        });
        this.resourcesValue = [
          ...this.resourcesValue,
          ...newItems,
        ]
          .map((element, index) => ({
            ...element,
            resourceOrder: index + 1,
          }))
          .sort((a, b) => (a.resourceOrder > b.resourceOrder ? 1 : -1));
        this.currentPage = 1;
        this.onPageChange(1);
        if (this.orderByName) {
          this.sortData();
        }
      });
  }

  public onDownloadFile(resource: domainModels.IFileDomainModel): void {
    this.#fileDownloadService.onDownloadFile(resource.name, resource?.url?.origin);
  }

  public onPreviewFile(file: domainModels.IFileDomainModel): void {
    window.open(file.url.origin, this.designMode || this.previewInNewWindow ? '_blank' : '_self');
  }

  public onRemoveFile(resource: domainModels.ResourceDomainModel): void {
    if (!this.designMode) {
      return;
    }

    this.resourcesValue = this.resourcesValue
      .filter((item) => item.id !== resource.id)
      .sort((a, b) => (a.resourceOrder > b.resourceOrder ? 1 : -1))
      .map((item, index) => ({
        ...item,
        resourceOrder: index + 1,
      }));

    const availablePages = Math.max(1, Math.ceil(this.resourcesValue?.length / this.itemsPerPage));
    this.currentPage = availablePages < this.currentPage
      ? availablePages
      : this.currentPage;

    this.onPageChange(this.currentPage);
  }

  public onPageChange(page: number): void {
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = page * this.itemsPerPage;
    this.itemsValue.next(this.resourcesValue.slice(startIndex, endIndex));
  }

  private addOrUpdateResourcesList(files: Partial<domainModels.ResourceDomainModel>[]): Observable<boolean> {
    if (!files?.length || this.subdirTrackArray.length) {
      return of(true);
    }

    const model: domainModels.ResourceListDomainModel = {
      id: this.resourceListId || undefined,
      resources: files.map(({ file }, index) => ({
        fileId: (file as domainModels.IFileDomainModel).id,
        resourceOrder: index + 1,
      })),
    };

    const action = this.resourceListId ? this.resourcesListService.put : this.resourcesListService.post;

    return action
      .bind(this.resourcesListService)(model)
      .pipe(
        map((res: domainModels.ResourceListDomainModel) => {
          this.resourceListId = res.id;
          this.resourcesListService.set(res.id, res);
          this.onResourcesChanged(this.resourceListId);
          return true;
        }),
        catchError(() => of(false))
      );
  }

  public onFileReorder(event: CdkDragDrop<domainModels.ResourceDomainModel[]>): void {
    this.moveItemsInArray(event.previousIndex, event.currentIndex);
  }

  public moveItemsInArray(previousIndex: number, currentIndex: number, manual: boolean = false): void {
    if (previousIndex === currentIndex) {
      return;
    }
    const startAt = manual ? (this.currentPage - 1) * this.itemsPerPage : 0;
    const source = this.resourcesValue;

    moveItemInArray(source, startAt + previousIndex, startAt + currentIndex);
    this.onPageChange(this.currentPage);
  }

  private onResourcesChanged(resourceId: string): void {
    if (!this.resourceListId) {
      return;
    }

    if (this.platformBrowser) {
      setTimeout(() => {
        this.loadingValue = true;
      });
    }

    this.resourcesListService
      .get(resourceId)
      .pipe(
        untilComponentDestroyed(this),
        filter(response => !!response?.result?.resources?.length),
        switchMap((response) =>
          of(
            (response.result.resources as domainModels.ResourceDomainModel[]).slice().sort((a, b) =>
              a.resourceOrder > b.resourceOrder ? 1 : -1)
          ))
      )
      .subscribe((response) => this.setNewResources(response));
  }

  private setNewResources(response: Partial<domainModels.ResourceDomainModel>[]): void {
    this.resourcesValue = response;
    if (this.orderByName) {
      this.sortData();
    }
    this.currentPage = 1;
    this.onPageChange(1);

    if (!this.platformBrowser) {
      this.loadingValue = false;
      return;
    }

    setTimeout(() => {
      this.loadingValue = false;
    }, 100);
  }

  public firstItemOfArray(resource: domainModels.ResourceDomainModel): boolean {
    const index = this.resourcesValue.indexOf(resource);
    return index === 0;
  }

  public lastItemOfArray(resource: domainModels.ResourceDomainModel): boolean {
    const index = this.resourcesValue.indexOf(resource);
    return index === this.resourcesValue?.length - 1;
  }

  public override onSaveChanges(): Observable<boolean> {
    return this.addOrUpdateResourcesList(this.resourcesValue);
  }

  public getResourceIcon(file: domainModels.IFileDomainModel): string {
    const { directory, fileType } = file;

    if (directory) {
      return 'folder';
    }

    switch (true) {
      case fileType.startsWith('image'):
        return 'file-image';
      case fileType.startsWith('video'):
        return 'file-video';
      case fileType.startsWith('audio'):
        return 'music';
      case fileType === 'application/pdf':
        return 'file-pdf';
      case fileType === 'application/msword':
        return 'file-word';
      default:
        return 'file';
    }
  }

  public onGoUpFromSubdirectory(): void {
    this.subdirTrackArray.pop();

    if (this.subdirTrackArray.length) {
      this.onGoInsideDirectory(this.subdirTrackArray[this.subdirTrackArray.length - 1], false);
      return;
    }

    this.onResourcesChanged(this.resourceListId);
  }

  public onGoInsideDirectory(resource: domainModels.ResourceDomainModel, trackable: boolean = true): void {
    const { file } = resource;
    if (this.designMode || !(file as domainModels.IFileDomainModel)?.directory) {
      return;
    }

    this.loadingValue = true;

    const parentDir = file as domainModels.IFileDomainModel;
    this.apiService
      .load<domainModels.IFileDomainModel>(urlJoin('resource_lists', this.resourceListId, 'subdir', parentDir.id))
      .pipe(
        take(1),
        map((res) => res.result),
        map((res) => res.map((item) => ({ file: item })))
      )
      .subscribe((res) => {
        this.setNewResources(res);

        if (trackable) {
          this.subdirTrackArray.push(resource);
        }
      });
  }

  public getContainerWidth(element: number): void {
    if (element < 370) {
      this.tableLayoutMobile = true;
    } else {
      this.tableLayoutMobile = false;
    }
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.filesListContainer) {
      this.resizeObserver?.unobserve(this.filesListContainer.nativeElement);
    }
  }

  public toggleSorting(): void {
    if (!this.orderByName) {
      const snackRef = this.snackBarData.customSnackBarTrigger('info', 100000, 'If you enable sorting files by name, the drag-and-drop sorting and sorting by arrow buttons will be disabled.');
      snackRef.afterDismissed().subscribe((res) => {
        if (res === undefined || res.message === 'CANCELLED') {
          return;
        }
        this.sortData();
      });
    } else {
      this.orderByName = false;
    }
  }

  public sortData(): void {
    this.orderByName = true;
    this.resourcesValue = orderBy(this.resourcesValue, [
      (item): boolean => (item.file as domainModels.IFileDomainModel).directory,
      (item): string => (item.file as domainModels.IFileDomainModel).name.toLowerCase(),
    ], [
      'desc',
      'asc',
    ]);
    this.itemsValue.next(this.resourcesValue.slice(0, this.itemsPerPage));
  }

}
