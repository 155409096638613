<ng-container *ngIf="to.j3Permissions?.length; else noPermissionsAdded">
  <ng-container *j3Permissions="to.j3Permissions">
    <ng-container *ngTemplateOutlet="recursiveList"></ng-container>
  </ng-container>
</ng-container>

<ng-template #noPermissionsAdded>
  <ng-container *ngTemplateOutlet="recursiveList"></ng-container>
</ng-template>

<ng-template #recursiveList>
  <div
    [class.has-error]="showError"
    class="form-group formly-normalized-select select-item-{{uniqueID}}"
  >
    <label
      *ngIf="to.label && to.hideLabel !== true"
      [attr.for]="id"
    >
      {{ to.label }}
      <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
    </label>
    <ng-select
      [formControl]="formControl"
      [multiple]="to.multiple"
      [items]="selectOptions | async"
      [bindValue]="to.valueProp"
      [bindLabel]="to.labelProp"
      [placeholder]="to.placeholder"
      [formlyAttributes]="field"
      [virtualScroll]="true"
      appendTo="body"
    >
      <ng-template
        *ngIf="to.template === 'icons'"
        ng-option-tmp
        let-item="item"
      >
        <i [className]="item.value + ' fa-' + item.name"></i> {{item.name}}
      </ng-template>
    </ng-select>
    <div
      *ngIf="showError"
      [style.display]="'block'"
      class="invalid-feedback"
    >
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
    <div
      *ngIf="to.description"
      class="form-text"
    >
      {{ to.description }}
    </div>
  </div>
</ng-template>
