const getPropertyMetadata = (
  arrayMetaKey: symbol,
  itemMetaKey: symbol,
  targetType: unknown
): { name: string, metadata: unknown }[] => {
  const properties: string[] = [];
  let target = targetType;

  while (target !== Object.prototype) {
    const childFields = Reflect.getOwnMetadata(arrayMetaKey, target) || [];
    properties.push(...childFields);
    target = Object.getPrototypeOf(target);
  }

  return properties.map((propertyName) => ({
    name: propertyName,
    metadata: Reflect.getMetadata(itemMetaKey, targetType, propertyName),
  }));
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const getComponentMetadata = <T extends unknown>(metadataKey: symbol, target: unknown): T[] =>
  Reflect.getOwnMetadata(metadataKey, target);

export { getComponentMetadata,
  getPropertyMetadata };
