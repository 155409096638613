import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import * as fromActions from '../actions';

@Injectable()
export class LoaderStatusEffects {
  loaderStatusData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadLoaderStatus),
      switchMap((loaderStatus) => of(fromActions.LoadLoaderStatusComplete({ loaderStatus })))
    ));

  constructor(private actions$: Actions) {}
}
