import * as tiny from 'tinymce';

const showEditorError = (editor: tiny.Editor, message: string): void => {
  editor.windowManager.alert(message);
};

const onCopy = (editor: tiny.Editor): void => {
  const editorErrorMessage =
    'Your browser does not have access to the clipboard. Please grant access or use Ctrl+C/Cmd+C keyboard shortcuts to copy contents.';

  try {
    navigator.clipboard
      .writeText('')
      .then(() => editor.execCommand('Copy'))
      .catch((error) => {
        console.error('J3CopyPlugin error:', error);
        showEditorError(editor, editorErrorMessage);
      });
  } catch (err) {
    showEditorError(editor, editorErrorMessage);
  }
};

const onPaste = (editor: tiny.Editor): void => {
  const editorErrorMessage =
    'Your browser does not have access to the clipboard. Please grant access or use Ctrl+V/Cmd+V keyboard shortcuts to paste contents.';

  try {
    navigator.clipboard
      .read()
      .then((clipboardData) => {
        if (!clipboardData.length) {
          return Promise.reject();
        }

        const clipboardItem = clipboardData[0];
        return clipboardItem.types.includes('text/html')
          ? clipboardItem.getType('text/html')
          : clipboardItem.getType('text/plain');
      })
      .then((blobValue) => blobValue.text())
      .then((clipboardText) => editor.execCommand('mceInsertContent', false, clipboardText))
      .catch((error) => {
        console.error('J3PastePlugin error:', error);
        showEditorError(editor, editorErrorMessage);
      });
  } catch (err) {
    showEditorError(editor, editorErrorMessage);
  }
};

const onCut = (editor: tiny.Editor): void => {
  const editorErrorMessage =
    'Your browser does not have access to the clipboard. Please grant access or use Ctrl+X/Cmd+X keyboard shortcuts to cut contents.';

  try {
    navigator.clipboard
      .writeText(editor.selection.getContent())
      .then(() => editor.execCommand('Cut'))
      .catch((error) => {
        console.error('J3CopyPlugin error:', error);
        showEditorError(editor, editorErrorMessage);
      });
  } catch (err) {
    showEditorError(editor, editorErrorMessage);
  }
};

const initializeCopy = (editor: tiny.Editor): void => {
  const copyDef = {
    tooltip: 'Copy',
    icon: 'copy',
    onAction: () => onCopy(editor),
  };

  editor.ui.registry.addButton('j3Copy', copyDef);
  editor.ui.registry.addMenuItem('j3Copy', {
    ...copyDef,
    text: 'Copy',
    shortcut: 'Meta+C',
  });
};

const initializePaste = (editor: tiny.Editor): void => {
  const pasteDef = {
    tooltip: 'Paste',
    icon: 'paste',
    onAction: () => onPaste(editor),
  };

  editor.ui.registry.addButton('j3Paste', pasteDef);
  editor.ui.registry.addMenuItem('j3Paste', {
    ...pasteDef,
    text: 'Paste',
    shortcut: 'Meta+V',
  });
};

const initializeCut = (editor: tiny.Editor): void => {
  const cutDef = {
    tooltip: 'Cut',
    icon: 'cut',
    onAction: () => onCut(editor),
  };

  editor.ui.registry.addButton('j3Cut', cutDef);
  editor.ui.registry.addMenuItem('j3Cut', {
    ...cutDef,
    text: 'Cut',
    shortcut: 'Meta+X',
  });
};

export const initialize = (editor: tiny.Editor): void => {
  initializeCopy(editor);
  initializePaste(editor);
  initializeCut(editor);
};
