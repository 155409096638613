import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
} from '@angular/core';

import {
  ModuleConfig,
  ModuleOptions,
} from './common';
import { LHSBracketsDataQueryAdapter } from './data-query/lhs-brackets-data-query-adapter.service';
import { AutomationIdDirective } from './directives';

@NgModule({
  declarations: [AutomationIdDirective],
  imports: [CommonModule],
  providers: [LHSBracketsDataQueryAdapter],
  exports: [AutomationIdDirective],
})
export class WaCoreModule {
  static forRoot(opt: ModuleConfig): ModuleWithProviders<WaCoreModule> {
    return {
      ngModule: WaCoreModule,
      providers: [
        {
          provide: ModuleOptions,
          useValue: opt,
        },
      ],
    };
  }
}
