import {
  Component,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { contentBuilderModel } from '../../models';


@Component({
  templateUrl: './row-settings.component.html',
})
export class RowSettingsComponent implements OnInit {
  public fields: FormlyFieldConfig[];
  public form = new FormGroup({});

  public row: contentBuilderModel.ContentRowModel;

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  public ngOnInit(): void {
    this.fields = [
      {
        fieldGroup: [
          {
            type: 'input',
            key: 'cssClass',
            templateOptions: {
              label: 'CSS Class',
            },
          },
        ],
      },
    ] as FormlyFieldConfig[];
  }

  public onCloseDialog(): void {
    this.activeModal.dismiss('dialog_closed');
  }

  public onSaveChanges(): void {
    this.activeModal.close(this.row);
  }
}
