import { Type } from '@angular/core';
import { dateValidators } from '@jotter3/common-helpers';
import { reflectMetadata } from '@jotter3/reflection-core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { isNil } from 'lodash-es';
import * as moment from 'moment-timezone';
import {
  BehaviorSubject,
  Observable,
  of,
} from 'rxjs';

import {
  constant,
  Property,
} from '../decorators';
import { ModifierType } from '../enums';
import { baseComponentHelpers } from '../helpers';
import {
  PropertyMetadataModel,
  SettingsDesignerModel,
  SettingsItem,
  SiteComponentMetadata,
  TemplateComponentMetadata,
} from '../models';


export abstract class BaseBuilderComponent extends OnDestroyMixin {
  public readonly settingsModalActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Property({
    displayName: 'Valid from',
    placeholder: 'YYYY-mm-dd',
    modifierType: ModifierType.DATE_PICKER,
    group: 'General',
    advanced: true,
    validates: {
      validFrom: {
        expression: dateValidators.isDateValid,
        message: 'Invalid date',
      },
    },
  })
    validFrom: Date | undefined;

  @Property({
    displayName: 'Valid to',
    placeholder: 'YYYY-mm-dd',
    modifierType: ModifierType.DATE_PICKER,
    group: 'General',
    advanced: true,
    validates: {
      validFrom: {
        expression: dateValidators.isDateValid,
        message: 'Invalid date',
      },
    },
  })
    validTo: Date | undefined;

  protected constructor(protected type: Type<any>) {
    super();
  }

  public get fromDate(): Date | undefined {
    return this.validFrom;
  }

  public set fromDate(value: Date | undefined) {
    this.validFrom = value;
  }

  public get toDate(): Date | undefined {
    return this.validTo;
  }

  public set toDate(value: Date | undefined) {
    this.validTo = value;
  }

  public getContentPropertiesDescription(): { name: string, metadata: unknown }[] {
    return reflectMetadata.getPropertyMetadata(constant.PROPERTIES_META_KEY, constant.PROPERTY_META_KEY, this.type);
  }

  public mapToSettingsDesigner(): Partial<SettingsDesignerModel> {
    const _this: any = this;
    return {
      componentInstance: this,
      items: this.getContentPropertiesDescription().map<SettingsItem>((el) => ({
        ...el,
        value: _this[el.name],
        metadata: el.metadata as PropertyMetadataModel,
      })),
    };
  }

  public getDataset(): { [key: string]: any } {
    return baseComponentHelpers.getComponentDataset(this, this.type);
  }

  public setDataset(data: { [key: string]: any }): void {
    if (!data) {
      return;
    }
    const _this: any = this;
    for (const key of Object.keys(data)) {
      _this[key] = data[key];
    }
  }

  public onSaveChanges(): Observable<boolean> {
    return of(true);
  }

  public validTime(): boolean {
    const currentDate = moment().toISOString();

    switch (true) {
      case !isNil(this.validFrom) && !isNil(this.validTo):
        return (
          moment(currentDate) > moment(this.validFrom).startOf('day') && moment(this.validTo).endOf('day') > moment(currentDate)
        );
      case !isNil(this.validFrom) && isNil(this.validTo):
        return moment(currentDate) > moment(this.validFrom).startOf('day');
      case isNil(this.validFrom) && !isNil(this.validTo):
        return moment(this.validTo).endOf('day') > moment(currentDate);
      default:
        return true;
    }
  }

  protected getComponentMetadata<T extends SiteComponentMetadata | TemplateComponentMetadata>(metaKey: symbol): T {
    const componentMetadata = reflectMetadata.getComponentMetadata<T>(metaKey, this.type)[0];

    return {
      ...componentMetadata,
      settingsDialogSettings: {
        ...componentMetadata?.settingsDialogSettings,
        advancedSettingsValidators: [
          componentMetadata?.settingsDialogSettings?.advancedSettingsValidators || [],
          [
            {
              name: 'J3BaseContentComponentDatesValidator',
              options: { errorPath: 'validTo' },
            },
          ],
        ].flat(),
      },
    } as T;
  }

  public beforeFormCreated(formFieldConfig: FormlyFieldConfig[]): FormlyFieldConfig[] {
    return formFieldConfig;
  }

  public afterSettingsSaved(): void {}
}
