<div class="mb-3" [class.has-error]="showError">
  <label
    class="form-label"
    *ngIf="to.label && to.hideLabel !== true"
    [attr.for]="id"
  >
    {{ to.label }}
    <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
  </label>
  <div
    class="input-group"
    [ngClass]="{'position-static': key === 'validFrom' || key === 'validTo'}"
  >
    <ng-container
      *ngIf="key === 'validFrom' || key === 'validTo'; else containerBody"
    >
      <input
        class="form-control"
        [formControl]="formControl"
        [placeholder]="to.placeholder"
        [disabled]="to.disabled"
        [class.is-invalid]="showError"
        (click)="d.toggle()"
        ngbDatepicker
        ng-model-options="{timezone: 'utc'}"
        #d="ngbDatepicker"
      />
      <button
        class="btn btn-outline-secondary calendar m-0"
        [disabled]="to.disabled"
        (click)="d.toggle()"
        type="button"
      ></button>
      <button
        class="btn btn-outline-secondary clear m-0"
        [disabled]="to.disabled"
        (click)="reset()"
        type="button"
      ></button>
    </ng-container>
    <ng-template #containerBody>
      <input
        class="form-control"
        [formControl]="formControl"
        [placeholder]="to.placeholder"
        [class.is-invalid]="showError"
        [minDate]="to.minDate"
        (click)="d.toggle()"
        ngbDatepicker
        ng-model-options="{timezone: 'utc'}"
        #d="ngbDatepicker"
        container="body"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar m-0"
          [disabled]="to.disabled"
          (click)="d.toggle()"
          type="button"
        ></button>
      </div>
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary clear m-0"
          [disabled]="to.disabled"
          (click)="reset()"
          type="button"
        ></button>
      </div>
    </ng-template>
  </div>
  <ng-container *ngIf="field.templateOptions.required; else notReq">
    <div class="invalid-feedback" *ngIf="showError" [style.display]="'block'">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  </ng-container>
  <ng-template #notReq>
    <div class="invalid-feedback" [style.display]="'block'">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  </ng-template>
  <div class="form-text" *ngIf="to.description">{{ to.description }}</div>
</div>
