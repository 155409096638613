import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import {
  filter,
  map,
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RouterDomainService extends OnDestroyMixin {
  constructor(private router: Router, private route: ActivatedRoute) {
    super();
  }

  loadrouterData(): Observable<ActivatedRouteSnapshot> {
    return this.router.events.pipe(
      untilComponentDestroyed(this),
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route.snapshot),
      map((routeData) => {
        while (routeData.firstChild) {
          routeData = routeData.firstChild;
        }
        return routeData;
      })
    );
  }
}
