<div
  class="top-swiper-slideshow__data"
  *ngIf="imagesList.length"
  [ngClass]="{
    'slideshow-fixed': parallax?.container,
    'static-images': parallax?.static
  }"
>
  <div class="top-swiper-slideshow__images swiper" *ngIf="imagesList.length">
    <div class="swiper-wrapper">
      <ng-container *ngFor="let slide of imagesList; let index = index">
        <div class="swiper-slide">
          <div
            class="video-wrapper"
            *ngIf="slide.type === 4; else imageTemplate"
          >
            <video
              class="slide-video"
              #video
              [muted]="true"
              [loop]="imagesList.length === 1"
              (ended)="vidEnded()"
              preload="metadata"
            >
              <source [src]="slide.url.mp4.fhd" type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
              <!-- Video Controls -->
            </video>
            <div class="video-controls" id="video-controls">
              <button
                class="video-controls__button video-controls__button--play"
                #playButton
                (click)="playVideo()"
                data-title="Play"
              >
                <span
                  class="material-icons-outlined"
                  *ngIf="playedVideo; else played"
                >
                  pause
                </span>
                <ng-template #played>
                  <span class="material-icons-outlined"> play_arrow </span>
                </ng-template>
              </button>
              <button
                class="video-controls__button video-controls__button--mute"
                #muteButton
                (click)="mute()"
                data-title="Mute"
              >
                <span
                  class="material-icons-outlined"
                  *ngIf="mutedVideo; else muted"
                >
                  volume_off
                </span>
                <ng-template #muted>
                  <span class="material-icons-outlined"> volume_up </span>
                </ng-template>
              </button>
            </div>
          </div>
          <ng-template #imageTemplate>
            <j3-image
              class="slide-item--image"
              [imgSrc]="slide?.url"
              [sourceSize]="pictureSizeList"
            ></j3-image>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="top-swiper-slideshow__captions swiper"
    *ngIf="captionsList.length > 0 && $slideshow?.showCaptions"
  >
    <div class="swiper-wrapper">
      <ng-container *ngFor="let caption of captionsList; let i = index">
        <div class="swiper-slide">
          <ng-container
            *ngIf="currentTheme.compatibilityMode === 2; else CaptionsAsText"
          >
            <div
              class="top-swiper-slideshow__caption--title"
              *ngIf="caption.title"
              [innerHTML]="caption.title | translate"
            ></div>
            <div
              class="todiv-swiper-slideshow__caption--text"
              *ngIf="caption.text"
              [innerHtml]="caption.text | translate"
            ></div>
          </ng-container>
          <ng-template #CaptionsAsText>
            <h3
              class="top-swiper-slideshow__caption--title"
              *ngIf="caption.title"
            >
              {{ caption.title | translate }}
            </h3>
            <p class="top-swiper-slideshow__caption--text" *ngIf="caption.text">
              {{ caption.text | translate }}
            </p>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="top-swiper-slideshow__nav">
  <ng-container *ngIf="captionsList?.length > 1 || imagesList.length > 1">
    <button
      class="top-swiper-slideshow__navigation top-swiper-slideshow__navigation--prev icon-chevron-left"
      *ngIf="$slideshow?.showArrows"
      (click)="prevSlide()"
      type="button"
    ></button>
    <button
      class="top-swiper-slideshow__navigation top-swiper-slideshow__navigation--next icon-chevron-right"
      *ngIf="$slideshow?.showArrows"
      (click)="nextSlide()"
      type="button"
    ></button>
    <div
      class="swiper-pagination top-swiper-slideshow__pagination"
      *ngIf="$slideshow?.showPoints"
    ></div>
  </ng-container>
</div>
