import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import * as fromPermissions from '../reducers/permissions.reducers';

export const selectPermissionsState = createFeatureSelector<fromPermissions.PermissionsState>('permissions');

export const permissionsStateSelector = createSelector(selectPermissionsState, (state) => state);

export const permissionsSelector = createSelector(permissionsStateSelector, (state) => {
  const entities = Object.values(state.entities);
  return entities;
});

export const arePermissionsLoading = createSelector(permissionsStateSelector, (state) => state.isLoading);

export const arePermissionsLoaded = createSelector(permissionsStateSelector, (state) => state.isLoaded);
export const selectPermissions = createSelector(
  selectPermissionsState,
  state => Object.values(state.entities ?? {})
);
