import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { UserMyProfileDomainModel } from '../../../domain-models';
import * as userActions from '../actions';

interface UserProfileState extends EntityState<UserMyProfileDomainModel> {
  isLoading: boolean;
  isLoaded: boolean;
  isAuthorized: boolean;
}

const userMyProfileAdapter: EntityAdapter<UserMyProfileDomainModel> = createEntityAdapter<UserMyProfileDomainModel>({
  sortComparer: false,
});

const initialUserProfileState: UserProfileState = userMyProfileAdapter.getInitialState({
  isLoaded: false,
  isLoading: true,
  isAuthorized: false,
});

const usersReturn = createReducer<UserProfileState>(
  initialUserProfileState,
  on(userActions.LoadUserMyProfileData, (state): UserProfileState => ({
    ...state,
    isLoading: true,
  })),
  on(userActions.LoadUserMyProfileDataComplete, (state, { userData }) =>
    userMyProfileAdapter.addOne(userData, {
      ...state,
      isLoading: false,
      isLoaded: true,
    })),
  on(userActions.LoadUserMyProfileDataFailed, (state): UserProfileState => ({
    ...state,
    isLoaded: false,
    isLoading: false,
  })),
  on(userActions.SetUserAuthorized, (state, payload): UserProfileState => ({
    ...state,
    isAuthorized: payload.isAuthorized,
  }))
);

const userMyProfileReducer = (state: UserProfileState = initialUserProfileState, action: Action): UserProfileState =>
  usersReturn(state, action);

export { initialUserProfileState,
  userMyProfileAdapter,
  userMyProfileReducer,
  UserProfileState };
