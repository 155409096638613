import {
  inject,
  Injectable,
} from '@angular/core';
import { ApiService } from '@jotter3/wa-core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  of,
  switchMap,
} from 'rxjs';
import { urlJoin } from 'url-join-ts';

import { ModuleOptionsInjectionToken } from '../../common';
import { SitesSettingsDomainModel } from '../../domain-models';
import { siteStoreSelectors } from '../site';
import { SiteSettingsStoreActions } from './site-settings.actions';

@Injectable()
export class SiteSettingsEffects {
  readonly #actions$ = inject(Actions);
  readonly #store: Store = inject(Store);
  readonly #apiService: ApiService = inject(ApiService);
  readonly #environments = inject(ModuleOptionsInjectionToken);

  loadSiteSettingsRequested$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(SiteSettingsStoreActions.loadTrigger),
      switchMap(({ siteId }) => this.#apiService.loadSingle<SitesSettingsDomainModel>(urlJoin('sites', siteId, 'settings'), undefined)),
      map((response) => response.success
        ? SiteSettingsStoreActions.loadSuccess({ entity: response.result })
        : SiteSettingsStoreActions.loadFailed({ errors: [response.error] }),
      catchError(err => of(SiteSettingsStoreActions.loadFailed({ errors: [err] }))))
    ));

  updateSiteSettingsRequested = createEffect(() => this.#actions$.pipe(
    ofType(SiteSettingsStoreActions.updateTrigger),
    concatLatestFrom(() => this.#store.select(siteStoreSelectors.selectSiteId)),
    switchMap(([
      { entity },
      siteId,
    ]) => this.#apiService.save<SitesSettingsDomainModel>(urlJoin('sites', siteId, 'settings'), entity)),
    map(response => SiteSettingsStoreActions.updateSuccess({ entity: response.result })),
    catchError(err => of(SiteSettingsStoreActions.updateFailed({ errors: [err] })))
  ));
}
