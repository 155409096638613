import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { generalSettingsReducers } from '../reducers';

export const selectSettingsState = createFeatureSelector<generalSettingsReducers.SettingsState>('settings');

export const generalSettingsSelector = createSelector(selectSettingsState, (state) => state.general);

export const areGeneralSettingsLoading = createSelector(generalSettingsSelector, (state) => state.isLoading);

export const areGeneralSettingsLoaded = createSelector(generalSettingsSelector, (state) => state.isLoaded);
