/* eslint-disable @typescript-eslint/naming-convention */
export enum contentType {
  pdf = 'application/pdf',
  pub = 'application/x-mspublisher',
  csv = 'text/csv',
  pubx = 'application/x-mspublisher',
  doc = 'application/msword',
  dot = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xla = 'application/msexcel',
  xls = 'application/msexcel',
  pps = 'application/vnd.ms-powerpoint',
  ppt = 'application/vnd.ms-powerpoint',
  ppa = 'application/vnd.ms-powerpoint',
  ppz = 'application/vnd.ms-powerpoint',
  pot = 'application/vnd.ms-powerpoint',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  odt = 'application/vnd.oasis.opendocument.text',
  ods = 'application/vnd.oasis.opendocument.spreadsheet',
  odp = 'application/vnd.oasis.opendocument.presentation',
  odg = 'application/vnd.oasis.opendocument.graphics',
  txt = 'text/plain',
  html = 'text/html',
  htm = 'text/html',
  xml = 'text/xml',
  rtf = 'text/rtf',
  bmp = 'image/bmp',
  gif = 'image/gif',
  ico = 'image/x-icon',
  fh4 = 'image/x-freehand',
  fh5 = 'image/x-freehand',
  fhc = 'image/x-freehand',
  jpe = 'image/jpeg',
  jpeg = 'image/jpeg',
  jpg = 'image/jpeg',
  png = 'image/png',
  tiff = 'image/tiff',
  tif = 'image/tiff',
  jfif = 'image/jpeg',
  svg = 'image/svg+xml',
  heic = 'image/heic',
  heif = 'image/heif',
  webp = 'image/webp',
  mp3 = 'audio/mpeg',
  m3u = 'audio/x-mpequrl',
  ra = 'audio/x-realaudio',
  ram = 'audio/x-pn-realaudio',
  rm = 'audio/x-pn-realaudio',
  stream = 'audio/x-qt-stream',
  wav = 'audio/x-wav',
  flac = 'audio/x-flac',
  ogg = 'audio/ogg',
  midi = 'audio/midi',
  mid = 'audio/midi',
  wma = 'audio/wma',
  m4a = 'audio/x-m4a',
  avi = 'video/x-msvideo',
  flv = 'video/x-flv',
  mkv = 'video/x-matroska',
  mov = 'video/quicktime',
  mpeg = 'video/mpeg',
  mpg = 'video/mpeg',
  mp2 = 'video/mpeg',
  mp4 = 'video/mp4',
  qt = 'video/quicktime',
  vob = 'video/x-mpg',
  wmv = 'video/x-ms-wmv',
  zip = 'application/zip',
  x7z = 'application/x-7z-compressed',
  tar = 'application/x-tar',
  rar = 'application/vnd.rar',
  gz = 'application/gzip'
}
