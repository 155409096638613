import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  domainModels,
  enums,
  FileSourceModel,
} from '@jotter3/api-connector';
import {
  InjectorHelperService,
  J3TranslateService,
} from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import {
  ApiResponse,
  ApiService,
  dataQuery,
} from '@jotter3/wa-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { isEqual } from 'lodash-es';
import {
  NgxMasonryComponent,
  NgxMasonryOptions,
} from 'ngx-masonry';
import {
  Observable,
  of,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import { GalleryModel } from '../../models/gallery.model';
import { ToggleContentService } from '../../services';
import { IMAGE_SIZES_OPTIONLIST_SOURCE } from '../image-element/image-element.consts';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';
import { GalleriesPreviewComponent } from './preview/galleries-preview.component';

enum Views {
  SPACE_BETWEEN = 'between',
  JUSTIFY_LEFT = 'left',
  JUSTIFY_CENTER = 'center',
  JUSTIFY_RIGHT = 'right',
  MASONRY = 'masonry'
}

@SiteComponent({
  selector: 'galleries-component',
  displayName: 'Gallery',
  icon: 'gallery',
  category: SiteComponentCategory.MODULES,
  showSettingsAfterAdd: true,
  roles: ['ROLE_GALLERIES_VIEWALL'],
})
@Component({
  templateUrl: './galleries.component.html',
  styleUrls: ['./galleries.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class GalleriesSiteElementComponent extends JotterSitesBaseComponent implements AfterViewInit {
  noGalleries = false;
  galleriesList: GalleryModel[];
  selectedGalleryValue: string = null;
  private loadingValue: Observable<boolean> = of(true);
  private selectedGroupsValue: string[] = [];

  @ViewChild('masonry', { read: ViewContainerRef }) templateRef: ViewContainerRef;

  source: domainModels.GalleryDomainModel;
  view: Views = Views.JUSTIFY_CENTER;

  isActive = false;
  elementId: string = UUID();
  itemPosition = '0px';
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  options: NgxMasonryOptions = {
    gutter: 10,
    fitWidth: true,
    columnWidth: 240,
  };

  constructor(
    private apiService: ApiService,
    private modal: NgbModal,
    private injectorHelper: InjectorHelperService,
    private toggleContent: ToggleContentService
  ) {
    super(GalleriesSiteElementComponent);
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
    required: false,
  })
    header = '';

  @Property({
    displayName: 'Gallery',
    modifierType: ModifierType.J3_API_DROPDOWN,
    resourceName: 'galleries',
    labelProp: 'name',
    valueProp: 'id',
    constDataQueryFilters: [
      {
        type: dataQuery.FilterType.DATE,
        operator: dataQuery.Operator.LTE as any,
        property: 'publishDate',
        value: new Date().toISOString(),
      },
      {
        type: dataQuery.FilterType.DATE,
        operator: dataQuery.Operator.GTE as any,
        property: 'publishUntil',
        value: new Date().toISOString(),
      },
    ],
    templateOptions: {
      refreshList: true,
      addNewItem: () => GalleriesSiteElementComponent.addNewGallery(),
    },

  })
  get selectedGallery(): string {
    return this.selectedGalleryValue;
  }

  set selectedGallery(value: string) {
    if (isEqual(this.selectedGalleryValue, value)) {
      return;
    }
    this.selectedGalleryValue = value;

    if (this.viewInitialized) {
      this.getFromApi();
    }
  }

  @Property({
    displayName: 'Image Resolution',
    modifierType: ModifierType.IMAGE_RESOLUTION,
    optionsList: IMAGE_SIZES_OPTIONLIST_SOURCE,
    required: true,
  })
  public imageSize = enums.PicturesSizes.M;

  public static addNewGallery(): void {
    window.open('/admin/galleries?edit_id=none', '_blank');
  }

  @Property({
    displayName: 'View',
    modifierType: ModifierType.DROPDOWN,
    template: 'vaIcons',
    dropdownSource: [
      {
        value: Views.JUSTIFY_LEFT,
        label: 'Left',
        icon: 'align_left',
      },
      {
        value: Views.JUSTIFY_CENTER,
        label: 'Center',
        icon: 'align_center',
      },
      {
        value: Views.JUSTIFY_RIGHT,
        label: 'Right',
        icon: 'align_right',
      },
      {
        value: Views.MASONRY,
        label: 'Masonry',
        icon: 'dashboard_layout',
      },
    ],
    defaultValue: Views.JUSTIFY_CENTER,
  })
  get selectedView(): Views {
    return this.view;
  }

  set selectedView(value: Views) {
    this.view = value;
  }

  @Property({
    displayName: 'Audience Targeting',
    modifierType: ModifierType.J3_API_MULTIDROPDOWN,
    resourceName: 'galleries/available_groups',
    valueProp: 'id',
    labelProp: 'name',
    group: 'General',
    advanced: true,
    placeholder: 'Choose groups...',
  })
  public get selectedGroups(): string[] {
    return this.selectedGroupsValue;
  }

  public set selectedGroups(value: string[]) {
    if (isEqual(this.selectedGroupsValue, value)) {
      return;
    }
    this.selectedGroupsValue = value;
  }

  public get loading$(): Observable<boolean> {
    return this.loadingValue;
  }

  public override ngAfterViewInit(): void {
    this.getFromApi();
    super.ngAfterViewInit();
  }

  public openPreview(item: { id: any, url: string }): void {
    this.modal.open(GalleriesPreviewComponent, {
      size: 'xl',
      injector: this.injectorHelper.provideModalData({
        gallery: (this.source.attachments as domainModels.IFileDomainModel[]).map((el) => el.url.m),
        index: this.source.attachments.findIndex((obj) => (obj as domainModels.IFileDomainModel).id === item.id),
        title: this.source.name,
        url: (this.source.attachments as domainModels.IFileDomainModel[]).map((el) => el.url),
      }),
    });
  }

  public updateMasonry(): void {
    if (!this.masonry) {
      return;
    }
    this.masonry.layout();
  }

  public toggleItem(status: boolean, id: string): void {
    this.isActive = status;
    this.itemPosition = this.toggleContent.toggleContent(status, id);
  }

  public animationOverflow(status: boolean, id: string): void {
    this.toggleContent.toggleOverflow(status, id);
  }

  public getImageFromAttachment(source: FileSourceModel): string {
    const imageSource = source.webp ?? source;

    return imageSource[this.imageSize];
  }


  private getFromApi(): void {
    const query: dataQuery.DataQuery = {
      filters: [
        {
          type: dataQuery.FilterType.DATE,
          operator: dataQuery.Operator.LTE as any,
          property: 'publishDate',
          value: new Date().toISOString(),
        },
        {
          type: dataQuery.FilterType.DATE,
          operator: dataQuery.Operator.GTE as any,
          property: 'publishUntil',
          value: new Date().toISOString(),
        },
        {
          type: dataQuery.FilterType.NUMERIC,
          operator: dataQuery.Operator.EMPTY,
          property: 'deletedStatus',
          value: 0,
        },
      ],
    };

    if (this.selectedGallery) {
      this.apiService
        .loadSingle<domainModels.GalleryDomainModel>('galleries', this.selectedGallery)
        .pipe(
          untilComponentDestroyed(this),
          filter((res: ApiResponse<domainModels.GalleryDomainModel>) => !res?.result?.deletedStatus)
        )
        .subscribe(({ result }) => {
          this.source = result;
          if (!result) {
            return;
          }
          this.updateMasonry();
        });
    }
  }
}
