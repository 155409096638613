import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';

import { PermissionDomainService } from '../../../domain-services';
import * as permissionsActions from '../actions';

@Injectable()
export class PermissionsEffects {
  loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(permissionsActions.LoadPermissions),
      switchMap(() =>
        this.permissionDomainService.getAllPermissions().pipe(
          map((permissions) => permissionsActions.LoadPermissionsComplete({ permissions })),
          catchError((error) => {
            console.log(error);
            return of(permissionsActions.LoadPermissionsFailed(error));
          })
        ))
    ));

  constructor(private actions$: Actions, private permissionDomainService: PermissionDomainService) {}
}
