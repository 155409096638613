export const NewsletterSubscriptionConst = {
  elementSelector: 'newsletter-subscription-component',
  hostCssClass: 'newsletter-subscription',
  header: 'Letter Subscription',
  description: 'Subscribe to our letters using the form below.',
  formInputs: {
    name: {
      label: 'Name',
      placeholder: 'Enter Name',
    },
    email: {
      label: 'Email',
      placeholder: 'Enter Email',
    },
  },
  buttons: {
    submit: 'Submit',
  },
  alerts: {
    success: 'You are now subscribed',
    failed: 'Save failed!',
  },
};
