import { LoadingStateEnum } from '@jotter3/store-helpers';
import {
  createFeature,
  createReducer,
  on,
} from '@ngrx/store';

import { TenantInfoDomainModel } from '../../domain-models';
import { BaseStateModel } from '../common';
import { AppStoreActions } from './app.actions';

export interface AppState extends BaseStateModel {
  domain: string | undefined;
  tenant: TenantInfoDomainModel | undefined;
  selectedLanguage: string | undefined;
  toast: {
    title: string,
    description: string,
  } | undefined;
}

const initialState: AppState = {
  loadingStatus: LoadingStateEnum.PENDING,
  domain: undefined,
  tenant: undefined,
  selectedLanguage: undefined,
  toast: undefined,
};

export const appStoreFeature = createFeature({
  name: 'appStore',
  reducer: createReducer(
    initialState,
    on(AppStoreActions.entered, (state: AppState, { domain, tenant }): AppState => {

      if (!!state?.tenant?.id && state?.tenant.id !== tenant.id) {
        console.warn(`TENANT CHANGED: from ${state.tenant.id} to ${tenant.id}`);
        console.warn(`TENANT CHANGED: from ${state.tenant.domain} to ${tenant.domain}`);
      }

      return {
        ...
        state,
        domain,
        tenant,
        loadingStatus: LoadingStateEnum.SUCCESS,
      };
    }),
    on(AppStoreActions.showToastError, (state: AppState, { title, description }): AppState => ({
      ...state,
      toast: {
        title: title,
        description: description,
      },
    }))
  ),
});

