import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { PoliciesState } from '../reducers';

export const selectPoliciesListState = createFeatureSelector<PoliciesState>('policies');

export const selectPoliciesListStateSelector = createSelector(selectPoliciesListState, (state) => state);

export const selectPoliciesListSelector = createSelector(selectPoliciesListStateSelector, (state) => {
  const entities = Object.values(state.entities);
  return entities;
});

export const selectArePoliciesListLoading = createSelector(selectPoliciesListState, (state) => state.isLoading);

export const selectArePoliciesListLoaded = createSelector(selectPoliciesListState, (state) => state.isLoaded);
