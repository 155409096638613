import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SiteEffects } from './site.effects';
import { siteStoreFeature } from './site.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(siteStoreFeature),
    EffectsModule.forFeature([SiteEffects]),
  ],
})
export class SiteStoreModule {

}
