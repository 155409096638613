import { Injectable } from '@angular/core';
import { ApiService } from '@jotter3/wa-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionDomainModel } from '../domain-models';
import { PermissionRole } from '../enums';
import { BaserDomainService } from './base.domain-service';

@Injectable({ providedIn: 'root' })
export class PermissionDomainService extends BaserDomainService<PermissionDomainModel> {
  constructor(apiService: ApiService) {
    super('permissions', apiService);
  }

  public getAllPermissions(): Observable<PermissionDomainModel[]> {
    return this.apiService.load<any>(this.resourceName).pipe(
      map<any, PermissionDomainModel[]>(({ result }) =>
        (result as string[]).map((key, idx) => ({
          id: `${idx}`,
          value: key as PermissionRole,
        })))
    );
  }
}
