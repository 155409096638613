import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { J3TranslateService } from '@jotter3/common-helpers';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import {
  filter,
  map,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import { ItemType } from '../../enums';
import { IAddressModel } from '../../models';
import { TemplateDataProvider } from '../../providers';
import { ToggleItemsService } from '../../services/toggle-items.service';

@TemplateComponent({
  selector: 'jotter-tplcmp-schooladdress',
  displayName: 'School address',
  icon: 'settings',
  defaultClass: 'school-data-address',
})
@Component({
  selector: 'jotter-tplcmp-schooladdress',
  templateUrl: './school-address.component.html',
  animations: [
    trigger('toggle', [
      state(
        'open',
        style({
          opacity: '1',
          height: '*',
        })
      ),
      state(
        'closed',
        style({
          opacity: '0',
          height: '0',
          overflow: 'hidden',
          paddingTop: 0,
          paddingBottom: 0,
        })
      ),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
    trigger('slide', [
      state(
        'open',
        style({
          left: '0',
          opacity: '1',
        })
      ),
      state(
        'closed',
        style({
          left: '-100%',
          opacity: '0',
        })
      ),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
    trigger('width', [
      state(
        'open',
        style({
          width: '*',
          whiteSpace: 'normal',
        })
      ),
      state(
        'closed',
        style({
          width: '0',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        })
      ),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class SchoolAddressComponent extends TemplateBaseComponent implements OnInit {
  $schoolAddress: Observable<IAddressModel | undefined> | undefined;
  isVisible = false;
  public ItemTypeEnum = ItemType;
  public valueMoved = false;
  public itemId = UUID();
  public animationReady = false;

  @Input() iconValue = 'icon-checkin';
  @Input() iconCustomClass = '';
  @Input() itemType: string = ItemType.STANDARD;
  @Input() moveToBody = false;
  @Input() moveTo = '';

  constructor(private dataProvider: TemplateDataProvider, private eRef: ElementRef, private toggleService: ToggleItemsService) {
    super(SchoolAddressComponent);
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  offClickHandler(event: any): void {
    if (!event.target.classList?.contains('school-data-icon-toggle')) {
      return;
    }
    if (
      (event.target.parentNode.classList?.contains('school-data-icon-toggle') ||
        event.target.classList?.contains('school-data-icon-toggle')) &&
      !this.eRef.nativeElement.contains(event.target) &&
      this.isVisible &&
      (this.itemType === ItemType.TOGGLE || this.itemType === ItemType.TOGGLE_OUTSIDE || this.itemType === ItemType.SLIDE)
    ) {
      this.isVisible = false;
    }
  }

  toggle(): void {
    if (this.itemType === ItemType.TOGGLE_OUTSIDE || (this.itemType === ItemType.TOGGLE && this.moveToBody && !this.valueMoved)) {
      const bodyElement = document.getElementsByTagName('body')[0];
      const valueElement = document.getElementById(`school-data-address-${this.itemId}`);

      if (valueElement) {
        bodyElement?.prepend(valueElement);
        this.valueMoved = true;
      }
    }
    if (this.itemType === ItemType.TOGGLE && this.moveTo) {
      const valueElement = document.getElementById(`school-data-address-${this.itemId}`);

      if (valueElement) {
        document.getElementsByClassName(this.moveTo)[0]?.prepend(valueElement);
        this.valueMoved = true;
      }
    }
    this.toggleService.onToggle(SchoolAddressComponent);
    if (
      this.itemType === ItemType.TOGGLE ||
      this.itemType === ItemType.TOGGLE_OUTSIDE ||
      this.itemType === ItemType.SLIDE ||
      this.itemType === ItemType.WIDTH
    ) {
      this.isVisible = !this.isVisible;
      this.animationReady = !this.animationReady ? true : this.animationReady;
    }
  }

  ngOnInit(): void {
    this.toggleService.toggleEvent
      .pipe(
        untilComponentDestroyed(this),
        filter((res) => res !== SchoolAddressComponent)
      )
      .subscribe(() => {
        this.isVisible = false;
      });

    if (
      this.itemType === ItemType.TOGGLE ||
      this.itemType === ItemType.TOGGLE_OUTSIDE ||
      this.itemType === ItemType.SLIDE ||
      this.itemType === ItemType.WIDTH
    ) {
      this.isVisible = false;
    }

    this.$schoolAddress = this.dataProvider.getData().pipe(map((res) => res.address));
  }

  public animationDone(): void {
    if (!this.isVisible) {
      this.animationReady = false;
    }
  }
}
