import {
  createAction,
  props,
} from '@ngrx/store';

export const LoadLoaderStatus = createAction('[Loader] Loader status');
export const LoadLoaderStatusComplete = createAction('[Loader] Loader status Success', props<{ loaderStatus: any }>());
export const LoadLoaderStatusFailed = createAction('[Loader] Loader status Failed', props<{ error: any }>());

export const SetLoaderVisibility = createAction('[Loader] Set Loader visibility', props<{ isVisible: boolean }>());
