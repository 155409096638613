import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { gdprSettingsReducers } from '../reducers';

export const selectGdprState = createFeatureSelector<gdprSettingsReducers.GdprSettingsState>('gdpr');
export const gdprStateSelector = createSelector(selectGdprState, (state) => state);
export const gdprSettingsSelector = createSelector(gdprStateSelector, (state) => state);
export const areGdprSettingsLoading = createSelector(gdprStateSelector, (state) => state.isLoading);
export const areGdprSettingsLoaded = createSelector(gdprStateSelector, (state) => state.isLoaded);
export const cookieHasBeenShown = createSelector(gdprStateSelector, (state) => state.cookieHasBeenShow);
export const cookieAccepted = createSelector(gdprStateSelector, (state) => state.cookieAccepted);
