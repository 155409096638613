import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SectionSizeMode } from '../enums';
import { contentBuilderModel } from '../models';
import {
  ContentBuilderApi,
  ContentItemModel,
  ContentSectionModel,
} from '../models/content-builder.model';

@Injectable({ providedIn: 'root' })
export class ContentBuilderManagerService implements ContentBuilderApi {
  addElement: (item: ContentItemModel, index: number) => void;
  removeElement: (item: ContentItemModel) => void;
  addRow: (addBefore?: boolean) => void;
  removeRow: () => void;
  addSection: (addLeft?: boolean) => void;
  changeSectionSize: (mode: SectionSizeMode) => void;
  removeSection: () => void;
  setSectionSiteAuto: () => void;
  selectedSection?: ContentSectionModel;
  rows?: contentBuilderModel.ContentRowModel[];
  designMode = false;
  index?: number;
  beforeSaveChanges: () => Observable<boolean>;
  contentBlocked: () => Observable<boolean>;
  onNavigateToPolicy: () => void;
  acceptCookies: () => void;
  reset: () => void;
}
