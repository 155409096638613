<header class="newsletter-subscription__header">
  <h3>{{ header | translate }}</h3>
  <p>{{ description | translate }}</p>
</header>
<section class="newsletter-subscription__content">
  <form
    [formGroup]="formGroup"
    (submit)="handleOnSubmit()"
    class="newsletter-subscription__form"
  >
    <ngb-alert
      *ngIf="hasError$ | async"
      [dismissible]="false"
      [type]="'danger'"
      class="newsletter-subscription__form_alert my-2 d-flex flex-column"
    >
      <strong>{{ alertText.failed | translate }}</strong>
      <small class="mt-2">{{ (error$ | async).title | translate }}</small>
    </ngb-alert>

    <ngb-alert
      *ngIf="loadedSuccess$ | async"
      [dismissible]="false"
      [type]="'success'"
      class="newsletter-subscription__form_alert my-2"
    >
      <strong>{{ alertText.success | translate }}</strong>
    </ngb-alert>

    <formly-form
      *ngIf="(loadedSuccess$ | async) === false"
      [fields]="formlyFieldConfig$ | async"
      [form]="formGroup"
      [options]="formOptions"
      class="newsletter-subscription__form__content"
    >
    </formly-form>
    <div class="form_buttons d-flex justify-content-center">
      <button
        [disabled]="!formGroup.valid || (isLoading$ | async) || designMode"
        type="submit"
        class="btn btn-primary"
      >
        {{ submitButtonText | translate }}
        <span
          *ngIf="isLoading$ | async"
          class="spinner-border spinner-border-sm mx-2"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </div>
  </form>
</section>
