import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Inject,
  inject,
  Injectable,
  Optional,
} from '@angular/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';

@Injectable({ providedIn: 'root' })
export class FileDownloadService extends OnDestroyMixin {
  readonly #httpClient: HttpClient = inject(HttpClient);
  readonly #document = inject(DOCUMENT);

  constructor(@Optional() @Inject(DOCUMENT) private document: any) {
    super();
  }

  public onDownloadFile(name: string, url: string): void {
    this.#httpClient
      .get(url, {
        responseType: 'blob',
        headers: {
          Anonymous: 'true',
          XTenantIgnore: 'true',
        },
      })
      .pipe(untilComponentDestroyed(this))
      .subscribe((blob) => {
        const a = this.#document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = name;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

}
