import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'j3-image-caption',
  templateUrl: './image-caption.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCaptionComponent {
  @Input() captionTitle: string;
  @Input() captionText: string;
  @Input() align: string;
}

