import { LoadingStateEnum } from '@jotter3/store-helpers';
import { createSelector } from '@ngrx/store';

import { TemplateDefinitionDomainModel } from '../../domain-models';
import { templatesStoreFeature } from './templates.reducer';

const {
  selectLoadingStatus,
  selectEntities: selectStateEntities,
  selectErrors,
} = templatesStoreFeature;

const selectIsLoading = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.LOADING
);

const selectIsLoaded = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.SUCCESS || loadingStatus === LoadingStateEnum.FAILED
);

const selectEntities = createSelector(selectStateEntities, entities => Object.values(entities));

const selectEntitiesWithVariants = createSelector(selectEntities, entities =>
  entities .map((template) => {
    const res: TemplateDefinitionDomainModel[] = [];
    const { variants, ...rest } = template;

    for (const variant of variants) {
      res.push({
        ...rest,
        name: variant.name ? `${rest.name} (${variant.name})` : rest.name,
        styles: [variant.path],
      });
    }

    return res;
  }).flat());

export const templatesSelectors = {
  selectEntities,
  selectEntitiesWithVariants,
  selectErrors,
  selectIsLoaded,
  selectIsLoading,
  selectLoadingStatus,
};
