export const DEFAULTS = {
  VISIBLE_SLIDES: 1,
  SHOW_INDICATORS: false,
  SHOW_ARROWS: false,
  ANIMATION_DELAY: 5000,
  SPEED: 1000,
  HEIGHT: 110,
  LOOP: true,
};

export enum ItemType {
  STANDARD = 'standard',
  TOGGLE = 'toggle'
}


export interface Grid {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export const defaultLayout: Grid = {
  xs: 2,
  sm: 3,
  md: 4,
  lg: 6,
  xl: 6,
};

export const defaultSpaceBetween: Grid = {
  xs: 5,
  sm: 10,
  md: 10,
  lg: 10,
  xl: 10,
};
