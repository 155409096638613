<div
  [class.has-error]="showError"
  class="form-group"
>
  <label
    *ngIf="to.label && to.hideLabel !== true"
    [attr.for]="id"
  >
    {{ to.label }}
    <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
  </label>
  <ngb-timepicker [formControl]="formControl"></ngb-timepicker>
  <div
    *ngIf="showError"
    [style.display]="'block'"
    class="invalid-feedback"
  >
    <formly-validation-message [field]="field"></formly-validation-message>
  </div>
  <div
    *ngIf="to.description"
    class="form-text text-muted"
  >
    {{ to.description }}
  </div>
</div>
