import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApiService,
} from '@jotter3/wa-core';
import { Store } from '@ngrx/store';
import {
  Observable,
  throwError,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { UserProfileDomainModel } from '../domain-models';
import { BaserDomainService } from './base.domain-service';

@Injectable({ providedIn: 'root' })
export class PeopleListDomainService extends BaserDomainService<UserProfileDomainModel> {
  constructor(apiService: ApiService, private store: Store) {
    super('groups', apiService);
  }

  public getProfilesNotBelongToTheGroup(
    queryParams?: HttpParams,
    group?: string
  ): Observable<ApiResponse<UserProfileDomainModel[]>> {
    return this.apiService.load<UserProfileDomainModel>(`groups/${group}/profilesNotBelongToTheGroup`, queryParams).pipe(
      map((apiResponse) => {
        const { success, error } = apiResponse;
        if (!success) {
          throwError(error);
        }
        return apiResponse;
      })
    );
  }
}
