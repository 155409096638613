import {
  Component,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  AttachmentModel,
  NGB_MODAL_DATA,
  StoryDomainModel,
} from '@jotter3/api-connector';
import { FileDownloadService } from '@jotter3/common-helpers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-news-preview',
  templateUrl: './news-preview.component.html',
  styleUrls: ['./news-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewsPreviewComponent extends OnDestroyMixin {
  readonly #activeModal: NgbActiveModal = inject(NgbActiveModal);
  readonly #fileDownloadService: FileDownloadService = inject(FileDownloadService);
  readonly news: Partial<StoryDomainModel> = inject<Partial<StoryDomainModel>>(NGB_MODAL_DATA);

  public onCloseDialog(): void {
    this.#activeModal.close(false);
  }

  public handleOnDownload(attachment: AttachmentModel): void {
    const { name, url } = attachment;

    this.#fileDownloadService.onDownloadFile(name, url);
  }
}
