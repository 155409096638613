import {
  DOCUMENT,
  isPlatformBrowser,
} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
} from '@angular/router';
import {
  domainModels,
  enums,
  pageContentState,
  siteStoreSelectors,
  templatesSelectors,
} from '@jotter3/api-connector';
import {
  AppType,
  J3TranslateService,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  isEqual,
  isNil,
} from 'lodash-es';
import {
  debounceTime,
  filter,
  fromEvent,
  Observable,
  Subscription,
} from 'rxjs';
import { map } from 'rxjs/operators';
import SwiperCore, {
  A11y,
  Autoplay,
  Controller,
  EffectFade,
  Lazy,
  Navigation,
  Pagination,
  Scrollbar,
  Swiper,
  SwiperOptions,
  Thumbs,
  Virtual,
  Zoom,
} from 'swiper';
import { PaginationOptions } from 'swiper/types';
import { v4 as UUID } from 'uuid';

import { TemplateDataProvider } from '../../providers';

export interface SlideshowCaptions {
  title?: string;
  text?: string;
}

export interface ParallaxData {
  container: string;
  exclude: string[];
  static: boolean;
}

enum animationType {
  FADE = 0,
  SCROLL = 1
}

enum FileTypeNumbers {
  VIDEO = 4
}

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
  EffectFade,
  Lazy,
]);

@TemplateComponent({
  selector: 'jotter-tplcmp-slideshow',
  displayName: 'Slide show',
  icon: 'settings',
  defaultClass: 'top-swiper-slideshow',
})
@Component({
  selector: 'jotter-tplcmp-slideshow',
  styleUrls: ['./slideshow.component.scss'],
  templateUrl: './slideshow.component.html',
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SlideshowComponent extends TemplateBaseComponent implements AfterViewInit, OnInit, OnDestroy {
  readonly #windowResize$: Observable<Event> = fromEvent(window, 'resize');
  @Input() slideshowCustomClass = '';
  @Input() pagination = 'bullet';
  @Input() parallax: ParallaxData | undefined;
  @Input() slidesHeightReduce: string[] = [];
  @ViewChild('video') video?: ElementRef;
  @ViewChild('playButton') playButton?: ElementRef;
  @ViewChild('muteButton') muteButton?: ElementRef;

  public itemId: string = UUID();
  public mutedVideo = true;
  public playedVideo = false;
  public $slideshow: Partial<domainModels.SlideshowDomainModel> = {};
  public captionsList: SlideshowCaptions[] = [];
  public imagesList: domainModels.IFileDomainModel[] = [];
  private defaultSlidesOptions: SwiperOptions = {};
  public isHomePage = false;
  private imagesConfigValues: SwiperOptions | undefined;
  private captionsConfigValues: SwiperOptions | undefined;
  public slideshowCaptions: boolean | undefined;
  public animationDelayDefaultValue = 7000;
  private animationSpeedDefaultValue = 2000;
  private sliderImages: Swiper | undefined;
  private sliderCaptions: Swiper | undefined;
  public elementId: string = UUID();
  private slideshowPublicationMode = enums.SlideshowPublicationMode;
  private dataProviderSubscription: Subscription | undefined;
  private pagesListSubscription: Subscription | undefined;
  public pictureSizeList = Object.values(enums.PicturesSizes);
  public currentTheme: domainModels.TemplateDefinitionDomainModel;

  constructor(
    private dataProvider: TemplateDataProvider,
    public activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private store: Store,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    @Inject(MODULE_PROVIDER_TOKEN) private moduleProvider: ModuleProvider
  ) {
    super(SlideshowComponent);
  }

  public ngOnInit(): void {
    this.defaultSlidesOptions = {};
    if (!isPlatformBrowser(this.platformId) || this.moduleProvider.applicationType === AppType.CLIENT) {
      return;
    }

    this.router.events
      .pipe(
        untilComponentDestroyed(this),
        filter((x) => x instanceof NavigationEnd && isPlatformBrowser(this.platformId))
      )
      .subscribe(() => this.checkSlideshow());

    this.#windowResize$.pipe(
      untilComponentDestroyed(this),
      filter(() => isPlatformBrowser(this.platformId) && !!this.parallax),
      debounceTime(500)
    ).subscribe(() => this.heightReduce(this.parallax));
  }

  private heightReduce(data: ParallaxData): void {
    let excludedItems = 0;
    if (data?.exclude?.length) {
      data.exclude.forEach((element) => {
        excludedItems += this.document.getElementsByClassName(element)[0].getBoundingClientRect().height;
      });
    }
    const mainHeader = this.document.getElementsByClassName('main-header')[0];
    const toolbarWrapper = this.document.getElementsByClassName('toolbar-wrapper')[0];
    excludedItems += mainHeader ? mainHeader.getBoundingClientRect().height : 0;
    excludedItems += toolbarWrapper ? toolbarWrapper.getBoundingClientRect().height : 0;
    this.document.getElementsByClassName(data.container)[0].style.height = `calc(100vh - ${excludedItems}px)`;
  }

  private clearSlideshowData(): void {
    if (!this.sliderCaptions?.destroyed) {
      this.sliderImages?.off('slideChange');
      this.sliderImages?.off('slideChangeTransitionEnd');
      this.sliderImages?.destroy(true, true);
    }

    if (!this.sliderImages?.destroyed) {
      this.sliderCaptions?.off('slideChange');
      this.sliderCaptions?.destroy(true, true);
    }

    this.imagesList = [];
    this.captionsList = [];
    this.captionsConfigValues = {};
    this.imagesConfigValues = {};
    this.playedVideo = false;

    this.$slideshow = {};
  }

  private checkSlideshow(): void {
    if (!this.dataProviderSubscription) {
      this.dataProviderSubscription = this.dataProvider.slideshowData
        .pipe(
          untilComponentDestroyed(this),
          filter((data) => {
            if (!data) {
              this.clearSlideshowData();
            }

            return !!data && !isEqual(this.$slideshow, data);
          }),
          concatLatestFrom(() => [
            this.store.select(pageContentState.pageContentSelectors.selectCurrentPage),
            this.store.select(siteStoreSelectors.selectEntity),
            this.store.select(templatesSelectors.selectEntitiesWithVariants),
          ]),
          filter(([
            , page,
            site,
            templates,
          ]) => ! isNil(page)),
          map(([
            slideshowData,
            page,
            site,
            templates,
          ]) => {
            let hash: string | undefined;

            if (this.isHomePage !== page?.homePage) {
              hash = UUID();
            }
            this.isHomePage = !!page?.homePage;
            this.currentTheme = templates.find((template) => template.name === site?.themeName);
            return {
              ...slideshowData,
              hash,
            };
          })
        )
        .subscribe((data: domainModels.SlideshowDomainModel & Record<string, any>) => {
          this.clearSlideshowData();
          this.stopVideo();

          if (data.publicationMode === 2 && !this.isHomePage) {
            return;
          }

          this.$slideshow = data;

          const captionsList =
            this.$slideshow.publicationMode === this.slideshowPublicationMode.EXCEPT_HOME_PAGE && this.isHomePage
              ? this.$slideshow?.slideshowHomePageCaptions ?? []
              : this.$slideshow?.slideshowCaptions ?? [];
          const titlesList =
            this.$slideshow.publicationMode === this.slideshowPublicationMode.EXCEPT_HOME_PAGE && this.isHomePage
              ? this.$slideshow?.slideshowHomePageTitles ?? []
              : this.$slideshow?.slideshowTitles ?? [];
          this.imagesList =
            this.$slideshow.publicationMode === this.slideshowPublicationMode.EXCEPT_HOME_PAGE && this.isHomePage
              ? this.$slideshow?.onlyOnHomePage ?? []
              : this.$slideshow?.attachments ?? [];

          if (!this.imagesList?.length) {
            return;
          }

          const captionsTarget =
            this.$slideshow.publicationMode === this.slideshowPublicationMode.EXCEPT_HOME_PAGE && this.isHomePage
              ? 'HomePage'
              : '';
          const titlesTarget =
            this.$slideshow.publicationMode === this.slideshowPublicationMode.EXCEPT_HOME_PAGE && this.isHomePage
              ? 'HomePage'
              : '';
          const captionsSRC =
            captionsList.length >= titlesList.length ? `slideshow${captionsTarget}Captions` : `slideshow${titlesTarget}Titles`;

          this.slideshowCaptions = captionsList.length > 0 && this.$slideshow.showCaptions;

          const captionRendererTemplate: PaginationOptions = {
            el: '.top-swiper-slideshow__pagination',
            clickable: true,
            renderCustom: (swiper, index: number, total: number) => `<span class="${total}">${index}</span>`,
          };

          (data?.[captionsSRC] ?? []).forEach((txtValue: string, index: number) => {
            const dataCaptionsList =
              this.$slideshow.publicationMode === this.slideshowPublicationMode.EXCEPT_HOME_PAGE && this.isHomePage
                ? data?.slideshowHomePageCaptions ?? []
                : data?.slideshowCaptions ?? [];
            const dataTitlesList =
              this.$slideshow.publicationMode === this.slideshowPublicationMode.EXCEPT_HOME_PAGE && this.isHomePage
                ? data?.slideshowHomePageTitles ?? []
                : data?.slideshowTitles ?? [];
            this.captionsList.push({
              title: captionsSRC === `slideshow${captionsTarget}Captions` ? dataTitlesList[index] : txtValue,
              text: captionsSRC === `slideshow${captionsTarget}Captions` ? txtValue : dataCaptionsList[index],
            });
          });

          this.imagesConfigValues = {
            ...this.defaultSlidesOptions,
            speed: this.$slideshow.animationSpeed ? this.$slideshow.animationSpeed : this.animationSpeedDefaultValue,
            loop: true,
            preloadImages: false,
            lazy: {
              loadPrevNext: true,
            },
            allowTouchMove: false,
          };

          if (this.imagesList.length > this.captionsList.length) {
            this.imagesConfigValues.autoplay = {
              delay: this.$slideshow.animationDelay ? this.$slideshow.animationDelay : this.animationDelayDefaultValue,
              disableOnInteraction: false,
            };
            if (this.$slideshow.showPoints && this.imagesConfigValues) {
              this.imagesConfigValues.pagination = captionRendererTemplate;
            }
          }

          this.sliderCaptions?.autoplay?.stop();
          this.sliderImages?.autoplay?.stop();
          this.captionsConfigValues = {
            ...this.defaultSlidesOptions,
            speed: this.$slideshow.animationSpeed ? this.$slideshow.animationSpeed : this.animationSpeedDefaultValue,
            loop: this.captionsList?.length !== 1,
            allowTouchMove: false,
          };

          if (this.$slideshow?.animationType === animationType.FADE) {
            this.animationTypeSet(this.imagesConfigValues, 'fade');
            this.animationTypeSet(this.captionsConfigValues, 'fade');
          }

          if (this.captionsList.length >= this.imagesList.length) {
            this.captionsConfigValues.autoplay = {
              delay: this.$slideshow.animationDelay ? this.$slideshow.animationDelay : this.animationDelayDefaultValue,
              disableOnInteraction: false,
            };
            if (this.$slideshow.showPoints) {
              this.captionsConfigValues.pagination = captionRendererTemplate;
            }
          }

          setTimeout(() => {
            if (this.imagesList) {
              this.sliderImages = new Swiper('.top-swiper-slideshow__images', {
                ...this.imagesConfigValues,
              });
            }
            if (this.captionsList.length) {
              this.sliderCaptions = new Swiper('.top-swiper-slideshow__captions', {
                ...this.captionsConfigValues,
              });
            }
            this.sliderImages?.autoplay.stop();
            this.sliderCaptions?.autoplay.stop();
          }, 0);

          setTimeout(() => {
            this.sliderImages?.off('slideChange');
            this.sliderImages?.off('slideChangeTransitionEnd');
            this.sliderCaptions?.off('slideChange');
            if (this.captionsList.length >= (this.imagesList?.length ?? 0) && !!this.slideshowCaptions) {
              this.sliderCaptions?.on('slideChange', ($event) => {
                this.slidesMove($event, 'images');
              });
            } else if (this.slideshowCaptions) {
              this.sliderImages?.on('slideChange', ($event) => {
                this.slidesMove($event, 'captions');
              });
            }

            if (this.parallax) {
              this.heightReduce(this.parallax);
            }

            const videoEl = this.videoElement();
            if (videoEl && !this.playedVideo) {
              this.videoAutoplay(this.sliderImages);
            } else {
              this.startLoop();
            }
            this.sliderImages?.on('slideChangeTransitionEnd', ($event) => {
              this.clearLoopFix($event, 'images');
            });
            document.documentElement.style.setProperty(
              '--slideshow-wrapper-height',
              `${this.document.querySelector('.top-swiper-slideshow')?.getBoundingClientRect().height}px`
            );
          }, 300);
        });
    }
  }

  public ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    setTimeout(() => {
      this.checkSlideshow();
    }, 1000);
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearSlideshowData();
    [
      this.dataProviderSubscription,
      this.pagesListSubscription,
    ].forEach((sub) => sub?.unsubscribe());
  }

  private startLoop(): void {
    if (this.$slideshow.animationDelay === 0) {
      return;
    }

    if (this.captionsList.length >= this.imagesList.length && this.slideshowCaptions) {
      this.sliderCaptions?.autoplay?.start();
    } else {
      this.sliderImages?.autoplay?.start();
    }
  }

  public nextSlide(): void {
    this.stopVideo();
    this.sliderImages?.slideNext();
    if (this.slideshowCaptions) {
      this.sliderCaptions?.slideNext();
    }
  }

  public prevSlide(): void {
    this.stopVideo();
    this.sliderImages?.slidePrev();
    if (this.slideshowCaptions) {
      this.sliderCaptions?.slidePrev();
    }
  }

  public clearLoopFix(slider: SwiperCore, type: string): void {
    switch (true) {
      case type === 'images' && slider.activeIndex === this.imagesList.length + 1:
        this.sliderImages?.slideToLoop(0, 0);
        break;
      case type === 'captions' && slider.activeIndex === this.captionsList.length + 1:
        this.sliderCaptions?.slideToLoop(0, 0);
        break;
    }

    if (
      type === 'images' &&
      this.imagesList[slider.realIndex]?.type === FileTypeNumbers.VIDEO &&
      slider.activeIndex < this.imagesList.length + 1 &&
      !this.playedVideo
    ) {
      this.sliderImages?.autoplay.stop();
      this.sliderCaptions?.autoplay.stop();
      this.videoAutoplay(slider);
      return;
    }

    if (!this.playedVideo) {
      this.startLoop();
    }
  }

  public slidesMove(slider: SwiperCore, type: string): void {
    if (isNil(slider) || isNil(type)) {
      return;
    }

    if (
      (type === 'captions' &&
        slider.previousIndex === this.imagesList.length + 1 &&
        this.imagesList.length !== this.captionsList.length) ||
      (type === 'images' &&
        slider.previousIndex === this.captionsList.length + 1 &&
        this.imagesList.length !== this.captionsList.length)
    ) {
      return;
    }

    const indexDiff = slider.activeIndex - slider.previousIndex;
    switch (true) {
      case this.imagesList.length > this.captionsList.length && this.captionsList.length > 0 && type === 'captions':
        if (indexDiff > 0) {
          this.sliderCaptions?.slideNext();
        } else {
          this.sliderCaptions?.slidePrev();
        }
        break;
      case this.imagesList.length < this.captionsList.length && type === 'images':
        if (indexDiff > 0) {
          this.sliderImages?.slideNext();
        } else {
          this.sliderImages?.slidePrev();
        }
        break;
      case this.imagesList.length === this.captionsList.length:
        if (slider.activeIndex === this.imagesList.length + 1) {
          this.sliderImages?.slideNext();
        } else {
          this.sliderImages?.slideToLoop(slider?.realIndex ?? 0);
        }
        break;
    }
  }

  public videoAutoplay(slider: SwiperCore | undefined): void {
    if (!this.imagesList?.length) {
      return;
    }

    if (this.imagesList[slider?.realIndex ? slider.realIndex : 0].type === FileTypeNumbers.VIDEO) {
      this.playVideo();
      return;
    }
  }

  public videoElement(): HTMLVideoElement {
    return document.querySelector('.top-swiper-slideshow__images .swiper-slide-active .slide-video') as HTMLVideoElement;
  }

  public vidEnded(): void {
    const videoEl = this.videoElement();
    if (!videoEl || !this.playButton) {
      return;
    }
    this.playedVideo = false;
    this.playButton.nativeElement.setAttribute('data-title', 'Pause');
    videoEl.currentTime = 0;
    this.startLoop();
  }

  // pause or play the video
  public playVideo(): void {
    const videoEl = this.videoElement();

    if (!videoEl || !this.playButton) {
      return;
    }

    this.playedVideo = true;
    const vidStatus =
      videoEl.currentTime > 0 && !videoEl.paused && !videoEl.ended && videoEl.readyState > videoEl.HAVE_CURRENT_DATA;

    if (!vidStatus) {
      this.sliderCaptions?.autoplay?.stop();
      this.sliderImages?.autoplay?.stop();
      this.playButton?.nativeElement.setAttribute('data-title', 'Pause');
      videoEl.playsInline = true;
      videoEl.play();
    } else {
      this.playButton.nativeElement.setAttribute('data-title', 'Play');
      videoEl.pause();
    }
  }

  private stopVideo(): void {
    const videoEl = document.querySelectorAll<HTMLVideoElement>('.top-swiper-slideshow__images .swiper-slide .slide-video');

    if (videoEl.length) {
      videoEl.forEach((video: HTMLVideoElement) => {
        this.playedVideo = false;
        this.playButton?.nativeElement.setAttribute('data-title', 'Play');
        video.currentTime = 0;
        video.pause();
      });
    }
  }

  public mute(): void {
    if (!this.muteButton) {
      return;
    }

    const videoEl = this.videoElement();
    this.mutedVideo = !this.mutedVideo;

    videoEl.muted = !videoEl.muted;
    if (videoEl.muted) {
      this.muteButton.nativeElement.setAttribute('data-title', 'Mute');
    } else {
      this.muteButton.nativeElement.setAttribute('data-title', 'Unmute');
    }
  }

  private animationTypeSet(src: SwiperOptions, type: 'fade' | 'slide'): void {
    src.effect = type;
    src.fadeEffect = {
      crossFade: type === 'fade',
    };
  }
}
