import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  LinkPluginModel,
  LinkProvider,
  ResourcesManagerService,
} from '@jotter3/common-components';

@Injectable({ providedIn: 'root' })
export class J3LinkProvider extends LinkProvider {
  constructor(private router: Router, resourceManager: ResourcesManagerService) {
    super(resourceManager);
  }

  protected navigateToCMSPage(ev: PointerEvent, model: LinkPluginModel): void {
    const { openInNewTab, page } = model;
    ev.preventDefault();

    if (openInNewTab || ev.ctrlKey || ev.metaKey) {
      const url = this.router.serializeUrl(this.router.createUrlTree([page.split('/')].flat()));
      window.open(url, '_blank');
      return;
    }

    this.router.navigate([model.page.split('/')].flat());
  }
}
