import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { SearchResultDomainModel } from '../../../domain-models';
import * as actions from '../actions';

interface SiteSearchState {
  isLoading: boolean;
  result?: SearchResultDomainModel[];
  searchPhrase?: string;
}

const initalSiteSearchState: SiteSearchState = {
  isLoading: false,
};

const siteSearchReturn = createReducer<SiteSearchState>(
  initalSiteSearchState,
  on(actions.SiteSearchClearState, () => initalSiteSearchState),
  on(actions.SiteSearchPhraseChange, (state, { phrase }) => ({
    ...state,
    isLoading: true,
    searchPhrase: phrase,
  })),
  on(actions.SiteSearchResultChangeError, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(actions.SiteSearchResultChangeSuccess, (state, { result }) => ({
    ...state,
    result,
    isLoading: false,
  }))
);

const siteSearchReducer = (state: SiteSearchState = initalSiteSearchState, action: Action): SiteSearchState =>
  siteSearchReturn(state, action);

export { initalSiteSearchState,
  siteSearchReducer,
  SiteSearchState };
