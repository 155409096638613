<ng-container *ngIf="cssClass === 'toggle-element'; else standard">
  <j3-toggle-info-component *ngIf="designMode"> </j3-toggle-info-component>
  <ng-container *ngIf="!designMode; else standard">
    <div
      (mouseenter)="toggleItem(true, elementId)"
      (mouseleave)="toggleItem(false, elementId)"
    >
      <div
        class="sectionHeader text-center h3 mb-0"
        [id]="'element-header-' + elementId"
      >
        {{ customHeader }}
      </div>
      <div
        class="section-toggle-wrapper absolute-section"
        [@openClose]="this.isActive ? 'open' : 'closed'"
        [id]="'element-wrapper-' + elementId"
        [ngClass]="{
          'section-toggle-wrapper-active': isActive === true
        }"
        [ngStyle]="{ bottom: isActive ? itemPosition : '0' }"
        (@openClose.done)="animationOverflow(false, elementId)"
        (@openClose.start)="animationOverflow(true, elementId)"
      >
        <div>
          <ng-container *ngTemplateOutlet="qlinksList"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #standard>
  <div [ngClass]="cssClass">
    <div class="sectionHeader text-center h3 mb-0">
      {{ customHeader | translate }}
    </div>
    <div
      class="p-3 w-100 h-100 text-center bg-secondary"
      *ngIf="designMode && !quickLinks"
    >
      ENTER IMAGE SOURCE
    </div>
    <ng-container *ngIf="quickLinks">
      <ng-container *ngTemplateOutlet="qlinksList"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #captions let-item>
  <h5 class="caption-title" *ngIf="item?.title">
    {{ item?.title | translate }}
  </h5>
  <p class="caption-text" *ngIf="item?.desc">
    {{ item?.desc | translate }}
  </p>
</ng-template>
<ng-template #qlinksList>
  <div
    class="quick-links-wrapper"
    #linksContainer
    [ngClass]="[
      getClass(align, direction),
      direction ? direction : 'flex-row',
      cssWidth ? 'flex-wrap' : '',
      'quick-links-wrapper-' + linksWrapperSize
    ]"
  >
    <div
      class="image-wrapper"
      *ngFor="let item of quickLinks"
      [style.width]="cssWidth"
    >
      <span class="image-link-active" *ngIf="designMode">
        Image link active
      </span>
      <a
        class="a-pointer image-container"
        *ngIf="!designMode"
        [routerLink]="generateUrl(item?.cmsPage)"
        [target]="target ? '_blank' : '_self'"
        [style.aspectRatio]="cssHeight ? item.image.aspectRatio : null"
      >
        <ng-container
          *ngTemplateOutlet="image; context: { item: item }"
        ></ng-container>
        <div
          class="captions-wrapper"
          *ngIf="(item?.title || item?.desc) && captionsInside"
        >
          <ng-container
            *ngTemplateOutlet="captions; context: { $implicit: item }"
          ></ng-container>
        </div>
      </a>
      <div
        class="image-container"
        *ngIf="designMode"
        [style.maxHeight]="!captionsInside && !fitImage ? cssHeight : null"
        [style.height]="fitImage ? cssHeight : null"
        [style.aspectRatio]="
          cssHeight && !fitImage ? item.image.aspectRatio : null
        "
      >
        <ng-container
          *ngTemplateOutlet="image; context: { item: item }"
        ></ng-container>
        <div
          class="captions-wrapper"
          *ngIf="(item?.title || item?.desc) && captionsInside"
        >
          <ng-container
            *ngTemplateOutlet="captions; context: { $implicit: item }"
          ></ng-container>
        </div>
      </div>
      <div
        class="captions-wrapper"
        *ngIf="(item?.title || item?.desc) && !captionsInside"
      >
        <ng-container
          *ngTemplateOutlet="captions; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #image let-imageSRC="item">
  <img
    [src]="(imageSRC?.image.icon || imageSRC?.image.url)[imageSize]"
    [style.width]="cssWidth"
    [style.height]="fitImage ? cssHeight : null"
    [style.max-height]="
      !fitImage &&
      (!linkType || (linkType && designMode) || (linkType && captionsInside))
        ? cssHeight
        : null
    "
    [ngClass]="'image-resizer image-' + size + (fitImage ? ' fit-cover' : '')"
    title="{{ captionTitle ? captionTitle : '' }}{{
      captionText && captionTitle ? ' - ' : ''
    }}{{ captionText ? captionText : '' }}"
    alt="{{ captionTitle ? captionTitle : '' }}{{
      captionText && captionTitle ? ' - ' : ''
    }}{{ captionText ? captionText : '' }}"
    loading="lazy"
  />
  <j3-image-caption
    *ngIf="(captionTitle || captionText) && captionsInside"
    [captionTitle]="captionTitle"
    [captionText]="captionText"
    [align]="align"
  ></j3-image-caption>
</ng-template>
