import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { PoliciesGroupsState } from '../reducers';

export const selectPoliciesGroupState = createFeatureSelector<PoliciesGroupsState>('policiesGroups');

export const selectPoliciesGroupStateSelector = createSelector(selectPoliciesGroupState, (state) => state);

export const selectPoliciesGroupSelector = createSelector(selectPoliciesGroupStateSelector, (state) => {
  const entities = Object.values(state.entities);
  return entities;
});

export const selectArePoliciesGroupLoading = createSelector(selectPoliciesGroupState, (state) => state.isLoading);

export const selectArePoliciesGroupLoaded = createSelector(selectPoliciesGroupState, (state) => state.isLoaded);
