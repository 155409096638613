import {
  inject,
  Injectable,
} from '@angular/core';
import {
  domainModels,
  SiteDomainModel,
  siteStoreSelectors,
} from '@jotter3/api-connector';
import { SiteSettingsDataService } from '@jotter3/common-helpers';
import {
  IDataModel,
  ISubpage,
  TemplateDataProvider,
} from '@jotter3/template-components';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteTemplatesDataService extends TemplateDataProvider {
  readonly #store: Store = inject(Store);
  readonly #sitesDataService: SiteSettingsDataService = inject(SiteSettingsDataService);
  appEditorUrlLinks = '/';

  public getData(): Observable<IDataModel> {
    return this.#sitesDataService.getData();
  }

  public getSitesData(): Observable<SiteDomainModel> {
    return this.#store.select(siteStoreSelectors.selectEntity);
  }

  public getPages(): Observable<ISubpage[]> {
    return this.#sitesDataService.getPages();
  }

  public get slideshowData(): Observable<domainModels.SlideshowDomainModel> {
    return this.#sitesDataService.slideshowData;
  }

  public loadSlideshowForPage(pageId: string): void {
    return this.#sitesDataService.loadSlideshowForPage(pageId);
  }

  public invokeSearch(phrase: string): void {
    return this.#sitesDataService.invokeSearch(phrase);
  }
}
