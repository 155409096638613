import { InjectionToken } from '@angular/core';
import {
  Action,
  createAction,
} from '@ngrx/store';
import { InitialState } from '@ngrx/store/src/models';
import { isNil } from 'lodash-es';

import { BaseStateModel } from './base-state.model';

const NGRX_STATE_KEY = 'NGRX_APP_STATE';
const resetStateAction = createAction('[Meta] Reset State');
const STATE_CALLBACK: InjectionToken<(state: string) => void> = new InjectionToken('STATE_CALLBACK');

const clearStateMetaReducer =
  (reducer: any): ((state: BaseStateModel | undefined, acttion: Action) => any | undefined) =>
    (state: BaseStateModel | undefined, action: Action): any | undefined => {
      if (action?.type === resetStateAction.type) {
        state = undefined;
      }
      return reducer(state, action);
    };

const getInitialState = <TState extends BaseStateModel>(): InitialState<TState> => {
  if (typeof document !== 'undefined') {
    const script = document.getElementById(NGRX_STATE_KEY) as HTMLScriptElement;

    let initialState = {};

    if (script && script.textContent) {
      try {
        const serverState = JSON.parse(script.textContent) as { state: string };
        initialState = unescapeHTMLStringInState(JSON.parse(serverState.state));
        script.parentElement.removeChild(script);
      } catch (e) {
        console.warn('Exception while restoring NGRX_STATE for app', e);
      }
    }
    return initialState;
  } else {
    return {};
  }
};

const escapeHtml = (text: string): string => {
  // prettier-ignore
  const escapedText: { [k: string]: string } = {
    '&': '&a;',
    '"': '&q;',
    '\'': '&s;',
    '<': '&l;',
    '>': '&g;',
    '’': '&rs;',
  };

  return text.replace(/[&"'<>’]/g, (s) => escapedText[s]);
};

const unescapeHtml = (text: string): string => {
  // prettier-ignore
  const unescapedText: { [k: string]: string } = {
    '&a;': '&',
    '&q;': '"',
    '&s;': '\'',
    '&l;': '<',
    '&g;': '>',
    '&rs;': '’',
  };
  return text.replace(/&[^;]+;/g, (s) => unescapedText[s]);
};

const escapeHTMLStringInState = (state: any, newState: any = {}): object => {
  if (isNil(state)) {
    return state;
  }

  if (typeof state !== 'object') {
    return typeof state === 'string' ? (escapeHtml(state) as any) : state;
  }

  for (const key of Object.keys(state)) {
    if (isNil(state[key])) {
      continue;
    }

    if (typeof state[key] === 'string') {
      try {
        newState[key] = escapeHtml(state[key]);
      } catch (err) {
        console.error(`Cannot convert value in key "${key}"`, err);
      }

      continue;
    }

    if (typeof state[key] === 'object') {
      if (Array.isArray(state[key])) {
        newState[key] = [];
        for (const index of (state[key] || []).keys()) {
          newState[key].push(escapeHTMLStringInState(state[key][index]));
        }
        continue;
      }
      newState[key] = escapeHTMLStringInState({ ...state[key] });
      continue;
    }
    newState[key] = state[key];
  }
  return newState;
};

const unescapeHTMLStringInState = (state: any): object => {
  if (isNil(state)) {
    return state;
  }

  for (const key of Object.keys(state)) {
    if (isNil(state[key])) {
      continue;
    }

    if (typeof state[key] === 'string') {
      try {
        (state as any)[key] = unescapeHtml(state[key]);
      } catch (err) {}
    }

    if (typeof state[key] === 'object' && isWritable(state, key)) {
      if (Array.isArray(state[key])) {
        for (const index of (state[key] || []).keys()) {
          state[key][index] = unescapeHTMLStringInState(state[key][index]);
        }
        continue;
      }
      state[key] = unescapeHTMLStringInState({ ...state[key] });
    }
  }
  return state;
};

const isWritable = <T extends object>(obj: T, key: string): boolean => {
  const desc =
    Object.getOwnPropertyDescriptor(obj, key) || Object.getOwnPropertyDescriptor(Object.getPrototypeOf(obj), key) || {};

  if (!desc.writable) {
    obj = Object.defineProperty(obj, key, {
      value: (obj as any)[key],
    });

    return (Object.getOwnPropertyDescriptor(obj, key) || Object.getOwnPropertyDescriptor(Object.getPrototypeOf(obj), key) || {})
      ?.writable;
  }

  return Boolean(desc.writable);
};

export { clearStateMetaReducer,
  escapeHtml,
  escapeHTMLStringInState,
  getInitialState,
  NGRX_STATE_KEY,
  resetStateAction,
  STATE_CALLBACK,
  unescapeHtml,
  unescapeHTMLStringInState };
