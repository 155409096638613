import { Type } from '@angular/core';
import { reflectMetadata } from '@jotter3/reflection-core';
import {
  constant,
  SiteBaseComponent,
  SiteComponentMetadata,
} from '@jotter3/sites-abstract';

export const getComponentDefinitions = (
  componentTypes: Type<SiteBaseComponent>[]
): { component: Type<SiteBaseComponent>, metadata?: SiteComponentMetadata }[] =>
  componentTypes.map((componentType) => {
    const meta: SiteComponentMetadata | SiteComponentMetadata[] = reflectMetadata.getComponentMetadata(
      constant.COMPONENT_DESCRIPTION_META_KEY,
      componentType
    );

    return {
      component: componentType,
      metadata: meta && meta.length > 0 ? meta[0] : undefined,
    };
  });
