import {
  inject,
  Injectable,
} from '@angular/core';
import { UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  map,
  Observable,
} from 'rxjs';
import {
  filter,
  switchMap,
  take,
} from 'rxjs/operators';

import { siteStoreSelectors } from '../+state';
import { SitesCollectionService } from '../collection-services';
import {
  pagesActions,
  pagesSelectors,
} from '../state/pageslist';

@Injectable({
  providedIn: 'root',
})
export class PagesGuard {
  url$: Observable<string>;
  readonly #store = inject(Store);
  readonly #sitesCollectionService = inject(SitesCollectionService);

  public canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.#store.select(siteStoreSelectors.selectIsLoaded).pipe(
      filter((loaded) => loaded),
      take(1),
      switchMap(() => combineLatest([
        this.#store.select(pagesSelectors.arePagesListLoaded),
        this.#store.select(pagesSelectors.arePagesListLoading),
      ])),
      filter(([
        isLoaded,
        isLoading,
      ]) => {
        if (isLoading) {
          return false;
        }

        if (!isLoading && !isLoaded) {
          this.#store.dispatch(pagesActions.pagesListActions.LoadPagesList({}));
        }

        return isLoaded;
      }),
      map(([isLoaded]) => isLoaded)
    );
  }
}
