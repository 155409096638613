import { ParseTreeResult } from '@angular/compiler';
import {
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { parse } from 'angular-html-parser';
import * as html from 'angular-html-parser/lib/compiler/src/ml_parser/ast';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const decode = require('decode-html');

import { v4 as UUID } from 'uuid';

import {
  CMS_TEMPLATE_DATA_ATTRIBUTE,
  CMS_TEMPLATE_IGNORE,
} from '../common';
import { templateBuilderModel } from '../models';

@Injectable()
export class TemplateParserHtml {
  private renderer: Renderer2;
  private datasetMap: Map<string, any> = new Map<string, any>();

  constructor(rendererFactory: RendererFactory2, private sanitizer: DomSanitizer) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  parseHtml(htmlValue: string): ParseTreeResult {
    const res = parse(htmlValue);
    return res as any;
  }

  prepareTemplateResultHtml(htmlValue: string): string {
    const templateTree = parse(htmlValue);

    const res = this.prepareTemplatePart(templateTree.rootNodes);

    return this.replaceComponentDatasetIndentifierToAttribute(decode(res.map((x) => x.outerHTML).join('\n')));
  }

  private prepareTemplatePart(nodes: html.Node[]): any[] {
    const res: any[] = [];
    for (const node of nodes.filter(
      (x) => x instanceof html.Element && !x.attrs.find((at) => at.name === CMS_TEMPLATE_IGNORE)
    ) as html.Element[]) {
      const { name: nodeName, attrs, children } = node;
      const templateElementDatasetModel = attrs.find((x) => x.name === CMS_TEMPLATE_DATA_ATTRIBUTE);

      if (templateElementDatasetModel) {
        try {
          const elementConfig = JSON.parse(templateElementDatasetModel.value) as templateBuilderModel.TemplateElementDatasetModel;
          const templateElement = this.renderer.createElement(elementConfig.componentName);

          this.setComponentDatasetIndentifier(templateElement, elementConfig);
          this.appendAttributes(templateElement, attrs);
          res.push(templateElement);
        } catch (err) {
          continue;
        }
      }
      const htmlElement = this.renderer.createElement(nodeName);

      this.appendAttributes(htmlElement, attrs);

      if (node.children?.length > 0) {
        for (const child of this.prepareTemplatePart(children)) {
          this.renderer.appendChild(htmlElement, child);
        }
      }

      res.push(htmlElement);
    }

    return res;
  }

  private setComponentDatasetIndentifier(element: any, dataset: templateBuilderModel.TemplateElementDatasetModel): void {
    const uuid = UUID();
    this.datasetMap.set(uuid, dataset);
    this.renderer.setAttribute(element, CMS_TEMPLATE_DATA_ATTRIBUTE, `#${uuid}`);
  }

  private replaceComponentDatasetIndentifierToAttribute(htmlValue: string): string {
    for (const [
      key,
      value,
    ] of this.datasetMap) {
      htmlValue = htmlValue.replace(
        `${CMS_TEMPLATE_DATA_ATTRIBUTE}="#${key}"`,
        `${CMS_TEMPLATE_DATA_ATTRIBUTE}='${JSON.stringify(value)}'`
      );
    }

    return htmlValue;
  }

  private appendAttributes(element: any, attributes: html.Attribute[]): void {
    // Ignore angular dynamic attributes using Array.filter by keys 'ng' and '_ng-'
    for (const attr of attributes.filter(
      (x) => !x.name.startsWith('ng-') && !x.name.startsWith('_ng') && x.name !== CMS_TEMPLATE_DATA_ATTRIBUTE
    )) {
      this.renderer.setAttribute(element, attr.name, attr.value);
    }
  }
}
