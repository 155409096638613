import { LoadingStateEnum } from '@jotter3/store-helpers';
import { createSelector } from '@ngrx/store';

import { siteStoreFeature } from './site.reducer';

const {
  selectLoadingStatus,
  selectDomain,
  selectEntity,
  selectErrors,
  selectCookiesAccepted,
  selectCookiesHasBeenShown,
} = siteStoreFeature;

const selectIsLoading = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.LOADING
);

const selectIsLoaded = createSelector(
  selectLoadingStatus,
  (loadingStatus: LoadingStateEnum) => loadingStatus === LoadingStateEnum.SUCCESS || loadingStatus === LoadingStateEnum.FAILED
);

const selectSiteId = createSelector(
  selectEntity,
  entity => entity?.id
);

export const siteStoreSelectors = {
  selectCookiesAccepted,
  selectCookiesHasBeenShown,
  selectEntity,
  selectDomain,
  selectErrors,
  selectIsLoaded,
  selectIsLoading,
  selectLoadingStatus,
  selectSiteId,
};
