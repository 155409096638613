import { ErrorBaseComponent } from './error/error-base.component';
import { SiteRendererComponent } from './site-renderer/site-renderer.component';

const components = [
  SiteRendererComponent,
  ErrorBaseComponent,
];

export { components,
  ErrorBaseComponent,
  SiteRendererComponent };
