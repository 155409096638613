import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { PeopleState } from '../reducers';

export const selectPeopleListState = createFeatureSelector<PeopleState>('peopleList');

export const peopleListStateSelector = createSelector(selectPeopleListState, (state) => state);

export const peopleListSelector = createSelector(peopleListStateSelector, (state) => {
  const entities = Object.values(state.entities);
  return entities;
});

export const arePeopleListLoading = createSelector(peopleListStateSelector, (state) => state.isLoading);

export const arePeopleListLoaded = createSelector(peopleListStateSelector, (state) => state.isLoaded);
