import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  DOCUMENT,
  isPlatformBrowser,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  inject,
  Input,
  OnInit,
  Optional,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import {
  enums,
  siteStoreSelectors,
} from '@jotter3/api-connector';
import { Store } from '@ngrx/store';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment-timezone';
import { CookieService } from 'ngx-cookie-service';
import {
  filter,
  take,
  tap,
} from 'rxjs/operators';

export enum SnackStatus {
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED'
}

enum CookiesLayout {
  BAR = 0,
  LEFT_BOX = 1
}

@Component({
  selector: 'j3-cookies-box',
  templateUrl: './cookies-box.component.html',
  styleUrls: ['./cookies-box.component.scss'],
  animations: [
    trigger('toggle', [
      state(
        'open',
        style({
          opacity: '0.9',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
  ],
})
export class CookiesBoxComponent extends OnDestroyMixin implements OnInit {
  readonly #store: Store = inject(Store);


  @Output() public CookeResponse: EventEmitter<enums.CookieState> = new EventEmitter<enums.CookieState>();
  @Output() public NavigateToPolicy: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public AnaliticsResponse: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() cookiesLayout: CookiesLayout = null;

  public isVisible = false;
  public gaCookiesAccepted = false;
  public thirdPartyCookiesAccepted = false;
  public googleAnaliticsExists = false;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: object,
    @Optional() @Inject(DOCUMENT) private document: Document,
    private readonly cookieService: CookieService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.#store.select(siteStoreSelectors.selectEntity)
      .pipe(
        untilComponentDestroyed(this),
        filter((currentSite) => !!currentSite && !!currentSite?.googleAnalytics),
        take(1),
        tap(() => {
          this.googleAnaliticsExists = true;
          if (this.googleAnaliticsExists && this.cookieService.get('ga_enabled')) {
            this.gaCookiesAccepted = true;
          }
        })
      )
      .subscribe();

    if (this.cookieService.get('J3CookiesAccepted') === 'false') {
      this.isVisible = true;
    }
    if (this.cookieService.get('J3CookiesAccepted') === 'ACCEPTED') {
      this.thirdPartyCookiesAccepted = true;
    }
  }

  public toggle(): void {
    this.isVisible = !this.isVisible;
  }

  public hide(): void {
    if (this.cookieService.get('J3CookiesAccepted') === 'false') {
      return;
    }
    this.isVisible = false;
  }

  public submit(accepted: boolean): void {
    const cookieResponseValue = accepted ? enums.CookieState.ACCEPTED : enums.CookieState.REJECTED;

    this.CookeResponse.emit(cookieResponseValue);
    this.gaCookiesAccepted = accepted;
    this.googleAnaliticsExists = accepted;
    this.thirdPartyCookiesAccepted = accepted;
    this.saveGaSettings();
    this.hide();
  }

  public onAnalyticsChange(accepted: boolean): void {
    this.gaCookiesAccepted = accepted;
  }

  public onThirdPartyChange(accepted: boolean): void {
    this.thirdPartyCookiesAccepted = accepted;
  }

  public saveGaSettings(): void {
    if (this.gaCookiesAccepted) {
      this.cookieService.set('ga_enabled', 'true', {
        expires: moment().add(6, 'month').toDate(),
        path: '/',
      });
    } else {
      this.cookieService.delete('ga_enabled');
    }
    const cookieResponseValue = this.thirdPartyCookiesAccepted ? enums.CookieState.ACCEPTED : enums.CookieState.REJECTED;
    this.CookeResponse.emit(cookieResponseValue);
    this.AnaliticsResponse.emit(this.gaCookiesAccepted);
    this.hide();
  }

  public navigateToPolicy(): void {
    this.NavigateToPolicy.emit(true);
  }
}
