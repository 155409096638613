import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { GroupDomainModel } from '../../../domain-models';
import {
  LoadMyGroupsList,
  LoadMyGroupsListComplete,
  LoadMyGroupsListFailed,
} from '../actions';

interface MyGroupsState extends EntityState<GroupDomainModel> {
  isLoading: boolean;
  isLoaded: boolean;
}

const myGroupsListAdapter: EntityAdapter<GroupDomainModel> = createEntityAdapter<GroupDomainModel>();

const initalMyGroupsState: MyGroupsState = myGroupsListAdapter.getInitialState({
  isLoaded: false,
  isLoading: false,
});

const myGroupsListReturn = createReducer<MyGroupsState>(
  initalMyGroupsState,
  on(LoadMyGroupsList, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(LoadMyGroupsListComplete, (state, { myGroupsList }) =>
    myGroupsListAdapter.setAll(myGroupsList, {
      ...state,
      isLoading: false,
      isLoaded: true,
    })),
  on(LoadMyGroupsListFailed, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
  }))
);

const myGroupsListReducer = (state: MyGroupsState = initalMyGroupsState, action: Action): MyGroupsState =>
  myGroupsListReturn(state, action);

export { initalMyGroupsState,
  myGroupsListAdapter,
  myGroupsListReducer,
  MyGroupsState };
