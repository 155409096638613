<div
  *ngIf="designMode && !postcode"
  class="p-3 w-100 h-100 text-center bg-secondary text-white ng-star-inserted"
>
  ENTER POSTCODE
</div>
<div
  [ngClass]="{ 'design-mode': designMode }"
  class="map-container"
>
  <div
    [style.maxWidth]="cssWidth"
    [ngClass]="align"
    class="map-frame"
  >
    <div
      [style.minHeight]="cssHeight?.trim()?.length ? cssHeight : '500px'"
      id="{{ 'map-' + id }}"
      class="map"
    ></div>
  </div>
</div>
