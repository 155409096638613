export enum Operator {
  EQ = 'eq',
  NEQ = 'neq',
  LT = 'lt',
  LTE = 'lte',
  GT = 'gt',
  GTE = 'gte',
  IN = 'in',
  NOT_IN = 'notIn',
  CONTAINS = 'contains',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  IS = 'is',
  EMPTY = 'empty',
  IS_NULL = 'isNull'
}

export const searchOperators = [
  Operator.CONTAINS,
  Operator.EQ,
  Operator.NEQ,
  Operator.ENDS_WITH,
  Operator.STARTS_WITH,
];

export const rangeOperators = [
  Operator.EQ,
  Operator.NEQ,
  Operator.LT,
  Operator.GT,
  Operator.LTE,
  Operator.GTE,
];

export const selectOperators = [
  Operator.IN,
  Operator.NOT_IN,
];

export const booleanOperators = [Operator.EQ];

export const nullOperator = [Operator.IS_NULL];

export const operators = [...new Set(...searchOperators, ...rangeOperators, ...selectOperators, ...booleanOperators, ...nullOperator)];
