import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { LetterDomainModel } from '@jotter3/api-connector';
import {
  J3TranslateService,
  OrderBy,
} from '@jotter3/common-helpers';
import {
  attachmentOrder,
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { LoadingStateEnum } from '@jotter3/store-helpers';
import {
  dataQuery,
  Pagination,
} from '@jotter3/wa-core';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  combineLatestWith,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import { ToggleContentService } from '../../services';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';
import {
  CONST_DATA_QUERY_FILTERS,
  DEFAULT_HEADER,
  DEFAULT_PAGE_SIZE,
  DEFAULT_VIEW_STYLE,
  LETTERS_ORDER_DROPDOWN_SOURCE,
  VIEW_STYLE,
  VIEW_STYLE_DROPDOWN_SOURCE,
} from './consts';
import { LettersComponentStore } from './letters.component.store';


@SiteComponent({
  selector: 'letters-content-component',
  displayName: 'Letters',
  icon: 'mail',
  category: SiteComponentCategory.MODULES,
  showSettingsAfterAdd: true,
  roles: ['ROLE_LETTERS_VIEWALL'],
})
@Component({
  templateUrl: './letters.component.html',
  styleUrls: ['./letters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
    LettersComponentStore,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class LettersComponent extends JotterSitesBaseComponent implements AfterViewInit, OnDestroy {
  readonly #componentStore: LettersComponentStore = inject(LettersComponentStore);
  readonly #subscription: Subscription = new Subscription();
  readonly #changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  readonly #toggleContentService: ToggleContentService = inject(ToggleContentService);

  public readonly entities$: Observable<LetterDomainModel[]> = this.#componentStore.selectEntities$;
  public readonly isLoading$: Observable<boolean> = this.#componentStore.selectIsLoading;
  public readonly loadingState$: Observable<LoadingStateEnum> = this.#componentStore.selectLoadingState;
  public readonly pagination$: Observable<Pagination> = this.#componentStore.selectPagination$;
  public readonly LoadingStateEnum = LoadingStateEnum;
  public readonly instanceId: string = UUID();
  public readonly isActive$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly VIEW_STYLE = VIEW_STYLE;
  public itemPosition = '0px';
  #currentPage = 1;

  constructor() {
    super(LettersComponent);
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
    required: true,
    defaultValue: DEFAULT_HEADER,
  })
  public header = DEFAULT_HEADER;

  @Property({
    displayName: 'Letters to display',
    modifierType: ModifierType.NUMBER,
    required: true,
    description: 'Maximum number of letters to display at once',
    templateOptions: {
      required: true,
      placeholder: 'Enter value...',
      min: 1,
    },
    defaultValue: DEFAULT_PAGE_SIZE,
  })
  public itemsPerPage = DEFAULT_PAGE_SIZE;

  @Property({
    displayName: 'Categories',
    modifierType: ModifierType.J3_API_MULTIDROPDOWN,
    resourceName: 'categories',
    valueProp: 'id',
    labelProp: 'name',
  })
  public selectedCategories: string[] = [];

  @Property({
    displayName: 'Audience Targeting',
    modifierType: ModifierType.J3_API_MULTIDROPDOWN,
    resourceName: 'letters/available_groups',
    valueProp: 'id',
    labelProp: 'name',
    group: 'General',
    advanced: true,
    placeholder: 'Choose groups...',
  })
  public selectedGroups: string[] = [];

  @Property({
    displayName: 'Letter Style',
    modifierType: ModifierType.DROPDOWN,
    template: 'vaIcons',
    dropdownSource: VIEW_STYLE_DROPDOWN_SOURCE,
    defaultValue: DEFAULT_VIEW_STYLE,
    clearable: false,
  })
  public elTemplateValue: VIEW_STYLE = DEFAULT_VIEW_STYLE;

  @Property({
    displayName: 'Order letters by',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: attachmentOrder.ATTACHMENTS_ORDER,
    defaultValue: OrderBy.DATE_DESC,
  })
  public orderLettersBy: OrderBy = OrderBy.DATE_DESC;

  @Property({
    displayName: 'Order attachments by',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: attachmentOrder.ATTACHMENTS_ORDER,
    defaultValue: OrderBy.NAME_ASC,
  })
  public orderAttBy: OrderBy = OrderBy.NAME_ASC;

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.#updateDataQueryInStore(this.#currentPage);

    this.#subscription.add(
      this.#componentStore.selectQueryParams$.pipe(
        combineLatestWith(this.settingsModalActive$),
        filter(([, active]) => !active),
        map(([params]) => params),
        distinctUntilChanged()
      ).subscribe((params) => {
        this.#componentStore.loadEntities(params);
        this.#changeDetector.markForCheck();
      })
    );
  }
  public override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.#subscription.unsubscribe();
  }

  public override afterSettingsSaved(): void {
    this.#updateDataQueryInStore(this.#currentPage);
  }

  public handlePageChanged(page: number): void {
    this.#currentPage = page;
    this.#updateDataQueryInStore(this.#currentPage);
  }

  public handleToggleItem(status: boolean, id: string): void {
    this.isActive$.next(status);
    this.itemPosition = this.#toggleContentService.toggleContent(status, id);
  }

  public animationOverflow(status: boolean, id: string): void {
    this.#toggleContentService.toggleOverflow(status, id);
  }

  #updateDataQueryInStore(page: number): void {
    this.#componentStore.setQueryParams({
      pagination: {
        size: this.itemsPerPage,
        page,
      },
      order: [LETTERS_ORDER_DROPDOWN_SOURCE[this.orderLettersBy ?? OrderBy.DATE_DESC]],
      filters: !this.selectedCategories?.length ? CONST_DATA_QUERY_FILTERS : [
        ...CONST_DATA_QUERY_FILTERS,
        {
          type: dataQuery.FilterType.SELECT,
          property: 'categories',
          operator: dataQuery.Operator.IN,
          value: this.selectedCategories,
        },
      ],
    });
  }
}
