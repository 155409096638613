<div class="modal-head">
  <div class="row">
    <div class="col">
      <h4 class="modal-header-title m-0">Add Element</h4>
    </div>
    <div class="col">
      <button
        (click)="onCloseDialog()"
        class="btn small-btn float-end"
        style="width: 40px; height: 40px; padding: 8px; position: relative; left: 8px"
      >
        <j3-icon name="close"></j3-icon>
      </button>
    </div>
  </div>
</div>

<div class="modal-body">
  <div
    *ngFor="let category of siteCategories"
    class="elements-container"
  >
    <ng-container *ngFor="let element of items">
      <span
        *ngIf="element.category === category"
        (click)="onAddItem(element)"
        class="element"
      >
        <div
          [style.backgroundColor]="siteCategoryColours[category]"
          class="element-icon"
        >
          <j3-icon
            [name]="element.icon"
            class="menu-icon"
          ></j3-icon>
        </div>
        <span class="element-title">{{ element.displayName }}</span>
      </span>
    </ng-container>
  </div>
</div>
