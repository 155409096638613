import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import * as moment from 'moment-timezone';

import { DataQueryAdapter } from './data-query-adapter.service';
import {
  DataQuery,
  Filter,
  Filters,
  FilterType,
  Operator,
  Order,
  PaginationQuery,
  Param,
  Params,
  PropertyFilterType,
} from './models';

@Injectable()
export class LHSBracketsDataQueryAdapter implements DataQueryAdapter {
  public toQueryString(queryData: DataQuery): string {
    const filters = this.filtersToParams(queryData?.filters || []);
    const order = this.orderToParams(queryData?.order || []);
    const pagination = this.paginationToParams(queryData?.pagination || {});

    return [
      ...filters,
      ...order,
      ...pagination,
    ]
      .filter(Boolean)
      .map(({ value, key }) => `${key}=${value}`)
      .join('&');
  }

  public toQueryParam(queryData: DataQuery): HttpParams {
    const filters = this.filtersToParams(queryData?.filters || []);
    const order = this.orderToParams(queryData?.order || []);
    const pagination = this.paginationToParams(queryData?.pagination || {});

    const fromObject: Record<string, string | string[]> = {};

    [
      ...filters,
      ...order,
      ...pagination,
    ].filter(Boolean).forEach(({ value, key }) => {
      fromObject[key] = `${value}`;
    });

    return new HttpParams({
      fromObject,
    });
  }

  public fromQueryString(queryString: string, config: PropertyFilterType[]): DataQuery {
    const params: Params =
      queryString.split('&').map((part) => {
        const [
          key,
          value,
        ] = part.split('=');
        return {
          key,
          value,
        };
      }) || [];

    return {
      filters: this.filtersFromParams(params, config),
      order: this.orderFromParams(params),
      pagination: this.paginationFromParams(params),
    };
  }

  public filtersToParams(filters: Filters = []): Params {
    return filters?.map(this.mapSingleFilterToParam);
  }

  public filtersFromParams(params: Params, config: PropertyFilterType[]): Filters {
    return params
      ?.filter(not(isOrderParam))
      ?.filter(not(isPaginationParam))
      ?.map((param) => this.mapSingleParamToFilter(param, config))
      ?.filter(Boolean) as Filters;
  }

  public orderToParams(order: Order = []): Params {
    return order?.map(({ key, direction }) => ({
      key: `order[${key}]`,
      value: direction,
    }));
  }

  public orderFromParams(params: Params): Order {
    return params?.filter(isOrderParam).map(({ key, value }) => ({
      key: key.replace('order[', '').replace(']', ''),
      direction: value,
    }));
  }

  public paginationToParams(pagination: PaginationQuery): Params {
    const { page: rawPage, size } = pagination;
    const limit =
      typeof size === 'number'
        ? {
          key: 'limit',
          value: size,
        }
        : undefined;
    const page =
      typeof rawPage === 'number'
        ? {
          key: 'page',
          value: rawPage,
        }
        : undefined;

    return [
      limit,
      page,
    ].filter(Boolean) as Params;
  }

  public paginationFromParams(params: Params): PaginationQuery {
    const limitParam = params?.find(({ key }) => key === 'limit')?.value;
    const pageParam = params?.find(({ key }) => key === 'page')?.value;

    const size = !isNaN(parseInt(limitParam, 10)) ? parseInt(limitParam, 10) : null;
    const page = !isNaN(parseInt(pageParam, 10)) ? parseInt(pageParam, 10) : null;

    return {
      size,
      page,
    } as PaginationQuery;
  }

  private mapSingleFilterToParam(filter: Filter): Param {
    const { property, type, operator } = filter;

    let value = filter.value;

    switch (type) {
      case FilterType.DATE: {
        value = moment(value as Date).format('YYYY-MM-DD');
        break;
      }

      case FilterType.SELECT: {
        value = [value || []]
          .flat()
          .map((item) => item.toString())
          .join(',');
        break;
      }
    }

    return {
      key: `${property}${operator === Operator.EMPTY ? '' : `[${operator}]`}`,
      value,
    };
  }

  private mapSingleParamToFilter(param: Param, config: PropertyFilterType[]): Filter | undefined {
    let value = param.value;
    const key = param.key;

    const [
      paramProperty,
      operator,
    ] = key.replace(']', '').split('[');
    const foundConfig = config.find((element) => paramProperty === element.property);

    if (isNil(foundConfig) || isNil(foundConfig.filterType)) {
      return undefined;
    }

    const { property, filterType } = foundConfig;

    switch (filterType) {
      case FilterType.BOOLEAN: {
        value = value === 'true' || value === true || value === 1;
        break;
      }

      case FilterType.NUMERIC: {
        value = parseFloat(value);
        break;
      }

      case FilterType.SELECT:
        value = `${value}`.split(',').map((item) => item?.trim?.());
        break;
    }

    return {
      property,
      operator: operator as Operator,
      value,
      type: filterType,
    };
  }
}

const isOrderParam = (item: Param): boolean => item.key.includes('order[');
const isPaginationParam = (item: Param): boolean => item.key.includes('page') || item.key.includes('limit');

const not =
  <T>(callback: (item: T, index: number, array: T[]) => boolean): ((item: T, index: number, array: T[]) => boolean) =>
    (item: T, index: number, array: T[]): boolean =>
      !callback(item, index, array);
