import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@jotter3/wa-core';
import { DefaultDataService } from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomHttpUrlGenerator } from '../common';
import {
  PagesContentDomainModel,
  PagesContentListDomainModel,
} from '../domain-models';

@Injectable()
export class PagesContentCollectionService extends DefaultDataService<PagesContentDomainModel> {
  constructor(http: HttpClient, httpUrlGenerator: CustomHttpUrlGenerator) {
    super('Page', http, httpUrlGenerator);
  }

  public getContentByPageId(id: string, headers?: Record<string, string>): Observable<PagesContentListDomainModel> {
    return this.http.get<PagesContentListDomainModel>(`${this.entityUrl}${id}/content`, {
      headers: new HttpHeaders(headers),
    });
  }

  public getAllRevisions(id: string, headers?: object): Observable<PagesContentListDomainModel[]> {
    return super.execute('GET', `${this.entityUrl}${id}/contents`, null, headers);
  }
}

@Injectable()
export class PagesContentUpdateCollectionService extends DefaultDataService<PagesContentDomainModel> {
  constructor(http: HttpClient, httpUrlGenerator: CustomHttpUrlGenerator) {
    super('Page_content', http, httpUrlGenerator);
  }

  public getContentByContentId(id: string): Observable<PagesContentListDomainModel> {
    return super.execute('GET', `${this.entityUrl}${id}`);
  }

  public createRevision(data: Partial<PagesContentDomainModel>): Observable<PagesContentDomainModel> {
    return super
      .execute('POST', `${this.entitiesUrl}`, data)
      .pipe(map((res) => (res as ApiResponse<PagesContentDomainModel>).result));
  }

  public updateContent(id: string, data: Partial<PagesContentDomainModel>): Observable<PagesContentDomainModel> {
    return super
      .execute('PUT', `${this.entityUrl}${id}`, data)
      .pipe(map((res) => (res as ApiResponse<PagesContentDomainModel>).result));
  }
}
