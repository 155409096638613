import {
  Component,
  Inject,
  Optional,
} from '@angular/core';
import {
  domainModels,
  IFileDomainModel,
  ResourcesListDomainService,
} from '@jotter3/api-connector';
import { J3TranslateService } from '@jotter3/common-helpers';
import {
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  combineLatest,
  Observable,
  of,
} from 'rxjs';
import {
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import {
  FILE_MANAGER_TOKEN,
  FileManagerProvider,
} from '../../common';
import { AccordionItemModel } from '../../models';
import { ToggleContentService } from '../../services';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'accordion-content-component',
  displayName: 'FAQ Builder',
  icon: 'faq',
  category: SiteComponentCategory.TEXT,
})
@Component({
  templateUrl: './accordion-element.component.html',
  styleUrls: ['./accordion-element.component.scss'],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class AccordionElementComponent extends JotterSitesBaseComponent {
  private accordionItems: AccordionItemModel[];
  elementId: string = UUID();
  isActive = false;
  itemPosition = '0px';
  public textEditing = false;
  private focusOnHeader = false;

  constructor(
    private resourcesListService: ResourcesListDomainService,
    @Optional() @Inject(FILE_MANAGER_TOKEN) private fileManager: FileManagerProvider,
    private toggleContent: ToggleContentService
  ) {
    super(AccordionElementComponent);
    this.accordionItems = [
      {
        id: UUID(),
      },
    ];
  }

  public get items(): AccordionItemModel[] {
    return this.accordionItems;
  }

  public set items(value: AccordionItemModel[]) {
    this.accordionItems = value;
  }

  public onAddSection(): void {
    this.accordionItems.push({
      id: UUID(),
    });
  }

  public onRemoveSection(section: AccordionItemModel): void {
    this.accordionItems = this.accordionItems.filter((item) => item.id !== section.id);
  }

  public onOpenFileManager(section: AccordionItemModel): void {
    if (!this.fileManager) {
      return;
    }
    this.fileManager
      .openManager({
        multiple: true,
        allowedTypes: ['all'],
        selectedItems: section.images || [],
      })
      .pipe(untilComponentDestroyed(this))
      .subscribe((res: domainModels.IFileDomainModel[]) => {
        section.images = res;
        this.addOrUpdateResourcesList(section, res);
      });
  }

  public onRemoveSectionImage(section: AccordionItemModel, file: IFileDomainModel): void {
    if (!this.designMode) {
      return;
    }

    section.images = section.images.filter((item) => item.id !== file.id);
    this.addOrUpdateResourcesList(section, section.images);
  }

  public override getDataset(): { [key: string]: any } {
    const dataset = super.getDataset() || {};
    dataset.accordionItems = this.accordionItems.map((item) => {
      const { resourceList, images, ...rest } = item;
      return rest;
    });
    return dataset;
  }

  public override setDataset(data: { [key: string]: any } = {}): void {
    super.setDataset(data);
    if (!data) {
      return;
    }

    this.loadResourcesListForAccordionItems([...(data?.accordionItems || [])]);
  }

  private addOrUpdateResourcesList(section: AccordionItemModel, files: domainModels.IFileDomainModel[]): void {
    const model: domainModels.ResourceListDomainModel = {
      id: section.resourceListId || undefined,
      resources: files.map(({ id }, index) => ({
        fileId: id,
        resourceOrder: index + 1,
      })),
    };

    if (!files.length) {
      delete section.resourceListId;
      delete section.resourceList;
      delete section.images;
      return;
    }

    const action = section.resourceListId ? this.resourcesListService.put : this.resourcesListService.post;

    this.subscriptions.push(
      action
        .bind(this.resourcesListService)(model)
        .subscribe((res: domainModels.ResourceListDomainModel) => {
          section.resourceListId = res.id;
          this.onResourcesChanged(section);
        })
    );
  }

  private onResourcesChanged(section: AccordionItemModel): void {
    if (!section.resourceListId) {
      return;
    }

    this.subscriptions.push(
      this.resourcesListService
        .get(section.resourceListId)
        .pipe(switchMap((response) => of(response.result.resources as domainModels.ResourceDomainModel[])))
        .subscribe((response) => {
          section.resourceList = response;
        })
    );
  }

  private loadResourcesListForAccordionItems(items: AccordionItemModel[]): void {
    const observables: Array<Observable<AccordionItemModel>> = [];

    for (const item of items) {
      if (!item.resourceListId) {
        observables.push(of(item));
        continue;
      }

      observables.push(
        this.resourcesListService
          .get(item.resourceListId)
          .pipe(untilComponentDestroyed(this), map(result => {
            const resourceList = result.result.resources as domainModels.ResourceDomainModel[] ?? [];
            return {
              ...item,
              resourceList,
              images: resourceList.map((rItem) => rItem.file as domainModels.IFileDomainModel),
            };
          }))
      );
    }

    combineLatest(observables).pipe(take(1)).subscribe(result => {
      this.accordionItems = result;
    });
  }

  public toggleItem(status: boolean, id: string): void {
    this.isActive = status;
    this.itemPosition = this.toggleContent.toggleContent(status, id);
  }

  public preventToggleAccordian(props: NgbPanelChangeEvent): void {
    if (!this.focusOnHeader) {
      return;
    }
    props.preventDefault();
  }

  public textEditingChange(event: boolean): void {
    if (this.textEditing && this.focusOnHeader) {
      return;
    }
    this.textEditing = event;
    this.isEdited.next(this.textEditing);
  }

  public inputFocused(val: boolean): void {
    this.focusOnHeader = val;
    this.textEditingChange(val);
  }

  public moveUp(id: string, event?: PointerEvent): void {
    event?.stopPropagation();

    const index = this.accordionItems.findIndex((e) => e.id === id);
    if (index > 0) {
      const el = this.accordionItems[index];
      this.accordionItems[index] = this.accordionItems[index - 1];
      this.accordionItems[index - 1] = el;
    }
  }

  public moveDown(id: string, event?: PointerEvent): void {
    event?.stopPropagation();

    const index = this.accordionItems.findIndex((e) => e.id === id);
    if (index !== -1 && index < this.accordionItems.length - 1) {
      const el = this.accordionItems[index];
      this.accordionItems[index] = this.accordionItems[index + 1];
      this.accordionItems[index + 1] = el;
    }
  }

  public checkPositionInArray(section: AccordionItemModel, first?: boolean): boolean {
    const index = this.accordionItems.indexOf(section);
    if (first) {
      return index === 0;
    } else {
      return index === this.accordionItems?.length - 1;
    }
  }
}
