import {
  DOCUMENT,
  isPlatformBrowser,
} from '@angular/common';
import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  domainModels,
  enums,
  gdprSettingsState,
  pagesListState,
} from '@jotter3/api-connector';
import { IGdprDataProvider } from '@jotter3/common-helpers';
import { Store } from '@ngrx/store';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { isNil } from 'lodash-es';
import * as moment from 'moment-timezone';
import { CookieService } from 'ngx-cookie-service';
import {
  BehaviorSubject,
  of,
} from 'rxjs';
import {
  filter,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

@Injectable()
export class GdprDataService extends OnDestroyMixin implements IGdprDataProvider {
  public cookiesAccept$ = new BehaviorSubject<enums.CookieState>(enums.CookieState.NOT_INITIALIZED);

  constructor(
    private store: Store,
    private router: Router,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  public checkIfCookiesAccepted(cookieKey: string, cookiesType: number): boolean {
    const cookeValue: enums.CookieState =
      enums.CookieState[this.cookieService.get(cookieKey) as enums.CookieState] || enums.CookieState.NOT_INITIALIZED;
    this.store.dispatch(
      gdprSettingsState.gdprSettingsActions.gdprSettingsActions.UpdateCookieAccepted({
        accepted: cookeValue,
      })
    );

    return cookiesType === 1 ? cookeValue !== enums.CookieState.NOT_INITIALIZED : cookeValue === enums.CookieState.ACCEPTED;
  }

  public onCookeResponse(accepted: enums.CookieState = enums.CookieState.NOT_INITIALIZED, cookieKey: string): void {
    if (accepted === enums.CookieState.REJECTED) {
      this.cookieService.deleteAll();
    }

    this.cookieService.set(cookieKey, accepted, moment().add(6, 'month').toDate(), '/');
    this.store.dispatch(gdprSettingsState.gdprSettingsActions.gdprSettingsActions.UpdateCookieAccepted({ accepted }));
  }

  public onNavigateToPolicy(): void {
    this.store
      .select(gdprSettingsState.gdprSettingsSelectors.gdprSettingsSelector.gdprSettingsSelector)
      .pipe(
        tap((gdpr) => {
          if (!gdpr.isLoaded && !gdpr.isLoading) {
            this.store.dispatch(gdprSettingsState.gdprSettingsActions.gdprSettingsActions.LoadGdprSettings());
          }
        }),
        filter((gdpr) => gdpr.isLoaded),
        take(1),
        untilComponentDestroyed(this),
        switchMap((gdpr) => {
          const { gdpr_page } = gdpr as { gdpr_page: domainModels.PageDataModel };

          return this.store
            .select(pagesListState.pagesSelectors.pagesListSelector)
            .pipe(switchMap((pages) => of(pages.find((page) => page.id === gdpr_page?.id))));
        })
      )
      .subscribe((privacyPolicyPage) => {
        if (!isPlatformBrowser(this.platformId)) {
          return;
        }

        if (isNil(privacyPolicyPage)) {
          window.open('https://www.schooljotter.com/privacy-policy', '_blank');
        } else {
          const url = this.router.serializeUrl(this.router.createUrlTree(privacyPolicyPage.urlSegments.flat()));
          window.open(url, '_blank');
        }
      });
  }

  public initGoogleAnalytics(site: domainModels.SiteDomainModel): void {
    const gTagManagerScript = this.document.createElement('script');
    gTagManagerScript.setAttribute('id', 'g-tag-script');
    gTagManagerScript.async = true;

    this.document.head.appendChild(gTagManagerScript);
    const gaCode = this.cookieService.get('ga_enabled');
    gTagManagerScript.setAttribute(
      'src',
      `//www.googletagmanager.com/gtag/js?id=${!gaCode ? 'disabled-' : ''}${site.googleAnalytics}`
    );
    // register google analytics
    const gaScript = this.document.createElement('script');
    gaScript.setAttribute('id', 'ga-script');
    gaScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', '${site.googleAnalytics}');
          `;
    this.document.head.appendChild(gaScript);
  }
}
