<section
  *ngIf="cookiesLayout >= 0"
  [ngClass]="{ open: isVisible }"
  id="ccc"
>
  <div
    (click)="hide()"
    id="ccc-overlay"
  ></div>

  <button
    (click)="toggle()"
    id="ccc-icon"
    aria-controls="ccc-module"
    accesskey="c"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 72.5 72.5"
      enable-background="new 0 0 72.5 72.5"
      xml:space="preserve"
    >
      <title>Cookie Control Icon</title>
      <g id="triangle">
        <path d="M0,0l72.5,72.5H0V0z"></path>
      </g>
      <g id="star">
        <path
          d="M33.2,51.9l-3.9-2.6l1.6-4.4l-4.7,0.2L25,40.6l-3.7,2.9l-3.7-2.9l-1.2,4.5l-4.7-0.2l1.6,4.4l-3.9,2.6l3.9,2.6l-1.6,4.4l4.7-0.2l1.2,4.5l3.7-2.9l3.7,2.9l1.2-4.5l4.7,0.2l-1.6-4.4L33.2,51.9z M24.6,55.3c-0.3,0.4-0.8,0.8-1.3,1s-1.1,0.3-1.9,0.3c-0.9,0-1.7-0.1-2.3-0.4s-1.1-0.7-1.5-1.4c-0.4-0.7-0.6-1.6-0.6-2.6c0-1.4,0.4-2.5,1.1-3.3c0.8-0.8,1.8-1.1,3.2-1.1c1.1,0,1.9,0.2,2.6,0.7s1.1,1.1,1.4,2L23,50.9c-0.1-0.3-0.2-0.5-0.3-0.6c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.7-0.2c-0.6,0-1.1,0.2-1.4,0.7c-0.2,0.4-0.4,0.9-0.4,1.7c0,1,0.1,1.6,0.4,2c0.3,0.4,0.7,0.5,1.2,0.5c0.5,0,0.9-0.1,1.2-0.4s0.4-0.7,0.6-1.2l2.3,0.7C25.2,54.3,25,54.8,24.6,55.3z"
        ></path>
      </g>
    </svg>
  </button>

  <div
    [ngClass]="{ active: isVisible }"
    id="ccc-module"
    role="region"
  >
    <div class="container ccc-container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <h2 id="ccc-title">Our use of cookies</h2>
              <p id="ccc-intro">
                We use necessary cookies to make our site work. We'd also like to set optional analytics cookies to help us
                improve it. We won't set optional cookies unless you enable them. Using this tool will set a cookie on your device
                to remember your preferences.
              </p>
              <div id="ccc-statement">
                <p>
                  For more detailed information about the cookies we use, see our
                  <a
                    (click)="navigateToPolicy()"
                    class="ccc-link ccc-tabbable"
                  >
                    Cookies page<span class="ccc-svg-element">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        data-icon="external-link"
                        viewBox="0 0 32 40"
                        x="0px"
                        y="0px"
                      >
                        <title>Cookie Control Link Icon</title>
                        <path
                          d="M32 0l-8 1 2.438 2.438-9.5 9.5-1.063 1.063 2.125 2.125 1.063-1.063 9.5-9.5 2.438 2.438 1-8zm-30 3c-.483 0-1.047.172-1.438.563-.391.391-.563.954-.563 1.438v25c0 .483.172 1.047.563 1.438.391.391.954.563 1.438.563h25c.483 0 1.047-.172 1.438-.563.391-.391.563-.954.563-1.438v-15h-3v14h-23v-23h15v-3h-16z"
                        ></path>
                      </svg>
                    </span>
                  </a>
                </p>
              </div>
            </div>
            <div class="col-12 d-flex justicy-content-between">
              <button
                (click)="submit(true)"
                id="ccc-all-cookies-button"
                class="ccc-notify-button ccc-button-solid ccc-tabbable ml-0 w-100"
              >
                Accept all cookies
              </button>
              <button
                (click)="submit(false)"
                id="ccc-reject-all-button"
                class="ccc-notify-button ccc-button-solid ccc-tabbable mr-0 w-100"
              >
                Reject all cookies
              </button>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h3 id="ccc-necessary-title">Necessary cookies</h3>
          <p id="ccc-necessary-description">
            Necessary cookies enable core functionality such as security, network management, and accessibility. You may disable
            these by changing your browser settings, but this may affect how the website functions.
          </p>
          <hr />
        </div>
      </div>
      <!-- *ngIf="googleAnaliticsExists" -->
      <div class="row">
        <div class="col-12">
          <div id="ccc-optional-categories">
            <div
              data-index="0"
              class="optional-cookie"
            >
              <div class="row">
                <div class="col-12 d-flex">
                  <h3 class="optional-cookie-header">Analytics cookies</h3>
                  <div class="checkbox-toggle ccc-tabbable">
                    <label
                      for="analytics_cookies"
                      class="checkbox-toggle-label"
                    >
                      <input
                        [(ngModel)]="gaCookiesAccepted"
                        (input)="onAnalyticsChange($event.target.checked)"
                        class="checkbox-toggle-input"
                        type="checkbox"
                        id="analytics_cookies"
                      />
                      <span class="checkbox-toggle-on">On</span>
                      <span class="checkbox-toggle-off">Off</span>
                      <span
                        class="checkbox-toggle-toggle"
                        data-index="0"
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
              <p>
                We'd like to set Google Analytics cookies to help us to improve our website by collecting and reporting
                information on how you use it. The cookies collect information in a way that does not directly identify anyone
              </p>
              <div class="ccc-alert"></div>
              <hr />
            </div>
          </div>
          <div id="ccc-optional-thirdparty">
            <div
              data-index="0"
              class="optional-cookie"
            >
              <div class="row">
                <div class="col-12 d-flex">
                  <h3 class="optional-cookie-header">Third-party cookies</h3>
                  <div class="checkbox-toggle ccc-tabbable">
                    <label
                      for="third_party_cookies"
                      class="checkbox-toggle-label"
                    >
                      <input
                        [(ngModel)]="thirdPartyCookiesAccepted"
                        (input)="onThirdPartyChange($event.target.checked)"
                        class="checkbox-toggle-input"
                        type="checkbox"
                        id="third_party_cookies"
                      />
                      <span class="checkbox-toggle-on">On</span>
                      <span class="checkbox-toggle-off">Off</span>
                      <span
                        class="checkbox-toggle-toggle"
                        data-index="0"
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
              <p>
                Third-party cookies are set by websites such as facebook. For example, when liking a facebook post embeded on this
                website a cookie will be stored. Facebook can then later access that cookie to identify visitors and see which
                websites they visited.
              </p>
              <div class="ccc-alert"></div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div>
            <button
              (click)="saveGaSettings()"
              id="ccc-dismiss-button"
              class="ccc-notify-button ccc-button-solid ccc-tabbable"
            >
              Save cookie settings
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
