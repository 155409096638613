<ngb-accordion
  #a="ngbAccordion"
  [animation]="true"
  [closeOthers]="!designMode"
  (panelChange)="preventToggleAccordian($event)"
>
  <ngb-panel
    *ngFor="let accordionItem of items; let i = index"
    (mouseenter)="toggleItem(true, elementId)"
    (mouseleave)="toggleItem(false, elementId)"
  >
    <ng-template ngbPanelHeader let-opened="opened">
      <button class="d-flex w-100 accordion-header-button" ngbPanelToggle>
        <div class="p-2 w-100 text-start">
          <input
            class="w-100"
            *ngIf="designMode"
            [(ngModel)]="accordionItem.header"
            (focus)="inputFocused(true)"
            (focusout)="inputFocused(false)"
            type="text"
          />
          <h5 class="m-0" *ngIf="!designMode">
            {{ accordionItem.header | translate }}
          </h5>
        </div>
        <div class="ms-auto p-2 expandSection" *ngIf="designMode">
          <i class="fas fa-caret-square-down"></i>
        </div>
        <div
          class="ms-auto p-2 removeSectionButton"
          *ngIf="designMode"
          (click)="onRemoveSection(accordionItem)"
        >
          <i class="fas fa-trash"></i>
        </div>
        <div class="ms-auto p-2 removeSectionButton" *ngIf="!designMode">
          <i [class]="opened ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
        </div>
        <i
          class="ms-auto p-2 action-ico-button fas fa-arrow-up"
          *ngIf="designMode && !checkPositionInArray(accordionItem, true)"
          (click)="moveUp(accordionItem.id, $event)"
          title="Move up"
        ></i>
        <i
          class="ms-auto p-2 action-ico-button fas fa-arrow-down"
          *ngIf="designMode && !checkPositionInArray(accordionItem, false)"
          (click)="moveDown(accordionItem.id, $event)"
          title="Move down"
        ></i>
      </button>
    </ng-template>
    <ng-template class="accordion-item" ngbPanelContent>
      <j3-text-component
        [(html)]="accordionItem.content"
        [focused]="true"
        [designMode]="designMode"
        (editing)="textEditingChange($event)"
      ></j3-text-component>
      <div class="section-images">
        <button
          class="btn btn-primary-1 m-2"
          *ngIf="designMode"
          (click)="onOpenFileManager(accordionItem)"
        >
          Add images
        </button>
        <div class="images-list d-flex" *ngIf="accordionItem.resourceList">
          <div
            class="section-image"
            *ngFor="let resource of accordionItem.resourceList"
          >
            <div
              class="ms-auto p-2 removeSectionButton"
              *ngIf="designMode"
              (click)="onRemoveSectionImage(accordionItem, resource.file)"
            >
              <i class="fas fa-trash"></i>
            </div>
            <j3-image
              [imgSrc]="resource.file.url"
              [title]="accordionItem.header"
              [alt]="accordionItem.header"
              sourceSize="m"
              loading="lazy"
            >
            </j3-image>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
<div class="d-flex justify-content-center" *ngIf="designMode">
  <button class="btn btn-primary-1 m-2" (click)="onAddSection()">
    Add section
  </button>
</div>
