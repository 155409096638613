import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  NavigationStart,
  Router,
} from '@angular/router';
import {
  AppType,
  J3TranslateService,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { isNil } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
} from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import { ThemeDataService } from '../../services';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'scrolling-component',
  displayName: 'Scrolling text',
  icon: 'double_left_arrows',
  category: SiteComponentCategory.TEXT,
})
@Component({
  templateUrl: './scrolling-text.component.html',
  styleUrls: ['./scrolling-text.component.scss'],
  animations: [
    trigger('scrollRightLeft', [
      state(
        'right',
        style({
          right: '0',
          transform: 'translateX(100%)',
        })
      ),
      state(
        'left',
        style({
          right: '100%',
          transform: 'translateX(0)',
        })
      ),
      transition('right => left', [animate('{{ speed }}')], { params: { speed: '17s' } }),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class ScrollingTextSiteComponent extends JotterSitesBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly #router = inject(Router);
  readonly tinyMCELoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly editorDisabled$: Observable<boolean> = combineLatest([
    this.isDesignMode$,
    this.isFocused$,
  ]).pipe(
    map(([
      isDesignMode,
      isFocused,
    ]) => !isDesignMode || !isFocused),
    tap((value) => {
      if (isNil(this.container?.element?.nativeElement)) {
        return;
      }

      const { nativeElement } = this.container.element;
      const { offsetHeight, offsetWidth } = nativeElement;

      if (value) {
        this.#renderer.removeStyle(nativeElement, 'min-height');
        this.#renderer.removeStyle(nativeElement, 'min-width');
        return;
      }

      this.#renderer.setStyle(nativeElement, 'min-height', `${offsetHeight}px`);
      this.#renderer.setStyle(nativeElement, 'min-width', `${offsetWidth}px`);
      this.tinyMCELoading$.next(true);
    })
  );

  readonly #themeDataService: ThemeDataService = inject(ThemeDataService);
  readonly #renderer: Renderer2 = inject(Renderer2);
  readonly #windowResize$: Observable<Event> = fromEvent(window, 'resize');

  private htmlVal: string;
  private mutationObserver: MutationObserver;
  public uuidValue: string = UUID();
  public scrollingSpeedVal = '17s';
  public startAnim = false;
  public animState = 'right';
  public scrollingTextContainerHeight = 30;
  @ViewChild('viewContainer', { read: ElementRef }) viewContainer: ElementRef;

  constructor(private container: ViewContainerRef, @Inject(MODULE_PROVIDER_TOKEN) private moduleProvider: ModuleProvider) {
    super(ScrollingTextSiteComponent);
  }

  @Input()
  public get html(): string {
    return this.htmlVal;
  }

  public set html(value: string) {
    this.htmlVal = value;
  }

  @Property({
    displayName: 'Speed',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: 'Very Slow',
        value: '45s',
      },
      {
        label: 'Slow',
        value: '28s',
      },
      {
        label: 'Normal',
        value: '17s',
      },
      {
        label: 'Fast',
        value: '8s',
      },
    ],
    defaultValue: '17s',
  })
  public get scrollingSpeed(): string {
    return this.scrollingSpeedVal;
  }

  public set scrollingSpeed(val: string) {
    this.scrollingSpeedVal = val;
  }

  public override ngAfterViewInit(): void {
    this.startAnim = true;

    if (!this.platformServer && !this.designMode && this.viewContainer?.nativeElement) {
      this.startObservePresenter();
    }

    if (!this.platformServer && !this.designMode) {
      this.updateScrollingTextHeight();
    }

    this.onDesingModeChanged.pipe(untilComponentDestroyed(this)).subscribe((value) => {
      if (!value) {
        if (this.platformServer) {
          this.updateScrollingTextHeight();
          return;
        }
        setTimeout(() => {
          this.updateScrollingTextHeight();
        }, 100);
      }
    });
  }

  updateScrollingTextHeight(): void {
    if (this.platformServer) {
      return;
    }
    setTimeout(
      () =>
        (this.scrollingTextContainerHeight = document
          .getElementById(`scrolling-text-content-${this.uuidValue}`)
          .getBoundingClientRect().height),
      0
    );
  }

  public triggerAnim(e: { toState: string }): void {
    if (this.platformServer) {
      return;
    }

    this.animState = 'right';
    if (e.toState === 'right') {
      this.animState = 'left';
    }
  }

  public override getDataset(): { [key: string]: any } {
    const dataset = super.getDataset();
    dataset['html'] = this.html;

    return dataset;
  }

  public override setDataset(data: { [key: string]: any }): void {
    super.setDataset(data);
    if (!data) {
      return;
    }
    this.html = data['html'] || '';
  }

  public ngOnInit(): void {
    if (this.platformServer) {
      return;
    }

    this.onDesingModeChanged.pipe(
      untilComponentDestroyed(this),
      filter(() => this.cssClass?.includes('append-to-'))
    ).subscribe(() => {
      this.appendToContainer('.content-element.content-element-scrolling-text', this.cssClass);
    });

    this.#router.events.pipe(untilComponentDestroyed(this), filter((event) => event instanceof NavigationStart)).subscribe(() => {
      document
        .querySelector('.content-element.content-element-scrolling-text' + `.${this.container.element.nativeElement.className}`)
        ?.remove();
    });

    if (this.cssClass?.includes('append-to-') && this.moduleProvider.applicationType === AppType.CLIENT) {
      setTimeout(() => {
        this.appendToContainer(
          '.content-element.content-element-scrolling-text',
          this.cssClass
        );
      }, 100);
    }


    if ((this.moduleProvider.applicationType === AppType.CLIENT && this.cssClass?.includes('append-to')) || this.moduleProvider.applicationType === AppType.ADMIN) {
      this.#windowResize$.pipe(
        debounceTime(500),
        filter(() => this.cssClass?.includes('append-to-')),
        untilComponentDestroyed(this)
      ).subscribe(() => {
        this.appendToContainer('.content-element.content-element-scrolling-text', this.cssClass);
      });
    }
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.platformBrowser && this.cssClass?.includes('append-to-')) {
      const appendContainerName = `.${this.cssClass.replace('append-to-', '')}`;
      const textBlockEl = document.querySelector(`${appendContainerName} .content-element.content-element-scrolling-text.${this.cssClass}`);
      if (!isNil(textBlockEl)) {
        textBlockEl.remove();
      }
    }
    this.mutationObserver?.disconnect();
  }

  public getThemeFontsData(): Observable<string[]> {
    return this.#themeDataService.getThemeFontsList();
  }

  private startObservePresenter(): void {
    this.mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
      mutations
        .filter((m) => (m.target as HTMLElement)?.classList?.contains('scrolling-text-content'))
        .forEach((el) => {
          this.scrollingTextContainerHeight = (el.target as HTMLElement).offsetHeight;
        });
    });

    this.mutationObserver.observe(this.viewContainer.nativeElement, {
      childList: true,
      attributes: false,
      characterData: false,
      subtree: true,
    });
  }

  public handleEditorInitialized(): void {
    this.tinyMCELoading$.next(false);
  }
}
