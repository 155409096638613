import {
  ModuleWithProviders,
  NgModule,
} from '@angular/core';

import {
  J3_CORE_MODULE_PROVIDER_TOKEN,
  ModuleConfig,
} from './providers';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
})
export class J3CoreModule {
  public static forRoot(config: ModuleConfig): ModuleWithProviders<J3CoreModule> {
    return {
      ngModule: J3CoreModule,
      providers: [
        {
          provide: J3_CORE_MODULE_PROVIDER_TOKEN,
          useValue: config,
        },
      ],
    };
  }
}
