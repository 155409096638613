import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Inject,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  NavigationStart,
  Router,
} from '@angular/router';
import {
  AppType,
  J3TranslateService,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { isNil } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  fromEvent,
  Observable,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import {
  ThemeDataService,
  ToggleContentService,
} from '../../services';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: [
    'text-content-component',
    'table-content-component',
  ],
  displayName: 'Text block',
  icon: 'align_left',
  category: SiteComponentCategory.TEXT,
})
@Component({
  selector: 'j3-text-component',
  templateUrl: './text-block.component.html',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
  styleUrls: ['text-block.component.scss'],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TextBlockElementComponent extends JotterSitesBaseComponent implements OnInit, OnDestroy {
  readonly #router = inject(Router);
  readonly tinyMCELoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly editorDisabled$: Observable<boolean> = combineLatest([
    this.isDesignMode$,
    this.isFocused$,
  ]).pipe(
    map(([
      isDesignMode,
      isFocused,
    ]) => !isDesignMode || !isFocused),
    tap((value) => {
      if (isNil(this.container?.element?.nativeElement)) {
        return;
      }

      const { nativeElement } = this.container.element;
      const { offsetHeight, offsetWidth } = nativeElement;

      if (value) {
        this.#renderer.removeStyle(nativeElement, 'min-height');
        this.#renderer.removeStyle(nativeElement, 'min-width');
        return;
      }

      this.#renderer.setStyle(nativeElement, 'min-height', `${offsetHeight}px`);
      this.#renderer.setStyle(nativeElement, 'min-width', `${offsetWidth}px`);
      this.tinyMCELoading$.next(true);
    })
  );

  readonly #themeDataService: ThemeDataService = inject(ThemeDataService);
  readonly #renderer: Renderer2 = inject(Renderer2);
  readonly #windowResize$: Observable<Event> = fromEvent(window, 'resize');

  public readonly fontsList$ = this.#themeDataService.getThemeFontsList().pipe(distinctUntilChanged());

  private htmlValue: string;
  isActive = false;
  elementId: string = UUID();
  itemPosition = '0px';
  @Output() editing = new EventEmitter<boolean>();

  constructor(
    private toggleContent: ToggleContentService,
    private container: ViewContainerRef,
    @Inject(MODULE_PROVIDER_TOKEN) private moduleProvider: ModuleProvider
  ) {
    super(TextBlockElementComponent);
  }

  @Input()
  get html(): string {
    const htmlData = isNil(this.htmlValue) ? '' : this.htmlValue;

    return htmlData.replace(/font-size: ?([\d]+pt)/gi, (a, b) => {
      switch (b) {
        case '8pt':
          return 'font-size: 0.666rem';
        case '10pt':
          return 'font-size: 0.833rem';
        case '12pt':
          return 'font-size: 1rem';
        case '14pt':
          return 'font-size: 1.166rem';
        case '18pt':
          return 'font-size: 1.5rem';
        case '24pt':
          return 'font-size: 2rem';
        case '36pt':
          return 'font-size: 3rem';
        default:
          return a;
      }
    });
  }

  set html(value: string) {
    this.htmlValue = value;
    this.htmlChange.emit(this.htmlValue);
  }

  @Output()
    htmlChange: EventEmitter<string> = new EventEmitter<string>();

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
  })
    header = '';

  override getDataset(): { [key: string]: any } {
    const dataset = super.getDataset();
    return {
      ...dataset,
      html: this.html,
    };
  }

  override setDataset(data: { [key: string]: any }): void {
    let html = data?.html || '';

    super.setDataset(data);
    if (this.moduleProvider.applicationType === AppType.CLIENT) {
      html = this.updateTableResponsive(html, true);
    }
    if (!data) {
      return;
    }
    this.html = html;
  }

  public ngOnInit(): void {
    if (this.platformServer) {
      return;
    }

    if (this.cssClass?.includes('append-to-') && this.moduleProvider.applicationType === AppType.CLIENT) {
      setTimeout(() => {
        this.appendToContainer(
          '.content-element.content-element-text-block',
          this.cssClass
        );
      }, 100);
    }


    this.#router.events.pipe(untilComponentDestroyed(this), filter((event) => event instanceof NavigationStart)).subscribe(() => {
      document
        .querySelector('.content-element.content-element-text-block' + `.${this.container.element.nativeElement.className}`)
        ?.remove();
    });

    if ((this.moduleProvider.applicationType === AppType.CLIENT && this.cssClass?.includes('append-to')) || this.moduleProvider.applicationType === AppType.ADMIN) {
      this.#windowResize$.pipe(
        debounceTime(500),
        filter(() => this.cssClass?.includes('append-to-')),
        untilComponentDestroyed(this)
      ).subscribe(() => {
        this.appendToContainer('.content-element.content-element-text-block', this.cssClass);
      });
    }

    this.onDesingModeChanged.pipe(
      untilComponentDestroyed(this),
      filter(() => this.cssClass?.includes('append-to-'))
    ).subscribe(() => {
      this.appendToContainer('.content-element.content-element-text-block', this.cssClass);
      this.html = this.updateTableResponsive(this.html, !this.designMode);
    });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.platformBrowser && this.cssClass?.includes('append-to-')) {
      const appendContainerName = `.${this.cssClass.replace('append-to-', '')}`;
      const textBlockEl = document.querySelector(`${appendContainerName} .content-element.content-element-text-block.${this.cssClass}`);
      if (!isNil(textBlockEl)) {
        textBlockEl.remove();
      }
    }
  }

  public toggleItem(status: boolean, id: string): void {
    this.isActive = status;
    this.itemPosition = this.toggleContent.toggleContent(status, id);
  }

  public animationOverflow(status: boolean, id: string): void {
    this.toggleContent.toggleOverflow(status, id);
  }

  private updateTableResponsive(src: string, wrapTable: boolean): string {
    let htmlData = (src ?? '').replace(/<div[^>]*? class\s*=\s*["']?table-responsive[^>]+>((.|\n|))<table/gi, '<table');
    htmlData = htmlData.replace(/<\/table>((.|\n|))<\/div>/gi, '</table>');
    if (wrapTable) {
      htmlData = htmlData.replace(/(<table[^>]*>[\s\S]*?<\/table>)/gi, '<div class="table-responsive">$1</div>');
    }
    return htmlData;
  }

  public handleEditorInitialized(): void {
    this.tinyMCELoading$.next(false);
  }
}
