import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { PageDomainModel } from '../../../domain-models';
import { PagesState } from '../reducers';

export const selectPagesListState = createFeatureSelector<PagesState>('pagesList');

export const pagesListStateSelector = createSelector(selectPagesListState, (state) => state);

export const pagesListSelector = createSelector(pagesListStateSelector, (state) => {
  return Object.values(state.entities);
});

export const selectPageById = (id: string): MemoizedSelector<object, PageDomainModel> =>
  createSelector(pagesListSelector, pages => pages.find(page => page.id === id));

export const arePagesListLoading = createSelector(pagesListStateSelector, (state) => state.isLoading);

export const arePagesListLoaded = createSelector(pagesListStateSelector, (state) => state.isLoaded);

export const selectHomePage = createSelector(pagesListSelector, state => state.find(entity => entity.homePage && entity.draftState === 1));
