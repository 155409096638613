import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NGB_MODAL_DATA } from '@jotter3/api-connector';
import {
  NguCarousel,
  NguCarouselConfig,
} from '@ngu/carousel';

@Component({
  templateUrl: './galleries-preview.component.html',
  styleUrls: ['./galleries-preview.component.scss'],
})
export class GalleriesPreviewComponent implements OnInit, AfterViewInit {
  carouselBanner: NguCarouselConfig;
  @ViewChild('galleryCarousel') galleryCarousel: NguCarousel<any>;
  constructor(@Inject(NGB_MODAL_DATA) public data: any, private cdref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.carouselBanner = {
      grid: {
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        xl: 1,
        all: 0,
      },
      slide: 1,
      speed: 1000,
      interval: {
        timing: 5000,
        initialDelay: 1000,
      },
      point: {
        visible: false,
        hideOnSingleSlide: true,
      },
      load: 1,
      loop: true,
      touch: false,
    };
  }

  ngAfterViewInit(): void {
    this.galleryCarousel.moveTo(this.data.index, true);
    this.cdref.detectChanges();
  }
}
