import {
  Pipe,
  PipeTransform,
} from '@angular/core';

import { FileInfoType } from '../enums';

@Pipe({
  name: 'fileInfo',
})
export class FileInfoPipe implements PipeTransform {
  transform(value: string, fileInfoType?: FileInfoType): string {
    const valueArr = value.split('.');

    return valueArr?.length <= 1
      ? value
      : fileInfoType === FileInfoType.EXTENSION
        ? `.${valueArr[valueArr.length - 1]}`
        : valueArr.slice(0, -1).join('.');
  }
}
