import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import {
  Inject,
  inject,
  Injectable,
} from '@angular/core';
import { ApiResponse } from '@jotter3/wa-core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  Observable,
  of,
} from 'rxjs';
import { urlJoin } from 'url-join-ts';

import { domainModels } from '../../../index';
import {
  ModuleConfig,
  ModuleOptionsInjectionToken,
} from '../../common';
import { TemplatesStoreActions } from './templates.actions';


@Injectable()
export class TemplatesEffects {
  readonly #actions$ = inject(Actions);
  constructor(@Inject(ModuleOptionsInjectionToken) private moduleConfig: ModuleConfig, private http: HttpClient) {}

  loadRequested$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(TemplatesStoreActions.loadTrigger),
      mergeMap(() => this.load('/templates')),
      map((data) => {
        const templatesList: domainModels.TemplateDefinitionDomainModel[] = [];
        data.result.forEach((template, index) => {
          templatesList.push({
            ...template,
            id: index.toString(),
          });
        });
        return templatesList;
      }),
      map((response: domainModels.TemplateDefinitionDomainModel[]) => response
        ? TemplatesStoreActions.loadSuccess({ entity: response })
        : TemplatesStoreActions.loadFailed({ errors: [response] })),
      catchError(err => of(TemplatesStoreActions.loadFailed({ errors: [err] })))
    ));

  private load(resource: string, queryParams?: HttpParams): Observable<ApiResponse<domainModels.TemplateDefinitionDomainModel[]>> {
    const baseUrl = this.moduleConfig.THEMES_API;
    return this.http
      .get<ApiResponse<domainModels.TemplateDefinitionDomainModel[]>>(urlJoin(baseUrl, resource), {
        params: queryParams,
      })
      .pipe(
        map((res) => {
          const { result } = res;
          return result
            ? res
            : {
              result: res as any,
              pagination: {},
              statusCode: 200,
              success: true,
              error: undefined,
            };
        }),
        map((res) => ({
          ...res,
          success: true,
          statusCode: 200,
        })),
        catchError((err: HttpErrorResponse): Observable<ApiResponse<domainModels.TemplateDefinitionDomainModel[]>> => {
          const { error, status } = err;
          return of({
            result: [],
            pagination: {},
            statusCode: status,
            error,
            success: false,
          });
        })
      );
  }

}
