import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { pagesListState } from '@jotter3/api-connector';
import {
  AppType,
  MODULE_PROVIDER_TOKEN,
} from '@jotter3/common-helpers';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { zip } from 'rxjs';
import {
  filter,
  map,
} from 'rxjs/operators';

import { TemplateDataProvider } from '../../providers';

@TemplateComponent({
  selector: 'jotter-tplcmp-schoollogo',
  displayName: 'Logo',
  icon: 'settings',
  defaultClass: 'school-data-logo',
})
@Component({
  selector: 'jotter-tplcmp-schoollogo',
  template: `
    <div *ngrxLet='logoData$ as logoData'>
      <a *ngIf="logoData" class='school-data-logo--anchor school-data-logo--image' [routerLink]="['/',isAdminSide ? logoData.pageUrl + logoData.homePageUrl : logoData.homePageUrl]">
        <img
          [ngClass]="className"
          [src]="logoData.logoImg[logoData.logoImageSize ?? 'm']"
          class="school-data-logo--img"
          alt="{{ logoData.schoolName }}{{ logoData.schoolMotto ? ' - ' + logoData.schoolMotto : '' }}"
          title="{{ logoData.schoolName }}{{ logoData.schoolMotto ? ' - ' + logoData.schoolMotto : '' }}"
          loading="lazy"
        />
      </a>
    </div>
  `,
  styleUrls: ['./school-logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SchoolLogoComponent extends TemplateBaseComponent implements OnInit, OnDestroy {
  readonly #dataProvider = inject(TemplateDataProvider);
  readonly #store = inject(Store);
  readonly #moduleProvider = inject(MODULE_PROVIDER_TOKEN);


  readonly #homePageURL = this.#store.select(pagesListState.pagesSelectors.selectHomePage).pipe(
    filter(page => !isNil(page)),
    map(page => page.url)
  );

  readonly logoData$ = zip([
    this.#homePageURL,
    this.#dataProvider.getData().pipe(filter(data => !isNil(data?.logoImg))),
  ]).pipe(
    map(([
      homePageUrl,
      data,
    ]) => ({
      logoImg: data.logoImg,
      logoImageSize: data.customContent?.logoImageSize,
      pageUrl: data.pageUrl,
      schoolName: data.schoolName,
      schoolMotto: data.motto,
      homePageUrl,
    }))
  );

  public isAdminSide = false;
  @Input() size: string | undefined;

  constructor() {
    super(SchoolLogoComponent);
  }

  ngOnInit(): void {
    this.isAdminSide = this.#moduleProvider.applicationType === AppType.ADMIN;
  }
}
