<iframe
  *ngIf="fbEmbededLink && !this.isBlocked"
  [src]="fbEmbededSafeLink"
  [width]="fbWidth"
  [height]="height ? height : 300"
  class="embedded-fb-iframe"
  scrolling="no"
  frameborder="0"
  allowfullscreen="true"
  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
>
</iframe>

<div
  *ngIf="this.isBlocked"
  class="content-blocked-notice"
>
  <h3>Facebook Blocked</h3>
  <p>
    This content element cannot load as you have declined to enable cookies. To re-enable cookies please click
    <a
      (click)="editorAPI.acceptCookies()"
      class="content-blocked-notice--link"
      >here</a
    >
  </p>
  <p>
    <small
      >Our privacy policy can be found here:
      <a
        (click)="editorAPI.onNavigateToPolicy()"
        class="content-blocked-notice--link"
        >Privacy Policy</a
      ></small
    >
  </p>
</div>
