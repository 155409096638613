<ng-container
  *ngIf="(!isHomePage || showOnHomePage) && menuItems$ | async as menu"
>
  <nav class="breadcrumb-wrapper" *ngIf="menu.length" aria-label="breadcrumb">
    <ul class="breadcrumb">
      <ng-container
        *ngFor="let item of menu; let first = first; let last = last"
      >
        <li
          class="breadcrumb-item"
          *ngIf="first && !item.homePage && item.parentId === null"
        >
          <ng-container
            *ngTemplateOutlet="linkItem; context: { $implicit: homePage }"
          ></ng-container>
        </li>
        <li class="breadcrumb-item" *ngIf="!last; else lastItem">
          <ng-container
            *ngTemplateOutlet="
              linkItem;
              context: { $implicit: item, first: first }
            "
          ></ng-container>
        </li>
        <ng-template #lastItem>
          <li class="breadcrumb-item active" aria-current="page">
            <ng-container
              *ngTemplateOutlet="
                linkItem;
                context: { $implicit: item, last: last }
              "
            ></ng-container>
          </li>
        </ng-template>
      </ng-container>
    </ul>
  </nav>
</ng-container>

<ng-template #linkItem let-item let-last="last" let-first="first">
  <span
    class="{{ separatorCustomClass }} breadcrumb-icon"
    *ngIf="
      separatorValue &&
      item &&
      ((item.homePage && item.parentId !== null) || !item.homePage)
    "
  >
    <ng-container class="breadcrumb-item" *ngIf="separatorAsText; else icon">
      {{ separatorValue }}
    </ng-container>
    <ng-template #icon>
      <i class="breadcrumb-icon--i {{ separatorValue }}"></i>
    </ng-template>
  </span>

  <ng-container *ngIf="!last; else linkEl">
    <a class="breadcrumb-link" href="{{ urlLinksPre + item.url }}">
      <span
        class="{{ homeIconCustomClass }} breadcrumb-home-icon"
        *ngIf="item.homePage && item.parentId === null && homeIconValue !== ''"
        [ngClass]="{ 'breadcrumb-icon-only': homePageIconOnly }"
      >
        <i class="breadcrumb-home-icon--i {{ homeIconValue }}"></i>
      </span>
      <ng-container
        *ngIf="item.homePage && homePageIconOnly; else showTitle"
      ></ng-container>
      <ng-template #showTitle>
        <span class="breadcrumb-title">{{ item.title | translate }}</span>
      </ng-template>
    </a>
  </ng-container>
  <ng-template #linkEl>
    <span
      class="{{ homeIconCustomClass }} breadcrumb-home-icon"
      *ngIf="item.homePage && item.parentId === null"
      [ngClass]="{ 'breadcrumb-icon-only': homePageIconOnly }"
    >
      <i class="breadcrumb-home-icon--i {{ homeIconValue }}"></i>
    </span>
    <ng-container
      *ngIf="item.homePage && homePageIconOnly; else showTitle"
    ></ng-container>
    <ng-template #showTitle>
      <span class="breadcrumb-title">{{ item.title | translate }}</span>
    </ng-template>
  </ng-template>
</ng-template>
