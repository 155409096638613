import { Injectable } from '@angular/core';
import {
  ApiResponse,
  LHSBracketsDataQueryAdapter,
} from '@jotter3/wa-core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { isNil } from 'lodash-es';
import {
  combineLatest,
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  switchMap,
} from 'rxjs/operators';

import { UserProfileDomainModel } from '../../../domain-models';
import { PeopleListDomainService } from '../../../domain-services';
import { peopleListActions } from '../actions';

@Injectable()
export class PeopleListEffects {
  LoadPeopleList = createEffect(() =>
    this.actions$.pipe(
      ofType(peopleListActions.LoadPeopleList),
      switchMap((action) => {
        const { limit, group } = action;
        return this.createApiRequest(limit, 1, group).pipe(
          switchMap((response) => {
            const { pagination } = response;
            const observableApiCalls: Observable<ApiResponse<UserProfileDomainModel[]>>[] = [of(response)];

            if (pagination.totalPages > 1) {
              for (let page = 2; page <= pagination.totalPages; page++) {
                observableApiCalls.push(this.createApiRequest(limit, page, group));
              }
            }

            return combineLatest(observableApiCalls).pipe(
              switchMap((responseArray) =>
                of(
                  peopleListActions.LoadPeopleListComplete({
                    peopleList: responseArray.map((x) => x.result).flat(),
                  })
                )),
              catchError((error) => of(peopleListActions.LoadPeopleListFailed({ error })))
            );
          }),
          catchError((error) => of(peopleListActions.LoadPeopleListFailed({ error })))
        );
      })
    ));

  constructor(
    private actions$: Actions,
    private peopleListDomainService: PeopleListDomainService,
    private lhsBracketsDataQueryAdapter: LHSBracketsDataQueryAdapter
  ) {}

  private createApiRequest(
    size: number = 100,
    page: number = 1,
    group?: string
  ): Observable<ApiResponse<UserProfileDomainModel[]>> {
    const queryParams = this.lhsBracketsDataQueryAdapter.toQueryParam({
      pagination: {
        size,
        page,
      },
    });

    if (isNil(group)) {
      return this.peopleListDomainService.getAll(queryParams);
    }
    return this.peopleListDomainService.getProfilesNotBelongToTheGroup(queryParams, group);
  }
}
