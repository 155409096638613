import {
  inject,
  Injectable,
} from '@angular/core';
import { UrlTree } from '@angular/router';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  filter,
  map,
  Observable,
  switchMap,
} from 'rxjs';

import {
  appStoreSelectors,
  SiteStoreActions,
  siteStoreSelectors,
} from '../+state';

@Injectable({ providedIn: 'root' })
export class SiteGuard {
  readonly #store: Store = inject(Store);

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.#store.select(appStoreSelectors.selectIsLoaded).pipe(
      filter(loaded => loaded),
      switchMap(() => combineLatest([
        this.#store.select(siteStoreSelectors.selectIsLoaded),
        this.#store.select(siteStoreSelectors.selectIsLoading),
      ])),
      concatLatestFrom(() => this.#store.select(appStoreSelectors.selectDomain)),
      filter(([
        [
          loaded,
          loading,
        ],
        domain,
      ]) => {
        if (loading) {
          return false;
        }

        if (!loading && !loaded) {
          this.#store.dispatch(SiteStoreActions.loadTrigger({ domain }));
        }

        return loaded;
      }),
      map(() => true)
    );
  }
}
