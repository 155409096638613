import {
  Component,
  Inject,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  siteStoreSelectors,
  templatesSelectors,
} from '@jotter3/api-connector';
import {
  APP_ENV,
  Environment,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import { siteTemplateHelpers } from '@jotter3/core';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  filter,
  map,
  switchMap,
} from 'rxjs/operators';

import { TemplateDataProvider } from '../../providers';

@TemplateComponent({
  selector: 'jotter-tplcmp-image',
})
@Component({
  template: `
    <img
      [src]="imagelink"
      [ngClass]="imgClass"
      [alt]="imgTitle"
      [title]="imgTitle"
      loading="lazy"
    />
  `,
})
export class ImageElementComponent extends TemplateBaseComponent implements OnInit {
  readonly #store: Store = inject(Store);
  constructor(
    @Inject(APP_ENV) public env: Environment,
    private readonly templateDataProvider: TemplateDataProvider,
    @Inject(MODULE_PROVIDER_TOKEN) private moduleProvider: ModuleProvider
  ) {
    super(ImageElementComponent);
  }

  @Input() imageSRC = '';
  @Input() imgClass = '';
  @Input() imgTitle = '';
  public imagelink = '';

  ngOnInit(): void {
    this.templateDataProvider
      .getSitesData()
      .pipe(
        untilComponentDestroyed(this),
        switchMap(() => this.#store.select(templatesSelectors.selectEntities)),
        map(templates => siteTemplateHelpers.convertVariantsToTemplateDefinitions(templates)),
        concatLatestFrom(() => this.#store.select(siteStoreSelectors.selectEntity)),
        map(([
          templates,
          site,
        ]) => siteTemplateHelpers.getSiteOrDefaultTemplate(templates, site.themeName).folder),
        filter((x) => !!x?.length)
      )
      .subscribe((themeFolder) => {
        this.imagelink = `${this.env.SITES_TEMPLATES_URL.replace(/\/$/, '')}/${themeFolder}/${this.imageSRC}`;
      });
  }
}
