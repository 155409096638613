import {
  Action,
  createReducer,
  on,
} from '@ngrx/store';

import { GeneralSettingsDomainModel } from '../../../domain-models';
import { generalSettingsActions } from '../actions';

export interface GeneralSettingsState extends Partial<GeneralSettingsDomainModel> {
  isLoaded: boolean;
  isLoading: boolean;
}

export interface SettingsState {
  general: GeneralSettingsState;
}

export const initialSettingsState: SettingsState = {
  general: {
    isLoaded: false,
    isLoading: false,
  },
};

const settingsReturn = createReducer<SettingsState>(
  initialSettingsState,
  on(generalSettingsActions.LoadGeneralSettings, (state) => ({
    ...state,
    general: {
      ...state.general,
      isLoading: true,
    },
  })),
  on(generalSettingsActions.LoadGeneralSettingsComplete, (state, { generalSettings }) => ({
    ...state,
    general: {
      ...state.general,
      ...generalSettings,
      isLoaded: true,
      isLoading: false,
    },
  })),
  on(generalSettingsActions.LoadGeneralSettingsFailed, (state) => ({
    ...state,
    general: {
      ...state.general,
      isLoading: false,
      isLoaded: false,
    },
  })),
  on(generalSettingsActions.UpdateGeneralSettings, (state) => ({
    ...state,
    general: {
      ...state.general,
      isLoading: true,
      isLoaded: false,
    },
  })),
  on(generalSettingsActions.UpdateGeneralSettingsFailed, (state) => ({
    ...state,
    general: {
      ...state.general,
      isLoading: false,
      isLoaded: false,
    },
  })),
  on(generalSettingsActions.UpdateGeneralSettingsComplete, (state, { generalSettings }) => ({
    ...state,
    general: {
      ...state.general,
      ...generalSettings,
      isLoading: false,
      isLoaded: true,
    },
  }))
);

export const settingsReducer = (state: SettingsState = initialSettingsState, action: Action): SettingsState =>
  settingsReturn(state, action);
