<ng-container *ngIf="$socialMedia | async; let socialMedia">
  <span
    class="{{ iconCustomClass }} school-data-icon"
    *ngIf="iconValue && itemType === ItemTypeEnum.TOGGLE"
    [ngClass]="{ 'school-data-icon-toggle': itemType === ItemTypeEnum.TOGGLE }"
    (click)="toggle()"
  >
    <i class="school-data-icon--i {{ iconValue }}"></i>
  </span>
  <div
    class="social-icons school-data-social-icons--value d-flex"
    id="school-data-social-icons-{{ itemId }}"
    [@toggle]="
      itemType === ItemTypeEnum.TOGGLE && !isVisible ? 'closed' : 'open'
    "
    [ngClass]="{ toggle: itemType === ItemTypeEnum.TOGGLE }"
  >
    <a
      class="theme-social-icon theme-facebook"
      *ngIf="socialMedia?.['facebook'] as facebook"
      [href]="facebook?.['url']"
      title="Facebook"
      target="_blank"
    >
      <span>
        <i class="icon-facebook"></i>
      </span>
    </a>
    <a
      class="theme-social-icon theme-twitter"
      *ngIf="socialMedia?.['twitter'] as twitter"
      [href]="twitter?.['url']"
      title="Twitter"
      target="_blank"
    >
      <span>
        <i class="fa-brands fa-x-twitter"></i>
      </span>
    </a>
    <a
      class="theme-social-icon theme-instagram"
      *ngIf="socialMedia?.['instagram'] as instagram"
      [href]="instagram?.['url']"
      title="Instagram"
      target="_blank"
    >
      <span>
        <i class="icon-instagram"></i>
      </span>
    </a>
    <a
      class="theme-social-icon theme-vimeo"
      *ngIf="socialMedia?.['vimeo'] as vimeo"
      [href]="vimeo?.['url']"
      title="Vimeo"
      target="_blank"
    >
      <span>
        <i class="icon-vimeo"></i>
      </span>
    </a>
    <a
      class="theme-social-icon theme-youtube"
      *ngIf="socialMedia?.['youtube'] as youtube"
      [href]="youtube?.['url']"
      title="youtube"
      target="_blank"
    >
      <span>
        <i class="icon-youtube"></i>
      </span>
    </a>
  </div>
</ng-container>
