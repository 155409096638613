import { OrderBy } from '@jotter3/common-helpers';

import { DropdownItemModel } from '../models';


export const ATTACHMENTS_ORDER: Array<DropdownItemModel<OrderBy, string>> = [
  {
    label: 'Name (A-Z)',
    value: OrderBy.NAME_ASC,
  },
  {
    label: 'Name (Z-A)',
    value: OrderBy.NAME_DESC,
  },
  {
    label: 'Start date (Publish date) ASC',
    value: OrderBy.DATE_ASC,
  },
  {
    label: 'Start date (Publish date) DESC',
    value: OrderBy.DATE_DESC,
  },
];
