import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  AppType,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';

enum ColourVariant {
  DEFAULT = 'default',
  BLACK_AND_WHITE = 'black-white',
  YELLOW_AND_BLACK = 'yellow-black',
  BLUE_AND_YELLOW = 'blue-yellow'
}

enum Size {
  SMALL = 'accessibility-small',
  NORMAL = 'accessibility-normal',
  LARGE = 'accessibility-large'
}

@TemplateComponent({
  selector: 'jotter-tplcmp-accessibility',
  displayName: 'High contrast',
  icon: 'settings',
  defaultClass: 'accessibility',
})
@Component({
  selector: 'jotter-tplcmp-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss'],
  animations: [
    trigger('toggle', [
      state(
        'open',
        style({
          height: '*',
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          padding: '0',
        })
      ),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
  ],
})
export class AccessibilityComponent extends TemplateBaseComponent implements OnDestroy {
  public Size = Size;

  public showSettings = false;
  public ColourVariant = ColourVariant;

  @Input() iconValue = 'icon-cog';
  @Input() iconCustomClass = '';

  constructor(
    @Inject(MODULE_PROVIDER_TOKEN) private moduleProvider: ModuleProvider,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(AccessibilityComponent);
  }

  public toggleSettings(): void {
    this.showSettings = !this.showSettings;
  }

  public changeColours(contrastType: ColourVariant): void {
    if (contrastType === ColourVariant.DEFAULT) {
      this.document.documentElement.removeAttribute('high-contrast');
      return;
    }
    this.document.documentElement.setAttribute('high-contrast', contrastType);
  }

  public override ngOnDestroy(): void {
    this.document.documentElement.removeAttribute('high-contrast');
  }

  public onChangeSize(size: Size): void {
    const templateResolver =
      this.moduleProvider.applicationType === AppType.ADMIN
        ? (this.document.getElementsByTagName('cms-template-resolver')[0] as HTMLElement | null)
        : this.document.documentElement;

    if (!templateResolver) {
      return;
    }

    switch (size) {
      case Size.LARGE:
        templateResolver.style.fontSize = '25px';
        break;
      case Size.NORMAL:
        templateResolver.style.fontSize = '16px';
        break;
      case Size.SMALL:
        templateResolver.style.fontSize = '13px';
        break;
    }
  }
}
