import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { TenantState } from '../reducers';

export const selectTenantState = createFeatureSelector<TenantState>('tenant');

export const tenantStateSelector = createSelector(selectTenantState, (state) => state);

export const tenantDataSelector = createSelector(tenantStateSelector, (state) => {
  const { isLoaded, isLoading, ...tenant } = state;
  return tenant;
});

export const areTenantDataLoading = createSelector(tenantStateSelector, (state) => state.isLoading);

export const areTenantDataLoaded = createSelector(tenantStateSelector, (state) => state.isLoaded);
