<div class="page-wrap d-flex flex-row align-items-center">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center">
        <span class="display-1 d-block">{{ pageInfo.title }}</span>
        <div class="mb-4 lead">{{ pageInfo.description }}</div>
      </div>
    </div>
  </div>
</div>
