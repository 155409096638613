import {
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostBinding,
  inject,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import {
  ModifierType,
  Property,
  SiteBaseComponent,
} from '@jotter3/sites-abstract';
import { isNil } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({ template: '' })
export abstract class JotterSitesBaseComponent extends SiteBaseComponent implements OnDestroy, AfterViewInit {
  readonly #platformId = inject(PLATFORM_ID);
  protected subscriptions: Subscription[] = [];

  #viewInitializedValue = false;
  #cssClass: string | undefined;

  public get platformBrowser(): boolean {
    return isPlatformBrowser(this.#platformId);
  }

  public get platformServer(): boolean {
    return isPlatformServer(this.#platformId);
  }

  @Property({
    displayName: 'CSS Class',
    modifierType: ModifierType.TEXT,
    group: 'General',
    advanced: true,
  })
  @HostBinding('class')
  public get cssClass(): string | undefined {
    return this.#cssClass;
  }

  public set cssClass(value: string | undefined) {
    this.#cssClass = value;
  }

  public get viewInitialized(): boolean {
    return this.#viewInitializedValue;
  }

  public ngAfterViewInit(): void {
    this.#viewInitializedValue = true;
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions?.forEach((sub) => sub.unsubscribe());
  }

  public appendToContainer(elementContentClass: string, elementCustomCssClass: string): void {
    if (!elementCustomCssClass?.includes('append-to-')) {
      return;
    }

    const htmlBlockItem = document.querySelector(`${elementContentClass}.${elementCustomCssClass}`);
    const appendContainerName = `.${elementCustomCssClass.replace('append-to-', '')}`;
    const appendContainer = document.querySelector(appendContainerName);

    const contentPresenter = document.querySelector(`.site-content-section-column-items-content-presenter > .${elementCustomCssClass}`);
    const contentParent = contentPresenter.parentNode as HTMLElement;

    if (isNil(contentParent) || isNil(contentPresenter)) {
      return;
    }

    if (
      elementCustomCssClass.includes('append-to') &&
      (document.body.classList.contains('jotter-site--client') || document.body.classList.contains('jotter-site--preview')) &&
      !isNil(appendContainer)
    ) {
      appendContainer.appendChild(htmlBlockItem);
      contentParent.classList.add('empty');
    } else {
      contentPresenter.appendChild(htmlBlockItem);
      contentParent.classList.remove('empty');
    }
  }
}
