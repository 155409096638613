<picture *ngIf="imgSrc">
  <ng-container *ngFor="let imageData of imagesList | ngrxPush">
    <source
      [srcset]="imageData.srcset"
      [attr.media]="imageData.mediaSize"
      [type]="imageData.type"
    />
  </ng-container>
  <img
    #nativeSource
    [src]="
      arrayOrSingle(sourceSize)
        ? imgSrc[mainImageSize]
        : urlExist(imgSrc[mainImageSize])
        ? imgSrc[sourceSize]
        : generateImageLink(imgSrc['s'], mainImageSize)
    "
    [alt]="alt ? alt : 'image'"
    [title]="title ?? ''"
    loading="lazy"
  />
</picture>
