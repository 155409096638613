import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { loaderReducer } from '../reducers';

export const selectLoaderState = createFeatureSelector<loaderReducer.LoaderDataState>('loaderStatus');

export const loaderStateSelector = createSelector(selectLoaderState, (state) => state);

export const loaderSelector = createSelector(loaderStateSelector, (state) => {
  const entities = Object.values(state);

  return entities;
});

export const isLoaderLoading = createSelector(loaderStateSelector, (state) => state);

export const isLoaderLoaded = createSelector(loaderStateSelector, (state) => state);
