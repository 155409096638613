import {
  Component,
  OnInit,
} from '@angular/core';
import { J3TranslateService } from '@jotter3/common-helpers';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import {
  filter,
  map,
} from 'rxjs/operators';

import { TemplateDataProvider } from '../../providers';

@TemplateComponent({
  selector: 'jotter-tplcmp-motto',
  displayName: 'Motto',
  icon: 'settings',
  defaultClass: 'school-data-motto',
})
@Component({
  selector: 'jotter-tplcmp-motto',
  templateUrl: './motto.component.html',
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class MottoComponent extends TemplateBaseComponent implements OnInit {
  $motto: Observable<string | undefined> | undefined;
  constructor(private dataProvider: TemplateDataProvider) {
    super(MottoComponent);
  }

  ngOnInit(): void {
    this.$motto = this.dataProvider.getData().pipe(filter(res => !isNil(res?.motto)), map((res) => {
      return res.motto.replace(/\n/g, '<br />');
    }));
  }
}
