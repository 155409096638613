import { Injectable } from '@angular/core';
import { ApiService } from '@jotter3/wa-core';

import { GroupDomainModel } from '../domain-models';
import { BaserDomainService } from './base.domain-service';

@Injectable({ providedIn: 'root' })
export class MyGroupsDomainService extends BaserDomainService<GroupDomainModel> {
  constructor(apiService: ApiService) {
    super('groups/my', apiService);
  }
}
