import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PushModule } from '@ngrx/component';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';

import {
  commonComponents,
  SitesPopupBoxComponent,
} from './components';
import { MinMaxValueDirective } from './directives';
import { commonFormFields } from './formly-common/fields';
import { pipes } from './pipes';
import { ResourcesManagerService } from './services';

@NgModule({
  declarations: [
    ...commonComponents,
    ...commonFormFields,
    ...pipes,
    SitesPopupBoxComponent,
    MinMaxValueDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forChild(),
    TranslateModule.forChild(),
    MatProgressSpinnerModule,
    EditorModule,
    NgbModule,
    PushModule,
  ],
  exports: [
    ...commonComponents,
    ...commonFormFields,
    ...pipes,
    EditorModule,
    NgbModule,
  ],
  providers: [ResourcesManagerService],
})
export class CommonComponentsModule {}
