export enum PermissionRole {
  // sites
  ROLE_SITES_LOGIN = 'ROLE_SITES_LOGIN',
  ROLE_SITE_MNGOWNDRAFTS = 'ROLE_SITE_MNGOWNDRAFTS',
  ROLE_SITE_MNGALLDRAFTS = 'ROLE_SITE_MNGALLDRAFTS',
  ROLE_SITE_PUBCONTENT = 'ROLE_SITE_PUBCONTENT',
  ROLE_SITES_RESTOREPAGEREV = 'ROLE_SITES_RESTOREPAGEREV',
  ROLE_SITES_ACCESSTPLBUILDER = 'ROLE_SITES_ACCESSTPLBUILDER',
  ROLE_SITES_MANAGETHEMES = 'ROLE_SITES_MANAGETHEMES',
  ROLE_SITES_ACCESSPRIVATECONTENT = 'ROLE_SITES_ACCESSPRIVATECONTENT',

  // files
  ROLE_FILES_LOGIN = 'ROLE_FILES_LOGIN',
  ROLE_FILES_CREATE = 'ROLE_FILES_CREATE',
  ROLE_FILES_SOFTDEL = 'ROLE_FILES_SOFTDEL',
  ROLE_FILES_HARDDEL = 'ROLE_FILES_HARDDEL',

  // categories
  ROLE_CATEG_LOGIN = 'ROLE_CATEG_LOGIN',
  ROLE_CATEG_CREATE = 'ROLE_CATEG_CREATE',
  ROLE_CATEG_SOFTDEL = 'ROLE_CATEG_SOFTDEL',
  ROLE_CATEG_HARDDEL = 'ROLE_CATEG_HARDDEL',

  // settings
  ROLE_SETTINGS_LOGIN = 'ROLE_SETTINGS_LOGIN',
  ROLE_SETTINGS_MNGALL = 'ROLE_SETTINGS_MNGALL',

  // user management
  ROLE_USERMNG_LOGIN = 'ROLE_USERMNG_LOGIN',
  ROLE_USERMNG_MNGUSERS = 'ROLE_USERMNG_MNGUSERS',
  ROLE_USERMNG_MNGGROUPS = 'ROLE_USERMNG_MNGGROUPS',
  ROLE_USERMNG_MNGROLES = 'ROLE_USERMNG_MNGROLES',
  ROLE_USERMNG_MNGLOGIN = 'ROLE_USERMNG_MNGLOGIN',

  // Organisations
  ROLE_ORG_LOGIN = 'ROLE_ORG_LOGIN',
  ROLE_ORG_EDITORGMETA = 'ROLE_ORG_EDITORGMETA',
  ROLE_ORG_LOGINSUBORG = 'ROLE_ORG_LOGINSUBORG',
  ROLE_ORG_CREATEORG = 'ROLE_ORG_CREATEORG',
  ROLE_ORG_MNGDOMAINS = 'ROLE_ORG_MNGDOMAINS',

  // links
  ROLE_LINKS_LOGIN = 'ROLE_LINKS_LOGIN',
  ROLE_LINKS_VIEWPUB = 'ROLE_LINKS_VIEWPUB',
  ROLE_LINKS_VIEWGRP = 'ROLE_LINKS_VIEWGRP',
  ROLE_LINKS_VIEWALL = 'ROLE_LINKS_VIEWALL',
  ROLE_LINKS_MNGGRP = 'ROLE_LINKS_MNGGRP',
  ROLE_LINKS_MNGALL = 'ROLE_LINKS_MNGALL',

  // notices
  ROLE_NOTICES_LOGIN = 'ROLE_NOTICES_LOGIN',
  ROLE_NOTICES_VIEWPUB = 'ROLE_NOTICES_VIEWPUB',
  ROLE_NOTICES_VIEWGRP = 'ROLE_NOTICES_VIEWGRP',
  ROLE_NOTICES_VIEWALL = 'ROLE_NOTICES_VIEWALL',
  ROLE_NOTICES_MNGGRP = 'ROLE_NOTICES_MNGGRP',
  ROLE_NOTICES_MNGALL = 'ROLE_NOTICES_MNGALL',
  ROLE_NOTICES_MNGOWNDRAFTS = 'ROLE_NOTICES_MNGOWNDRAFTS',
  ROLE_NOTICES_MNGALLDRAFTS = 'ROLE_NOTICES_MNGALLDRAFTS',

  // letters
  ROLE_LETTERS_LOGIN = 'ROLE_LETTERS_LOGIN',
  ROLE_LETTERS_VIEWALL = 'ROLE_LETTERS_VIEWALL',
  ROLE_LETTERS_VIEWPUB = 'ROLE_LETTERS_VIEWPUB',
  ROLE_LETTERS_VIEWGRP = 'ROLE_LETTERS_VIEWGRP',
  ROLE_LETTERS_MNGGRP = 'ROLE_LETTERS_MNGGRP',
  ROLE_LETTERS_MNGALL = 'ROLE_LETTERS_MNGALL',
  ROLE_LETTERS_MNGOWNDRAFTS = 'ROLE_LETTERS_MNGOWNDRAFTS',
  ROLE_LETTERS_MNGALLDRAFTS = 'ROLE_LETTERS_MNGALLDRAFTS',

  // profile
  ROLE_PROFILE_VIEWOWN = 'ROLE_PROFILE_VIEWOWN',
  ROLE_PROFILE_VIEWSTUDENT = 'ROLE_PROFILE_VIEWSTUDENT',
  ROLE_PROFILE_VIEWALL = 'ROLE_PROFILE_VIEWALL',

  // groups
  ROLE_GROUP_VIEWALLMEMBERS = 'ROLE_GROUP_VIEWALLMEMBERS',
  ROLE_GROUP_VIEWSUPMEMBERS = 'ROLE_GROUP_VIEWSUPMEMBERS',

  // Dashboard
  ROLE_DASH_VIEWORGSWITCH = 'ROLE_DASH_VIEWORGSWITCH',
  ROLE_DASH_LOGIN = 'ROLE_DASH_LOGIN',

  // Mobile Centre
  ROLE_MOBILEAPP_LOGIN = 'ROLE_MOBILEAPP_LOGIN',

  // news
  ROLE_NEWS_LOGIN = 'ROLE_NEWS_LOGIN',
  ROLE_NEWS_VIEWPUB = 'ROLE_NEWS_VIEWPUB',
  ROLE_NEWS_VIEWGRP = 'ROLE_NEWS_VIEWGRP',
  ROLE_NEWS_VIEWALL = 'ROLE_NEWS_VIEWALL',
  ROLE_NEWS_MNGGRP = 'ROLE_NEWS_MNGGRP',
  ROLE_NEWS_MNGALL = 'ROLE_NEWS_MNGALL',
  ROLE_NEWS_MNGOWNDRAFTS = 'ROLE_NEWS_MNGOWNDRAFTS',
  ROLE_NEWS_MNGALLDRAFTS = 'ROLE_NEWS_MNGALLDRAFTS',

  // galleries
  ROLE_GALLERIES_LOGIN = 'ROLE_GALLERIES_LOGIN',
  ROLE_GALLERIES_VIEWPUB = 'ROLE_GALLERIES_VIEWPUB',
  ROLE_GALLERIES_VIEWGRP = 'ROLE_GALLERIES_VIEWGRP',
  ROLE_GALLERIES_VIEWALL = 'ROLE_GALLERIES_VIEWALL',
  ROLE_GALLERIES_MNGGRP = 'ROLE_GALLERIES_MNGGRP',
  ROLE_GALLERIES_MNGALL = 'ROLE_GALLERIES_MNGALL',

  // scheduler
  ROLE_SCHEDULER_LOGIN = 'ROLE_SCHEDULER_LOGIN',
  ROLE_SCHEDULER_MANAGE = 'ROLE_SCHEDULER_MANAGE',

  // forms
  ROLE_FORMS_LOGIN = 'ROLE_FORMS_LOGIN',
  ROLE_FORMS_VIEWPUB = 'ROLE_FORMS_VIEWPUB',
  ROLE_FORMS_VIEWGRP = 'ROLE_FORMS_VIEWGRP',
  ROLE_FORMS_VIEWALL = 'ROLE_FORMS_VIEWALL',
  ROLE_FORMS_MNGGRP = 'ROLE_FORMS_MNGGRP',
  ROLE_FORMS_MNGALL = 'ROLE_FORMS_MNGALL',
  ROLE_FORMS_VIEWFORMSUBMISSIONS = 'ROLE_FORMS_VIEWFORMSUBMISSIONS',

  // calendar
  ROLE_CALENDAR_LOGIN = 'ROLE_CALENDAR_LOGIN',
  ROLE_CALENDAR_VIEWPUB = 'ROLE_CALENDAR_VIEWPUB',
  ROLE_CALENDAR_VIEWGRP = 'ROLE_CALENDAR_VIEWGRP',
  ROLE_CALENDAR_VIEWALL = 'ROLE_CALENDAR_VIEWALL',
  ROLE_CALENDAR_MNGGRP = 'ROLE_CALENDAR_MNGGRP',
  ROLE_CALENDAR_MNGALL = 'ROLE_CALENDAR_MNGALL',

  // policies
  ROLE_POLICIES_MNGALL = 'ROLE_POLICIES_MNGALL',

  // other
  ROLE_FULL_TOKEN = 'ROLE_FULL_TOKEN'
}
