import { InjectionToken } from '@angular/core';

import { AppType } from '../enums';

export const MODULE_PROVIDER_TOKEN: InjectionToken<ModuleProvider> = new InjectionToken<ModuleProvider>('MODULE_PROVIDER_TOKEN');

export class ModuleProvider {
  private readonly appType: AppType;

  constructor(applicationType: AppType) {
    this.appType = applicationType;
  }

  public get applicationType(): AppType {
    return this.appType;
  }
}
