import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { isNil } from 'lodash-es';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';

import { GeneralSettingsDomainService } from '../../../domain-services';
import { generalSettingsActions } from '../actions';

@Injectable()
export class GeneralSettingsEffects {
  loadGeneralSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(generalSettingsActions.LoadGeneralSettings),
      mergeMap(() =>
        this.generalSettingsDomainService.get('').pipe(
          map(({ result: { customContent, ...rest } }) => ({
            ...rest,
            customContent: isNil(customContent) ? {} : JSON.parse(`${customContent}`),
          })),
          map(result => generalSettingsActions.LoadGeneralSettingsComplete({
            generalSettings: result,
          })),
          catchError((error) => of(generalSettingsActions.LoadGeneralSettingsFailed({ error })))
        ))
    ));

  updateGeneralSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(generalSettingsActions.UpdateGeneralSettings),
      mergeMap(({ generalSettings }) =>
        this.generalSettingsDomainService.put({
          ...generalSettings,
          customContent: JSON.stringify(generalSettings.customContent ?? {}) as any,
        }).pipe(
          switchMap(() =>
            of(
              generalSettingsActions.LoadGeneralSettings(),
              generalSettingsActions.UpdateGeneralSettingsComplete({ generalSettings })
            )),
          catchError((error) => of(generalSettingsActions.UpdateGeneralSettingsFailed({ error })))
        ))
    ));

  constructor(private actions$: Actions, private generalSettingsDomainService: GeneralSettingsDomainService) {}
}
