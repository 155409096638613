import {
  Component,
  Input,
} from '@angular/core';
import { domainModels } from '@jotter3/api-connector';

@Component({
  selector: 'j3-events-list-item',
  templateUrl: './events-list-item.component.html',
})
export class EventsListItemComponent {
  @Input() elTemplate: number;
  @Input() event: domainModels.CalendarEventDomainModel;
}
