<div
  class="w-100 h-100 d-block content-builder-template-mock"
  *ngIf="templateDesignMode"
>
  {{ 'Content presenter' | translate }}
</div>
<ng-container>
  <div
    class="site-content-container"
    *ngIf="!templateDesignMode"
    cdkDropListGroup
  >
    <div
      class="row site-content-section-row site-content-section-row-{{
        rowId
      }} {{ contentRow.cssClass }}"
      *ngFor="let contentRow of $api.rows; let rowId = index"
      [class.designer]="$api.designMode"
      [class.selected]="
        selectedRow?.uuid === contentRow.uuid && $api.designMode
      "
      (click)="setSelectedRow(contentRow)"
    >
      <div class="site-content-section-column-wrapper">
        <div
          class="col-12 col-md{{
            section.size === 0 ? '' : '-' + section.size
          }} site-content-section-column site-content-section-column-{{
            sectionId
          }}
            "
          *ngFor="let section of contentRow.sections; let sectionId = index"
          [class.selected]="
            selectedSection?.uuid === section.uuid && $api.designMode
          "
          [class.designer]="$api.designMode"
          (click)="setSelectedSection(section)"
        >
          <div
            class="site-content-section-column-items-container container-for-draggable-items"
          >
            <div
              class="sub-container-for-draggable-items"
              #destinationArrayForDraggableItems="cdkDropList"
              [cdkDropListData]="section.items"
              [cdkDropListConnectedTo]="$draggableContainers"
              (cdkDropListDropped)="onItemDropped($event)"
              cdkDropList
            >
              <div
                class="site-content-section-column-items-add-element-wrapper"
                *ngIf="$api.designMode && section && section.items?.length > 0"
              >
                <button
                  class="site-content-section-column-items-add-element-button site-content-section-column-items-add-element-button-top"
                  (click)="onAddElement(section, 0)"
                  color="primary"
                >
                  <span class="material-icons-outlined"> add_circle </span>
                </button>
              </div>
              <ng-container
                *ngFor="
                  let component of section.items;
                  let componentIndex = index
                "
              >
                <element-content-presenter
                  class="site-content-section-column-items-content-presenter content-presenter"
                  *audienceTargeting="{
                    groups: component?.dataset?.selectedGroups,
                    component: component,
                    messageRef: testTemplate
                  }"
                  [designMode]="!!$api?.designMode"
                  [containerAPI]="$api"
                  [item]="component"
                  [class.selected]="
                    $api?.designMode && section.selectedComponent === component
                  "
                  [class.hide-settings-button-list]="isEdited | async"
                  [selected]="
                    !!$api?.designMode &&
                    section.selectedComponent === component
                  "
                  [addedItem]="addedItem"
                  (click)="onSelectComponent(component, section)"
                ></element-content-presenter>
                <ng-template #testTemplate>
                  <p
                    class="p-3 w-100 text-center bg-secondary text-white ng-star-inserted"
                  >
                    No permissions or Element is private
                  </p>
                </ng-template>
                <div
                  class="site-content-section-column-items-add-element-wrapper"
                >
                  <button
                    class="site-content-section-column-items-add-element-button site-content-section-column-items-add-element-button-content-presenter"
                    *ngIf="$api?.designMode && section"
                    (click)="onAddElement(section, componentIndex + 1)"
                    color="primary"
                  >
                    <span class="material-icons-outlined"> add_circle </span>
                  </button>
                </div>
              </ng-container>
              <div
                class="site-content-section-column-items-add-element-container"
                *ngIf="
                  $api?.designMode && section && section.items?.length === 0
                "
              >
                <button
                  class="site-content-section-column-items-add-element-button site-content-section-column-items-add-element-button-empty-row"
                  (click)="onAddElement(section, 0)"
                  color="primary"
                >
                  <span class="material-icons-outlined"> add_circle </span>
                </button>
              </div>
            </div>
            <div
              class="site-content-section-column-options-container"
              *ngIf="$api?.designMode"
            >
              <div class="site-content-section-column-options-info">
                <mat-icon class="site-content-section-column-options-info-icon"
                  >open_with</mat-icon
                >
                Column (Size:
                {{ section.size === 0 ? 'auto' : section.size + '/12' }})
              </div>
              <button
                class="site-content-section-column-options-buttons-toggle"
                #toggleButton
                (click)="
                  toggleSectionColumnMenu(contentRow.sections[sectionId].uuid)
                "
              >
                <mat-icon
                  class="site-content-section-column-options-buttons-toggle-icon"
                  >menu</mat-icon
                >
              </button>
              <div
                class="site-content-section-column-options-buttons site-content-section-column-options-buttons-{{
                  sectionId
                }}"
                #menu
                [class.active-options]="
                  currentMenuColumnVisible?.uuid ===
                    contentRow.sections[sectionId].uuid &&
                  currentMenuColumnVisible?.uuid
                "
              >
                <div
                  class="site-content-section-column-options-buttons-wrapper"
                >
                  <button
                    class="site-content-section-column-options-buttons-item"
                    [class.disabled]="sectionId < 1"
                    [disabled]="sectionId < 1"
                    (click)="
                      swapSection(sectionId, sectionDirection.LEFT, contentRow)
                    "
                  >
                    <mat-icon
                      class="site-content-section-column-options-info-icon"
                      >arrow_back</mat-icon
                    >
                    <span class="site-content-section-column-options-info-text">
                      Move column left
                    </span>
                  </button>
                  <button
                    class="site-content-section-column-options-buttons-item"
                    [class.disabled]="
                      sectionId === contentRow.sections.length - 1
                    "
                    [disabled]="sectionId === contentRow.sections.length - 1"
                    (click)="
                      swapSection(sectionId, sectionDirection.RIGHT, contentRow)
                    "
                  >
                    <mat-icon
                      class="site-content-section-column-options-info-icon"
                      >arrow_forward</mat-icon
                    >
                    <span class="site-content-section-column-options-info-text">
                      Move column right
                    </span>
                  </button>
                </div>
                <div
                  class="site-content-section-column-options-buttons-wrapper"
                >
                  <button
                    class="site-content-section-column-options-buttons-item"
                    [class.disabled]="contentRow.sections.length > 11"
                    [disabled]="contentRow.sections.length > 11"
                    (click)="$api.addSection(true)"
                  >
                    <mat-icon
                      class="site-content-section-column-options-info-icon"
                      >add</mat-icon
                    >
                    <span class="site-content-section-column-options-info-text">
                      Add column on left
                    </span>
                  </button>
                  <button
                    class="site-content-section-column-options-buttons-item"
                    [class.disabled]="contentRow.sections.length > 11"
                    [disabled]="contentRow.sections.length > 11"
                    (click)="$api.addSection()"
                  >
                    <mat-icon
                      class="site-content-section-column-options-info-icon"
                      >add</mat-icon
                    >
                    <span class="site-content-section-column-options-info-text">
                      Add column on right
                    </span>
                  </button>
                </div>

                <div
                  class="site-content-section-column-options-buttons-wrapper"
                >
                  <button
                    class="site-content-section-column-options-buttons-item"
                    [class.disabled]="
                      selectedSection?.size >= 12 ||
                      (sectionWidth > 11 && selectedSection?.size !== 0)
                    "
                    [disabled]="
                      selectedSection?.size >= 12 ||
                      (sectionWidth > 11 && selectedSection?.size !== 0)
                    "
                    (click)="changeSectionSize(sectionSize.INCREMENT)"
                  >
                    <mat-icon
                      class="site-content-section-column-options-info-icon"
                      >add_circle_outline</mat-icon
                    >
                    <span class="site-content-section-column-options-info-text">
                      <ng-container
                        *ngIf="selectedSection?.size === 0; else size"
                      >
                        Set column size
                      </ng-container>
                      <ng-template #size> Increase column size </ng-template>
                    </span>
                  </button>
                  <button
                    class="site-content-section-column-options-buttons-item"
                    [class.disabled]="selectedSection?.size <= 1"
                    [disabled]="selectedSection?.size <= 1"
                    (click)="changeSectionSize(sectionSize.DECREMENT)"
                  >
                    <mat-icon
                      class="site-content-section-column-options-info-icon"
                      >remove_circle_outline</mat-icon
                    >
                    <span class="site-content-section-column-options-info-text">
                      Decrease column size
                    </span>
                  </button>
                  <button
                    class="site-content-section-column-options-buttons-item"
                    [class.disabled]="selectedSection?.size === 0"
                    [disabled]="selectedSection?.size === 0"
                    (click)="setSectionSiteAuto()"
                  >
                    <mat-icon
                      class="site-content-section-column-options-info-icon"
                      >compare_arrows</mat-icon
                    >
                    <span class="site-content-section-column-options-info-text">
                      Set column size to auto
                    </span>
                  </button>
                </div>

                <div
                  class="site-content-section-column-options-buttons-wrapper"
                >
                  <button
                    class="site-content-section-column-options-buttons-item"
                    [class.disabled]="contentRow.sections.length <= 1"
                    [disabled]="contentRow.sections.length <= 1"
                    (click)="onRemoveSection(section)"
                  >
                    <mat-icon
                      class="site-content-section-column-options-info-icon"
                      >close</mat-icon
                    >
                    <span class="site-content-section-column-options-info-text">
                      Delete column
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="site-content-section-row-options-container"
        *ngIf="$api?.designMode"
      >
        <div class="site-content-section-row-options-menu">
          <div class="site-content-section-row-options-info">
            <mat-icon class="site-content-section-row-options-info-icon"
              >open_with</mat-icon
            >
            Row {{ rowId + 1 }}
          </div>
          <button
            class="site-content-section-row-options-buttons-toggle site-content-section-row-options-buttons-toggle-{{
              rowId
            }}"
            #toggleButton
            (click)="toggleSectionRowMenu(contentRow.uuid)"
          >
            <mat-icon
              class="site-content-section-row-options-buttons-toggle-icon site-content-section-row-options-buttons-toggle-icon-{{
                rowId
              }}"
              >menu</mat-icon
            >
          </button>
        </div>

        <div
          class="site-content-section-row-options-buttons site-content-section-row-options-buttons-{{
            rowId
          }}"
          #menu
          [class.active-options]="
            currentMenuRowVisible?.uuid === contentRow.uuid
          "
        >
          <div class="site-content-section-row-options-buttons-wrapper">
            <button
              class="site-content-section-row-options-buttons-item site-content-section-row-options-buttons-item-{{
                rowId
              }}"
              [class.disabled]="rowId < 1"
              [disabled]="rowId < 1"
              (click)="swapRow(rowId, rowDirection.UP)"
            >
              <mat-icon class="site-content-section-row-options-info-icon"
                >arrow_upward</mat-icon
              >
              Move row up
            </button>
            <button
              class="site-content-section-row-options-buttons-item site-content-section-row-options-buttons-item-{{
                rowId
              }}"
              [class.disabled]="
                $api.rows.length < 1 || rowId === $api.rows.length - 1
              "
              [disabled]="
                $api.rows.length < 1 || rowId === $api.rows.length - 1
              "
              (click)="swapRow(rowId, rowDirection.DOWN)"
            >
              <mat-icon class="site-content-section-row-options-info-icon"
                >arrow_downward</mat-icon
              >
              Move row down
            </button>
          </div>

          <div class="site-content-section-row-options-buttons-wrapper">
            <button
              class="site-content-section-row-options-buttons-item site-content-section-row-options-buttons-item-{{
                rowId
              }}"
              (click)="$api.addRow(true)"
            >
              <mat-icon class="site-content-section-row-options-info-icon"
                >add</mat-icon
              >
              Add row above
            </button>
            <button
              class="site-content-section-row-options-buttons-item site-content-section-row-options-buttons-item-{{
                rowId
              }}"
              (click)="$api.addRow()"
            >
              <mat-icon class="site-content-section-row-options-info-icon"
                >add</mat-icon
              >
              Add row below
            </button>
          </div>

          <div class="site-content-section-row-options-buttons-wrapper">
            <button
              class="site-content-section-row-options-buttons-item site-content-section-row-options-buttons-item-{{
                rowId
              }}"
              (click)="rowSettings()"
            >
              <mat-icon class="site-content-section-row-options-info-icon"
                >settings</mat-icon
              >
              Row settings
            </button>
          </div>
          <div class="site-content-section-row-options-buttons-wrapper">
            <button
              class="site-content-section-row-options-buttons-item site-content-section-row-options-buttons-item-{{
                rowId
              }}"
              [class.disabled]="$api.rows.length <= 1"
              [disabled]="$api.rows.length <= 1"
              (click)="removeRow()"
            >
              <mat-icon class="site-content-section-row-options-info-icon"
                >close</mat-icon
              >
              Delete row
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
