import { isPlatformBrowser } from '@angular/common';
import {
  inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import {
  AppStoreActions,
  TenantInfoDomainModel,
} from '@jotter3/api-connector';
import {
  AppTenantInfoService,
  SSR_TENANT_INFO,
} from '@jotter3/core';
import { Store } from '@ngrx/store';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { cloneDeep } from 'lodash-es';

import { J3Request } from '../../../express-common';

@Injectable()
export class AppInitializerService {
  readonly #store: Store = inject(Store);
  readonly #request: J3Request = inject<J3Request>(REQUEST, { optional: true });
  readonly #platformId = inject(PLATFORM_ID);
  readonly #appTenantInfoService: AppTenantInfoService = inject(AppTenantInfoService);
  readonly #tenantInfo = inject<TenantInfoDomainModel>(SSR_TENANT_INFO, { optional: true });

  public async initialize(): Promise<any> {
    if (isPlatformBrowser(this.#platformId)) {
      this.#appTenantInfoService.loadFromState();
      this.triggerEnteredAction();
      return Promise.resolve();
    }

    const tenantInfo = cloneDeep(this.#request.tenantInfo);

    this.#appTenantInfoService.tenant = { ...(this.#tenantInfo ?? tenantInfo) };

    if (this.#request?.cacheEntry) {
      this.#request.cacheEntry.domainName = this.#appTenantInfoService.domain;
    }

    this.triggerEnteredAction();

    return Promise.resolve();
  }

  private triggerEnteredAction(): void {
    const { domain, tenant } = this.#appTenantInfoService;

    this.#store.dispatch(AppStoreActions.entered({
      domain,
      tenant: { ...tenant },
    }));
  }
}

export const initializeApplicationState =
  (provider: AppInitializerService): (() => Promise<any>) =>
    async() =>
      await provider.initialize();
