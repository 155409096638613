import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';

@Component({
  selector: 'j3-toggle-info-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
   <div
    class="toggle-content-enabled"
  >
    {{'CORE.toggleContentEnabled' | translate}}
  </div>
  `,
})
export class J3ToggleInfoComponent {
}
