import {
  Component,
  InjectionToken,
  Type,
} from '@angular/core';
import { SiteBaseComponent } from '@jotter3/sites-abstract';

import { SnackBarCustom } from '../models/snackbar/snackbar.model';

const CMS_CONTENT_COMPONENTS: InjectionToken<Array<Type<SiteBaseComponent>>> = new InjectionToken('CMS_CONTENT_COMPONENTS');
const CMS_TEMPLATE_COMPONENTS: InjectionToken<Array<Type<Component>>> = new InjectionToken('CMS_TEMPLATE_COMPONENTS');

const SNACKBAR_COMPONENT: InjectionToken<Type<SnackBarCustom>> = new InjectionToken('SNACKBAR_COMPONENT');

const CMS_TEMPLATE_DATA_ATTRIBUTE = 'data-template-element';
const CMS_TEMPLATE_IGNORE = 'data-template-ignore';

export { CMS_CONTENT_COMPONENTS,
  CMS_TEMPLATE_COMPONENTS,
  CMS_TEMPLATE_DATA_ATTRIBUTE,
  CMS_TEMPLATE_IGNORE,
  SNACKBAR_COMPONENT };
