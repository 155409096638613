import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import {
  makeStateKey,
  TransferState,
} from '@angular/platform-browser';
import { domainModels } from '@jotter3/api-connector';
import { J3TranslateProvider } from '@jotter3/common-helpers';
import { ApiResponse } from '@jotter3/wa-core';
import {
  Observable,
  of,
} from 'rxjs';
import {
  map,
  tap,
} from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { urlJoin } from 'url-join-ts';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class J3TranslateClientProvider implements J3TranslateProvider {
  readonly translateCacheStateKey = makeStateKey<Record<string, string>>('J3_TRANSLATE_STATE');
  readonly cacheArray: Record<string, string>;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: object,
    private readonly transferState: TransferState,
    private readonly httpClient: HttpClient
  ) {
    this.cacheArray = isPlatformServer(platformId) ? {} : transferState.get(this.translateCacheStateKey, {});
  }

  public getTranslation(key: string, targetLanguageCode: string): Observable<string> {
    const cacheKey = `${targetLanguageCode}/${Md5.hashStr(key)}`;
    const cacheValue = this.cacheArray[cacheKey];

    if (cacheValue) {
      return of(cacheValue);
    }

    const body: domainModels.TranslateRequestDomainModel = {
      contents: [key],
      targetLanguageCode,
      sourceLanguageCode: 'en',
    };

    const { TRANSLATE_API } = environment.ApiConnectorConfig;

    return this.httpClient.post<ApiResponse<any>>(urlJoin(TRANSLATE_API, 'translate'), body).pipe(
      tap((res) => {
        if (!res.success || !res.result.length) {
          return;
        }

        this.cacheArray[cacheKey] = res.result[0].translatedText;
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(this.translateCacheStateKey, this.cacheArray);
        }
      }),
      map((res) => (res.success || res.result.length ? res.result[0].translatedText : key))
    );
  }
}
