import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import {
  enums,
  gdprSettingsState,
  siteStoreSelectors,
} from '@jotter3/api-connector';
import {
  AppType,
  IGdprDataProvider,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { isNil } from 'lodash-es';
import * as moment from 'moment-timezone';
import { CookieService } from 'ngx-cookie-service';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import { SNACKBAR_COMPONENT } from '../../common';
import {
  RowDirectionMove,
  SectionDirectionMove,
  SectionSizeMode,
} from '../../enums';
import { contentBuilderModel } from '../../models';
import { SnackBarCustom } from '../../models/snackbar/snackbar.model';
import { ContentBuilderManagerService } from '../../services';
import { ElementSelectorComponent } from '../element-selector/element-selector.component';
import { RowSettingsComponent } from '../row-settings/row-settings.component';

const selector = 'cms-content-builder';

@TemplateComponent({
  selector,
  displayName: 'Content',
  icon: 'settings',
})
@Component({
  selector,
  templateUrl: './content-builder.component.html',
  styleUrls: ['./content-builder.component.scss'],
})
export class ContentBuilderComponent extends TemplateBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  #activeComponent: contentBuilderModel.ContentItemModel;

  $draggableContainers: any[] = [];
  rowDirection = RowDirectionMove;
  sectionDirection = SectionDirectionMove;
  sectionSize = SectionSizeMode;
  selectedRow: contentBuilderModel.ContentRowModel = {
    sections: [],
    uuid: '',
  };
  currentMenuColumnVisible: contentBuilderModel.CurrentSectionModel;
  currentMenuRowVisible: contentBuilderModel.CurrentRowModel;
  isEdited: BehaviorSubject<boolean>;
  sectionWidth: number;
  public addedItem = false;
  private contentBlockedObserver: Observable<boolean>;
  private readonly cookieKey = 'J3CookiesAccepted';

  @Input() selectedSection: contentBuilderModel.ContentSectionModel;
  @ViewChildren('destinationArrayForDraggableItems')
    destinationArrayForDraggableItems: QueryList<any>;
  @ViewChildren('toggleButton') toggleButton: QueryList<ElementRef>;
  @ViewChildren('menu') menu: QueryList<ElementRef>;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(MODULE_PROVIDER_TOKEN) private moduleProvider: ModuleProvider,
    @Optional() @Inject(SNACKBAR_COMPONENT) private snackBarData: SnackBarCustom,
    private renderer: Renderer2,
    private $changeDetector: ChangeDetectorRef,
    public modal: NgbModal,
    public $api: ContentBuilderManagerService,
    private cookieService: CookieService,
    private store: Store,
    @Optional()
    @Inject(IGdprDataProvider)
    private iGdprDataProvider: IGdprDataProvider
  ) {
    super(ContentBuilderComponent);
  }

  public contentBlocked(): Observable<boolean> {
    if (!this.contentBlockedObserver) {
      this.contentBlockedObserver = this.store
        .select(gdprSettingsState.gdprSettingsSelectors.gdprSettingsSelector.gdprSettingsSelector)
        .pipe(
          untilComponentDestroyed(this),
          map(() => {
            if (!isPlatformBrowser(this.platformId) || this.moduleProvider.applicationType === AppType.ADMIN) {
              return false;
            }

            if (!window?.navigator?.cookieEnabled) {
              return true;
            }

            const cookieValue = enums.CookieState[this.cookieService.get(this.cookieKey) as enums.CookieState];

            return cookieValue === enums.CookieState.REJECTED;
          })
        );
    }
    return this.contentBlockedObserver;
  }

  public ngOnInit(): void {
    if (this.templateDesignMode) {
      return;
    }

    this.$api.addElement = this.addElement.bind(this);
    this.$api.removeElement = this.removeElement.bind(this);
    this.$api.addRow = this.addRow.bind(this);
    this.$api.removeRow = this.removeRow.bind(this);
    this.$api.addSection = this.addSection.bind(this);
    this.$api.changeSectionSize = this.changeSectionSize.bind(this);
    this.$api.setSectionSiteAuto = this.setSectionSiteAuto.bind(this);
    this.$api.removeSection = this.removeSection.bind(this);

    this.$api.beforeSaveChanges = this.beforeSaveChanges.bind(this);

    this.$api.contentBlocked = this.contentBlocked.bind(this);
    this.$api.onNavigateToPolicy = this.onNavigateToPolicy.bind(this);
    this.$api.acceptCookies = this.acceptCookies.bind(this);
    this.$api.reset = (): void => {
      this.addedItem = false;
      this.#activeComponent = undefined;
    };

    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.renderer.listen('window', 'click', (e: Event) => {
      let noButtonClicked = true;
      let noMenuClicked = true;

      this.toggleButton.forEach((element) => {
        if (e.target === element.nativeElement.children[0]) {
          noButtonClicked = false;
        }
      });
      this.menu.forEach((element) => {
        if ((e.target as any).parentNode === element.nativeElement) {
          noMenuClicked = false;
        }
      });
      if (noButtonClicked && noMenuClicked) {
        this.currentMenuRowVisible = {};
        this.currentMenuColumnVisible = {};
      }
    });
  }

  public onNavigateToPolicy(): void {
    this.iGdprDataProvider.onNavigateToPolicy();
  }

  public acceptCookies(): void {
    this.iGdprDataProvider.onCookeResponse(enums.CookieState.ACCEPTED, this.cookieKey);
    this.cookieService.set('ga_enabled', 'true', {
      expires: moment().add(6, 'month').toDate(),
      path: '/',
    });

    this.store.select(siteStoreSelectors.selectEntity).pipe(
      filter(entity => !isNil(entity)),
      take(1)
    ).subscribe(site => {
      this.iGdprDataProvider.initGoogleAnalytics(site);
      this.iGdprDataProvider.cookiesAccept$.next(enums.CookieState.ACCEPTED);
    });
  }

  public ngAfterViewInit(): void {
    if (this.templateDesignMode) {
      return;
    }
    this.updateDraggableContainers();
  }

  public setSelectedSection(section: contentBuilderModel.ContentSectionModel): void {
    this.selectedSection = section;
    this.$api.selectedSection = section;
    this.checkSectionsWidth();
  }

  public onSelectComponent(
    component: contentBuilderModel.ContentItemModel,
    section: contentBuilderModel.ContentSectionModel
  ): void {
    if (
      this.templateDesignMode ||
      !this.$api.designMode ||
      isNil(component) ||
      isNil(section)) {
      return;
    }

    if (component.uuid === this.#activeComponent?.uuid) {
      return;
    }

    if (!isNil(this.#activeComponent?.instance)) {
      this.#activeComponent.instance.isFocused$.next(false);
    }

    this.#activeComponent = component;
    section.selectedComponent = component;
    this.isEdited = component.instance.isEdited;
    this.#activeComponent?.instance?.isFocused$.next(true);
  }

  public onItemDropped($event: CdkDragDrop<any[]>): void {
    const { previousContainer, container, previousIndex, currentIndex } = $event;

    if (previousContainer === container) {
      moveItemInArray(container.data, previousIndex, currentIndex);
      return;
    }

    transferArrayItem(previousContainer.data, container.data, previousIndex, currentIndex);
  }

  public onAddElement(section: contentBuilderModel.ContentSectionModel, index?: number): void {
    this.$api.index = index ? index : 0;

    this.setSelectedSection(section);

    const dialogRef = this.modal.open(ElementSelectorComponent, { size: 'element-selector' });
    dialogRef.componentInstance.containerAPI = this.$api;

    merge(
      dialogRef.closed,
      dialogRef.dismissed
    ).pipe(
      take(1)
    ).subscribe((data) => {
      console.log(data);
      this.addedItem = false;
    });
  }

  public onRemoveSection(section: contentBuilderModel.ContentSectionModel): void {
    this.setSelectedSection(section);
    this.removeSection();
  }

  public swapRow(rowId: number, direction: RowDirectionMove): void {
    const { rows } = this.$api;
    const step = direction === RowDirectionMove.UP ? -1 : 1;
    [
      rows[rowId],
      rows[rowId + step],
    ] = [
      rows[rowId + step],
      rows[rowId],
    ];
  }

  public swapSection(index: number, direction: SectionDirectionMove, contentRow: contentBuilderModel.ContentRowModel): void {
    const { sections } = contentRow;
    const step = direction === SectionDirectionMove.LEFT ? -1 : 1;

    [
      sections[index],
      sections[index + step],
    ] = [
      sections[index + step],
      sections[index],
    ];
  }

  public setSelectedRow(row: contentBuilderModel.ContentRowModel): void {
    this.selectedRow = row;
    this.checkSectionsWidth();
  }

  private beforeSaveChanges(): Observable<boolean> {
    const observables: Observable<boolean>[] = [of(true)];
    const avaibleComponents = this.$api.rows
      .map((row) => row.sections)
      .flat()
      .map((section) => section.items)
      .flat();

    for (const { instance } of avaibleComponents) {
      observables.push(instance ? instance.onSaveChanges() : of(true));
    }

    return combineLatest(observables).pipe(switchMap((res) => of(res.every((x) => !!x))));
  }

  /** API METHODS */

  private addElement(item: contentBuilderModel.ContentItemModel, index?: number): void {
    this.addedItem = true;
    this.selectedSection.items.splice(index, 0, {
      ...item,
      uuid: UUID(),
      parent: this.selectedSection,
    });
  }

  private addRow(addBefore?: boolean): void {
    const section: any = {
      items: [],
      uuid: UUID(),
      size: 12,
    };

    const index = addBefore ? this.$api.rows.indexOf(this.selectedRow) : this.$api.rows.indexOf(this.selectedRow) + 1;
    this.$api.rows.splice(index, 0, {
      sections: [section],
      uuid: UUID(),
    });

    this.setSelectedSection(section);
    this.updateDraggableContainers();
  }

  private checkSections(rowData: contentBuilderModel.ContentRowModel): boolean {
    return rowData.sections?.some((section) => section.items.length > 0);
  }

  public rowSettings(): void {
    const dialogRef = this.modal.open(RowSettingsComponent, { size: 'element-selector' });
    dialogRef.componentInstance.row = {
      ...this.selectedRow,
      sections: [],
    };

    dialogRef.closed.subscribe((row: contentBuilderModel.ContentRowModel) => {
      this.selectedRow.cssClass = row.cssClass;
    });
  }

  public removeRow(): void {
    if (this.snackBarData && this.checkSections(this.selectedRow)) {
      const snackRef = this.snackBarData.customSnackBarTrigger('remove', 100000);
      snackRef.afterDismissed().pipe(take(1)).subscribe((res) => {
        if (res === undefined || res.message === 'CANCELLED') {
          return;
        }
        this.deleteSelectedRow(this.selectedRow);
      });
    } else {
      this.deleteSelectedRow(this.selectedRow);
    }
  }

  private deleteSelectedRow(row: contentBuilderModel.ContentRowModel): void {
    this.$api.rows.splice(this.$api.rows.indexOf(row), 1);
    this.setSelectedSection(null);
    this.updateDraggableContainers();
  }

  private removeElement(item: contentBuilderModel.ContentItemModel): void {
    const { items } = item.parent;
    item.parent.items.splice(items.indexOf(item), 1);
    this.updateDraggableContainers();
  }

  private updateDraggableContainers(): void {
    if (this.templateDesignMode || !this.$api?.designMode) {
      return;
    }

    this.$draggableContainers = this.destinationArrayForDraggableItems?.toArray();
    if (!this.$api) {
      return;
    }

    if (!isNil(this.$api?.rows) && !!this.$api.rows.length) {
      for (const section of this.$api.rows.map((x) => x.sections).flat()) {
        for (const component of section.items) {
          component.parent = section;
        }
      }
    }

    this.$changeDetector.detectChanges();
  }

  public changeSectionSize(mode: SectionSizeMode): void {
    const { sections } = this.selectedRow;

    if (mode === SectionSizeMode.DECREMENT) {
      this.selectedSection.size =
        this.selectedSection.size > 1 ? this.selectedSection.size - 1 : Math.floor(12 / sections.length);
      return;
    }

    const findAutoSizeSection = sections.filter((section) => section.size === 0);
    const sumOfAllCols = sections.reduce((prev, curr) => (curr.size === 0 ? prev + 2 : prev + curr.size), 0);

    if (this.selectedSection.size !== 0 && this.selectedSection.size < 12 && sumOfAllCols < 12) {
      this.selectedSection.size++;
    } else if (this.selectedSection.size === 0) {
      this.selectedSection.size = Math.floor((12 - sumOfAllCols + findAutoSizeSection.length) / findAutoSizeSection.length);
    } else if (sumOfAllCols < 12) {
      this.selectedSection.size = Math.floor(12 / sections.length);
    }

    this.checkSectionsWidth();
  }

  public setSectionSiteAuto(): void {
    this.selectedSection.size = 0;
    this.checkSectionsWidth();
  }

  private addSection(addLeft?: boolean): boolean | number | void {
    const { sections } = this.selectedRow;
    const findAutoSizeSection = sections.filter((section) => section.size === 0);
    const sumOfSections = sections.reduce((prev, curr) => {
      if (curr.size === 0) {
        return prev + 2;
      } else {
        return prev + curr.size;
      }
    }, 0);
    const autoSizeSectionsLength = findAutoSizeSection.length === 0 ? 1 : findAutoSizeSection.length;
    const colSize = Math.floor((12 - sumOfSections) / autoSizeSectionsLength);

    if (this.sectionWidth > 11 && sumOfSections < 12) {
      return false;
    }

    if (sumOfSections < 12) {
      const index = addLeft ? sections.indexOf(this.selectedSection) : sections.indexOf(this.selectedSection) + 1;
      sections.splice(index, 0, {
        uuid: UUID(),
        items: [],
        size: colSize < 1 ? 1 : colSize,
      });
    } else {
      const nrOfColumns = sections.length + 1;
      const colLeft = 12 % nrOfColumns;
      const widthPerCol = Math.floor(12 / nrOfColumns);
      const index = addLeft ? sections.indexOf(this.selectedSection) : sections.indexOf(this.selectedSection) + 1;
      sections.splice(index, 0, {
        uuid: UUID(),
        items: [],
        size: widthPerCol,
      });

      sections.forEach((x) => {
        if (x.size === 0) {
          x.size = 0;
        } else {
          x.size = widthPerCol;
        }
      });
      sections[index].size = widthPerCol + colLeft;
    }

    this.updateDraggableContainers();
  }

  private removeSection(): void {
    const { sections } = this.selectedRow;
    const { rows } = this.$api;
    sections.splice(sections.indexOf(this.selectedSection), 1);

    if (sections.length === 0) {
      rows.splice(rows.indexOf(this.selectedRow), 1);
      this.setSelectedRow(rows.length === 0 ? undefined : rows[0]);
    }

    this.setSelectedSection(this.selectedRow && this.selectedRow.sections?.length > 0 ? this.selectedRow.sections[0] : undefined);
    this.updateDraggableContainers();
  }

  public toggleSectionColumnMenu(uuid: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.currentMenuColumnVisible.uuid === uuid
      ? (this.currentMenuColumnVisible = {})
      : (this.currentMenuColumnVisible = { uuid });
    this.currentMenuRowVisible = {};
  }

  public toggleSectionRowMenu(uuid: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.currentMenuRowVisible.uuid === uuid ? (this.currentMenuRowVisible = {}) : (this.currentMenuRowVisible = { uuid });
    this.currentMenuColumnVisible = {};
  }

  private checkSectionsWidth(): void {
    let currentSectionsWidthCheck = 0;

    if (this.selectedRow.sections && this.selectedRow.sections.length) {
      this.selectedRow.sections?.forEach((item) => {
        const currentSectionWidth = item.size === 0 ? 2 : item.size;
        currentSectionsWidthCheck = currentSectionsWidthCheck + currentSectionWidth;
      });
      this.sectionWidth = currentSectionsWidthCheck;
    }
  }

  /** API METHODS */
}
