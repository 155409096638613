import {
  Injector,
  NgZone,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NGB_MODAL_DATA } from '@jotter3/api-connector';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isEqual } from 'lodash-es';
import { take } from 'rxjs/operators';
import * as tiny from 'tinymce';
import { v4 as uuid } from 'uuid';

import {
  DATA_DEF_ATTRIBUTE,
  DATA_INSPECTION_ATTRIBUTE,
  J3_LINK_PROVIDER,
  LinkProvider,
} from '../../../../common';
import {
  FormAction,
  LinkType,
} from '../../../../enums';
import { LinkPluginModel } from '../../../../models';
import { injectFn } from '../core-types';
import { LinkDialogComponent } from './link.dialog.component';

const inspectCurrentContentDebounce: Record<string, any> = {};
const isImageFigure = (elm: Element): boolean =>
  elm && elm.nodeName.toLowerCase() === 'img' && elm.hasAttribute(DATA_DEF_ATTRIBUTE);

const getAnchorElement = (editor: tiny.Editor, selectedElm?: Element): Element => {
  const element = selectedElm || editor.selection.getNode();
  if (isImageFigure(element)) {
    return editor.dom.getParent(element, 'a[href]');
  }
  return editor.dom.getParent(element, 'a[href]');
};

const unlinkImage = (editor: tiny.Editor, node: Element): void => {
  const img = editor.dom.select('img', node)[0];
  if (!img) {
    return;
  }
  const a = editor.dom.getParents(img, 'a[href]', node)[0];

  if (!a) {
    return;
  }

  a.parentNode.insertBefore(img, a);
  editor.dom.remove(a);
};

const unlinkSelection = (editor: tiny.Editor): void => {
  const dom = editor.dom;
  const selection = editor.selection;
  const bookmark = selection.getBookmark();
  const rng = selection.getRng().cloneRange();
  const startAnchorElm = dom.getParent(rng.startContainer, 'a[href]', editor.getBody());
  const endAnchorElm = dom.getParent(rng.endContainer, 'a[href]', editor.getBody());
  if (startAnchorElm) {
    rng.setStartBefore(startAnchorElm);
  }
  if (endAnchorElm) {
    rng.setEndAfter(endAnchorElm);
  }
  selection.setRng(rng);
  editor.execCommand('unlink');
  selection.moveToBookmark(bookmark);
};

const unlinkDom = (editor: tiny.Editor): void => {
  editor.undoManager.transact(() => {
    try {
      const node = editor.selection.getNode();
      if (isImageFigure(node)) {
        unlinkImage(editor, node);
      } else {
        unlinkSelection(editor);
      }
    } finally {
      editor.focus();
    }
  });
};

const unlink = (editor: tiny.Editor): void => {
  if (editor.hasPlugin('rtc', true)) {
    editor.execCommand('unlink');
    return;
  }
  unlinkDom(editor);
};

const getHrefValue = (model: LinkPluginModel): string => {
  const { linkType, protocol, url, page, mail } = model;

  switch (linkType) {
    case LinkType.EXTERNAL_URL:
      return `${protocol}://${url}`;
    case LinkType.LINK_CMS_TO_PAGE:
      return page;
    case LinkType.MAIL:
      return `mailto:${mail}`;
    case LinkType.LINK_TO_FILE:
      return '#';
  }
};

const getAnchorAttributes = (data: LinkPluginModel, sanitizer: DomSanitizer): Record<string, string> => {
  const attr: Record<string, string> = {};
  const { file, resource, ...dataToSerialize } = data;

  attr.href = sanitizer.sanitize(SecurityContext.URL, getHrefValue(data));
  attr.target = data.openInNewTab ? '_blank' : '_self';
  attr[DATA_DEF_ATTRIBUTE] = encodeURIComponent(JSON.stringify(dataToSerialize));
  attr.class = data.linkAsButton ? 'text-link-button' : '';

  return attr;
};

const updateAnchor = (editor: tiny.Editor, anchorElement: Element, data: LinkPluginModel, sanitizer: DomSanitizer): void => {
  editor.dom.setAttribs(anchorElement, getAnchorAttributes(data, sanitizer));
  editor.selection.select(anchorElement);
};

const linkImageFigure = (editor: tiny.Editor, selectedElement: Element, data: LinkPluginModel, sanitizer: DomSanitizer): void => {
  const img =
    selectedElement?.nodeName.toLocaleLowerCase() === 'img' ? selectedElement : editor.dom.select('img', selectedElement)[0];
  if (!img) {
    return;
  }

  const anchor = editor.dom.create('a', getAnchorAttributes(data, sanitizer));
  img.parentNode.insertBefore(anchor, img);
  anchor.appendChild(img);
};

const createAnchor = (editor: tiny.Editor, selectedElement: Element, data: LinkPluginModel, sanitizer: DomSanitizer): void => {
  if (isImageFigure(selectedElement)) {
    linkImageFigure(editor, selectedElement, data, sanitizer);
    return;
  }
  editor.execCommand('mceInsertLink', false, getAnchorAttributes(data, sanitizer));
};

const link = (editor: tiny.Editor, data: LinkPluginModel, sanitizer: DomSanitizer): void => {
  const selection = editor.selection.getNode();
  const anchorElement = getAnchorElement(editor, selection);
  editor.undoManager.transact(() => {
    if (anchorElement) {
      updateAnchor(editor, anchorElement, data, sanitizer);
      return;
    }

    createAnchor(editor, selection, data, sanitizer);
  });
};

const openDialog = (editor: tiny.Editor, inject: injectFn): void => {
  const ngZone = inject(NgZone);

  const selectionContent = editor.selection.getContent();
  const anchorElement = getAnchorElement(editor, editor.selection.getNode());
  if (!anchorElement && !selectionContent?.trim()?.length) {
    return;
  }

  let formModel = {};
  if (anchorElement?.nodeName?.toLowerCase() === 'a') {
    try {
      formModel = JSON.parse(decodeURIComponent(anchorElement.getAttribute(DATA_DEF_ATTRIBUTE) || '{}'));
    } catch (err) {
      console.warn(`ERR: cannot parse "${DATA_DEF_ATTRIBUTE}" for given selection`);
    }
  }

  ngZone.run(() => {
    const dialogService = inject(NgbModal);
    const dialogRef = dialogService.open(LinkDialogComponent, {
      backdrop: 'static',
      injector: Injector.create({
        providers: [
          {
            provide: NGB_MODAL_DATA,
            useValue: {
              formModel,
              showRemoveButton: !isEqual(formModel, {}),
            },
          },
        ],
      }),
    });

    dialogRef.closed.pipe(take(1)).subscribe((result) => {
      if (!result || result.formAction === FormAction.CANCEL) {
        return;
      }

      const { model, formAction } = result;

      const linkAction =
        formAction === FormAction.SUBMIT
          ? (): void => {
            const sanitizer: DomSanitizer = inject(DomSanitizer);
            link(editor, model, sanitizer);
          }
          : (): void => unlink(editor);

      linkAction();
      inspectCurrentContent(editor.getBody(), inject);
    });
  });
};

export const inspectCurrentContent = (sourceElement: HTMLElement, inject: injectFn): void => {
  let inspectId = sourceElement.getAttribute(DATA_INSPECTION_ATTRIBUTE);

  if (inspectId) {
    clearTimeout(inspectCurrentContentDebounce[inspectId]);
  } else {
    inspectId = uuid();
    sourceElement.setAttribute(DATA_INSPECTION_ATTRIBUTE, inspectId);
  }

  inspectCurrentContentDebounce[inspectId] = setTimeout(() => {
    const anchors = sourceElement.querySelectorAll('a[data-element-def]');
    const linkProvider: LinkProvider = inject<LinkProvider>(J3_LINK_PROVIDER);

    if (!anchors.length) {
      return;
    }

    anchors.forEach((anchor: Element) => {
      try {
        const formModel: LinkPluginModel = JSON.parse(decodeURIComponent(anchor.getAttribute(DATA_DEF_ATTRIBUTE) || '{}'));
        const evListener = (event: Event): boolean => {
          const ev = event as PointerEvent;
          const prevent = linkProvider.navigate(ev, formModel);
          if (prevent) {
            ev.preventDefault();
            ev.stopPropagation();
            ev.stopImmediatePropagation();
          }

          return prevent;
        };

        anchor.removeEventListener('click', evListener);
        anchor.addEventListener('click', evListener);
      } catch (err) {
        console.warn(`ERR: cannot parse "${DATA_DEF_ATTRIBUTE}" for given selection`);
      }
    });
  }, 200);
};

export const initialize = (editor: tiny.Editor, inject: injectFn): void => {
  const onAction = (): void => openDialog(editor, inject);
  const pluginDef = {
    icon: 'link',
    tooltip: 'Link',
    onAction,
  };

  editor.ui.registry.addButton('j3Link', pluginDef);
  editor.ui.registry.addMenuItem('j3Link', {
    ...pluginDef,
    text: 'Link...',
    shortcut: 'Meta+K',
  });

  editor.addCommand('j3LinkCommand', onAction);
  editor.addShortcut('Meta+K', '', () => editor.execCommand('j3LinkCommand'));

  editor.on('SetContent', (ev) => {
    const { content, target } = ev;

    if (!content?.trim() || !editor.mode.isReadOnly()) {
      return;
    }

    inspectCurrentContent((target as tiny.Editor).getBody(), inject);
  });
};
