import {
  createAction,
  props,
} from '@ngrx/store';

import { PolicyGroupListDomainModel } from '../../../domain-models';

const LoadPoliciesList = createAction('[Policies list] Load data', props<{ group?: string }>());
const LoadPoliciesListComplete = createAction(
  '[Policies list] Load Policies List Complete',
  props<{ policies: PolicyGroupListDomainModel[] }>()
);
const LoadPoliciesListFailed = createAction('[Policies list] Load data failed', props<{ error: any }>());

export { LoadPoliciesList,
  LoadPoliciesListComplete,
  LoadPoliciesListFailed };
