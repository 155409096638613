import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  OnDestroy,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { EventInput } from '@fullcalendar/core';
import { domainModels } from '@jotter3/api-connector';
import {
  CALENDAR_STORE,
  CalendarEvent,
  CalendarView,
} from '@jotter3/calendar';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { dataQuery } from '@jotter3/wa-core';
import {
  isEqual,
  isNil,
} from 'lodash-es';
import * as moment from 'moment';
import {
  debounceTime,
  Observable,
  Subscription,
  takeWhile,
} from 'rxjs';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { v4 as UUID } from 'uuid';

import { LayoutType } from '../../enums';
import { ToggleContentService } from '../../services';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';
import { CalendarComponentStore } from './calendar.component-store';
import {
  DEFAULTS,
  DISPLAYED_SLIDES_DROPDOWN_OPTIONS,
  EVENT_STYLE_DROPDOWN_OPTIONS,
  LAYOUT_DROPDOWN_OPTIONS,
  SHOW_EVENTS_DROPDOWN_OPTIONS,
  SLIDE_TRANSITION_TIME_DROPDOWN_OPTIONS,
  VIEW_DROPDOWN_OPTIONS,
} from './consts';

@SiteComponent({
  selector: 'calendar-content-component',
  displayName: 'Calendar',
  icon: 'calendar',
  category: SiteComponentCategory.MODULES,
  showSettingsAfterAdd: true,
  roles: ['ROLE_CALENDAR_VIEWALL'],
})
@Component({
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
  providers: [
    {
      provide: CALENDAR_STORE,
      useClass: CalendarComponentStore,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarContentComponent extends JotterSitesBaseComponent implements AfterViewInit, OnDestroy {
  readonly #calendarComponentStore: CalendarComponentStore = inject<CalendarComponentStore>(CALENDAR_STORE);
  readonly #toggleContent: ToggleContentService = inject(ToggleContentService);

  readonly #platformId: object = inject(PLATFORM_ID);
  #subscription: Subscription = new Subscription();

  public defaultViewValue: CalendarView | string = CalendarView.DAY_GRID_MONTH;
  public events$: Observable<EventInput> = this.#calendarComponentStore.selectSortedEvents$;
  public visibleCategories$: Observable<domainModels.CategoryDomainModel[]> = this.#calendarComponentStore.selectEventsCategories$;

  public isActive = false;
  public elementId: string = UUID();
  public itemPosition = '0px';

  public page = 1;
  public events: Observable<CalendarEvent[]>;
  public filterByCategories: domainModels.CategoryDomainModel[] = [];
  public filterByIds: string[] = [];

  public maxCount = DEFAULTS.MAX_EVENTS_COUNT;
  public showCategoryFilter = DEFAULTS.SHOW_CATEGORY_FILTER;
  public layoutTypeEnum = LayoutType;
  public layoutTypeValue = DEFAULTS.LAYOUT;
  public showIndicators = DEFAULTS.SHOW_INDICATORS;
  public showArrows = DEFAULTS.SHOW_ARROWS;
  public slidesVisible = DEFAULTS.DISPLAYED_SLIDES;
  public elTemplate = DEFAULTS.CALENDAR_EVENT_STYLE;
  public selectedCategoriesList: Observable<domainModels.CategoryDomainModel[]>;

  private showEventsDays = DEFAULTS.EVENTS_COUNT;
  private animationDelay = DEFAULTS.SLIDE_TRANSITION;
  private selectedGroupsValue: string[] = [];

  #selectedCategoriesIds = new Array<string>();

  @ViewChild('eventsListSwiper')
    eventsListSwiper?: SwiperComponent;
  public eventsConfig: SwiperOptions = {};

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    super(CalendarContentComponent);
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
    defaultValue: DEFAULTS.HEADER,
  })
    header = DEFAULTS.HEADER;


  @Property({
    displayName: 'Categories',
    modifierType: ModifierType.J3_API_MULTIDROPDOWN,
    resourceName: 'categories',
    valueProp: 'id',
    labelProp: 'name',
  })
  public get selectedCategories(): string[] {
    return this.#selectedCategoriesIds;
  }

  public set selectedCategories(value: string[]) {
    this.#selectedCategoriesIds = value;
  }

  @Property({
    displayName: 'Show category filter',
    modifierType: ModifierType.CHECK_BOX,
    className: 'form-switch',
    defaultValue: DEFAULTS.ALLOW_EVENTS_FILTER,
  })
  public get allowEventsFilter(): boolean {
    return this.showCategoryFilter;
  }

  public set allowEventsFilter(value: boolean) {
    if (this.showCategoryFilter === value || value === undefined) {
      return;
    }
    this.showCategoryFilter = value;
  }

  @Property({
    displayName: 'Layout',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: VIEW_DROPDOWN_OPTIONS,
    defaultValue: DEFAULTS.DEFAULT_VIEW,
  })
  public get defaultView(): CalendarView | string {
    return this.defaultViewValue;
  }

  public set defaultView(value: CalendarView | string) {
    this.defaultViewValue = value;
  }

  @Property({
    displayName: 'Events to display',
    modifierType: ModifierType.NUMBER,
    required: true,
    description: 'Maximum number of events to display at once',
    hideExpression: 'model.defaultView !== "eventsList"',
    templateOptions: {
      type: 'number',
      min: 1,
    },
  })
  public get eventsCount(): number {
    return this.maxCount;
  }
  public set eventsCount(value: number) {
    if (this.maxCount === value || value === undefined) {
      return;
    }

    this.maxCount = value;
  }

  @Property({
    displayName: 'Show events for the next',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: SHOW_EVENTS_DROPDOWN_OPTIONS,
    hideExpression: 'model.defaultView !== "eventsList"',
    defaultValue: DEFAULTS.EVENTS_COUNT,
  })
  public get showEvents(): number {
    return this.showEventsDays;
  }

  public set showEvents(value: number) {
    if (this.showEventsDays === value || value === undefined) {
      return;
    }
    this.showEventsDays = value;
  }

  @Property({
    displayName: 'Event Style',
    modifierType: ModifierType.DROPDOWN,
    template: 'vaIcons',
    hideExpression: 'model.defaultView !== "eventsList"',
    dropdownSource: EVENT_STYLE_DROPDOWN_OPTIONS,
    defaultValue: DEFAULTS.CALENDAR_EVENT_STYLE,
  })
  public get elTemplateValue(): number {
    return this.elTemplate;
  }

  public set elTemplateValue(value: number) {
    if (this.elTemplate === value || value === undefined) {
      return;
    }
    this.elTemplate = value;
  }

  @Property({
    displayName: 'Layout',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: LAYOUT_DROPDOWN_OPTIONS,
    hideExpression: 'model.defaultView !== "eventsList"',
    defaultValue: DEFAULTS.LAYOUT,
  })
  public get layoutType(): LayoutType {
    return isNil(this.layoutTypeValue) ? LayoutType.NORMAL : this.layoutTypeValue;
  }

  public set layoutType(value: LayoutType) {
    this.layoutTypeValue = value;
  }

  @Property({
    displayName: 'Slides to display',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: DISPLAYED_SLIDES_DROPDOWN_OPTIONS,
    hideExpression: 'model.layoutType !== 1',
    defaultValue: DEFAULTS.DISPLAYED_SLIDES,
  })

  public get slidesVisibleValue(): number {
    return this.slidesVisible;
  }

  public set slidesVisibleValue(value: number) {
    if (this.slidesVisible === value || value === undefined) {
      return;
    }
    this.slidesVisible = value;
  }

  @Property({
    displayName: 'Show indicators',
    modifierType: ModifierType.CHECK_BOX,
    className: 'form-switch',
    hideExpression: 'model.layoutType !== 1',
  })
  public get showIndicatorsValue(): boolean {
    return this.showIndicators;
  }
  public set showIndicatorsValue(value: boolean) {
    if (this.showIndicators === value || value === undefined) {
      return;
    }
    this.showIndicators = value;
  }

  @Property({
    displayName: 'Show arrows',
    modifierType: ModifierType.CHECK_BOX,
    className: 'form-switch',
    hideExpression: 'model.layoutType !== 1',
  })
  public get showArrowsValue(): boolean {
    return this.showArrows;
  }
  public set showArrowsValue(value: boolean) {
    if (this.showArrows === value || value === undefined) {
      return;
    }
    this.showArrows = value;
  }

  @Property({
    displayName: 'Transition Time',
    modifierType: ModifierType.DROPDOWN,
    advanced: true,
    dropdownSource: SLIDE_TRANSITION_TIME_DROPDOWN_OPTIONS,
    required: true,
    defaultValue: DEFAULTS.SLIDE_TRANSITION,
  })
  public get animationDelayValue(): number {
    return this.animationDelay;
  }

  public set animationDelayValue(value: number) {
    if (this.animationDelay === value) {
      return;
    }
    this.animationDelay = value;
  }

  @Property({
    displayName: 'Audience Targeting',
    modifierType: ModifierType.J3_API_MULTIDROPDOWN,
    resourceName: 'events/available_groups',
    valueProp: 'id',
    labelProp: 'name',
    group: 'General',
    advanced: true,
    placeholder: 'Choose groups...',
  })
  public get selectedGroups(): string[] {
    return this.selectedGroupsValue;
  }

  public set selectedGroups(value: string[]) {
    if (isEqual(this.selectedGroupsValue, value)) {
      return;
    }
    this.selectedGroupsValue = value;
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (isPlatformServer(this.#platformId)) {
      return;
    }

    if (this.defaultView === CalendarView.EVENTS_LIST) {
      this.updateConfig();
    }

    if (this.router.url.includes('/calendar?')) {
      const categoriesIds = this.route.snapshot.queryParams['categories'];
      this.#selectedCategoriesIds = categoriesIds ? categoriesIds.split(',') : [];
    }


    if (this.#selectedCategoriesIds?.length) {
      this.selectedCategoriesList = this.#calendarComponentStore.getSelectedCategoriesData(this.#selectedCategoriesIds);
    }

    this.#updateDataQueryInStore(this.#selectedCategoriesIds);
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.#subscription.unsubscribe();
  }

  public override afterSettingsSaved(): void {
    this.filterByIds = [];
    this.filterByCategories = [];
    if (this.defaultView === CalendarView.EVENTS_LIST) {
      this.updateConfig();
    }
    if (this.defaultView !== CalendarView.EVENTS_LIST) {
      this.#calendarComponentStore.changeCalendarView(this.defaultView as CalendarView);
    }
    if (this.#selectedCategoriesIds?.length) {
      this.selectedCategoriesList = this.#calendarComponentStore.getSelectedCategoriesData(this.#selectedCategoriesIds);
    }
    this.#updateDataQueryInStore(this.#selectedCategoriesIds);
    this.events$ = this.#calendarComponentStore.filterEventsByCategories(this.filterByCategories);
  }

  public toggleItem(status: boolean, id: string): void {
    this.isActive = status;
    this.itemPosition = this.#toggleContent.toggleContent(status, id);
  }

  public animationOverflow(status: boolean, id: string): void {
    this.#toggleContent.toggleOverflow(status, id);
  }

  public toggleCategory(categoryItem?: domainModels.CategoryDomainModel): void {
    if (!this.filterByIds.find((categoryId) => categoryId === categoryItem.id)) {
      this.filterByIds.push(categoryItem.id);
    } else {
      this.filterByIds.splice(this.filterByIds.indexOf(categoryItem.id), 1);
    }

    if (this.defaultView === 'eventsList') {
      this.page = 1;
      if (!this.filterByCategories.find((category) => category === categoryItem)) {
        this.filterByCategories.push(categoryItem);
      } else {
        this.filterByCategories.splice(this.filterByCategories.indexOf(categoryItem), 1);
      }
      this.events$ = this.#calendarComponentStore.filterEventsByCategories(this.filterByCategories);
    } else {
      this.#updateDataQueryInStore(this.filterByIds.length === 0 && this.#selectedCategoriesIds.length ? this.#selectedCategoriesIds : this.filterByIds);
    }

  }

  public goToCalendarPage(): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['calendar'], { queryParams: { categories: this.#selectedCategoriesIds.toString() } }));
    this.router.navigateByUrl(this.designMode ? `sites/page${url}` : `${url}`);
  }

  private updateConfig(): void {
    if (this.platformServer) {
      return;
    }

    this.#subscription.unsubscribe();
    this.#subscription = new Subscription();

    this.#subscription.add(
      this.#calendarComponentStore.selectQueryParams$.pipe(
        debounceTime(200),
        takeWhile(() => this.defaultView === CalendarView.EVENTS_LIST, true)
      ).subscribe(params => {
        this.#calendarComponentStore.loadEntities(params);
      })
    );

    this.eventsConfig = {
      autoplay:
          this.animationDelay === 0
            ? false
            : {
              delay: this.animationDelay,
              disableOnInteraction: false,
              pauseOnMouseEnter: false,
            },
      speed: 1000,
      pagination: this.showIndicators
        ? {
          el: '.events-pagination',
          type: 'bullets',
        }
        : false,
      preventInteractionOnTransition: true,
      allowTouchMove: true,
      spaceBetween: 30,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },

        768: {
          slidesPerView: this.slidesVisible > 2 ? this.slidesVisible - 2 : 1,
          spaceBetween: 20,
        },

        991: {
          slidesPerView: this.slidesVisible > 1 ? this.slidesVisible - 1 : 1,
          spaceBetween: 20,
        },

        1200: {
          slidesPerView: this.slidesVisible,
          spaceBetween: 30,
        },
      },
    };
  }

  #updateDataQueryInStore(categories: string[]): void {
    this.#calendarComponentStore.setQueryParams({
      filters: categories?.length ? [
        {
          type: dataQuery.FilterType.SELECT,
          property: 'categories',
          operator: dataQuery.Operator.IN,
          value: categories,
        },
      ] : [],
    });

    if (this.defaultView === CalendarView.EVENTS_LIST) {
      this.#calendarComponentStore.changeSelectedDates({
        from: moment().toISOString(),
        to: moment().add(this.showEvents, 'days').toISOString(),
      });
    }
  }

}
