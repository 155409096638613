import {
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  AttachmentModel,
  IFileDomainModel,
} from '@jotter3/api-connector';
import {
  FileDownloadService,
  OrderBy,
} from '@jotter3/common-helpers';
import { orderBy } from 'lodash-es';

import { AttachmentLayout } from './consts';



@Component({
  selector: 'j3-attachment-item',
  templateUrl: './attachment-item.component.html',
})
export class AttachmentItemComponent implements OnInit {
  readonly #fileDownloadService: FileDownloadService = inject(FileDownloadService);

  @Input() attachments: IFileDomainModel[] = [];
  @Input() orderAttBy: string;
  @Input() template: AttachmentLayout;

  public attachmentLayout = AttachmentLayout;

  public ngOnInit(): void {
    switch (true) {
      case this.orderAttBy === OrderBy.NAME_ASC:
        this.attachments = this.sortbyName(true);
        break;
      case this.orderAttBy === OrderBy.DATE_ASC:
        this.attachments = this.sortbyDate(true);
        break;
      case this.orderAttBy === OrderBy.DATE_DESC:
        this.attachments = this.sortbyDate(false);
        break;
      default:
        this.attachments = this.sortbyName(false);
        break;
    }
  }

  private sortbyName(order: boolean): IFileDomainModel[] {
    return orderBy(this.attachments, (attachment) => attachment.name.toLowerCase(), [order ? 'asc' : 'desc']);
  }

  private sortbyDate(order: boolean): IFileDomainModel[] {
    return orderBy(this.attachments, ['createDate'], [order ? 'asc' : 'desc']);
  }

  public handleOnDownload(attachment: AttachmentModel): void {
    const { name, url } = attachment;

    this.#fileDownloadService.onDownloadFile(name, url);
  }
}
