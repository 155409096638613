

import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  Renderer2,
} from '@angular/core';
import { J3TranslateService } from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { TranslateService } from '@ngx-translate/core';

import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

declare let mntSchoolVacancies: any;

@SiteComponent({
  selector: 'my-new-term-content-component',
  displayName: 'MyNewTerm',
  icon: 'calendar',
  category: SiteComponentCategory.EMBED,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './my-new-term.component.html',
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class MyNewTermSiteElementComponent extends JotterSitesBaseComponent implements AfterViewInit {
  private script: any;
  private mntInitCounter = 0;
  newTermIdData = '';
  newTermScriptIdName = 'mynewterm';


  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(MyNewTermSiteElementComponent);
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
  })
    header = '';

  @Property({
    displayName: 'My New Term ID',
    modifierType: ModifierType.TEXT,
    required: true,
    placeholder: 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX',
    description: "Inside MYNEWTERM Part C (API SCRIPT), find line with ID mntSchoolVacancies('XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX', 1). Please copy and paste only this ID number XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX without ''",
  })
  get newTermId(): string {
    return this.newTermIdData;
  }
  set newTermId(value: string) {
    this.newTermIdData = value;
    this.document.getElementById('mnt-parent-container').innerHTML = '';
    if (this.mntInitCounter) {
      mntSchoolVacancies(this.newTermIdData, 1);
    }
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.newTermIdData !== '') {
      this.#addExternalJsFile().onload = (): void => {
        this.mntInitCounter = 1;
        mntSchoolVacancies(this.newTermIdData, 1);
      };;
    }
  }

  #addExternalJsFile(): any {
    this.document.getElementById(this.newTermScriptIdName)?.remove();
    this.script = this.document.createElement('script');
    this.script.id = this.newTermScriptIdName;
    this.script.type = 'text/javascript';
    this.script.src = `https://api.mynewterm.com/assets/v1/dist/js/school_vacancies.js?v=${ new Date().getTime() }`;
    this.renderer.appendChild(this.document.body, this.script);

    return this.script;
  }

}


