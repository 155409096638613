export enum ModifierType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DROPDOWN = 'DROPDOWN',
  MULTIDROPDOWN = 'MULTIDROPDOWN',
  J3_API_DROPDOWN = 'J3_API_DROPDOWN',
  J3_API_MULTIDROPDOWN = 'J3_API_MULTIDROPDOWN',
  TEXT_AREA = 'TEXT_AREA',
  CHECK_BOX = 'CHECK_BOX',
  DATE_PICKER = 'DATEPICKER',
  TIME_PICKER = 'TIME_PICKER',
  ARRAY = 'ARRAY',
  FILEUPLOAD = 'FILEUPLOAD',
  CUSTOM = 'CUSTOM',
  RADIO_BUTTON = 'RADIO_BUTTON',
  RADIO_BUTTON_GROUP = 'RADIO_BUTTON_GROUP',
  IMAGE_RESOLUTION = 'IMAGE_RESOLUTION',
  UNIT_INPUT = 'UNIT_INPUT'
}
