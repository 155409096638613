import { Component } from '@angular/core';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { FieldType } from '@ngx-formly/core';

@Component({
  templateUrl: './time-picker.field.template.html',
  providers: [
    {
      provide: NgbTimepickerConfig,
      useFactory: TimePickerFieldComponent.getTimePickerConfig,
    },
  ],
})
export class TimePickerFieldComponent extends FieldType {
  static getTimePickerConfig(): NgbTimepickerConfig {
    return Object.assign(new NgbTimepickerConfig(), {
      hourStep: 1,
      minuteStep: 10,
      meridian: false,
      spinners: false,
      readonlyInput: false,
    });
  }
}
