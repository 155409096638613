import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';

import { TenantDomainService } from '../../../domain-services';
import * as fromActions from '../actions';

@Injectable()
export class TenantEffects {
  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadTenantData),
      switchMap(({ tenantId }) =>
        this.tenantDomainService.get(tenantId).pipe(
          map(({ result: tenant }) => fromActions.LoadTenantDataComplete({ tenant })),
          catchError((error) => of(fromActions.LoadTenantDataFailed({ error })))
        ))
    ));

  constructor(private actions$: Actions, private tenantDomainService: TenantDomainService) {}
}
