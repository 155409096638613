import { isPlatformServer } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import { AuthService } from '@jotter3/core';
import {
  Observable,
  throwError,
} from 'rxjs';
import {
  mergeMap,
  retryWhen,
} from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    @Inject(PLATFORM_ID) private readonly platformId: object
  ) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { DB_URL, DB_URL_VPC } = environment.ApiConnectorConfig;
    const API_URL = isPlatformServer(this.platformId) && DB_URL_VPC ? DB_URL_VPC : DB_URL;

    const byPassAuthorization =
      req.headers.get('Anonymous') || !req.urlWithParams.startsWith(API_URL);

    if (!this.authService.isAuthorized || byPassAuthorization) {
      req = req.clone({
        headers: req.headers.delete('Anonymous'),
      });

      return next.handle(req);
    }

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.authToken}`,
      },
    });

    return next.handle(req).pipe(
      retryWhen((error) =>
        error.pipe(
          mergeMap((err, count) => {
            if (!(err instanceof HttpErrorResponse) || count >= 1) {
              return throwError(err);
            }

            if (err.status === 401) {
              this.authService.logout();
              return throwError(err);
            }

            return throwError(err);
          })
        ))
    );
  }
}
