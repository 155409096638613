import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  domainModels,
  enums,
  pagesListState,
} from '@jotter3/api-connector';
import { ResourcesManagerService } from '@jotter3/common-components';
import {
  DropdownItemModel,
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  isEqual,
  isNil,
} from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  of,
  take,
} from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import * as contentEnums from '../../enums';
import { ContentDataProvider } from '../../providers';
import { ToggleContentService } from '../../services';
import { IMAGE_SIZES_OPTIONLIST_SOURCE } from '../image-element/image-element.consts';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'quick-links-content-component',
  displayName: 'Quick Links',
  icon: 'link',
  category: SiteComponentCategory.OTHER,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class QuickLinksComponent extends JotterSitesBaseComponent implements AfterViewInit, OnDestroy {
  private imagesListSrc: domainModels.ResourceDomainModel[] = [];
  private quickLinksVal: { [key: string]: any, attachments: domainModels.IFileDomainModel[] } = null;
  private resourceListId: string;
  private targetValue = false;
  private fitImageValue = false;
  public cmsPagesDropdownSource$: Observable<DropdownItemModel<string, string>[]>;

  public isActive = false;
  public itemPosition = '0px';
  public elementId: string = UUID();
  private itemsValue: BehaviorSubject<domainModels.ResourceDomainModel[]> = new BehaviorSubject<
    domainModels.ResourceDomainModel[]
  >([]);
  private captionsInsideValue = false;

  private widthValue = '';
  private heightValue = '';
  public linksWrapperSize: string;
  private resizeObserver: ResizeObserver;

  constructor(
    private resourceManager: ResourcesManagerService,
    private store: Store,
    private router: Router,
    public dataService: ContentDataProvider,
    private toggleContent: ToggleContentService,
    private zone: NgZone
  ) {
    super(QuickLinksComponent);
    if (this.platformBrowser) {
      this.resizeObserver = new ResizeObserver((entries) => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        this.zone.run(() => {
          this.getContainerWidth(entries[0].contentRect.width);
        });
      });
    }
  }

  @ViewChild('linksContainer') linksContainer: ElementRef<HTMLInputElement>;

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
  })
    customHeader = '';

  @Property({
    displayName: 'Captions inside link',
    modifierType: ModifierType.CHECK_BOX,
    className: 'form-switch',
    defaultValue: false,
  })
  public get captionsInside(): boolean {
    return this.captionsInsideValue;
  }

  public set captionsInside(value: boolean) {
    if (isEqual(this.captionsInsideValue, value) || isNil(value)) {
      return;
    }
    this.captionsInsideValue = value;
  }

  @Property({
    displayName: 'Open link in new window',
    modifierType: ModifierType.CHECK_BOX,
    className: 'form-switch',
    defaultValue: false,
  })
  public get target(): boolean {
    return this.targetValue;
  }

  public set target(value: boolean) {
    if (isEqual(this.targetValue, value) || isNil(value)) {
      return;
    }
    this.targetValue = value;
  }

  @Property({
    displayName: 'Fit image size',
    modifierType: ModifierType.CHECK_BOX,
    className: 'form-switch',
    defaultValue: false,
  })
  public get fitImage(): boolean {
    return this.fitImageValue;
  }

  public set fitImage(value: boolean) {
    if (isEqual(this.fitImageValue, value) || isNil(value)) {
      return;
    }
    this.fitImageValue = value;
  }

  @Property({
    modifierType: ModifierType.UNIT_INPUT,
    displayName: 'Single image max width',
    units: [
      '%',
      'px',
    ],
  })
  public get cssWidth(): string {
    return this.widthValue;
  }

  private set cssWidth(value: string) {
    this.widthValue = value;
  }

  @Property({
    modifierType: ModifierType.UNIT_INPUT,
    displayName: 'Single image max height',
  })
  public get cssHeight(): string {
    return this.heightValue;
  }

  private set cssHeight(value: string) {
    this.heightValue = value;
  }

  @Property({
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: 'Row',
        value: 'flex-row',
      },
      {
        label: 'Column',
        value: 'flex-column',
      },
    ],
    clearable: false,
    displayName: 'Direction',
    defaultValue: 'flex-row',
  })
    direction = 'flex-row';

  @Property({
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: 'Left',
        value: 'justify-content-start',
      },
      {
        label: 'Center',
        value: 'justify-content-center',
      },
      {
        label: 'Right',
        value: 'justify-content-end',
      },
      {
        label: 'Space around',
        value: 'justify-content-around',
      },
      {
        label: 'Space between',
        value: 'justify-content-between',
      },
    ],
    clearable: false,
    displayName: 'Align',
    hideExpression: 'model.direction === "flex-column"',
    defaultValue: 'justify-content-start',
  })
    align = 'justify-content-start';

  @Property({
    displayName: 'Image Resolution',
    modifierType: ModifierType.IMAGE_RESOLUTION,
    optionsList: IMAGE_SIZES_OPTIONLIST_SOURCE,
    required: true,
  })
  public imageSize = enums.PicturesSizes.S;

  @Property({
    displayName: 'Images',
    modifierType: ModifierType.CUSTOM,
    customFieldDef: {
      key: 'quickLinks',
      type: 'array',
      templateOptions: {
        addText: 'Add quick link',
        label: 'Add quick link',
      },
      fieldArray: {
        fieldGroup: [
          {
            type: 'input',
            key: 'title',
            templateOptions: {
              label: 'Enter quick link title',
              required: true,
            },
          },
          {
            type: 'input',
            key: 'desc',
            templateOptions: {
              label: 'Enter quick link description',
            },
          },
          {
            type: 'ng-select',
            key: 'cmsPage',
            props: {
              required: true,
              placeholder: 'Select url',
              label: 'Select URL',
              valueProp: 'value',
              labelProp: 'label',
              options: [],
            },
          },
          {
            type: 'file-upload',
            key: 'attachments',
            templateOptions: {
              multipleItems: false,
              allowTypes: [1],
              label: 'Quick link image',
              getUrl: true,
              required: true,
            },
          },
          {
            template: '<hr/>',
          },
        ],
      },
    },
  })
  get quickLinks(): { [key: string]: any, attachments: domainModels.IFileDomainModel[] } {
    return this.quickLinksVal;
  }

  set quickLinks(value: { [key: string]: any, attachments: domainModels.IFileDomainModel[] }) {
    if (!value) {
      return;
    }

    this.quickLinksVal = value['map']((item: { attachments: any[] }) => ({
      ...item,
      image: this.checkAspectRatio(item.attachments[0]),
    }));

    if (this.platformBrowser) {
      setTimeout(() => {
        if (this.linksContainer) {
          this.resizeObserver.observe(this.linksContainer.nativeElement);
        }
      }, 0);
      return;
    }
  }

  public get cmsListDropdown(): Observable<DropdownItemModel<string, string>[]> {
    if (!this.cmsPagesDropdownSource$) {
      this.cmsPagesDropdownSource$ = this.store.select(pagesListState.pagesSelectors.pagesListSelector).pipe(
        map((items) =>
          items
            .filter((menuItem) => menuItem.draftState === 1)
            .map((item) => ({
              value: item.url,
              label: item.title,
            }))),
        take(1)
      );
    }

    return this.cmsPagesDropdownSource$;
  }

  public get items(): Observable<domainModels.ResourceDomainModel[]> {
    return this.itemsValue;
  }

  public static imageUlrExpr(model: any): boolean {
    return model.linkType !== contentEnums.LinkType.EXTERNAL_URL;
  }

  public static linkTargetExpr(model: any): boolean {
    return !model.linkType;
  }

  public override ngAfterViewInit(): void {
    this.onResourcesChanged(this.resourceListId);
  }

  public override getDataset(): { [key: string]: any } {
    const dataset = super.getDataset();
    dataset['selectedFiles'] = undefined;
    dataset['resourceListId'] = this.resourceListId;
    return dataset;
  }

  public override setDataset(data: { [key: string]: any }): void {
    super.setDataset(data);

    if (!data) {
      return;
    }

    this.quickLinks = data?.['quickLinks'];
    this.resourceListId = data?.['resourceListId'];
  }

  public toggleItem(status: boolean, id: string): void {
    this.isActive = status;
    this.itemPosition = this.toggleContent.toggleContent(status, id);
  }

  public animationOverflow(status: boolean, id: string): void {
    this.toggleContent.toggleOverflow(status, id);
  }

  private addOrUpdateResourcesList(files: domainModels.ResourceDomainModel[]): Observable<boolean> {
    if (!files?.length) {
      return of(true);
    }

    const model: domainModels.ResourceListDomainModel = {
      id: this.resourceListId || undefined,
      resources: files.map((file, index) => ({
        fileId: (file as domainModels.ResourceDomainModel).id,
        resourceOrder: index,
      })),
    };

    return this.resourceManager.setResource(model, 'LIST').pipe(
      switchMap(resourceListId => {
        this.resourceListId = resourceListId;
        this.onResourcesChanged(resourceListId);
        return of(true);
      }),
      catchError(() => of(false))
    );
  }

  private onResourcesChanged(resourceId: string): void {
    if (!resourceId) {
      return;
    }

    this.resourceManager.getResource(resourceId)
      .pipe(
        untilComponentDestroyed(this),
        map(res => res as domainModels.ResourceListDomainModel),
        switchMap((response: domainModels.ResourceListDomainModel) =>
          of(
            (response.resources as domainModels.ResourceDomainModel[]).sort((a, b) =>
              a.resourceOrder > b.resourceOrder ? 1 : -1)
          ))
      )
      .subscribe((response) => {
        this.imagesListSrc = response;
      });
  }

  public override onSaveChanges(): Observable<boolean> {
    return this.addOrUpdateResourcesList(this.imagesListSrc);
  }

  public generateUrl(pageName?: string): string {
    const page = `${pageName ?? ''}`;

    return this.router
      .createUrlTree(page.trim().length > 0
        ? [
          this.dataService.appEditorUrlLinks ? this.dataService.appEditorUrlLinks : '',
          page.split('/'),
        ].flat()
        : ['#'])
      .toString();
  }

  public override beforeFormCreated(formFieldConfig: FormlyFieldConfig[]): FormlyFieldConfig[] {
    const { fieldGroup } = formFieldConfig[0];
    const cmsPage = (fieldGroup.find((x) => x.key === 'quickLinks').fieldArray as FormlyFieldConfig).fieldGroup.find(
      (w) => w.key === 'cmsPage'
    );

    cmsPage.props = {
      ...cmsPage.props,
      options: this.cmsListDropdown,
    };

    return formFieldConfig;
  }

  public getClass(align: string, direction: string): string {
    switch (true) {
      case direction === 'flex-row': {
        return align;
      }
      case direction === 'flex-column': {
        return 'align-items-center';
      }
      default: {
        return 'justify-content-left';
      }
    }
  }

  public getContainerWidth(element: number): void {
    switch (true) {
      case element > 991 && element < 1200:
        this.linksWrapperSize = 'lg';
        break;
      case element > 575 && element < 992:
        this.linksWrapperSize = 'md';
        break;
      case element > 400 && element < 576:
        this.linksWrapperSize = 'sm';
        break;
      case element < 401:
        this.linksWrapperSize = 'xs';
        break;
      default:
        this.linksWrapperSize = 'xl';
        break;
    }
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.linksContainer) {
      this.resizeObserver?.unobserve(this.linksContainer.nativeElement);
    }
  }

  public checkAspectRatio(image: domainModels.IFileDomainModel): domainModels.IFileDomainModel {
    if (this.platformServer) {
      return image;
    }

    let imageItem: domainModels.IFileDomainModel = image;
    const imageEl = new Image();
    imageEl.src = imageItem?.icon.s;
    imageEl.onload = (event: Event): void => {
      const loadedImage: HTMLImageElement = event.currentTarget as HTMLImageElement;
      if (!isNil(loadedImage)) {
        imageEl.width = loadedImage.width;
        imageEl.height = loadedImage.height;
        const ratio = (imageEl.height / imageEl.width).toFixed(2);
        imageItem = {
          ...imageItem,
          aspectRatio: `auto 1 / ${ratio}`,
        };
      }
    };
    return imageItem;
  }
}
