<ng-container *ngIf="data.gallery">
  <ngu-carousel
    #galleryCarousel
    [inputs]="carouselBanner"
    [dataSource]="data.gallery || []"
  >
    <div class="item" *nguCarouselDef="let item; let i = index">
      <div class="picsum-img-wrapper">
        <j3-image
          [imgSrc]="data.url[i]"
          [alt]="data.title + ' - image ' + i"
          [title]="data.title + ' - image ' + i + 1"
          sourceSize="m"
          loading="lazy"
        >
        </j3-image>
      </div>
    </div>
    <a
      class="carousel-control-button carousel-control-prev"
      NguCarouselPrev
      role="button"
      ><span class="carousel-control-prev-icon" aria-hidden="true"></span
      ><span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-button carousel-control-next"
      NguCarouselNext
      role="button"
      ><span class="carousel-control-next-icon" aria-hidden="true"></span
      ><span class="sr-only">Next</span></a
    >
    <ol class="carousel-indicators" role="tablist" NguCarouselPoint>
      <li
        *ngFor="let f of galleryCarousel?.pointNumbers; let j = index"
        [class.active]="j === galleryCarousel.activePoint"
        (click)="galleryCarousel.moveTo(j)"
      ></li>
    </ol>
  </ngu-carousel>
</ng-container>
