import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  enums,
  StoryDomainModel,
} from '@jotter3/api-connector';
import {
  InjectorHelperService,
  J3TranslateService,
  OrderBy,
} from '@jotter3/common-helpers';
import {
  attachmentOrder,
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { LoadingStateEnum } from '@jotter3/store-helpers';
import {
  dataQuery,
  Pagination,
} from '@jotter3/wa-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  combineLatestWith,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper_angular';
import { v4 as UUID } from 'uuid';

import { LayoutType } from '../../enums';
import { ToggleContentService } from '../../services';
import { NewsPreviewComponent } from '../index';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';
import {
  createConstFilters,
  createSlidesDisplayDropdownSource,
  DEFAULTS,
  LAYOUT_TYPE_OPTIONS,
  NEWS_ORDER_DROPDOWN_SOURCE,
  STORY_STYLE,
  STORY_STYLE_OPTIONS,
  TRANSITION_TIME_OPTIONS,
} from './consts';
import { NewsComponentStore } from './news.component.store';

@SiteComponent({
  selector: [
    'news-content-component',
    'new-news-content-component', // this additional selector is necessary in case if some of the schools created component with previous version
  ],
  displayName: 'News',
  icon: 'news',
  category: SiteComponentCategory.MODULES,
  showSettingsAfterAdd: true,
  roles: ['ROLE_NEWS_VIEWALL'],
})
@Component({
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
    NewsComponentStore,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class NewsComponent extends JotterSitesBaseComponent implements AfterViewInit {
  readonly #componentStore = inject(NewsComponentStore);
  readonly #subscription: Subscription = new Subscription();
  readonly #changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  readonly #toggleContentService: ToggleContentService = inject(ToggleContentService);
  readonly #injectorHelper: InjectorHelperService = inject(InjectorHelperService);
  readonly #dialog: NgbModal = inject(NgbModal);

  public readonly entities$: Observable<StoryDomainModel[]> = this.#componentStore.selectEntities$;
  public readonly isLoading$: Observable<boolean> = this.#componentStore.selectIsLoading;
  public readonly loadingState$: Observable<LoadingStateEnum> = this.#componentStore.selectLoadingState;
  public readonly pagination$: Observable<Pagination> = this.#componentStore.selectPagination$;
  public readonly instanceId: string = UUID();
  public readonly isActive$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  public itemPosition = '0px';
  public layoutTypeEnum = LayoutType;
  public readonly LoadingStateEnum = LoadingStateEnum;
  public readonly swiperConfig$: Observable<SwiperOptions> = this.entities$.pipe(
    filter(entities => entities.length > 0),
    map(entities => this.#updateConfig(entities))
  );
  public pictureSizeList = enums.PicturesSizes;

  #currentPage = 1;

  constructor() {
    super(NewsComponent);
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
    required: true,
    defaultValue: DEFAULTS.HEADER,
  })
  public header: string = DEFAULTS.HEADER;

  @Property({
    displayName: 'Stories to display',
    modifierType: ModifierType.NUMBER,
    required: true,
    description: 'Maximum number of stories to display at once',
    templateOptions: {
      required: true,
      type: 'number',
      placeholder: 'Enter value...',
      min: 1,
    },
    defaultValue: DEFAULTS.PAGE_SIZE,
  })
  public newsCount: number = DEFAULTS.PAGE_SIZE;

  @Property({
    displayName: 'Categories',
    modifierType: ModifierType.J3_API_MULTIDROPDOWN,
    resourceName: 'categories',
    valueProp: 'id',
    labelProp: 'name',
  })
  public selectedCategories: string[] = [];

  @Property({
    displayName: 'Show featured images',
    modifierType: ModifierType.CHECK_BOX,
    className: 'form-switch',
    defaultValue: DEFAULTS.SHOW_IMAGES,
  })
  public showImg: boolean = DEFAULTS.SHOW_IMAGES;

  @Property({
    displayName: 'Audience Targeting',
    modifierType: ModifierType.J3_API_MULTIDROPDOWN,
    resourceName: 'letters/available_groups',
    valueProp: 'id',
    labelProp: 'name',
    group: 'General',
    advanced: true,
    placeholder: 'Choose groups...',
  })
  public selectedGroups: string[] = [];

  @Property({
    displayName: 'Story Style',
    modifierType: ModifierType.DROPDOWN,
    template: 'vaIcons',
    dropdownSource: STORY_STYLE_OPTIONS,
    defaultValue: STORY_STYLE.STYLE_NORMAL,
  })
  public elTemplateValue: number = STORY_STYLE.STYLE_NORMAL;

  @Property({
    displayName: 'Layout',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: LAYOUT_TYPE_OPTIONS,
    defaultValue: DEFAULTS.LAYOUT_TYPE,
  })
  public layoutType: LayoutType = DEFAULTS.LAYOUT_TYPE;

  @Property({
    displayName: 'Order news by',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: attachmentOrder.ATTACHMENTS_ORDER,
    defaultValue: OrderBy.DATE_DESC,
    clearable: false,
  })
  public orderNewsBy: OrderBy = OrderBy.DATE_DESC;

  @Property({
    displayName: 'Order attachments by',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: attachmentOrder.ATTACHMENTS_ORDER,
    defaultValue: OrderBy.NAME_ASC,
    clearable: false,
  })
  public orderAttBy: OrderBy = OrderBy.NAME_ASC;

  @Property({
    displayName: 'Slides to display',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: createSlidesDisplayDropdownSource(4),
    hideExpression: 'model.layoutType !== 1',
    defaultValue: DEFAULTS.VISIBLE_SLIDES,
  })
  public slidesVisibleValue: number = DEFAULTS.VISIBLE_SLIDES;

  @Property({
    displayName: 'Show arrows',
    modifierType: ModifierType.CHECK_BOX,
    className: 'form-switch',
    hideExpression: 'model.layoutType !== 1',
    defaultValue: DEFAULTS.SHOW_ARROWS,
  })
  public showArrows: boolean = DEFAULTS.SHOW_ARROWS;

  @Property({
    displayName: 'Transition Time',
    modifierType: ModifierType.DROPDOWN,
    advanced: true,
    dropdownSource: TRANSITION_TIME_OPTIONS,
    required: true,
    defaultValue: DEFAULTS.ANIMATION_DELAY,
  })
  public animationDelayValue: number = DEFAULTS.ANIMATION_DELAY;

  @Property({
    displayName: 'Show indicators',
    modifierType: ModifierType.CHECK_BOX,
    className: 'form-switch',
    hideExpression: 'model.layoutType !== 1',
    defaultValue: DEFAULTS.SHOW_INDICATORS,
  })
  public showIndicatorsValue: boolean = DEFAULTS.SHOW_INDICATORS;

  @ViewChild('newsListSwiper') swiperComponent?: SwiperComponent;

  public handleToggleItem(status: boolean, id: string): void {
    this.isActive$.next(status);
    this.itemPosition = this.#toggleContentService.toggleContent(status, id);
  }

  public handleAnimationOverflow(status: boolean, id: string): void {
    this.#toggleContentService.toggleOverflow(status, id);
  }

  public handleNewsPreview(news: StoryDomainModel): void {
    this.#dialog.open(NewsPreviewComponent, {
      injector: this.#injectorHelper.provideModalData({ ...news }),
    });
  }

  public handlePageChanged(page: number): void {
    this.#currentPage = page;
    this.#updateDataQueryInStore(this.#currentPage);
  }

  public handleNextSlide(): void {
    this.swiperComponent?.swiperRef.slideNext();
  }

  public handlePrevSlide(): void {
    this.swiperComponent?.swiperRef.slidePrev();
  }

  public override afterSettingsSaved(): void {
    this.#updateDataQueryInStore(this.#currentPage);
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.#updateDataQueryInStore(this.#currentPage);

    this.#subscription.add(
      this.#componentStore.selectQueryParams$.pipe(
        combineLatestWith(this.settingsModalActive$),
        filter(([, active]) => !active),
        map(([params]) => params),
        distinctUntilChanged()
      ).subscribe((params) => {
        this.#componentStore.loadEntities(params);
        this.#changeDetector.markForCheck();
      })
    );
  }

  #updateConfig(newsList: StoryDomainModel[]): SwiperOptions {
    return {
      autoplay:
        this.animationDelayValue === 0 ||
            this.slidesVisibleValue > newsList.length
          ? false
          : {
            delay: this.animationDelayValue,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          },
      speed: 1000,
      pagination:
        this.showIndicatorsValue ||
            newsList.length >= this.slidesVisibleValue
          ? {
            el: '.news-pagination',
            type: 'bullets',
          }
          : false,
      loop: this.slidesVisibleValue <= newsList.length,
      slidesPerView: this.slidesVisibleValue,
      preventInteractionOnTransition: true,
      allowTouchMove:
              this.slidesVisibleValue <= newsList.length,
      spaceBetween: 30,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },

        768: {
          slidesPerView: this.slidesVisibleValue > 2 ? this.slidesVisibleValue - 2 : 1,
          spaceBetween: 20,
        },

        991: {
          slidesPerView: this.slidesVisibleValue > 1 ? this.slidesVisibleValue - 1 : 1,
          spaceBetween: 20,
        },

        1200: {
          slidesPerView: this.slidesVisibleValue,
          spaceBetween: 30,
        },
      },
    };
  }

  public picturesSizesList(): Array<enums.PicturesSizes> {
    return Object.values(this.pictureSizeList);
  }

  #updateDataQueryInStore(page: number): void {
    this.#componentStore.setQueryParams({
      pagination: {
        size: this.newsCount,
        page,
      },
      filters: !this.selectedCategories?.length ? createConstFilters() : [
        ...createConstFilters(),
        {
          type: dataQuery.FilterType.SELECT,
          property: 'categories',
          operator: dataQuery.Operator.IN,
          value: this.selectedCategories,
        },
      ],
      order: [NEWS_ORDER_DROPDOWN_SOURCE[this.orderNewsBy ?? OrderBy.NAME_DESC]],
    });
  }
}
