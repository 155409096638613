import { Injectable } from '@angular/core';
import {
  ApiResponse,
  LHSBracketsDataQueryAdapter,
} from '@jotter3/wa-core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
} from 'rxjs/operators';

import { GroupDomainModel } from '../../../domain-models';
import { MyGroupsDomainService } from '../../../domain-services';
import {
  LoadMyGroupsList,
  LoadMyGroupsListComplete,
  LoadMyGroupsListFailed,
} from '../actions';

@Injectable()
export class MyGroupsListEffects {
  loadMyGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMyGroupsList),
      mergeMap(() =>
        this.myGroupsDomainService.getAll().pipe(
          map(
            ({ result }) => LoadMyGroupsListComplete({ myGroupsList: result }),
            catchError((error) => of(LoadMyGroupsListFailed({ error })))
          )
        ))
    ));

  constructor(
    private actions$: Actions,
    private lhsBracketsDataQueryAdapter: LHSBracketsDataQueryAdapter,
    private myGroupsDomainService: MyGroupsDomainService
  ) {}

  private createApiRequest(size: number = 100, page: number = 1): Observable<ApiResponse<GroupDomainModel[]>> {
    const queryParams = this.lhsBracketsDataQueryAdapter.toQueryParam({
      pagination: {
        size,
        page,
      },
    });
    return this.myGroupsDomainService.getAll(queryParams);
  }
}
