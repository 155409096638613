export const languages = [
  {
    id: 'af',
    displayName: 'Afrikaans',
  },
  {
    id: 'sq',
    displayName: 'Albanian',
  },
  {
    id: 'am',
    displayName: 'Amharic',
  },
  {
    id: 'ar',
    displayName: 'Arabic',
  },
  {
    id: 'hy',
    displayName: 'Armenian',
  },
  {
    id: 'as',
    displayName: 'Assamese',
  },
  {
    id: 'ay',
    displayName: 'Aymara',
  },
  {
    id: 'az',
    displayName: 'Azerbaijani',
  },
  {
    id: 'bm',
    displayName: 'Bambara',
  },
  {
    id: 'eu',
    displayName: 'Basque',
  },
  {
    id: 'be',
    displayName: 'Belarusian',
  },
  {
    id: 'bn',
    displayName: 'Bengali',
  },
  {
    id: 'bho',
    displayName: 'Bhojpuri',
  },
  {
    id: 'bs',
    displayName: 'Bosnian',
  },
  {
    id: 'bg',
    displayName: 'Bulgarian',
  },
  {
    id: 'ca',
    displayName: 'Catalan',
  },
  {
    id: 'ceb',
    displayName: 'Cebuano',
  },
  {
    id: 'ny',
    displayName: 'Chichewa',
  },
  {
    id: 'zh',
    displayName: 'Chinese (Simplified)',
  },
  {
    id: 'zh-CN',
    displayName: 'Chinese (Simplified)',
  },
  {
    id: 'zh-TW',
    displayName: 'Chinese (Traditional)',
  },
  {
    id: 'co',
    displayName: 'Corsican',
  },
  {
    id: 'hr',
    displayName: 'Croatian',
  },
  {
    id: 'cs',
    displayName: 'Czech',
  },
  {
    id: 'da',
    displayName: 'Danish',
  },
  {
    id: 'dv',
    displayName: 'Divehi',
  },
  {
    id: 'doi',
    displayName: 'Dogri',
  },
  {
    id: 'nl',
    displayName: 'Dutch',
  },
  {
    id: 'en',
    displayName: 'English',
  },
  {
    id: 'eo',
    displayName: 'Esperanto',
  },
  {
    id: 'et',
    displayName: 'Estonian',
  },
  {
    id: 'ee',
    displayName: 'Ewe',
  },
  {
    id: 'tl',
    displayName: 'Filipino',
  },
  {
    id: 'fil',
    displayName: 'Filipino',
  },
  {
    id: 'fi',
    displayName: 'Finnish',
  },
  {
    id: 'fr',
    displayName: 'French',
  },
  {
    id: 'fy',
    displayName: 'Frisian',
  },
  {
    id: 'gl',
    displayName: 'Galician',
  },
  {
    id: 'lg',
    displayName: 'Ganda',
  },
  {
    id: 'ka',
    displayName: 'Georgian',
  },
  {
    id: 'de',
    displayName: 'German',
  },
  {
    id: 'el',
    displayName: 'Greek',
  },
  {
    id: 'gn',
    displayName: 'Guarani',
  },
  {
    id: 'gu',
    displayName: 'Gujarati',
  },
  {
    id: 'ht',
    displayName: 'Haitian Creole',
  },
  {
    id: 'ha',
    displayName: 'Hausa',
  },
  {
    id: 'haw',
    displayName: 'Hawaiian',
  },
  {
    id: 'iw',
    displayName: 'Hebrew',
  },
  {
    id: 'he',
    displayName: 'Hebrew',
  },
  {
    id: 'hi',
    displayName: 'Hindi',
  },
  {
    id: 'hmn',
    displayName: 'Hmong',
  },
  {
    id: 'hu',
    displayName: 'Hungarian',
  },
  {
    id: 'is',
    displayName: 'Icelandic',
  },
  {
    id: 'ig',
    displayName: 'Igbo',
  },
  {
    id: 'ilo',
    displayName: 'Iloko',
  },
  {
    id: 'id',
    displayName: 'Indonesian',
  },
  {
    id: 'ga',
    displayName: 'Irish Gaelic',
  },
  {
    id: 'it',
    displayName: 'Italian',
  },
  {
    id: 'ja',
    displayName: 'Japanese',
  },
  {
    id: 'jw',
    displayName: 'Javanese',
  },
  {
    id: 'jv',
    displayName: 'Javanese',
  },
  {
    id: 'kn',
    displayName: 'Kannada',
  },
  {
    id: 'kk',
    displayName: 'Kazakh',
  },
  {
    id: 'km',
    displayName: 'Khmer',
  },
  {
    id: 'rw',
    displayName: 'Kinyarwanda',
  },
  {
    id: 'gom',
    displayName: 'Konkani',
  },
  {
    id: 'ko',
    displayName: 'Korean',
  },
  {
    id: 'kri',
    displayName: 'Krio',
  },
  {
    id: 'ku',
    displayName: 'Kurdish (Kurmanji)',
  },
  {
    id: 'ckb',
    displayName: 'Kurdish (Sorani)',
  },
  {
    id: 'ky',
    displayName: 'Kyrgyz',
  },
  {
    id: 'lo',
    displayName: 'Lao',
  },
  {
    id: 'la',
    displayName: 'Latin',
  },
  {
    id: 'lv',
    displayName: 'Latvian',
  },
  {
    id: 'ln',
    displayName: 'Lingala',
  },
  {
    id: 'lt',
    displayName: 'Lithuanian',
  },
  {
    id: 'lb',
    displayName: 'Luxembourgish',
  },
  {
    id: 'mk',
    displayName: 'Macedonian',
  },
  {
    id: 'mai',
    displayName: 'Maithili',
  },
  {
    id: 'mg',
    displayName: 'Malagasy',
  },
  {
    id: 'ms',
    displayName: 'Malay',
  },
  {
    id: 'ml',
    displayName: 'Malayalam',
  },
  {
    id: 'mt',
    displayName: 'Maltese',
  },
  {
    id: 'mi',
    displayName: 'Maori',
  },
  {
    id: 'mr',
    displayName: 'Marathi',
  },
  {
    id: 'mni-Mtei',
    displayName: 'Meiteilon (Manipuri)',
  },
  {
    id: 'lus',
    displayName: 'Mizo',
  },
  {
    id: 'mn',
    displayName: 'Mongolian',
  },
  {
    id: 'my',
    displayName: 'Myanmar (Burmese)',
  },
  {
    id: 'ne',
    displayName: 'Nepali',
  },
  {
    id: 'nso',
    displayName: 'Northern Sotho',
  },
  {
    id: 'no',
    displayName: 'Norwegian',
  },
  {
    id: 'or',
    displayName: 'Odia (Oriya)',
  },
  {
    id: 'om',
    displayName: 'Oromo',
  },
  {
    id: 'ps',
    displayName: 'Pashto',
  },
  {
    id: 'fa',
    displayName: 'Persian',
  },
  {
    id: 'pl',
    displayName: 'Polish',
  },
  {
    id: 'pt',
    displayName: 'Portuguese',
  },
  {
    id: 'pa',
    displayName: 'Punjabi',
  },
  {
    id: 'qu',
    displayName: 'Quechua',
  },
  {
    id: 'ro',
    displayName: 'Romanian',
  },
  {
    id: 'ru',
    displayName: 'Russian',
  },
  {
    id: 'sm',
    displayName: 'Samoan',
  },
  {
    id: 'sa',
    displayName: 'Sanskrit',
  },
  {
    id: 'gd',
    displayName: 'Scots Gaelic',
  },
  {
    id: 'sr',
    displayName: 'Serbian',
  },
  {
    id: 'st',
    displayName: 'Sesotho',
  },
  {
    id: 'sn',
    displayName: 'Shona',
  },
  {
    id: 'sd',
    displayName: 'Sindhi',
  },
  {
    id: 'si',
    displayName: 'Sinhala',
  },
  {
    id: 'sk',
    displayName: 'Slovak',
  },
  {
    id: 'sl',
    displayName: 'Slovenian',
  },
  {
    id: 'so',
    displayName: 'Somali',
  },
  {
    id: 'es',
    displayName: 'Spanish',
  },
  {
    id: 'su',
    displayName: 'Sundanese',
  },
  {
    id: 'sw',
    displayName: 'Swahili',
  },
  {
    id: 'sv',
    displayName: 'Swedish',
  },
  {
    id: 'tg',
    displayName: 'Tajik',
  },
  {
    id: 'ta',
    displayName: 'Tamil',
  },
  {
    id: 'tt',
    displayName: 'Tatar',
  },
  {
    id: 'te',
    displayName: 'Telugu',
  },
  {
    id: 'th',
    displayName: 'Thai',
  },
  {
    id: 'ti',
    displayName: 'Tigrinya',
  },
  {
    id: 'ts',
    displayName: 'Tsonga',
  },
  {
    id: 'tr',
    displayName: 'Turkish',
  },
  {
    id: 'tk',
    displayName: 'Turkmen',
  },
  {
    id: 'ak',
    displayName: 'Twi',
  },
  {
    id: 'uk',
    displayName: 'Ukrainian',
  },
  {
    id: 'ur',
    displayName: 'Urdu',
  },
  {
    id: 'ug',
    displayName: 'Uyghur',
  },
  {
    id: 'uz',
    displayName: 'Uzbek',
  },
  {
    id: 'vi',
    displayName: 'Vietnamese',
  },
  {
    id: 'cy',
    displayName: 'Welsh',
  },
  {
    id: 'xh',
    displayName: 'Xhosa',
  },
  {
    id: 'yi',
    displayName: 'Yiddish',
  },
  {
    id: 'yo',
    displayName: 'Yoruba',
  },
  {
    id: 'zu',
    displayName: 'Zulu',
  },
];
