import { RootStoreConfig } from '@ngrx/store';

import { BaseStateModel } from './base-state.model';
import { clearStateMetaReducer } from './state-helpers';

export const genenrateStoreModuleConfig = <TState = BaseStateModel>(): RootStoreConfig<TState> => ({
  runtimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
  },
  metaReducers: [clearStateMetaReducer],
});
