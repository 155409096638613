import { Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { CategoryDomainModel } from '../domain-models';
import { PaginatedEntityCollectionServiceBase } from './paginated-entity-collection-base';

@Injectable({ providedIn: 'root' })
export class CategoriesCollectionService extends PaginatedEntityCollectionServiceBase<CategoryDomainModel> {
  constructor(serviceElementFactory: EntityCollectionServiceElementsFactory) {
    super('Category', serviceElementFactory);
  }
}
