import { isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import {
  DefaultHttpUrlGenerator,
  HttpResourceUrls,
  normalizeRoot,
  Pluralizer,
} from '@ngrx/data';

import {
  ModuleConfig,
  ModuleOptionsInjectionToken,
} from './module-config';

@Injectable({ providedIn: 'root' })
export class CustomHttpUrlGenerator extends DefaultHttpUrlGenerator {
  readonly COLLECTIONS_ENDPOINTS_MAP: { [key: string]: string } = {};

  constructor(
    @Inject(ModuleOptionsInjectionToken) moduleConfig: ModuleConfig,
    @Inject(PLATFORM_ID) platformId: object,
    private pluralizerService: Pluralizer
  ) {
    super(pluralizerService);

    this.COLLECTIONS_ENDPOINTS_MAP = this.initializeCollectionsEndpointsMap(moduleConfig, platformId);
  }

  protected override getResourceUrls(entityName: string, root: string): HttpResourceUrls {
    let resourceUrls = this.knownHttpResourceUrls[entityName];

    if (!resourceUrls) {
      const nRoot = normalizeRoot(this.COLLECTIONS_ENDPOINTS_MAP[entityName] || root);

      resourceUrls = {
        entityResourceUrl: `${nRoot}/${this.pluralizerService.pluralize(entityName)}/`.toLowerCase(),
        collectionResourceUrl: `${nRoot}/${this.pluralizerService.pluralize(entityName)}`.toLowerCase(),
      };
      this.registerHttpResourceUrls({ [entityName]: resourceUrls });
    }
    return resourceUrls;
  }

  private initializeCollectionsEndpointsMap(moduleConfig: ModuleConfig, platformId: object): { [key: string]: string } {
    const { DB_URL, THEMES_API, DB_URL_VPC } = moduleConfig;

    const API_URL = isPlatformServer(platformId) && DB_URL_VPC ? DB_URL_VPC : DB_URL;

    return {
      Letter: API_URL,
      Link: API_URL,
      Category: API_URL,
      Group: API_URL,
      Profile: API_URL,
      Notice: API_URL,
      New: API_URL,
      Page: API_URL,
      Site: API_URL,
      Template: THEMES_API,
      GeneralSettings: API_URL,
      CalendarEvents: API_URL,
      Gallery: API_URL,
      TenantDomain: API_URL,
      Page_content: API_URL,
      Slideshow: API_URL,
      Form: API_URL,
      FormField: API_URL,
      Form_Submition: API_URL,
      Tenants: API_URL,
      Group_Type: API_URL,
      Group_Members: API_URL,
      Group_Membership: API_URL,
      Add_Group_Membership: API_URL,
      Profile_Contacts: API_URL,
      Contact_Relation: API_URL,
      Files: API_URL,
      Group_Type_List: API_URL,
      MisList: API_URL,
      MobileSetting: API_URL,
      Revision: API_URL,
      Subscription: API_URL,
      NewsletterSendDetails: API_URL,
      Policy_groups: API_URL,
      Policies: API_URL,
    };
  }
}
