<ng-container *ngIf="cssClass === 'toggle-element'; else standard">
  <j3-toggle-info-component *ngIf="designMode"> </j3-toggle-info-component>
  <ng-container *ngIf="!designMode; else standard">
    <div
      (mouseenter)="toggleItem(true, elementId)"
      (mouseleave)="toggleItem(false, elementId)"
    >
      <div
        class="sectionHeader text-center h3 mb-0"
        [id]="'element-header-' + elementId"
      >
        {{ header }}
      </div>
      <div
        class="letters-wrapper section-toggle-wrapper absolute-section"
        [@openClose]="this.isActive ? 'open' : 'closed'"
        [id]="'element-wrapper-' + elementId"
        [ngClass]="{
          'section-toggle-wrapper-active': isActive === true
        }"
        [ngStyle]="{ bottom: isActive ? itemPosition : '0' }"
        (@openClose.done)="animationOverflow(false, elementId)"
        (@openClose.start)="animationOverflow(true, elementId)"
      >
        <div>
          <ng-container [ngTemplateOutlet]="contentData"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #standard>
  <div [ngClass]="cssClass">
    <div class="sectionHeader text-center h3 mb-0">
      {{ header }}
    </div>
    <ng-container [ngTemplateOutlet]="contentData"></ng-container>
  </div>
</ng-template>

<ng-template #contentData>
  <div *ngIf="headteachercheckValue">
    <div *ngIf="$headTeacher | async; let headTeacherValue">
      <span class="school-data-container school-data-headteacher-container">
        <i
          class="school-data-icon--i {{
            headteacherCustomIconValue ?? 'icon-student-school'
          }}"
          *ngIf="showHeadteacherIcons"
        ></i>
        <div class="school-data-value">{{ headTeacherValue.name }}</div>
      </span>
    </div>
  </div>

  <div *ngIf="addressCheckValue">
    <div *ngIf="$schoolAddress | async; let address">
      <span class="school-data-container school-data-address-container">
        <i
          class="school-data-icon--i {{
            addressCustomIconValue ?? 'icon-map-marker'
          }}"
          *ngIf="showAddressIcons"
        ></i>
        <div
          class="school-data-value"
          *ngIf="
            address.street ||
            address.houseNr ||
            address.postcode ||
            address.city
          "
        >
          <span class="school-data-address--street" *ngIf="address.street">
            {{ address.street }},
          </span>
          <span
            class="school-data-address--city"
            *ngIf="address.postcode || address.city"
          >
            {{ address.city }}, {{ address.postcode }}
          </span>
        </div>
      </span>
    </div>
  </div>

  <div *ngIf="phoneCheckValue">
    <div *ngIf="$phone | async; let phoneValue">
      <span class="school-data-container school-data-phone-container">
        <i
          class="school-data-icon--i {{
            phoneCustomIconValue ?? 'icon-phonealt'
          }}"
          *ngIf="showPhoneIcons"
        ></i>
        <div class="school-data-value">{{ phoneValue }}</div>
      </span>
    </div>
  </div>

  <div *ngIf="emailCheckValue">
    <div *ngIf="$mail | async; let mailValue">
      <span class="school-data-container school-data-email-container">
        <i
          class="school-data-icon--i {{
            emailCustomIconValue ?? 'icon-envelope '
          }}"
          *ngIf="showEmailIcons"
        ></i>
        <div class="school-data-value">
          <ng-container *ngIf="mailTo; else regularText">
            <a
              class="school-data-email--link"
              [attr.target]="targeBlank ? '_blank' : ''"
              href="mailto:{{ mailValue }}"
            >
              {{ mailValue }}
            </a>
          </ng-container>
          <ng-template #regularText>
            {{ mailValue }}
          </ng-template>
        </div>
      </span>
    </div>
  </div>
</ng-template>
