<div class="sectionHeader text-center h3 mb-0">
  {{ header | translate }}
</div>
<div
  id="mnt-parent-container"
  style="width: 96.7%; margin: 0 auto; background-color: transparent"
></div>
<ng-container *ngIf="newTermIdData === ''">
  <p class="my-new-term-info">
    please provide MyNewTerm ID value: Inside MyNewTerm Part C (API SCRIPT),
    find line with ID:
    <br />
    <span style="color: #dd4a68">mntSchoolVacancies</span>('<strong
      style="color: #800"
      >XXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX</strong
    >', 1);
    <br />
    Please copy and paste only this ID number
    <strong>XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX</strong> without ''
  </p>
</ng-container>
