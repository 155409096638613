import { Component } from '@angular/core';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';

@TemplateComponent({
  selector: 'jotter-tplcmp-text-editor',
  displayName: 'Text block',
  icon: 'text_fields',
})
@Component({
  template: '<quill-editor [ngClass]="className" format="html" theme="snow"></quill-editor>',
})
export class TextBlockElementComponent extends TemplateBaseComponent {
  constructor() {
    super(TextBlockElementComponent);
  }
}
