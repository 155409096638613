import { isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import {
  makeStateKey,
  StateKey,
  TransferState,
} from '@angular/platform-browser';
import {
  domainServices,
  ResourceDomainModel,
  ResourceListDomainModel,
} from '@jotter3/api-connector';
import {
  Observable,
  of,
} from 'rxjs';
import {
  map,
  tap,
} from 'rxjs/operators';

type ResourceType = 'LIST' | 'SINGLE';
const resourcesManagerStateKey: StateKey<Record<string, ResourceDomainModel | ResourceListDomainModel>> =
  makeStateKey('J3_RESOURCES_STATE_MANAGER');

@Injectable({ providedIn: 'root' })
export class ResourcesManagerService {
  private readonly resourcesCache: Record<string, ResourceDomainModel | ResourceListDomainModel>;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: object,
    private readonly resourcesService: domainServices.ResourcesDomainService,
    private readonly resourcesListService: domainServices.ResourcesListDomainService,
    @Optional() private readonly transferState: TransferState
  ) {
    this.resourcesCache =
      isPlatformBrowser(PLATFORM_ID) && this.transferState ? this.transferState.get(resourcesManagerStateKey, {}) : {};
  }

  public getResource(
    resourceId: string,
    resourceType: ResourceType = 'SINGLE'
  ): Observable<ResourceDomainModel | ResourceListDomainModel | undefined> {
    const resource = this.resourcesCache[resourceId];

    if (!resource) {
      return this.loadFromAPI(resourceId, resourceType);
    }

    return of(resource);
  }

  private loadFromAPI(
    resourceId: string,
    resourceType: ResourceType
  ): Observable<ResourceDomainModel | ResourceListDomainModel | undefined> {
    if (resourceType === 'LIST') {
      return this.resourcesListService.get(resourceId).pipe(
        map((res) => {
          const { success, result } = res;

          if (!success) {
            return undefined;
          }

          this.resourcesCache[result.id] = result as ResourceListDomainModel | ResourceDomainModel;
          this.updateServerState();

          return result;
        })
      );
    }

    return this.resourcesService.get(resourceId).pipe(
      map((res) => {
        const { success, result } = res;

        if (!success) {
          return undefined;
        }

        this.resourcesCache[result.id] = result as ResourceListDomainModel | ResourceDomainModel;
        this.updateServerState();

        return result;
      })
    );
  }

  public setResource(
    resourceModel: Partial<ResourceListDomainModel | ResourceDomainModel>,
    resourceType: ResourceType
  ): Observable<string> {
    if (resourceType === 'LIST') {
      return this.resourcesListService.post(resourceModel as ResourceListDomainModel).pipe(
        tap((res: ResourceListDomainModel) => {
          this.resourcesCache[res.id] = res;
          this.updateServerState();
        }),
        map((res: ResourceListDomainModel) => res.id)
      );
    }

    return this.resourcesService.post(resourceModel as ResourceDomainModel).pipe(
      tap((res: ResourceDomainModel) => {
        this.resourcesCache[res.id] = res;
        this.updateServerState();
      }),
      map((res: ResourceDomainModel) => res.id)
    );
  }

  public putResource(
    resourceModel: Partial<ResourceListDomainModel | ResourceDomainModel>,
    resourceType: ResourceType
  ): Observable<string> {
    if (resourceType === 'LIST') {
      return this.resourcesListService.put(resourceModel as ResourceListDomainModel).pipe(
        tap((res) => {
          this.resourcesCache[res.id] = res;
          this.updateServerState.bind(this);
        }),
        map((res) => res.id ?? '')
      );
    }

    return this.resourcesService.put(resourceModel as ResourceDomainModel).pipe(
      tap((res) => {
        this.resourcesCache[res.id] = res;
        this.updateServerState.bind(this);
      }),
      map((res) => res.id ?? '')
    );
  }

  private updateServerState(): void {
    if (isPlatformBrowser(this.platformId) || !this.transferState) {
      return;
    }

    this.transferState.set(resourcesManagerStateKey, this.resourcesCache);
  }
}
