import { InjectionToken } from '@angular/core';

const ModuleOptionsInjectionToken = new InjectionToken<ModuleConfig>('api-connector-module-config');

interface ModuleConfig {
  THEMES_API: string;
  DB_URL: string;
  DB_URL_VPC?: string;
  TRANSLATE_API: string;
}

export { ModuleConfig,
  ModuleOptionsInjectionToken };
