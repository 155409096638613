import {
  createAction,
  props,
} from '@ngrx/store';

import { TenantInfoDomainModel } from '../../domain-models';


const ACTIONS_PREFIX = '[J3|APP]';

export const AppStoreActions = {
  entered: createAction(`${ACTIONS_PREFIX} Entered`, props<{
    domain: string,
    tenant: TenantInfoDomainModel,
  }>()),
  showToastError: createAction(`${ACTIONS_PREFIX} Show Toast Error`, props<{ title: string, description: string }>()),
  showToastUnknownError: createAction(`${ACTIONS_PREFIX} Show Toast Unknown Error`),
};
