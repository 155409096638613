import { HttpParams } from '@angular/common/http';
import {
  inject,
  Injectable,
} from '@angular/core';
import {
  ApiResponse,
  ApiService,
} from '@jotter3/wa-core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  isArray,
  isNil,
} from 'lodash-es';
import {
  catchError,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { urlJoin } from 'url-join-ts';

import {
  DirectoryDomainModel,
  IFileDomainModel,
} from '../../../index';
import { DirectoriesStoreActions } from './directories.actions';


@Injectable()
export class DirectoriesEffects {
  readonly #actions$ = inject(Actions);
  readonly #apiService = inject(ApiService);

  loadRequested$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(DirectoriesStoreActions.loadTrigger),
      switchMap(() => this.#load(urlJoin('files', 'directories_tree'))),
      map((response: ApiResponse<DirectoryDomainModel[]>) => response.success
        ? DirectoriesStoreActions.loadSuccess({
          entity: !isNil(response.result)
            ? response.result
            : [],
        })
        : DirectoriesStoreActions.loadFailed({ errors: [response.error] })),
      catchError(err => of(DirectoriesStoreActions.loadFailed({ errors: [err] })))
    ));

  upsert$ = createEffect(() => this.#actions$.pipe(
    ofType(DirectoriesStoreActions.upsertTrigger),
    switchMap(({ entity }) => this.#upsert(entity)),
    map((response: ApiResponse<DirectoryDomainModel>) => response.success
      ? DirectoriesStoreActions.upsertSuccess({ entity: response.result })
      : DirectoriesStoreActions.upsertFailed({ errors: [response.error] })),
    catchError(err => of(DirectoriesStoreActions.upsertFailed({ errors: [err] })))
  ));

  delete$ = createEffect(() => this.#actions$.pipe(
    ofType(DirectoriesStoreActions.deleteTrigger),
    switchMap(({ entityId }) => this.#upsert({
      id: entityId,
      deletedStatus: 1,
    })),
    map((response: ApiResponse<DirectoryDomainModel>) => response.success
      ? DirectoriesStoreActions.deleteSuccess({ entityId: response.result.id })
      : DirectoriesStoreActions.deleteFailed({ errors: [response.error] })),
    catchError(err => of(DirectoriesStoreActions.deleteFailed({ errors: [err] })))
  ));

  #load(resource: string, queryParams?: HttpParams): Observable<ApiResponse<DirectoryDomainModel[]>> {
    return this.#apiService.load<DirectoryDomainModel>(resource, queryParams).pipe(
      map(({ result, ...rest }: ApiResponse<DirectoryDomainModel[]>) => ({
        ...rest,
        result: isNil(result) || !isArray(result) ? [] : result,
      }))
    );
  }

  #upsert({ id, name, parentId, deletedStatus }: Partial<DirectoryDomainModel>): Observable<ApiResponse<DirectoryDomainModel>> {
    return this.#apiService.save<IFileDomainModel>('files', {
      id,
      name,
      parentId,
      directory: true,
      deletedStatus,
    });
  }
}
