import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import {
  IAddressModel,
  IHeadTeacherModel,
  SiteSettingsDataService,
} from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import { ToggleContentService } from '../../services';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

@SiteComponent({
  selector: 'contact-data-content-component',
  displayName: 'Contact Data',
  icon: 'contacts',
  category: SiteComponentCategory.OTHER,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './contact-data.component.html',
  styleUrls: ['./contact-data.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
})
export class ContactDataSiteElementComponent extends JotterSitesBaseComponent implements OnInit {
  public headteachercheckValue = false;
  public addressCheckValue = false;
  public phoneCheckValue = false;
  public emailCheckValue = false;

  public headteacherCustomIconValue: string | null = null;
  public addressCustomIconValue: string | null = null;
  public phoneCustomIconValue: string | null = null;
  public emailCustomIconValue: string | null = null;
  isVisible = true;
  public isActive = false;
  public elementId: string = UUID();
  public itemPosition = '0px';

  @Input() iconCustomClass = '';
  @Input() mailTo = true;
  @Input() targeBlank = true;
  @Input() itemType = 'standard';

  $headTeacher: Observable<IHeadTeacherModel>;
  $schoolAddress: Observable<IAddressModel>;
  $phone: Observable<string>;
  $mail: Observable<string>;

  constructor(
    private eRef: ElementRef,
    private toggleContent: ToggleContentService,
    private siteService: SiteSettingsDataService
  ) {
    super(ContactDataSiteElementComponent);
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
    required: true,
    defaultValue: 'Contact Data',
  })
    header = 'Contact Data';

  @Property({
    displayName: 'Headteacher',
    modifierType: ModifierType.CHECK_BOX,
    defaultValue: false,
  })
  public get headteachercheck(): boolean {
    return this.headteachercheckValue;
  }

  public set headteachercheck(value: boolean) {
    if (this.headteachercheckValue === value || value === undefined) {
      return;
    }
    this.headteachercheckValue = value;
  }

  @Property({
    modifierType: ModifierType.RADIO_BUTTON,
    optionsList: [
      {
        label: 'No Icon',
        value: 0,
      },
      {
        label: 'Standard Icon',
        value: 1,
      },
      {
        label: 'Custom Icon',
        value: 2,
      },
    ],
    displayName: 'Show Icons',
    hideExpression: '!model.headteachercheck === true',
    defaultValue: 0,
  })
  public showHeadteacherIcons = 0;

  @Property({
    displayName: 'Headteacher Custom Icon',
    modifierType: ModifierType.TEXT,
    hideExpression: 'model.showHeadteacherIcons !== 2',
    templateOptions: {
      placeholder: 'Enter value...',
    },
  })
  public get headteacherCustomIcon(): string {
    return this.headteacherCustomIconValue;
  }

  public set headteacherCustomIcon(value: string) {
    this.headteacherCustomIconValue = value;
  }

  @Property({
    displayName: 'Address',
    modifierType: ModifierType.CHECK_BOX,
    defaultValue: false,
  })
  public get addressCheck(): boolean {
    return this.addressCheckValue;
  }

  public set addressCheck(value: boolean) {
    if (this.addressCheckValue === value || value === undefined) {
      return;
    }
    this.addressCheckValue = value;
  }

  @Property({
    modifierType: ModifierType.RADIO_BUTTON,
    optionsList: [
      {
        label: 'No Icon',
        value: 0,
      },
      {
        label: 'Standard Icon',
        value: 1,
      },
      {
        label: 'Custom Icon',
        value: 2,
      },
    ],
    displayName: 'Show Icons',
    hideExpression: '!model.addressCheck === true',
    defaultValue: 0,
  })
  public showAddressIcons = 0;

  @Property({
    displayName: 'Address Custom Icon',
    modifierType: ModifierType.TEXT,
    hideExpression: 'model.showAddressIcons !== 2',
    templateOptions: {
      placeholder: 'Enter value...',
    },
  })
  public get addressCustomIcon(): string {
    return this.addressCustomIconValue;
  }

  public set addressCustomIcon(value: string) {
    this.addressCustomIconValue = value;
  }

  @Property({
    displayName: 'Phone Number',
    modifierType: ModifierType.CHECK_BOX,
    defaultValue: false,
  })
  public get phoneCheck(): boolean {
    return this.phoneCheckValue;
  }

  public set phoneCheck(value: boolean) {
    if (this.phoneCheckValue === value || value === undefined) {
      return;
    }
    this.phoneCheckValue = value;
  }

  @Property({
    modifierType: ModifierType.RADIO_BUTTON,
    optionsList: [
      {
        label: 'No Icon',
        value: 0,
      },
      {
        label: 'Standard Icon',
        value: 1,
      },
      {
        label: 'Custom Icon',
        value: 2,
      },
    ],
    displayName: 'Show Icons',
    hideExpression: '!model.phoneCheck === true',
    defaultValue: 0,
  })
  public showPhoneIcons = 0;

  @Property({
    displayName: 'Phone Custom Icon',
    modifierType: ModifierType.TEXT,
    hideExpression: 'model.showPhoneIcons !== 2',
    templateOptions: {
      placeholder: 'Enter value...',
    },
  })
  public get phoneCustomIcon(): string {
    return this.phoneCustomIconValue;
  }

  public set phoneCustomIcon(value: string) {
    this.phoneCustomIconValue = value;
  }

  @Property({
    displayName: 'Email',
    modifierType: ModifierType.CHECK_BOX,
    defaultValue: false,
  })
  public get emailCheck(): boolean {
    return this.emailCheckValue;
  }

  public set emailCheck(value: boolean) {
    if (this.emailCheckValue === value || value === undefined) {
      return;
    }
    this.emailCheckValue = value;
  }

  @Property({
    modifierType: ModifierType.RADIO_BUTTON,
    optionsList: [
      {
        label: 'No Icon',
        value: 0,
      },
      {
        label: 'Standard Icon',
        value: 1,
      },
      {
        label: 'Custom Icon',
        value: 2,
      },
    ],
    displayName: 'Show Icons',
    hideExpression: '!model.emailCheck === true',
    defaultValue: 0,
  })
  public showEmailIcons = 0;

  @Property({
    displayName: 'Email Custom Icon',
    modifierType: ModifierType.TEXT,
    hideExpression: 'model.showEmailIcons !== 2',
    templateOptions: {
      placeholder: 'Enter value...',
    },
  })
  public get emailCustomIcon(): string {
    return this.emailCustomIconValue;
  }

  public set emailCustomIcon(value: string) {
    this.emailCustomIconValue = value;
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  public offClickHandler(event: any): void {
    const { parentNode } = event.target;
    if (!event.target.classList?.contains('school-data-icon-toggle')) {
      return;
    }
    if (
      (parentNode.classList?.contains('school-data-icon-toggle') ||
        event.target.classList?.contains('school-data-icon-toggle')) &&
      !this.eRef.nativeElement.contains(event.target) &&
      this.isVisible &&
      this.itemType === 'toggle'
    ) {
      this.isVisible = false;
    }
  }

  public toggle(): void {
    if (this.itemType === 'toggle' || this.itemType === 'width') {
      this.isVisible = !this.isVisible;
    }
  }

  public ngOnInit(): void {
    if (this.itemType === 'toggle' || this.itemType === 'width') {
      this.isVisible = false;
    }
    this.$headTeacher = this.siteService.getData().pipe(map((res) => res.headTeacher));
    this.$schoolAddress = this.siteService.getData().pipe(map((res) => res.address));
    this.$phone = this.siteService.getData().pipe(map((res) => res.phoneNr));
    this.$mail = this.siteService.getData().pipe(map((res) => res.email));
  }

  public toggleItem(status: boolean, id: string): void {
    this.isActive = status;
    this.itemPosition = this.toggleContent.toggleContent(status, id);
  }

  public animationOverflow(status: boolean, id: string): void {
    this.toggleContent.toggleOverflow(status, id);
  }
}
