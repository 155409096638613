import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  Inject,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  AppStoreActions,
  domainModels,
} from '@jotter3/api-connector';
import {
  J3TranslateService,
  MODULE_PROVIDER_TOKEN,
  ModuleProvider,
} from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import {
  ApiService,
  dataQuery,
} from '@jotter3/wa-core';
import { Store } from '@ngrx/store';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
} from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { isEqual } from 'lodash-es';
import * as moment from 'moment-timezone';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
} from 'rxjs';
import {
  map,
  switchMap,
} from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import { ToggleContentService } from '../../services';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

export enum FormFieldType {
  SHORT_TEXT = 1,
  LONG_TEXT = 2,
  MATRIX = 3,
  RADIO = 4,
  CHECKBOX = 5,
  SELECT = 6,
  EMAIL = 7,
  CAPTCHA = 8,
  SIGNATURE = 9,
  DATE = 10,
  DATE_TIME = 11
}

@SiteComponent({
  selector: 'forms-content-component',
  displayName: 'Forms',
  icon: 'single_line_text_input',
  category: SiteComponentCategory.MODULES,
  showSettingsAfterAdd: true,
  roles: ['ROLE_FORMS_VIEWALL'],
})
@Component({
  templateUrl: './forms-element.component.html',
  styleUrls: ['./forms-element.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FormsSiteElementComponent extends JotterSitesBaseComponent implements AfterViewInit {
  static dateFormat = 'YYYY-MM-DD h:mm A';
  static dateFormatNg = 'YYYY-MM-dd h:mm a';

  readonly #store: Store = inject(Store);
  private loadingValue = new Subject<boolean>();
  // eslint-disable-next-line
  private formExpiredValue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line
  private formNotStartedValue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public formIsVisible: Observable<boolean> = combineLatest([
    this.formExpiredValue,
    this.formNotStartedValue,
  ]).pipe(map(([
    expired,
    notStarted,
  ]) => !expired && !notStarted));
  private publishDateValue: moment.Moment;
  private publishUntilValue: moment.Moment;
  elementId: string = UUID();
  itemPosition = '0px';

  isActive = false;
  selectedFormValue: string | undefined;
  source: domainModels.FormDomainModel | undefined;

  formGroup: FormGroup = new FormGroup({});
  formModel: any = {}; // Dynamic form model;
  formDefinition: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {
    formState: {
      disabled: this.designMode,
    },
  };
  private formFieldsArray: domainModels.FormFieldDomainModel[] = [];
  private selectedGroupsValue: string[] = [];

  constructor(
    private apiService: ApiService,
    private toggleContent: ToggleContentService,
    private snackBarService: MatSnackBar,
    private translateService: TranslateService,
    @Inject(MODULE_PROVIDER_TOKEN) private moduleProvider: ModuleProvider,
    private readonly router: Router
  ) {
    super(FormsSiteElementComponent);
    this.loadingValue.next(true);
    this.formExpiredValue.next(false);
    this.formNotStartedValue.next(false);
  }

  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
    required: true,
    defaultValue: 'Form',
  })
  public header = 'Form';

  static getDescription(field: FormlyFieldConfig): string {

    if (!field?.props?.currentElement) {
      return null;
    }

    let data = '';

    if (field.props.currentElement?.publishDate) {
      const start = moment(field.props.currentElement?.publishDate).format(FormsSiteElementComponent.dateFormat);
      data += `Publish Date: ${ start }`;
    }

    if (field.props.currentElement?.publishUntil) {
      const end = moment(field.props.currentElement?.publishUntil).format(FormsSiteElementComponent.dateFormat);
      data += `, Publish Until: ${ end }`;
    }
    return data;
  }

  @Property({
    displayName: 'Form',
    modifierType: ModifierType.J3_API_DROPDOWN,
    resourceName: 'forms',
    labelProp: 'title',
    valueProp: 'id',
    constDataQueryFilters: [
      {
        type: dataQuery.FilterType.DATE,
        operator: dataQuery.Operator.GTE as any,
        property: 'publishUntil',
        value: new Date().toISOString(),
      },
    ],
    expressions: {
      'props.description': FormsSiteElementComponent.getDescription,
    },
  })
  get selectedForm(): string | undefined {
    return this.selectedFormValue;
  }

  set selectedForm(value: string | undefined) {
    if (isEqual(this.selectedFormValue, value)) {
      return;
    }
    this.selectedFormValue = value;

    if (this.viewInitialized) {
      this.getFromApi();
    }
  }

  public get deteTimeFormatNg(): string {
    return FormsSiteElementComponent.dateFormatNg;
  }

  @Property({
    displayName: 'Audience Targeting',
    modifierType: ModifierType.J3_API_MULTIDROPDOWN,
    resourceName: 'forms/available_groups',
    valueProp: 'id',
    labelProp: 'name',
    group: 'General',
    advanced: true,
    placeholder: 'Choose groups...',
  })
  public get selectedGroups(): string[] {
    return this.selectedGroupsValue;
  }

  public set selectedGroups(value: string[]) {
    if (isEqual(this.selectedGroupsValue, value)) {
      return;
    }
    this.selectedGroupsValue = value;
  }

  public get loading$(): Observable<boolean> {
    return this.loadingValue;
  }

  public get formExpired(): Observable<boolean> {
    return this.formExpiredValue;
  }

  public get formNotStarted(): Observable<boolean> {
    return this.formNotStartedValue;
  }

  public set publishDate(value: moment.Moment) {
    this.publishDateValue = value;
  }

  public get publishDate(): moment.Moment {
    return this.publishDateValue;
  }

  public set publishUntil(value: moment.Moment) {
    this.publishUntilValue = value;
  }

  public get publishUntil(): moment.Moment {
    return this.publishUntilValue;
  }

  public override ngAfterViewInit(): void {
    this.onDesingModeChanged.pipe(untilComponentDestroyed(this)).subscribe((val) => {
      this.designModeChanged(val);
    });
    this.designModeChanged(this.designMode);
    this.getFromApi();

    super.ngAfterViewInit();
  }

  public login(): void {
    window.location.replace(`/admin/auth/login?redirectUrl=${this.router.url}&client=true`);
  }

  private getFromApi(): void {
    if (!this.selectedForm) {
      return;
    }

    this.loadingValue.next(true);
    combineLatest([
      this.apiService.loadSingle<domainModels.FormDomainModel>('forms', this.selectedForm).pipe(map((res) => res.result)),
      this.apiService.load<domainModels.FormFieldDomainModel>(`forms/${this.selectedForm}/fields`).pipe(
        switchMap(({ result }) => {
          if (result.length) {
            this.formFieldsArray = result.sort((a: { fieldOrder: number }, b: { fieldOrder: number }) => a.fieldOrder - b.fieldOrder);
            return [this.mapToFormly(this.formFieldsArray)];
          } else {
            return [];
          }
        })
      ),
    ])
      .pipe(untilComponentDestroyed(this))
      .subscribe(([
        form,
        formFields,
      ]) => {
        this.formDefinition = formFields;
        this.loadingValue.next(false);

        this.publishDate = moment(form.publishDate);
        this.publishUntil = moment(form.publishUntil);

        this.formNotStartedValue.next(form.publishDate && moment(form.publishDate).isAfter(moment()));
        this.formExpiredValue.next(form.publishUntil && moment(form.publishUntil).isBefore(moment()));
      });
  }

  private generateCheckboxFormField(formField: domainModels.FormFieldDomainModel): FormlyFieldConfig {
    const formlyCheckboxes: FormlyFieldConfig[] = [
      {
        expressionProperties: {
          template: this.translateService.stream(formField.label).pipe(map((val) => `<label>${val}</label>`)),
        },
      },
    ];
    for (let i = 0; i < formField.content.length; i++) {
      formlyCheckboxes.push({
        key: formField.label + i,
        templateOptions: {
          required: formField.required,
          placeholder: '',
        },
        expressionProperties: {
          'templateOptions.label': this.translateService.stream(formField.content[i]),
          'templateOptions.disabled': 'formState.disabled',
        },
        type: 'checkbox',
        validators: undefined,
        defaultValue: false,
      });
    }
    return {
      fieldGroup: formlyCheckboxes,
    };
  }

  private generateDateFormField(formField: domainModels.FormFieldDomainModel, withTime: boolean = false): FormlyFieldConfig {
    const formlyDateTime: FormlyFieldConfig[] = [
      {
        template: `<label>${formField.label}</label>`,
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-8',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-auto forms-date-picker-wrapper',
                type: 'datepicker',
                key: formField.id,
                templateOptions: {
                  placeholder: 'Date',
                  required: formField.required,
                },
                expressionProperties: {
                  'templateOptions.disabled': 'formState.disabled',
                },
              },
              {
                key: `${formField.id}time`,
                type: 'timepicker',
                defaultValue: undefined,
                expressionProperties: {
                  'templateOptions.disabled': 'formState.disabled',
                },
                templateOptions: {
                  required: formField.required,
                },
                hideExpression: () => !withTime,
              },
            ],
          },
        ],
      },
    ];
    return {
      fieldGroup: formlyDateTime,
    };
  }

  private mapToFormly(fields: domainModels.FormFieldDomainModel[]): FormlyFieldConfig[] {
    const formlyArray: FormlyFieldConfig[] = [];
    for (const currentField of fields) {
      switch (currentField.type) {
        case FormFieldType.CHECKBOX: {
          formlyArray.push(this.generateCheckboxFormField(currentField));
          break;
        }
        case FormFieldType.DATE: {
          formlyArray.push(this.generateDateFormField(currentField));
          break;
        }
        case FormFieldType.DATE_TIME: {
          formlyArray.push(this.generateDateFormField(currentField, true));
          break;
        }
        default: {
          const formElement = this.mapFormFieldType(currentField);
          formlyArray.push({
            ...formElement,
            expressionProperties: {
              ...formElement?.expressionProperties,
              'templateOptions.disabled': 'formState.disabled',
            },
          });
        }
      }
    }
    return [
      {
        fieldGroup: formlyArray,
      },
    ].reverse();
  }

  private mapFormFieldType(fieldData: domainModels.FormFieldDomainModel): FormlyFieldConfig {
    switch (fieldData.type) {
      case FormFieldType.SHORT_TEXT: {
        return {
          key: fieldData.id,
          templateOptions: {
            required: fieldData.required,
            placeholder: '',
            maxLength: 120,
          },
          expressionProperties: {
            'templateOptions.label': this.translateService.stream(fieldData.label),
            'templateOptions.description': this.translateService.stream('Max length 120 characters'),
          },
          type: 'input',
        };
      }
      case FormFieldType.LONG_TEXT: {
        return {
          key: fieldData.id,
          templateOptions: {
            required: fieldData.required,
            placeholder: '',
            rows: 5,
            maxLength: 500,
          },
          expressionProperties: {
            'templateOptions.label': this.translateService.stream(fieldData.label),
            'templateOptions.description': this.translateService.stream('Max length 500 characters'),
          },
          type: 'textarea',
        };
      }
      case FormFieldType.RADIO: {
        const optionsList: { label: any, value: string }[] = [];
        fieldData.content.map((item) => {
          optionsList.push({
            label: this.translateService.instant(item),
            value: item,
          });
        });
        return {
          key: fieldData.id,
          templateOptions: {
            required: fieldData.required,
            options: combineLatest(
              optionsList.map((x) =>
                this.translateService.stream(x.label).pipe(
                  map((res) => ({
                    ...x,
                    value: res,
                  }))
                ))
            ).pipe(map((result) => result.flat())),
          },
          expressionProperties: {
            'templateOptions.label': this.translateService.stream(fieldData.label),
          },
          type: 'radio',
        };
      }
      case FormFieldType.CHECKBOX: {
        return {
          type: 'checkbox',
        };
      }
      case FormFieldType.SELECT: {
        const optionsList = fieldData.content.map((item) => ({
          id: item,
          name: this.translateService.instant(item),
        }));
        return {
          key: fieldData.id,
          templateOptions: {
            required: fieldData.required,
            placeholder: this.translateService.instant('Select'),
            label: this.translateService.instant(fieldData.label),
            options: combineLatest(
              optionsList.map((x) =>
                this.translateService.stream(x.id).pipe(
                  map((res) => ({
                    ...x,
                    name: res,
                  }))
                ))
            ).pipe(map((result) => result.flat())),
            valueProp: 'id',
            labelProp: 'name',
          },
          expressionProperties: {
            'templateOptions.placeholder': this.translateService.stream('Select'),
            'templateOptions.label': this.translateService.stream(fieldData.label),
          },
          type: 'ng-select',
        };
      }
      case FormFieldType.EMAIL: {
        return {
          key: fieldData.id,
          templateOptions: {
            required: fieldData.required,
            placeholder: '',
          },
          expressionProperties: {
            'templateOptions.label': this.translateService.stream(fieldData.label),
          },
          type: 'input',
          validators: {
            email: {
              expression: (c: FormControl): boolean => {
                if (!c.value) {
                  return true;
                }
                return /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]*([a-z]+)$/.test(c.value.trim());
              },
              message: (error: any, field: FormlyFieldConfig) =>
                `${field.formControl?.value.trim()} ${this.translateService.instant('is not a valid email.')} `,
            },
          },
        };
      }
      default: {
        return {
          expressionProperties: {
            template: this.translateService.stream('Unknown Form Field').pipe(map((val) => `<hr><h5>${val}</h5><hr>`)),
          },
        };
      }
    }
  }

  public onSubmit(): void {
    const formValues: Record<string, any> = this.formGroup.getRawValue();
    const formSubmitionContent: domainModels.FormSubmitionContent[] = [];

    for (const item of this.formFieldsArray) {
      const currentFormFieldId = item.id as string;

      switch (item.type) {
        case FormFieldType.CHECKBOX: {
          const checkboxValues: domainModels.FormSubmitionValue[] = [];
          for (let i = 0; i < item.content.length; i++) {
            checkboxValues.push({
              label: item.content[i],
              answer: formValues[item.label + i],
            });
          }
          formSubmitionContent.push({
            formFieldId: currentFormFieldId,
            value: checkboxValues,
          });
          break;
        }
        case FormFieldType.SELECT: {
          const selectValues: domainModels.FormSubmitionValue[] = [];
          item.content.map((option) => {
            selectValues.push({
              label: option,
              answer: formValues[`${item.id}`] === option,
            });
          });
          formSubmitionContent.push({
            formFieldId: currentFormFieldId,
            value: selectValues,
          });
          break;
        }
        case FormFieldType.RADIO: {
          const radioValues: domainModels.FormSubmitionValue[] = [];
          for (const answer of item.content) {
            radioValues.push({
              label: answer,
              answer: formValues[`${item.id}`] === answer,
            });
          }
          formSubmitionContent.push({
            formFieldId: currentFormFieldId,
            value: radioValues,
          });
          break;
        }
        case FormFieldType.DATE: {
          const dateValue = formValues[currentFormFieldId]
            ? moment(formValues[currentFormFieldId]).format('YYYY-MM-DD').toString()
            : null;

          if (dateValue !== null) {
            formSubmitionContent.push({
              formFieldId: currentFormFieldId,
              value: dateValue,
            });
          }
          break;
        }
        case FormFieldType.DATE_TIME: {
          const timeValue = formValues[`${currentFormFieldId}time`] ? moment(formValues[`${currentFormFieldId}time`]) : null;

          const hasTime = !!timeValue;
          const hasDate = !!formValues[currentFormFieldId];

          let value = null;
          if (hasDate || hasTime) {
            value = moment(formValues[currentFormFieldId]);
            if (hasTime) {
              value = value.set({
                hour: timeValue.hour(),
                minute: timeValue.minute(),
                second: 0,
              });
            }
            value = value.format().toString();
          }
          if (value !== null) {
            formSubmitionContent.push({
              formFieldId: currentFormFieldId,
              value,
            });
          }
          break;
        }
        default: {
          if (formValues[currentFormFieldId]) {
            formSubmitionContent.push({
              formFieldId: currentFormFieldId,
              value: formValues[currentFormFieldId],
            });
          }
        }
      }
    }
    const submitEntity: Partial<domainModels.FormSubmitionDomainModel> = {
      userEmail: undefined,
      content: formSubmitionContent,
    };
    this.subscriptions.push(
      this.apiService.save(`forms/${this.selectedForm}/submit`, submitEntity).subscribe((res) => {
        if (res.success && this.options?.resetModel) {
          this.options.resetModel();
          this.showSnackbar();
        } else {
          const description = this.translateService.instant('Answer saving error');
          this.#store.dispatch((AppStoreActions.showToastError({
            title: 'Error!',
            description,
          })));
        }
      })
    );
  }

  private showSnackbar(): void {
    this.snackBarService.open(this.translateService.instant('Answer saved sucessfully'),
      undefined,
      {
        duration: 3000,
        panelClass: 'snack-success',
      });
  }

  public toggleItem(status: boolean, id: any): void {
    this.isActive = status;
    this.itemPosition = this.toggleContent.toggleContent(status, id);
  }

  public animationOverflow(status: boolean, id: any): void {
    this.toggleContent.toggleOverflow(status, id);
  }

  private designModeChanged(currentValue: boolean): void {
    this.options.formState.disabled = currentValue;
  }
}
