import {
  Component,
  EventEmitter,
  Input,
  Output,
  Type,
} from '@angular/core';

import {
  constant,
  Property,
} from '../decorators';
import { ModifierType } from '../enums';
import { TemplateComponentMetadata } from '../models';
import { BaseBuilderComponent } from './base-builder.compoment';

@Component({
  template: '',
})
export abstract class TemplateBaseComponent extends BaseBuilderComponent {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDataChanged: EventEmitter<{ instance: string, dataset: { [key: string]: any } }> = new EventEmitter<{
    instance: string,
    dataset: { [key: string]: any },
  }>();

  private readonly metadata: TemplateComponentMetadata;

  protected constructor(protected override type: Type<TemplateBaseComponent>) {
    super(type);

    this.metadata = this.getComponentMetadata(constant.TEMPLATE_COMPONENT_DESCRIPTION_META_KEY);
  }

  $templateInstanceName: string;
  $injectedDataset: { [key: string]: any };
  templateDesignMode = false;

  @Property({
    displayName: 'CSS Class',
    modifierType: ModifierType.TEXT,
    group: 'General',
  })
  @Input() className: string;

  public injectDataset(dataset: { [key: string]: any }): void {
    this.$injectedDataset = dataset;
    Object.assign(this, dataset);
  }

  public notifyOnDatasetChanged(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const copyInstance: any = this;
    for (const key of Object.keys(this.$injectedDataset)) {
      this.$injectedDataset[key] = copyInstance[key];
    }

    this.onDataChanged.emit({
      instance: this.$templateInstanceName,
      dataset: this.$injectedDataset,
    });
  }

  public get componentMetadata(): TemplateComponentMetadata {
    return this.metadata;
  }
}
