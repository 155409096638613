import { Injectable } from '@angular/core';

import { CalendarCollectionService } from '../collection-services';
import { CalnedarDomainModel } from '../domain-models';
import { EntityCollectionLoadedAbstractGuard } from './entity-collection-loaded.abstract.guard';

@Injectable({ providedIn: 'root' })
export class CalendarCollectionLoadedGuard extends EntityCollectionLoadedAbstractGuard<CalnedarDomainModel> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(stateCollectionService: CalendarCollectionService) {
    super(stateCollectionService);
  }
}
