<section class="container-fluid app-container" *ngIf="pageExist | ngrxPush">
  <div class="row app-container-row">
    <cms-template-resolver
      class="col app-container-content"
      [templateDef]="siteTemplate$ | ngrxPush"
    ></cms-template-resolver>
  </div>
</section>
<j3-cookies-box
  *ngIf="cookieBoxVisible$ | ngrxPush"
  [cookiesLayout]="cookiesType$ | ngrxPush"
  (CookeResponse)="onCookeResponse($event)"
  (NavigateToPolicy)="onNavigateToPolicy()"
  (AnaliticsResponse)="onAnalyticsResponse($event)"
></j3-cookies-box>
