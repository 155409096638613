import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  OnInit,
  Optional,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  domainModels,
  enums as apiEnums,
  FilesCollectionService,
  NGB_MODAL_DATA,
  pagesListState,
  SiteGuard,
} from '@jotter3/api-connector';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import {
  cloneDeep,
  isNil,
} from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  of,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import * as enums from '../../../../enums';
import { LinkPluginModel } from '../../../../models';
import { ResourcesManagerService } from '../../../../services';

export interface LinkFormDialogData {
  formModel: LinkPluginModel;
  showRemoveButton: boolean;
}

@Component({
  templateUrl: './link.dialog.component.html',
  styles: ['.close-icon{ cursor: pointer; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkDialogComponent extends OnDestroyMixin implements OnInit {
  readonly #siteGuard: SiteGuard = inject(SiteGuard);

  public viewInitialized = false;
  private formGroupValue: FormGroup;
  private formFieldsValue: FormlyFieldConfig[];
  private modelValue: BehaviorSubject<LinkPluginModel> = new BehaviorSubject<LinkPluginModel>(undefined);
  private readonly showRemoveButton: boolean;
  private pageMenuLinksList: any[] = [];
  constructor(
    private activeModal: NgbActiveModal,
    private store: Store,
    private resourceManager: ResourcesManagerService,
    private filesCollectionService: FilesCollectionService,
    @Inject(NGB_MODAL_DATA) @Optional() private dialogData: LinkFormDialogData
  ) {
    super();

    this.showRemoveButton = dialogData?.showRemoveButton;
  }

  public get formGroup(): FormGroup {
    if (!this.formGroupValue) {
      this.formGroupValue = new FormGroup({});
    }

    return this.formGroupValue;
  }

  public get model$(): Observable<LinkPluginModel> {
    return this.modelValue.asObservable();
  }

  public get formFields(): FormlyFieldConfig[] {
    if (!this.formFieldsValue) {
      this.formFieldsValue = this.defineFormFields();
    }

    return this.formFieldsValue;
  }

  public get removeButton(): boolean {
    return this.showRemoveButton;
  }

  public get formActionType(): typeof enums.FormAction {
    return enums.FormAction;
  }

  public ngOnInit(): void {
    this.initializeModel(this.dialogData?.formModel);
  }

  public onClose(): void {
    this.activeModal.close({
      formAction: enums.FormAction.CANCEL,
    });
  }

  public onSubmit(formAction: enums.FormAction): void {
    let invokeSubmit = of(true);
    if (formAction === enums.FormAction.SUBMIT && this.modelValue.value.linkType === enums.LinkType.LINK_TO_FILE) {
      const { resource, resourceId, file } = this.modelValue.value;
      const invokeResource =
        resource || resourceId
          ? this.resourceManager.putResource.bind(this.resourceManager)
          : this.resourceManager.setResource.bind(this.resourceManager);
      const resourceModel: domainModels.ResourceDomainModel = {
        id: !isNil(resource) || !isNil(resourceId) ? resource?.id || resourceId : undefined,
        file: file as string,
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      invokeSubmit = invokeResource(resourceModel, 'SINGLE').pipe(
        untilComponentDestroyed(this),
        tap((res: string) => {
          this.modelValue.value.resourceId = res;
        })
      );
    }

    invokeSubmit.pipe(take(1)).subscribe(() => {
      this.activeModal.close({
        model: this.modelValue.value,
        formAction,
      });
    });
  }

  private defineFormFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            type: 'checkbox',
            key: 'openInNewTab',
            defaultValue: false,
            templateOptions: {
              label: 'Open in new tab?',
            },
          },
          {
            type: 'ng-select',
            key: 'linkType',
            templateOptions: {
              label: 'Link type',
              options: of([
                {
                  id: enums.LinkType.EXTERNAL_URL,
                  name: 'External url',
                },
                {
                  id: enums.LinkType.LINK_CMS_TO_PAGE,
                  name: 'Link to cms page',
                },
                {
                  id: enums.LinkType.MAIL,
                  name: 'E-Mail address',
                },
                {
                  id: enums.LinkType.LINK_TO_FILE,
                  name: 'File',
                },
              ]),
              valueProp: 'id',
              labelProp: 'name',
              multiple: false,
              placeholder: 'Select link type',
              required: true,
            },
          },
          {
            type: 'ng-select',
            key: 'protocol',
            templateOptions: {
              label: 'Protocol',
              options: of([
                {
                  id: 'https',
                  name: 'HTTPS',
                },
                {
                  id: 'http',
                  name: 'HTTP',
                },
              ]),
              valueProp: 'id',
              labelProp: 'name',
              multiple: false,
              placeholder: 'Select protocol type',
              required: true,
            },
            hideExpression: (model) => model?.linkType !== enums.LinkType.EXTERNAL_URL,
          },
          {
            type: 'input',
            key: 'url',
            templateOptions: {
              label: 'URL',
              placeholder: 'Enter url',
              required: true,
            },
            hideExpression: (model) => model?.linkType !== enums.LinkType.EXTERNAL_URL,
            hooks: {
              onInit: (field) =>
                field.formControl.valueChanges.pipe(
                  distinctUntilChanged(),
                  tap((val: string) => {
                    if (this.isValidHttpUrl(val)) {
                      const urlArray = val.split('//');
                      this.modelValue.next({
                        ...this.modelValue.value,
                        url: urlArray[1],
                        protocol: urlArray[0].replace(':', ''),
                      });
                    }
                  })
                ),
            },
          },
          {
            type: 'input',
            key: 'mail',
            templateOptions: {
              label: 'E-mail address',
              placeholder: 'Enter e-mail address',
              required: true,
            },
            hideExpression: (model) => model?.linkType !== enums.LinkType.MAIL,
          },
          {
            type: 'ng-select',
            key: 'page',
            templateOptions: {
              label: 'CMS pages',
              options: (this.#siteGuard.canActivate() as Observable<boolean>).pipe(
                filter((x) => x),
                switchMap(() =>
                  this.store.select(pagesListState.pagesSelectors.pagesListStateSelector).pipe(
                    tap(({ isLoaded, isLoading }) => {
                      if (!isLoaded && !isLoading) {
                        this.store.dispatch(pagesListState.pagesActions.pagesListActions.LoadPagesList({}));
                      }
                    }),
                    filter((state) => state.isLoaded),
                    switchMap((state) => of(Object.values(state.entities))),
                    map((pages) => {
                      const pageList = pages.filter((page) => page.draftState === apiEnums.DraftState.PUBLISHED);
                      const treeMenu = this.generateTreeMenu(
                        cloneDeep(
                          pageList.sort((a, b) => (!isNil(a.lp) ? a.lp : Infinity) - (!isNil(b.lp) ? b.lp : Infinity))
                        ),
                        (item) => !item.parentId || item.parentId?.trim() === ''
                      );

                      return this.generateOptionList(treeMenu);
                    })
                  ))
              ),
              valueProp: 'id',
              labelProp: 'name',
              multiple: false,
              placeholder: 'Select page',
            },
            hideExpression: (model) => model?.linkType !== enums.LinkType.LINK_CMS_TO_PAGE,
          },
          {
            type: 'file-upload',
            key: 'file',
            templateOptions: {
              multipleItems: false,
              allowTypes: ['all'],
              label: 'File',
              required: true,
            },
            hideExpression: (model) => model?.linkType !== enums.LinkType.LINK_TO_FILE,
          },
          {
            type: 'checkbox',
            key: 'linkAsButton',
            defaultValue: false,
            templateOptions: {
              label: 'Link as button',
            },
          },
        ],
      },
    ];
  }

  private isValidHttpUrl(value: string): boolean {
    let url;

    try {
      url = new URL(value);
    } catch (err) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  private generateOptionList(pageList: any[]): any[] {
    pageList.map((item) => {
      this.pageMenuLinksList.push({
        name: `${'\u2014'.repeat(item.level)}${item.title}`,
        disabled: item.type !== 1,
        id: item.url,
      });
      if (item?.children) {
        this.generateOptionList(item.children);
      }
    });

    return this.pageMenuLinksList;
  }

  public generateTreeMenu(array: any[], comparer: (parent: any) => boolean, level?: number): any[] {
    const result: any[] = [];
    for (const item of array.filter((x) => comparer(x))) {
      const levelNr = level ? level : 0;
      if (item.parentId) {
        item.parentName = array.find((page) => page.id === item.parentId)?.title;
      }
      result.push({
        ...item,
        level: levelNr,
        children: this.generateTreeMenu(array, (subElement) => subElement.parentId === item.id, levelNr + 1),
      });
    }

    return result;
  }

  private initializeModel(model: LinkPluginModel): void {
    const { resource, resourceId } = model;

    if (resource || resourceId) {
      this.resourceManager
        .getResource(resource?.id || resourceId)
        .pipe(
          switchMap((res) => {
            const result = res as domainModels.ResourceDomainModel;

            //TODO temporary fix (wait until backend unify response for files in each places)
            return this.filesCollectionService.getByKey(typeof result.file === 'string' ? result.file : result.file.id).
              pipe(
                map(file => {
                  return {
                    ...result,
                    file,
                  };
                })
              );
          }),
          take(1)
        ).subscribe(response => {
          this.modelValue.next({
            ...model,
            ...response,
          });
          this.viewInitialized = true;
        });
      return;
    }

    this.modelValue.next({ ...model });
    this.viewInitialized = true;
  }
}
