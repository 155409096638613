import { isPlatformServer } from '@angular/common';
import {
  Component,
  Inject,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { REQUEST } from '@nguniversal/express-engine/tokens';

import { J3Request } from '../../../express-common';
import { AppConfigurationService } from '../../services';

@Component({
  templateUrl: './error-base.component.html',
  styleUrls: ['./error-base.component.scss'],
})
export class ErrorBaseComponent {
  pageInfo: {
    title?: string,
    description?: string,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private appConfigService: AppConfigurationService,
    @Inject(PLATFORM_ID) platformId: object,
    @Optional() @Inject(REQUEST) request: J3Request
  ) {
    const { title, description, statusCode } = this.activatedRoute?.snapshot?.data ?? {};

    this.pageInfo = {
      title,
      description,
    };

    this.appConfigService.title = title;
    if (isPlatformServer(platformId) && request?.cacheEntry) {
      request.cacheEntry.errorPage = true;
      request.cacheEntry.statusCode = statusCode;
    }
  }
}
