import { isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Injector,
  Input,
  Output,
  PLATFORM_ID,
  ProviderToken,
  Type,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EditorComponent } from '@tinymce/tinymce-angular';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { isNil } from 'lodash-es';
import {
  Observable,
  of,
} from 'rxjs';
import {
  finalize,
  map,
} from 'rxjs/operators';
import * as tiny from 'tinymce';
import { v4 as UUID } from 'uuid';

import {
  nodeTransformations,
  plugins,
} from './plugins';

@Component({
  selector: 'j3-text-editor',
  template: `
    <ng-container *ngIf="!toggleable else toggleableEditor">
      <div
        *ngIf="disabled"
        [innerHTML]="(translatedContent | ngrxPush) || '' | safeUrl : 'html'"
        #previewBox
        class="{{ previewContainerClass || 'j3-text-editor-preview-wrapper' }}"
      ></div>
      <div
        *ngIf="!disabled && inlineMode"
        id="{{ toolbarId }}"
        class="j3-text-editor-toolbar"
      ></div>
      <editor
        *ngIf="!disabled && !toggleable"
        [(ngModel)]="content"
        [disabled]='disabled'
        [init]="this.defineEditorConfig()"
        [inline]="!!inlineMode">
      >
      </editor>
    </ng-container>

    <ng-template #toggleableEditor>
    <button (click)="enableEditor()" class="btn btn-primary form-toggle-btn" [disabled]="active">Edit</button>
      <div
        *ngIf="!active"
        [innerHTML]="(translatedContent | ngrxPush) || '' | safeUrl : 'html'"
        #previewBox
        class="{{ previewContainerClass || 'j3-text-editor-preview-wrapper' }}"
      ></div>
      <editor
        *ngIf="active"
        [(ngModel)]="content"
        (onBlur)="disableEditor()"
        class="{{toolbarId}}"
        [init]="this.defineLimitedEditorConfig()">
      >
      </editor>
    </ng-template>
  `,
  styleUrls: ['./j3-text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => J3TextEditorComponent),
      multi: true,
    },
  ],
})
export class J3TextEditorComponent extends OnDestroyMixin implements AfterViewInit, ControlValueAccessor {
  readonly #platformId: object = inject(PLATFORM_ID);
  readonly #appInjector: Injector = inject(Injector);
  readonly #changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  readonly translateService: TranslateService = inject(TranslateService);
  @Output() readonly editorInitialized: EventEmitter<void> = new EventEmitter<void>();
  @Output() blurEvent: EventEmitter<void> = new EventEmitter<void>();

  private translatedContentValue: Observable<any> = of('');
  public readonly toolbarId: string = `editorToolbar-${UUID()}`;
  private contentValue: string;
  private disabledValue = false;
  private toggleableValue = false;
  private active = false;
  private prevContainerClass: string;
  private inlineModeValue: boolean | undefined = true;
  private heightValue: string;
  @ViewChild('tinyEditor') tinyEditor: EditorComponent;
  @ViewChild('previewBox') previewBox: ElementRef<HTMLElement>;
  @Output() public contentChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() public editorFonts: string[];

  public onChange: any = (): void => {};
  public onTouched: any = (): void => {};

  @Input()
  public get content(): string {
    return this.contentValue;
  }

  public set content(value: string) {
    this.contentValue = value;
    this.contentChange.emit(this.contentValue);

    if (this.contentValue?.length) {
      this.translatedContentValue = this.getTranslationObserver(this.content);
    }

    this.onChange(value);
  }

  public get translatedContent(): Observable<any> {
    if (!this.translatedContentValue && this.content?.length) {
      this.translatedContentValue = this.getTranslationObserver(this.content);
    }

    return this.translatedContentValue;
  }

  @Input()
  public get disabled(): boolean {
    return this.disabledValue;
  }

  public set disabled(value: boolean) {
    this.disabledValue = value;

    if (isPlatformServer(this.#platformId)) {
      return;
    }

    this.#changeDetector.detectChanges();

    this.transformNodes(this.previewBox?.nativeElement);
  }

  @Input()
  public get toggleable(): boolean {
    return this.toggleableValue;
  }

  public set toggleable(value: boolean) {
    this.toggleableValue = value;

    if (isPlatformServer(this.#platformId)) {
      return;
    }

    this.#changeDetector.detectChanges();

    this.transformNodes(this.previewBox?.nativeElement);
  }

  @Input()
  public get previewContainerClass(): string {
    return this.prevContainerClass;
  }

  public set previewContainerClass(value: string) {
    this.prevContainerClass = value;
  }

  @Input()
  public get inlineMode(): boolean | undefined {
    return this.inlineModeValue;
  }

  public set inlineMode(value: boolean | undefined) {
    this.inlineModeValue = value;
  }

  @Input()
  public get height(): string {
    return this.heightValue;
  }
  public set height(value: string) {
    this.heightValue = value;
  }

  public ngAfterViewInit(): void {
    if (isPlatformServer(this.#platformId)) {
      return;
    }

    this.transformNodes(this.previewBox?.nativeElement);

    this.translateService.onLangChange.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.translatedContentValue = this.getTranslationObserver(this.content);
    });

    if (!this.tinyEditor) {
      return;
    }

    this.tinyEditor.registerOnTouched(this.onTouched);
  }

  public writeValue(value: string): void {
    this.content = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
    if (this.tinyEditor) {
      this.tinyEditor.registerOnTouched(this.onTouched);
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  public disableEditor(): void {
    setTimeout(() => {
      this.active = false;
    }, 200);
  }

  public enableEditor(): void {
    this.active = true;
    this.blurEvent.emit();
  }

  private onSetupEditor(editor: tiny.Editor): void {
    for (const plugin of plugins) {
      plugin(editor, this.#injectType.bind(this));
    }

    if (this.inlineMode) {
      editor.on('blur', (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
      });
      editor.on('init', () => {
        this.editorInitialized.emit();
        editor.fire('focus');
      });
    }
  }

  private onEditorInstance(editor: tiny.Editor): void {
    if (!this.inlineMode) {
      return;
    }

    editor.fire('focus');
  }

  private transformNodes(element: HTMLElement): void {
    if (isPlatformServer(this.#platformId) || !element) {
      return;
    }

    for (const nodeTransform of nodeTransformations) {
      nodeTransform(element, this.#injectType.bind(this));
    }
  }

  public defineEditorConfig(disabled: boolean = false): tiny.RawEditorOptions {
    const config: tiny.RawEditorOptions = {
      plugins: `lists help wordcount table code preview advlist fullscreen emoticons ${disabled ? 'autoresize' : ''}`,
      toolbar: disabled
        ? false
        : `
                undo redo | j3Copy j3Cut j3Paste |
                bold italic underline strikethrough |
                fontfamily fontsize blocks |
                alignleft aligncenter alignright alignjustify |
                outdent indent | numlist bullist  checklist |
                forecolor backcolor removeformat |
                pagebreak | charmap emoticons |
                fullscreen  preview save print | | ltr rtl
                j3Link j3Image|
                table code |
                `,
      toolbar_mode: 'sliding',
      statusbar: !disabled,
      inline: true,
      contextmenu: 'j3Link | j3Image | j3Copy j3Cut j3Paste | table',
      menubar: false,
      table_toolbar:
        'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
      table_default_attributes: { border: '1' },
      height: disabled ? 'auto' : this.heightValue ? this.heightValue : '350',
      setup: this.onSetupEditor.bind(this),
      resize: !disabled,
      readonly: disabled,
      min_height: disabled ? 0 : 100,
      branding: false,
      fontsize_formats: '8pt=0.666rem 10pt=0.833rem 12pt=1rem 14pt=1.166rem 18pt=1.5rem 24pt=2rem 36pt=3rem',
      autoresize_bottom_margin: 0,
      font_family_formats: `
          Andale Mono=andale mono,
          times;
          Arial=arial,
          helvetica,
          sans-serif;
          Arial Black=arial black,
          avant garde;
          Book Antiqua=book antiqua,
          palatino;
          Comic Neue=Comic Neue,
          cursive;
          Courier New=courier new,
          courier;
          Georgia=georgia,
          palatino;
          Helvetica=helvetica;
          Impact=impact,
          chicago;
          Symbol=symbol;
          Tahoma=tahoma,
          arial,
          helvetica,
          sans-serif;
          Terminal=terminal,
          monaco;
          Times New Roman=times new roman,
          times;
          Trebuchet MS=trebuchet ms,
          geneva;
          Verdana=verdana,
          geneva;
          Webdings=webdings;
          Wingdings=wingdings,
          zapf dingbats,
          Open Sans=Open Sans;
          `,
    };

    if (this.editorFonts?.length) {
      config.font_family_formats = `${config.font_family_formats}${this.editorFonts.join('')}`;
    }

    return this.inlineMode
      ? {
        ...config,
        fixed_toolbar_container: `#${this.toolbarId}`,
        init_instance_callback: this.onEditorInstance.bind(this),
      }
      : config;
  }


  public defineLimitedEditorConfig(disabled: boolean = false): tiny.RawEditorOptions {

    const config: tiny.RawEditorOptions = {
      plugins: 'lists help wordcount code preview advlist fullscreen',
      toolbar: `
                undo redo | j3Copy j3Cut j3Paste |
                bold italic underline strikethrough |
                blocks |
                alignleft aligncenter alignright alignjustify |
                numlist bullist checklist |
                forecolor removeformat j3Link |
                fullscreen  preview save code |
                `,
      toolbar_mode: 'sliding',
      contextmenu: 'j3Link | j3Copy j3Cut j3Paste',
      menubar: false,
      min_height: 350,
      setup: this.#onSetupLimitedEditor.bind(this),
      selector: this.toolbarId,
      inline: false,
      branding: false,
      format: 'text',
      block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',

    };

    return config;
  }

  private getTranslationObserver(key: string): Observable<any> {
    if (!key?.length) {
      return of('');
    }

    return (this.translatedContentValue = this.translateService
      .get(this.content)
      .pipe(
        map((res) => (isNil(res) ? '' : res)),
        finalize(() => {
          if (isPlatformServer(this.#platformId)) {
            return;
          }
          setTimeout(() => this.transformNodes(this.previewBox?.nativeElement), 200);
        })
      ));
  }

  #injectType<T>(objectType: Type<T> | ProviderToken<T>): T {
    return this.#appInjector.get(objectType);
  }



  #onSetupLimitedEditor(editor: tiny.Editor): void {
    for (const plugin of plugins) {
      plugin(editor, this.#injectType.bind(this));
    }

    editor.on('init', () => {
      setTimeout(() => {
        this.editorInitialized.emit();
        editor.focus();
      }, 1000);
    });

  }

}
