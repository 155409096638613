import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';

import { ProfileDomainService } from '../../../domain-services';
import * as fromActions from '../actions';

@Injectable()
export class UserProfileEffects {
  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadUserMyProfileData),
      switchMap(() =>
        this.profileDomainService.get('').pipe(
          map((response) => {
            const { result: userData, success, error } = response;
            return success
              ? fromActions.LoadUserMyProfileDataComplete({ userData })
              : fromActions.LoadUserMyProfileDataFailed({ error });
          }),
          catchError((error) => of(fromActions.LoadUserMyProfileDataFailed({ error })))
        ))
    ));

  constructor(private actions$: Actions, private profileDomainService: ProfileDomainService) {}
}
