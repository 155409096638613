<div class="p-4">
  <div class="d-flex justify-content-between">
    <h2>{{ modalSettings?.popupSettings?.title }}</h2>
    <i
      (click)="onClose()"
      class="material-icons"
      >close</i
    >
  </div>
  <div
    [innerHTML]="modalSettings?.popupSettings?.text"
    class="content"
  ></div>
  <div class="footer">
    <button
      (click)="onClose()"
      class="btn btn-primary"
    >
      OK
    </button>
  </div>
</div>
