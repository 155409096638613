import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';

import { GdprSettingsDomainService } from '../../../domain-services';
import { gdprSettingsActions } from '../actions';

@Injectable()
export class GdprSettingsEffects {
  loadGdprSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(gdprSettingsActions.LoadGdprSettings),
      switchMap(() =>
        this.gdprSettingsDomainService.get('').pipe(
          map((result) => gdprSettingsActions.LoadGdprSettingsComplete({ gdprSettings: result.result })),
          catchError((error) => of(gdprSettingsActions.LoadGdprSettingsFailed({ error })))
        ))
    ));

  updateGdprSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(gdprSettingsActions.UpdateGdprSettings),
      mergeMap(({ gdprSettings }) =>
        this.gdprSettingsDomainService.put(gdprSettings).pipe(
          map(() => gdprSettingsActions.UpdateGdprSettingsComplete({ gdprSettings })),
          catchError((error) => of(gdprSettingsActions.UpdateGdprSettingsFailed({ error })))
        ))
    ));

  constructor(private actions$: Actions, private gdprSettingsDomainService: GdprSettingsDomainService) {}
}
