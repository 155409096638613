import { isPlatformBrowser } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import {
  domainModels,
  enums,
} from '@jotter3/api-connector';
import { ResourcesManagerService } from '@jotter3/common-components';
import {
  imageHelpers,
  J3TranslateService,
} from '@jotter3/common-helpers';
import {
  ModifierType,
  Property,
  SiteComponent,
  SiteComponentCategory,
} from '@jotter3/sites-abstract';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  cloneDeep,
  isEqual,
  isNil,
} from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';
import SwiperCore, {
  A11y,
  Autoplay,
  Controller,
  EffectFade,
  Lazy,
  Navigation,
  Pagination,
  Scrollbar,
  Swiper,
  SwiperOptions,
  Thumbs,
  Virtual,
  Zoom,
} from 'swiper';
import { AutoplayOptions } from 'swiper/types';
import { PaginationOptions } from 'swiper/types/modules/pagination';
import { v4 as UUID } from 'uuid';

import { IMAGE_SIZES_OPTIONLIST_SOURCE } from '../image-element/image-element.consts';
import { JotterSitesBaseComponent } from '../jotter-sites-base.component';

export interface SlideshowCaptions {
  title?: string;
  text?: string;
}

@SiteComponent({
  selector: 'slideshow-content-component',
  displayName: 'Slideshow',
  icon: 'gallery',
  category: SiteComponentCategory.MEDIA,
  showSettingsAfterAdd: true,
})
@Component({
  templateUrl: './slideshow-element.component.html',
  styleUrls: ['./slideshow-element.component.scss'],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideshowSiteComponent
  extends JotterSitesBaseComponent
  implements OnInit, AfterViewInit {
  public imagesListSrc: domainModels.IFileDomainModel[] = [];
  public noPaddingsValue = false;
  public elementId: string = UUID();
  public attachments: domainModels.IFileDomainModel[] = [];
  public slideshowCaptionsList: SlideshowCaptions[] = [];
  public showPointsValue = 1;
  public animationTypeValue = 1;
  public pictureSizeList = enums.PicturesSizes;
  public mediaSize = enums.ImageSize;
  tenantId: HttpParams;
  private titleListSrc: Array<string> = [];
  private captionsListSrc: Array<string> = [];
  private resourceListId: string;
  private imagesConfig: BehaviorSubject<SwiperOptions> = new BehaviorSubject<SwiperOptions>({});
  private captionsConfig: BehaviorSubject<SwiperOptions> = new BehaviorSubject<SwiperOptions>({});
  private defaultSlidesOptions: SwiperOptions = {
    preventInteractionOnTransition: true,
    allowTouchMove: false,
    simulateTouch: false,
    loop: true,
  };
  private captionsConfigValues: SwiperOptions = {};
  private imagesConfigValues: SwiperOptions = {};
  private width = '';
  private height = '';
  private animationDelayValue = 7000;
  private animationSpeedValue = 2000;
  private slideshowCaptions: boolean;
  private sliderImages: Swiper;
  private sliderCaptions: Swiper;
  readonly #platformId: object = inject(PLATFORM_ID);

  constructor(
    private readonly resourcesManager: ResourcesManagerService
  ) {
    super(SlideshowSiteComponent);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    displayName: 'Heading',
    modifierType: ModifierType.TEXT,
  })
    customHeader = '';

  @Property({
    displayName: 'Images',
    modifierType: ModifierType.FILEUPLOAD,
    templateOptions: {
      multipleItems: true,
      allowTypes: [1],
      getUrl: true,
    },
    required: true,
  })
  get imagesList(): domainModels.IFileDomainModel[] {
    return this.imagesListSrc;
  }

  set imagesList(value: domainModels.IFileDomainModel[]) {
    if (isEqual(this.imagesListSrc, value) || isNil(value)) {
      return;
    }
    this.imagesListSrc = value;
    this.attachments = value;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    displayName: 'Titles',
    modifierType: ModifierType.ARRAY,
  })
  get titleList(): Array<string> {
    return this.titleListSrc;
  }
  set titleList(value: Array<string>) {
    if (isEqual(this.titleListSrc, value)) {
      return;
    }
    this.titleListSrc = value;
    if (this.captionsListSrc.length > this.titleListSrc.length) {
      this.generateCaptionsList(this.captionsListSrc, 'slideshowCaptions');
    } else {
      this.generateCaptionsList(this.titleListSrc, 'slideshowTitles');
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    displayName: 'Captions',
    modifierType: ModifierType.ARRAY,
  })
  get captionList(): Array<string> {
    return this.captionsListSrc;
  }

  set captionList(value: Array<string>) {
    if (isEqual(this.captionsListSrc, value)) {
      return;
    }
    this.captionsListSrc = value;
    if (this.captionsListSrc.length > this.titleListSrc.length) {
      this.generateCaptionsList(this.captionsListSrc, 'slideshowCaptions');
    } else {
      this.generateCaptionsList(this.titleListSrc, 'slideshowTitles');
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    modifierType: ModifierType.UNIT_INPUT,
    units: [
      '%',
      'px',
    ],
    displayName: 'Width',
  })
  get cssWidth(): string {
    return this.width;
  }
  set cssWidth(value: string) {
    this.width = value;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    modifierType: ModifierType.UNIT_INPUT,
    displayName: 'Height',
  })
  get cssHeight(): string {
    return this.height;
  }
  set cssHeight(value: string) {
    this.height = value;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    displayName: 'Image Resolution',
    modifierType: ModifierType.IMAGE_RESOLUTION,
    optionsList: IMAGE_SIZES_OPTIONLIST_SOURCE,
    required: true,
  })
  public imageSize = enums.PicturesSizes.M;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    modifierType: ModifierType.DROPDOWN,
    template: 'vaIcons',
    dropdownSource: [
      {
        label: 'Left',
        value: 'text-left',
        icon: 'align_left',
      },
      {
        label: 'Center',
        value: 'text-center',
        icon: 'align_center',
      },
      {
        label: 'Right',
        value: 'text-right',
        icon: 'align_right',
      },
    ],
    clearable: false,
    displayName: 'Align',
  })
    align = 'text-center';

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get cssClassValue(): string | undefined {
    return this.cssClass;
  }

  public set cssClassValue(value: string | undefined) {
    if (this.cssClass === value || value === undefined) {
      return;
    }
    this.cssClass = value;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: 'Fade',
        value: 0,
      },
      {
        label: 'Scroll',
        value: 1,
      },
    ],
    clearable: false,
    displayName: 'Animation',
    advanced: true,
  })
  public get animationType(): number {
    return this.animationTypeValue;
  }

  public set animationType(value: number) {
    if (isEqual(this.animationTypeValue, value)) {
      return;
    }
    this.animationTypeValue = value;
    if (this.animationTypeValue === 0) {
      this.animationTypeSet(this.captionsConfigValues, 'fade');
      this.animationTypeSet(this.imagesConfigValues, 'fade');
    } else {
      this.animationTypeSet(this.captionsConfigValues, 'slide');
      this.animationTypeSet(this.imagesConfigValues, 'slide');
    }
    this.captionsConfig.next(this.captionsConfigValues);
    this.imagesConfig.next(this.imagesConfigValues);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    modifierType: ModifierType.RADIO_BUTTON,
    optionsList: [
      {
        label: 'No',
        value: 0,
      },
      {
        label: 'Yes',
        value: 1,
      },
    ],
    displayName: 'Show Arrows',
    advanced: true,
  })
  public showArrows = 1;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    modifierType: ModifierType.RADIO_BUTTON,
    optionsList: [
      {
        label: 'No',
        value: 0,
      },
      {
        label: 'Yes',
        value: 1,
      },
    ],
    displayName: 'Show Points',
    advanced: true,
  })
  public get showPoints(): number {
    return this.showPointsValue;
  }

  public set showPoints(value: number) {
    if (isEqual(this.showPointsValue, value)) {
      return;
    }
    this.showPointsValue = value;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    displayName: 'Ignore column padding',
    modifierType: ModifierType.CHECK_BOX,
    className: 'form-switch',
    advanced: true,
  })
  public get noPaddings(): boolean {
    return this.noPaddingsValue;
  }

  public set noPaddings(value: boolean) {
    if (this.noPaddingsValue === value || value === undefined) {
      return;
    }
    this.noPaddingsValue = value;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    displayName: 'Transition Delay',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: '1s',
        value: 1000,
      },
      {
        label: '2s',
        value: 2000,
      },
      {
        label: '3s',
        value: 3000,
      },
      {
        label: '4s',
        value: 4000,
      },
      {
        label: '5s',
        value: 5000,
      },
      {
        label: '6s',
        value: 6000,
      },
      {
        label: '7s',
        value: 7000,
      },
      {
        label: '8s',
        value: 8000,
      },
      {
        label: '9s',
        value: 9000,
      },
      {
        label: '10s',
        value: 10000,
      },
    ],
    required: true,
    advanced: true,
  })
  public get animationDelay(): number {
    return this.animationDelayValue;
  }

  public set animationDelay(value: number) {
    if (isEqual(this.animationDelayValue, value)) {
      return;
    }
    this.animationDelayValue = value;
    this.imagesConfigValues.autoplay = {
      ...(this.imagesConfigValues.autoplay as AutoplayOptions),
      delay: value,
    };
    this.captionsConfigValues.autoplay = {
      ...(this.captionsConfigValues.autoplay as AutoplayOptions),
      delay: value,
    };
    this.captionsConfig.next(this.captionsConfigValues);
    this.imagesConfig.next(this.imagesConfigValues);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Property({
    displayName: 'Transition Speed',
    modifierType: ModifierType.DROPDOWN,
    dropdownSource: [
      {
        label: 'Fast (0.5s)',
        value: 500,
      },
      {
        label: 'Medium (1s)',
        value: 1000,
      },
      {
        label: 'Slow (2s)',
        value: 2000,
      },
    ],
    advanced: true,
  })
  public get animationSpeed(): number {
    return this.animationSpeedValue;
  }

  public set animationSpeed(value: number) {
    if (isEqual(this.animationSpeedValue, value)) {
      return;
    }
    this.animationSpeedValue = value;
    this.imagesConfigValues.speed = value;
    this.captionsConfigValues.speed = value;
    this.captionsConfig.next(this.captionsConfigValues);
    this.imagesConfig.next(this.imagesConfigValues);
  }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.#platformId)) {
      this.initSwiperCore();
    }
  }

  public override setDataset(data: { [key: string]: any }): void {
    super.setDataset(data);
    if (!data) {
      return;
    }
    this.resourceListId = data?.['resourceListId'];
  }

  public override getDataset(): { [key: string]: any } {
    const dataset = super.getDataset();
    const { ...rest } = dataset;
    rest['resourceListId'] = this.resourceListId;
    rest['imagesList'] = null;
    return rest;
  }

  public override ngAfterViewInit(): void {
    if (isPlatformBrowser(this.#platformId)) {
      this.onResourcesChanged(this.resourceListId);
    }

  }

  public override onSaveChanges(): Observable<boolean> {
    return this.addOrUpdateResourcesList(this.attachments);
  }

  public nextSlide(): void {
    this.sliderImages.slideNext();
    if (this.slideshowCaptionsList.length > 1) {
      this.sliderCaptions.slideNext();
    }
  }

  public prevSlide(): void {
    this.sliderImages.slidePrev();
    if (this.slideshowCaptionsList.length > 1) {
      this.sliderCaptions.slidePrev();
    }
  }

  public slidesMove(e: SwiperCore, type: string): void {
    if (
      (type === 'captions' &&
        e.previousIndex === this.attachments.length + 1 &&
        this.attachments.length !== this.slideshowCaptionsList.length) ||
      (type === 'images' &&
        e.previousIndex === this.slideshowCaptionsList.length + 1 &&
        this.attachments.length !== this.slideshowCaptionsList.length)
    ) {
      return;
    }

    const indexDiff = e.activeIndex - e.previousIndex;
    switch (true) {
      case this.attachments.length > this.slideshowCaptionsList.length &&
        this.slideshowCaptionsList.length > 0:
        if (indexDiff > 0) {
          this.sliderCaptions?.slideNext();
        } else {
          this.sliderCaptions?.slidePrev();
        }
        break;
      case this.attachments.length < this.slideshowCaptionsList.length:
        if (indexDiff > 0) {
          this.sliderImages?.slideNext();
        } else {
          this.sliderImages?.slidePrev();
        }
        break;
      default:
        if (e.activeIndex === this.attachments.length + 1) {
          this.sliderImages?.slideNext();
        } else {
          this.sliderImages?.slideToLoop(e.realIndex);
        }
        break;
    }
  }

  public override afterSettingsSaved(): void {
    this.removeSlideshow();
    setTimeout(() => {
      if (isNil(this.sliderImages) || this.sliderImages?.destroyed) {
        this.createSlideshow('images');
      }
      if (isNil(this.sliderCaptions) || this.sliderCaptions?.destroyed) {
        this.createSlideshow('captions');
      }
    }, 1000);
  }

  public removeSlideshow(): void {
    if (this.sliderCaptions !== undefined) {
      this.sliderCaptions.destroy(true, true);
    }
    if (this.sliderImages !== undefined) {
      this.sliderImages.destroy(true, true);
    }
  }

  public picturesSizesList(imageSize: enums.PicturesSizes): Array<string> {
    return imageHelpers.picturesSizesList(imageSize);
  }

  private createSlideshow(element?: 'images' | 'captions'): void {
    if (this.captionsListSrc.length > this.titleListSrc.length) {
      this.generateCaptionsList(this.captionsListSrc, 'slideshowCaptions');
    } else {
      this.generateCaptionsList(this.titleListSrc, 'slideshowTitles');
    }

    // check if they exist and there are more captions than images
    this.slideshowCaptions = this.slideshowCaptionsList.length >= this.attachments.length;
    const captionRendererTemplate: PaginationOptions = {
      el: `#content-swiper-slideshow__pagination-${ this.elementId}`,
      clickable: true,
    };

    if (element !== 'images' && this.slideshowCaptionsList?.length) {
      this.captionsConfigValues = {
        ...this.defaultSlidesOptions,
        speed: this.slideshowCaptionsList.length > 1 ? this.animationSpeedValue : 0,
      };

      if (this.animationTypeValue === 0) {
        this.animationTypeSet(this.captionsConfigValues, 'fade');
      }

      if (this.animationDelayValue > 0 && this.slideshowCaptions) {
        this.captionsConfigValues.autoplay = {
          delay: this.animationDelayValue,
          disableOnInteraction: false,
        };
      }
      if (this.slideshowCaptions) {
        this.captionsConfigValues.pagination = captionRendererTemplate;
      }

      this.sliderCaptions = new Swiper(`.content-swiper-slideshow__captions-${ this.elementId}`, {
        ...this.captionsConfigValues,
      });

      if (this.slideshowCaptions) {
        this.sliderCaptions.on('slideChange', ($event) => {
          this.slidesMove($event, 'images');
        });
      }
    }

    if (element !== 'captions') {
      this.imagesConfigValues = {
        ...this.defaultSlidesOptions,
        speed: this.attachments.length > 1 ? this.animationSpeedValue : 0,
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
        },
      };

      if (this.animationDelayValue > 0 && !this.slideshowCaptions) {
        this.imagesConfigValues.autoplay = {
          delay: this.animationDelayValue,
          disableOnInteraction: false,
        };
      }

      if (!this.slideshowCaptions) {
        this.imagesConfigValues.pagination = captionRendererTemplate;
      }

      if (this.animationTypeValue === 0) {
        this.animationTypeSet(this.imagesConfigValues, 'fade');
      }

      this.sliderImages = new Swiper(`.content-swiper-slideshow__images-${ this.elementId}`, {
        ...this.imagesConfigValues,
      });

      if (!this.slideshowCaptions && this.slideshowCaptionsList.length > 1) {
        this.sliderImages.on('slideChange', ($event) => {
          this.slidesMove($event, 'captions');
        });
      }
    }
  }

  private generateCaptionsList(src: string[], captionsSRC: string): void {
    this.slideshowCaptionsList = [];
    src.forEach((txtValue, index) => {
      this.slideshowCaptionsList.push({
        title: captionsSRC === 'slideshowCaptions' ? this.titleListSrc[index] : txtValue,
        text: captionsSRC === 'slideshowCaptions' ? txtValue : this.captionsListSrc[index],
      });
    });
  }

  private addOrUpdateResourcesList(files: domainModels.IFileDomainModel[]): Observable<boolean> {
    if (!files?.length) {
      return of(true);
    }
    const model: domainModels.ResourceListDomainModel = {
      id: this.resourceListId || undefined,
      resources: files.map((file, index) => ({
        fileId: file.id,
        resourceOrder: index + 1,
      })),
    };

    return this.resourcesManager.setResource(model, 'LIST').pipe(
      map(resourceListId => {
        this.resourceListId = resourceListId;
        this.onResourcesChanged(resourceListId);
        return true;
      }),
      catchError(() => of(false))
    );
  }

  private onResourcesChanged(resourceId: string): void {
    if (!resourceId) {
      return;
    }
    this.resourcesManager
      .getResource(resourceId, 'LIST')
      .pipe(
        untilComponentDestroyed(this),
        map(res => res as domainModels.ResourceListDomainModel),
        map((response: domainModels.ResourceListDomainModel) => cloneDeep(response.resources as domainModels.ResourceDomainModel[]).sort((a, b) => a.resourceOrder > b.resourceOrder ? 1 : -1))
      )
      .subscribe((response) => {
        const imagesList: domainModels.IFileDomainModel[] = [];
        response.map((elementImage) => imagesList.push((elementImage.file as domainModels.IFileDomainModel)));
        this.attachments = imagesList;
        this.imagesListSrc = imagesList;
        this.removeSlideshow();
        setTimeout(() => {
          this.createSlideshow();
        }, 1000);
      });
  }

  private animationTypeSet(src: SwiperOptions, type: 'fade' | 'slide'): void {
    src.effect = type;
    src.fadeEffect = {
      crossFade: type === 'fade',
    };
  }

  private initSwiperCore(): void {
    SwiperCore.use([
      Navigation,
      Pagination,
      Scrollbar,
      A11y,
      Virtual,
      Zoom,
      Autoplay,
      Thumbs,
      Controller,
      EffectFade,
      Lazy,
    ]);
  }

}

