import {
  EntityDataModuleConfig,
  EntityMetadataMap,
} from '@ngrx/data';

const additionalCollectionState: any = {
  pagination: null,
};

const entityMetadata: EntityMetadataMap = {
  Letter: { additionalCollectionState },
  Category: { additionalCollectionState },
  Group: { additionalCollectionState },
  Link: { additionalCollectionState },
  Profile: { additionalCollectionState },
  Notice: { additionalCollectionState },
  New: { additionalCollectionState },
  Page: { additionalCollectionState },
  Site: { additionalCollectionState },
  Template: { selectId: (x) => x.name },
  Calendar: { additionalCollectionState },
  Gallery: { additionalCollectionState },
  TenantDomain: { additionalCollectionState },
  Role: { additionalCollectionState },
  Page_contents: { additionalCollectionState },
  Slideshow: { additionalCollectionState },
  Form: { additionalCollectionState },
  FormField: { additionalCollectionState },
  Form_Submition: { additionalCollectionState },
  Tenant: { additionalCollectionState },
  Group_Type: { additionalCollectionState },
  AddGroupMember: { additionalCollectionState },
  Group_Members: { additionalCollectionState },
  Group_Membership: { additionalCollectionState },
  Add_Group_Membership: { additionalCollectionState },
  File: { additionalCollectionState },
  Group_Type_List: { additionalCollectionState },
  Profile_Contacts: { additionalCollectionState },
  Profile_Students: { additionalCollectionState },
  Contact_Relation: { additionalCollectionState },
  MisList: { selectId: (x) => x.name },
  MisMatched: { additionalCollectionState },
  MisIgnored: { additionalCollectionState },
  SitePages: { additionalCollectionState },
  MobileSettings: { additionalCollectionState },
  Revision: { additionalCollectionState },
  Subscription: { additionalCollectionState },
  NewsletterSendDetails: { additionalCollectionState },
  Policy_groups: { additionalCollectionState },
  Policy_groups_shared: { additionalCollectionState },
  Policies: { additionalCollectionState },
};

const pluralNames = {
  TenantDomain: 'tenant_domains',
  Add_Group_Membership: 'group_memberships/:id',
  Profile_Contacts: 'profiles/:id/contacts',
  Profile_Students: 'profiles/:id/students',
  MisList: 'missync/info',
  MisIgnored: 'missync/ignored/:syncKey',
  MisMatched: 'missync/:syncKey',
  Form_Submition: 'forms/:id/submitted',
  Revision: 'pages/:id/contents',
  Subscription: 'email_subscriptions',
  NewsletterSendDetails: 'letter_sends',
  Policy_groups: 'policy_groups',
  Policy_groups_shared: 'policy_groups/shared',
  Policies: 'policies',
};

const EntityDataConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames,
};

export { EntityDataConfig,
  pluralNames };
