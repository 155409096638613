import { InjectionToken } from '@angular/core';
import { domainModels } from '@jotter3/api-connector';
import { Observable } from 'rxjs';

interface FileManagerOptions {
  allowedTypes?: any;
  selectedItems?: domainModels.IFileDomainModel[];
  multiple?: boolean;
}

interface FileManagerProvider {
  openManager: (opt: FileManagerOptions) => Observable<any>;
}

const FILE_MANAGER_TOKEN: InjectionToken<FileManagerProvider> = new InjectionToken<FileManagerProvider>('FILE_MANAGER_TOKEN');

export { FILE_MANAGER_TOKEN,
  FileManagerOptions,
  FileManagerProvider };
