<div class="modal-head">
  <div class="row">
    <div class="col">
      <h4 class="modal-header-title m-0">Row Settings</h4>
    </div>
    <div class="col">
      <button
        class="btn small-btn float-end"
        (click)="onCloseDialog()"
        style="
          width: 40px;
          height: 40px;
          padding: 8px;
          position: relative;
          left: 8px;
        "
      >
        <j3-icon name="close"></j3-icon>
      </button>
    </div>
  </div>
</div>

<div class="p-4 pt-0">
  <div>
    <formly-form [form]="form" [fields]="fields" [model]="row"></formly-form>
  </div>
  <div class="d-flex gap-1">
    <button class="btn btn-primary" (click)="onSaveChanges()" type="submit">
      Save
    </button>
    <button class="btn btn-secondary" (click)="onCloseDialog()">Cancel</button>
  </div>
</div>
