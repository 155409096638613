import {
  CommonModule,
  isPlatformBrowser,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  NgZone,
  OnDestroy,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  FullCalendarComponent,
  FullCalendarModule,
} from '@fullcalendar/angular';
import {
  CalendarOptions,
  DatesSetArg,
  EventApi,
  EventClickArg,
  EventInput,
  EventMountArg,
} from '@fullcalendar/core';
import { DateClickArg } from '@fullcalendar/interaction';
import { CalendarEventDomainModel } from '@jotter3/api-connector';
import { PushModule } from '@ngrx/component';
import { isNil } from 'lodash-es';
import * as moment from 'moment-timezone';
import {
  filter,
  map,
  Observable,
  Subscription,
} from 'rxjs';
import Tooltip from 'tooltip.js';

import {
  BaseCalendarComponentStore,
  CALENDAR_STORE,
} from '../../common';
import { CalendarEventClickArgs } from '../../models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'wa-cal-calendar',
  templateUrl: 'calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    PushModule,
    FullCalendarModule,
  ],
})
export class CalendarComponent implements AfterViewInit, OnDestroy {
  readonly #ngZone: NgZone = inject(NgZone);
  readonly #subscription: Subscription = new Subscription();
  readonly #platformId: unknown = inject(PLATFORM_ID);
  readonly #componentStore: BaseCalendarComponentStore = inject<BaseCalendarComponentStore>(CALENDAR_STORE);
  readonly options$: Observable<CalendarOptions> = this.#componentStore.selectOptions$.pipe(map(options => ({
    ...options,
    eventDidMount: this.onEventDidMount.bind(this),
  })));
  readonly events$: Observable<EventInput[]> = this.#componentStore.selectEvents$;
  @Output() eventClick: EventEmitter<CalendarEventClickArgs> = new EventEmitter<CalendarEventClickArgs>();
  @Output() dateClick: EventEmitter<DateClickArg> = new EventEmitter<DateClickArg>();
  #fullCalendarComponent: FullCalendarComponent;

  @ViewChild('fullCalendarComponent', { static: false }) set fullCalendarComponent(value: FullCalendarComponent) {
    this.#fullCalendarComponent = value;
    this.ngAfterViewInit();
  }

  public ngAfterViewInit(): void {
    if (!this.#fullCalendarComponent) {
      return;
    }

    const api = this.#fullCalendarComponent?.getApi();
    const { activeStart, activeEnd } = api.view;

    this.#componentStore.changeSelectedDates({
      to: moment(activeEnd).add(1, 'days').toISOString(),
      from: moment(activeStart).add(-1, 'days').toISOString(),
    });

    api.on('eventClick', (event: EventClickArg) => {
      const { event: { extendedProps } } = event;

      this.#componentStore.selectEntity(extendedProps as CalendarEventDomainModel);
      this.eventClick.emit({
        event,
        sourceEvent: extendedProps as CalendarEventDomainModel,
      });

    });

    api.on('dateClick', (event: DateClickArg) => {
      this.dateClick.emit(event);
    });

    api.on('datesSet', ({ start, end }: DatesSetArg) => {
      this.#componentStore.changeSelectedDates({
        to: moment(end).add(1, 'days').toISOString(),
        from: moment(start).add(-1, 'days').toISOString(),
      });
    });

    this.#subscription.add(this.#componentStore.selectQueryParams$
      .pipe(
        filter(params => !isNil(params))
      )
      .subscribe(params => {
        this.#componentStore.loadEntities(params);
      }));

    this.#subscription.add(this.#componentStore.selectCalendarView$
      .subscribe(currentView => {
        api.changeView(currentView);
      }));
  }

  public ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }

  public get isBrowserSide(): boolean {
    return isPlatformBrowser(this.#platformId);
  }

  private onEventDidMount({ event, el }: EventMountArg): void {
    this.#ngZone.runOutsideAngular(() => this.showEventTooltip(event, el));
  }

  private showEventTooltip(
    { title: eventTitle, start: startDate, end: endDate, allDay, extendedProps }: EventApi,
    targetElement: HTMLElement
  ): void {
    const { sourceStartDate, sourceEndDate } = extendedProps;
    const allDayDateFormat = 'DD/MM/yyyy';
    const dateFormat = 'hh:mma DD/MM/yyyy';

    const start = (
      sourceStartDate ?? moment(startDate)
    ).format(allDay ? allDayDateFormat : dateFormat);

    let end;
    if (allDay) {
      end = (sourceEndDate ?? moment(endDate)).add(-1, 'd').format(allDayDateFormat); // fullcallendar end date is exclusive - so we have to remove one day
    } else {
      end = (sourceEndDate ?? moment(endDate)).format(dateFormat);
    }

    const location = extendedProps?.location ? `<br/><strong>${extendedProps.location}</strong>` : '';
    const description = extendedProps?.description ? `<br/>${extendedProps?.description}` : '';
    const title = `<p><strong>${eventTitle}</strong><br /> <em>${start} - ${end}</em>${location}${description}</p>`;

    new Tooltip(targetElement, {
      html: true,
      title,
    });
  }
}
