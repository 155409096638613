import { isPlatformServer } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import { appStoreSelectors } from '@jotter3/api-connector';
import { Store } from '@ngrx/store';
import {
  Observable,
  switchMap,
} from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class XTenantInterceptor implements HttpInterceptor {
  readonly #platformId = inject(PLATFORM_ID);
  readonly #store = inject(Store);

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { DB_URL, DB_URL_VPC } = environment.ApiConnectorConfig;
    const API_URL = isPlatformServer(this.#platformId) && DB_URL_VPC ? DB_URL_VPC : DB_URL;
    const { headers, urlWithParams } = request;

    const xTenantIgnore =
      headers.get('XTenantIgnore') ||
      (!urlWithParams.startsWith(API_URL) &&
        !urlWithParams.startsWith(environment.ApiConnectorConfig.TRANSLATE_API));

    if (xTenantIgnore) {
      return next.handle(request.clone({
        headers: request.headers.delete('XTenantIgnore'),
      }));
    }

    return this.#store.select(appStoreSelectors.selectTenant).pipe(take(1), switchMap(tenant => {
      return next.handle(request.clone({
        setHeaders: {
          'X-Tenant': tenant?.id || '',
        },
      }));
    }));
  }
}
