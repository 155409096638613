import { LoadingStateEnum } from '@jotter3/store-helpers';
import { ApiErrorResponse } from '@jotter3/wa-core';
import {
  createEntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  createFeature,
  createReducer,
  on,
} from '@ngrx/store';

import { TemplateDefinitionDomainModel } from '../../domain-models';
import { TemplatesStoreActions } from './templates.actions';

export interface TemplateState extends EntityState<TemplateDefinitionDomainModel> {
  loadingStatus: LoadingStateEnum;
  errors: Array<ApiErrorResponse | unknown> | undefined;
}

const templateStateAdapter = createEntityAdapter<TemplateDefinitionDomainModel>();

const initialState: TemplateState = templateStateAdapter.getInitialState({
  loadingStatus: LoadingStateEnum.PENDING,
  errors: undefined,
});

export const templatesStoreFeature = createFeature({
  name: 'templatesStore',
  reducer: createReducer(
    initialState,
    on(TemplatesStoreActions.loadTrigger, (state: TemplateState): TemplateState => ({
      ...state,
      loadingStatus: LoadingStateEnum.LOADING,
    })),
    on(TemplatesStoreActions.loadSuccess, (state: TemplateState, { entity }): TemplateState => templateStateAdapter.addMany(entity, {
      ...state,
      loadingStatus: LoadingStateEnum.SUCCESS,
    })),
    on(TemplatesStoreActions.loadFailed, (state: TemplateState, { errors }): TemplateState => ({
      ...state,
      errors,
      loadingStatus: LoadingStateEnum.FAILED,
    }))
  ),
});
