import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TemplatesEffects } from './templates.effects';
import { templatesStoreFeature } from './templates.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(templatesStoreFeature),
    EffectsModule.forFeature([TemplatesEffects]),
  ],
})
export class TemplatesStoreModule {

}
