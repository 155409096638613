import {
  inject,
  Injectable,
} from '@angular/core';
import {
  siteStoreSelectors,
  templatesSelectors,
} from '@jotter3/api-connector';
import { siteTemplateHelpers } from '@jotter3/core';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  map,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';


@Injectable()
export class ThemeDataService {
  readonly #store: Store = inject(Store);
  public getThemeFontsList(): Observable<string[]> {
    return this.#store.select(templatesSelectors.selectEntities).pipe(
      map(templates => siteTemplateHelpers.convertVariantsToTemplateDefinitions(templates)),
      concatLatestFrom(() => this.#store.select(siteStoreSelectors.selectEntity)),
      switchMap(([
        templates,
        siteSettings,
      ]) => {
        const currentTheme = templates.find((template) => template.name === siteSettings?.themeName);
        const fontsList: string[] = [];
        currentTheme?.editorFonts?.forEach(font => {
          const newFont = `${font.name}=${font.family};`;
          fontsList.push(newFont);
        });
        return of(
          fontsList
        );
      }),
      take(1)
    );
  }
}
