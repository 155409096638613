import { isPlatformServer } from '@angular/common';
import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import {
  J3TranslateService,
  LeafletService,
} from '@jotter3/common-helpers';
import {
  TemplateBaseComponent,
  TemplateComponent,
} from '@jotter3/sites-abstract';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { take } from 'rxjs/operators';

import { GeoJson } from '../../models/geojson.model';
import { TemplateDataProvider } from '../../providers';
import { mapConfig } from './map.config';

@TemplateComponent({
  selector: 'jotter-tplcmp-map',
  displayName: 'Map',
  defaultClass: 'site-map-element',
})
@Component({
  selector: 'jotter-tplcmp-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [
    {
      provide: TranslateService,
      useExisting: J3TranslateService,
    },
  ],
})
export class MapTemplateComponent extends TemplateBaseComponent implements AfterViewInit {
  private map: any;
  private marker: any = null;
  public showErr = false;

  constructor(
    private http: HttpClient,
    private leafletServie: LeafletService,
    private dataProvider: TemplateDataProvider,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    super(MapTemplateComponent);
  }

  public ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.leafletServie
      .loadMap()
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: (leaflet) => {
          this.dataProvider
            .getData()
            .pipe(untilComponentDestroyed(this))
            .subscribe((res) => {
              this.getDataFromApi(leaflet, res?.address?.postcode);
            });
        },
        error: (error) => {
          console.log('Error: ', error);
        },
      });
  }

  private initMap(leaflet: any, lan: number, lon: number): void {
    const container = leaflet.DomUtil.get('footer-map-component');
    if (container != null) {
      container._leaflet_id = null;
    }
    const tiles = leaflet.tileLayer(mapConfig.tileUrl, {
      maxZoom: 19,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });
    const icon = leaflet.icon({
      iconUrl: './assets/marker-icon.png',
      iconSize: [
        25,
        41,
      ],
      iconAnchor: [
        12.5,
        41,
      ],
    });
    this.marker = leaflet.marker([
      lon,
      lan,
    ], { icon });
    this.map = leaflet.map('footer-map-component', {
      center: [
        lon,
        lan,
      ],
      zoom: 16,
    });
    tiles?.addTo(this.map);
    this.map?.attributionControl.setPrefix(false);
    this.map?.on('moveend', () => {
      this.marker = leaflet.marker([
        lon,
        lan,
      ], { icon });
    });

    this.marker?.addTo(this.map);
  }

  private getDataFromApi(leaflet: any, postcode?: string): void {
    let params = new HttpParams();
    params = params.append('q', postcode || '');
    params = params.append('format', 'geojson');

    this.http
      .get(mapConfig.apiUrl, { params })
      .pipe(untilComponentDestroyed(this), take(1))
      .subscribe((res) => {
        const response = res as GeoJson;
        if (!response.features.length) {
          this.showErr = true;
          return;
        }
        const coordinates: number[][] = response.features.map((item) => item.geometry.coordinates);
        this.initMap(leaflet, coordinates[0][0], coordinates[0][1]);
      });
  }
}
