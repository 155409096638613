import { CalendarOptions } from '@fullcalendar/core';
import enLocale from '@fullcalendar/core/locales/en-gb';
import plLocale from '@fullcalendar/core/locales/pl';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from '@fullcalendar/timegrid';

import { CalendarView } from './enums';

export const DEFAULT_AVAILABLE_VIEWS = [
  CalendarView.DAY_GRID_MONTH,
  CalendarView.TIME_GRID_WEEK,
  CalendarView.TIME_GRID_DAY,
  CalendarView.LIST_WEEK,
];

export const DEFAULT_CALENDAR_OPTIONS: CalendarOptions = {
  plugins: [
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin,
    momentTimezonePlugin,
  ],
  locales: [
    enLocale,
    plLocale,
  ],
  locale: 'en-gb',
  initialView: CalendarView.DAY_GRID_MONTH,
  dayMaxEventRows: true,
  eventDisplay: 'block',
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: DEFAULT_AVAILABLE_VIEWS.join(','),
  },
  weekends: true,
  firstDay: 1,
  eventTimeFormat: {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
    hour12: false,
  },
  eventOrder: 'start,-duration,allDay,name',
  slotLabelFormat: {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: false,
    hour12: true,
  },
};
