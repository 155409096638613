<div
  *ngIf="designMode && !videoSrc"
  class="p-3 w-100 h-100 text-center bg-secondary"
>
  ENTER VIDEO SOURCE
</div>
<div
  *ngIf="videoSrc"
  [ngClass]="{ full: fullWidth }"
  class="videoWrapper"
>
  <div class="videoInner">
    <iframe
      [src]="videoSafeUrl"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>
</div>
