import { Injectable } from '@angular/core';
import { ApiService } from '@jotter3/wa-core';
import {
  Observable,
  of,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { urlJoin } from 'url-join-ts';

import { UserMyProfileDomainModel } from '../domain-models';
import { BaserDomainService } from './base.domain-service';

@Injectable({ providedIn: 'root' })
export class ProfileDomainService extends BaserDomainService<UserMyProfileDomainModel> {
  constructor(apiService: ApiService) {
    super('profiles/my', apiService);
  }

  public getAllProfiles(): Observable<UserMyProfileDomainModel[]> {
    return this.apiService.load<UserMyProfileDomainModel>(urlJoin('profiles', 'all')).pipe(switchMap((res) => of(res.result)));
  }
}
