<div class="calendar-carousel-wrapper" *ngIf="events?.length > 0">
  <swiper
    #eventsListSwiper
    [config]="eventsConfig"
    [loop]="events.length > slidesVisible"
  >
    <ng-container *ngFor="let event of events">
      <ng-template swiperSlide>
        <j3-events-list-item
          [event]="event"
          [elTemplate]="elTemplate"
        ></j3-events-list-item>
      </ng-template>
    </ng-container>
  </swiper>
  <div class="events-swiper-controls">
    <button
      class="events-swiper-navigation events-swiper-navigation--prev icon-chevron-left"
      *ngIf="showArrows && events.length > slidesVisible"
      (click)="prevSlide()"
      type="button"
    ></button>
    <div
      class="events-pagination"
      [ngClass]="
        showIndicators && events.length > slidesVisible ? '' : 'd-none'
      "
    ></div>
    <button
      class="events-swiper-navigation events-swiper-navigation--next icon-chevron-right"
      *ngIf="showArrows && events.length > slidesVisible"
      (click)="nextSlide()"
      type="button"
    ></button>
  </div>
</div>
