import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApiService,
} from '@jotter3/wa-core';
import {
  DefaultDataService,
  EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import {
  BehaviorSubject,
  Observable,
  switchMap,
} from 'rxjs';
import { urlJoin } from 'url-join-ts';

import { siteStoreSelectors } from '../+state';
import { CustomHttpUrlGenerator } from '../common';
import {
  SiteDomainModel,
  SitesSettingsDomainModel,
} from '../domain-models';
import { SearchResultDomainModel } from '../domain-models/search-result.domain-model';
import { PaginatedEntityCollectionServiceBase } from './paginated-entity-collection-base';

@Injectable({ providedIn: 'root' })
export class SitesCollectionService extends PaginatedEntityCollectionServiceBase<SiteDomainModel> {
  private currentSiteSubject: BehaviorSubject<SiteDomainModel> = new BehaviorSubject<SiteDomainModel>(undefined);
  private currentSiteValue: SiteDomainModel | undefined = undefined;

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private apiService: ApiService) {
    super('Site', serviceElementsFactory);
  }

  public invokeSearch(phrase: string, page: number = 1, size: number = 20): Observable<ApiResponse<SearchResultDomainModel[]>> {
    const params: HttpParams = new HttpParams().set('query', phrase).set('page', `${page}`).set('limit', `${size}`);

    return this.store.select(siteStoreSelectors.selectEntity).pipe(
      switchMap(site => this.apiService.load<SearchResultDomainModel>(urlJoin('sites', site.id, 'search'), params))
    );
  }
}

@Injectable({ providedIn: 'root' })
export class SitesSettingsService extends DefaultDataService<SitesSettingsDomainModel> {
  private currentSiteValue: SiteDomainModel | undefined = undefined;

  constructor(http: HttpClient, httpUrlGenerator: CustomHttpUrlGenerator) {
    super('Site', http, httpUrlGenerator);
  }

  public get currentSite(): SiteDomainModel | undefined {
    return this.currentSiteValue;
  }

  public updateSitesSettings(data: SitesSettingsDomainModel): Observable<SitesSettingsDomainModel> {
    return super.execute('PUT', this.currentSite?.id, data);
  }
}
