import {
  Inject,
  inject,
  Injectable,
  InjectionToken,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  DEFAULT_LANGUAGE,
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateLoader,
  TranslateParser,
  TranslateService,
  TranslateStore,
  USE_DEFAULT_LANG,
  USE_EXTEND,
  USE_STORE,
} from '@ngx-translate/core';
import {
  BehaviorSubject,
  Observable,
  Subscription,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { J3TranslateProvider } from '../providers';

export const TRANSLATE_PROVIDER = new InjectionToken<J3TranslateProvider>('TRANSLATE_PROVIDER');
export const USE_GOOGLE_TRANSLATE = new InjectionToken<BehaviorSubject<boolean>>('USE_GOOGLE_TRANSLATE');

@Injectable({ providedIn: 'root' })
export class J3TranslateService extends TranslateService implements OnDestroy {
  #store: Store = inject(Store);

  private readonly subscriptions: Subscription[] = [];
  private useExternalService = false;

  constructor(
    store: TranslateStore,
    currentLoader: TranslateLoader,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingTranslationHandler: MissingTranslationHandler,
    @Inject(USE_DEFAULT_LANG) useDefaultLang: boolean = true,
    @Inject(USE_STORE) isolate: boolean = false,
    @Inject(USE_EXTEND) extend: boolean = false,
    @Inject(DEFAULT_LANGUAGE) defaultLanguage: string,
    @Inject(TRANSLATE_PROVIDER) private translateProvider: J3TranslateProvider,
    @Inject(USE_GOOGLE_TRANSLATE) useGoogleTranslate: BehaviorSubject<boolean>
  ) {
    super(store, currentLoader, compiler, parser, missingTranslationHandler, useDefaultLang, isolate, extend, defaultLanguage);

    this.subscriptions.push(
      useGoogleTranslate.subscribe((value) => {
        this.useExternalService = value;
        this.onLangChange.emit({
          lang: this.currentLang,
          translations: this.translations,
        });
      })
    );
  }

  public override getParsedResult(
    translations: any,
    key: any,
    interpolateParams?: object
  ): string | Observable<string> | undefined {
    if (this.currentLang === 'en' || !this.useExternalService) {
      return super.getParsedResult(translations, key, interpolateParams);
    }

    return this.translateProvider.getTranslation(key, this.currentLang).pipe(map((res) => (typeof res === 'string' ? res : '')));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub?.unsubscribe());
  }
}
